import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuickBarItems } from '../quick-bar.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-tournament-list-quick-bar',
  templateUrl: './tournament-list-quick-bar.component.html',
  styleUrls: ['./tournament-list-quick-bar.component.scss'],
  animations: [
    trigger('swipeUp', [
      transition(':increment', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('0.5s ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':decrement', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('0.5s ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class TournamentListQuickBarComponent implements OnInit {
  public tournamentListShort: QuickBarItems[];
  public singlePlayerDisplayIndex: number;
  public tournamentListLong: QuickBarItems[];
  private interval: Subscription;
  constructor(private http: HttpClient) {}

  public stateOfQuickBar: string;

  @Input() closeQuickBar!: () => void;

  @Input() set setStateOfQuickBar(data: string) {
    this.stateOfQuickBar = data;
    if (this.stateOfQuickBar === 'show') {
      this.singlePlayerDisplayIndex = this.tournamentListLong?.length ? 0 : undefined;
      if (this.interval) {
        this.interval.unsubscribe();
      }
      this.interval = interval(3000).subscribe(() => {
        this.nextPlayerOnTimout();
      });
    }
  }

  @Input() tournamentItemConfig: QuickBarItems;

  ngOnInit() {
    this.getLeaderBoardForTournament();
  }

  private getLeaderBoardForTournament() {
    this.http
      .get(`https://www.admiralbet.rs/mbase/api/tournament/${this.tournamentItemConfig.tournamentID}/rank`)
      .subscribe((data: any) => {
        this.tournamentListLong = data.records;
        this.tournamentListShort = [
          ...data.records.slice(0, 3),
          '...',
          data.records[data.records.length - 1],
        ];
        this.singlePlayerDisplayIndex = 0;
      });
  }

  private nextPlayerOnTimout() {
    this.singlePlayerDisplayIndex++;
    if (this.singlePlayerDisplayIndex >= this.tournamentListLong.length) {
      this.singlePlayerDisplayIndex = 0;
    }
  }
}
