import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcOffset',
})
export class UtcOffsetPipe implements PipeTransform {
  transform(utcTime: any): unknown {
    const time = new Date(utcTime);
    const localOffset = time.getTimezoneOffset() * 60000;
    const offsetTime = new Date(time.getTime() - localOffset);
    return offsetTime;
  }
}
