import { environment } from '../../../environments/environment';
import { HelpersChatInstanceService } from '@services/helpers-chat-instance.service';

export class HelpersStringManipulationService extends HelpersChatInstanceService {
  constructor() {
    super();
  }

  cleanSerbCharacterFromString(string): string {
    return string
      .replace(/[č]/g, 'c')
      .replace(/[ž]/gi, 'z')
      .replace(/[š]/gi, 's')
      .replace(/[ć]/gi, 'c')
      .replace(/[đ]/gi, 'dj');
  }

  reverseString(_string) {
    return _string.split('').reverse().join('');
  }

  // trim phoneCode from cellPhone if exists
  trimPhoneCode(cellPhone: string): string {
    // e.g. cellPhone starts with +381
    if (cellPhone && cellPhone.startsWith(environment.phoneCode)) {
      cellPhone = cellPhone.replace(environment.phoneCode, '');

      // e.g. cellPhone starts with 381
    } else if (cellPhone && cellPhone.startsWith(environment.phoneCode.substring(1))) {
      cellPhone = cellPhone.replace(environment.phoneCode.substring(1), '');
    }
    return cellPhone;
  }
}
