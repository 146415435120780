<div
  #container
  class="tournamentDescriptionContainer"
  [ngStyle]="{ backgroundImage: 'url(' + tournament?.tournamentImage + ')' }"
>
  <div class="tournament-wrapper" [ngClass]="{ 'mobile-max-width': isMobile }">
    <div class="row no-gutters">
      <div class="col-12 col-md-8 pr-md-2">
        <div [ngClass]="{ 'info-container p-2': !isMobile }">
          <!-- <i class="pointer favorite-icon float-right" inlineSVG="assets/icons/info-circle-white.svg"></i> -->
          <p class="tournament-title">{{ tournament?.tournamentTitle }}</p>
          <div [ngClass]="{ 'info-container p-2': isMobile }">
            <p class="m-0 amount">
              {{ tournament.tournamentPrizePool | number : '1.2-2' | currency : tournament.currency }}
            </p>
            <div class="row no-gutters pt-sm-5">
              <div class="col-6">
                <p class="m-0" *ngIf="endTime">
                  {{ 'PINNBET_TOURNAMENT_REMAINING_TIME' | translate }}:
                  <span>
                    <span *ngIf="days">{{ days }} {{ 'COMMON_DAYS' | translate | lowercase }}</span>
                    {{ endTime }}
                  </span>
                </p>
              </div>
              <div class="col-6 text-right" *ngIf="tournament.minimumBet">
                <p class="m-0">
                  {{ 'PINNBET_TOURNAMENT_MIN_BET' | translate }} -
                  <span>{{ tournament.minimumBet | number : '1.2-2' | currency : tournament.currency }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 rank-list-container px-3">
        <ng-container *ngIf="rankListObservable | async as rankList; else noPlayers">
          <h2 *ngIf="!isMobile" class="text-center mt-3">{{ 'PINNBET_TOURNAMENT_RANK_LIST' | translate }}</h2>
          <div class="players-table mt-2 pt-3 col-12 px-0 col-sm-10 offset-sm-1">
            <div class="row no-gutters header pb-1">
              <div class="col-2 text-center">{{ 'PINNBET_TOURNAMENT_PLAYER_RANK' | translate }}</div>
              <div class="col">{{ 'PINNBET_TOURNAMENT_PLAYER' | translate }}</div>
              <div class="col-3 text-center">{{ 'PINNBET_TOURNAMENT_POINTS' | translate }}</div>
              <div class="col text-right">
                {{ 'PINNBET_TOURNAMENT_REWARD' | translate }} ({{ tournament.currency }})
              </div>
            </div>
            <div
              *ngFor="let rankedPlayer of rankList; index as i"
              class="row no-gutters body pt-2 pb-2"
              [ngClass]="{ 'text-primary font-weight-bold': rankedPlayer.playerId === player?.id }"
            >
              <div class="col-2 text-center">{{ i + 1 }}.</div>
              <div class="col">{{ rankedPlayer.username }}</div>
              <div class="col-3 text-center text-primary">{{ rankedPlayer.points }}</div>
              <div class="col text-right">
                {{ rankedPlayer.prize | number : '1.2-2' | currency : tournament.currency }}
              </div>
            </div>
          </div>
          <button
            class="px-4 py-1 rounded my-3"
            [ngClass]="{ 'disable-bottom-margin': rankList.length > 4 && isMobile }"
            (click)="openLeaderboard($event)"
          >
            {{ 'PINNBET_LOOK_ALL_PLAYERS' | translate }}
          </button>
        </ng-container>

        <app-count-down-counter
          *ngIf="showCounter"
          [labelForCounter]="labelForCounter"
          [countDownTime]="dateForCounter"
          [showBackground]="false"
        ></app-count-down-counter>

        <ng-template #noPlayers>
          <div *ngIf="!showCounter" class="no-active-players">
            {{ 'TOURNAMENT_NO_ACTIVE_PLAYERS' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
