import { Component, Input, OnInit } from '@angular/core';
// import { Main } from 'tsparticles';
// import { loadConfettiShape } from 'tsparticles-shape-confetti';
import { coinsSettings } from './particle_settings_coins';
import { snowSettings } from './snow';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-particle-system',
  templateUrl: './particle-system.component.html',
  styleUrls: ['./particle-system.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ParticleSystemComponent implements OnInit {
  @Input() typeOfParticles: string;
  title = 'CodeSandbox';
  options: any;
  // options = snowSettings;

  public activateParticle = false;
  private interval$: Subscription;

  // particlesInit(main: Main): void {
  //   loadConfettiShape(main);
  // }

  constructor(private appFacadeService: AppStateFacadeService) {}

  ngOnInit(): void {
    this.appFacadeService.observeParticleSystem().subscribe(particleData => {
      switch (true) {
        case particleData.type === 'confetti':
          this.options = coinsSettings;
          break;

        case particleData.type === 'snow':
          this.options = snowSettings;
          break;

        default:
          this.options = coinsSettings;
      }
      this.activateParticle = particleData.active;
      if (particleData.active) {
        this.interval$ = interval(7000).subscribe(_ => {
          this.appFacadeService.setParticleSystem(false);
          this.interval$.unsubscribe();
        });
      }
    });
  }
  clickOnParticleContainer() {
    this.activateParticle = false;
    this.interval$.unsubscribe();
  }
}
