<ng-container
  *ngIf="typeOfCheckboxButton === CHECKBOX_BUTTON_TYPES.TOUCH_REGION_BTN; else regularCheckboxButton"
>
  <div [class]="'d-flex ' + 'align-items-' + alignItems.toLowerCase()">
    <button
      type="button"
      class="custom-checkbox--is-touch_area mr-2 d-flex align-items-center justify-content-center"
      [ngClass]="{ active: controlValue }"
      [disabled]="checkboxFormControl.disabled"
      (click)="checkboxClicked()"
    >
      <span *ngIf="controlValue" class="inner-mark"></span>
    </button>
    <span (click)="checkboxClicked()" *ngTemplateOutlet="label"></span>
  </div>
</ng-container>

<ng-template #regularCheckboxButton>
  <div [class]="'d-flex ' + 'align-items-' + alignItems.toLowerCase()">
    <button
      type="button"
      class="custom-checkbox mr-2 d-flex align-items-center justify-content-center"
      [ngClass]="{ active: controlValue }"
      [disabled]="checkboxFormControl.disabled"
      (click)="checkboxClicked()"
    >
      <span *ngIf="controlValue" class="inner-mark"></span>
    </button>
    <span (click)="checkboxClicked()" *ngTemplateOutlet="label"></span>
  </div>
</ng-template>

<ng-template #label>
  <ng-content></ng-content>
</ng-template>
