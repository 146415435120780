import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-euro-cup',
  templateUrl: './euro-cup.component.html',
  styleUrls: ['./euro-cup.component.scss'],
})
export class EuroCupComponent {
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  private client = environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ? 'admiralbet' : 'pinnbet';
  public iframeUrl = environment.production
    ? `https://sportcenter.sir.sportradar.com/${this.client}/eurocup`
    : `https://feature-cs-16880-admiralbet-pinnbet-review.ci.sportcenterstaging.sir.sportradar.com/${this.client}/eurocup`;

  constructor(private appStateFacadeService: AppStateFacadeService, private router: Router) {}

  @HostListener('window:message', ['$event'])
  onSportMessage(event) {
    if (event.data.type === 'REDIRECT') {
      this.router.navigate([event.data.url]);
    }
  }
}
