import { GameDataModel } from './game-data.model';

export class MessageDataModel {
  id: number;
  title: string;
  text: string;
  textForTranslate: string;
  seen: boolean;
  createdOn: number;
  messageType: string;
  dialog: boolean;
  buttonUrl: string;
  buttonCode: string;
  games: [];
  subType: string;
  endDate: string;
  startDate: string;
  jackpotAssignedId: number;
  cashbackId: number;
  luckyWheelPrizeAwardQueueId: number;

  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.text = data.text;
    this.textForTranslate = data.textForTranslate;
    this.seen = data.seen;
    this.createdOn = data.createdOn;
    this.messageType = data.messageType || data.type || null;
    this.dialog = data.dialog || null;
    this.buttonUrl = data.buttonUrl || '';
    this.buttonCode = data.buttonCode || '';
    this.games = (data.games && data.games.map((g: any) => new GameDataModel().deserialize(g))) || [];
    this.subType = data.subType || '';
    this.endDate = data.endDate || null;
    this.startDate = data.startDate || null;
    this.jackpotAssignedId = data.jackpotAssignedId || 0;
    this.cashbackId = data.cashbackId || 0;
    this.luckyWheelPrizeAwardQueueId = data.luckyWheelPrizeAwardQueueId || null;
  }
}
