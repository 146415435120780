import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GameInfoDialog } from '@modules/lobby/game-info/game-info.dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { GameDataModel } from '@models/game-data.model';
import { LOBBY_PATHS } from '@enums/lobby-paths.enum';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { CroatiaGameInfoDialog } from '@modules/lobby/game-info-croatia/croatia-game-info.dialog';
import { PinnbetGameInfoDialog } from '@modules/lobby/game-info-pinnbet/pinnbet-game-info.dialog';

@Component({
  selector: 'app-game-slider',
  templateUrl: './game-slider.component.html',
  styleUrls: ['./game-slider.component.scss'],
})
export class GameSliderComponent implements OnInit {
  @Output() gameInfoOpened = new EventEmitter<any>();
  @Input() location;
  @Input() games;
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  public componentId = Math.random().toString(36).substring(2);
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public numberOfVisibleGames;

  constructor(private appStateFacadeService: AppStateFacadeService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.numberOfVisibleGames = this.location === 'CASINO_CAROUSEL' ? 4 : 3;
  }

  // side scroll
  public sideScroll(event, direction: string): void {
    event.stopPropagation();
    const gamesContainer = document.getElementById(this.componentId);
    if (direction === 'right') {
      gamesContainer.scrollBy(gamesContainer.clientWidth, 0);
    } else {
      gamesContainer.scrollBy(-gamesContainer.clientWidth, 0);
    }
  }

  // navigate to game info page
  public navigateToGameInfo(game: any): void {
    this.openGameInfoDialog(game);
  }

  private openGameInfoDialog(game: GameDataModel): void {
    let GAME_INFO_DIALOG;
    let dialogOnCenter = true;

    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        GAME_INFO_DIALOG = CroatiaGameInfoDialog;
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
        GAME_INFO_DIALOG = PinnbetGameInfoDialog;
        dialogOnCenter = this.appStateFacadeService.getIsMobileStatus() ? false : true;
        break;
      default:
        GAME_INFO_DIALOG = GameInfoDialog;
        break;
    }

    const modalRef = this.modalService.open(GAME_INFO_DIALOG, {
      centered: dialogOnCenter,
      scrollable: false,
      keyboard: false,
    });

    // Set data on dialog instance to be used in called Modal
    modalRef.componentInstance.game = game;
    modalRef.componentInstance.lobbyPath = LOBBY_PATHS[game.lobbies[0]];

    this.gameInfoOpened.emit();
  }
}
