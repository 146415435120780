<!-- IFRAME CONTAINER -->
<div #iframeGamePlayContainer id="iframeGamePlayContainer">
  <app-quick-bar></app-quick-bar>
  <div *ngIf="!isCroClient" [ngClass]="{'no-jackpots': !jackpots?.length}" class="header">
    <ng-container *ngTemplateOutlet="noBonusContainer"></ng-container>
    <div
      (click)="closeDialog()"
      class="close"
      [ngClass]="{'no_jackpots': !(jackpots && jackpots.length), 'isCroClient': isCroClient}"
    >
      &#x2715;
    </div>
  </div>

  <div *ngIf="isCroClient" class="bonus-info row no-gutters align-items-center">
    <div class="col-auto">
      <img (click)="closeDialog()" class="m-3" role="button" src="assets/icons/closeIcon.svg" alt="cup" />
    </div>
    <div class="col row justify-content-around header-content">
      <ng-container *ngIf="hasBonus; else noBonusContainer">
        <div class="header-line"></div>
        <div class="pt-3 pb-1">
          <p class="mb-1 text-uppercase">{{"BONUS" | translate}}</p>
          <p class="mb-0 font-weight-bold amount">
            {{balanceInfo.bonus | number : '1.2-2' | currency : balanceInfo?.currencyIso}}
          </p>
        </div>
        <div class="bonus-required pt-3 pb-1">
          <p class="mb-1">{{"BONUS_REQUIRED" | translate}}</p>
          <p class="mb-0 amount">
            {{balanceInfo.spentDiffAmount | number : '1.2-2' | currency : balanceInfo?.currencyIso}}
          </p>
        </div>
      </ng-container>
    </div>
    <div class="col-auto">
      <img
        (click)="toggleFullscreen()"
        role="button"
        class="m-3"
        src="{{ isFullScreen ? 'assets/icons/fullscreenClose.svg' : 'assets/icons/fullscreenOpen.svg' }}"
        alt="cup"
      />
    </div>
  </div>

  <ng-template #noBonusContainer>
    <app-jackpot-v2
      class="w-100"
      *ngIf="jackpots && jackpots.length"
      [isGameFavorite]="game.favorite"
      [gameName]="game.name"
      [gameId]="game.id"
    >
    </app-jackpot-v2>

    <!-- INFO CONTAINER -->
    <div
      [ngClass]="{'isCroClient': isCroClient, 'border-bottom-glow': !isCroClient && !jackpots?.length}"
      class="infoContainer"
      *ngIf="!jackpots?.length"
    >
      <div *ngIf="!(jackpots && jackpots.length)" class="game">
        <div class="gameName">{{game.name}}</div>
        <span
          class="favoriteStar"
          [inlineSVG]="isCroClient ? 'assets/icons/heart-active.svg' : 'assets/images/star-solid.svg'"
          fallbackSVG="assets/images/default-left-menu-icon.svg"
          *ngIf="isLoggedIn"
          [ngClass]="{'active': game.favorite}"
        ></span>
      </div>
    </div>
  </ng-template>

  <!-- IFRAME CASINO GAMES -->
  <iframe
    *ngIf="!game.geolocation && !isCroClient"
    #iframe
    [ngClass]="{ 'no_jackpots': !(jackpots && jackpots.length) }"
    id="gamePlayIframe"
    frameborder="0"
    allow="autoplay; fullscreen; clipboard-write"
    (load)="onIframeLoad()"
  ></iframe>

  <iframe
    *ngIf="!game.geolocation && isCroClient"
    #iframe
    [ngClass]="{ 'no_jackpots': !(jackpots && jackpots.length) }"
    id="gamePlayIframe"
    frameborder="0"
    allow="autoplay"
    (load)="onIframeLoad()"
  ></iframe>

  <!-- IFRAME POKER WITH GEOLOCATION -->
  <iframe
    *ngIf="game.geolocation"
    #iframe
    [ngClass]="{ 'no_jackpots': !(jackpots && jackpots.length) }"
    id="gamePlayIframe"
    frameborder="0"
    allow="geolocation"
    (load)="onIframeLoad()"
  ></iframe>

  <!-- OVERLAY SWIPE CONTAINER -->
  <div *ngIf="showOverlaySwipeContainer" id="overlaySwipeContainer">
    <div class="controls">
      <table>
        <tbody>
          <tr>
            <td class="left">
              <div (click)="closeDialog()" class="back">
                <span class="icon icon-arrow-left"></span><br />
                <span class="title" innerHTML="{{'OVERLAY_BACK_TO_LOBBY' | translate}}"></span>
              </div>
            </td>

            <!-- IOS -->
            <td *ngIf="isIos" class="center">
              <div class="swipeUp"><span class="icon icon-hand"></span><br /></div>
              <div class="title">{{'OVERLAY_SWIPE_UP' | translate}}</div>
            </td>

            <!-- ANDROID -->
            <td *ngIf="!isIos" class="center">
              <div (click)="requestFullScreen()" class="title">{{'CLICK_FOR_FULL_SCREEN' | translate}}</div>
            </td>

            <td class="right">
              <div (click)="setFavoriteGame()" class="fav">
                <span *ngIf="!game.favorite" class="icon icon-heart"></span>
                <span *ngIf="game.favorite" class="icon icon-heart-pulse"></span><br />
                <span
                  innerHTML="{{'OVERLAY_ADD_TO_FAVORITES' | translate}}"
                  class="title"
                  *ngIf="!game.favorite"
                ></span>
                <span
                  innerHTML="{{'OVERLAY_REMOVE_FROM_FAVORITES' | translate}}"
                  class="title"
                  *ngIf="game.favorite"
                ></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="showDeviceRotationOverlay" class="force-device-rotation">
    <img
      src="assets/images/rotate-device.svg"
      class="splash"
      [ngClass]="isIpad ? 'ipad-overlay-size': 'iphone-overlay-size'"
      alt="Rotate device"
    />
  </div>
</div>
