export enum DEPOSIT_CODES {
  BANK_DEPOSIT = 'BANK_DEPOSIT',
  ALLSECURE_DEPOSIT = 'ALLSECURE_DEPOSIT',
  MPAY_DEPOSIT = 'MPAY_DEPOSIT',
  CORVUSPAY_DEPOSIT = 'CORVUSPAY_DEPOSIT',
  CORVUSPAY_DEPOSIT_MAESTRO = 'CORVUSPAY_DEPOSIT_MAESTRO',
  CORVUSPAY_DEPOSIT_VISA = 'CORVUSPAY_DEPOSIT_VISA',
  CORVUSPAY_DEPOSIT_MASTERCARD = 'CORVUSPAY_DEPOSIT_MASTERCARD',
  CORVUSPAY_IBAN_DEPOSIT = 'CORVUSPAY_IBAN_DEPOSIT',
  PAYSTACK_DEPOSIT = 'PAYSTACK_DEPOSIT',
  XBON_DEPOSIT = 'XBON',
  KLIKER_DEPOSIT = 'KLIKER',
  YO_PAYMENT_DEPOSIT = 'YO_PAYMENT_DEPOSIT',
  ADMIRAL_360_DEPOSIT = 'NXCS_DEPOSIT',
  AIRTEL_DEPOSIT = 'AIRTEL_DEPOSIT',
  PAYSPOT_DEPOSIT = 'PAYSPOT_DEPOSIT',
  ADMIRAL_DEPOSIT = 'ADMIRAL_DEPOSIT',
  FLUTTERWAVE_DEPOSIT = 'FLUTTERWAVE_DEPOSIT',
  NOVABANKA_DEPOSIT = 'NOVABANKA_DEPOSIT',
  SKRILL_DEPOSIT = 'SKRILL_DEPOSIT',
  AIRCASH_DEPOSIT = 'AIRCASH_DEPOSIT',
  ABON_DEPOSIT = 'ABON_DEPOSIT',
  RESERVED_FUNDS_DEPOSIT = 'RESERVED_FUNDS_DEPOSIT',
  A_VOUCHER_DEPOSIT = 'A_VOUCHER_DEPOSIT',
  GLOVO_DEPOSIT = 'GLOVO_DEPOSIT',
  VOUCHER_DEPOSIT = 'VOUCHER_DEPOSIT',
  ALTAPAY_DEPOSIT = 'ALTAPAY_DEPOSIT',
  SBON_DEPOSIT = 'SBON_DEPOSIT',
  PINNBET_DEPOSIT = 'PINNBET_DEPOSIT',
  SETTLE_DEPOSIT = 'SETTLE_DEPOSIT',
  IPS_DEPOSIT = 'IPS_DEPOSIT',
  MTN_DEPOSIT = 'MTN_DEPOSIT',
  NUVEI_DEPOSIT = 'NUVEI_DEPOSIT',
  NUVEI_CREDIT_CARD = 'NUVEI_CREDIT_CARD',
  NUVEI_GOOGLEPAY = 'NUVEI_GOOGLEPAY',
  OKTO_DEPOSIT = 'OKTO_DEPOSIT',
}
