import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TermsConditionsTextComponent } from '../../components/terms-conditions-text/terms-conditions-text.component';
import { PoliticalExposureFormComponent } from '../../components/political-exposure-form/political-exposure-form.component';
import { CLIENT_NAMES } from '@enums/client-names.enum';

/*

Example DATA:

    const data = {
      status,
      title,
      innerHTML: true / false,  --> possibility to inject HTML element in message input
      message,
      component, --> possibility to pass and inject another component
      componentInstanceType, --> used to determine different instances of same component
      closeButtonDeactivated --> determines visibility of close mark
      isCloseMarkBig --> determines size of close mark

      inputs: [
        {
          key: '',
          value: '',
        },
      ],
      buttons: [
        {
          label: '',
          label_input: FOR SOME DATA TO BE IN INPORTED IN LABEL,
          type: '',
          on_click: RESPONSE FROM MODAL >>> close-event
        },
      ],
      links: [
        {
        linkDescription: translate.instant('WITHDRAWAL_DISABLED_LINK_3'),
        linkClass: 'c_additional-data', // optional class for link button
        linkText: translate.instant('WITHDRAWAL_DISABLED_LINK_3_TEXT'),
        url: './url',
        }
      ],
      checkbox: [
        {
          id: number, will be used for set result set list
          label: this.translate.instant('MESSAGE'),
        },
      ],
    };

*/
@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom.dialog.html',
  styleUrls: ['./custom.dialog.scss'],
})
export class CustomDialog implements OnInit {
  @Input() data: any;

  @ViewChild('componentViewContainer', { read: ViewContainerRef, static: true })
  private componentViewContainer: ViewContainerRef;

  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public disableButton = false;
  private checkboxResults = [];

  constructor(private activeModal: NgbActiveModal) {}

  public closeDialog(close_event): void {
    if (this.checkboxResults.length) {
      const eventWithCheckbox = {
        close_event,
        checkbox_result_set: this.checkboxResults,
      };
      this.activeModal.close(eventWithCheckbox);
    } else {
      this.activeModal.close(close_event);
    }
  }

  // set checkbox result in result set to be send with close dialog event
  public setCheckboxResult(checkboxParentObj): void {
    // get checkbox value
    const checkbox = document.querySelector(`.checkboxInput-${checkboxParentObj.id}`) as HTMLInputElement;
    const checkboxHtmlValue = checkbox.checked;
    const checkboxResult = { id: checkboxParentObj.id, value: checkboxHtmlValue };

    // check if value exist in result list... it will exist if we already click on some checkbox
    const index = this.checkboxResults.findIndex(listItem => listItem.id === checkboxParentObj.id);

    if (index === -1) {
      // if checkbox value do not exist, add value to result list
      this.checkboxResults.push(checkboxResult);
    } else {
      // if value already is set to list, just replace it with new value
      this.checkboxResults[index] = checkboxResult;
    }
  }

  ngOnInit(): void {
    if (this.data.component) {
      const component = this.componentViewContainer.createComponent(this.data.component);

      if (component.instance instanceof TermsConditionsTextComponent) {
        component.instance.location = 'DIALOG';
      }

      if (component.instance instanceof PoliticalExposureFormComponent) {
        this.data.component = component.instance;
        const sub: Subscription = component.instance.buttonDisabled.subscribe(
          disabled => (this.disableButton = disabled)
        );
        component.onDestroy(() => {
          sub.unsubscribe();
        });
      }
    }

    // If message for dialog have HTML tags we switch to innerHTML mode for creating messages
    this.data.innerHTML = /(<a\b[^>]*>)|(\<br>)|(\<\w*)((\s\/\>)|(.*\<\/\\w*\>))/.test(this.data.message);
  }
}
