<div class="gamesContainer" [ngClass]="{ mobile: isMobile }">
  <div [ngClass]="{ mobile: isMobile }" class="gamesRow">
    <div
      class="rounded gameContainer gameLarge"
      *ngFor="let game of tournament?.games"
      (appDelayLoad)="game.visible = true"
    >
      <div class="gameImgWrapper rounded-lg">
        <!-- GAME ICON IMAGE -->
        <app-image-wrapper
          class="w-100"
          [width]="120"
          [height]="120"
          [alt]="game.name"
          [progressiveLoad]="true"
          [costumeLowResSrc]="
            game.lowResGameIconLocation ? environment.imagesBaseUrl + game.lowResGameIconLocation : ''
          "
          [src]="
            imgSource === 'img'
              ? game.gameIconLocation
                ? environment.imagesBaseUrl + game.gameIconLocation
                : ''
              : game.mobileGameIconLocation
              ? environment.imagesBaseUrl + game.mobileGameIconLocation
              : ''
          "
          (click)="navigateToGameInfo(game)"
          *ngIf="game.visible"
          [costumeClass]="'rounded-lg img-fluid gameIconCasinoGamePage ' + pointerClass"
        ></app-image-wrapper>

        <div class="game-label-wrapper" [ngSwitch]="language">
          <div *ngSwitchCase="'sr'">
            <div class="game-label novo-label" *ngIf="game.newGame"></div>
          </div>
          <div *ngSwitchCase="'en'">
            <div class="game-label new-label" *ngIf="game.newGame"></div>
          </div>
          <div *ngSwitchDefault>
            <div class="game-label novo-label" *ngIf="game.newGame"></div>
          </div>
        </div>

        <!-- HOT ICON -->
        <div class="game-badges-wrapper">
          <div class="game-badge hot-icon" *ngIf="game.popular"></div>
          <div
            class="game-badge tournament-icon"
            [ngClass]="{ 'tournament-icon--is-cro': environment.clientName === clientNames.ADMIRAL_CROATIA }"
            *ngIf="game.tournament"
          ></div>
          <div class="game-badge jackpot-icon" *ngIf="game.jackpot"></div>
        </div>
        <!-- GAME ICON BACKGROUND -->
        <img *ngIf="!game.visible" [style.background-color]="game.backgroundColor" [src]="" />

        <div *ngIf="!isMobile" class="buttonContainer buttonHover">
          <button
            (click)="openGame(game, tournament)"
            class="btn btn-block btn-primary btn-bold text-uppercase"
          >
            {{ 'PLAY' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
