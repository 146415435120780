import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IpsDepositService } from '@services/ips-deposit.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Deposit } from '@models/deposit.model';
import { Subscription } from 'rxjs';
import { Player } from '@models/player.model';
import { PaymentType } from '@models/ips-deposit.model';
import { ToasterService } from '@services/toaster.service';
import { DEPOSIT_CODES } from '@enums/deposit-codes.enum';
import { DepositService } from '@services/deposit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-bar-ips-qrcode',
  templateUrl: './quick-bar-ips-qrcode.component.html',
  styleUrls: ['./quick-bar-ips-qrcode.component.scss'],
})
export class QuickBarIpsQRCodeComponent implements OnInit, OnDestroy {
  public deposit: Deposit;
  public depositSubscription: Subscription;
  private isDepositFinishedSubscription: Subscription;

  private timeLeftSubscription: Subscription;
  public timeLeft: number; // In seconds.
  public qrCode = '';

  @Input() isMobile = false;
  @Input() depositAmount!: number;
  @Input() paymentProceed!: boolean;
  @Input() playerData!: Player;
  @Input() clientName!: string;
  @Input() expandCollapseQuickBar!: (stateOveride?) => void;
  @Input() closeQuickBar!: () => void;
  @Input() stopIntervalChangeItemsInBar!: () => void;
  @Input() paymentSuccess!: () => void;

  constructor(
    private ipsDepositService: IpsDepositService,
    private toasterService: ToasterService,
    private depositService: DepositService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.setDepositSubscription();
    this.getDepositConfig();
    this.checkActivePaymentState();
    this.setTimeLeftSubscription();
    this.setDepositFinishedSubscription();
  }

  ngOnDestroy(): void {
    this.depositSubscription.unsubscribe();
    this.timeLeftSubscription.unsubscribe();
    this.isDepositFinishedSubscription.unsubscribe();
  }

  private getDepositConfig(): void {
    this.depositService.getDeposit(DEPOSIT_CODES.IPS_DEPOSIT).subscribe((deposit: Deposit) => {
      if (deposit) {
        this.deposit = deposit;
        this.ipsDepositService.setDepositConfig(deposit);
      } else {
        this.router.navigate(['/profile/deposit']);
      }
    });
  }

  generateQRCode(): void {
    const dataToSend = { amount: this.depositAmount };
    this.ipsDepositService.generateIpsDepositData(this.deposit.url, false, dataToSend).subscribe({
      next: data => {
        this.qrCode = data.message;
        this.startCountdown();
      },
    });
  }

  private setDepositSubscription() {
    this.depositSubscription = this.ipsDepositService
      .getDepositConfigObservable()
      .subscribe((depositConfig: Deposit) => {
        this.deposit = depositConfig;
      });
  }

  private checkActivePaymentState(): void {
    const activePaymentData = this.ipsDepositService.getActivePaymentData();
    if (activePaymentData && activePaymentData.paymentType === PaymentType.QR_CODE) {
      this.qrCode = activePaymentData.paymentData;
    }
  }

  // Disable amount input field and initiate countdown.
  private startCountdown(): void {
    this.ipsDepositService.initCountdown({
      paymentType: PaymentType.QR_CODE,
      amount: this.depositAmount.toString(),
      paymentData: this.qrCode,
      rpReference: this.ipsDepositService.extractRpReference(this.qrCode, 'RP:', '|'),
    });
  }

  private setTimeLeftSubscription(): void {
    this.timeLeftSubscription = this.ipsDepositService
      .getTimeLeftObservable()
      .subscribe((timeLeft: number) => {
        if (timeLeft !== null) {
          this.timeLeft = timeLeft;
          if (timeLeft === 0) {
            this.qrCode = '';
          }
        }
      });
  }

  private setDepositFinishedSubscription(): void {
    this.isDepositFinishedSubscription = this.ipsDepositService
      .getIsDepositFinishedObservable()
      .subscribe((isDepositFinished: string) => {
        if (isDepositFinished) {
          setTimeout(() => {
            this.toasterService.showSuccess('Payment successful');
            this.expandCollapseQuickBar();
            this.ipsDepositService.resetDepositFinishedValue();
          }, 0);
        }
      });
  }
}
