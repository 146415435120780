<div class="box">
  <header class="pb-2">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{'DEPOSIT_STATUS' | translate}}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close">
          <img [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'" alt="" (click)="closeModal()" />
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3 h3 text-center">
      <div class="text-uppercase status-success" *ngIf="data?.status === 'SUCCESS'">
        {{'DEPOSIT_STATUS_SUCCESSFUL' | translate}}
      </div>
      <div class="text-uppercase status-pending" *ngIf="data?.status === 'PENDING'">
        {{'DEPOSIT_STATUS_PENDING' | translate}}
      </div>
      <div class="text-uppercase status-error" *ngIf="data?.status === 'ERROR'">
        {{'DEPOSIT_STATUS_UNSUCCESSFUL' | translate}}
      </div>
    </div>
  </header>
  <!-- SUCCESS STATUS -->
  <div class="px-5 pt-3" *ngIf="data?.status === 'SUCCESS'">
    <div *ngIf="data?.amount" class="row mb-2">
      <div class="col-4">
        <span class="text-muted">{{data?.amount | number: '1.2-2' | currency: data.currency }}</span>
      </div>
      <div class="col-8 text-right">
        <span class="text-light"> {{ 'DEPOSIT_STATUS_SUCCESSFUL' | translate }}</span>
      </div>
    </div>
    <div *ngIf="!data?.amount" class="row mb-2">
      <div class="col-12 text-center">{{ 'DEPOSIT_STATUS_SUCCESSFUL' | translate }}</div>
    </div>
  </div>
  <!-- PENDING STATUS -->
  <div class="px-5 pt-3" *ngIf="data?.status === 'PENDING'">
    <div class="row mb-2">
      <div class="col-12 text-center">{{ 'DEPOSIT_STATUS_PENDING' | translate }}</div>
    </div>
  </div>
  <!-- ERROR STATUS -->
  <div class="px-5 pt-3" *ngIf="data?.status === 'ERROR'">
    <div class="row mb-2">
      <div class="col-12 text-center">{{data?.message}}</div>
    </div>
  </div>
  <hr />
  <div class="py-1 text-center">
    <div (click)="closeModal()" class="btn btn-outline-light px-6">OK</div>
  </div>
</div>