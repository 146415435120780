<div class="gameContainer">
  <div class="gameImgWrapper rounded-lg">
    <!-- GAME ICON IMAGE -->
    <app-image-wrapper
      class="w-100"
      [width]="120"
      [height]="120"
      [alt]="game.name"
      [progressiveLoad]="true"
      [costumeLowResSrc]="
        game.lowResGameIconLocation ? environment.imagesBaseUrl + game.lowResGameIconLocation : ''
      "
      [src]="
        imageType === 'img'
          ? game.gameIconLocation
            ? environment.imagesBaseUrl + game.gameIconLocation
            : ''
          : game.mobileGameIconLocation
          ? environment.imagesBaseUrl + game.mobileGameIconLocation
          : ''
      "
      (click)="openGameInfoDialog(game)"
      [costumeClass]="'rounded-lg img-fluid gameIconCasinoGamePage'"
    ></app-image-wrapper>
    <!--    GAME INFO BAR FOR MNE -->
    <div *ngIf="gameInfoBarDisplay" class="c_bottom-info-bar">
      <ng-container *ngIf="activePlayersNumber | async">
        <i
          inlineSVG="assets/images/players.svg"
          [setSVGAttributes]="{ height: '14px' }"
          fallbackSVG="assets/images/default-left-menu-icon.svg"
        ></i>
        {{ activePlayersNumber | async }}
      </ng-container>
    </div>
    <!-- GAME ICON BACKFROUND -->
    <div *ngIf="!game.visible" [style.background-color]="game.backgroundColor"></div>

    <div *ngIf="!isMobile" class="buttonContainer buttonHover">
      <!-- PLAY FOR CASH BUTTON -->
      <button
        (click)="navigateToDesktopGamePlay(game, gameType.FOR_REAL)"
        class="btn btn-block btn-primary btn-bold text-uppercase"
      >
        {{ 'PLAY' | translate }}
      </button>

      <!-- PLAY FOR FUN BUTTON -->
      <button
        (click)="navigateToDesktopGamePlay(game, gameType.FOR_FUN)"
        class="btn btn-grey btn-block text-uppercase btn-bold"
        *ngIf="game.demoIncluded"
      >
        {{ 'DEMO' | translate }}
      </button>
    </div>

    <!-- NEW LABEL ICON -->
    <div class="game-label-wrapper" [ngSwitch]="language">
      <div *ngSwitchCase="'sr'">
        <div class="game-label novo-label" *ngIf="game.newGame"></div>
      </div>
      <div *ngSwitchCase="'en'">
        <div class="game-label new-label" *ngIf="game.newGame"></div>
      </div>
      <div *ngSwitchDefault>
        <div class="game-label novo-label" *ngIf="game.newGame"></div>
      </div>
    </div>

    <!-- BADGES -->
    <div class="game-badges-wrapper">
      <div class="game-badge hot-icon" *ngIf="game.popular"></div>
      <div
        class="game-badge tournament-icon"
        [ngClass]="{ 'tournament-icon--is-cro': environment.clientName === clientNames.ADMIRAL_CROATIA }"
        *ngIf="game.tournament"
      ></div>
      <div class="game-badge jackpot-icon" *ngIf="game.jackpot"></div>
    </div>
  </div>
</div>
