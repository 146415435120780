import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deposit-status',
  templateUrl: './deposit-status.dialog.html',
  styleUrls: ['./deposit-status.dialog.scss'],
})
export class DepositStatusDialog {
  @Input() data: any;
  public imagesBaseUrl = environment.imagesBaseUrl;

  constructor(public activeModal: NgbActiveModal) {}

  // close modal
  public closeModal(): void {
    this.activeModal.close();
  }
}
