import { DesktopMenus, MobileMenus } from './menu/app-menus.model';
import { Header } from './header.model';
import { Styles } from './styles/styles.model';
import { Footer } from './footer/footer.model';
import { Registration } from '@models/config-data/registration';
import { Signd } from '@models/config-data/signd';
import { environment } from 'src/environments/environment';

export class ConfigData {
  public backgroundImage: string;
  public defaultAppUrl: string;
  public displayCarousel: boolean;
  public registration: Registration;
  public footer: Footer;
  public footerV2: string;
  public generalV2: string;
  public sideMenuV2: string;
  public header: Header;
  public headerV2: string;
  public logo: string;
  public styles: any;
  public signd: Signd;
  public apiDateFormat: string;
  public desktopMenus: DesktopMenus;
  public mobileMenus: MobileMenus;
  public appMenus: DesktopMenus | MobileMenus;

  constructor(data, isMobile: boolean) {
    this.backgroundImage = environment.imagesBaseUrl + data.backgroundImage;
    this.defaultAppUrl = data.defaultAppUrl;
    this.displayCarousel = data.displayCarousel;
    this.footerV2 = data.footerV2;
    this.generalV2 = data.generalV2;
    this.headerV2 = data.headerV2;
    this.generalV2 = data.generalV2;
    this.sideMenuV2 = data.sideMenuV2;
    this.registration = new Registration(data.mbaseConfig.registration);
    data.footer && (this.footer = new Footer(data.footer));
    data.header && (this.header = new Header(data.header));
    this.logo = data.logo;
    this.styles = new Styles(data.styles);
    this.signd = new Signd(data.mbaseConfig.signd);
    this.apiDateFormat = data.mbaseConfig.apiDateFormat;
    data.desktopMenus && (this.desktopMenus = new DesktopMenus(data.desktopMenus));
    data.mobileMenus && (this.mobileMenus = new MobileMenus(data.mobileMenus));
    this.appMenus = isMobile ? this.mobileMenus : this.desktopMenus;
  }
}
