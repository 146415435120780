import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Directive uses Intersection Observer API, for observing changes in the intersection of a target element with
 * document's viewport. It can be used, for example, to lazy-load images or other content as a page is scrolled.
 *
 * Usage: <div (appDelayLoad)="doSomethingOnIntersectionEvent($event)"></div>
 */
@Directive({
  selector: '[appDelayLoad]',
})
export class DelayLoadDirective implements OnInit, OnDestroy, AfterViewInit {
  private intersectionObserver?: IntersectionObserver;

  @Output() public appDelayLoad: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (!this.isBrowserCompatible()) {
      this.appDelayLoad.emit();
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowserCompatible()) {
      if (!this.intersectionObserver) {
        this.createIntersectionObserver();
      }

      if (this.intersectionObserver && this.elementRef.nativeElement) {
        // Add element to the set of target elements being watched by the IntersectionObserver.
        this.intersectionObserver.observe(this.elementRef.nativeElement as Element);
      }
    }
  }

  public isBrowserCompatible(): boolean {
    return 'IntersectionObserver' in window;
  }

  private createIntersectionObserver(): void {
    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      // Check for intersection.
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting && entry.target === this.elementRef.nativeElement) {
          this.appDelayLoad.emit();
          // As soon as notifying event is emitted, observer should stop observing the specified target element.
          if (this.intersectionObserver && this.elementRef.nativeElement) {
            this.intersectionObserver.unobserve(this.elementRef.nativeElement as Element);
          }
        }
      });
    };

    this.intersectionObserver = new IntersectionObserver(intersectionCallback, {});
  }

  ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
  }
}
