import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Observable, interval, combineLatest } from 'rxjs';
import { Player } from 'src/app/shared/models/player.model';
import { filter, map } from 'rxjs/operators';
import { ConfigData } from '@models/config-data/config-data.model';
import { environment } from 'src/environments/environment';
import { Menu } from '@models/config-data/menu/menu.model';
import { NavigationService } from '@services/navigation.service';
import { HelpersService } from '@services/helpers.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { CookieService } from 'ngx-cookie';
import { AppHtmlElementsService } from '@services/app-html-elements.service';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { MessagesInterface } from '../../shared/state/interfaces/AppStateInterface.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() player: Player;
  @ViewChild('headerContainer') headerContainer: ElementRef;
  public btnClass: string;
  public unreadMessages: number;
  public CLIENT_NAMES = CLIENT_NAMES;
  public showRegistrationBtn = true;
  public clock = interval(1000).pipe(map(() => new Date())); // clock implementation
  public playerRegistrationStep;
  public showMenuUserIcon = true;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public showContinueRegistrationBtn: boolean;

  // Config
  public mainMenu: Menu;
  public logoImg: string;
  public environment = environment;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public unauthorizedUser: boolean;

  // Observables
  private routerObservable: Observable<any>;
  private unreadMessagesObservable: Observable<any>;
  private configDataObservable: Observable<any>;
  private playerDataObservable: Observable<Player>;

  // Subscriptions
  private unreadMessages$: Subscription;
  private configData$: Subscription;
  private playerDataBlinkingServiceStatus$: Subscription;

  constructor(
    private helpersService: HelpersService,
    public navigationService: NavigationService,
    private appStateFacadeService: AppStateFacadeService,
    private cookieService: CookieService,
    private appHtmlElementsService: AppHtmlElementsService
  ) {
    this.setupStore();
  }

  ngOnInit(): void {
    this.setConfigDataSubscription();
    this.setUnreadMessagesSubscription();
    this.setPlayerSubscription();
    this.checkCookies();
  }

  ngAfterViewInit() {
    this.appHtmlElementsService.headerContainerElement = this.headerContainer.nativeElement;
    this.appHtmlElementsService.setHeaderContainerStatus();
  }

  ngOnDestroy() {
    this.configData$.unsubscribe();
    this.unreadMessages$.unsubscribe();
    this.playerDataBlinkingServiceStatus$.unsubscribe();
  }

  // setup store
  private setupStore(): void {
    this.unreadMessagesObservable = this.appStateFacadeService.getMessagesObservable();
    this.configDataObservable = this.appStateFacadeService.getConfigData();
    this.playerDataObservable = this.appStateFacadeService.getPlayerDataObservable();
    this.routerObservable = this.appStateFacadeService.observeCurrentUrlStatus();
  }

  // set config subscription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable.subscribe((configData: ConfigData) => {
      // sometimes configData is null at first and in that case we GET https://admiralbet.rsundefined/ net::ERR_NAME_NOT_RESOLVED
      // where undefined is result of previous logic configData?.logo
      if (configData) {
        this.logoImg = environment.imagesBaseUrl + configData.logo;
        this.mainMenu = configData.appMenus.mainMenu;
        this.appStateFacadeService.setLogoImgPath(this.logoImg);
      }
    });
  }

  private setUnreadMessagesSubscription(): void {
    this.unreadMessages$ = this.unreadMessagesObservable.subscribe((data: MessagesInterface) => {
      this.unreadMessages = data.total;
    });
  }

  private setPlayerSubscription(): void {
    const userAndBlinking = combineLatest([
      this.playerDataObservable.pipe(filter(data => !!data)),
      this.appStateFacadeService.getBlinkingServiceStatus(),
    ]);
    this.playerDataBlinkingServiceStatus$ = userAndBlinking.subscribe({
      next: ([userData, blinkingStatus]) => {
        this.unauthorizedUser = this.helpersService.blinkingMarkerState(userData, blinkingStatus);
      },
    });
  }

  // check cookies
  private checkCookies(): void {
    const cookie = this.cookieService.get('cookie');
    const idsCookieArray = this.cookieService.get('_pc');
    if (cookie && idsCookieArray) {
      this.showRegistrationBtn = false;
    }
  }

  logoImageClassCreator() {
    const class1 = CLIENT_NAMES.ADMIRAL_NIGERIA === environment.clientName ? `biggerImg` : '';
    const class2 =
      CLIENT_NAMES.ADMIRAL_NIGERIA === environment.clientName && this.showRegistrationBtn
        ? `biggerImg--is-regBtnActive`
        : '';
    return `${class1} ${class2}`;
  }

  public handleLeftSideBar() {
    this.appStateFacadeService.expandCollapseLeftSideBar();
  }

  public handleRightSideBar() {
    const chatWindowOpen = this.appStateFacadeService.isChatWindowOpen();
    this.appStateFacadeService.setChatWindowWasOpenBeforeRightBarExpand(chatWindowOpen);
    this.appStateFacadeService.expandCollapseRightSideBar();
  }
}
