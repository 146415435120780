<div class="clearfix">
  <div class="box">
    <app-payment-card-detail-header
      backUrl="isWithdrawal ? ['profile/withdrawal'] : ['profile/deposit']"
      [paymentTitle]="reserved_payment.name"
    >
    </app-payment-card-detail-header>
    <div class="py-4">
      <!-- ARROW -->
      <div class="row">
        <div class="col-4">{{ 'ONLINE' | translate }}</div>
        <div class="col-4 text-center">
          <div
            class="arrow"
            inlineSVG="assets/images/admiral-360-arrow.svg"
            [ngClass]="{ arrowRotate: isWithdrawal }"
            (click)="toggleSide()"
          ></div>
        </div>
        <div class="col-4 text-right text-uppercase">{{ 'RESERVED' | translate }}</div>
      </div>
      <!-- VALUES -->
      <div class="row">
        <div class="col-6 text-bold" [ngClass]="{ 'text-tertiary': !isWithdrawal }">
          {{ balanceInfo?.availableForWithdrawal | number: '1.2-2' | currency: player?.currency }}
        </div>
        <div class="col-6 text-right text-bold" [ngClass]="{ 'text-tertiary': isWithdrawal }">
          {{ reservedBalance | number: '1.2-2' | currency: player?.currency }}
        </div>
      </div>

      <br /><br />

      <!-- PAYMENT DETAILS -->
      <form [formGroup]="reservedForm" (ngSubmit)="onFormSubmit()">
        <app-payment-card-detail-predefined-values
          *ngIf="reserved_payment"
          [payment]="reserved_payment"
          [paymentForm]="reservedForm"
          [predefinedValues]="reserved_payment.predefinedValues"
          (setPredefinedValueALL)="setPredefinedValueAllAvailableAmount()"
          buttonText="{{ 'TRANSFER_TO' | translate }} {{
            (isWithdrawal ? 'RESERVED' : 'TICKETS.MONEY') | translate
          }}"
        >
        </app-payment-card-detail-predefined-values>
      </form>
    </div>
  </div>
</div>

<!-- LOADER -->
<div *ngIf="isLoading" class="spinner-border spinner" role="status"></div>
