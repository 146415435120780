import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
/**
 * It is important how input are set in parent component for example:
 * [itemsPerLoad]="5" [length]="this.config.totalItems" bcs
 * length use data from itemsPerLoad and need to be first in line
 */
@Component({
  selector: 'app-show-more-pagination-button',
  templateUrl: './show-more-pagination-button.component.html',
  styleUrls: ['./show-more-pagination-button.component.scss'],
})
export class ShowMorePaginationButtonComponent implements OnInit {
  private numberOfItems = 10;
  private numberOfPages = 1;
  private activePageLoaded = 0;
  public visibleStatus: boolean;
  @Input() backOnTop = false;
  @Input() scrollableContainer;

  @Input() set itemsPerLoad(numberOfItems) {
    if (numberOfItems) {
      this.numberOfItems = numberOfItems;
    }
  }

  @Input() set length(lengthData) {
    if (lengthData && this.numberOfItems) {
      this.numberOfPages = Math.floor(lengthData / this.numberOfItems);
    }
  }

  @Input() resetPagination: Subject<boolean>;
  @Input() set visible(status: boolean) {
    this.visibleStatus = status;
  }

  @Output() loadMore = new Subject();

  constructor() {}

  ngOnInit(): void {
    if (this.resetPagination) {
      this.resetPagination.subscribe(boolean => {
        if (boolean) {
          this.activePageLoaded = 0;
          this.numberOfPages = 1;
        }
      });
    }
  }

  public loadMoreEvent() {
    if (this.activePageLoaded + 1 <= this.numberOfPages) {
      this.activePageLoaded++;
      this.loadMore.next(this.activePageLoaded);
    }
  }

  public scrollOnTop() {
    if (this.scrollableContainer) {
      this.scrollableContainer.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }
}
