<div class="clearfix">
  <div class="box">
    <app-payment-card-detail-header
      backUrl="isWithdrawal ? ['profile/withdrawal'] : ['profile/deposit']"
      [paymentTitle]="isWithdrawal ? admiral360Withdrawal?.name : admiral360Deposit?.name"
    >
    </app-payment-card-detail-header>
    <div class="py-4">
      <ng-content *ngTemplateOutlet="admiral360BalanceInfo ? admiral360 : noAdmiral360"></ng-content>
    </div>
  </div>
</div>

<!-- PLAYER HAVE ADMIRAL 360 ACCOUNT -->
<ng-template #admiral360>
  <!-- ARROW -->
  <div class="row">
    <div class="col-4">{{ 'ONLINE' | translate }}</div>
    <div class="col-4 text-center">
      <div
        class="arrow"
        inlineSVG="assets/images/admiral-360-arrow.svg"
        [ngClass]="{ arrowRotate: isWithdrawal }"
        (click)="toggleSide()"
      ></div>
    </div>
    <div class="col-4 text-right">{{ 'OFFICE' | translate }}</div>
  </div>
  <!-- VALUES -->
  <div class="row">
    <div class="col-6 text-bold" [ngClass]="{ 'text-tertiary': !isWithdrawal }">
      {{ balanceInfo.nxcsAvailableForWithdrawal | number: '1.2-2' | currency: player?.currency }}
    </div>
    <div class="col-6 text-right text-bold" [ngClass]="{ 'text-tertiary': isWithdrawal }">
      {{ admiral360BalanceInfo?.balance | number: '1.2-2' | currency: player?.currency }}
    </div>
  </div>

  <br /><br />

  <!-- PAYMENT DETAILS -->
  <form [formGroup]="admiral360Form" (ngSubmit)="onFormSubmit()">
    <app-payment-card-detail-predefined-values
      *ngIf="admiral360Withdrawal || admiral360Deposit"
      [payment]="isWithdrawal ? admiral360Withdrawal : admiral360Deposit"
      [paymentForm]="admiral360Form"
      [predefinedValues]="
        isWithdrawal ? admiral360Withdrawal?.predefinedValues : admiral360Deposit?.predefinedValues
      "
      (setPredefinedValueALL)="setPredefinedValueAllAvailableAmount()"
      buttonText="{{
        (isWithdrawal ? 'ADMIRAL_360_SEND_TO_OFFICE' : 'ADMIRAL_360_SEND_TO_ONLINE') | translate
      }}"
    >
    </app-payment-card-detail-predefined-values>
  </form>
</ng-template>

<!-- PLAYER DO NOT HAVE ADMIRAL 360 ACCOUNT -->
<ng-template #noAdmiral360>
  <div class="row">
    <div class="col-12">
      {{ 'ADMIRAL_360_NO_NXCS_ACCOUNT_TEXT' | translate }}
    </div>
  </div>
</ng-template>

<!-- LOADER -->
<div *ngIf="isLoading" class="spinner-border spinner" role="status"></div>
