import { Component, Input, OnInit } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-info',
  templateUrl: './redirect-info.dialog.html',
  styleUrls: ['./redirect-info.dialog.scss'],
})
export class RedirectInfoDialog implements OnInit {
  public imagesBaseUrl = environment.imagesBaseUrl;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  constructor(private activeModal: NgbActiveModal) {}

  @Input() depositName;
  @Input() redirectionUrl;
  @Input() message;
  @Input() redirectOnNewTab = false;

  ngOnInit(): void {
    this.setRedirectUrl();
  }

  // set redirect url
  setRedirectUrl() {
    const anchor = document.getElementById('redirectButton');
    if (anchor) {
      anchor.setAttribute('href', this.redirectionUrl);
      if (this.redirectOnNewTab) {
        anchor.setAttribute('target', '_blank');
      }
    }
  }

  // close dialog
  public closeDialog() {
    this.activeModal.close();
  }
}
