import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * @AdmiralButtonDirective decorate div or any link element to have unify admiral bet button look and feel
 * @Input type call presets for buttons
 * @Input text OPTIONAL! is translate key for button label
 * @icon OPTIONAL! it is possible to provide icon example:
 * <i class="btn_icon btn_icon--is-deposit"
 *    #depositIcon
 *    [inlineSVG]="'assets/icons/deposit_btn_icon.svg'">
 * </i>
 * Example of implementation :
 *            <div appAdmiralButton
 *              type="{{ BUTTON_TYPES.DEPOSIT }}"
 *              [icon]="depositIcon"
 *              (click)="
                 navigateToLinkInMenu({
                                type: 'INTERNAL_LINK_SUBMENU',
                                url: '/profile/deposit'
                              })
                 "
                 ></div>
 * Example of Button implementation :
 * <button
     appAdmiralButton
     [type]="'submit-btn-style'"
     [text]="'PERSONAL_DATA_SAVE'"
     [disabledBtn]="personalDataForm.invalid || personalDataForm.pristine"
     type="submit"
     [disabled]="personalDataForm.invalid || personalDataForm.pristine"
     class="btn btn-primary ml-2"
     >
     </button>
 */

export enum BUTTON_TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  CUSTOM = 'custom',
  DEPOSIT = 'deposit',
  LOGOUT = 'logout',
  OUTLINE_PRIMARY = 'outline-primary',
  SAVE_CHANGES = 'save-changes',
  // decorate submit button
  SUBMIT_BTN_STYLE = 'submit-btn-style',
}

@Directive({
  selector: '[appAdmiralButton]',
})
export class AdmiralButtonDirective implements OnInit {
  @Input() type: string = BUTTON_TYPES.PRIMARY;
  @Input() text: any;
  @Input() width = '167px';
  @Input() height = '40px';
  @Input() padding = '10px';
  @Input() fontFamily = 'var(--fontFamily)';
  @Input() textColor = '';
  @Input() fontSize = '14px';
  @Input() borderRadius = 'var(--btnPrimaryBorderRadius)';
  @Input() fontWeight = '600';
  @Input() textTransform = '';
  @Input() textAlign = 'center';
  @Input() outlineColor = 'center';
  @Input() backgroundColor = '';
  @Input() borderStyle = ''; // send border param in form of 'solid 1px COLOR'
  @Input() hoverBackground: boolean;

  @Input() set disabledBtn(state: boolean) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', `${state ? '0.5' : '1'}`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'pointer-events', `${state ? 'none' : 'all'}`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', `${state ? 'auto' : 'pointer'}`);
  }
  @Input() marginCostume = 'unset';
  @Input() icon: ElementRef;
  private css: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.css = `
      width: ${this.width};
      height: ${this.height};
      padding: ${this.padding};
      cursor: pointer;
      background-color: ${this.generateMainBtnColors().background};
      color: ${this.generateMainBtnColors().textColor};
      text-transform: ${this.textTransform};
      font-family: ${this.fontFamily};
      font-weight: ${this.fontWeight};
      font-size: ${this.fontSize};
      border: ${this.generateMainBtnColors().border};
      margin: ${this.marginCostume};
      border-radius: ${this.borderRadius};
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: row;
      white-space: nowrap;
      text-align: ${this.textAlign};
  `;

    this.elementRef.nativeElement.setAttribute('style', this.css);
    if (this.icon) {
      this.addicon();
    }
    this.assignListeners();
    this.createTextForBtn();
  }

  addicon() {
    const elementDiv = this.renderer.createElement('div');
    this.renderer.setStyle(elementDiv, 'margin-right', '14px');
    this.renderer.appendChild(elementDiv, this.icon);
    this.renderer.appendChild(this.elementRef.nativeElement, elementDiv);
  }

  createTextForBtn() {
    if (!this.text) {
      switch (this.type) {
        case BUTTON_TYPES.DEPOSIT:
          this.text = 'DEPOSIT';
          break;
        case BUTTON_TYPES.SAVE_CHANGES:
          this.text = 'SAVE_CHANGES';
          break;
        case BUTTON_TYPES.LOGOUT:
          this.text = 'LOG_OUT';
          break;
        case BUTTON_TYPES.SUBMIT_BTN_STYLE:
          this.text = 'SUBMIT';
          break;
        default:
          this.text = 'CANCEL';
          break;
      }
    }
    this.translateService.get(this.text).subscribe(textForBtn => {
      const elementDiv = this.renderer.createElement('div');
      const elementText = this.renderer.createText(textForBtn);
      this.renderer.appendChild(elementDiv, elementText);

      this.renderer.appendChild(this.elementRef.nativeElement, elementDiv);
    });
  }

  generateMainBtnColors(): {
    background: string;
    textColor: string;
    border: string;
  } {
    const colorOfBtn = {
      background: '',
      textColor: '',
      border: '',
    };
    switch (true) {
      case this.type === BUTTON_TYPES.PRIMARY:
        colorOfBtn['background'] = this.backgroundColor || 'var(--primaryColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--admiralButtonColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--primaryColor)';
        break;
      case this.type === BUTTON_TYPES.OUTLINE_PRIMARY:
        colorOfBtn['background'] = this.backgroundColor || 'transparent';
        colorOfBtn['textColor'] = this.textColor || 'inherit';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--primaryColor)';
        break;
      case this.type === BUTTON_TYPES.SECONDARY:
        colorOfBtn['background'] = this.backgroundColor || 'var(--secondaryColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--admiralButtonColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--secondaryColor)';
        break;
      case this.type === BUTTON_TYPES.SUCCESS:
        colorOfBtn['background'] = this.backgroundColor || 'var(--success)';
        colorOfBtn['textColor'] = this.textColor || 'var(--admiralButtonColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--success)';
        break;
      case this.type === BUTTON_TYPES.DEPOSIT:
        colorOfBtn['background'] = this.backgroundColor || 'var(--tertiaryColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--admiralButtonColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--tertiaryColor)';
        break;
      case this.type === BUTTON_TYPES.LOGOUT:
        colorOfBtn['background'] = this.backgroundColor || 'var(--admiralButtonColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--lightGrayColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--primaryColor)';
        break;
      case this.type === BUTTON_TYPES.SAVE_CHANGES:
        colorOfBtn['background'] = this.backgroundColor || 'var(--primaryColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--admiralButtonColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--primaryColor)';
        break;
      case this.type === BUTTON_TYPES.SUBMIT_BTN_STYLE:
        colorOfBtn['background'] = this.backgroundColor || 'var(--primaryColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--admiralButtonColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--primaryColor)';
        break;
      case this.type === BUTTON_TYPES.CUSTOM:
        colorOfBtn['background-color'] = `${this.backgroundColor}`;
        colorOfBtn['textColor'] = `${this.textColor}`;
        colorOfBtn['border'] = this.borderStyle || ``;
        break;
      default:
        colorOfBtn['background'] = this.backgroundColor || 'var(--admiralButtonColor)';
        colorOfBtn['textColor'] = this.textColor || 'var(--lightGrayColor)';
        colorOfBtn['border'] = this.borderStyle || 'solid 1px var(--lightGrayColor)';
        break;
    }
    return colorOfBtn;
  }

  assignListeners() {
    if (this.type === BUTTON_TYPES.SUBMIT_BTN_STYLE || this.hoverBackground) {
      this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', e => {
        this.renderer.setStyle(
          this.elementRef.nativeElement,
          'background-color',
          'var(--btnPrimaryHoverColor)'
        );
      });
      this.renderer.listen(this.elementRef.nativeElement, 'mouseleave', e => {
        this.renderer.setStyle(
          this.elementRef.nativeElement,
          'background-color',
          `${this.generateMainBtnColors().background}`
        );
      });
    }
  }
}
