import { BlinkIdMultiSideRecognizerResult } from '@microblink/blinkid-in-browser-sdk';
import { Buffer } from 'buffer';

export class DocumentScanPersonalData {
  public firstName: string;
  public lastName: string;
  public dateOfBirth: ItemDate;
  public personalIdNumber: string;
  public documentNumber: string;
  public address: string;
  public frontImageBase64: string;
  public backImageBase64: string;
  public dateOfExpiry: ItemDate;
  public dateOfExpiryPermanent: boolean;
  public issuingAuthority: string;
  public sex: string;
  public isoAlpha2CountryCode: string;
  public faceImageBase64: string;

  constructor(data: BlinkIdMultiSideRecognizerResult) {
    this.firstName =
      data.firstName.latin || data.firstName.cyrillic || data.firstName.arabic || data.mrz.secondaryID;
    this.lastName =
      data.lastName.latin || data.lastName.cyrillic || data.lastName.arabic || data.mrz.primaryID;
    this.dateOfBirth = new ItemDate(data.dateOfBirth);
    this.personalIdNumber =
      data.personalIdNumber.latin || data.personalIdNumber.cyrillic || data.personalIdNumber.arabic;
    this.documentNumber =
      data.documentNumber.latin || data.documentNumber.cyrillic || data.documentNumber.arabic;
    this.address = data.address.latin || data.address.cyrillic || data.address.arabic;
    this.frontImageBase64 = this.getImageBase64String(data.fullDocumentFrontImage.encodedImage);
    this.backImageBase64 = this.getImageBase64String(data.fullDocumentBackImage.encodedImage);
    this.dateOfExpiry = new ItemDate(data.dateOfExpiry);
    this.dateOfExpiryPermanent = data.dateOfExpiryPermanent;
    this.issuingAuthority =
      data.issuingAuthority.latin || data.issuingAuthority.cyrillic || data.issuingAuthority.arabic;
    this.sex = data.sex.latin || data.sex.cyrillic || data.sex.arabic;
    this.isoAlpha2CountryCode = data.classInfo.isoAlpha2CountryCode || '';
    this.faceImageBase64 = this.getImageBase64String(data.faceImage.encodedImage);
  }

  private getImageBase64String(encodedImage: Uint8Array): string {
    const imagebyteArray = Object.values(encodedImage);
    const imageBase64String = Buffer.from(imagebyteArray).toString('base64');

    return imageBase64String;
  }
}

export class ItemDate {
  public day: number;
  public month: number;
  public year: number;
  public originalString: string;

  constructor(data: any) {
    this.day = data.day;
    this.month = data.month;
    this.year = data.year;
    this.originalString =
      data.originalString.latin || data.originalString.cyrillic || data.originalString.arabic;
  }
}
