<div class="u_relative-position">
  <div>
    <div
      class="p-3 rounded c_language-flag-container pointer c_language-flag-container{{ '--is-down' }}"
      [ngClass]="{ 'c_language-flag-container--is-active': chooserExpanded }"
    >
      <p class="font-weight-bold mb-2">{{ 'CHOOSE_LANGUAGE' | translate }}</p>
      <div
        class="row no-gutters align-items-center"
        *ngFor="let flag of this.languagesList"
        (click)="changeLanguage(flag.isocode2)"
      >
        <div
          class="col-auto my-1 c_language-chooser-flag c_language-chooser-flag--is-{{ flag.isocode2 }}"
        ></div>
        <p class="col px-1 mb-0">{{ 'LANGUAGE_' + flag.isocode2.toUpperCase() | translate }}</p>
      </div>
    </div>

    <div
      class="c_language-chooser-container pointer"
      (click)="expandLangChooser()"
      [ngClass]="{ 'c_btn-chooser-active': chooserExpanded }"
    >
      <div class="c_language-chooser-flag c_language-chooser-flag--is-{{ this.activeLanguage }}"></div>
    </div>
  </div>
</div>
