import { Injectable, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip';
import { HelperClientService } from '@services/helpers-client.service';
import { PLAYER_PROFILE_ROLE } from '@enums/player-profile-role.enum';
import { BehaviorSubject } from 'rxjs';
import { Player } from '@models/player.model';

declare var gtag_report_conversion;

@Injectable({
  providedIn: 'root',
})
export class HelpersService extends HelperClientService {
  private activationParam = new BehaviorSubject('');
  public activationParamObservable = this.activationParam.asObservable();

  constructor() {
    super();
  }

  setActivationParam(data: string) {
    this.activationParam.next(data);
  }

  /**
   * Removing null values from object and empty stings
   * @param object - target object
   */
  clearObject(object: any) {
    Object.keys(object).forEach(key => {
      if (!object[key] || object[key] === '') {
        delete object[key];
      }
    });
    return object;
  }

  /**
   * Removing only null values from objects
   * @param object - target object
   */
  clearNullObject(object: any) {
    Object.keys(object).forEach(key => {
      if (object[key] === null) {
        delete object[key];
      }
    });
    return object;
  }

  // Iterate through object properties and trim every string
  trimObjectProperties(obj: any): any {
    Object.keys(obj).map(k => (obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]));
    return obj;
  }

  // trim form and return form invalid flag
  trimedFormIsInvalid(form: FormGroup): boolean {
    form.patchValue(this.trimObjectProperties(form.value));
    return form.invalid;
  }

  // check file(image) upload size
  isFileLargerThen5MB(sizeInBytes): any {
    const sizeInMB = sizeInBytes / 1048576;
    return sizeInMB > 5;
  }

  /**
   * Check if some element is visible on viewport
   *
   * @param elem - element which we are testing if it is visible
   */
  elementVisible(elem): boolean {
    const rect = elem.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  allowOnlyNumbers(input) {
    return input.replace(/[^0-9]/g, '');
  }

  allowOnlyNumbersAndLetters(input) {
    return input.replace(/[^a-zA-Z0-9ćčšžđĆČŠŽĐ]/g, '');
  }

  allowOnlyNumbersAndLettersAlphabetOnly(input) {
    return input.replace(/[^a-zA-Z0-9]/g, '');
  }

  autoFocusNextInput(codeOne, codeTwo) {
    if (codeOne.value.length === codeOne.maxLength) {
      codeTwo.focus();
    }
  }

  maskUnmaskPassword(element: HTMLElement, hideShowIcon?: HTMLElement) {
    if (element.getAttribute('type') === 'password') {
      element.setAttribute('type', 'text');
    } else {
      element.setAttribute('type', 'password');
    }
    if (hideShowIcon) {
      hideShowIcon.classList.contains('shown')
        ? hideShowIcon.classList.remove('shown')
        : hideShowIcon.classList.add('shown');
    }
  }

  getPropertyValue(value) {
    return getComputedStyle(document.documentElement).getPropertyValue('--' + value);
  }

  gtagReportConversion() {
    return gtag_report_conversion();
  }

  closeTooltipOnClick(element: NgbTooltip, timeOutDuration) {
    element.open();
    const timeoutID = setTimeout(() => {
      element.close();
      clearTimeout(timeoutID);
    }, timeOutDuration);
  }

  // only works for android devices and desktop
  requestFullScreen() {
    if (document.fullscreenElement === null) {
      const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
      };

      if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
        docElmWithBrowsersFullScreenFunctions.requestFullscreen();
      } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
        /* Firefox */
        docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
      } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
      } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
        /* IE/Edge */
        docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
      }
    }
  }

  // only works for android devices and desktop
  closeFullScreen() {
    if (document.fullscreenElement !== null) {
      const docWithBrowsersExitFunctions = document as Document & {
        mozCancelFullScreen(): Promise<void>;
        webkitExitFullscreen(): Promise<void>;
        msExitFullscreen(): Promise<void>;
      };
      if (docWithBrowsersExitFunctions.exitFullscreen) {
        docWithBrowsersExitFunctions.exitFullscreen();
      } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
        /* Firefox */
        docWithBrowsersExitFunctions.mozCancelFullScreen();
      } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        docWithBrowsersExitFunctions.webkitExitFullscreen();
      } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
        /* IE/Edge */
        docWithBrowsersExitFunctions.msExitFullscreen();
      }
    }
  }

  scrollToTop(smooth: boolean = true): void {
    window.scrollTo({ top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto' });
  }

  // Blinking Marker procedure
  blinkingMarkerState(userData, blinkingServiceStatus: boolean) {
    let unauthorizedUser;
    if (blinkingServiceStatus) {
      const blinkingCustomerId = userData?.blinkingCustomerId;
      const playerRole = userData?.profileRole;

      if (blinkingCustomerId) {
        unauthorizedUser = playerRole !== PLAYER_PROFILE_ROLE.VERIFIED;
      } else {
        if (playerRole === PLAYER_PROFILE_ROLE.VERIFIED) {
          unauthorizedUser = false;
        } else {
          unauthorizedUser = true;
        }
      }
    } else {
      // this marker is related to blinking process soo it has to be disabled on other clients
      unauthorizedUser = false;
    }

    return unauthorizedUser;
  }

  setLobbyBackgroundImage(element, renderer: Renderer2, desiredBackgroundColor?) {
    const backgroundImageValue = document.documentElement.style.getPropertyValue('--backgroundImage');
    const backgroundColor = desiredBackgroundColor
      ? desiredBackgroundColor
      : document.documentElement.style.getPropertyValue('--backgroundColor');
    renderer.setStyle(
      element,
      'background',
      `url(./assets/images/${backgroundImageValue}), ${backgroundColor}`
    );
  }

  handleNumberMaxLength(input) {
    if (input.value.length > input.maxLength) {
      input.value = input.value.slice(0, input.maxLength);
    }
  }

  getVerifiedUserNoWithdrawalMessage(player: Player, blinkingAvailable: boolean, translate: any) {
    if (
      player.profileRole === PLAYER_PROFILE_ROLE.VERIFIED &&
      (player.roles || []).find(role => role.name === 'WITHDRAWAL - DISABLED') &&
      blinkingAvailable
    ) {
      return translate
        .instant('WITHDRAWAL_DISABLED_FOR_VERIFIED_USER')
        .replace('##URL##', location.origin + '/profile/messages');
    }
    return null;
  }

  toFixedWithoutRounding(value: number, precision: number): number {
    const power = Math.pow(10, precision || 0);
    return Math.floor(value * power) / power;
  }
}
