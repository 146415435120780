export enum CLIENT_NAMES {
  ADMIRAL_SERBIA = 'ADMIRAL_SERBIA',
  ADMIRAL_PINNBET = 'ADMIRAL_PINNBET',
  ADMIRAL_MONTENEGRO = 'ADMIRAL_MONTENEGRO',
  ADMIRAL_MD_SHOP = 'ADMIRAL_MD_SHOP',
  ADMIRAL_UGANDA = 'ADMIRAL_UGANDA',
  ADMIRAL_NIGERIA = 'ADMIRAL_NIGERIA',
  ADMIRAL_BOSNIA = 'ADMIRAL_BOSNIA',
  ADMIRAL_CROATIA = 'ADMIRAL_CROATIA',
}
