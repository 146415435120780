import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ConfigData } from '@models/config-data/config-data.model';
import { AppStateFacadeService } from '@state/app-state.facade';
import { interval, Subscription } from 'rxjs';
import { CmsGeneralHeaderSettings, CmsHeader } from './cmsHeader.model';
import { Player } from '@models/player.model';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header-cms',
  templateUrl: './header-cms.component.html',
  styleUrls: ['./header-cms.component.scss'],
})
export class HeaderCmsComponent implements OnInit, OnDestroy {
  @Input() player: Player;
  public headerCmsData: any;
  public cmsGeneralData: CmsGeneralHeaderSettings;
  public clock = interval(1000).pipe(map(() => new Date()));
  private windowWidth: number = window.innerWidth;
  public headerType = 'deskType1';
  public logoImage: string;
  public imagesBaseUrl = environment.imagesBaseUrl;

  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isTablet = this.appStateFacadeService.getIsTabletStatus();

  // Subscriptions
  private configDataObservable$: Subscription;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appStateFacadeService: AppStateFacadeService,
    private elementRef: ElementRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderType();
  }

  ngOnInit(): void {
    this.configDataObservable$ = this.appStateFacadeService
      .getConfigData()
      .pipe(filter((data: ConfigData) => !!data))
      .subscribe((data: ConfigData) => {
        this.headerCmsData = new CmsHeader(JSON.parse(data.headerV2));
        this.cmsGeneralData = new CmsGeneralHeaderSettings(
          JSON.parse(data.generalV2).filter((dataGeneral: any) => dataGeneral.active)[0].style.header
        );
        this.setFaIcons();
        this.setHeaderType();
        this.setColorsForMenuFromGeneralSettings();
      });
  }
  private setFaIcons(): void {
    const faIcons = this.headerCmsData.faviconImagePath;
    if (faIcons) {
      let link: HTMLLinkElement = this.document.querySelector('#favicon');
      if (!link) {
        link = this.document.createElement('link');
        link.id = 'favicon';
        link.rel = 'icon';
        this.document.head.appendChild(link);
      }
      link.href = this.imagesBaseUrl + faIcons;
    }
  }

  private setColorsForMenuFromGeneralSettings(): void {
    this.elementRef.nativeElement.style.setProperty(
      '--headerBackgroundColors',
      this.cmsGeneralData.backgroundColor.hex
    );
    this.elementRef.nativeElement.style.setProperty('--defaultHeaderHeight', '68px');
    this.elementRef.nativeElement.style.setProperty(
      '--headerBorderBottomColor',
      this.cmsGeneralData.borderColor.hex
    );
  }

  setHeaderType(): void {
    if (!this.isMobile && !this.isTablet) {
      switch (true) {
        case this.windowWidth < 576: // Extra small devices (portrait phones, less than 576px)
          this.headerType = `mobile_${this.headerCmsData.headerLayoutMobile.toLowerCase()}`;
          this.logoImage =
            environment.imagesBaseUrl +
            (this.headerCmsData.logoMobileImagePath || this.headerCmsData.logoImagePath);
          break;
        case this.windowWidth >= 576 && this.windowWidth < 768: // Small devices (landscape phones, 576px and up)
          this.headerType = `tablet_${this.headerCmsData.headerLayoutTablet.toLowerCase()}`;
          this.logoImage =
            environment.imagesBaseUrl +
            (this.headerCmsData.logoRetinaImagePath || this.headerCmsData.logoImagePath);
          break;
        case this.windowWidth >= 768: // Medium devices (tablets, 768px and up) and Large devices (desktops, 992px and up)
          this.headerType = `desk_${this.headerCmsData.headerLayoutDesktop.toLowerCase()}`;
          this.logoImage = environment.imagesBaseUrl + this.headerCmsData.logoImagePath;
          break;
        default:
          this.headerType = `desk_${this.headerCmsData.headerLayoutDesktop.toLowerCase()}`;
          this.logoImage = environment.imagesBaseUrl + this.headerCmsData.logoImagePath;
      }
    }

    if (this.isMobile) {
      this.headerType = `mobile_${this.headerCmsData.headerLayoutMobile.toLowerCase()}`;
      this.logoImage =
        environment.imagesBaseUrl +
        (this.headerCmsData.logoMobileImagePath || this.headerCmsData.logoImagePath);
    }

    if (this.isTablet) {
      this.headerType = `tablet_${this.headerCmsData.headerLayoutTablet.toLowerCase()}`;
      this.logoImage =
        environment.imagesBaseUrl +
        (this.headerCmsData.logoRetinaImagePath || this.headerCmsData.logoImagePath);
    }
  }

  ngOnDestroy(): void {
    this.configDataObservable$.unsubscribe();
  }

  public handleLeftSideBar() {
    this.appStateFacadeService.expandCollapseLeftSideBar();
  }

  protected readonly environment = environment;
}
