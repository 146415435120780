<nav #headerContainer id="headerContainer" class="p-2 px-5 py-md-3 navbar row no-gutters">
  <div class="headerButtons center left-margin col-auto">
    <div class="logo p-0">
      <a [routerLink]="[environment.appDefaultRoute]">
        <img [ngClass]="logoImg" [src]="logoImg" alt="logo" width="94" height="19" />
      </a>
    </div>
  </div>

  <!-- MAIN MENU -->
  <div #headerMenuContainer class="nav-main mx-4 d-none d-md-block col text-center" (mousedown)="startDragging($event)"
    (mouseup)="stopDragging()" (mouseleave)="stopDragging()" (mousemove)="moveEvent($event)">
    <ul *ngIf="!isMobile">
      <ng-template #tipContent>{{ 'HELP' | translate }}</ng-template>
      <ng-container *ngFor="let menuItem of mainMenu?.menuItems">
        <li [ngbTooltip]="tipContent" placement="right" [disableTooltip]="menuItem?.url !== '/help'"
          [routerLinkActive]="['active']" (click)="navigationService.navigate(menuItem)">
          <ng-container *ngIf="!(menuItem.requireAuthorized && !player)">
            <a routerLink="{{ menuItem.url }}">
              <div>
                <i *ngIf="menuItem?.inactiveIcon || menuItem?.activeIcon" [setSVGAttributes]="{ height: '18px' }"
                  [inlineSVG]="imagesBaseUrl + menuItem?.inactiveIcon || menuItem?.activeIcon"
                  fallbackSVG="assets/images/default-left-menu-icon.svg"></i>
                <span *ngIf="menuItem?.url !== '/help'" class="menu-item-name">{{
                  menuItem.name | translate
                  }}</span>
              </div>
            </a>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>

  <div *ngIf="!isMobile" class="mr-3 col-auto">
    <app-language-chooser-v2></app-language-chooser-v2>
  </div>
  <div *ngIf="chatEnabled && !player" class="pointer clickableArea ml-auto" (click)="toggleChat()">
    <span inlineSVG="assets/icons/green_chat_icon.svg"></span>
    <sub *ngIf="unreadChat > 0" class="align-top top text-white badge badge-danger p-1 d-inline-block ml-n1">{{
      unreadChat }}</sub>
  </div>
  <div class="right-margin col-auto">
    <a *ngIf="!player" [routerLink]="['/login']" routerLinkActive="active" class="header-icon-button">
      <button class="btn btn-primary btn-rounded text-uppercase px-4 py-1">{{ 'LOGIN' | translate }}</button>
    </a>
    <div *ngIf="player" class="header-icon-button row no-gutters">
      <a routerLink="/profile/deposit">
        <button class="btn btn-primary btn-rounded text-uppercase px-4 py-1">
          {{ 'DEPOSIT' | translate }}
        </button>
      </a>
      <a [routerLink]="['/profile/messages']" routerLinkActive="active" class="user-icon-wrapper px- ml-3">
        <span class="icon-wrapper">
          <i [setSVGAttributes]="{ height: '14px' }" inlineSVG="/assets/images/bell.svg"
            fallbackSVG="assets/images/default-left-menu-icon.svg"></i>
          <span *ngIf="player && unreadMessages > 0" class="notification-counter">{{ unreadMessages }}</span>
        </span>
      </a>
      <div class="user-icon-wrapper ml-3" (click)="handleRightSideBar()">
        <img [src]="'/assets/images/user-icon-green.svg'" alt="User icon" />
      </div>
    </div>
  </div>
</nav>