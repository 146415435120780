import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bootstrap-alert',
  templateUrl: './bootstrap-alert.component.html',
  styleUrls: ['./bootstrap-alert.component.scss'],
})
export class BootstrapAlertComponent implements OnInit, OnDestroy {
  // doc: https://getbootstrap.com/docs/4.0/components/alerts/

  private toaster$: Subscription;
  public alertClass;
  public message;
  public showAlert: boolean;

  constructor(private toasterService: ToasterService) {}

  ngOnInit(): void {
    this.setToasterServiceSubscription();
  }

  ngOnDestroy(): void {
    this.toaster$.unsubscribe();
  }

  // get message from toaster service
  private setToasterServiceSubscription() {
    this.toaster$ = this.toasterService.getAlertMessage().subscribe(alert => {
      this.showAlert = true;
      this.alertClass = alert.class;
      this.message = alert.message;
      this.fadeOut();
    });
  }

  // fade out alert message after 3 seconds
  private fadeOut(): void {
    setTimeout(() => {
      this.close();
    }, 4000);
  }

  // close alert message on x btn
  public close(): void {
    this.showAlert = false;
  }
}
