import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from './client-names.enum';

export const LOBBY_PATHS = {
  CASINO:
    environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
    environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
      ? 'slot'
      : 'casino',
  LIVE_CASINO:
    environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
    environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
      ? 'live-dealer'
      : 'casino-live',
  VIRTUAL: 'virtual',
  MINI_GAMES: 'mini-games',
};
