import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { AppStateFacadeService } from '@state/app-state.facade';
import { HelpersService } from '@services/helpers.service';
import { NavigationService } from '@services/navigation.service';
import { environment } from 'src/environments/environment';

import { Player } from '@models/player.model';
import { MenuElement, MenuSection } from '@models/config-data/menu/mobile-nav-menu.model';

@Component({
  selector: 'app-mobile-quick-menu-pinnbet',
  templateUrl: './mobile-quick-menu-pinnbet.component.html',
  styleUrls: ['./mobile-quick-menu-pinnbet.component.scss'],
  animations: [
    trigger('slideDown', [
      state('true', style({ transform: 'translateY(0)' })),
      state('false', style({ transform: 'translateY(100%)' })),
      transition('false => true', animate('300ms ease-in')),
      transition('true => false', animate('300ms ease-out')),
    ]),
  ],
})
export class MobileQuickMenuPinnbetComponent implements OnInit, OnDestroy {
  public isQuickMenuOpen: boolean;
  // Register open-action from parent to support detection
  // of click event on dots button in parent, which opens this menu.
  private openActionFromParent = false;
  @Input() set isOpened(value: boolean) {
    this.isQuickMenuOpen = value;
    this.openActionFromParent = value;
  }
  @Output() isOpenedChange = new EventEmitter<boolean>();

  public navBarMenu: MenuElement[] = [];
  public menuSections: MenuSection[] = [];

  public imagesBaseUrl = environment.imagesBaseUrl;
  public player: Player;
  private playerObservable: Observable<any>;
  private player$: Subscription;
  private mobileMenuItems$: Subscription;

  constructor(
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private helperService: HelpersService,
    private navigationService: NavigationService,
    private viewRef: ViewContainerRef
  ) {
    this.playerObservable = this.appStateFacadeService.getPlayerDataObservable();
  }

  ngOnInit(): void {
    this.setPlayerSubscription();
    this.setBottomMobileMenuSubscription();
  }

  private setBottomMobileMenuSubscription(): void {
    this.mobileMenuItems$ = this.appStateFacadeService.getBottomMobileMenu().subscribe(items => {
      if (items.length) {
        if (this.helperService.iOS()) {
          setTimeout(() => this.configureQuickMenuItems(items), 500);
        } else {
          this.configureQuickMenuItems(items);
        }
      }
    });
  }

  private configureQuickMenuItems(items: any): void {
    let tempElements: MenuElement[] = [];
    const tempMenuSections: MenuSection[] = [];
    let isUsefulLinks = false;

    const subMenu = items.find(elem => elem.type === 'WRAPPER')?.subMenu;
    subMenu?.forEach((elem: any, idx: number, arr: any[]) => {
      tempElements.push({
        iconPath: elem.activeIcon,
        iconLabel: elem.name,
        pagePath: elem.url,
        pagePathType: elem.type,
        requireAuthorized: elem.requireAuthorized,
      });

      if (elem.breakline || idx === arr.length - 1) {
        if (elem.horizontal) {
          this.navBarMenu = tempElements;
        } else {
          tempMenuSections.push({
            title: isUsefulLinks ? 'PINNBET_MENU_USEFUL_LINKS_SECTION' : 'PINNBET_MENU_PRIMARY_SECTION',
            items: tempElements,
          });
          isUsefulLinks = true;
        }
        tempElements = [];
      }
    });

    if (!!tempMenuSections.length) {
      this.menuSections = tempMenuSections;
    }
  }

  private setPlayerSubscription(): void {
    this.player$ = this.playerObservable.subscribe(playerData => {
      this.player = playerData;
    });
  }

  public navigateToPage(menuElement: MenuElement): void {
    this.navigationService.navigateToLink(menuElement.pagePathType, menuElement.pagePath);
    this.closeQuickMenu();
  }

  public closeQuickMenu(): void {
    this.isQuickMenuOpen = false;
    this.isOpenedChange.emit(false);
    this.openActionFromParent = false;
  }

  public mainBtnAction(): void {
    const routeToNavigate = this.player ? '/profile/deposit' : '/login';
    this.router.navigate([routeToNavigate]);
    this.closeQuickMenu();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: PointerEvent) {
    if (!this.viewRef.element.nativeElement.contains(event.target) && this.isQuickMenuOpen) {
      if (this.openActionFromParent) {
        this.openActionFromParent = false;
      } else {
        this.closeQuickMenu();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.mobileMenuItems$) {
      this.mobileMenuItems$.unsubscribe();
    }
    if (this.player$) {
      this.player$.unsubscribe();
    }
  }
}
