<nav
  (swipeleft)="closeLeftMenuOnSwipe()"
  id="sidebar-left"
  class="sidebar sidebar-left"
  [ngClass]="{ expanded: leftBarExpanded }"
>
  <aside (click)="handleLeftSideBar()">
    <div class="sidebar-toggle">
      <i class="icon icon-menu"></i>
    </div>
    <app-language-picker></app-language-picker>

    <div
      (swipeleft)="closeLeftMenuOnSwipe()"
      id="left-sidebar-content"
      class="sidebar-content"
      #contentOfMenu
    >
      <!-- LEFT MENU ITEMS -->
      <ul>
        <ng-container *ngFor="let menuItem of leftMenu?.menuItems">
          <ng-container *ngIf="!(menuItem.requireAuthorized && !player)">
            <li
              (click)="navigateToLinkInMenu(menuItem, $event); $event.stopPropagation()"
              *ngIf="menuItem.type !== 'WRAPPER'"
              routerLinkActive="active"
              [routerLink]="menuItem.url"
            >
              <a>
                <figure>
                  <ng-template #tipContent>{{ menuItem.name | translate }}</ng-template>
                  <i
                    [inlineSVG]="imagesBaseUrl + menuItem.inactiveIcon || menuItem.activeIcon"
                    fallbackSVG="assets/images/default-left-menu-icon.svg"
                    class="icon"
                    #tooltipInstance="ngbTooltip"
                    placement="top"
                    [ngbTooltip]="tipContent"
                    [autoClose]="'inside'"
                    (click)="helpersService.closeTooltipOnClick(tooltipInstance, 2000)"
                  >
                  </i>
                  <figcaption class="h5">{{ menuItem.name | translate }}</figcaption>
                </figure>
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="menuItem.breakline">
            <div class="c_divider-style">
              <hr />
            </div>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </aside>
</nav>
