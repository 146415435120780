export class CmsFooterSection {
  id: number;
  sectionName: string;
  verticalAlignment: string;
  horizontalAlignment: string;
  rowGap?: number;
  columnGap?: number;
  sectionItems: CmsAsset[];
  constructor(data: any) {
    this.id = data.id;
    this.sectionName = data.sectionName;
    this.verticalAlignment = CmsFooterSection.verticalAligmentFlexToBootstrap(data.verticalAlignment);
    this.horizontalAlignment = CmsFooterSection.horizontalAligmentFlexToBootstrap(data.horizontalAlignment);
    this.rowGap = data.rowGap;
    this.columnGap = data.columnGap;
    this.sectionItems = data.sectionItems.map((item: any) => new CmsAsset(item));
  }

  static flexRowOrColumnToBootstrap(property?: string): string {
    switch (property) {
      case 'row':
        return 'flex-row';
      case 'column':
        return 'flex-column';
      default:
        return 'flex-column';
    }
  }

  static horizontalAligmentFlexToBootstrap(alignment: string): string {
    switch (alignment) {
      case 'flex-start':
        return 'justify-content-start';
      case 'center':
        return 'justify-content-center';
      case 'flex-end':
        return 'justify-content-end';
      case 'space-between':
        return 'justify-content-between';
      case 'space-around':
        return 'justify-content-around';
      default:
        return 'justify-content-start';
    }
  }

  static verticalAligmentFlexToBootstrap(alignment: string): string {
    switch (alignment) {
      case 'flex-start':
        return 'align-items-start';
      case 'center':
        return 'align-items-center';
      case 'flex-end':
        return 'align-items-end';
      case 'stretch':
        return 'align-items-stretch';
      case 'baseline':
        return 'align-items-baseline';
      default:
        return 'align-items-start';
    }
  }
}

export class CmsAsset {
  id: number;
  title: string;
  assetHeight: number;
  items: CmsAssetItem[];
  desktopWidth?: number;
  tabletWidth?: number;
  mobileWidth?: number;
  flexDirection: string;
  verticalAlignment: string;
  horizontalAlignment: string;
  rowGap: number;
  columnGap: number;
  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.assetHeight = data.assetHeight;
    this.items = data.items ? data.items.map((item: any) => new CmsAssetItem(item)) : [];
    this.desktopWidth = data.desktopWidth;
    this.tabletWidth = data.tabletWidth;
    this.mobileWidth = data.mobileWidth;
    this.flexDirection =  CmsFooterSection.flexRowOrColumnToBootstrap(data.flexDirection);
    this.verticalAlignment = data.verticalAlignment ? CmsFooterSection.verticalAligmentFlexToBootstrap(data.verticalAlignment) : 'align-items-center';
    this.horizontalAlignment = data.verticalAlignment ? CmsFooterSection.horizontalAligmentFlexToBootstrap(data.horizontalAlignment) : 'justify-content-start';
    this.rowGap = data.rowGap || 0;
    this.columnGap = data.columnGap || 0;
  }
}

export class CmsAssetItem {
  id: number;
  title: string;
  image: File;
  icon: File;
  text: string;
  localizationKey: string;
  link: string;
  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.image = data.image;
    this.icon = data.icon;
    this.text = data.text;
    this.localizationKey = data.localizationKey;
    this.link = data.link;
  }
}
