<div class="box">
  <!-- HEADER -->
  <div class="section section-header">
    <div class="game-vendor">{{game.providerName}}</div>
    <div class="game">
      <div class="game-title">
        <div>{{game.name}}</div>
        <!-- <img
          *ngIf="!game.favorite && isLoggedIn"
          (click)="setFavoriteGame()"
          class="pointer"
          src="assets/jackpots/favorite_star_empty.svg"
        />
        <img
          *ngIf="game.favorite && isLoggedIn"
          (click)="setFavoriteGame()"
          class="pointer"
          src="assets/jackpots/favorite_star_full.svg"
        /> -->
        <i
          (click)="setFavoriteGame()"
          class="pointer favorite-icon"
          inlineSVG="assets/images/star-solid.svg"
          [setSVGAttributes]="{ height: '12px' }"
          fallbackSVG="assets/images/default-left-menu-icon.svg"
          *ngIf="isLoggedIn"
          [ngClass]="{'active': game.favorite}"
        ></i>
      </div>
      <i
        (click)="closeDialog()"
        class="pointer close-icon"
        inlineSVG="assets/images/close-solid.svg"
        [setSVGAttributes]="{ height: '16px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
    </div>
  </div>
  <!-- GAME INFO -->
  <div class="section section-game-info">
    <div class="game-image">
      <app-image-wrapper
        class="w-100"
        [width]="120"
        [height]="120"
        [alt]="game.name"
        [progressiveLoad]="true"
        [costumeLowResSrc]="game.lowResGameIconLocation ? imagesBaseUrl + game.lowResGameIconLocation : ''"
        [src]="
          imgSource === 'img'
            ? (game.gameIconLocation ? imagesBaseUrl + game.gameIconLocation : '')
            : (game.mobileGameIconLocation ? imagesBaseUrl + game.mobileGameIconLocation : '')
        "
        [costumeClass]="'br-12 img-fluid'"
      ></app-image-wrapper>
    </div>
    <div class="game-description">
      <div *ngIf="!game.descriptionLocale" class="description">{{ 'NO_GAME_DESCRIPTION' | translate }}</div>
      <div
        *ngIf="game.descriptionLocale"
        [innerHtml]="game.descriptionLocale | sanitizeHtml"
        class="description"
      ></div>
    </div>
  </div>
  <!-- TOURNAMENT INFO -->
  <div *ngIf="game.tournament && tournament?.tournamentName" class="section section-tournament-info">
    <div class="tournament-position">
      <div class="text">{{ 'GAME_PLAY_TOURNAMENT_POSITION' | translate }}</div>
      <div class="number">{{ tournament?.playerPosition ? tournament?.playerPosition : '-' }}</div>
    </div>
    <div class="tournament-info">
      <div class="title">{{ tournament?.tournamentName | uppercase }}</div>
      <div class="ends">
        <img class="info-icon" src="assets/icons/tournament-ends.png" alt="tournament-ends" />
        <div class="info-label">{{ 'TOURNAMENT_ENDS' | translate }}</div>
        <div class="info-time">{{ tournament?.tournamentEndsOn | date: 'd.M.yyyy hh:mm' }}</div>
      </div>
      <div class="prize-fond">
        <div class="prize-fond-info">
          <img class="info-icon" src="assets/icons/tournament-prize-fond.png" alt="tournament-prize-fond" />
          <div class="info-label">{{ 'TOURNAMENT_PRIZE_FOND' | translate }}</div>
          <ng-container *ngIf="!tournament?.manual">
            <div class="info-prize">
              {{ tournament?.tournamentPrizePool | number: '1.2-2' | currency: tournament?.currency }}
            </div>
          </ng-container>
          <ng-container *ngIf="tournament?.manual">
            <div class="info-prize">{{ tournament.manualPrize }}</div>
          </ng-container>
        </div>
        <div class="right-arrow" (click)="goToTournament(tournament?.tournamentId)">
          <i
            class="pointer info-arrow-icon"
            inlineSVG="assets/images/circle-chevron-right-solid.svg"
            [setSVGAttributes]="{ height: '16px' }"
            fallbackSVG="assets/images/default-left-menu-icon.svg"
          >
          </i>
        </div>
      </div>
    </div>
  </div>
  <!-- BUTTONS -->
  <div class="section section-primary-buttons">
    <a
      *ngIf="game.demoIncluded"
      (click)="navigateToGamePlay(gameType.FOR_FUN)"
      class="btn btn-outline-primary color-primary-outline br-8 w-25"
    >
      {{ 'DEMO' | translate | uppercase }}
    </a>
    <a
      *ngIf="!isLoggedIn"
      [routerLink]="['/login']"
      (click)="goToLogin()"
      class="btn btn-primary color-primary br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'LOGIN' | translate | uppercase }}
    </a>
    <a
      (click)="navigateToGamePlay(gameType.FOR_REAL)"
      *ngIf="isLoggedIn"
      class="btn btn-primary color-primary br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'PLAY_FOR_CASH' | translate | uppercase }}
    </a>
    <a *ngIf="false" class="btn btn-outline-success color-outline-success br-8 w-25">
      {{ 'FREEBET' | translate | uppercase }}
    </a>
  </div>
  <!-- PROVIDER GAME OPEN -->
  <div *ngIf="isLoggedIn && gamePlayProblemLinkShown" class="section section-provider-game-open">
    <a (click)="navigateToGamePlay(gameType.FOR_REAL_EXTERNAL)" class="link">
      {{ 'PROBLEMS_OPENING_GAME' | translate }}
    </a>
  </div>
  <!-- ADDITIONAL INFO -->
  <!-- <div class="section section-info-buttons">
    <div class="info-buttons info-buttons-active">{{ game.vendorName }}</div>
    <div class="info-buttons info-buttons-default">#Vockice</div>
    <div class="info-buttons info-buttons-default">{{ game.categoryName }}</div>
    <div class="info-buttons info-buttons-default">#jackpot</div>
  </div> -->
</div>
