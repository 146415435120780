import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExternalContentService {
  constructor(private http: HttpClient) {}

  getPageContent(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'external/resources', { params });
  }

  getStaticPageByCode(code): Observable<any> {
    return this.http.get(environment.apiUrl + `static-page/${code}`);
  }
}
