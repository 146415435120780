import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { TermsConditions } from '@modules/terms-conditions/terms-conditions/models/terms-conditions.model';
import { TranslateService } from '@ngx-translate/core';
import { TermsConditionsService } from '@services/terms-conditions.service';
import { ToasterService } from '@services/toaster.service';
import { environment } from 'src/environments/environment';

enum LOCATION {
  PAGE = 'PAGE',
  DIALOG = 'DIALOG',
}

@Component({
  selector: 'app-terms-conditions-text',
  templateUrl: './terms-conditions-text.component.html',
  styleUrls: ['./terms-conditions-text.component.scss'],
})
export class TermsConditionsTextComponent {
  public LOCATION = LOCATION;
  public isJumpToTopAvailable: boolean;
  public termsModel: TermsConditions;
  @Output() dataSent = new EventEmitter<TermsConditions>();
  @Input() location;

  constructor(
    private termsConditionsService: TermsConditionsService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {
    this.getTermsAndConditions();
  }

  private getTermsAndConditions() {
    this.termsConditionsService.getActiveTermsConditions().subscribe({
      next: data => {
        this.termsModel = new TermsConditions(data);
        this.dataSent.emit(this.termsModel);
      },
      error: error => {
        this.toaster.showError(this.translate.instant('UNABLE_TO_FETCH_TERMS_CONDITIONS'));
      },
    });
  }

  public scroll(event: any) {
    if (this.location === LOCATION.DIALOG && environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      this.isJumpToTopAvailable = event.target.scrollTop >= 2000;
    }
  }

  public jumpToTop() {
    document.getElementsByClassName('termsConditionsDialogWrapper')[0].scrollTop = 0;
  }
}
