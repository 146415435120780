import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { GAME_TYPE } from '@enums/game-type.enum';
import { LOBBY_PATHS } from '@enums/lobby-paths.enum';
import { GameDataModel } from '@models/game-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '@services/local-storage.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';
import { CroatiaGameInfoDialog } from '../game-info-croatia/croatia-game-info.dialog';
import { GameInfoDialog } from '../game-info/game-info.dialog';
import { NavigationService } from '@services/navigation.service';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { LobbyService } from '@services/lobby.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-lobby-game',
  templateUrl: './lobby-game.component.html',
  styleUrls: ['./lobby-game.component.scss'],
})
export class LobbyGameComponent implements OnInit, OnDestroy {
  @Input() game: GameDataModel;
  @Input() lobbyPath = '';

  public environment = environment;
  public clientNames = CLIENT_NAMES;
  public isMobile: boolean;
  public imageType: 'img' | 'mobile';
  public language: string;
  public readonly gameType = GAME_TYPE;

  public gameInfoBarDisplay = false;
  public activePlayersNumber = new BehaviorSubject(0);
  private activePlayersByGameId$: Subscription;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private modalService: NgbModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
    private toasterService: ToasterService,
    private translate: TranslateService,
    private lobbyService: LobbyService
  ) {
    this.language = this.localStorageService.getLanguage();
    this.imageType = window.innerWidth > 600 ? 'img' : 'mobile';
    this.isMobile = this.appStateFacadeService.getIsMobileStatus();
  }

  ngOnDestroy(): void {
    this.activePlayersByGameId$?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.environment.clientName === CLIENT_NAMES.ADMIRAL_MONTENEGRO) {
      this.setupGameInfoBarDisplay();
    }
  }

  private setupGameInfoBarDisplay() {
    this.gameInfoBarDisplay = true;
    this.activePlayersByGameId$ = this.lobbyService.activePlayerNumbersByGameIDObservable
      .pipe(filter(activePlayers => !!activePlayers))
      .subscribe(activePlayers => {
        this.activePlayersNumber.next(activePlayers[this.game.id]);
      });
  }

  public openGameInfoDialog(game: GameDataModel): void {
    const GAME_INFO_DIALOG =
      environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA ? CroatiaGameInfoDialog : GameInfoDialog;

    const modalRef = this.modalService.open(GAME_INFO_DIALOG, {
      centered: true,
      scrollable: false,
      keyboard: false,
    });

    modalRef.componentInstance.game = game;
    modalRef.componentInstance.lobbyPath = this.lobbyPath;
  }

  public navigateToDesktopGamePlay(game, gameType: GAME_TYPE): void {
    // proceed registration
    const player = this.appStateFacadeService.getPlayerData();
    if (
      environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA &&
      gameType === GAME_TYPE.FOR_REAL &&
      player &&
      !player.registrationCompleted
    ) {
      this.navigationService.checkIfOnlyF2FLeftAndInProcess(player);
      return;
    }
    // Prevent playing for cash, for game which is not allowed on geolocation.
    if (gameType === GAME_TYPE.FOR_REAL && !this.game.allowedInGeolocation) {
      this.toasterService.showError(this.translate.instant('GAME_NOT_ALLOWED_ON_GEOLOCATION'));
      return;
    }

    const type = gameType === 'FOR_REAL' ? 'cash' : 'demo';
    if (!this.lobbyPath) {
      this.lobbyPath = LOBBY_PATHS[game.lobbies[0]];
    }

    switch (game.vendorName) {
      case 'ELBET':
        this.router.navigate([`${this.lobbyPath}/gameplay/elbet/${game.externalProductId}`]);
        return;
      case 'NSOFT':
        this.router.navigate([`${this.lobbyPath}/gameplay/nsoft/${game.externalProductId}`]);
        return;
      case 'Golden Race':
        this.router.navigate([`${this.lobbyPath}/gameplay/golden-race/${game.externalProductId}`]);
        return;
      case 'Spribe':
        this.router.navigate([`${this.lobbyPath}/gameplay/spribe/${game.externalProductId}`]);
        return;
      case 'Pragmatic Play':
        // is slot lobby, and spaceman need to be open like mini game
        if (game.code === 'spaceman') {
          environment.clientName === CLIENT_NAMES.ADMIRAL_NIGERIA
            ? this.router.navigate([`virtual/gameplay/pragmatic/${game.code}`])
            : this.router.navigate([`mini-games/gameplay/pragmatic/${game.code}`]);
        } else {
          this.router.navigate([`${this.lobbyPath}/gameplay/desktop/${type}/${game.id}`]);
        }
        return;
      default:
        // EXEFEED
        this.router.navigate([`${this.lobbyPath}/gameplay/desktop/${type}/${game.id}`]);
        return;
    }
  }
}
