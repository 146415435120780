<div
  class="c_sec-menu u_opacity-0"
  [ngClass]="{ 'c_sec-menu--is-expand': secondaryMenuExtended, 'u_opacity-100': secondaryMenuExtended }"
>
  <div class="u_position-relative">
    <ng-container *ngFor="let btn of subMenuItems">
      <ng-container
        *ngTemplateOutlet="
          subMenuBtn;
          context: {
            data: btn
          }
        "
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-template
  #subMenuBtn
  let-label="data.iconLabel"
  let-icon="data.imagePath"
  let-pageActive="data.pageActive"
  let-classSuFix="data.classSuFix"
  let-requireAuthorized="data.requireAuthorized"
>
  <div class="rotate" [ngClass]="classConstructor('rotate', classSuFix, secondaryMenuExtended)">
    <div
      class="c_menu-btn--is-secondary-menu sec-menu-icon sec-menu-icon{{ classSuFix }}"
      (click)="navigateToPage(pageActive, requireAuthorized)"
      [ngClass]="{
        'sec-menu-icon--is-expand': secondaryMenuExtended,
        'c_menu-btn--sub-is-active': activePage === pageActive
      }"
    >
      <div
        [fallbackSVG]="'assets/images/mobile-menu-icon/menu_casino.svg'"
        [inlineSVG]="environment.imagesBaseUrl + icon"
      ></div>
      <div class="c_menu-label--is-sec-menu text-center">{{ label | translate }}</div>
    </div>
  </div>
</ng-template>

<div *ngIf="menuActive" class="c_bottom-menu">
  <ng-container *ngFor="let btn of menuItems">
    <ng-container
      *ngTemplateOutlet="
        btn.pageActive === 'menu' ? subMenuBtnSwitch : menuBtn;
        context: {
          data: btn
        }
      "
    ></ng-container>
  </ng-container>
</div>

<ng-template
  #menuBtn
  let-label="data.iconLabel"
  let-icon="data.imagePath"
  let-pageActive="data.pageActive"
  let-requireAuthorized="data.requireAuthorized"
>
  <div
    class="c_menu-btn"
    [ngClass]="{ 'c_menu-btn--is-active': pageActive === activePage }"
    (click)="navigateToPage(pageActive, requireAuthorized)"
  >
    <div
      [fallbackSVG]="'assets/images/mobile-menu-icon/menu_casino.svg'"
      [inlineSVG]="environment.imagesBaseUrl + icon"
    ></div>
    <div class="c_menu-label text-center">{{ label | translate }}</div>
  </div>
</ng-template>

<ng-template #subMenuBtnSwitch let-label="data.iconLabel" let-icon="data.imagePath">
  <div
    class="c_menu-btn c_menu-btn--is-menu"
    [ngClass]="{ 'c_menu-btn--is-selected': secondaryMenuExtended }"
    (click)="clickOnMenuIcon()"
  >
    <div
      [fallbackSVG]="'assets/images/mobile-menu-icon/menu_casino.svg'"
      [inlineSVG]="environment.imagesBaseUrl + icon"
    ></div>
    <div class="c_menu-label text-center">{{ label | translate }}</div>
  </div>
</ng-template>
