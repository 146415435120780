import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Player } from '@models/player.model';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Observable, Subscription } from 'rxjs';
import { heightAnimation } from '../../shared/animations/animations';
import { BalanceInfoInterface, MessagesInterface } from '@state/interfaces/AppStateInterface.interface';
import { BalanceInfo } from '@models/balance-info.model';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';
import { NavigationService } from '@services/navigation.service';
import { ConfigData } from '@models/config-data/config-data.model';
import { Menu } from '@models/config-data/menu/menu.model';
import { BUTTON_TYPES } from '../../shared/directive/admiral-button.directive';
import { AuthService } from '@services/auth.service';
import { filter } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-right-menu-cro',
  templateUrl: './right-menu-cro.component.html',
  styleUrls: ['./right-menu-cro.component.scss'],
  animations: [heightAnimation('200ms')],
  providers: [DecimalPipe],
})
export class RightMenuCroComponent implements OnInit, OnDestroy {
  @ViewChild('contentOfMenu', { static: true }) contentOfMenu: ElementRef;
  @Input() player: Player;

  public balanceInfo: BalanceInfo;
  public admiral360BalanceInfo: Admiral360BalanceInfo;
  public rightBarExpanded: boolean;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public balanceDropDownOpen = true;

  // Config
  public rightMenu: Menu;
  public BUTTON_TYPES = BUTTON_TYPES;
  public finalizeRegistration: boolean;
  public unreadMessages: number;

  // Observables
  private configDataObservable: Observable<any>;
  private balanceInfoObservable: Observable<any>;
  private playerDataObservable: Observable<Player>;
  private unreadMessagesObservable: Observable<any>;

  // Subscriptions
  private configData$: Subscription;
  private balanceInfo$: Subscription;
  private rightBarStatus$: Subscription;
  private playerData$: Subscription;
  private unreadMessages$: Subscription;

  constructor(
    public navigationService: NavigationService,
    public appStateFacadeService: AppStateFacadeService,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setupStore();
    this.setConfigDataSubscription();
    this.setRightBarSubscription();
    this.setBalanceInfoSubscription();
    this.setPlayerSubscription();
    this.setUnreadMessagesSubscription();
  }

  ngOnDestroy(): void {
    this.configData$.unsubscribe();
    this.balanceInfo$.unsubscribe();
    this.rightBarStatus$.unsubscribe();
    this.playerData$.unsubscribe();
    this.unreadMessages$.unsubscribe();
  }

  // setup store
  private setupStore(): void {
    this.configDataObservable = this.appStateFacadeService.getConfigData();
    this.balanceInfoObservable = this.appStateFacadeService.getBalanceInfo();
    this.playerDataObservable = this.appStateFacadeService.getPlayerDataObservable();
    this.unreadMessagesObservable = this.appStateFacadeService.getMessagesObservable();
  }

  private setRightBarSubscription(): void {
    this.rightBarStatus$ = this.appStateFacadeService.observeRightBarStatus().subscribe((status: boolean) => {
      this.rightBarExpanded = status;
      this.renderer.setProperty(this.contentOfMenu.nativeElement, 'scrollTop', '0');
    });
  }

  // set config subscription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable
      .pipe(filter(data => !!data))
      .subscribe((configData: ConfigData) => {
        this.rightMenu = new Menu(configData?.appMenus.rightMenu);
      });
  }

  private setUnreadMessagesSubscription(): void {
    this.unreadMessages$ = this.unreadMessagesObservable.subscribe((data: MessagesInterface) => {
      this.unreadMessages = data.total;
    });
  }

  // close right menu on swipe
  public closeRightMenuOnSwipe() {
    if (this.isMobile) {
      this.appStateFacadeService.expandCollapseRightSideBar();
    }
  }

  public openBalanceInformation() {
    this.balanceDropDownOpen = !this.balanceDropDownOpen;
  }

  public navigateToLinkInMenu(linkInformation) {
    this.appStateFacadeService.collapseAllSideBars();
    this.navigationService.checkRegistrationStatusAndNavigate(linkInformation);
  }

  public openLinkForLabel(url) {
    this.navigationService.checkRegistrationStatusAndNavigate({ url, type: 'INTERNAL_LINK_SUBMENU' });
    this.appStateFacadeService.expandCollapseRightSideBar();
  }

  private setBalanceInfoSubscription(): void {
    this.balanceInfo$ = this.balanceInfoObservable.subscribe((balanceState: BalanceInfoInterface) => {
      const { playerBalanceInfo: playerBalanceInfo, admiral360BalanceInfo } = balanceState;
      this.balanceInfo = playerBalanceInfo || new BalanceInfo({});
      this.admiral360BalanceInfo = new Admiral360BalanceInfo(admiral360BalanceInfo);
    });
  }

  private setPlayerSubscription(): void {
    this.playerData$ = this.playerDataObservable.pipe(filter(data => !!data)).subscribe(player => {
      this.finalizeRegistration = player && !player.registrationCompleted;
    });
  }

  // logout
  public logout(): void {
    this.authService.logout('Right menu');
  }
}
