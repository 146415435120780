import { Injectable, Renderer2 } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject } from 'rxjs';

const embedded_svc = (window as any).embedded_svc;

@Injectable({
  providedIn: 'root',
})
export class SalesForceChatService {
  private unreadChatSubject = new BehaviorSubject<number>(0);
  public unreadChat$ = this.unreadChatSubject.asObservable();

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private localStorageService: LocalStorageService
  ) {}

  public setSalesForceChat(player?) {
    if (embedded_svc) {
      this.endChat();

      const gslbBaseURL = 'https://service.force.com';
      embedded_svc.settings.displayHelpButton = false; // display initial button by sales force
      embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
      embedded_svc.settings.defaultMinimizedText = 'Započni razgovor';
      embedded_svc.settings.loadingText = 'Učitavanje';
      embedded_svc.settings.offlineSupportMinimizedText = 'Ostavi poruku';

      if (player) {
        embedded_svc.settings.prepopulatedPrechatFields = {
          FirstName: player.firstName,
          LastName: player.lastName,
          Email: player.email,
          Subject: 'Live agent',
          AdmiralBetID__c: 'P-' + player.id.toString(),
        };
      } else {
        embedded_svc.settings.prepopulatedPrechatFields = {
          FirstName: 'Gost',
          LastName: 'Lastname',
          Email: '',
          Subject: 'Live agent',
          AdmiralBetID__c: '',
        };
      }

      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      embedded_svc.settings.extraPrechatInfo = [
        {
          entityFieldMaps: [
            {
              doCreate: true,
              doFind: true,
              fieldName: 'LastName',
              isExactMatch: true,
              label: 'Last Name',
            },
            {
              doCreate: true,
              doFind: true,
              fieldName: 'FirstName',
              isExactMatch: true,
              label: 'First Name',
            },
            {
              doCreate: true,
              doFind: true,
              fieldName: 'Email',
              isExactMatch: true,
              label: 'Email',
            },
          ],
          entityName: 'Contact',
          showOnCreate: false,
        },
      ];

      if (environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA) {
        if (environment.production) {
          this.initChatProductionSRB(gslbBaseURL);
        } else {
          this.initChatDevelopSRB(gslbBaseURL);
        }
      }

      if (environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET) {
        if (environment.production) {
          this.initChatProductionPINN(gslbBaseURL);
        } else {
          this.initChatDevelopPINN(gslbBaseURL);
        }
      }
    }
  }

  public endChat(): void {
    if (embedded_svc.liveAgentAPI) {
      embedded_svc.liveAgentAPI.endChat();
    }
  }

  // PROD PARAMS
  private initChatProductionSRB(gslbBaseURL) {
    embedded_svc.init(
      'https://admiralbet.my.salesforce.com',
      'https://admiralbet.my.salesforce-sites.com/AdmiralExternalChat',
      gslbBaseURL,
      '00D7Q000006m9w2',
      'AdmiralOnlineChat',
      {
        baseLiveAgentContentURL: 'https://c.la2-c1-cdg.salesforceliveagent.com/content',
        deploymentId: '5727Q000000UBCc',
        buttonId: '5737Q000000UBmO',
        baseLiveAgentURL: 'https://d.la2-c1-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'Online_Chats',
        isOfflineSupportEnabled: true,
      }
    );
  }

  private initChatProductionPINN(gslbBaseURL) {
    embedded_svc.init(
      'https://pinnbet2.my.salesforce.com',
      'https://pinnbet2.my.salesforce-sites.com/PinnBetExternalChat',
      gslbBaseURL,
      '00D2o000000jMWf',
      'PinnBetOnlineChat',
      {
        baseLiveAgentContentURL: 'https://c.la1-c1-fra.salesforceliveagent.com/content',
        deploymentId: '5722o000000Csz1',
        buttonId: '5732o0000008guT',
        baseLiveAgentURL: 'https://d.la1-c1-fra.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'Online_Chats',
        isOfflineSupportEnabled: true,
      }
    );
  }

  // STAGE PARAMS
  private initChatDevelopSRB(gslbBaseURL) {
    embedded_svc.init(
      'https://admiralbet--devsb2.sandbox.my.salesforce.com',
      'https://admiralbet--devsb2.sandbox.my.salesforce-sites.com/AdmiralExternalChat',
      gslbBaseURL,
      '00D8E0000009f8T',
      'AdmiralOnlineChat',
      {
        baseLiveAgentContentURL: 'https://c.la1-c1cs-lo3.salesforceliveagent.com/content',
        deploymentId: '5728E0000008Oi3',
        buttonId: '5738E0000008OoU',
        baseLiveAgentURL: 'https://d.la1-c1cs-lo3.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'AdmiralExternalChat',
        isOfflineSupportEnabled: true,
      }
    );
  }

  private initChatDevelopPINN(gslbBaseURL) {
    embedded_svc.init(
      'https://pinnbet2--devsb.sandbox.my.salesforce.com',
      'https://pinnbet2--devsb.sandbox.my.salesforce-sites.com/PinnBetExternalChat',
      gslbBaseURL,
      '00DMT0000008gGF',
      'PinnBetOnlineChat',
      {
        baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
        deploymentId: '572MT0000004CAu',
        buttonId: '573MT0000004CCC',
        baseLiveAgentURL: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IMT000000003o2AA_18cf3835550',
        isOfflineSupportEnabled: true,
      }
    );
  }

  public setSalesForceChatEvents(renderer: Renderer2) {
    const that = this;

    embedded_svc.addEventHandler('onHelpButtonClick', function (data) {
      that.appStateFacadeService.setChatWindowOpen(true);
    });

    embedded_svc.addEventHandler('afterMaximize', function (data) {
      that.appStateFacadeService.setChatWindowOpen(true);
      that.unreadChatSubject.next(0);

      // disable email field in prechat form if user is logged in
      const inputEmail = document.getElementById('Email');
      const offlineInputEmail = document.getElementById('SuppliedEmail') as HTMLInputElement;
      if (inputEmail && that.localStorageService.isLoggedIn()) {
        renderer.setStyle(inputEmail, 'pointer-events', 'none');
      }

      if (offlineInputEmail && that.localStorageService.isLoggedIn()) {
        offlineInputEmail.value = that.appStateFacadeService.getPlayerData().email;
        renderer.setStyle(offlineInputEmail, 'pointer-events', 'none');
      }

      // hide subject and id fields
      const hide = document.getElementsByClassName('offlineSupportUI').length !== 1;
      if (hide) {
        document.getElementById('Subject').parentElement.parentElement.style.display = 'none';
        document.getElementById('AdmiralBetID__c').parentElement.parentElement.style.display = 'none';
      }
    });

    embedded_svc.addEventHandler('afterMinimize', function (data) {
      that.appStateFacadeService.setChatWindowOpen(false);
    });

    embedded_svc.addEventHandler('afterDestroy', function (data) {
      that.appStateFacadeService.setChatWindowOpen(false);
      that.unreadChatSubject.next(0);
    });

    embedded_svc.addEventHandler('onAgentMessage', function (data) {
      that.unreadChatSubject.next(1);
    });

    embedded_svc.addEventHandler('onChasitorMessage', function (data) {
      that.unreadChatSubject.next(0);
    });
  }
}
