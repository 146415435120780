<nav id="headerContainer" class="c_cms-header-container d-flex justify-content-between align-items-center">
  <ng-container *ngIf="headerType === 'desk_type_1'">
    <ng-container *ngTemplateOutlet="deskType1"></ng-container>
  </ng-container>
  <ng-container *ngIf="headerType === 'desk_type_2'">
    <ng-container *ngTemplateOutlet="deskType2"></ng-container>
  </ng-container>
  <ng-container *ngIf="headerType === 'tablet_type_1'">
    <ng-container *ngTemplateOutlet="tabletType1"></ng-container>
  </ng-container>
  <ng-container *ngIf="headerType === 'tablet_type_2'">
    <ng-container *ngTemplateOutlet="tabletType2"></ng-container>
  </ng-container>
  <ng-container *ngIf="headerType === 'mobile_type_1'">
    <ng-container *ngTemplateOutlet="mobile_type_1"></ng-container>
  </ng-container>
  <ng-container *ngIf="headerType === 'mobile_type_2'">
    <ng-container *ngTemplateOutlet="mobile_type_2"></ng-container>
  </ng-container>
</nav>

<!--Layout schemes-->
<ng-template #deskType1>
  <ng-container *ngTemplateOutlet="headerLogo; context: { device: 'deskType' }"></ng-container>
  <ng-container *ngTemplateOutlet="headerMenu"></ng-container>
  <ng-container *ngTemplateOutlet="userOperationElements"></ng-container>
</ng-template>
<ng-template #deskType2>
  <ng-container *ngTemplateOutlet="headerMenu"></ng-container>
  <ng-container *ngTemplateOutlet="headerLogo; context: { device: 'deskType' }"></ng-container>
  <ng-container *ngTemplateOutlet="userOperationElements"></ng-container>
</ng-template>

<ng-template #tabletType1>
  <ng-container *ngTemplateOutlet="headerLogo; context: { device: 'tabletType' }"></ng-container>
  <ng-container *ngTemplateOutlet="userOperationElements"></ng-container>
  <ng-container *ngTemplateOutlet="burgerElement"></ng-container>
</ng-template>

<ng-template #tabletType2>
  <ng-container *ngTemplateOutlet="burgerElement"></ng-container>
  <ng-container *ngTemplateOutlet="headerLogo; context: { device: 'tabletType' }"></ng-container>
  <ng-container *ngTemplateOutlet="userOperationElements"></ng-container>
</ng-template>

<ng-template #mobile_type_1>
  <ng-container *ngTemplateOutlet="headerLogo; context: { device: 'mobileType' }"></ng-container>
  <ng-container *ngTemplateOutlet="userOperationElements"></ng-container>
  <ng-container *ngTemplateOutlet="burgerElement"></ng-container>
</ng-template>
<ng-template #mobile_type_2>
  <ng-container *ngTemplateOutlet="burgerElement"></ng-container>
  <ng-container *ngTemplateOutlet="headerLogo; context: { device: 'mobileType' }"></ng-container>
  <ng-container *ngTemplateOutlet="userOperationElements"></ng-container>
</ng-template>

<!--Layout elements-->
<ng-template #headerLogo let-device="device">
  <div class="c_cms-header-logo">
    <a [routerLink]="[environment.appDefaultRoute]">
      <img src="{{ logoImage }}" alt="logo" />
    </a>
  </div>
</ng-template>

<ng-template #headerMenu>
  <app-header-cms-menu-component
    [navigationMenu]="headerCmsData.navigationMenuAssetsItems"
    [player]="player"
    [cmsGeneralData]="cmsGeneralData"
  >
  </app-header-cms-menu-component>
</ng-template>

<ng-template #userOperationElements let-device="device">
  <app-user-operation-elements
    [headerCmsData]="headerCmsData"
    [player]="player"
  ></app-user-operation-elements>
</ng-template>
<ng-template #burgerElement>
  <div (click)="handleLeftSideBar()">
    <i
      [setSVGAttributes]="{ height: '14px' }"
      inlineSVG="/assets/images/hamburger.svg"
      fallbackSVG="assets/images/default-left-menu-icon.svg"
    ></i>
  </div>
</ng-template>
