import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AppState } from './app-state';
import { HelpersService } from '@services/helpers.service';
import { Router } from '@angular/router';
import { PreviousRouteService } from './previous-route.service';
import { Observable } from 'rxjs';
import { Player } from '@models/player.model';
import { ConfigData } from '@models/config-data/config-data.model';
import { BalanceInfo } from '@models/balance-info.model';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';
import {
  BalanceInfoInterface,
  MessagesInterface,
  webSocketActonInterface,
} from './interfaces/AppStateInterface.interface';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppStateFacadeService extends PreviousRouteService {
  private renderer: Renderer2;

  constructor(
    public _appState: AppState,
    private helperService: HelpersService,
    public router: Router,
    rendererFactory: RendererFactory2
  ) {
    super(_appState, router);
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setPlayerData(player: Player): void {
    this._appState.setPlayerData(player);
  }

  getPlayerData(): Player {
    return this._appState.getPlayerData();
  }

  getPlayerDataObservable(): Observable<Player> {
    return this._appState.getPlayerDataObservable();
  }

  getConfigData(): Observable<ConfigData> {
    return this._appState.getConfigDataObservable();
  }

  setClientName(clientName) {
    this._appState.setClientName(clientName);
  }

  getClientName(): string {
    return this._appState.getClientName();
  }

  setConfigData(configData: ConfigData): void {
    this._appState.setConfigData(configData);
  }

  setPlayerBalance(playerBalanceInfo: BalanceInfo): void {
    const balanceFromState = this._appState.getPlayerBalanceInfo();
    const patchedPlayerBalance = Object.assign(
      {},
      balanceFromState,
      this.helperService.clearNullObject(playerBalanceInfo)
    );
    this._appState.setPlayerBalanceInfo(new BalanceInfo(patchedPlayerBalance));
    this.setBalanceInfo();
  }

  setAdmiral360BalanceInfo(admiral360BalanceInfo: Admiral360BalanceInfo): void {
    this._appState.setPlayerAdmiral360BalanceInfo(admiral360BalanceInfo);
    this.setBalanceInfo();
  }

  setBalanceInfo(): void {
    this._appState.setBalanceInfo({
      playerBalanceInfo: this._appState.getPlayerBalanceInfo(),
      admiral360BalanceInfo: this._appState.getPlayerAdmiral360BalanceInfo(),
    });
  }

  getBalanceInfo(): Observable<BalanceInfoInterface> {
    return this._appState.getBalanceInfo();
  }

  getMessagesObservable(): Observable<MessagesInterface> {
    return this._appState.getMessagesObservable();
  }

  setMessages(messages) {
    this._appState.setMessages({
      total: messages.length,
      records: [...messages],
    });
  }

  setNewUnreadMessage(message) {
    const messageFromState = this._appState.getMessages();
    this.setMessages([message, ...messageFromState.records]);
  }

  removeUnreadMessage(id: number): void {
    const messageFromState = this._appState.getMessages();
    const filterRetainedMessages = messageFromState.records.filter(message => message.id !== id);
    this.setMessages(filterRetainedMessages);
  }

  setSocketAction(socketData: webSocketActonInterface) {
    this._appState.setSocketAction(socketData);
  }

  getSocketActionObservable(): Observable<webSocketActonInterface> {
    return this._appState.getSocketActionObservable();
  }

  // URLs
  observeCurrentUrlStatus(): Observable<string> {
    return this._appState.observeCurrentUrl();
  }

  // MOBILE
  setIsMobileStatus(flag) {
    this._appState.setIsMobileStatus(flag);
  }

  getIsMobileStatus() {
    return this._appState.getIsMobileStatus();
  }

  setIsTabletStatus(flag) {
    this._appState.setIsTabletStatus(flag);
  }

  getIsTabletStatus() {
    return this._appState.getIsTabletStatus();
  }

  setBottomMobileMenu(menuItems) {
    this._appState.setBottomMobileMenu(menuItems);
  }

  getBottomMobileMenu(): Observable<any> {
    return this._appState.getBottomMobileMenu();
  }

  setSecondaryMenuState(newState: boolean = false) {
    this._appState.setBottomMenuSecondaryMenuState(newState);
  }

  getSecondaryMenuStateObservable(): Observable<boolean> {
    return this._appState.getBottomMenuSecondaryMenuState();
  }

  // LOGO
  setLogoImgPath(logoImgPath) {
    this._appState.setLogoImgPath(logoImgPath);
  }

  getLogoImgPath() {
    return this._appState.getLogoImgPath();
  }

  // SIDEBARS
  setIsLeftSidebarPresent(flag) {
    this._appState.setIsLeftSidebarPresent(flag);
  }

  getIsLeftSidebarPresent() {
    return this._appState.getIsLeftSidebarPresent();
  }

  setLeftSideBarStatus(status) {
    this._appState.setLeftSideBarStatus(status);
  }

  getLeftSideBarStatus() {
    return this._appState.getLeftSideBarStatus();
  }

  setRightSideBarStatus(status) {
    this._appState.setRightSideBarStatus(status);
  }

  getRightSideBarStatus() {
    return this._appState.getRightSideBarStatus();
  }

  expandCollapseLeftSideBar() {
    // On method call we want to switch state of left side bar.
    const leftBarStatusSwitch = !this.getLeftSideBarStatus();
    if (leftBarStatusSwitch) {
      this.setLeftSideBarStatus(true);
      this.setRightSideBarStatus(false);
      this.setOverlayShadeStatus(true);
    } else {
      this.setLeftSideBarStatus(false);
      this.setOverlayShadeStatus(false);
    }
  }

  expandCollapseRightSideBar() {
    const rightBarStatusSwitch = !this.getRightSideBarStatus();
    if (rightBarStatusSwitch) {
      this.setRightSideBarStatus(true);
      this.setLeftSideBarStatus(false);
      this.setOverlayShadeStatus(true);
      this.handleChatView();
    } else {
      this.setRightSideBarStatus(false);
      this.handleChatView();
      this.setOverlayShadeStatus(false);
    }
  }

  collapseAllSideBars() {
    this.setRightSideBarStatus(false);
    this.setLeftSideBarStatus(false);
    this.setOverlayShadeStatus(false);
    this.handleChatView();
  }

  observeLeftBarStatus() {
    return this._appState.observeLeftSideBarStatus();
  }

  observeRightBarStatus() {
    return this._appState.observeRightSideBarStatus();
  }

  setOverlayShadeStatus(flag) {
    this._appState.setOverlayShadeStatus(flag);
  }

  observeOverlayShadeStatus() {
    return this._appState.observeOverlayShadeStatus();
  }

  // CAROUSEL
  setMainCarouselVisibility(newState: boolean) {
    this._appState.setMainCarouselVisibility(newState);
  }

  observeMainCarouselVisibility() {
    return this._appState.observeMainCarouselVisibility();
  }

  // ACTIVE QUERY PARAMS
  setActiveQueryParams(activeQuerryParams) {
    this._appState.setActiveQueryParams(activeQuerryParams);
  }

  getActiveQueryParams() {
    return this._appState.getActiveQueryParams();
  }

  observeActiveQueryParamsStatus() {
    return this._appState.observeActiveQueryParams();
  }

  // CHAT
  setChatWindowWasOpenBeforeRightBarExpand(flag) {
    this._appState.setChatWindowWasOpenBeforeBarExpand(flag);
  }

  getChatWindowWasOpenBeforeRightBarExpand() {
    return this._appState.getChatWindowWasOpenBeforeBarExpand();
  }

  setChatWindowOpen(flag) {
    this._appState.setOpenChatWindow(flag);
  }

  isChatWindowOpen() {
    return this._appState.isChatWindowOpen();
  }

  private handleChatView() {
    const leftBarStatus = this.getLeftSideBarStatus();
    const rightBarStatus = this.getRightSideBarStatus();
    const isMobileStatus = this.getIsMobileStatus();
    const chatWasOpen = this.getChatWindowWasOpenBeforeRightBarExpand();

    switch (true) {
      // mobile view show on left menu activation
      case isMobileStatus && leftBarStatus:
        // this.helperService.openChat();
        break;
      case isMobileStatus && !leftBarStatus:
        // this.helperService.closeChat();
        break;
      // desktop view right menu activation
      case !isMobileStatus && rightBarStatus:
        this.helperService.closeChat(this.renderer, this);
        break;
      case !isMobileStatus && !rightBarStatus && chatWasOpen:
        this.helperService.openChat(this.renderer, this);
        break;
      default:
        break;
    }
  }

  // PARTICLES
  setParticleSystem(active: boolean, type?: string) {
    this._appState.setParticleSystemControl(active, type);
  }

  observeParticleSystem() {
    return this._appState.observeParticleSystemControl();
  }

  // SEARCH STATUS
  setSearchStatus(nextSearchStatus: boolean) {
    this._appState.setIsSearchActive(nextSearchStatus);
  }

  getSearchStatus(): boolean {
    return this._appState.getIsSearchActive();
  }

  getSearchStatusObservable(): Observable<boolean> {
    return this._appState.observeSearchActiveStatus();
  }

  // SEARCHED GAMES
  setSearchedGames(nextSearchedGames: any) {
    this._appState.setSearchedGames(nextSearchedGames);
  }

  getSearchedGamesObservable(): Observable<any> {
    return this._appState.observeSearchedGames();
  }

  // CURRENT SEARCH
  setCurrentSearchValue(newSearch: string) {
    this._appState.setCurrentSearchValue(newSearch);
  }

  getCurrentSearchValueObservable(): Observable<string> {
    return this._appState.observeCurrentSearchValue();
  }

  setScrollToSearch(): void {
    this._appState.setScrollToSearch();
  }

  getScrollToSearchObservable(): Observable<void> {
    return this._appState.observeScrollToSearch();
  }

  getDefaultAppUrl(): string {
    return this._appState.getDefaultAppUrl();
  }

  setDefaultAppUrl(defaultAppUrl: string) {
    this._appState.setDefaultAppUrl(defaultAppUrl);
  }

  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // setAppDownloadPopupState(flag): void {
  //   this._appState.setAppDownloadPopup(flag);
  // }

  // setAppQuickBarStatus(flag): void {
  //   this._appState.setAppQuickBarStatus(flag);
  // }

  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // getAppDownloadPopupObservable() {
  //   return this._appState.observeAppDownloadPopup();
  // }
  // getAppQuickBarStatusObservable() {
  //   return this._appState.observeQuickBarStatus();
  // }

  setNxcsPlatformLogin(data): void {
    this._appState.setNxcsLoginData(data);
  }

  getNxcsPlatformLogin(): Observable<any> {
    return this._appState.getNxcsLoginDataObservable();
  }

  setBlinkingServiceStatus(status): void {
    this._appState.setBlinkingService(status);
  }

  getBlinkingServiceStatus(): Observable<boolean> {
    return this._appState.getBlinkingService().pipe(filter(stateForFilter => stateForFilter !== null));
  }

  setDocumentVerificationServiceStatus(status: boolean) {
    this._appState.setDocumentVerificationService(status);
  }

  getDocumentVerificationServiceStatus(): Observable<boolean> {
    return this._appState
      .getDocumentVerificationService()
      .pipe(filter(stateForFilter => stateForFilter !== null));
  }

  setMicroBlinkingServiceStatus(status: boolean) {
    this._appState.setMicroBlinkingService(status);
  }

  getMicroBlinkingServiceStatus(): Observable<boolean> {
    return this._appState.getMicroBlinkingService().pipe(filter(stateForFilter => stateForFilter !== null));
  }

  setReferFriendCodeStatus(referFriendCodeStatus: boolean) {
    this._appState.setReferFriendCodeStatus(referFriendCodeStatus);
  }

  getReferFriendCodeStatusObservable(): Observable<boolean> {
    return this._appState.getReferFriendCodeStatusObservable().pipe(filter(status => status !== null));
  }

  setRegistrationPromoCodeStatus(registrationPromoCodeStatus: boolean) {
    this._appState.setRegistrationPromoCodeStatus(registrationPromoCodeStatus);
  }

  getRegistrationPromoCodeStatusObservable(): Observable<boolean> {
    return this._appState.getRegistrationPromoCodeStatusObservable();
  }

  setPaymentPromoCodeStatus(paymentPromoCodeStatus: boolean) {
    this._appState.setPaymentPromoCodeStatus(paymentPromoCodeStatus);
  }

  getPaymentPromoCodeStatusObservable(): Observable<boolean> {
    return this._appState.getPaymentPromoCodeStatusObservable();
  }

  setLuckyWheelResult(result: any) {
    this._appState.setLuckyWheelResult(result);
  }

  getLuckyWheelResultObservable(): Observable<any> {
    return this._appState.getLuckyWheelResultObservable();
  }
}
