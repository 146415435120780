<div class="box">
  <form [formGroup]="questionnaireForm">
    <div class="row">
      <!-- POLITICIAN -->
      <div class="col-lg-12 col-xxl-12">
        <div class="text-muted note">{{ 'POLITICAL_QUESTION_1' | translate }}</div>
        <div class="mb-3">
          <span class="link" (click)="tooglePoliticalQuestion()">{{
            'POLITICAL_QUESTION_2' | translate
          }}</span>
        </div>
        <div class="note" *ngIf="politicalQuestion">{{ 'POLITICAL_DESCRIPTION' | translate }}</div>
      </div>

      <!-- POLITICIAN YES/NO -->
      <div class="col-lg-12 col-xxl-12">
        <div class="form-group">
          <div class="d-flex justify-content-center">
            <div>
              <div class="mb-2 mr-3">
                <app-custom-radio
                  [radioFormControl]="questionnaireForm.get('politicianRadioBtn')"
                  [typeOfRadioBtn]="'TOUCH_REGION_BTN'"
                  value="YES"
                  (radioClick)="changePolitician(true)"
                >
                  <span>{{ 'YES' | translate | uppercase }}</span>
                </app-custom-radio>
              </div>
            </div>
            <div>
              <div class="mb-2">
                <app-custom-radio
                  [radioFormControl]="questionnaireForm.get('politicianRadioBtn')"
                  [typeOfRadioBtn]="'TOUCH_REGION_BTN'"
                  value="NO"
                  (radioClick)="changePolitician(false)"
                >
                  <span>{{ 'NO' | translate | uppercase }}</span>
                </app-custom-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="politicalQuestions" class="row">
      <!-- EXPOSURE -->
      <div class="col-lg-12 col-xxl-12">
        <div class="form-group">
          <label class="label required">
            {{ 'ADDITIONAL_DATA.WAY_OF_EXPOSURE' | translate }}
          </label>
          <app-custom-select
            [itemsObservable]="exposureTypesObservable"
            (selectEvent)="onSelectExposureType($event)"
            [fcontrol]="questionnaireForm.get('exposure')"
            [patchValueObservable]="patchExposureTypesSubject"
            [orientationTop]="true"
            [ngClass]="{
              'is-invalid': questionnaireForm.get('exposure').invalid && questionnaireForm.get('exposure').touched
            }"
          >
          </app-custom-select>
          <small *ngIf="questionnaireForm.get('exposure').hasError('required')" class="invalid-feedback">
            {{ 'REQUIRED_FIELD' | translate }}
          </small>
        </div>
      </div>

      <!-- PUBLIC DUTY -->
      <div class="col-lg-12 col-xxl-12">
        <div class="form-group">
          <label class="label required">
            {{ 'ADDITIONAL_DATA.CHOSE_PUBLIC_DUTY' | translate }}
          </label>
          <app-custom-select
            [itemsObservable]="publicDutyTypesObservable"
            (selectEvent)="onSelectPublicDutyType($event)"
            [fcontrol]="questionnaireForm.get('publicDuty')"
            [patchValueObservable]="patchPublicDutyTypesSubject"
            [orientationTop]="true"
            [ngClass]="{
              'is-invalid': questionnaireForm.get('publicDuty').invalid && questionnaireForm.get('publicDuty').touched
            }"
          >
          </app-custom-select>
          <small *ngIf="questionnaireForm.get('publicDuty').hasError('required')" class="invalid-feedback">
            {{ 'REQUIRED_FIELD' | translate }}
          </small>
        </div>
      </div>

      <!-- SOURCE OF PROPERTY -->
      <div class="col-lg-12 col-xxl-12">
        <div class="form-group">
          <label class="label required">
            {{ 'ADDITIONAL_DATA.SOURCE_OF_PROPERTY' | translate }}
          </label>
          <app-custom-select
            [itemsObservable]="sourceOfPropertyTypesObservable"
            (selectEvent)="onSelectSourceOfPropertyType($event)"
            [fcontrol]="questionnaireForm.get('sourceOfProperty')"
            [patchValueObservable]="patchSourceOfPropertyTypesSubject"
            [orientationTop]="true"
            [ngClass]="{
              'is-invalid': questionnaireForm.get('sourceOfProperty').invalid && questionnaireForm.get('sourceOfProperty').touched
            }"
          >
          </app-custom-select>
          <small
            *ngIf="questionnaireForm.get('sourceOfProperty').hasError('required')"
            class="invalid-feedback"
          >
            {{ 'REQUIRED_FIELD' | translate }}
          </small>
        </div>
      </div>

      <!-- OTHER SOURCE OF PROPERTY -->
      <div *ngIf="showOtherSourceOfProperty" class="col-lg-12 col-xxl-12">
        <div class="form-group">
          <label class="label required">
            {{ 'ADDITIONAL_DATA.OTHER_SOURCE_OF_PROPERTY' | translate }}
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="otherSourceOfProperty"
            (input)="shouldEnableButton()"
            [ngClass]="{
              'is-invalid': questionnaireForm.get('otherSourceOfProperty').invalid && questionnaireForm.get('otherSourceOfProperty').touched
            }"
          />
          <small
            *ngIf="questionnaireForm.get('otherSourceOfProperty').hasError('required')"
            class="invalid-feedback"
          >
            {{ 'REQUIRED_FIELD' | translate }}
          </small>
        </div>
      </div>
    </div>
  </form>
</div>
