import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Player } from '@models/player.model';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/local-storage.service';
import { DEPOSIT_CODES } from '@enums/deposit-codes.enum';
import { DepositService } from '@services/deposit.service';
import { Deposit } from '@models/deposit.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-deposit-switch-by-client',
  templateUrl: './deposit-switch-by-client.component.html',
  styleUrls: ['./deposit-switch-by-client.component.scss'],
})
export class DepositSwitchByClientComponent implements AfterViewInit, OnInit {
  public depositForm: FormGroup;
  public playerData: Player;
  public paymentProceed = false;
  public currency = 'EUR';
  public lastUsedPaymentSystem: string;
  public depositTypeToRun: string;

  public paymentPresets: number[] = [500, 1000, 100000];

  public deposit: Deposit;
  public minMaxValueForDeposit = { min: 100, max: 100000 };

  constructor(
    private fb: FormBuilder,
    private appStateFacadeService: AppStateFacadeService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private depositService: DepositService
  ) {}

  @Input() clientName!: string;
  @Input() expandCollapseQuickBar!: (stateOveride?) => void;
  @Input() closeQuickBar!: () => void;
  @Input() stopIntervalChangeItemsInBar!: () => void;
  @Input() isMobile = true;

  ngOnInit(): void {
    this.setUpFormGroupForInput();
    this.lastUsedPaymentSystem = this.localStorageService.getLastUsedDeposit();
    this.depositTypeToRun = this.depositTypeToRunCheck;
  }

  private setUpFormGroupForInput() {
    this.depositForm = this.fb.group({
      depositAmount: ['', [Validators.required, Validators.min(100), Validators.max(10000)]],
    });
  }

  ngAfterViewInit(): void {
    this.appStateFacadeService.getPlayerDataObservable().subscribe((playerData: Player) => {
      this.playerData = playerData;
      if (this.playerData) {
        setTimeout(() => {
          this.currency = this.playerData.currency.toUpperCase().replace('.', '');
        });
      }
    });
  }

  public setDepositAmount(amount: number) {
    this.depositForm.get('depositAmount').setValue(amount);
  }

  public proceedToPayment() {
    if (!this.playerData) {
      this.router.navigate(['login']);
      return;
    }
    this.paymentProceed = true;

    this.isMobile ? this.expandCollapseQuickBar('expand') : this.expandCollapseQuickBar();
  }

  public paymentSuccess() {
    this.paymentProceed = false;
  }

  get depositTypeToRunCheck() {
    switch (true) {
      case this.lastUsedPaymentSystem !== 'IPS_DEPOSIT' && this.clientName !== 'ADMIRAL_MONTENEGRO':
        this.getDeposit(DEPOSIT_CODES.CORVUSPAY_DEPOSIT);
        return 'corvus';
      case this.lastUsedPaymentSystem === 'IPS_DEPOSIT' &&
        this.isMobile &&
        this.clientName !== 'ADMIRAL_MONTENEGRO':
        this.getDeposit(DEPOSIT_CODES.IPS_DEPOSIT);
        return 'ips-bank';
      case this.lastUsedPaymentSystem === 'IPS_DEPOSIT' &&
        !this.isMobile &&
        this.clientName !== 'ADMIRAL_MONTENEGRO':
        this.getDeposit(DEPOSIT_CODES.IPS_DEPOSIT);
        return 'ips-qrcode';
      case this.clientName === 'ADMIRAL_MONTENEGRO':
        this.lastUsedPaymentSystem = null;
        this.getDeposit(DEPOSIT_CODES.ALLSECURE_DEPOSIT);
        return 'allsecure';
      default:
        return 'corvus';
    }
  }

  private getDeposit(depositCode): void {
    if (this.appStateFacadeService.getPlayerData()) {
      this.depositService.getDeposit(depositCode).subscribe(data => {
        if (data) {
          this.setDeposit(data);
        } else {
          this.router.navigate(['/profile/deposit']);
        }
      });
    }
  }

  private setDeposit(deposit): void {
    this.deposit = deposit;
    this.paymentPresets = deposit.predefinedValues;
    this.minMaxValueForDeposit = { min: deposit.minValue, max: deposit.maxValue };

    this.depositForm.get('depositAmount').setValue(deposit.predefinedValues[0] || deposit.minValue);
    this.depositForm
      .get('depositAmount')
      .setValidators([
        Validators.required,
        Validators.min(deposit.minValue),
        Validators.max(deposit.maxValue),
      ]);
    this.depositForm.get('depositAmount').updateValueAndValidity();
  }
}
