import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// doc link: https://millbase.atlassian.net/wiki/spaces/MBAS/pages/2865463297/Solitics

@Injectable({
  providedIn: 'root',
})
export class SoliticsEventReportingService {
  private solitics = (window as any).$solitics;

  constructor() {}

  /**
   * Identifies the brand and the member in Solitics platform.
   * Note: Invoke this method once in user session, when a user logs-in to your web client, before any other event is being reported.
   *
   * @param player logged player data
   * @param customFields (object ex. { "page": "url" }) optional param
   *
   */
  login(player, customFields?): void {
    const loginObj = {
      memberId: player.id.toString(),
      email: player.email,
      brand: environment.soliticsBrand,
      popupToken: environment.soliticsPopupToken,
      branch: environment.soliticsBranch,
      customFields,
    };

    if (this.solitics) {
      this.solitics.loginSuccess(loginObj);
    }
  }

  /**
   * Logs out the member from Solitics platform.
   */
  logout(): void {
    if (this.solitics) {
      this.solitics.onLogout();
    }
  }

  /**
   * A user entered a page that your brand would like to react to/track
   *
   * @param pageName - string name of the page
   * @param customFields (object ex. { "page": "url" }) optional param
   *
   */
  enterPage(pageName, customFields?): void {
    if (this.solitics) {
      this.solitics.onPageEnter(pageName, customFields);
    }
  }

  /**
   * A user left a page that your brand would like to react to/track
   *
   * @param pageName - string name of the page
   * @param customFields (object ex. { "page": "url" }) optional param
   */
  leavePage(pageName, customFields?): void {
    if (this.solitics) {
      this.solitics.onPageLeave(pageName, customFields);
    }
  }

  /**
   * A user clicked a button that your brand would like to track / react to
   *
   * @param objectName - string ex. name of button
   * @param customFields (object ex. { "page": "url" }) optional param
   */
  onClick(objectName, customFields?): void {
    if (this.solitics) {
      this.solitics.onClick(objectName, customFields);
    }
  }

  /**
   * Reports a custom user’s event.
   * Use this if none of the predefined events above matches your need.
   *
   * @param transactionType - String mandatory
   * @param transactionAmount - Number If the event has an amount, specify it here optional param
   * @param customFields (object ex. { "page": "url" }) optional param
   */
  customEvent(transactionType, transactionAmount?, customFields?): void {
    if (this.solitics) {
      this.solitics.emit(transactionType, transactionAmount, customFields);
    }
  }
}
