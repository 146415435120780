<div class="wheels-container">
  <div
    *ngIf="!isMobile && !isLeftArrowHidden"
    class="arrow-wrapper arrow-wrapper_{{ arrowBackgroundColor }} arrow-wrapper_left"
  >
    <div class="arrow-animation icon icon-chevron-left" (click)="scroll('left')"></div>
    <div class="shadow shadow-left shadow-left_{{ arrowBackgroundColor }}"></div>
  </div>
  <div
    id="available-wheels"
    #awailableWheelsContainer
    class="available-wheels"
    [ngClass]="{ 'available-wheels-mobile': isMobile, 'content-center': luckyWheels?.length === 1 }"
  >
    <div class="wheel" *ngFor="let wheel of luckyWheels; let i = index" (click)="select(wheel)">
      <img
        id="spin-animation_{{ wheel.id }}"
        src="assets/images/wheel-of-fortune/wheel.png"
        alt=""
        [ngClass]="{
          'spin-animation': wheel.playerInWheelSegment && wheel.isSpinAvailable,
          'slowly-stop-animation': wheel.playerInWheelSegment && !wheel.isSpinAvailable
        }"
      />
      <div class="wheel-data">
        <div class="text-white">{{ wheel.name }}</div>
        <div class="small text-primary" *ngIf="wheel.playerInWheelSegment && wheel.isSpinAvailable">
          {{ 'LUCKY_WHEEL_SPIN_AVAILABLE' | translate }}
        </div>
        <div class="small text-primary" *ngIf="!wheel.playerInWheelSegment">
          {{ 'LUCKY_WHEEL_SPIN_NOT_AVAILABLE' | translate }}
        </div>
        <div class="small text-primary" *ngIf="wheel.playerInWheelSegment && !wheel.isSpinAvailable">
          {{ 'LUCKY_WHEEL_SPIN_AVAILABLE' | translate }}:
          <span class="text-white font-weight-bold">{{ countdownValues[wheel.id] }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isMobile && !isRightArrowHidden"
    class="arrow-wrapper arrow-wrapper_{{ arrowBackgroundColor }} arrow-wrapper_right"
  >
    <div class="arrow-animation icon icon-chevron-right" (click)="scroll('right')"></div>
    <div class="shadow shadow-right shadow-right_{{ arrowBackgroundColor }}"></div>
  </div>
</div>
