import { FooterColumn } from './footer-column.model';
import { FooterCerts } from './footer-certs.model';
import { FooterIcons } from './footer-icon.model';
import { FooterDescription } from './footer-description.model';

export class Footer {
  public backgroundImg: string;
  public columns: FooterColumn[];
  public certs: FooterCerts[];
  public icons: FooterIcons[];
  public footerDescription: FooterDescription[];

  constructor(data) {
    this.backgroundImg = data.backgroundImg;
    this.columns = [];
    data.columns.forEach(column => this.columns.push(new FooterColumn(column)));
    this.certs = [];
    data.certs.forEach(cert => this.certs.push(new FooterCerts(cert)));
    this.icons = [];
    data.icons.forEach(icon => this.icons.push(new FooterIcons(icon)));
    this.footerDescription = [];
    data.footerDescription.forEach(description =>
      this.footerDescription.push(new FooterDescription(description))
    );
  }
}
