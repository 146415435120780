<nav id="sidebar-right" class="sidebar sidebar-right" [ngClass]="{ expanded: rightBarExpanded }">
  <div>
    <div
      #contentOfMenu
      (swiperight)="closeRightMenuOnSwipe()"
      id="right-sidebar-content"
      class="sidebar-content sidebar-content-cro"
    >
      <div class="c_right-menu-header container-padding">
        <div class="c_right-menu-username">{{ player?.username }}</div>
        <div
          class="c_right-menu-dropdown-detect"
          (click)="openBalanceInformation(); $event.stopPropagation()"
        >
          <div class="dropdownArrow" [ngClass]="{ 'dropdownArrow--is-active': balanceDropDownOpen }"></div>
        </div>
      </div>
      <div class="wallet-container container-padding">
        <div @heightAnimation class="c_balance-info-container" *ngIf="balanceDropDownOpen">
          <div class="u_border-bottom-separator">
            <ul>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'PLAYER_ID',
                    value: player?.id,
                    currency: false
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RIGHT_MENU.MONEY',
                    value: balanceInfo?.balance,
                    currency: true
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RESERVED',
                    value: balanceInfo?.internalReservedFunds,
                    currency: true,
                    link: '/profile/withdrawal/reserved'
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RIGHT_MENU.FOR_PAYOUT',
                    value: balanceInfo?.availableForWithdrawal,
                    currency: true,
                    link: '/profile/withdrawal'
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'CURRENT_WITHDRAWAL_REQUEST_TEXT',
                    value: balanceInfo?.systemReservedFunds,
                    currency: true,
                    link: '/profile/withdrawal/withdrawal-requests'
                  }
                "
              ></li>
            </ul>
          </div>
          <div class="u_border-bottom-separator pt-2">
            <ul>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'BALANCE_INFO_BONUS',
                    value: balanceInfo?.bonus,
                    currency: true
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'ACTIVE_BONUS_SPENT_AMOUNT',
                    value: balanceInfo?.spentAmount,
                    currency: true
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RIGHT_MENU.NEEDED',
                    value: balanceInfo?.bonusRequiredAmount,
                    currency: true
                  }
                "
              ></li>
            </ul>
          </div>
          <ng-container *ngIf="balanceInfo?.freebet">
            <div class="u_border-bottom-separator pt-2">
              <ul>
                <li
                  *ngTemplateOutlet="
                    balanceItem;
                    context: {
                      label: 'BALANCE_INFO_FREEBET',
                      value: balanceInfo?.freebet,
                      currency: true
                    }
                  "
                ></li>
                <li
                  *ngTemplateOutlet="
                    balanceItem;
                    context: {
                      label: 'FREEBET_UNAVAILABLE_PART1',
                      value: balanceInfo?.availableFreebet,
                      currency: true,
                      helpInfo: iconForAdditionalInfo
                    }
                  "
                ></li>
                <ng-template #iconForAdditionalInfo>
                  <i
                    [ngbTooltip]="tipContent"
                    placement="top"
                    [setSVGAttributes]="{ height: '18px' }"
                    [inlineSVG]="'assets/images/mobile-menu-icon/menu_help_l-menu.svg'"
                    fallbackSVG="assets/images/default-left-menu-icon.svg"
                  ></i>
                </ng-template>
                <ng-template #tipContent>
                  {{
                    'FREEBET_UNAVAILABLE'
                      | translate
                        : {
                            unavailable:
                              balanceInfo.freebetDiff | number : '1.2-2' | currency : player?.currency,
                            unwithdrawable:
                              balanceInfo.freebetUnwithdrawableAmount
                              | number : '1.2-2'
                              | currency : player?.currency
                          }
                  }}
                </ng-template>
              </ul>
            </div>
          </ng-container>
          <div class="u_border-bottom-separator pt-2">
            <ul>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'DEPOSIT_METHOD_ADMIRAL_360',
                    value: admiral360BalanceInfo?.balance,
                    currency: true,
                    link: '/profile/withdrawal/admiral-360'
                  }
                "
              ></li>
            </ul>
          </div>
        </div>
        <div *ngIf="!finalizeRegistration" class="pt-4">
          <i
            class="btn_icon btn_icon--is-deposit"
            #depositIcon
            [inlineSVG]="'assets/icons/deposit_btn_icon.svg'"
          >
          </i>
          <div
            appAdmiralButton
            class="u_txt-white"
            type="{{ BUTTON_TYPES.DEPOSIT }}"
            [icon]="depositIcon"
            [marginCostume]="'auto'"
            (click)="
              navigateToLinkInMenu({
                type: 'INTERNAL_LINK_SUBMENU',
                url: '/profile/deposit'
              })
            "
          ></div>
        </div>
        <div *ngIf="finalizeRegistration" class="d-flex flex-column pt-4">
          <div
            class="proceeed-registration-button"
            text="{{ 'PROCEED_REGISTRATION_COMPONENT_BUTTON' }}"
            (click)="
              navigateToLinkInMenu({
                type: 'INTERNAL_LINK_SUBMENU',
                url: '/registration',
                urlType: 'register'
              })
            "
          >
            {{ 'PROCEED_REGISTRATION_COMPONENT_BUTTON' | translate | uppercase }}
            <i
              class="icon"
              [setSVGAttributes]="{ height: '18px' }"
              inlineSVG="assets/icons/skipped-badge.svg"
            ></i>
          </div>
        </div>
      </div>

      <div class="mt-4 container-padding">
        <div class="mb-1 mb-lg-3" *ngFor="let menuItemWrapper of rightMenu?.menuItems">
          <!-- RIGHT MENU ITEMS -->
          <ul class="list-menu">
            <ng-container *ngFor="let menuItem of menuItemWrapper.subMenu">
              <ng-container *ngIf="!(menuItem.requireAuthorized && !player)">
                <li (click)="navigateToLinkInMenu(menuItem)">
                  <a class="list-menu-title mb-0">
                    <div
                      class="c_right-list-menu-item c_right-list-menu-item--is-cro"
                      [ngClass]="{
                        'notifications-container':
                          menuItem.name === 'INTERNAL_LINK_SUBMENU.NOTIFICATIONS_MESSAGES' &&
                          unreadMessages > 0
                      }"
                    >
                      <ng-container *ngIf="menuItem.activeIcon; else iconPlaceholder">
                        <div
                          [inlineSVG]="menuItem.activeIcon"
                          fallbackSVG="assets/images/default-left-menu-icon.svg"
                          class="c_right-menu-icon-cro"
                        ></div>
                      </ng-container>
                      <ng-template #iconPlaceholder>
                        <div class="c_right-menu-icon-cro"></div>
                      </ng-template>
                      <div class="c_right-menu-item-font">{{ menuItem.name | translate }}</div>
                      <div
                        *ngIf="
                          menuItem.name === 'INTERNAL_LINK_SUBMENU.NOTIFICATIONS_MESSAGES' &&
                          unreadMessages > 0
                        "
                        class="notifications-counter"
                      >
                        {{ unreadMessages }}
                      </div>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
      <!-- <hr class="w-100 u_divider-cro container-padding" /> -->
      <div
        class="sidebar-hidden d-flex flex-column align-items-stretch align-content-stretch justify-content-center container-padding"
      >
        <div class="c_right-menu__logout-btn-container">
          <i
            class="btn_icon btn_icon--is-logout"
            #logoutIcon
            [inlineSVG]="'assets/icons/deposit_btn_icon.svg'"
          >
          </i>
          <div
            appAdmiralButton
            text="LOGOUT"
            type="{{ BUTTON_TYPES.LOGOUT }}"
            [icon]="logoutIcon"
            (click)="logout()"
          ></div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template
  #balanceItem
  let-label="label"
  let-value="value"
  let-currency="currency"
  let-link="link"
  let-helpInfo="helpInfo"
>
  <div class="c_balance-row-item">
    <span *ngIf="link" class="c_balance-row-link" (click)="openLinkForLabel(link)">{{
      label | translate
    }}</span>
    <div *ngIf="!link">{{ label | translate }}</div>
    <span *ngIf="helpInfo" class="p-2">
      <ng-container *ngTemplateOutlet="helpInfo"></ng-container>
    </span>
    <div class="c_balance-row-value" *ngIf="!currency">{{ value }}</div>
    <div class="c_balance-row-value" *ngIf="currency">
      {{ value | number : '1.2-2' | currency : player?.currency }}
    </div>
  </div>
</ng-template>
