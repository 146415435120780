<div class="box">
  <header class="py-2 py-lg-3">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{data?.headerName | translate}}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close">
          <img
            *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
            [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
            (click)="closeDialog()"
            alt="Confirmation dialog popup close button"
          />
          <div
            *ngIf="clientName === clientNames.ADMIRAL_PINNBET"
            class="u_pointer"
            (click)="closeDialog()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
      </div>
    </div>
  </header>
  <p class="descParagraph">{{ data?.description | translate}}</p>
</div>
