/**
 * Suported types of items and theres propertyes whitch are required:
 * { itemType: 'downloadApp', active: true, duration: 1000 },
 * { itemType: 'cardDeposit', active: true, duration: 1000 },
 * { itemType: 'liederBoard', active: true, duration: 1000, tournamentID: 640 },
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AppStateFacadeService } from '@state/app-state.facade';
import { LocalStorageService } from '@services/local-storage.service';
import { DateService } from '@services/date.service';
import { combineLatest, fromEvent, interval, Subscription } from 'rxjs';
import { QuickBarService } from './quick-bar.service';

@Component({
  selector: 'app-quick-bar',
  templateUrl: './quick-bar.component.html',
  styleUrls: ['./quick-bar.component.scss'],
})
export class QuickBarComponent implements OnInit, OnDestroy {
  @ViewChild('scrollableDiv') scrollableDiv: ElementRef;
  private scrollEvent$: Subscription;

  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public clientName = this.appStateFacadeService.getClientName();
  public showExpandBtn = true;
  public showDownloadPopup: boolean;
  private lastScrollPosition = 0;

  public activeItemsInBar;
  public quickBarStates: 'hide' | 'show' | 'expand' = 'show';
  private deviceScreenSize: number;
  private activeItemInViewport = 0;
  private interval$: Subscription;
  private automaticScroll: boolean;

  constructor(
    private quickBarService: QuickBarService,
    private appStateFacadeService: AppStateFacadeService,
    private localStorageService: LocalStorageService,
    private dateService: DateService,
    private elementRef: ElementRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.deviceScreenSize = event.target.innerWidth;
    this.setContainerDimensions();
  }

  ngOnDestroy(): void {
    if (this.scrollEvent$) {
      this.scrollEvent$.unsubscribe();
    }
  }

  ngOnInit(): void {
    combineLatest([this.quickBarService.quickBarStates$, this.quickBarService.activeItemsInBar$]).subscribe(
      ([state, items]) => {
        this.quickBarStates = state;
        this.activeItemsInBar = items;
        this.deviceScreenSize = window.innerWidth;
        if (this.activeItemsInBar.length > 0) {
          this.calculateXpositionOfItemsInBar();
          this.setContainerDimensions();
          /**
           * Cover case when quick bar have only one item and it is download app item
           */
          if (
            (this.activeItemsInBar.length === 1 && this.activeItemsInBar[0]['itemType'] === 'downloadApp') ||
            state === 'hide'
          ) {
            this.showExpandBtn = false;
          } else {
            this.showExpandBtn = true;
          }
        } else {
          // if there is no items in quick bar, hide handle bar
          this.showExpandBtn = false;
        }
      }
    );
  }

  private calculateXpositionOfItemsInBar() {
    // TODO prepare calculation of position only active items
    if (this.quickBarStates !== 'hide') {
      this.activeItemsInBar.forEach((item, index) => {
        const widthOfElements = this.isMobile ? this.deviceScreenSize : 1449;
        item['xPosition'] = widthOfElements * index;
      });
      this.activateIntervalChangeItemsInBar();
    }
  }

  private activateIntervalChangeItemsInBar() {
    if (this.interval$) {
      this.interval$.unsubscribe();
    }
    this.interval$ = interval(5000).subscribe(() => {
      this.automaticScroll = true;
      this.activeItemInViewport =
        this.activeItemsInBar.length - 1 === this.activeItemInViewport ? 0 : this.activeItemInViewport + 1;
      this.scrollToPosition(this.activeItemInViewport);
      setTimeout(() => (this.automaticScroll = false), 1000);
    });
  }

  private scrollToPosition(itemPositionInArray) {
    this.scrollableDiv.nativeElement.scrollTo({
      left: this.activeItemsInBar[itemPositionInArray]['xPosition'],
      behavior: 'smooth',
    });
  }

  public stopIntervalChangeItemsInBar() {
    if (this.interval$) {
      this.interval$.unsubscribe();
    }
  }

  private setContainerDimensions(): void {
    // get height of element in dom by id headerContainer
    const headerHeight = document.getElementById('headerContainer').offsetHeight;
    this.elementRef.nativeElement.style.setProperty('top', `${headerHeight}px`);

    this.elementRef.nativeElement.style.setProperty(
      '--width-of-device-screen',
      `${this.isMobile ? this.deviceScreenSize : this.deviceScreenSize - 75}px`
    );
    this.elementRef.nativeElement.style.setProperty(
      '--sum-of-all-items-width',
      `${this.deviceScreenSize * this.activeItemsInBar.length}px`
    );
    if (this.activeItemsInBar.length > 1) {
      this.elementRef.nativeElement.style.setProperty('--height-of-quick-bar', `70px`);
    }
  }

  public closeQuickBar(): void {
    this.quickBarService.closeQuickBar();
  }

  public expandCollapseQuickBar(overideState?): void {
    if (this.quickBarStates === 'hide') {
      this.quickBarStates = 'show';
      this.localStorageService.removeFromLocalStorage('showQuickBar');
      // this.appStateFacadeService.setAppQuickBarStatus(true);
      this.quickBarService.setQuickBarStates('show');
    } else {
      this.quickBarStates = this.quickBarStates === 'show' ? 'expand' : 'show';
      this.quickBarService.setQuickBarStates(this.quickBarStates);
    }

    if (overideState === 'expand') {
      this.stopIntervalChangeItemsInBar();
      this.quickBarStates = overideState;
    }

    // Start again to change items in bar when quick bar is in show state
    this.quickBarStates === 'show'
      ? this.activateIntervalChangeItemsInBar()
      : this.stopIntervalChangeItemsInBar();
  }
}
