import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AppStateFacadeService } from '@state/app-state.facade';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private renderer: Renderer2;

  private doNotChangeScroll = false;

  constructor(
    private helperService: HelpersService,
    private appStateFacadeService: AppStateFacadeService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  changeScrollGuard(newValue: boolean) {
    this.doNotChangeScroll = newValue;
  }

  disableOrEnableBodyScroll(status: boolean) {
    /**
     * Boolean that works as a guard to scroll changes
     * If a user is searching games and then opens and closes sidebar scroll is enabled but it should be disabled
     * So when a user is searching doNotChangeScroll will be true and when he stops searching it will be set to false
     */
    if (this.doNotChangeScroll) {
      return;
    }
    /**
     * scrolling work with two type of parameters "window.pageYOffset" and "document.body.style.top"
     * on expand side bar document.body is 0 and we pick value from window.pageYOffset
     * on collapse window.pageYOffset is 0 and then we "parse" value from expand side bar document.body witch
     * s in format "-[some number] px" because of that we have to splice it to cutout "-" and "px"
     * in purpose to get "clean value"
     */
    const scrollValue = Number(document.body.style.top.slice(1, document.body.style.top.length - 2));

    const scrollPosition = window.scrollY || scrollValue;
    const style = {
      overflow: 'hidden scroll',
      position: 'fixed',
      top: `-${scrollPosition}px`,
      width: '100%',
    };
    if (status) {
      Object.keys(style).forEach(key => {
        this.renderer.setStyle(document.body, `${key}`, `${style[key]}`);
      });
      // this is logic for version of iOS 15, changing status bar position -  fix problem with empty bars when side bars are open
      if (this.appStateFacadeService.getIsMobileStatus() && this.helperService.iOSversion15()) {
        this.renderer.setStyle(document.documentElement, 'height', '100vh');
        this.renderer.removeStyle(document.body, 'overflow');
      }
    } else {
      Object.keys(style).forEach(key => {
        this.renderer.removeStyle(document.body, `${key}`);
      });
      // this is logic for version of iOS 15, changing status bar position - fix problem with empty bars when side bars are open
      if (this.appStateFacadeService.getIsMobileStatus() && this.helperService.iOSversion15()) {
        this.renderer.removeStyle(document.documentElement, 'height');
      }
      window.scrollTo(0, scrollPosition);
    }
  }
}
