import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PRODUCT_CODES } from '@enums/product-codes.enum';
import { GAME_TYPE } from '@enums/game-type.enum';
import { LobbyFavoriteGame } from '@models/lobby-game-favorite.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LobbyService } from '@services/lobby.service';
import { EventListenerService } from '@services/event-listener.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ToasterService } from '@services/toaster.service';
import { TournamentService } from '@services/tournament.service';
import { Subscription } from 'rxjs';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';
import { LOBBY_CODES } from '@enums/lobby-codes.enum';
import { LOBBY_PATHS } from '@enums/lobby-paths.enum';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { GameDataModel } from '@models/game-data.model';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-croatia-game-info',
  templateUrl: './croatia-game-info.dialog.html',
  styleUrls: ['./croatia-game-info.dialog.scss'],
})
export class CroatiaGameInfoDialog implements OnInit, OnDestroy {
  @Input() game: GameDataModel;
  @Input() lobbyPath;
  @Input() tournament;
  public isLoggedIn = this.localStorageService.isLoggedIn();
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public imagesBaseUrl = environment.imagesBaseUrl;
  public imgSource: string;
  private resize$: Subscription;
  public gamePlayProblemLinkShown;
  public readonly gameType = GAME_TYPE;

  private initialFavoriteStatus: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private localStorageService: LocalStorageService,
    private toasterService: ToasterService,
    private lobbyService: LobbyService,
    private translate: TranslateService,
    private eventListenerService: EventListenerService,
    private tournamentService: TournamentService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.initialFavoriteStatus = this.game.favorite;
    if (this.isMobile) {
      this.createUrlForMobileGamePlay();
    }

    // TODO please test this if everything work as it should after pinnbet changes are deployed on stage
    if (this.game.tournament && !this.tournament) {
      this.getTournamentInfo();
    }

    // disable demo button for tournament games
    if (this.game.tournament && this.router.url.includes('tournament')) {
      this.game.demoIncluded = false;
    }

    this.checkGameModel();
    this.setResizeSubscription();
    this.onResize();
  }

  ngOnDestroy(): void {
    this.resize$.unsubscribe();
    // Only if favorite status changed reload games
    if (this.initialFavoriteStatus !== this.game.favorite) {
      this.lobbyService.setReloadGamesInGamePage({
        sectionCode: 'POPULAR_FAVORITE',
        providerName: null,
        specialGamePage: null,
      });

      if (this.appStateFacadeService.getSearchStatus()) {
        // We need to reload searched games bacause we need to update favorite
        // statuses in searched games as well
        this.lobbyService.setReloadGamesInGamePage({
          sectionCode: 'POPULAR_FAVORITE',
          providerName: null,
          // Pass 'search' to reload searched games
          specialGamePage: 'search',
        });
      }
    }
  }

  private checkGameModel(): void {
    if (!(this.game instanceof GameDataModel)) {
      this.game = new GameDataModel().deserialize(this.game);
    }
  }

  // create bimran urls for mobile game play
  private createUrlForMobileGamePlay(): void {
    this.checkIfGamePlayProblemLinkIsShown();

    if (
      this.game.vendorName === 'ELBET' ||
      this.game.vendorName === 'NSOFT' ||
      this.game.vendorName === 'Golden Race' ||
      this.game.vendorName === 'Spribe' ||
      this.game.vendorName === 'EXEFEED'
    ) {
      this.gamePlayProblemLinkShown = false;
      return;
    }
  }

  private checkIfGamePlayProblemLinkIsShown(): void {
    this.gamePlayProblemLinkShown = this.game.lobbies.find(
      lobby => lobby === LOBBY_CODES.CASINO || lobby === LOBBY_CODES.LIVE_CASINO
    );
  }

  // get tournament info
  private getTournamentInfo(): void {
    this.tournamentService.getGameTournamentInfo(this.game.id).subscribe(tournamentInfo => {
      this.tournament = tournamentInfo;

      this.tournamentService.getTournament(this.tournament.tournamentId).subscribe(tournament => {
        if (tournament) {
          this.tournament.productCode = tournament.productCode;
        }
      });
    });
  }

  // resize subsribe
  private setResizeSubscription() {
    this.resize$ = this.eventListenerService.resizeEvent$.subscribe({
      next: this.onResize.bind(this),
    });
  }

  onResize() {
    this.imgSource = window.innerWidth > 600 ? 'img' : 'mobile';
  }

  // close dialog
  public closeDialog(): void {
    this.activeModal.close();
  }

  // set favorite game
  public setFavoriteGame(): void {
    // proceed registration
    const player = this.appStateFacadeService.getPlayerData();
    if (player && !player.registrationCompleted) {
      this.navigationService.checkIfOnlyF2FLeftAndInProcess(player);
      return;
    }

    const game: LobbyFavoriteGame = {
      gameId: this.game.id,
      isFavorite: !this.game.favorite,
    };
    this.lobbyService.setFavoriteGame(game).subscribe({
      next: data => {
        this.game.favorite = !this.game.favorite;
      },
      error: error => {
        this.toasterService.showError(this.translate.instant('UNABLE_TO_SET_FAVORITE_GAME'));
      },
    });
  }

  // navigate to mobile or desktop game play
  public navigateToGamePlay(gameType: GAME_TYPE): void {
    // proceed registration
    const player = this.appStateFacadeService.getPlayerData();
    if (gameType === GAME_TYPE.FOR_REAL && player && !player.registrationCompleted) {
      this.closeDialog();
      this.navigationService.checkIfOnlyF2FLeftAndInProcess(player);
      return;
    }

    // If playing for cash, check if game is allowed in country.
    if (gameType === GAME_TYPE.FOR_REAL || gameType === GAME_TYPE.FOR_REAL_EXTERNAL) {
      // Property 'allowedInGeolocation' is not defined, if game is sent via websocket.
      // In that case, we need to check manually if game is allowed in country.
      if (typeof this.game.allowedInGeolocation === 'undefined') {
        this.lobbyService.checkIfGameAllowedInCountry(this.game.id).subscribe((isAllowed: boolean) => {
          this.continueNavigationToGamePlay(gameType, isAllowed);
        });
      } else {
        this.continueNavigationToGamePlay(gameType, this.game.allowedInGeolocation);
      }
    } else {
      // Demo play.
      this.continueNavigationToGamePlay(gameType, true);
    }
  }

  private continueNavigationToGamePlay(gameType: GAME_TYPE, isAllowedInCountry: boolean): void {
    if (!isAllowedInCountry) {
      this.toasterService.showError(this.translate.instant('GAME_NOT_ALLOWED_ON_GEOLOCATION'));
      return;
    }

    if (this.game.forceExternalLink || gameType === GAME_TYPE.FOR_REAL_EXTERNAL) {
      this.lobbyService[gameType !== GAME_TYPE.FOR_FUN ? 'getGameLaunchUrlCash' : 'getGameLaunchUrlDemo'](
        this.game.id
      ).subscribe({
        next: data => {
          const gameUrl = data['message'];
          window.location.href = gameUrl;
        },
        error: error => {
          if (error.status === 404) {
            return;
          }
          this.toasterService.showError(
            this.translate.instant('GAME_RESPONSE_ERROR', { gameName: this.game.name })
          );
        },
      });
      return;
    }

    const type = gameType === GAME_TYPE.FOR_REAL ? 'cash' : 'demo';
    if (this.lobbyPath) {
      switch (this.game.vendorName) {
        case 'ELBET':
          this.router.navigate([`${this.lobbyPath}/gameplay/elbet/${this.game.externalProductId}`]);
          this.closeDialog();
          return;
        case 'NSOFT':
          this.router.navigate([`${this.lobbyPath}/gameplay/nsoft/${this.game.externalProductId}`]);
          this.closeDialog();
          return;
        case 'Golden Race':
          this.router.navigate([`${this.lobbyPath}/gameplay/golden-race/${this.game.externalProductId}`]);
          this.closeDialog();
          return;
        case 'Spribe':
          this.router.navigate([`${this.lobbyPath}/gameplay/spribe/${this.game.externalProductId}`]);
          this.closeDialog();
          return;
        case 'Pragmatic Play':
          if (this.game.code === 'spaceman' && !this.game.forceExternalLink) {
            environment.clientName === CLIENT_NAMES.ADMIRAL_NIGERIA
              ? this.router.navigate([`virtual/gameplay/pragmatic/${this.game.code}`])
              : this.router.navigate([`mini-games/gameplay/pragmatic/${this.game.code}`]);
          } else {
            this.isMobile
              ? this.startMobileGamePlayWithLobbyPath(type)
              : this.startDesktopGamePlayWithLobbyPath(type);
          }
          this.closeDialog();
          return;
        default:
          this.isMobile
            ? this.startMobileGamePlayWithLobbyPath(type)
            : this.startDesktopGamePlayWithLobbyPath(type);
          this.closeDialog();
          return;
      }
    } else {
      this.isMobile
        ? this.startMobileGamePlayWithoutLobbyPath(type)
        : this.startDesktopGamePlayWithoutLobbyPath(type);
      this.closeDialog();
    }
  }

  // start mobile game with looby path
  private startMobileGamePlayWithLobbyPath(type): void {
    if (!this.game.forceExternalLink) {
      this.router.navigate([`${this.lobbyPath}/gameplay/mobile/${type}/${this.game.id}`]);
    }
  }

  // start mobile game without looby path
  private startMobileGamePlayWithoutLobbyPath(type): void {
    if (!this.game.forceExternalLink) {
      this.startGameWithoutLobbyPath('mobile', type);
    }
  }

  // start desktop game with looby path
  private startDesktopGamePlayWithLobbyPath(type): void {
    this.router.navigate([`${this.lobbyPath}/gameplay/desktop/${type}/${this.game.id}`]);
  }

  // start desktop game without looby path
  private startDesktopGamePlayWithoutLobbyPath(type): void {
    this.startGameWithoutLobbyPath('desktop', type);
  }

  private startGameWithoutLobbyPath(device, type): void {
    switch (this.tournament.productCode) {
      case PRODUCT_CODES.CASINO:
        environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
        environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
          ? this.router.navigate([`slot/gameplay/${device}/${type}/${this.game.id}`])
          : this.router.navigate([`casino/gameplay/${device}/${type}/${this.game.id}`]);
        return;
      case PRODUCT_CODES.LIVE_CASINO:
        environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
        environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
          ? this.router.navigate([`live-dealer/gameplay/${device}/cash/${this.game.id}`])
          : this.router.navigate([`casino-live/gameplay/${device}/cash/${this.game.id}`]);
        return;
      case PRODUCT_CODES.ELBET:
        this.game.code === 'ROCKET_MAN' && environment.clientName !== CLIENT_NAMES.ADMIRAL_NIGERIA
          ? this.router.navigate([`mini-games/gameplay/elbet/${this.game.externalProductId}`])
          : this.router.navigate([`virtual/gameplay/elbet/${this.game.externalProductId}`]);
        break;
      case PRODUCT_CODES.GOLDEN_RACE:
        this.router.navigate([`virtual/gameplay/golden-race/${this.game.name}`]);
        break;
      case PRODUCT_CODES.SEVEN_AGGREGATOR:
        this.router.navigate([`virtual/gameplay/nsoft/${this.game.externalProductId}`]);
        break;
      case PRODUCT_CODES.SPRIBE:
        this.router.navigate([`mini-games/gameplay/spribe/${this.game.externalProductId}`]);
        break;
      case PRODUCT_CODES.EXEFEED:
        this.router.navigate([`virtual/gameplay/${device}/${type}/${this.game.id}`]);
        this.closeDialog();
        break;
    }
  }

  // set previous route for after login return url
  public goToLogin(): void {
    const lobbyPath = this.lobbyPath ? this.lobbyPath : LOBBY_PATHS[this.game.lobbies[0]];
    this.appStateFacadeService.addPreviousRoute(
      `${lobbyPath}/${this.game.providerName.toLowerCase()}/${this.game.code}`
    );
    this.closeDialog();
  }
}
