export enum MESSAGE_TYPE {
  GENERAL = 'General',
  BONUS = 'Bonus',
  FREESPIN = 'Freespin',
  JACKPOT = 'Jackpot',
  FREEBET = 'Freebet',
  MAINTENANCE = 'Maintenance',
  CASHBACK = 'Cashback',
  LUCKY_WHEEL = 'LuckyWheel',
  PLAYER_REGISTRATION = 'Player Registration',
}
