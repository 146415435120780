import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { TermsConditions } from 'src/app/modules/terms-conditions/terms-conditions/models/terms-conditions.model';

@Injectable({
  providedIn: 'root',
})
export class TermsConditionsService {
  constructor(private http: HttpClient) {}

  /**
   * Update players terms and conditions
   * @param data - object contains internal id, value and accepted flag
   */
  updateTermsConditions(data: TermsConditions): Observable<TermsConditions> {
    return this.http.put<TermsConditions>(environment.apiUrl + `terms-and-conditions/${data.id}`, data);
  }

  /**
   * Get active terms and conditions
   */
  getActiveTermsConditions(): Observable<TermsConditions> {
    return this.http.get<TermsConditions>(environment.apiUrl + 'terms-and-conditions');
  }
}
