import { Component, OnInit, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CroPoliticalExposureDataModel } from '@models/croatia-additional-data.model';
import { TranslateService } from '@ngx-translate/core';
import { PlayerService } from '@services/player.service';
import { ToasterService } from '@services/toaster.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-political-exposure-form',
  templateUrl: './political-exposure-form.component.html',
  styleUrls: ['./political-exposure-form.component.scss']
})
export class PoliticalExposureFormComponent implements OnInit {
  public questionnaireForm: FormGroup;
  public politicalQuestion: boolean;
  public politicalQuestions: boolean;
  public showOtherSourceOfProperty: boolean;
  public buttonDisabled: EventEmitter<boolean> = new EventEmitter();

  // For selecting public exposure type.
  public exposureTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public exposureTypesObservable: Observable<{ value: string; text: string }[]>;
  public patchExposureTypesSubject = new BehaviorSubject('');
  // For selecting public duty type.
  public publicDutyTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public publicDutyTypesObservable: Observable<{ value: string; text: string }[]>;
  public patchPublicDutyTypesSubject = new BehaviorSubject('');
  // For selecting source of income type.
  public sourceOfPropertyTypes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public sourceOfPropertyTypesObservable: Observable<{ value: string; text: string }[]>;
  public patchSourceOfPropertyTypesSubject = new BehaviorSubject('');

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private playerService: PlayerService,
    private toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this.createQuestionnaireForm();
    this.setSubscriptions();
    this.loadPlayerAdditionalData();
    this.loadAdditionalPoliticalInfo();
  }

  private createQuestionnaireForm(): void {
    this.questionnaireForm = this.fb.group({
      politician: [''],
      politicianRadioBtn: [''],
      exposure: [''],
      publicDuty: [''],
      sourceOfProperty: [''],
      otherSourceOfProperty: [''],
    });

    this.translateService.get('CHOOSE').subscribe(data => {
      this.patchExposureTypesSubject.next(data);
      this.patchPublicDutyTypesSubject.next(data);
      this.patchSourceOfPropertyTypesSubject.next(data);
    });
  }

  private setSubscriptions() {
    this.setExposureTypesSubscription();
    this.setPublicDutyTypesSubscription();
    this.setSourceOfPropertyTypesSubscription();
  }

  private setExposureTypesSubscription(): void {
    this.exposureTypesObservable = this.exposureTypes
      .asObservable()
      .pipe(map(items => items.map(item => ({ value: item, text: this.translateService.instant(item) }))));
  }

  private setPublicDutyTypesSubscription(): void {
    this.publicDutyTypesObservable = this.publicDutyTypes
      .asObservable()
      .pipe(map(items => items.map(item => ({ value: item, text: this.translateService.instant(item) }))));
  }

  private setSourceOfPropertyTypesSubscription(): void {
    this.sourceOfPropertyTypesObservable = this.sourceOfPropertyTypes
      .asObservable()
      .pipe(map(items => items.map(item => ({ value: item, text: this.translateService.instant(item) }))));
  }

  private loadPlayerAdditionalData() {
    this.playerService.getAdditionalPlayerData().subscribe(additionalData => {

      // Patch political exposure question checkbox.
      this.questionnaireForm.patchValue({
        politician: additionalData.politician,
        politicianRadioBtn: additionalData.politician === true ? 'YES' : 'NO',
        exposure: additionalData.exposure,
        publicDuty: additionalData.publicDuty,
        sourceOfProperty: additionalData.sourceOfProperty,
        otherSourceOfProperty: additionalData.otherSourceOfProperty
      });

      // Show political questions, if present.
      if (additionalData.politician) {
        this.politicalQuestions = true;
        // Show other source of property if exist.
        if (additionalData.otherSourceOfProperty) {
          this.showOtherSourceOfProperty = true;
        }
        this.buttonDisabled.next(false);
      }

      // patch exposureType select field
      if (additionalData.exposure) {
        this.translateService.get(additionalData.exposure).subscribe(data => {
          this.patchExposureTypesSubject.next(data);
        });
      }

      // patch publicDutyType select field
      if (additionalData.publicDuty) {
        this.translateService.get(additionalData.publicDuty).subscribe(data => {
          this.patchPublicDutyTypesSubject.next(data);
        });
      }

      // patch sourceOfPropertyType select field
      if (additionalData.sourceOfProperty) {
        this.translateService.get(additionalData.sourceOfProperty).subscribe(data => {
          this.patchSourceOfPropertyTypesSubject.next(data);
        });
      }
    });
  }

  private loadAdditionalPoliticalInfo() {
    this.playerService.getAddtionalPoliticianInfoOptions().subscribe(data => {
      this.exposureTypes.next(data.politicalExposureTypes);
      this.publicDutyTypes.next(data.politicalPublicDutyTypes);
      this.sourceOfPropertyTypes.next(data.politicalSourceOfPropertyTypes);
    });
  }

  public tooglePoliticalQuestion(): void {
    this.politicalQuestion = !this.politicalQuestion;
  }

  public changePolitician(value: boolean): void {
    this.politician.setValue(value);
    this.politicalQuestions = value;
    this.validatePoliticalQuestions(value);
  }

  private validatePoliticalQuestions(flag: boolean) {
    ['exposure', 'publicDuty', 'sourceOfProperty'].forEach(formControlName => {
      flag ? this.enableFormControl(formControlName) : this.disableFormControl(formControlName);
    });
  }

  public onSelectExposureType(selectedValue: string) {
    this.questionnaireForm.patchValue({ exposure: selectedValue });
    this.shouldEnableButton();
  }

  public onSelectPublicDutyType(selectedValue: string) {
    this.questionnaireForm.patchValue({ publicDuty: selectedValue });
    this.shouldEnableButton();
  }

  public onSelectSourceOfPropertyType(selectedValue: string) {
    this.questionnaireForm.patchValue({ sourceOfProperty: selectedValue });
    this.showOtherSourceOfProperty = selectedValue === 'POLITICAL.SOURCE.OTHER';
    this.showOtherSourceOfProperty ? this.enableFormControl('otherSourceOfProperty') : this.disableFormControl('otherSourceOfProperty');
  }

  public shouldEnableButton(): void {
    this.questionnaireForm.valid ? this.buttonDisabled.next(false) : this.buttonDisabled.next(true);
  }

  private enableFormControl(formControlName: string): void {
    const control = this.questionnaireForm.get(formControlName);
    control.clearValidators();
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
    control.enable();
    this.shouldEnableButton();
  }

  private disableFormControl(formControlName: string): void {
    const control = this.questionnaireForm.get(formControlName);
    control.clearValidators();
    control.updateValueAndValidity();
    control.disable();
    this.buttonDisabled.next(false);
  }

  public submitData(): void {
    const data = new CroPoliticalExposureDataModel(this.questionnaireForm.value);
    this.playerService.savePoliticianInfo(data).subscribe(response => {
      this.toaster.showSuccess(response.message);
      this.questionnaireForm.markAsPristine();
    });
  }

  // getters
  public get politician(): AbstractControl {
    return this.questionnaireForm.get('politician');
  }

}
