import { Component, OnInit, OnDestroy, ElementRef, Input, ViewChild, Renderer2 } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Menu } from '@models/config-data/menu/menu.model';
import { ConfigData } from '@models/config-data/config-data.model';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { environment } from 'src/environments/environment';
import { HelpersService } from '@services/helpers.service';
import { Player } from '@models/player.model';
import { AppStateFacadeService } from '@state/app-state.facade';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  @ViewChild('contentOfMenu', { static: true }) contentOfMenu: ElementRef;
  @Input() player: Player;
  public leftBarExpanded: boolean;
  public imagesBaseUrl = environment.imagesBaseUrl;
  private isMobile = this.appStateFacadeService.getIsMobileStatus();

  public leftMenu: Menu;
  private configDataObservable: Observable<any>;
  private configData$: Subscription;
  private leftBarStatus$: Subscription;

  constructor(
    private renderer: Renderer2,
    private appStateFacadeService: AppStateFacadeService,
    public navigationService: NavigationService,
    public helpersService: HelpersService
  ) {}

  ngOnInit(): void {
    this.setupStore();
    this.setConfigDataSubscription();
    this.setLeftMenuSubscription();
  }

  ngOnDestroy(): void {
    this.configData$.unsubscribe();
    this.leftBarStatus$.unsubscribe();
  }

  // setup store
  private setupStore(): void {
    this.configDataObservable = this.appStateFacadeService.getConfigData();
  }

  // set config subscription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable
      .pipe(filter(configData => configData !== null))
      .subscribe((configData: ConfigData) => {
        this.leftMenu = configData.appMenus.leftMenu;
      });
  }

  private setLeftMenuSubscription(): void {
    this.leftBarStatus$ = this.appStateFacadeService.observeLeftBarStatus().subscribe((status: boolean) => {
      this.leftBarExpanded = status;
      this.renderer.setProperty(this.contentOfMenu.nativeElement, 'scrollTop', '0');
    });
  }

  public handleLeftSideBar() {
    this.appStateFacadeService.expandCollapseLeftSideBar();
  }

  public navigateToLinkInMenu(link, event: PointerEvent) {
    event.preventDefault();
    this.appStateFacadeService.collapseAllSideBars();
    this.navigationService.navigate(link);
  }

  // close left menu on swipe
  public closeLeftMenuOnSwipe() {
    if (!this.leftBarExpanded) {
      return;
    }

    if (!this.appStateFacadeService.getIsLeftSidebarPresent() && this.isMobile) {
      this.handleLeftSideBar();
    }
  }
}
