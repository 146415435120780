import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IMAGE_ORIENTATION } from '@enums/image-orientation.enum';
import { FileUploadService } from '@services/file-upload.service';
import { ImageCompressService } from '@services/image-compress.service';
import { heightAnimation } from '../../animations/animations';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  animations: [heightAnimation('400ms')],
})
export class FileUploadComponent implements OnInit {
  @Input()
  public uploadForm: FormGroup;
  @ViewChild('fileInput')
  public fileInput: ElementRef;
  @Output()
  public closeEvent = new EventEmitter<string>();
  public imagePreview = '';
  public imagePreviewSet = false;

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private imageCompressService: ImageCompressService
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  // Create form
  public createForm() {
    this.uploadForm = this.fb.group({
      verificationDocument: null,
    });
  }

  // On click
  public onClick(e) {
    const element: HTMLElement = document.getElementById('hiddenImgInput') as HTMLElement;

    element.click();
  }

  // On file change
  public onFileChange(event) {
    const reader = new FileReader();

    const file = (event.target as HTMLInputElement).files[0];
    if (file instanceof Blob) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const result = String(reader.result);
        this.imagePreview = result;
        this.imagePreviewSet = true;
        // this.imageCompressService.getImageOrientation(file).then(orientation => {
        this.imageCompressService
          .compressImage(result, IMAGE_ORIENTATION.Default, 50, 50)
          .then(compressedImage => {
            const base64 = compressedImage.split(',')[1];

            this.uploadForm.get('verificationDocument').setValue({
              filename: file.name,
              filetype: file.type,
              value: base64,
            });
          });
        // });
      };
    }
  }

  // On submit
  public onSubmit() {
    const formModel = this.uploadForm.value;
    const imgObj = {
      data: formModel.verificationDocument.value,
      fileName: formModel.verificationDocument.filename,
      type: formModel.verificationDocument.filetype,
    };
    this.handleFileSubmit(imgObj);
  }

  // Handle file submit
  public handleFileSubmit(imgObj) {
    this.fileUploadService.uploadFile(imgObj).subscribe(data => {
      this.clearFile();
      this.closeEvent.emit('SUCCESS');
    });
  }

  // Clear file
  public clearFile() {
    this.uploadForm.get('verificationDocument').setValue(null);
    this.fileInput.nativeElement.value = '';
    this.imagePreviewSet = false;
  }
}
