import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Language } from '@models/language.model';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { EventListenerService } from '@services/event-listener.service';
import { Subscription } from 'rxjs';
import { HelpersService } from '@services/helpers.service';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { SalesForceChatService } from '@services/sales-force-chat.service';
import { InfobipChatService } from '../../../core/services/infobitp-chat.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
  public activeLanguage: string;
  public languagesList: Language[];
  private beforeUnload$: Subscription;
  private unreadChat$: Subscription;
  public unreadChat;
  public chatEnabled: boolean;
  public clientName = environment.clientName;
  public clients = CLIENT_NAMES;
  public isCroatianStagingEnv = false;
  private isInfobipChatEnabled = false;

  constructor(
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private appStateFacadeService: AppStateFacadeService,
    private eventListenerService: EventListenerService,
    private helpersService: HelpersService,
    private renderer: Renderer2,
    private salesForceChatService: SalesForceChatService,
    private infobitpChatService: InfobipChatService
  ) {}

  ngOnInit(): void {
    this.getLanguages();
    this.setApplicationLanguage();
    this.setBeforeUnloadSubscription();
    this.enableChat();
    this.infobitpChatService.hideInfobipChat();
    this.isCroatianStagingEnv = this.infobitpChatService.isCroatianStagingEnv();
  }

  ngOnDestroy(): void {
    this.beforeUnload$.unsubscribe();
    if (this.unreadChat$) {
      this.unreadChat$.unsubscribe();
    }
  }

  // get languages
  private getLanguages(): void {
    this.languageService.getLanguages().subscribe(data => {
      this.languagesList = data;
    });
  }

  // set app language
  private setApplicationLanguage(): void {
    this.activeLanguage = this.localStorageService.getLanguage();
  }

  private setBeforeUnloadSubscription(): void {
    this.beforeUnload$ = this.eventListenerService.beforeUnload$.subscribe({
      next: () => {
        window.scrollTo(0, 0);
      },
    });
  }

  // change language
  public changeLanguage(language: string) {
    if (language !== this.activeLanguage) {
      this.activeLanguage = language;
      this.appStateFacadeService.expandCollapseLeftSideBar();
      this.localStorageService.setLanguage(language);
      this.translate.getTranslation(language);
      this.translate.use(language);
      location.reload();
    }
  }

  private enableChat(): void {
    this.chatEnabled = this.helpersService.chatEnabled();
    if (this.chatEnabled) {
      if ((window as any).HelpCrunch) {
        this.setHelpCrunchChatEvents();
      }
      if ((window as any).embedded_svc) {
        this.setSalesForceChatEventsSubscription();
      }
      if ((window as any).Tawk_API) {
        this.setTawkEvents();
      }
    }
  }

  public toggleChat(): void {
    const isChatWindowOpen = this.appStateFacadeService.isChatWindowOpen();
    if (isChatWindowOpen) {
      this.helpersService.closeChat(this.renderer, this.appStateFacadeService);
    } else {
      this.helpersService.openChat(this.renderer, this.appStateFacadeService);
    }
  }

  private setHelpCrunchChatEvents() {
    const that = this;
    (window as any).HelpCrunch('onChatOpen', function () {
      that.appStateFacadeService.setChatWindowOpen(true);
    });
    (window as any).HelpCrunch('onChatClose', function () {
      that.appStateFacadeService.setChatWindowOpen(false);
    });
    (window as any).HelpCrunch('onNewUnreadChat', function (event) {
      if (event.unreadChats > 0) {
        that.unreadChat = event.unreadChats;
      } else {
        that.unreadChat = 0;
      }
    });
  }

  private setSalesForceChatEventsSubscription() {
    this.unreadChat$ = this.salesForceChatService.unreadChat$.subscribe(unreadChat => {
      this.unreadChat = unreadChat;
    });
    this.salesForceChatService.setSalesForceChatEvents(this.renderer);
  }

  private setTawkEvents() {
    const that = this;
    (window as any).Tawk_API.onChatMaximized = function () {
      that.appStateFacadeService.setChatWindowOpen(true);
      if (that.unreadChat) {
        that.unreadChat = 0;
      }
    };
    (window as any).Tawk_API.onChatMinimized = function () {
      that.appStateFacadeService.setChatWindowOpen(false);
    };
    (window as any).Tawk_API.onChatMessageAgent = function () {
      that.unreadChat = 1;
    };
  }

  public toggleInfobipChat(): void {
    this.isInfobipChatEnabled = !this.isInfobipChatEnabled;
    this.isInfobipChatEnabled
      ? this.infobitpChatService.showInfobipChat()
      : this.infobitpChatService.hideInfobipChat();
  }
}
