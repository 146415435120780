import { Component, Input } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kliker-xbon-deposit-status',
  templateUrl: './kliker-xbon-deposit-status.dialog.html',
  styleUrls: ['./kliker-xbon-deposit-status.dialog.scss'],
})
export class KlikerXbonDepositStatusDialog {
  @Input() data: any;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  constructor(public activeModal: NgbActiveModal) {}

  // close modal
  public closeModal(): void {
    this.activeModal.close();
  }
}
