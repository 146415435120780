import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tournament-info',
  templateUrl: './tournament-info.component.html',
  styleUrls: ['./tournament-info.component.scss'],
})
export class TournamentInfoComponent {
  @Input() tournament;
  @Input() lobbyPath;

  constructor() {}
}
