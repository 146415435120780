<app-no-data [actionButtonUrl]="actionButtonUrl" *ngIf="!transactions?.length"></app-no-data>

<div *ngIf="transactions?.length" class="py-4">
  <div *ngFor="let transaction of transactions">
    <div class="box-row mb-2">
      <div class="d-flex flex-column u_gap-5px">
        <div>
          <div
            class="d-flex align-items-center w-100 c_ticket-label"
            [ngClass]="
              environment.clientName === clientNames.ADMIRAL_PINNBET
                ? 'pl-2'
                : 'justify-content-center text-center'
            "
            [ngStyle]="{ backgroundColor: transaction.color }"
          >
            <div class="no-pointer c_ticket-label-text">{{ transaction?.status | uppercase }}</div>
          </div>
        </div>
        <div class="flex-fill text-xl-center u_padding-0-5px">
          <div
            class="row row-sm h-100"
            [ngClass]="{
              'cro_client-ticket-values': this.appStateFacadeService.getClientName() === 'ADMIRAL_CROATIA'
            }"
          >
            <div class="col-sm-6 col-xl-2 mb-2 mb-lg-0">
              <small class="text-muted">{{ 'GAME_TRANSACTION_CREATED_TIME' | translate }}</small>
              <div>{{ transaction?.createdOn | date : 'd.M.yyyy' }}</div>
              <div>{{ transaction?.createdOn | date : 'mediumTime' }}</div>
            </div>
            <div class="col-sm-6 col-xl-2 mb-2 mb-lg-0">
              <small class="text-muted">{{ 'GAME_TRANSACTION_ID' | translate }}</small>
              <div>{{ transaction?.id }}</div>
            </div>
            <div class="col-sm-6 col-xl-2 mb-2 mb-lg-0">
              <small class="text-muted">{{ 'GAME_TRANSACTION_TYPE' | translate }}</small>
              <div *ngIf="!transaction?.gameName">{{ transaction?.product }}</div>
              <div *ngIf="transaction?.gameName">
                {{ transaction?.product.replace(regex, ' ') | titlecase }} / {{ transaction?.gameName }}
              </div>
            </div>
            <div class="col-sm-6 col-xl-2 mb-2 mb-lg-0">
              <small class="text-muted">{{ 'GAME_TRANSACTION_BALANCE' | translate }}</small>
              <div class="text-primary">
                {{ transaction?.startBalance | number : '1.2-2' | currency : transaction?.currency }}
              </div>
            </div>
            <div class="col-sm-6 col-xl-2 mb-2 mb-lg-0">
              <small class="text-muted">{{ 'GAME_TRANSACTION_AMOUNT' | translate }}</small>
              <div>{{ transaction?.amount | number : '1.2-2' | currency : transaction?.currency }}</div>
            </div>
            <div class="col-sm-6 col-xl-2 mb-2 mb-lg-0">
              <small class="text-muted">{{ 'GAME_TRANSACTION_BALANCE_AFTER' | translate }}</small>
              <div
                class="text-primary"
                [ngClass]="{
                  'text-success': transaction?.statusTypeCategory === 2,
                  'text-danger': transaction?.statusTypeCategory === 3
                }"
              >
                {{ transaction?.endBalance | number : '1.2-2' | currency : transaction?.currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center py-2">
  <app-show-more-pagination-button
    [length]="this.config.totalItems"
    [resetPagination]="resetPaginationEmitter"
    [itemsPerLoad]="10"
    [visible]="showMoreBtnVisible"
    [backOnTop]="backOnTop"
    (loadMore)="pageChanged($event)"
  >
  </app-show-more-pagination-button>
</div>
<!-- LOADER -->
<div *ngIf="isLoading" class="spinner-border spinner" role="status"></div>
