import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-token-expiration',
  templateUrl: 'token-expiration.dialog.html',
  styleUrls: ['./token-expiration.dialog.scss'],
})
export class TokenExpirationDialog implements OnInit {
  public imagesBaseUrl = environment.imagesBaseUrl;
  private timeoutRef;

  constructor(public activeModal: NgbActiveModal, private auth: AuthService) {}

  ngOnInit(): void {
    this.autoClose();
  }

  // close dialog
  public closeModal(): void {
    this.activeModal.close();
  }

  // refresh token
  public refreshToken(): void {
    this.auth.updateToken().subscribe(data => {
      clearTimeout(this.timeoutRef);
      this.closeModal();
    });
  }

  // cancel refresh action
  public cancelRefresh(): void {
    this.closeModal();
    this.auth.logout('Token expiration dialog');
  }

  // auto close dialog after 1 min
  private autoClose(): void {
    this.timeoutRef = setTimeout(() => {
      this.cancelRefresh();
    }, 60000);
  }
}
