<nav #headerContainer id="headerContainer" class="navbar c_navbar-base-style py-xl-0">
  <div class="c_header-btnAndLogo-container headerButtons center left-margin">
    <span class="header-icon-button left-menu-icon d-xl-none" (click)="handleLeftSideBar()">
      <svg height="30" width="30" fill="currentColor" viewBox="0 0 48 48">
        <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" id="path2" />
      </svg>
    </span>
    <div class="logo logo-sm">
      <a [routerLink]="[environment.appDefaultRoute]">
        <img style="max-width: unset" [src]="logoImg" alt="logo" width="150" height="45" />
      </a>
    </div>
  </div>

  <!-- MAIN MENU -->
  <div class="nav-main ml-4 d-none d-xl-block">
    <ul>
      <ng-template #tipContent>{{ 'HELP' | translate }}</ng-template>
      <ng-container *ngFor="let menuItem of mainMenu?.menuItems">
        <li
          [ngbTooltip]="tipContent"
          placement="right"
          [disableTooltip]="menuItem?.url !== '/help'"
          [routerLinkActive]="['active']"
          (click)="navigationService.navigate(menuItem)"
        >
          <ng-container *ngIf="!(menuItem.requireAuthorized && !player)">
            <a routerLink="{{ menuItem.url }}">
              <div>
                <span *ngIf="menuItem?.url !== '/help'" class="menu-item-name">{{
                  menuItem.name | translate
                }}</span>
              </div>
            </a>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- CONTENT FOR AUTHORIZED IN PLAYER -->
  <div class="nav-user ml-auto headerButtons right-margin" *ngIf="player">
    <div
      *ngIf="showContinueRegistrationBtn"
      class="proceeed-registration-button"
      text="{{ 'PROCEED_REGISTRATION_COMPONENT_BUTTON' }}"
      routerLink="/registration"
    >
      {{ 'PROCEED_REGISTRATION_COMPONENT_BUTTON' | translate | uppercase }}
      <i class="icon" [setSVGAttributes]="{ height: '18px' }" inlineSVG="assets/icons/skipped-badge.svg"></i>
    </div>
    <a
      *ngIf="!isMobile && player && showUserInfoAndDepositBtn"
      appAdmiralButton
      [width]="'none'"
      [height]="'26px'"
      [type]="'custom'"
      [text]="'DEPOSIT'"
      [padding]="'5px 19px'"
      [textTransform]="'uppercase'"
      [fontWeight]="'400'"
      (click)="authRouteNavigation($event, '/profile/deposit')"
      [class.active]="router.isActive('/profile/deposit', true)"
      [class]="btnClass"
      class="c_deposit-btn"
    >
    </a>
    <div>
      <div *ngIf="showUserInfoAndDepositBtn" class="u_font-roboto-400">
        {{ player.firstName || player.username }}
      </div>
      <div *ngIf="!isMobile && showUserInfoAndDepositBtn">
        {{ balanceInfo.playerBalanceInfo?.balance | number : '1.2-2' | currency : player?.currency }}
      </div>
    </div>
    <span *ngIf="showUserInfoAndDepositBtn" class="header-icon-button">
      <span class="icon-wrapper">
        <i
          [setSVGAttributes]="{ height: '32px' }"
          inlineSVG="/assets/images/new-user.svg"
          fallbackSVG="assets/images/default-left-menu-icon.svg"
          (click)="handleRightSideBar()"
          class="auth-required"
        ></i>
        <span *ngIf="player && unreadMessages > 0" class="notification-counter">{{ unreadMessages }}</span>
      </span>
    </span>
  </div>
  <!-- CONTENT FOR UNAUTHORIZED IN PLAYER -->

  <div class="nav-user ml-auto pr-2 headerButtons" *ngIf="!player">
    <a
      *ngIf="showRegistrationBtn && !isMobile"
      appAdmiralButton
      [type]="'custom'"
      [text]="'REGISTRATION'"
      [routerLink]="['/registration']"
      routerLinkActive="active"
      class="header-icon-button"
      [width]="'none'"
      [height]="'26px'"
      [padding]="'5px 19px'"
      [textTransform]="'uppercase'"
      [fontWeight]="'400'"
      class="c_registration-btn"
    >
    </a>
    <a
      [routerLink]="['/login']"
      routerLinkActive="active"
      appAdmiralButton
      [type]="'custom'"
      [text]="'LOGIN'"
      [width]="'none'"
      [height]="'26px'"
      [padding]="'5px 19px'"
      [fontWeight]="'400'"
      class="c_login-btn"
    >
    </a>
  </div>
</nav>
