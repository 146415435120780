<!-- PAGE LOCATION -->
<div *ngIf="termsModel && location === LOCATION.PAGE" [innerHTML]="termsModel?.value | sanitizeHtml"></div>

<!-- DIALOG LOCATION -->
<div
  (scroll)="scroll($event)"
  class="termsConditionsDialogWrapper"
  *ngIf="termsModel && location === LOCATION.DIALOG"
  [innerHTML]="termsModel?.value | sanitizeHtml"
></div>

<div (click)="jumpToTop(dialogContent)" *ngIf="isJumpToTopAvailable" class="jump-to-top">
  <div [setSVGAttributes]="{ height: '20px', width: '20px' }" inlineSVG="/assets/icons/carret-up.svg"></div>
</div>
