<div class="leaderboardContainer">
  <div class="title">{{ 'TOURNAMENT_LEADERBOARD' | translate }}</div>
  <table *ngIf="rankList?.length; else noActivePlayersLabel" class="table">
    <thead>
      <th [setSVGAttributes]="{ height: '16' }" inlineSVG="assets/icons/leaderboard.svg"></th>
      <th class="id">ID</th>
      <th
        class="text-left username"
        [setSVGAttributes]="{ height: '16' }"
        inlineSVG="assets/images/login.svg"
      ></th>
      <th [ngClass]="{ pointsHeadMobile: isMobile }" class="text-right points">
        <span class="points" [setSVGAttributes]="{ height: '16' }" inlineSVG="assets/icons/points.svg"></span>
        <span *ngIf="!isMobile" class="invisible">{{ rankList[0].points | number: '1.1-2' }}</span>
      </th>
    </thead>
    <tbody #scrollableContainer [ngClass]="{ mobile: isMobile }">
      <div class="rankList">
        <tr
          *ngFor="let rank of rankList; index as i"
          [ngClass]="{ prize: i + 1 <= tournament?.numberOfAwardPrizes }"
        >
          <td class="text-left">
            <span [ngClass]="{ found: foundPlayerIndexInitialRankList === i }">
              {{ rank?.position }}
            </span>
          </td>
          <td>
            <span [ngClass]="{ found: foundPlayerIndexInitialRankList === i }">
              {{ rank?.playerId }}
            </span>
          </td>
          <td class="text-left username">
            <span
              [setSVGAttributes]="
                isMobile ? { height: '13', width: '15px' } : { height: '16', width: '16px' }
              "
              *ngIf="i + 1 <= tournament?.numberOfAwardPrizes"
              inlineSVG="assets/icons/cup.svg"
            ></span>
            <span [ngClass]="{ found: foundPlayerIndexInitialRankList === i }">
              {{ rank?.username }}
            </span>
          </td>
          <td class="text-right points">
            <span [ngClass]="{ found: foundPlayerIndexInitialRankList === i }">
              {{ rank?.points | number: '1.2-2' }}
            </span>
          </td>
        </tr>
      </div>
      <tr *ngIf="!hideDots && player">
        <div class="dotsContainer">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </tr>
      <div class="playerCurrentPositionRankList">
        <tr *ngFor="let rank of playerCurrentPositionRankList; index as i">
          <td>
            <span [ngClass]="{ found: foundPlayerIndexCurrentPositionRankList === i }">
              {{ rank?.position }}
            </span>
          </td>
          <td>
            <span [ngClass]="{ found: foundPlayerIndexInitialRankList === i }">
              {{ rank?.playerId }}
            </span>
          </td>
          <td class="text-right">
            <span [ngClass]="{ found: foundPlayerIndexCurrentPositionRankList === i }">
              {{ rank?.username }}
            </span>
          </td>
          <td class="text-right">
            <span [ngClass]="{ found: foundPlayerIndexCurrentPositionRankList === i }">
              {{ rank?.points | number: '1.2-2' }}
            </span>
          </td>
        </tr>
      </div>
    </tbody>
  </table>
  <ng-template #noActivePlayersLabel>
    <div *ngIf="!isLoading" class="noActivePlayersLabel">
      {{ 'TOURNAMENT_NO_ACTIVE_PLAYERS' | translate }}
    </div>
  </ng-template>

  <div *ngIf="isLoading" class="spinner-border spinner" role="status"></div>
</div>
