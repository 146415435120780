import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

enum CHECKBOX_BUTTON_TYPES {
  REGULAR_BTN = 'REGULAR_BTN',
  TOUCH_REGION_BTN = 'TOUCH_REGION_BTN',
}

enum ALIGN_ITEMS {
  START = 'START',
  CENTER = 'CENTER',
  END = 'END',
}

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxComponent implements AfterViewChecked {
  @Input() checkboxFormControl: FormControl;
  @Output() checkboxClick = new EventEmitter<void>();
  @Input() typeOfCheckboxButton: string = CHECKBOX_BUTTON_TYPES.REGULAR_BTN;
  @Input() alignItems = ALIGN_ITEMS.CENTER;
  public CHECKBOX_BUTTON_TYPES = CHECKBOX_BUTTON_TYPES;
  public checked;

  ngAfterViewChecked(): void {
    this.checked = this.controlValue;
  }

  checkboxClicked() {
    this.checked = !this.checked;
    this.checkboxFormControl.markAsTouched();
    this.checkboxFormControl.markAsDirty();
    this.checkboxFormControl.patchValue(this.checked);
    this.checkboxClick.emit();
  }

  get controlValue(): any {
    return this.checkboxFormControl?.value;
  }
}
