import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { DepositAllsecure } from '@models/deposit-allsecure.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DepositService } from '@services/deposit.service';
import { ToasterService } from '@services/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-allsecure-deposit-status',
  templateUrl: './allsecure-deposit-status.dialog.html',
  styleUrls: ['./allsecure-deposit-status.dialog.scss'],
})
export class AllsecureDepositStatusDialog implements OnInit {
  @Input() data: any;
  public allsecureDeposit: DepositAllsecure;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;
  public depositStatus; // success, error, cancel

  constructor(
    public activeModal: NgbActiveModal,
    private depositService: DepositService,
    private toaster: ToasterService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.depositStatus = this.data.allsecureStatus;
    this.getAllsecureDeposit(this.data.txnId);
  }

  // close modal
  public closeModal(): void {
    // redirect on route without params needed for deposit dialog
    const route = this.router.url.split('?')[0];
    this.router.navigate([route]);
    this.activeModal.close();
  }

  // get allsecure deposit
  private getAllsecureDeposit(txnId): void {
    this.depositService.getAllsecureDeposit(txnId).subscribe({
      next: data => {
        this.allsecureDeposit = data;
      },
      error: error => {
        this.closeModal();
        this.toaster.showError(this.translateService.instant('ALLSECURE_DEPOSIT_TXN_GET_UNSUCCESSFUL'));
      },
    });
  }
}
