<div *ngIf="menuActive" class="bottom-menu-mobile">
  <div class="menu-items">
    <div
      *ngFor="let menuItem of menuItems"
      class="menu-btn"
      [ngClass]="{
        'menu-non-middle-btn': !menuItem.isMiddle,
        'menu-btn--is-active': menuItem.pagePath === activePagePath && !menuItem.isMiddle,
        'menu-middle-btn': menuItem.isMiddle
      }"
      (click)="navigateToPage(menuItem)"
    >
      <div class="circle"></div>
      <div
        [setSVGAttributes]="{ height: menuItem.isMiddle ? '20px' : '16px' }"
        [fallbackSVG]="'assets/images/mobile-menu-icon/menu_casino_l-menu.svg'"
        [inlineSVG]="imagesBaseUrl + menuItem.iconPath"
      ></div>
      <div class="menu-btn-label" [ngClass]="{ 'middle-item-color': menuItem.isMiddle }">
        {{ menuItem.iconLabel | translate }}
      </div>
    </div>

    <div class="menu-dots" (click)="showQuickMenu()">
      <i
        [removeSVGAttributes]="['fill']"
        [setSVGAttributes]="{ fill: 'white' }"
        [inlineSVG]="'assets/images/mobile-menu-icon/menu_dots_icon.svg'"
      ></i>
    </div>
  </div>
</div>

<app-mobile-quick-menu-pinnbet [(isOpened)]="isQuickMenuOpen"></app-mobile-quick-menu-pinnbet>
