import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { MESSAGE_TYPE } from '../../enums/message-type.enum';
import { MESSAGE_SUB_TYPE } from '../../enums/message-sub-type.enum';
import { Router } from '@angular/router';
import { MessagesService } from '@services/messages.service';
import { PopupAllowanceStateService } from '@services/popup-allowance-state.service';
import { JackpotService } from '@services/jackpot.service';
import { ToasterService } from '@services/toaster.service';
import { WithdrawalService } from '@services/withdrawal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { MessageDataModel } from '@models/message.model';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { PlayerService } from '@services/player.service';
import { PromotionService } from '@services/promotion.service';
import { CustomDialog } from 'src/app/shared/dialogs/custom-dialog/custom.dialog';
import { Player } from '@models/player.model';

@Component({
  selector: 'app-messages-popup',
  templateUrl: './messages-popup.dialog.html',
  styleUrls: ['./messages-popup.dialog.scss'],
})

// In this component needs to be implemented one more type of a message which will return API CALL and type of METHOD(PUT,GET,POST,DELETE)
export class MessagesPopupDialog implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('htmlData') htmlData: ElementRef;
  public MESSAGE_TYPE = MESSAGE_TYPE;
  public MESSAGE_SUB_TYPE = MESSAGE_SUB_TYPE;
  public clientName = environment.clientName;
  public CLIENT_NAMES = CLIENT_NAMES;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public activeImg: string;
  public player: Player;

  @Input() message: MessageDataModel;

  private unsubscriber: Subject<void> = null;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private messageService: MessagesService,
    private popupAllowanceStateService: PopupAllowanceStateService,
    private jackpotService: JackpotService,
    private toasterService: ToasterService,
    private appStateFacadeService: AppStateFacadeService,
    private playerService: PlayerService,
    private promotionService: PromotionService,
    private withdrawalService: WithdrawalService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.setActiveClassColorAndImage();
    if (
      this.message.messageType === 'Jackpot' ||
      this.message.messageType === 'Cashback' ||
      this.message.messageType === 'LuckyWheel'
    ) {
      this.unsubscriber = new Subject<void>();
      history.pushState(null, document.title, location.href);

      fromEvent(window, 'popstate')
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(_ => {
          history.pushState(null, document.title, location.href);
        });
    }

    this.appStateFacadeService.getPlayerDataObservable().subscribe(player => {
      this.player = player;
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
      history.back();
    }
  }

  ngAfterViewInit(): void {
    this.htmlData?.nativeElement?.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();
        this.navigate(link.href);
      });
    });
  }

  public setActiveClassColorAndImage() {
    this.activeImg = this.message.messageType;
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      this.activeImg = 'hr-messages/' + this.activeImg;
    } else {
      this.activeImg = 'default-messages/' + this.activeImg;
    }

    // Possible activeImg: 'Freebet', 'General', 'Bonus', 'Jackpot', 'Maintenance', 'Freespin', 'Cashback', 'LuckyWheel';
    if (this.activeImg === 'Jackpot') {
      this.appStateFacadeService.setParticleSystem(true);
    }
  }

  // close dialog
  public closeDialog(callback?: () => void, flag = false) {
    // Mark this message as seen to make sure it will not be shown in popup dialog again
    this.messageService.markAsSeen(this.message.id).subscribe(_ => {
      // Get unread messages again and set new number in the store
      if (callback) {
        callback();
      }
      this.dispatchUnreadMessagesToStore();
      this.activeModal.close(flag);
    });
  }

  // Set unread messages to the store
  public dispatchUnreadMessagesToStore() {
    this.messageService.getAllUnreadMessages().subscribe(data => {
      this.appStateFacadeService.setMessages(data.records);
    });
  }

  // Navigate to a specified location
  public navigate(buttonUrl) {
    // disable next popup apperance on visiting link
    this.closeDialog(() => {
      this.popupAllowanceStateService.popupAllowanceStateSource.next(false);

      if (buttonUrl.startsWith('www') || buttonUrl.startsWith('http')) {
        // external link
        window.open(buttonUrl, '_blank');
      } else {
        // Internal link
        this.router.navigateByUrl(buttonUrl);
      }
    });
  }

  // Game info opened
  public gameInfoOpened() {
    // disable next popup apperance on visiting game info
    this.popupAllowanceStateService.popupAllowanceStateSource.next(false);
    this.closeDialog();
  }

  // accept jackpot
  public acceptJackpot(jackpotId: number): void {
    this.closeDialog(() => {
      this.jackpotService.acceptJackpot(jackpotId).subscribe(data => {
        this.toasterService.showSuccess(data.message);
      });
    }, true);
  }

  // accept cashback
  public acceptCashback(cashbackId: number): void {
    this.closeDialog(() => {
      this.playerService.acceptCashback(cashbackId).subscribe(data => {
        this.toasterService.showSuccess(data.message);
      });
    }, true);
  }

  // accept lucky wheel prize
  public acceptLuckyWheelPrize(luckyWheelPrizeAwardQueueId: number): void {
    this.closeDialog(() => {
      this.promotionService.claimLuckyWheelPrize(luckyWheelPrizeAwardQueueId).subscribe({
        next: response => {
          this.promotionService.luckyWheelClaimedPrize.next(response);
        },
      });
    }, true);
  }

  // CRO - Send withdrawal code
  public sendWithdrawalCode(type: string): void {
    const message = this.message.text;
    const regex = /[A-Z0-9]{10}/;
    const code = message.match(regex)[0];

    this.closeDialog(() => {
      if (!code) {
        return;
      }

      if (this.player.cellPhone) {
        this.withdrawalService.sendEmailOrSms(code, type).subscribe({
          next: data => {
            this.openInfoDialog(data.message);
            this.router.navigate(['/profile/withdrawal/withdrawal-requests']);
          },
          error: error => {
            this.openInfoDialog(error.message);
          },
        });
      } else {
        this.router.navigate(['/profile/identity-verification/phone']);
      }
    }, true);
  }

  private openInfoDialog(message: string): void {
    const modalRef = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
    });
    const data = {
      title: this.translate.instant('COMMON_NOTIFICATIONS'),
      message,
    };
    modalRef.componentInstance.data = data;
  }
}
