import { Component, ElementRef, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GameDataModel } from '@models/game-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { PinnbetGameInfoDialog } from '@modules/lobby/game-info-pinnbet/pinnbet-game-info.dialog';
import { AppStateFacadeService } from '@state/app-state.facade';
import { LocalStorageService } from '@services/local-storage.service';
import { PRODUCT_CODES } from '@enums/product-codes.enum';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pinnbet-lobby-game',
  templateUrl: './lobby-game.component.html',
  styleUrls: ['./lobby-game.component.scss'],
})
export class PinnbetLobbyGameComponent implements AfterViewInit {
  @ViewChild('gameImage') gameImage: ElementRef;
  @Input() game: GameDataModel;
  @Input() lobbyPath = '';
  @Input() displayGameInfo = true;
  @Input() tournament;
  @Input() gameIndex; // for large games for number beside the image
  @Input() gamesCount; // to check when to show number
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isLoggedIn = this.localStorageService.isLoggedIn();
  public environment = environment;
  public language: string;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private appStateFacadeService: AppStateFacadeService,
    private localStorageService: LocalStorageService,
    private toasterService: ToasterService,
    private translate: TranslateService
  ) {
    this.language = this.localStorageService.getLanguage();
  }

  ngAfterViewInit(): void {
    const gameImage =
      window.innerWidth > 600 && !this.displayGameInfo
        ? this.game.gameIconLocation
        : this.game.mobileGameIconLocation;
    if (!gameImage) {
      this.setDefaultImage(window.innerWidth <= 600);
    } else {
      const image = environment.imagesBaseUrl + gameImage;
      this.gameImage.nativeElement.style.background = `url(${image}) center / cover`;
    }
  }

  public openGameInfoDialog(): void {
    const modalRef = this.modalService.open(PinnbetGameInfoDialog, {
      centered: this.isMobile ? false : true,
      scrollable: false,
      keyboard: false,
    });

    modalRef.componentInstance.game = this.game;
    modalRef.componentInstance.lobbyPath = this.lobbyPath;
    modalRef.componentInstance.tournament = this.tournament;
  }

  private setDefaultImage(mobile) {
    const imageSrc = mobile
      ? '../../../../assets/images/pinnbet-missing-image.png'
      : '../../../../assets/images/pinnbet-missing-image.png';
    this.gameImage.nativeElement.style.background = `url(${imageSrc}) center / cover`;
  }

  // navigate to mobile or desktop game play
  public navigateToGamePlay(): void {
    // Prevent playing for cash, for game which is not allowed on geolocation.
    if (!this.game.allowedInGeolocation) {
      this.toasterService.showError(this.translate.instant('GAME_NOT_ALLOWED_ON_GEOLOCATION'));
      return;
    }

    const type = 'cash';
    if (this.lobbyPath) {
      switch (this.game.vendorName) {
        case 'ELBET':
          this.router.navigate([`${this.lobbyPath}/gameplay/elbet/${this.game.externalProductId}`]);
          return;
        case 'NSOFT':
          this.router.navigate([`${this.lobbyPath}/gameplay/nsoft/${this.game.externalProductId}`]);
          return;
        case 'Golden Race':
          this.router.navigate([`${this.lobbyPath}/gameplay/golden-race/${this.game.externalProductId}`]);
          return;
        case 'Spribe':
          this.router.navigate([`${this.lobbyPath}/gameplay/spribe/${this.game.externalProductId}`]);
          return;
        case 'Pragmatic Play':
          if (this.game.code === 'spaceman' && !this.game.forceExternalLink) {
            this.router.navigate([`mini-games/gameplay/pragmatic/${this.game.code}`]);
          } else {
            this.isMobile
              ? this.startMobileGamePlayWithLobbyPath(type)
              : this.startDesktopGamePlayWithLobbyPath(type);
          }
          return;
        default:
          this.isMobile
            ? this.startMobileGamePlayWithLobbyPath(type)
            : this.startDesktopGamePlayWithLobbyPath(type);
          return;
      }
    } else {
      this.isMobile
        ? this.startMobileGamePlayWithoutLobbyPath(type)
        : this.startDesktopGamePlayWithoutLobbyPath(type);
    }
  }

  // start mobile game with looby path
  private startMobileGamePlayWithLobbyPath(type): void {
    if (!this.game.forceExternalLink) {
      this.router.navigate([`${this.lobbyPath}/gameplay/mobile/${type}/${this.game.id}`]);
    }
  }

  // start mobile game without looby path
  private startMobileGamePlayWithoutLobbyPath(type): void {
    if (!this.game.forceExternalLink) {
      this.startGameWithoutLobbyPath('mobile', type);
    }
  }

  // start desktop game with looby path
  private startDesktopGamePlayWithLobbyPath(type): void {
    this.router.navigate([`${this.lobbyPath}/gameplay/desktop/${type}/${this.game.id}`]);
  }

  // start desktop game without looby path
  private startDesktopGamePlayWithoutLobbyPath(type): void {
    this.startGameWithoutLobbyPath('desktop', type);
  }

  private startGameWithoutLobbyPath(device, type): void {
    switch (this.tournament.productCode) {
      case PRODUCT_CODES.CASINO:
        this.router.navigate([`slot/gameplay/${device}/${type}/${this.game.id}`]);
        return;
      case PRODUCT_CODES.LIVE_CASINO:
        this.router.navigate([`live-dealer/gameplay/${device}/cash/${this.game.id}`]);
        return;
      case PRODUCT_CODES.ELBET:
        this.router.navigate([`virtual/gameplay/elbet/${this.game.externalProductId}`]);
        break;
      case PRODUCT_CODES.GOLDEN_RACE:
        this.router.navigate([`virtual/gameplay/golden-race/${this.game.name}`]);
        break;
      case PRODUCT_CODES.SEVEN_AGGREGATOR:
        this.router.navigate([`virtual/gameplay/nsoft/${this.game.externalProductId}`]);
        break;
      case PRODUCT_CODES.SPRIBE:
        this.router.navigate([`mini-games/gameplay/spribe/${this.game.externalProductId}`]);
        break;
      case PRODUCT_CODES.EXEFEED:
        this.router.navigate([`virtual/gameplay/${device}/${type}/${this.game.id}`]);
        break;
    }
  }
}
