import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Subscription } from 'rxjs';
import { ScrollService } from '@services/scroll.service';

@Component({
  selector: 'app-overlay-shade',
  templateUrl: './overlay-shade.component.html',
  styleUrls: ['./overlay-shade.component.scss'],
})
export class OverlayShadeComponent implements OnInit, OnDestroy {
  public overlayStatus: boolean;
  private overlayShadeStatus$: Subscription;

  constructor(public appStateFacadeService: AppStateFacadeService, private scrollService: ScrollService) {}

  ngOnInit(): void {
    this.overlayShadeStatus$ = this.appStateFacadeService.observeOverlayShadeStatus().subscribe(status => {
      this.overlayStatus = status;
      this.scrollService.disableOrEnableBodyScroll(status);
    });
  }

  ngOnDestroy(): void {
    this.overlayShadeStatus$.unsubscribe();
  }

  public collapseAllSideBars() {
    this.appStateFacadeService.collapseAllSideBars();
  }
}
