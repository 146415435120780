import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { tap, throttleTime, switchMap, takeUntil, map } from 'rxjs/operators';
import { fromEvent, Subscription, zip } from 'rxjs';
import { Router } from '@angular/router';
import { ButtonBarItem } from '@models/button-bar-item.model';
import { NavigationService } from '@services/navigation.service';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

// Added beacuse we want to leave some space for
// the previous item so the user knows that there are more items
const LEFT_SCROLL_OFFSET = 60;

@Component({
  selector: 'app-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.scss'],
})
export class ButtonsBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('buttonsBarContainer', { static: true }) buttonsBarContainer: ElementRef<HTMLDivElement>;
  @Output() navigationEvent = new EventEmitter<ButtonBarItem>();
  @Input() buttonBarItems: ButtonBarItem[] = [];
  @Input() arrowBackgroundColor: 'bgColor' | 'cardColor';
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  private wheelEventSubscription$: Subscription;
  public isRightArrowHidden: boolean;
  public isLeftArrowHidden: boolean;
  private currentUrl$: Subscription;
  private componentInitialized: boolean;
  public dragging = false;
  private dragging$: Subscription;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    public router: Router,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.setRouteSubscription();
    // Initial check
    const mutObs = new MutationObserver(() => {
      this.handleArrowsVisibilityOnScroll();
      mutObs.disconnect();
    });
    mutObs.observe(this.buttonsBarContainer.nativeElement, { childList: true });
  }

  ngAfterViewInit(): void {
    const onWheelEvent = fromEvent(this.buttonsBarContainer.nativeElement, 'wheel').pipe(
      tap<Event>(e => {
        e.preventDefault();
      }),
      throttleTime(250)
    );

    let startMouseDownX = 0;
    let scrollLeftStart = 0;

    const mouseDown = fromEvent(this.buttonsBarContainer.nativeElement, 'mousedown').pipe(
      tap<PointerEvent>(e => {
        scrollLeftStart = this.buttonsBarContainer.nativeElement.scrollLeft;
        this.dragging = true;
        startMouseDownX = e.x;
      })
    );

    const mouseMove = fromEvent(document, 'mousemove');

    const mouseUp = fromEvent(document, 'mouseup').pipe(
      tap<PointerEvent>(() => {
        this.dragging = false;
        this.handleArrowsVisibilityOnScroll();
      })
    );

    const drag = mouseDown.pipe(switchMap(() => mouseMove.pipe(takeUntil(mouseUp))));

    this.dragging$ = drag.subscribe((e: PointerEvent) => {
      this.buttonsBarContainer.nativeElement.scroll({
        left: scrollLeftStart - e.x + startMouseDownX,
        behavior: 'auto',
      });
    });

    this.wheelEventSubscription$ = onWheelEvent.subscribe((e: WheelEvent) => {
      this.buttonsBarContainer.nativeElement.scrollBy({ left: e.deltaY * 4, top: 0, behavior: 'smooth' });

      setTimeout(() => {
        this.handleArrowsVisibilityOnScroll();
      }, 400);
    });
  }

  ngOnDestroy(): void {
    this.wheelEventSubscription$.unsubscribe();
    this.currentUrl$.unsubscribe();
    if (this.dragging$) {
      this.dragging$.unsubscribe();
    }
  }

  public scrollRight() {
    this.buttonsBarContainer.nativeElement.scrollBy({ left: 400, behavior: 'smooth' });
    setTimeout(() => {
      this.handleArrowsVisibilityOnScroll();
    }, 400);
  }

  public scrollLeft() {
    this.buttonsBarContainer.nativeElement.scrollBy({ left: -400, behavior: 'smooth' });
    setTimeout(() => {
      this.handleArrowsVisibilityOnScroll();
    }, 400);
  }

  public emitEvent(btn: ButtonBarItem) {
    if (this.navigationService.checkRegistrationStatusAndNavigate(btn, true)) {
      this.navigationEvent.emit(btn);
    }
  }

  private handleArrowsVisibilityOnScroll() {
    if (
      this.buttonsBarContainer.nativeElement.scrollLeft +
        this.buttonsBarContainer.nativeElement.clientWidth !==
      this.buttonsBarContainer.nativeElement.scrollWidth
    ) {
      this.isRightArrowHidden = false;
    }

    if (
      this.buttonsBarContainer.nativeElement.scrollLeft +
        this.buttonsBarContainer.nativeElement.clientWidth ===
      this.buttonsBarContainer.nativeElement.scrollWidth
    ) {
      this.isRightArrowHidden = true;
    }

    if (this.buttonsBarContainer.nativeElement.scrollLeft !== 0) {
      this.isLeftArrowHidden = false;
    }

    if (this.buttonsBarContainer.nativeElement.scrollLeft === 0) {
      this.isLeftArrowHidden = true;
    }
  }

  private setRouteSubscription(): void {
    let element: ButtonBarItem;
    let index: number;

    this.currentUrl$ = this.appStateFacadeService.observeCurrentUrlStatus().subscribe(currentUrl => {
      element = this.buttonBarItems.find(button => currentUrl.includes(button.url));
      index = this.buttonBarItems.indexOf(element);

      if (index === 0) {
        this.isLeftArrowHidden = true;
      }

      if (index === this.buttonBarItems.length - 1) {
        this.isRightArrowHidden = true;
      }

      setTimeout(() => {
        if (element) {
          const markedElement = document.getElementById(`${element.id}`);

          if (markedElement) {
            this.buttonsBarContainer.nativeElement.scrollTo({
              left: this.isMobile
                ? markedElement.offsetLeft - 8 - LEFT_SCROLL_OFFSET
                : markedElement.offsetLeft - 8 - 60 - LEFT_SCROLL_OFFSET,
              behavior: 'smooth',
            });
          }
        }
      }, 0);

      if (!this.isMobile) {
        setTimeout(
          () => {
            this.handleArrowsVisibilityOnScroll();
            this.componentInitialized = true;
          },
          this.componentInitialized ? 400 : 800
        );
      }
    });
  }
}
