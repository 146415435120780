import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Player } from '@models/player.model';
import { CookieService } from 'ngx-cookie';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { GLOBAL_VARIABLES } from '@enums/global-variables.enum';
import { HelpersService } from '@services/helpers.service';
import { LocalStorageService } from '@services/local-storage.service';
import * as moment from 'moment';
import { AppStateFacadeService } from '../../state/app-state.facade';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit, OnDestroy {
  public environment = environment;
  public cookieAccepted: boolean;
  public isNotificationVisible = true;
  public player: Player;
  public btnClass: string;

  private playerObservable: Observable<any>;
  private player$: Subscription;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private helpersService: HelpersService,
    private localStorageService: LocalStorageService,
    private appStateFacadeService: AppStateFacadeService
  ) {}

  ngOnInit(): void {
    this.btnClass = this.helpersService.getPropertyValue('btnDepositClass');
    this.checkCookies();
    this.setupStore();
    this.setPlayerSubscription();
    this.checkCanExtendCookie();
  }

  checkCanExtendCookie() {
    const expDateFromStorage = moment(this.localStorageService.getCookieExpDate(), 'YYYY-MM-DD');
    const canExtendCookie = moment().isBefore(expDateFromStorage);
    const cookie = this.cookieService.get('cookie');
    if (canExtendCookie && !cookie) {
      this.acceptCookies();
      this.setPlayerSubscription();
    }
  }

  ngOnDestroy(): void {
    this.player$.unsubscribe();
  }

  // setup store
  private setupStore() {
    this.playerObservable = this.appStateFacadeService.getPlayerDataObservable();
  }

  // set player subscription
  private setPlayerSubscription(): void {
    this.player$ = this.playerObservable.subscribe(player => {
      if (this.player?.id !== player?.id) {
        this.checkCookiesIdArray(player.id);
      }
      this.player = player;
    });
  }

  // check cookies
  private checkCookies(): void {
    const cookie = this.cookieService.get('cookie');
    if (cookie) {
      this.cookieAccepted = true;
    }
  }

  // set cookies
  public acceptCookies(): void {
    const dateUntilCookieExpires = new Date(
      new Date().getFullYear() + GLOBAL_VARIABLES.NUMBER_OF_YEARS_COOKIE_WILL_BE_PRESENT,
      new Date().getMonth(),
      new Date().getDate()
    );
    this.cookieService.put('cookie', `cookie${Math.random()}`, { expires: dateUntilCookieExpires });
    this.localStorageService.setCookieExpDate(
      moment().add(GLOBAL_VARIABLES.NUMBER_OF_YEARS_COOKIE_WILL_BE_PRESENT, 'years').format('YYYY-MM-DD')
    );
    this.closeCookieNotification();
  }

  // dismiss cookie notification
  public closeCookieNotification(): void {
    this.isNotificationVisible = false;
  }

  // check cookies array of id's
  private checkCookiesIdArray(playerId: number): void {
    const hashedId = this.md5Hash(playerId);
    const idsCookieArray = this.cookieService.get('_pc');

    idsCookieArray
      ? this.updateIdsCookiesArray(hashedId, idsCookieArray)
      : this.createIdsCookiesArray(hashedId);
  }

  // initialize ids cookie array
  createIdsCookiesArray(hashedId): void {
    this.cookieService.put('_pc', hashedId);
  }

  // update ids cookie array
  updateIdsCookiesArray(hashedId, idsCookieArray): void {
    if (!idsCookieArray.includes(hashedId)) {
      const updatedArray = `${idsCookieArray},${hashedId}`;
      this.cookieService.put('_pc', updatedArray);
    }
  }

  // md5Hash
  private md5Hash(playerId): any {
    return Md5.hashStr(playerId.toString());
  }

  // navigate to help cookies page
  public navigate(destination): void {
    destination === 'POLICY'
      ? this.router.navigate([environment.cookiePolicyUrl])
      : this.router.navigate([environment.cookiePolicyPrivacyUrl]);
  }
}
