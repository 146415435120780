import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';
import { BalanceInfo } from '@models/balance-info.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BonusService } from '@services/bonus.service';
import { DepositService } from '@services/deposit.service';
import { PlayerService } from '@services/player.service';
import { ToasterService } from '@services/toaster.service';
import { WithdrawalService } from '@services/withdrawal.service';
import { amountValidator } from '@validators/amount.validator';
import { Observable, Subscription } from 'rxjs';
import { BonusFreebetWarningDialog } from '../../dialogs/bonus-freebet-warning-dialog/bonus-freebet-warning.dialog';
import { AppStateFacadeService } from '@state/app-state.facade';
import { BalanceInfoInterface } from '../../state/interfaces/AppStateInterface.interface';
import { DEPOSIT_CODES } from '@enums/deposit-codes.enum';
import { WITHDRAWAL_CODES } from '@enums/withdrawal.codes.enum';
import { GoogleAnalyticsService } from '@services/google-analytics.service';

@Component({
  selector: 'app-admiral360',
  templateUrl: './admiral360.component.html',
  styleUrls: ['./admiral360.component.scss'],
})
export class Admiral360Component implements OnInit, OnDestroy {
  public admiral360Form: FormGroup;
  public isWithdrawal: boolean;
  public isLoading: boolean;
  public balanceInfo: BalanceInfo;
  public admiral360BalanceInfo: Admiral360BalanceInfo;
  public player;
  public admiral360Deposit;
  public admiral360Withdrawal;

  private balanceInfoObservable: Observable<any>;
  private balanceInfo$: Subscription;
  private playerObservable: Observable<any>;
  private player$: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private depositService: DepositService,
    private withdrawalService: WithdrawalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private bonusService: BonusService,
    private modalService: NgbModal,
    private playerService: PlayerService,
    private appStateFacadeService: AppStateFacadeService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.buildAdmiral360Form();
  }

  ngOnInit(): void {
    this.setupStore();
    this.setPlayerSubscription();
    this.setBalanceInfoSubscription();
    this.setInitArrowDirection();
    this.getAdmiral360Payments();
    this.getAdmiral360Balance();
  }

  ngOnDestroy(): void {
    this.player$.unsubscribe();
    this.balanceInfo$.unsubscribe();
  }

  private buildAdmiral360Form() {
    this.admiral360Form = this.fb.group(
      {
        amount: [''],
      },
      {
        validators: [amountValidator],
      }
    );
  }

  private setupStore(): void {
    this.balanceInfoObservable = this.appStateFacadeService.getBalanceInfo();
    this.playerObservable = this.appStateFacadeService.getPlayerDataObservable();
  }

  private setPlayerSubscription(): void {
    this.player$ = this.playerObservable.subscribe(playerData => {
      this.player = playerData;
    });
  }

  private setBalanceInfoSubscription(): void {
    this.balanceInfo$ = this.balanceInfoObservable.subscribe((balanceState: BalanceInfoInterface) => {
      const { playerBalanceInfo, admiral360BalanceInfo } = balanceState;
      this.balanceInfo = playerBalanceInfo || new BalanceInfo({});
      if (admiral360BalanceInfo) {
        this.admiral360BalanceInfo = admiral360BalanceInfo;
      }
      this.setMaxValidatorValue();
    });
  }

  private getAdmiral360Payments(): void {
    this.depositService.getDeposit(DEPOSIT_CODES.ADMIRAL_360_DEPOSIT).subscribe(data => {
      this.admiral360Deposit = data;
    });
    this.withdrawalService.getWithdrawal(WITHDRAWAL_CODES.ADMIRAL_360_WITHDRAWAL).subscribe(data => {
      this.admiral360Withdrawal = data;
    });
  }

  private getAdmiral360Balance(): void {
    if (this.player?.externalId) {
      this.playerService.getAdmiral360PlayerBalance().subscribe(admiral360BalanceInfo => {
        this.admiral360BalanceInfo = new Admiral360BalanceInfo(admiral360BalanceInfo);
      });
    }
  }

  private setInitArrowDirection(): void {
    // withdrawal online >>> office
    // deposit online <<< office
    const url = this.router.url;
    this.isWithdrawal = url.includes('withdrawal/admiral-360') ? true : false;
  }

  // transfer money
  public onFormSubmit(): void {
    this.isLoading = true;
    // withdrawal
    if (this.isWithdrawal) {
      this.bonusService.checkIfBonusOrFreebetWillBeDeactivated(this.amount.value).subscribe(data => {
        if (data.bonuses && data.bonuses.length) {
          this.openBonusWarningDialog(data);
        } else {
          this.doWithdrawal();
        }
      });
    } else {
      // deposit
      this.doDeposit();
    }
  }

  // open bonus warning dialog
  private openBonusWarningDialog(data) {
    this.isLoading = false;
    const modalRef = this.modalService.open(BonusFreebetWarningDialog, {
      centered: true,
      scrollable: false,
      size: 'md',
      backdrop: 'static',
    });

    modalRef.componentInstance.wrapper = data;

    modalRef.result.then(accepted => {
      if (accepted) {
        this.doWithdrawal();
      }
    });
  }

  public doWithdrawal() {
    this.withdrawalService.saveBalanceFromMbase(this.admiral360Withdrawal.url, this.amount.value).subscribe({
      next: data => {
        this.googleAnalyticsService.eventEmitterCro('Deposit', 'Deposit', 'Admiral 360', data.balance);
        this.afterTransfer(data);
      },
      error: error => {
        this.isLoading = false;
      },
    });
  }

  public doDeposit() {
    this.depositService.saveBalanceToMbase(this.admiral360Deposit.url, this.amount.value).subscribe({
      next: data => {
        this.googleAnalyticsService.eventEmitterCro('Deposit', 'Deposit', 'Admiral 360', data.balance);
        this.afterTransfer(data);
      },
      error: error => {
        this.isLoading = false;
      },
    });
  }

  private afterTransfer(data): void {
    this.isLoading = false;
    this.amount.reset();
    this.toaster.showSuccess(this.translate.instant('ADMIRAL_360_MONEY_TRANSFER_SUCCESSFUL'));
    this.appStateFacadeService.setAdmiral360BalanceInfo(new Admiral360BalanceInfo(data));
  }

  // change arrow direction
  public toggleSide(): void {
    this.isWithdrawal = !this.isWithdrawal;
    this.setMaxValidatorValue();
  }

  // set max amount value to validator amount field
  private setMaxValidatorValue(): void {
    const maxAmountValue = this.isWithdrawal
      ? this.balanceInfo?.nxcsAvailableForWithdrawal
      : this.admiral360BalanceInfo?.balance;
    this.addAmountValidators(maxAmountValue);
  }

  // add amount validators after we got needed data from backend
  private addAmountValidators(maxAmountValue) {
    this.amount.setValidators([Validators.max(maxAmountValue), Validators.required, Validators.min(1)]);
    this.amount.updateValueAndValidity();
  }

  public setPredefinedValueAllAvailableAmount(): void {
    this.isWithdrawal
      ? this.admiral360Form.patchValue({ amount: this.balanceInfo.nxcsAvailableForWithdrawal.toString() })
      : this.admiral360Form.patchValue({ amount: this.admiral360BalanceInfo.balance.toString() });
  }

  // getters
  public get amount(): AbstractControl {
    return this.admiral360Form.get('amount');
  }
}
