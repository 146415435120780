import { Injectable } from '@angular/core';
import { SCREEN_ORIENTATION } from '@enums/screen-orientation.enum';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, share } from 'rxjs/operators';
import { AppStateFacadeService } from '@state/app-state.facade';

@Injectable({
  providedIn: 'root',
})
export class EventListenerService {
  constructor(private appStateFacadeService: AppStateFacadeService) {}

  clickEvent$ = fromEvent(window, 'click');
  resizeEvent$ = fromEvent(window, 'resize');
  messageEvent$ = fromEvent(window, 'message');
  scrollEvent$ = fromEvent(window, 'scroll');
  beforeUnload$ = fromEvent(window, 'beforeunload');
  /*
   * @inputKeyUp field listener take care of debounce time and distinct value of input change
   * return only value of input value
   */
  inputKeyUp(inputRef, debounceTimeSet = 750) {
    return fromEvent(inputRef, 'keyup').pipe(
      debounceTime(debounceTimeSet),
      distinctUntilChanged(),
      map((inputValue: any) => inputValue.target.value)
    );
  }

  orientationChange$ = this.resizeEvent$.pipe(
    filter(() => this.appStateFacadeService.getIsMobileStatus()),
    map(() =>
      window.matchMedia('(orientation: portrait)').matches
        ? SCREEN_ORIENTATION.PORTRAIT
        : SCREEN_ORIENTATION.LANDSCAPE
    ),
    share()
  );
}
