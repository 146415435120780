import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { baseServerUrl, environment } from 'src/environments/environment';
import { CmsFooterSection } from './cmsFooter.model';
import { NavigationEnd, Router } from '@angular/router';
import { AppStateFacadeService } from '@state/app-state.facade';
import { NavigationService } from '@services/navigation.service';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { Subscription } from 'rxjs';
import { ConfigData } from '@models/config-data/config-data.model';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

const HIDE_FOOTER_ON_EXACT_URLS = [
  '/sport-prematch',
  '/sport-live',
  '/sport-results',
  '/worldcup',
  '/eurocup',
];
const HIDE_FOOTER_ON_START_WITH_URLS = [
  /**
   * TODO when refactor of routing is done please uncomment and modify, those paths
   * check mobile menu how behave after hiding footer on new paths
   */
  // '/virtual/elbet',
  // '/virtual/nsoft',
  // '/virtual/spribe',
  // '/virtual/golden-race',
  '/lotto',
  '/poker',
  '/horses',
  '/worldcup',
  '/eurocup',
  '/profile/blinking-verification',
];

@Component({
  selector: 'app-footer-cms',
  templateUrl: './footer-cms.component.html',
  styleUrls: ['./footer-cms.component.scss'],
})
export class FooterCMSComponent implements OnInit, OnDestroy {
  private windowWidth: number = window.innerWidth;
  public baseServerUrl = baseServerUrl;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();

  public environment = environment;
  private router$: Subscription;
  public isFooterVisible = true;
  public generalFooterData: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
  }
  public footerCmsData: CmsFooterSection[];
  private configDataObservable$: Subscription;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private navigationService: NavigationService
  ) {
    if (this.isMobile) {
      this.setRouterSubscription();
    }
  }

  ngOnInit(): void {
    this.configDataObservable$ = this.appStateFacadeService
      .getConfigData()
      .pipe(filter((data: ConfigData) => !!data))
      .subscribe((data: ConfigData) => {
        this.footerCmsData = data.footerV2
          ? JSON.parse(data.footerV2).map((item: CmsFooterSection) => new CmsFooterSection(item))
          : [];
        this.generalFooterData = JSON.parse(data.generalV2).filter(
          (generalData: any) => generalData.active === true
        )[0].style.footer;
        this.setColorsFromFooterFromGeneralSettings();
      });
  }

  private setColorsFromFooterFromGeneralSettings() {
    this.elementRef.nativeElement.style.setProperty(
      '--headerBackgroundColors',
      this.generalFooterData.backgroundColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--footerBorderColour',
      this.generalFooterData.borderColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--footerTextColor',
      this.generalFooterData.textColor.hex
    );
  }

  ngOnDestroy() {
    this.router$?.unsubscribe();
  }

  getDynamicStyles(sectionData: any) {
    switch (true) {
      case this.windowWidth < 576: // Extra small devices (portrait phones, less than 576px)
        return sectionData.mobileWidth;
      case this.windowWidth >= 576 && this.windowWidth < 768: // Small devices (landscape phones, 576px and up)
        return sectionData.tabletWidth;
      case this.windowWidth >= 768: // Medium devices (tablets, 768px and up) and Large devices (desktops, 992px and up)
        return sectionData.desktopWidth;
      default:
        return sectionData.desktopWidth;
    }
  }

  // navigate to internal or external url
  public navigate(footerItem): void {
    if (!footerItem.link) {
      return;
    }

    // proceed registration dialog
    const player = this.appStateFacadeService.getPlayerData();
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA && player && !player.registrationCompleted) {
      this.navigationService.checkIfOnlyF2FLeftAndInProcess(player);
      return;
    }
    // Asset in cms doesn't have urlType property when we decide what to do with a link uncomment this
    // if (footerItem.urlType === 'INTERNAL') {
    if (footerItem.link.includes('http')) {
      window.open(footerItem.link, '_self');
    } else {
      this.router.navigateByUrl(footerItem.link);
      window.scrollTo(0, 0);
    }
    // }
    // Asset in cms doesn't have urlType property when we decide what to do with a link uncomment this
    // if (footerItem.urlType === 'EXTERNAL') {
    //   window.open(footerItem.link, '_blank');
    // }
  }

  // set router subscription
  private setRouterSubscription() {
    // cover case when footer initialize after route change
    this.testRouteForFooterVisibility(this.router.url.split('?')[0]);
    this.router$ = this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        // url after redirects without params
        this.testRouteForFooterVisibility(data.urlAfterRedirects.split('?')[0]);
      }
    });
  }

  private testRouteForFooterVisibility(pageUrl) {
    this.isFooterVisible = !HIDE_FOOTER_ON_EXACT_URLS.find(url => url === pageUrl);
    if (this.isFooterVisible) {
      this.isFooterVisible = !HIDE_FOOTER_ON_START_WITH_URLS.find(url => pageUrl.startsWith(url));
    }
  }
}
