import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from './web-socket.service';
import { interval, of, Subscription } from 'rxjs';
import { concatMap, first, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageDataModel } from '@models/message.model';
import { LocalStorageService } from './local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagesPopupDialog } from 'src/app/shared/dialogs/messages-popup/messages-popup.dialog';
import { BonusService } from './bonus.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Player } from '@models/player.model';
import { SOCKET_ACTION_TYPES } from '@enums/socket_action_type.enum';
import { AppInjector } from '@services/app-injector';
import { TranslateService } from '@ngx-translate/core';
import { CustomDialog } from '../../shared/dialogs/custom-dialog/custom.dialog';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { DateService } from './date.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { WebSocketNxcsService } from './web-socket-nxcs.service';
import { ToasterService } from '@services/toaster.service';
import { PoliticalExposureFormComponent } from 'src/app/shared/components/political-exposure-form/political-exposure-form.component';
import { PinnbetMessagesPopupDialog } from '../../shared/dialogs/messages-popup-pinnbet/pinnbet-messages-popup.dialog';
import { HelpersService } from './helpers.service';
import { IpsDepositStatusDialog } from '@modules/profile/modules/deposit/ips-deposit/ips-deposit-status-dialog/ips-deposit-status.dialog';
import { QuickBarService } from '../../layout/quick-bar/quick-bar.service';
import { PlayerService } from './player.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private chanelMbase: Subscription;
  private chanelNxcs: Subscription;
  private intervalMbase: Subscription;
  private intervalNxcs: Subscription;
  private croatianNotification: Map<any, any>;
  private blinkingNotifications: Map<any, any>;
  private microBlinkingNotifications: Map<any, any>;
  private commonNotifications: Map<any, any>;
  private activeNotificationMap: Map<any, any>;
  private translate: TranslateService;

  constructor(
    private modalService: NgbModal,
    private quickBarService: QuickBarService,
    private webSocketService: WebSocketService,
    private webSocketNxcsService: WebSocketNxcsService,
    private localStorageService: LocalStorageService,
    private bonusService: BonusService,
    private appStateFacadeService: AppStateFacadeService,
    private dateService: DateService,
    private toasterService: ToasterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helpersService: HelpersService,
    private router: Router,
    private playerService: PlayerService
  ) {}

  public connect(): void {
    /**
     * TODO when Blinking message service return full message delete translate service and
     * setUpNotificationMaps() method move to constructor
     */
    this.setUpNotificationMaps();
    this.closeConnectionMbase();
    const authToken = this.localStorageService.getToken() || null;
    this.chanelMbase = this.webSocketService.connectToWS(environment.trumpeter + authToken).subscribe({
      next: response => {
        if (response.connectionStatus === 'success') {
          this.handlePayload(response.payload);
        } else {
          this.intervalMbase = interval(3000)
            .pipe(take(1))
            .subscribe(_ => {
              this.webSocketService.reconnectToWS();
            });
        }
      },
      error: err => {
        console.warn('WS error', err);
      },
    });
  }

  public connectToNXCSPlatform(kskId): void {
    this.closeConnectionNxcs();
    const nxcsSocketUrl = `wss://test-bs.admiral.hr:9809?kskId=${kskId}`;
    this.chanelNxcs = this.webSocketNxcsService.connectToWS(nxcsSocketUrl).subscribe({
      next: response => {
        if (response.connectionStatus === 'nxcs connection success') {
          if (response.payload.type === 'KSK_LOGIN') {
            // pass data to state and listen in layput
            this.appStateFacadeService.setNxcsPlatformLogin(response.payload);
          } else if (response.payload.source !== 'PROTOCOL') {
            this.toasterService.showErrorTranslatingKey(`NXCS_ERROR_${response.payload.code}`);
          }
        } else {
          this.intervalNxcs = interval(3000)
            .pipe(take(1))
            .subscribe(_ => {
              this.webSocketNxcsService.reconnectToWS();
            });
        }
      },
      error: err => {
        console.warn('NXCS WS error', err);
      },
    });
  }

  public closeConnectionMbase(): void {
    if (this.chanelMbase) {
      this.webSocketService.close();
      this.chanelMbase.unsubscribe();
    }
  }

  public closeConnectionNxcs(): void {
    if (this.chanelNxcs) {
      this.webSocketNxcsService.close();
      this.chanelNxcs.unsubscribe();
    }
  }

  private setUpNotificationMaps() {
    this.translate = AppInjector.get(TranslateService);

    this.blinkingNotifications = new Map([
      [
        'NEW_BLINKING_CLIENT',
        () => {
          this.blinkingPopupMessage(this.translate.instant('BLINKING_VERIFICATION_SUCCESS'), true);
          this.playerVerificationStatusChange('Verified');
        },
      ],
      [
        'BLINKING_UNVERIFIED_CLIENT',
        () => this.blinkingPopupMessage(this.translate.instant('BLINKING_DOC_EXPIRED')),
      ],
      [
        'BLINKING_BACK_OFFICE_APPROVAL',
        () => this.blinkingPopupMessage(this.translate.instant('BLINKING_OFFICE_APPROVAL')),
      ],
      ['BLINKING_BLOCKED_CLIENT', socketPayload => this.blinkingPopupMessage(socketPayload.message)],
      [
        'BLINKING_TERMINATED_SESSION',
        () => this.blinkingPopupMessage(this.translate.instant('BLINKING_TERMINATED_SESSION')),
      ],
      [
        'BLINKING_SOFT_DELETE',
        () => this.blinkingPopupMessage(this.translate.instant('BLINKING_SOFT_DELETE')),
      ],
      [
        'BLINKING_EXPIRED_SESSION',
        () => this.blinkingPopupMessage(this.translate.instant('BLINKING_EXPIRED_SESSION')),
      ],
      ['BLINKING_REJECTED_SESSION', socketPayload => this.blinkingPopupMessage(socketPayload.message)],
      ['BLINKING_PENDING_FOR_UPDATE', socketPayload => this.blinkingPopupMessage(socketPayload.message)],
    ]);

    this.commonNotifications = new Map([
      ['BALANCE_INFO', socketPayload => this.appStateFacadeService.setPlayerBalance(socketPayload)],
      [
        'LOW_FUNDS',
        () => {
          const clientsForCorvusFrameDeposit: string[] = [
            CLIENT_NAMES.ADMIRAL_CROATIA,
            CLIENT_NAMES.ADMIRAL_SERBIA,
            CLIENT_NAMES.ADMIRAL_PINNBET,
            CLIENT_NAMES.ADMIRAL_MONTENEGRO,
          ];
          if (clientsForCorvusFrameDeposit.includes(environment.clientName)) {
            this.quickBarService.wakeUpQuickBarOnNotification();
          }
        },
      ],
      [
        'NEW_MESSAGE',
        socketPayload => {
          if (socketPayload.dialog) {
            // mark message as seen when opened by dialog
            const messagesComponent =
              environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
                ? PinnbetMessagesPopupDialog
                : MessagesPopupDialog;
            const modalRef = this.modalService.open(messagesComponent, {
              centered: true,
              scrollable: true,
              size: 'md',
              keyboard:
                socketPayload.messageType !== 'Jackpot' &&
                socketPayload.messageType !== 'Cashback' &&
                socketPayload.messageType !== 'LuckyWheel', // NOTE: prevent exit on esc for Jackpot and Cashback messages.
              backdrop: 'static',
            });
            modalRef.componentInstance.message = new MessageDataModel(socketPayload);
            modalRef.result.then(result => {
              if (result) {
                this.appStateFacadeService.setParticleSystem(false);
              }
            });
            // Exit from full screen mode when Jackpot or Cashback notification arrives.
            if (socketPayload.messageType === 'Jackpot' || socketPayload.messageType === 'Cashback') {
              this.helpersService.closeFullScreen();
            }
          } else {
            // add this to unread messages if message is not determined to open via popup dialog
            this.appStateFacadeService.setNewUnreadMessage(socketPayload);
          }

          // Check if message type is "F2F Verification" (CRO REGISTRATION).
          if (socketPayload.messageType === 'F2F Verification') {
            // The message indicates that the F2F verification process is resolved.
            // This means that the is_completed property for step 6, in player registrationSteps, is updated on BE.
            // Updating the player data in the state will trigger update of the "Identity Check" card from the "Unfinished Registration" page, if the user is on that page.
            this.playerService.getPlayer().subscribe(playerData => {
              this.appStateFacadeService.setPlayerData(new Player(playerData));
              this.router.navigate([
                `/registration/${playerData.registrationCompleted ? 'welcome' : 'unfinished'}`,
              ]);
            });
          }
        },
      ],
      [
        'SESSION_DURATION',
        socketPayload => {
          // refresh session
          this.appStateFacadeService.setSocketAction({
            type: SOCKET_ACTION_TYPES.SESSION_DURATION,
            payload: socketPayload,
          });
        },
      ],
      [
        'SESSION_EXPIRATION',
        () => {
          // logout from another device
          this.appStateFacadeService.setSocketAction({
            type: SOCKET_ACTION_TYPES.SESSION_EXPIRATION,
            payload: true,
          });
        },
      ],
      [
        'BONUS_CONFIRMATION',
        socketPayload => {
          // new bonus has arrived
          this.appStateFacadeService.setSocketAction({
            type: SOCKET_ACTION_TYPES.BONUS_CONFIRMATION,
            payload: socketPayload,
          });
          this.helpersService.closeFullScreen();
        },
      ],
      [
        'BONUS_REDEMPTION',
        () => {
          this.appStateFacadeService.setSocketAction({
            type: SOCKET_ACTION_TYPES.BONUS_REDEMPTION,
            payload: null,
          });
          this.helpersService.closeFullScreen();
          this.appStateFacadeService.setParticleSystem(true);
        },
      ],
      ['FREEBET_NOTIFICATION', () => this.bonusService.refreshPromotionsActivityTab.next('active-freebets')],
      [
        'FREESPINS_NOTIFICATION',
        socketPayload => {
          const freespinsNotification = this.modalService.open(CustomDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
          });

          socketPayload.campaignEndTime = this.dateService.formatTimestamp(socketPayload.campaignEndTime);

          const moreGames = socketPayload.games.length > 1;
          const lobby =
            environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
            environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
              ? 'slot'
              : 'casino';

          const getMessage = () => {
            let games = '';

            socketPayload.games.forEach(game => {
              games += `<a class="link" href="${lobby}/${game.providerName}/${game.gameCode}">${game.name}</a><br />`;
            });

            if (moreGames) {
              return `${this.translate.instant('FREESPINS_NOTIFICATION_MESSAGE_MORE_GAMES_PART_1', {
                numOfSpins: socketPayload.numOfSpins,
              })}<br />
                ${games}
                ${this.translate.instant('FREESPINS_NOTIFICATION_MESSAGE_PART_2', {
                  campaignEndTime: socketPayload.campaignEndTime,
                })}`;
            } else {
              return `${this.translate.instant('FREESPINS_NOTIFICATION_MESSAGE_ONE_GAME_PART_1', {
                numOfSpins: socketPayload.numOfSpins,
              })} ${games}
                ${this.translate.instant('FREESPINS_NOTIFICATION_MESSAGE_PART_2', {
                  campaignEndTime: socketPayload.campaignEndTime,
                })}`;
            }
          };

          freespinsNotification.componentInstance.data = {
            title: this.translate.instant('FREESPINS_NOTIFICATION_WON'),
            innerHTML: true,
            message: getMessage(),
          };

          this.helpersService.closeFullScreen();
        },
      ],
      [
        'LUCKY_WHEEL_SPIN',
        socketPayload => {
          socketPayload.message = 'SUCCESS';
          this.appStateFacadeService.setLuckyWheelResult(socketPayload);
        },
      ],
      [
        'LUCKY_WHEEL_SPIN_ERROR',
        socketPayload => {
          socketPayload.message = 'ERROR';
          this.appStateFacadeService.setLuckyWheelResult(socketPayload);
        },
      ],
      [
        'IPS_DEPOSIT_OUTCOME',
        (socketPayload: any) => {
          this.router.navigate(['profile/deposit/ips']);
          const modalRef = this.modalService.open(IpsDepositStatusDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
          });
          modalRef.componentInstance.data = socketPayload;
          this.helpersService.closeFullScreen();
        },
      ],
      ...this.blinkingNotifications,
    ]);

    this.microBlinkingNotifications = new Map([
      [
        'MICROBLINK_UNVERIFIED_CLIENT',
        () => {
          const documentExpiredModal = this.modalService.open(CustomDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
            windowClass: 'top-level-position',
          });

          documentExpiredModal.componentInstance.data = {
            title: this.translate.instant('MESSAGES'),
            innerHTML: true,
            message: this.translate.instant('MICROBLINK_DOC_EXPIRED'),
            buttons: [
              {
                type: 'primary',
                label: this.translate.instant('CONTINUE'),
                on_click: false,
              },
            ],
          };
        },
      ],
    ]);

    this.croatianNotification = new Map([
      [
        'WALLET_CHANGE',
        socketPayload => {
          if (socketPayload.walletType !== 1) {
            const modalRefWalletChange = this.modalService.open(CustomDialog, {
              centered: true,
              scrollable: true,
              size: 'md',
              backdrop: 'static',
            });

            modalRefWalletChange.componentInstance.data = {
              title: this.translate.instant('COMMON_NOTIFICATIONS'),
              message: this.translate.instant('WALLET_CHANGE_BONUS'),
              buttons: [
                {
                  type: 'primary',
                  label: this.translate.instant('CONTINUE'),
                  on_click: false,
                },
              ],
            };

            // NOTE: This is used to show bonus widget in the game-play header.
            if (socketPayload.walletType === 3) {
              // Set bonus notification to state.
              this.appStateFacadeService.setSocketAction({
                type: SOCKET_ACTION_TYPES.BONUS_NOTIFICATION,
                payload: null,
              });
            }
          }

          this.helpersService.closeFullScreen();
        },
      ],
      [
        'WITHDRAWAL_DISABLED_SOCKET',
        socketPayload => {
          if (socketPayload.walletType !== 1) {
            const modalRefWithrowalDisabledSocket = this.modalService.open(CustomDialog, {
              centered: true,
              scrollable: true,
              size: 'md',
              backdrop: 'static',
            });

            modalRefWithrowalDisabledSocket.componentInstance.data = {
              title: this.translate.instant('COMMON_NOTIFICATIONS'),
              innerHTML: true,
              message: this.translate.instant('WITHDRAWAL_DISABLED_SOCKET_MESSAGE'),
              buttons: [
                {
                  type: 'primary',
                  label: this.translate.instant('CONTINUE'),
                  on_click: false,
                },
              ],
              links: [
                {
                  linkDescription: this.translate.instant('WITHDRAWAL_DISABLED_LINK_3'),
                  linkText: this.translate.instant('WITHDRAWAL_DISABLED_LINK_3_TEXT'),
                  linkClass: 'c_additional-data',
                  url: './profile/additional-data',
                },
                {
                  linkDescription: this.translate.instant('WITHDRAWAL_DISABLED_LINK_1'),
                  linkText: this.translate.instant('WITHDRAWAL_DISABLED_LINK_1_TEXT'),
                  url: './info/vise-o_dodatnim_podacima',
                },
                {
                  linkDescription: this.translate.instant('WITHDRAWAL_DISABLED_LINK_2'),
                  linkText: this.translate.instant('WITHDRAWAL_DISABLED_LINK_2_TEXT'),
                  url: './info/vise-o_dodatnim_podacima',
                },
              ],
            };
          }
        },
      ],
      [
        'NO_FUNDS',
        () => {
          const modalNoFounds = this.modalService.open(CustomDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
            windowClass: 'top-level-position',
          });

          modalNoFounds.componentInstance.data = {
            title: this.translate.instant('COMMON_NOTIFICATIONS'),
            innerHTML: true,
            message: this.translate.instant('MESSAGE_NO_FOUNDS'),
            buttons: [
              {
                type: 'primary',
                label: this.translate.instant('CONTINUE'),
                on_click: false,
              },
            ],
          };

          // Set NO_FUNDS notification to state.
          // NOTE: This is one of the reasons why the bonus widget will be removed from game-play header, in case it is present there.
          this.appStateFacadeService.setSocketAction({
            type: SOCKET_ACTION_TYPES.NO_FUNDS,
            payload: null,
          });

          this.helpersService.closeFullScreen();
        },
      ],
      [
        'DEPOSIT_NOTIFICATION',
        socketPayload => {
          const depositNotification = this.modalService.open(CustomDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
          });

          depositNotification.componentInstance.data = {
            title: this.translate.instant('COMMON_NOTIFICATIONS'),
            innerHTML: true,
            message: `${
              socketPayload.success
                ? `${this.translate.instant('DEPOSIT_SUCCESS_MSG')} ${socketPayload.amount} ${
                    this.appStateFacadeService.getPlayerData().currency
                  }`
                : this.translate.instant('DEPOSIT_NO_SUCCESS_MSG')
            }`,
            buttons: [
              {
                type: 'primary',
                label: this.translate.instant('CONTINUE'),
                on_click: false,
              },
            ],
          };

          this.helpersService.closeFullScreen();
          this.googleAnalyticsService.eventEmitterCro('Deposit', 'Uspešna Uplata Aircash', 'AircashApp');
        },
      ],
      [
        'BONUS_NOTIFICATION',
        socketPayload => {
          const bonusNotification = this.modalService.open(CustomDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
          });

          const pipedDate = this.dateService.formatTimestamp(socketPayload.redeemActive);

          bonusNotification.componentInstance.data = {
            title: this.translate.instant('BONUS_ACTIVITY_BONUS_WON'),
            innerHTML: true,
            message: this.translate.instant('BONUS_NOTIFICATION_PARAMS_MESSAGE', {
              amount: socketPayload.amount,
              currency: this.appStateFacadeService.getPlayerData().currency,
              date: pipedDate,
            }),
          };

          this.helpersService.closeFullScreen();
        },
      ],
      [
        'IS_POLITICIAN',
        () => {
          const modalPoliticalExposure = this.modalService.open(CustomDialog, {
            centered: true,
            scrollable: true,
            size: 'md',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'top-level-position', // NOTE: Position this dialog on top of other modals.
          });

          const data = {
            title: this.translate.instant('COMMON_NOTIFICATIONS'),
            closeButtonDeactivated: true,
            component: PoliticalExposureFormComponent,
            message: this.translate.instant('MESSAGE_POLITICAL_EXPOSURE'),
            buttons: [
              {
                type: 'primary',
                label: this.translate.instant('PERSONAL_DATA_SAVE'),
                on_click: false,
              },
            ],
          };

          modalPoliticalExposure.componentInstance.data = data;
          modalPoliticalExposure.result.then(
            // After close.
            result => {
              modalPoliticalExposure.componentInstance.data.component.submitData();
            }
          );

          this.helpersService.closeFullScreen();
        },
      ],
    ]);

    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        this.activeNotificationMap = new Map([
          ...this.commonNotifications,
          ...this.croatianNotification,
          ...this.microBlinkingNotifications,
        ]);
        break;
      default:
        this.activeNotificationMap = new Map([...this.commonNotifications]);
    }
  }

  private handlePayload(socketPayload): void {
    if (this.activeNotificationMap.has(socketPayload.type)) {
      this.activeNotificationMap.get(socketPayload.type)(socketPayload);
    }
  }

  blinkingPopupMessage(messageText, innerHtml = false) {
    this.playerVerificationStatusChange(null);
    const modalRefBlinking = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: true,
      size: 'sm',
      backdrop: 'static',
    });
    modalRefBlinking.componentInstance.data = {
      title: this.translate.instant('COMMON_NOTIFICATIONS'),
      innerHTML: innerHtml,
      message: messageText,
    };
  }

  playerVerificationStatusChange(status) {
    this.appStateFacadeService
      .getPlayerDataObservable()
      .pipe(
        concatMap((data: any) => of(data)),
        first()
      )
      .subscribe(data => {
        const updatePlayerData = Object.assign({}, data, { blinkingCustomerId: status });
        localStorage.setItem('player', JSON.stringify(new Player(updatePlayerData)));
        this.appStateFacadeService.setPlayerData(new Player(updatePlayerData));
      });
  }
}
