import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { PRODUCT_CODES } from '@enums/product-codes.enum';
import { CroatiaGameInfoDialog } from '@modules/lobby/game-info-croatia/croatia-game-info.dialog';
import { PinnbetGameInfoDialog } from '@modules/lobby/game-info-pinnbet/pinnbet-game-info.dialog';
import { GameInfoDialog } from '@modules/lobby/game-info/game-info.dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '@services/local-storage.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tournament-games',
  templateUrl: './tournament-games.component.html',
  styleUrls: ['./tournament-games.component.scss'],
})
export class TournamentGamesComponent implements OnChanges {
  @Input() tournament;
  @Input() lobbyPath;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public imgSource = window.innerWidth > 600 ? 'img' : 'mobile';
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  public language: string;
  public pointerClass = '';

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private modalService: NgbModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toasterService: ToasterService,
    private translate: TranslateService
  ) {
    this.language = this.localStorageService.getLanguage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tournament'].currentValue !== undefined) {
      if (
        this.tournament.productCode === PRODUCT_CODES.CASINO ||
        this.tournament.productCode === PRODUCT_CODES.LIVE_CASINO
      ) {
        this.pointerClass = 'pointer';
      }
    }
  }

  public navigateToGameInfo(game) {
    this.openGameInfoDialog(game);
  }

  private openGameInfoDialog(game): void {
    let GAME_INFO_DIALOG;
    let dialogOnCenter = true;

    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        GAME_INFO_DIALOG = CroatiaGameInfoDialog;
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
        GAME_INFO_DIALOG = PinnbetGameInfoDialog;
        dialogOnCenter = this.appStateFacadeService.getIsMobileStatus() ? false : true;
        break;
      default:
        GAME_INFO_DIALOG = GameInfoDialog;
        break;
    }

    const modalRef = this.modalService.open(GAME_INFO_DIALOG, {
      centered: dialogOnCenter,
      scrollable: false,
      keyboard: false,
    });

    // when user is already in tournament dedicated page, he will not be interested in playing demo
    game.demoIncluded = false;
    // Set data on dialog instance to be used in called Modal
    modalRef.componentInstance.game = game;
    modalRef.componentInstance.lobbyPath = this.lobbyPath;
    modalRef.componentInstance.tournament = this.tournament;
  }

  // handle click on a play button in case of just desktop
  public openGame(game, tournament) {
    if (!game.allowedInGeolocation) {
      this.toasterService.showError(this.translate.instant('GAME_NOT_ALLOWED_ON_GEOLOCATION'));
      return;
    }
    switch (tournament.productCode) {
      case PRODUCT_CODES.CASINO:
        environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
        environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
          ? this.router.navigate([`slot/gameplay/desktop/cash/${game.id}`])
          : this.router.navigate([`casino/gameplay/desktop/cash/${game.id}`]);
        break;
      case PRODUCT_CODES.LIVE_CASINO:
        environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
        environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
          ? this.router.navigate([`live-dealer/gameplay/desktop/cash/${game.id}`])
          : this.router.navigate([`casino-live/gameplay/desktop/cash/${game.id}`]);
        break;
      case PRODUCT_CODES.ELBET:
        game.code === 'ROCKET_MAN' && environment.clientName !== CLIENT_NAMES.ADMIRAL_NIGERIA
          ? this.router.navigate([`mini-games/gameplay/elbet/${game.externalProductId}`])
          : this.router.navigate([`virtual/gameplay/elbet/${game.externalProductId}`]);
        break;
      case PRODUCT_CODES.GOLDEN_RACE:
        this.router.navigate([`virtual/gameplay/golden-race/${game.externalProductId}`]);
        break;
      case PRODUCT_CODES.SEVEN_AGGREGATOR:
        this.router.navigate([`virtual/gameplay/nsoft/${game.externalProductId}`]);
        break;
      case PRODUCT_CODES.SPRIBE:
        this.router.navigate([`mini-games/gameplay/spribe/${game.externalProductId}`]);
        break;
      case PRODUCT_CODES.EXEFEED:
        this.router.navigate([`virtual/gameplay/desktop/cash/${game.id}`]);
        break;
      case PRODUCT_CODES.AVIATRIX:
        this.router.navigate([`virtual/gameplay/desktop/cash/${game.id}`]);
        break;
    }
  }
}
