import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppService } from 'src/app/app.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { PRODUCT_CODES } from '@enums/product-codes.enum';
import {
  SPORT_TAB_TRANSACTIONS,
  CASINO_TAB_TRANSACTIONS,
  LIVE_CASINO_TAB_TRANSACTIONS,
  VIRTUAL_TAB_TRANSACTIONS,
  SLOT_TAB_TRANSACTIONS,
  LIVE_DEALER_TAB_TRANSACTIONS,
  LOTTO_TAB_TRANSACTIONS,
  POKER_TAB_TRANSACTIONS,
} from 'src/app/shared/components/transaction-product-component/temp-mock-data/transactions-tabs-mock';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(
    private http: HttpClient,
    private apiService: AppService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * Get transaction history with filtering query
   * @param params - query parameters ( walletType, filter or productId, limit, offset, startDate, endDate)
   * if transactions are payment transactions then param is filter, else productId
   */
  getTransactionHistory(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'txn/game', { params });
  }

  /**
   * Get product auth token
   */
  getProductAuthToken(): Observable<any> {
    return this.http.get(environment.apiUrl + 'game-product/product-auth-token');
  }

  /**
   * Get transaction details from external server
   * @param params - query parameters ( productTxnId, token, productId)
   */
  getSportTransactionDetails(params): Observable<any> {
    const url = this.composeProductUrl(params.token);
    return this.apiService.externalServiceGetMethod(
      `${url.host}ticket/${params.productTxnId}/${url.extension}`,
      url.params
    );
  }

  /**
   * Method for composing url depending on product
   * @param token - token from response of getProductAuthToken() method
   */
  private composeProductUrl(token): any {
    const url = {
      host: '',
      extension: '',
      params: {},
    };
    const language = this.localStorageService.getLanguage();
    url.host = environment.sportApi;
    url.params = { language, token };

    return url;
  }

  /**
   * Get payment transaction history with filtering query
   * @param params - query parameters ( productType, limit, offset, startDate, endDate)
   *
   */
  getPaymentTransactionHistory(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'txn/payment', { params });
  }

  /**
   * Get sport tickets with filtering query
   * @param params - query parameters ( walletType, limit, offset, startDate, endDate)
   *
   */
  getSportTickets(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'txn/sport', { params });
  }

  /**
   * Get CROATIA lotto tickets
   * @param params - query parameters ( walletType, limit, offset)
   *
   */
  getLottoTickets(params): Observable<any> {
    return this.http.get(`${environment.apiUrl}txn/lotto`, { params });
  }

  /**
   * Get CROATIA lotto ticket details
   * @param url - lotto url details
   * @param ticketID - ticket id for more detail
   *
   */
  getLottoTicketDetail(url, ticketID) {
    return this.http.get(url + `?ticketCode=${ticketID}`);
  }

  /**
   * Get CROATIA virtual games tickets
   * @param params - query parameters (walletType, limit, offset)
   *
   */
  getVirtualGamesTickets(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'txn/vr-horse', { params });
  }

  /**
   * Get CROATIA casino tickets
   * @param params - query parameters (limit, offset, start, end date)
   *
   */
  getCasinoTickets(params: any): Observable<any> {
    return this.http.get(environment.apiUrl + `txn/txn-by-reference?filter=gamesClosed`, { params });
  }

  /**
   * Get CROATIA casino tickets details
   * @param params - query parameters (limit, offset, start, end date)
   *
   */
  getCasinoTicketDetails(referenceTxnId: number, params?: any): Observable<any> {
    return this.http.get(environment.apiUrl + `txn/txn-by-reference/${referenceTxnId}`, { params });
  }

  /**
   * Get active sport tickets with filtering query
   * @param params - query parameters ( walletType, limit, offset)
   *
   */
  getActiveSportTickets(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'txn/sport-active', { params });
  }

  /**
   * Get redemption history
   * @param params
   */
  getRedemptionHistory(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'txn/redemptions', { params });
  }

  /**
   * Get transactions tabs based on wallet type
   * @param params - TRANSACTIONS_MOCK_DATA, walletType
   */
  getTransactionTabsByWalletType(TRANSACTIONS_MOCK_DATA, walletType): any {
    return TRANSACTIONS_MOCK_DATA.filter(transactionMockObj =>
      transactionMockObj.walletTypes.find(wallet_type => walletType === wallet_type)
    );
  }

  /**
   * Get transactions based on product code
   * @param params - productCode
   */
  getTransactionsByProductCode(productCode): any {
    let TRANSACTION;
    switch (productCode) {
      case PRODUCT_CODES.SPORT:
        TRANSACTION = SPORT_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.CASINO:
        TRANSACTION = CASINO_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.LIVE_CASINO:
        TRANSACTION = LIVE_CASINO_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.SLOT:
        TRANSACTION = SLOT_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.LIVE_DEALER:
        TRANSACTION = LIVE_DEALER_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.VIRTUAL_GAMES:
        TRANSACTION = VIRTUAL_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.HR_LOTO:
        TRANSACTION = LOTTO_TAB_TRANSACTIONS;
        break;
      case PRODUCT_CODES.POKER:
        TRANSACTION = POKER_TAB_TRANSACTIONS;
        break;
    }

    return TRANSACTION;
  }

  /**
   * Get game products ids from transactions
   * @param params - gameProducts from BE, transaction from mock
   */
  getTransactionGameProductIds(gameProducts, transaction): any {
    // find game products from transaction in game products array from BE
    const transactionGameProducts = gameProducts.filter(product =>
      transaction.gameProducts.find(gameProduct => gameProduct === product.code)
    );

    // get game products ids
    const transactionGameProductsIds = [];
    transactionGameProducts.forEach(gameProduct => {
      transactionGameProductsIds.push(gameProduct.id);
    });

    return transactionGameProductsIds;
  }
}
