import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appAppendImage]',
})
export class AppendImageDirective implements OnInit {
  @Input() imgPath: string;
  @Input() className: string;
  @Input() loadingStrategy: 'eager' | 'lazy';
  @Input() parentNode = false;

  private image: HTMLImageElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.createImg();
  }

  private createImg() {
    this.image = new Image();
    if (this.loadingStrategy) {
      this.image.loading = this.loadingStrategy;
    }

    this.image.src = environment.imagesBaseUrl + this.imgPath;
    this.image.onload = (img: any) => {
      this.image.width = img.composedPath()[0].naturalWidth;
      this.image.height = img.composedPath()[0].naturalHeight;
      this.appendImgToParent();
    };
    this.image.onerror = () => this.loadingError();
    this.image.className = this.className;
  }

  private appendImgToParent() {
    if (this.parentNode) {
      this.renderer.insertBefore(
        this.elementRef.nativeElement.parentNode,
        this.image,
        this.elementRef.nativeElement.firstChild
      );
    } else {
      this.renderer.insertBefore(
        this.elementRef.nativeElement,
        this.image,
        this.elementRef.nativeElement.firstChild
      );
    }
  }

  private loadingError() {
    console.warn('[error while loading footer icons on path: ' + this.imgPath);
  }
}
