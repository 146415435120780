import { inject } from '@angular/core';
import { Route, UrlSegment, UrlTree, Router, CanMatchFn } from '@angular/router';
import { Observable } from 'rxjs';

export const visitLandingGuardCanMatch: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);

  // Check if this is base URL (to be precise - if there is no relative URL path).
  if (!segments.length) {
    const lastVisit = localStorage.getItem('lastVisitLandingPage');
    const currentTime = new Date().getTime();
    const day = 24 * 60 * 60 * 1000; // 24 hours in milliseconds.

    // User hasn't visited in the last 24 hours or it's their first visit.
    if (!lastVisit || currentTime - parseInt(lastVisit, 10) > day) {
      // Update last visit timestamp.
      localStorage.setItem('lastVisitLandingPage', currentTime.toString());
      router.navigate(['/home']);
      return false;
    }
  }

  return true;
};
