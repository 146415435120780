<div #dialogContent class="box">
  <header>
    <div *ngIf="sections?.length > 1" class="buttonsContainer">
      <div class="dialogButton">
        <div [setSVGAttributes]="{ height: '16' }" inlineSVG="assets/icons/info.svg"></div>
        <div>{{'TOURNAMENT_INFO' | translate}}</div>
      </div>
      <div class="dialogButton">
        <div [setSVGAttributes]="{ height: '16' }" inlineSVG="assets/icons/leaderboard.svg"></div>
        <div>{{'TOURNAMENT_LEADERBOARD' | translate}}</div>
      </div>
    </div>

    <hr *ngIf="sections?.length > 1" />

    <div [ngClass]="{'mobile': isMobile}" class="close pointer">
      <img
        *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
        [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
        (click)="closeDialog()"
        alt="Info leaderboard popup close icon"
      />
      <div
        *ngIf="clientName === clientNames.ADMIRAL_PINNBET"
        class="u_pointer"
        (click)="closeDialog()"
        [inlineSVG]="'assets/icons/close_cross.svg'"
      ></div>
    </div>
  </header>

  <!-- INFO SECTION -->
  <ng-container *ngIf="sections.includes('INFORMATION')">
    <app-tournament-info [tournament]="tournament"></app-tournament-info>
  </ng-container>

  <!-- LEADERBOARD SECTION -->
  <ng-container *ngIf="sections.includes('LEADERBOARD')">
    <div class="leaderboardWrapper">
      <app-leaderboard [tournament]="tournament"></app-leaderboard>
    </div>
  </ng-container>
</div>
