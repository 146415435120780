<div class="box">
  <header class="py-2">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">
          {{ 'TOKEN_EXPIRATION_TITLE' | translate }}
        </div>
      </div>
    </div>
  </header>
  <div class="row pl-4 pt-4">
    <div class="col-12">
      <div class="h6 mb-2 mb-md-2">
        {{ 'TOKEN_EXPIRATION_TEXT' | translate }}
      </div>
    </div>
  </div>
  <hr />
  <div class="col-12 col-lg-12 text-right pt-2 pt-lg-0">
    <button (click)="refreshToken()" class="btn btn-primary btn-md">
      {{ 'CONTINUE' | translate }}
    </button>
    <button (click)="cancelRefresh()" class="btn btn-outline-light btn-md">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
