import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, interval, Observable, of, startWith, Subject, Subscription } from 'rxjs';
import { IReloadGamesInGamePageInfo } from '@modules/lobby/models/ReloadGamesInGamePageInfo';
import { map } from 'rxjs/operators';
import { GameDataModel } from '@models/game-data.model';
import { LobbyFavoriteGame } from '@models/lobby-game-favorite.model';

@Injectable({
  providedIn: 'root',
})
export class LobbyService {
  private visitedSection = new BehaviorSubject('');
  public $observeCurrentVisitedSection = this.visitedSection.asObservable();

  private reloadGamesInGamePage = new Subject<IReloadGamesInGamePageInfo>();
  public reloadGamesInGamePageObservable = this.reloadGamesInGamePage.asObservable();

  private refreshActivePlayerNumber$: Subscription;
  private activePlayersNumber = new BehaviorSubject<any>(null);
  public activePlayerNumbersByGameIDObservable = this.activePlayersNumber.asObservable();

  public setReloadGamesInGamePage(requiredFilters: IReloadGamesInGamePageInfo) {
    this.reloadGamesInGamePage.next(requiredFilters);
  }

  constructor(private http: HttpClient) {}

  // set visited section
  setVisitedSection(value) {
    this.visitedSection.next(value);
  }

  // get visited section
  getVisitedSection() {
    // isDeepStrictEqual()
    return this.visitedSection.value;
  }

  /**
   * Get game products
   */
  getGameProducts(): Observable<any> {
    return this.http.get(environment.apiUrl + 'game-product');
  }

  /**
   * Get lobby
   */
  getLobby(): Observable<any> {
    return this.http.get(environment.apiUrl + 'casino/lobby');
  }

  /**
   * Get all lobby games
   */
  getLobbyGames(params): Observable<any[]> {
    if (!params.sections) {
      return of([]);
    }
    return this.http.get<any[]>(environment.apiUrl + 'casino/games-by-sections', {
      params: new HttpParams()
        .set('sections', params.sections)
        .set('gameName', params.providerName || '')
        .set('offset', params.offset || 0)
        .set('limit', params.limit || 10)
        .set('lobby', params.lobby || ''),
    });
  }

  getLobbyGamesCount(params: { sections: string; lobby: string }): Observable<number> {
    return this.http.get<number>(environment.apiUrl + 'casino/games-by-sections/count', {
      params: new HttpParams().set('lobby', params.lobby).set('sections', params.sections),
    });
  }

  /**
   * Get casino game
   * @param gameId - game id
   */
  public getLobbyGame(gameId: number): Observable<GameDataModel> {
    return this.http
      .get(environment.apiUrl + `casino/games/${gameId}`)
      .pipe(map(response => new GameDataModel().deserialize(response)));
  }

  /**
   * Get casino game
   * @param gameCode - game code
   */
  public getLobbyGameByCode(gameCode: string): Observable<any> {
    return this.http
      .get(environment.apiUrl + `casino/game-by-code/${gameCode}`)
      .pipe(map(response => new GameDataModel().deserialize(response)));
  }

  /**
   * Get game
   * @param gameExternalID - game exteranl id
   */
  public getLobbyGameByExternalId(gameExternalID: string): Observable<any> {
    return this.http
      .get(environment.apiUrl + `casino/game-by-external/${gameExternalID}`)
      .pipe(map(response => new GameDataModel().deserialize(response)));
  }

  /**
   * Set favorite game
   * @param game - object with properties isFavorite nad gameId
   */
  public setFavoriteGame(game: LobbyFavoriteGame): Observable<any> {
    return this.http.post(environment.apiUrl + 'casino/favorite', game);
  }

  /**
   * Get my casino content
   */
  public getMyCasinoContent(): Observable<any> {
    return this.http.get(environment.apiUrl + 'casino/my-casino');
  }

  /**
   * Get slides for carousell
   */
  getCarousellSlides(params?): Observable<any> {
    return this.http.get(environment.apiUrl + 'homepage/carousell', { params });
  }

  /**
   * Search for a games
   * @param params - query parameters (gameName - for searching games, limit and offset(optional))
   */
  searchGames(params) {
    return this.http.get<any[]>(environment.apiUrl + 'casino/games', {
      params,
    });
  }

  /**
   * Get demo game launch url
   */
  getGameLaunchUrlDemo(gameId) {
    return this.http.get(environment.apiUrl + `launch-game/demo/${gameId}`);
  }

  /**
   * Get cash game launch url
   */
  getGameLaunchUrlCash(gameId) {
    return this.http.get(environment.apiUrl + `launch-game/${gameId}`);
  }

  /**
   * Check if game is allowed in country.
   */
  checkIfGameAllowedInCountry(gameId: number) {
    return this.http.get(environment.apiUrl + `casino/game-allowed-in-country/${gameId}`);
  }

  /**
   * Get the number of players per games
   * and set the interval to refresh the number of players and refresh observable
   * Lobby-game component observes this observable and refresh active number
   */
  getActivePlayersNumberInterval(): void {
    this.refreshActivePlayerNumber$ = interval(60000)
      .pipe(startWith(0))
      .subscribe(() => {
        this.http
          .get<number>(environment.apiUrl + `game/active-players-count`)
          .subscribe(activePlayersNumber => {
            this.activePlayersNumber.next(activePlayersNumber);
          });
      });
  }

  /**
   * Clear active players number interval
   */
  clearActivePlayersNumberInterval(): void {
    this.activePlayersNumber.next({});
    if (this.refreshActivePlayerNumber$) {
      this.refreshActivePlayerNumber$.unsubscribe();
    }
  }
}
