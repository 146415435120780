<div class="box">
  <!-- HEADER -->
  <div class="section section-header">
    <div class="game-vendor">{{game.providerName}}</div>
    <div>
      <i
        (click)="closeDialog()"
        class="pointer close-icon"
        inlineSVG="assets/images/close-solid.svg"
        [setSVGAttributes]="{ height: '16px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
    </div>
  </div>

  <!-- GAME TITLE -->
  <div class="section section-game-title">
    <div class="game-title">{{game.name}}</div>
  </div>

  <!-- GAME INFO -->
  <div class="section section-game-image">
    <div class="game-image">
      <app-image-wrapper
        class="w-100"
        [width]="120"
        [height]="120"
        [alt]="game.name"
        [progressiveLoad]="true"
        [costumeLowResSrc]="game.lowResGameIconLocation ? imagesBaseUrl + game.lowResGameIconLocation : ''"
        [src]="game.gameIconLocation ? imagesBaseUrl + game.gameIconLocation : ''"
        [costumeClass]="'br-12 img-fluid'"
      ></app-image-wrapper>
    </div>
  </div>

  <!-- BUTTONS -->
  <div class="section section-primary-buttons">
    <a
      *ngIf="game.demoIncluded"
      (click)="navigateToGamePlay(gameType.FOR_FUN)"
      class="btn demo-btn br-8 w-25"
    >
      {{ 'DEMO' | translate | uppercase }}
    </a>
    <a
      *ngIf="!isLoggedIn"
      [routerLink]="['/login']"
      (click)="goToLogin()"
      class="btn play-btn br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'LOGIN' | translate | uppercase }}
    </a>
    <a
      (click)="navigateToGamePlay(gameType.FOR_REAL)"
      *ngIf="isLoggedIn"
      class="btn play-btn br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'PLAY_FOR_CASH' | translate | uppercase }}
    </a>
  </div>
  <!-- PROVIDER GAME OPEN -->
  <div *ngIf="isLoggedIn && gamePlayProblemLinkShown" class="section section-provider-game-open">
    <a (click)="navigateToGamePlay(gameType.FOR_REAL_EXTERNAL)" class="link">
      {{ 'PROBLEMS_OPENING_GAME' | translate }}
    </a>
  </div>
  <!-- GAME FAVORITE -->
  <div class="section section-game-favorite">
    <div *ngIf="isLoggedIn" (click)="setFavoriteGame()">
      <i
        *ngIf="!game.favorite"
        class="pointer favorite-icon"
        inlineSVG="assets/icons/heart-non-active.svg"
      ></i>
      <i *ngIf="game.favorite" class="pointer favorite-icon" inlineSVG="assets/icons/heart-active.svg"></i>
      <span class="label" *ngIf="!game.favorite">{{'GAME_INFO.ADD_TO_MY_GAMES' | translate}}</span>
      <span class="label" *ngIf="game.favorite">{{'GAME_INFO.REMOVE_FROM_MY_GAMES' | translate}}</span>
    </div>
  </div>
</div>
