export class TermsConditions {
  public id: number;
  public value: string;
  public acceptedTandC: boolean;

  constructor(data) {
    this.id = data.id;
    this.value = data.value;
    this.acceptedTandC = data.acceptedTandC;
  }
}
