import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStateFacadeService } from '@state/app-state.facade';
import { HelpersService } from '@services/helpers.service';
import { NavigationService } from '@services/navigation.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-mobile-bottom-menu-pinnbet',
  templateUrl: './mobile-bottom-menu-pinnbet.component.html',
  styleUrls: ['./mobile-bottom-menu-pinnbet.component.scss'],
})
export class MobileBottomMenuPinnbetComponent implements OnInit, OnDestroy {
  public activePagePath: string;
  public subRoute: string;
  public imagesBaseUrl = environment.imagesBaseUrl;

  public menuActive = false;
  public menuItems = [];
  private mobileMenuItems$: Subscription;

  public isQuickMenuOpen = false;

  @Input() set setActivePage(page: string) {
    const routeSplit = page.split('?')[0].split('/');
    this.activePagePath = `/${routeSplit[1]}`;
    this.subRoute = routeSplit[2];
    if (this.activePagePath === '/profile') {
      this.activePagePath = `${this.activePagePath}/${this.subRoute}`;
    }
  }

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private helperService: HelpersService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.mobileMenuItems$ = this.appStateFacadeService.getBottomMobileMenu().subscribe(menuItems => {
      if (menuItems.length) {
        if (this.helperService.iOS()) {
          setTimeout(() => this.setMenuItems(menuItems), 500);
        } else {
          this.setMenuItems(menuItems);
        }
        this.menuActive = true;
      }
    });
  }

  private setMenuItems(items: any): void {
    const tempMenuItems = [];
    for (let i = 0; i < 5; i++) {
      tempMenuItems.push({
        iconPath: items[i].activeIcon,
        iconLabel: items[i].name,
        pagePath: items[i].url,
        pagePathType: items[i].type,
        // requireAuthorized: items[i].requireAuthorized,
        isMiddle: i === 2,
      });
    }
    this.menuItems = tempMenuItems;
  }

  public showQuickMenu(): void {
    this.isQuickMenuOpen = true;
  }

  public navigateToPage(menuElement: any): void {
    this.navigationService.navigateToLink(menuElement.pagePathType, menuElement.pagePath);
  }

  ngOnDestroy(): void {
    if (this.mobileMenuItems$) {
      this.mobileMenuItems$.unsubscribe();
    }
  }
}
