import { Injectable } from '@angular/core';
import { MenuItem } from '@models/config-data/menu/menu-item.model';
import { MENU_TYPES } from 'src/app/shared/enums/navigation-type.enum';
import { Router } from '@angular/router';
import { CustomDialog } from '../../shared/dialogs/custom-dialog/custom.dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppInjector } from '@services/app-injector';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from '../../../environments/environment';
import { PlayerService } from './player.service';
import { Player } from '@models/player.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private translate: TranslateService;
  private finalizeRegistration = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private appStateFacadeService: AppStateFacadeService,
    private playerService: PlayerService
  ) {}

  // navigate
  navigate(menuItem: MenuItem): void {
    switch (menuItem.type) {
      case MENU_TYPES.INTERNAL_LINK:
      case MENU_TYPES.IFRAME:
      case MENU_TYPES.TEXT_LINK_INTERNAL:
      case MENU_TYPES.INTERNAL_LINK_SUBMENU:
        this.router.navigateByUrl(menuItem.url);
        break;
      case MENU_TYPES.EXTERNAL_LINK:
      case MENU_TYPES.EXTERNAL_LINK_SUBMENU:
        window.open(menuItem.url, '_blank');
        break;
    }

    // TODO on these URL-s scroll on top when we click on menu item
    const urlForScrollToTop = ['/tournament', '/promotions', '/sport-prematch', '/sport-live'];
    const strollToTopCase = urlForScrollToTop.includes(menuItem.url);
    switch (true) {
      case strollToTopCase:
        window.scrollTo(0, 0);
        break;
    }
  }

  public navigateToLink(linkType: string, url: string): void {
    switch (linkType) {
      case MENU_TYPES.INTERNAL_LINK:
      case MENU_TYPES.IFRAME:
      case MENU_TYPES.TEXT_LINK_INTERNAL:
      case MENU_TYPES.INTERNAL_LINK_SUBMENU:
        this.router.navigateByUrl(url);
        break;
      case MENU_TYPES.EXTERNAL_LINK:
      case MENU_TYPES.EXTERNAL_LINK_SUBMENU:
        window.open(url, '_blank');
        break;
    }
  }

  /**
   * For Cro project if user not pass all steps in registration rout will be ignored,
   * and continue registration popup will appear.
   * For other project beside CRO this method just pass throw.
   */
  checkRegistrationStatusAndNavigate(linkInformation, returnBoolean?): void | boolean {
    zip(this.appStateFacadeService.getPlayerDataObservable(), this.appStateFacadeService.getConfigData())
      .pipe(
        filter(data => !(data.indexOf(null) > -1)),
        map(([player]) => ({
          registrationCompleted: player.registrationCompleted,
        }))
      )
      .subscribe({
        next: value =>
          (this.finalizeRegistration =
            environment.clientName === 'ADMIRAL_CROATIA' ? value && !value.registrationCompleted : false),
      });

    if (this.finalizeRegistration && linkInformation.urlType !== 'register') {
      const player = this.appStateFacadeService.getPlayerData();
      this.checkIfOnlyF2FLeftAndInProcess(player);
    } else {
      if (returnBoolean) {
        return true;
      } else {
        this.navigate(linkInformation);
      }
    }
  }

  /**
   * On CRO environment proceed Dialog open if registration steps is not finished
   */
  private openProceedRegistrationDialog(isOnlyF2FLeftAndInProcess?: boolean): void {
    this.translate = AppInjector.get(TranslateService);
    const modalRef = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: 'static',
    });

    const title = this.translate.instant('COMMON_NOTIFICATIONS');
    const message = this.translate.instant(
      isOnlyF2FLeftAndInProcess ? 'WAITING_F2F_TO_COMPLETE' : 'PROCEED_REGISTRATION'
    );

    const data = {
      title,
      message,
      buttons: [
        {
          type: 'primary',
          label: this.translate.instant(
            isOnlyF2FLeftAndInProcess ? 'OK' : 'PROCEED_REGISTRATION_COMPONENT_BUTTON'
          ),
          on_click: true,
        },
      ],
    };

    // Set data on dialog instance to be used in called Modal
    modalRef.componentInstance.data = data;

    // navigate player to the unfinished step
    modalRef.result.then(on_click_event => {
      if (on_click_event) {
        // If only F2F step is left and in process, just close dialog without navigation.
        if (isOnlyF2FLeftAndInProcess) {
          return;
        }

        const registrationSteps = this.appStateFacadeService.getPlayerData().registrationSteps;
        const someStepsNotTouched = registrationSteps.some(step => step.is_completed === null);

        if (registrationSteps.length && someStepsNotTouched) {
          this.router.navigate(['/registration']);
        }

        if (registrationSteps.length && !someStepsNotTouched) {
          this.router.navigate(['/registration/unfinished']);
        }
      }
    });
  }

  /**
   * Open the appropriate dialog based on whether only the F2F step is left to complete and is in process or not.
   * @param player - Player object
   */
  checkIfOnlyF2FLeftAndInProcess(player: Player): void {
    this.playerService.isIdentityVerificationInProgress().subscribe({
      next: status => {
        if (!status.response) {
          // Player skipped face verification step (F2F). No need to check if it is the only one left to complete.
          this.openProceedRegistrationDialog();
          return;
        }

        // Check if all other mandatory steps are completed.
        const areOtherMandatoryStepsCompleted = player.registrationSteps.every(step => {
          if (step.registration_step === 'STEP_SIX' || step['is_optional']) {
            return true;
          }
          return step.is_completed;
        });
        this.openProceedRegistrationDialog(areOtherMandatoryStepsCompleted);
      },
    });
  }
}
