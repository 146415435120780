import { Component, OnInit, OnDestroy, Input, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { NavigationService } from '@services/navigation.service';
import { Menu } from '@models/config-data/menu/menu.model';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ConfigData } from '@models/config-data/config-data.model';
import { BalanceInfo } from '@models/balance-info.model';
import { Player } from '@models/player.model';
import { environment } from 'src/environments/environment';
import { PlayerService } from '@services/player.service';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '@services/helpers.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';
import { ImageCompressService } from '@services/image-compress.service';
import { IMAGE_ORIENTATION } from '@enums/image-orientation.enum';
import { BonusService } from '@services/bonus.service';
import { BONUS_TYPES } from '@enums/bonus-types.enum';
import { heightAnimation } from 'src/app/shared/animations/animations';
import { AuthService } from '@services/auth.service';
import { filter } from 'rxjs/operators';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { BalanceInfoInterface } from '../../shared/state/interfaces/AppStateInterface.interface';
import { BUTTON_TYPES } from '../../shared/directive/admiral-button.directive';
import { SalesForceChatService } from '@services/sales-force-chat.service';
import { PromotionService } from '@services/promotion.service';
import { CustomDialog } from 'src/app/shared/dialogs/custom-dialog/custom.dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LuckyWheel } from 'src/app/shared/components/lucky-wheel/lucky-wheel.component';

@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss'],
  animations: [heightAnimation('200ms')],
})
export class RightMenuComponent implements OnInit, OnDestroy {
  public balanceInfo: BalanceInfo;
  public admiral360BalanceInfo: Admiral360BalanceInfo;
  @ViewChild('contentOfMenu', { static: true }) contentOfMenu: ElementRef;
  @Input() player: Player;
  public rightBarExpanded: boolean;
  public unauthorizedUser: boolean;
  public chatEnabled: boolean;
  public unreadChat;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public environment = environment;
  public btnClass: string;
  public lockedBonuses = [];
  public lockedFreebets = [];
  public lockedFreebetsForDisplay = [];
  public lockedBonusesForDisplay = [];
  public BONUS_TYPES = BONUS_TYPES;
  public showWheel: boolean;
  public spinWheel: boolean;

  // Config
  public rightMenu: Menu;
  public CLIENT_NAMES = CLIENT_NAMES;
  public BUTTON_TYPES = BUTTON_TYPES;
  public logoImg: string;

  // Observables
  private configDataObservable: Observable<any>;
  private balanceInfoObservable: Observable<any>;
  private playerDataObservable: Observable<Player>;

  // Subscriptions
  private configData$: Subscription;
  private balanceInfo$: Subscription;
  private rightBarStatus$: Subscription;
  private playerDataBlinkingServiceStatus$: Subscription;
  private unreadChat$: Subscription;

  constructor(
    public helpersService: HelpersService,
    public appStateFacadeService: AppStateFacadeService,
    public navigationService: NavigationService,
    private playerService: PlayerService,
    private toaster: ToasterService,
    private translateService: TranslateService,
    private imageCompressService: ImageCompressService,
    private renderer: Renderer2,
    private bonusService: BonusService,
    private authService: AuthService,
    private salesForceChatService: SalesForceChatService,
    private promotionService: PromotionService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.setupStore();
    this.btnClass = this.helpersService.getPropertyValue('btnDepositClass');
    this.setConfigDataSubscription();
    this.setBalanceInfoSubscription();
    this.setRightBarSubscription();
    const userAndBlinking = combineLatest([
      this.playerDataObservable.pipe(filter(data => !!data)),
      this.appStateFacadeService.getBlinkingServiceStatus(),
    ]);
    this.playerDataBlinkingServiceStatus$ = userAndBlinking.subscribe({
      next: ([userData, blinkingStatus]) => {
        this.unauthorizedUser = this.helpersService.blinkingMarkerState(userData, blinkingStatus);
      },
    });
    if (this.environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET) {
      this.enableChat();
    }
  }

  ngOnDestroy(): void {
    this.configData$.unsubscribe();
    this.balanceInfo$.unsubscribe();
    this.rightBarStatus$.unsubscribe();
    this.playerDataBlinkingServiceStatus$.unsubscribe();
    if (this.unreadChat$) {
      this.unreadChat$.unsubscribe();
    }
  }

  // setup store
  private setupStore(): void {
    this.configDataObservable = this.appStateFacadeService.getConfigData();
    this.balanceInfoObservable = this.appStateFacadeService.getBalanceInfo();
    this.playerDataObservable = this.appStateFacadeService.getPlayerDataObservable();
  }

  // set config subscription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable.subscribe((configData: ConfigData) => {
      this.rightMenu = configData?.appMenus.rightMenu;
      this.logoImg = environment.imagesBaseUrl + configData?.logo;
    });
  }

  // set balance info subscription
  private setBalanceInfoSubscription(): void {
    this.balanceInfo$ = this.balanceInfoObservable.subscribe((balanceState: BalanceInfoInterface) => {
      const { playerBalanceInfo: playerBalanceInfo, admiral360BalanceInfo } = balanceState;

      // get locked bonuses
      if (balanceState.playerBalanceInfo && balanceState.playerBalanceInfo.lockedBonusCount) {
        this.getLockedBonuses(BONUS_TYPES.BONUS);
      } else {
        this.lockedBonuses = [];
        this.lockedBonusesForDisplay = [];
      }

      // get locked freebets
      if (balanceState.playerBalanceInfo && balanceState.playerBalanceInfo.lockedFreebetCount) {
        this.getLockedBonuses(BONUS_TYPES.FREEBET);
      } else {
        this.lockedFreebets = [];
        this.lockedFreebetsForDisplay = [];
      }

      this.balanceInfo = playerBalanceInfo || new BalanceInfo({});
      if (admiral360BalanceInfo) {
        this.admiral360BalanceInfo = admiral360BalanceInfo;
      }
    });
  }

  private setRightBarSubscription(): void {
    this.rightBarStatus$ = this.appStateFacadeService.observeRightBarStatus().subscribe((status: boolean) => {
      this.rightBarExpanded = status;
      this.renderer.setProperty(this.contentOfMenu.nativeElement, 'scrollTop', '0');
      if (this.player) {
        this.getLuckyWheels();
      }
    });
  }

  navigateToLinkInMenu(link) {
    this.appStateFacadeService.collapseAllSideBars();
    this.navigationService.navigate(link);
  }

  handleRightSideBar() {
    this.appStateFacadeService.expandCollapseRightSideBar();
  }

  public toggleChat(): void {
    const isChatWindowOpen = this.appStateFacadeService.isChatWindowOpen();
    if (isChatWindowOpen) {
      this.helpersService.closeChat(this.renderer, this.appStateFacadeService);
    } else {
      this.helpersService.openChat(this.renderer, this.appStateFacadeService);
    }
  }

  private enableChat(): void {
    this.chatEnabled = this.helpersService.chatEnabled();
    if (this.chatEnabled) {
      // salesforce chat for pinnbet
      if ((window as any).embedded_svc) {
        this.setSalesForceChatEventsSubscription();
      }
    }
  }

  private setSalesForceChatEventsSubscription() {
    this.unreadChat$ = this.salesForceChatService.unreadChat$.subscribe(unreadChat => {
      this.unreadChat = unreadChat;
    });
    this.salesForceChatService.setSalesForceChatEvents(this.renderer);
  }

  // On file change
  public changeAvatar(event) {
    const reader = new FileReader();

    const file = (event.target as HTMLInputElement).files[0];
    if (file instanceof Blob) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const result = String(reader.result);
        // this.imageCompressService.getImageOrientation(file).then(orientation => {
        this.imageCompressService
          .compressImage(result, IMAGE_ORIENTATION.Default, 50, 50)
          .then(compressedImage => {
            const base64 = compressedImage.split(',')[1];

            const imgObj = {
              data: base64,
              fileName: file.name,
              type: file.type,
            };
            this.uploadAvatar(imgObj);
          });
        // });
      };
    }
  }

  // upload new avatar
  private uploadAvatar(imgObj) {
    this.playerService.uploadAvatar(this.player.id, imgObj).subscribe(data => {
      // update player
      this.appStateFacadeService.setPlayerData(new Player(data));
      this.toaster.showSuccess(this.translateService.instant('SUCCESS_AVATAR_UPLOAD'));
    });
  }

  // close right menu on swipe
  public closeRightMenuOnSwipe() {
    if (this.isMobile) {
      this.appStateFacadeService.expandCollapseRightSideBar();
    }
  }

  // get locked bonuses
  private getLockedBonuses(type) {
    this.bonusService.getLockedBonuses(type).subscribe(data => {
      if (type === BONUS_TYPES.BONUS) {
        this.lockedBonuses = data;

        if (this.lockedBonusesForDisplay.length) {
          this.lockedBonusesForDisplay = this.lockedBonuses;
        }
      }

      if (type === BONUS_TYPES.FREEBET) {
        this.lockedFreebets = data;

        if (this.lockedFreebetsForDisplay.length) {
          this.lockedFreebetsForDisplay = this.lockedFreebets;
        }
      }
    });
  }

  // dropdown toggle
  public dropdownToggle(bonusType) {
    if (bonusType === BONUS_TYPES.BONUS) {
      if (this.lockedBonusesForDisplay.length) {
        this.lockedBonusesForDisplay = [];
      } else {
        this.lockedBonusesForDisplay = this.lockedBonuses;
      }
    }

    if (bonusType === BONUS_TYPES.FREEBET) {
      if (this.lockedFreebetsForDisplay.length) {
        this.lockedFreebetsForDisplay = [];
      } else {
        this.lockedFreebetsForDisplay = this.lockedFreebets;
      }
    }
  }

  private getLuckyWheels() {
    this.promotionService.getLuckyWheels().subscribe(data => {
      if (data.length) {
        this.showWheel = true;
        const now = new Date().getTime() + 1000; // add 1 second to avoid any issues with the time when spin is available imeadiately
        const spinTheWheel = data.find(
          wheel => wheel.playerInWheelSegment && wheel.nextSpinAvailableFrom <= now
        );
        if (spinTheWheel) {
          this.spinWheel = true;
        } else {
          this.spinWheel = false;
        }
      }
    });
  }

  public openLuckyWheel() {
    const draggableContentDilaog = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: false,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });

    draggableContentDilaog.componentInstance.data = {
      closeButtonDeactivated: false, // close btn visible
      component: LuckyWheel,
    };

    this.handleRightSideBar();
  }

  // logout
  public logout(): void {
    this.authService.logout('Right menu');
  }
}
