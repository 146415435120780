import { Component, ViewChild, ElementRef, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { DocumentScannerService } from '@services/document-scanner.service';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

import {
  CapturedFrame,
  RecognizerResultState,
  WasmSDK,
  WasmSDKLoadSettings,
  captureFrame,
  createBlinkIdMultiSideRecognizer,
  createRecognizerRunner,
  isBrowserSupported,
  loadWasmModule,
} from '@microblink/blinkid-in-browser-sdk';
import { HelpersService } from '@services/helpers.service';
import { Subscription, interval, takeWhile } from 'rxjs';

const COUNTDOWN_VALUE = 10;

@Component({
  selector: 'app-upload-document-scanner',
  templateUrl: './upload-document-scanner.component.html',
  styleUrls: ['./upload-document-scanner.component.scss'],
})
export class UploadDocumentScannerComponent implements OnDestroy {
  @Input() isProfileLocation = false;
  @Input() active: boolean;
  @Input() uploadedFiles: any;
  @Output() scanFinishedEvent = new EventEmitter<boolean>();
  @Output() stepSkippedEvent = new EventEmitter<boolean>();

  @ViewChild('targetImage') scanImageElement: ElementRef<HTMLImageElement>;
  @ViewChild('imageFileFrontSide') inputImageFileFrontSide: ElementRef<HTMLInputElement>;
  @ViewChild('imageFileBackSide') inputImageFileBackSide: ElementRef<HTMLInputElement>;

  public isUploadMenuActive = true;
  public isMobile = this.helperService.isMobile();
  public isScanInProgress = false;
  public scanInProgressCounter = COUNTDOWN_VALUE;
  private counterSubscription: Subscription;
  private processResultFrontSide: RecognizerResultState;
  private processResultBackSide: RecognizerResultState;

  constructor(
    private scannerService: DocumentScannerService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private helperService: HelpersService
  ) {}

  ngOnDestroy(): void {
    this.uploadedFiles.back = false;
    this.uploadedFiles.front = false;
    this.counterSubscription?.unsubscribe();
  }

  inputChanged(event): void {
    this.uploadedFiles[event.target.dataset.type] = !!event.target.value;
  }

  private loadScanSettings(): WasmSDKLoadSettings {
    // Check if browser is supported.
    if (!isBrowserSupported()) {
      console.log('This browser is not supported by the SDK!');
      return null;
    }
    const licenseKey = environment.microBlinkLicenceKey;
    const loadSettings = new WasmSDKLoadSettings(licenseKey);
    loadSettings.engineLocation = '/assets/microblink/resources';
    loadSettings.workerLocation = '/assets/microblink/resources/BlinkIDWasmSDK.worker.min.js';
    return loadSettings;
  }

  public handleUploadDocument(): void {
    const loadSettings = this.loadScanSettings();
    if (!loadSettings) {
      return;
    }
    loadWasmModule(loadSettings).then(
      (wasmSDK: WasmSDK) => {
        console.log('The SDK was initialized successfully.');
        this.startScanOfUploadedImages(wasmSDK);
      },
      (error: any) => {
        // Error happened during the initialization of the SDK
        console.log('Error during the initialization of the SDK!', error);
      }
    );
  }

  // Scan single side of identity document with web camera.
  private async startScanOfUploadedImages(sdk: WasmSDK) {
    this.isUploadMenuActive = false;
    this.startScanProgressCounter();

    // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
    //
    // BlinkID Multi-side Recognizer - scan ID documents on both sides.
    const multiSideGenericIDRecognizer = await createBlinkIdMultiSideRecognizer(sdk);

    // [OPTIONAL] Recognizer settings.
    const settings = await multiSideGenericIDRecognizer.currentSettings();
    settings.returnEncodedFullDocumentImage = true; // Set recognizer to return cropped image it extracted the data from.
    settings.allowUncertainFrontSideScan = false; // Proceed with scanning the back side even if the front side result is uncertain.
    settings.returnEncodedFaceImage = true;
    await multiSideGenericIDRecognizer.updateSettings(settings);

    // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more recognizer objects.
    const recognizerRunner = await createRecognizerRunner(
      // SDK instance to use.
      sdk,
      // List of recognizer objects that will be associated with created RecognizerRunner object.
      [multiSideGenericIDRecognizer],
      // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition.
      false
    );

    // 3. Prepare front side image for scan action - keep in mind that SDK can only process images represented
    // in internal CapturedFrame data structure. Therefore, auxiliary method "captureFrame" is provided.

    // Make sure that image file is provided.
    const fileFrontSide = this.getImageFromInput(this.inputImageFileFrontSide.nativeElement.files);

    if (!fileFrontSide) {
      this.toasterService.showError(this.translateService.instant('SCAN_FRONT_SIDE_NO_IMAGE'));
      // Release memory on WebAssembly heap used by the RecognizerRunner.
      recognizerRunner?.delete();

      // Release memory on WebAssembly heap used by the recognizer.
      multiSideGenericIDRecognizer?.delete();
      this.inputImageFileFrontSide.nativeElement.value = '';
      this.isUploadMenuActive = true;
      return;
    }

    const frontImageFrame = await this.getImageFrame(fileFrontSide);

    // 4. Start the recognition and await for the results.
    this.processResultFrontSide = await recognizerRunner.processImage(frontImageFrame);

    // 5. If recognition of the front side was successful, process the back side.
    if (this.processResultFrontSide !== RecognizerResultState.Empty) {
      // 6. Prepare back side image for scan action.
      const fileBackSide = this.getImageFromInput(this.inputImageFileBackSide.nativeElement.files);
      if (!fileBackSide) {
        this.toasterService.showError(this.translateService.instant('SCAN_BACK_SIDE_NO_IMAGE'));
        // Release memory on WebAssembly heap used by the RecognizerRunner.
        recognizerRunner?.delete();

        // Release memory on WebAssembly heap used by the recognizer.
        multiSideGenericIDRecognizer?.delete();
        this.inputImageFileBackSide.nativeElement.value = '';
        this.isUploadMenuActive = true;
        return;
      }

      const backImageFrame = await this.getImageFrame(fileBackSide);

      // 7. Start the recognition and await for the results.
      this.processResultBackSide = await recognizerRunner.processImage(backImageFrame);

      if (this.processResultBackSide !== RecognizerResultState.Empty) {
        // 8. If recognition of the back side was successful, obtain the result.
        const recognitionResults = await multiSideGenericIDRecognizer.getResult();

        if (recognitionResults.state !== RecognizerResultState.Empty) {
          this.scannerService.setScanResult(recognitionResults);
          this.scanFinishedEvent.emit(true);
        }
      } else {
        this.stopScanProgressCounter();
        this.isUploadMenuActive = true;
        this.inputImageFileBackSide.nativeElement.value = '';
        this.uploadedFiles.back = false;
        this.toasterService.showError(this.translateService.instant('SCAN_BACK_SIDE_EXTRACTION_FAILED'));
      }
    } else {
      this.stopScanProgressCounter();
      this.isUploadMenuActive = true;
      this.inputImageFileFrontSide.nativeElement.value = '';
      this.uploadedFiles.front = false;
      this.toasterService.showError(this.translateService.instant('SCAN_FRONT_SIDE_EXTRACTION_FAILED'));
    }

    // 9. Release all resources allocated on the WebAssembly heap and associated with camera stream.

    // Release memory on WebAssembly heap used by the RecognizerRunner.
    recognizerRunner?.delete();

    // Release memory on WebAssembly heap used by the recognizer.
    multiSideGenericIDRecognizer?.delete();

    // Hide image preview screen.
    this.scanImageElement.nativeElement.src = '';
  }

  private getImageFromInput(fileList) {
    let image = null;
    const imageRegex = RegExp(/^image\//);
    for (const element of fileList) {
      if (imageRegex.exec(element.type)) {
        image = element;
      }
    }
    return image;
  }

  private read = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => resolve(event.target.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  private async getImageFrame(file: any): Promise<CapturedFrame> {
    const blob = (await this.read(file)) as string;
    this.scanImageElement.nativeElement.src = blob;
    await this.scanImageElement.nativeElement.decode();
    return captureFrame(this.scanImageElement.nativeElement);
  }

  public stepSkipped() {
    this.stepSkippedEvent.emit();
  }

  private startScanProgressCounter(): void {
    this.isScanInProgress = true;

    // Start the countdown.
    this.counterSubscription = interval(1000)
      .pipe(takeWhile(() => this.scanInProgressCounter > 0))
      .subscribe(() => {
        this.scanInProgressCounter--;

        // Hide the counter when it reaches 0 or when the scan process is finished.
        if (
          this.scanInProgressCounter < 0 ||
          (this.processResultFrontSide !== undefined && this.processResultBackSide !== undefined)
        ) {
          this.stopScanProgressCounter();
        }
      });
  }

  private stopScanProgressCounter(): void {
    this.isScanInProgress = false;
    this.scanInProgressCounter = COUNTDOWN_VALUE;
    this.counterSubscription?.unsubscribe();
  }
}
