export enum WITHDRAWAL_CODES {
  ADMIRAL_WITHDRAWAL = 'ADMIRAL_WITHDRAWAL',
  PINNBET_WITHDRAWAL = 'PINNBET_WITHDRAWAL',
  MDSHOP_WITHDRAWAL = 'MDSHOP_WITHDRAWAL',
  BANK_ACCOUNT_WITHDRAWAL = 'BANK_ACCOUNT_WITHDRAWAL',
  YO_PAYMENT_WITHDRAWAL = 'YO_PAYMENT_WITHDRAWAL',
  AIRTEL_WITHDRAWAL = 'AIRTEL_WITHDRAWAL',
  PAYSPOT_WITHDRAWAL = 'PAYSPOT_WITHDRAWAL',
  PAYSTACK_WITHDRAWAL = 'PAYSTACK_WITHDRAWAL',
  FLUTTERWAVE_WITHDRAWAL = 'FLUTTERWAVE_WITHDRAWAL',
  ADMIRAL_360_WITHDRAWAL = 'NXCS_WITHDRAWAL',
  SKRILL_WITHDRAWAL = 'SKRILL_WITHDRAWAL',
  AIRCASH_WITHDRAWAL = 'AIRCASH_WITHDRAWAL',
  RESERVED_FUNDS_WITHDRAWAL = 'RESERVED_FUNDS_WITHDRAWAL',
  SETTLE_WITHDRAWAL = 'SETTLE_WITHDRAWAL',
  DEPOZITRON_WITHDRAWAL = 'DEPOZITRON_WITHDRAWAL',
  NUVEI_WITHDRAWAL = 'NUVEI_WITHDRAWAL',
  OKTO_WITHDRAWAL = 'OKTO_WITHDRAWAL',
}
