export class AssecoChatService {
  private liveToolbar: any;
  private liveChat: any;

  public setAssecoLiveChat(player?, video?) {
    // after livechat.js script is loaded Asseco object is present in window object
    if (this.liveChat) {
      this.liveChat.destroy();
    }
    if (window['Asseco']) {
      const Asseco = window['Asseco'];
      const playerId = player ? player.id : null;

      Asseco.config = {
        lang: 'hr', // language of the client
        ChatServerUrl: 'https://chat.admiral.hr/liveChat', // URL to Virtual Branch Server)
      };

      if (playerId) {
        Asseco.customer = {
          customer_role: playerId.toString(), // customer_role parameter indicates that customer id is given for identification
        };
      } else if (Asseco.customer) {
        delete Asseco.customer; // if customer was there (user was logged in) and then logout we need to remove customer from asseco object
      }

      this.createLiveChat(Asseco, video);
    }
  }

  private createLiveChat(Asseco, video) {
    const chatType = video ? 'LiveVideoChat' : 'LiveChat';
    const options: any = {
      position: {
        position: 'fixed',
        bottom: '10px',
        right: '15px',
      },
      cls: 'assecoContainerClass',
      autoInit: true,
    };
    if (video) {
      options.sgID = '4';
    }
    this.liveChat = new Asseco[chatType](options);
  }

  // COMMENTED CODE IS FOR CHAT THAT HAVE MORE OPTIONS THE JUST LIVE CHAT - IT WILL BE USED LATER WHEN CRO CLIENT DESIDE TO HAVE VIDEO CHAT

  // public setLiveChatConfig(player?) {
  //   // after livechat.js script is loaded Asseco object is present in window object
  //   if (window['Asseco']) {
  //     const Asseco = window['Asseco'];
  //     const playerId = player ? player.id : null;

  //     Asseco.config = {
  //       lang: 'hr', // language of the client
  //       ChatServerUrl: 'https://chat.admiral.hr/liveChat', // URL to Virtual Branch Server)
  //     };

  //     if (playerId) {
  //       Asseco.customer = {
  //         customer_role: playerId.toString(), // customer_role parameter indicates that customer id is given for identification
  //       };
  //     } else if (Asseco.customer) {
  //       delete Asseco.customer; // if customer was there (user was logged in) and then logout we need to remove customer from asseco object
  //     }

  //     if (this.liveToolbar) {
  //       this.liveToolbar.destroy();
  //       this.liveToolbar = null;
  //     }
  //     this.createLiveChatTolbarVisible(Asseco);
  //   }
  // }

  // private createLiveChatTolbarVisible(Asseco) {
  //   this.liveToolbar = new Asseco.LiveToolbar({
  //     cls: 'assecoContainerClass',
  //     // if not defined, default position is position: fixed, bottom: 10px, right: 10px
  //     position: {
  //       position: 'fixed',
  //       bottom: '10px',
  //       right: '15px',
  //     },
  //     // if plugins parameter is not defined then all available components will be included
  //     plugins: [Asseco.LiveChat],
  //     autoInit: true
  //     // renderHidden: true,
  //     // deferShow: 0
  //   });
  // }

  // public showLiveToolbar() {
  //   if (this.liveToolbar) {
  //     this.liveToolbar.show();
  //   }
  // }

  // public hideLiveToolbar() {
  //   if (this.liveToolbar) {
  //     this.liveToolbar.hide();
  //   }
  //   const body = document.getElementsByTagName('body')[0];
  //   const observer = new MutationObserver(mutations => {
  //     mutations.forEach(mutation => {
  //       if (mutation.addedNodes[0] && mutation.addedNodes[0]['id'] === 'asseco-toolbar') {
  //         this.liveToolbar.hide();
  //         observer.disconnect();
  //       }
  //     });
  //   });
  //   observer.observe(body, {
  //     childList: true,
  //   });
  // }
}
