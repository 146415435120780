import { IImage } from './header-cms-menu-component/header-cms-menu.component';

export class MenuItem {
  icon: string;
  image: null | IImage;
  index: number;
  link: string;
  url: string;
  text: string;
  title: string;
  type: string;
  localizationKey: string | null;

  constructor(data: any) {
    this.icon = data.icon || '';
    this.image = data.image || null;
    this.index = data.index || 0;
    this.link = data.link || '';
    this.url = data.link || '';
    this.text = data.text || '';
    this.title = data.title || '';
    this.type = data.typeOfLink || 'INTERNAL_LINK';
    this.localizationKey = data.localizationKey || null;
  }
}

export class NavigationMenu {
  assetType: string;
  id: number;
  items: any[];
  title: string;

  constructor(data: any) {
    this.assetType = data.assetType || '';
    this.id = data.id || 0;
    this.items = data.items ? data.items.map((item: MenuItem) => new MenuItem(item)) : [];
    this.title = data.title || '';
  }
}

export class CmsHeader {
  faviconImagePath: string;
  headerLayoutDesktop: string;
  headerLayoutMobile: string;
  headerLayoutTablet: string;
  loginButtonIcon: string;
  loginButtonLinkColor: Color;
  loginButtonLinkHoverColor: Color;
  logoImagePath: string;
  logoMobileImagePath: string;
  logoRetinaImagePath: string;
  navigationMenuAssetsItems: any[];
  navigationmenuIcon: string;
  notificationShowTime: boolean;
  notificationButtonIcon: string;
  profilePicturePath: string;
  registrationButtonIcon: string;
  registrationButtonLinkColor: Color;
  registrationButtonLinkHoverColor: Color;
  showLoginButtonIcon: boolean;
  showLoginButtonTitle: boolean;
  showProfilePicture: boolean;
  showRegisterButtonTitle: boolean;
  showRegistrationButtonIcon: boolean;
  showTime: boolean;
  showTimeIcon: boolean;
  timeColor: Color;
  i18nLogin: string | null;
  i18nRegistration: string | null;
  i18nNotification: string | null;

  constructor(data: any) {
    this.faviconImagePath = data.faviconImagePath || '';
    this.headerLayoutDesktop = data.headerLayoutDesktop || '';
    this.headerLayoutMobile = data.headerLayoutMobile || '';
    this.headerLayoutTablet = data.headerLayoutTablet || '';
    this.loginButtonIcon = data.loginButtonIcon || '';
    this.loginButtonLinkColor = data.loginButtonLinkColor || new Color({});
    this.loginButtonLinkHoverColor = data.loginButtonLinkHoverColor || new Color({});
    this.logoImagePath = data.logoImagePath || '';
    this.logoMobileImagePath = data.logoMobileImagePath || '';
    this.logoRetinaImagePath = data.logoRetinaImagePath || '';
    this.navigationMenuAssetsItems = data.navigationMenuAssets
      .reduce((acc, asset: NavigationMenu) => {
        acc.push(...asset.items);
        return acc;
      }, [])
      .map(items => new MenuItem(items));
    this.navigationmenuIcon = data.navigationmenuIcon || '';
    this.notificationShowTime = data.notificationShowTime || false;
    this.notificationButtonIcon = data.notificationButtonIcon || '';
    this.profilePicturePath = data.profilePicturePath || '';
    this.registrationButtonIcon = data.registrationButtonIcon || '';
    this.registrationButtonLinkColor = data.registrationButtonLinkColor || new Color({});
    this.registrationButtonLinkHoverColor = data.registrationButtonLinkHoverColor || new Color({});
    this.showLoginButtonIcon = data.showLoginButtonIcon || false;
    this.showLoginButtonTitle = data.showLoginButtonTitle || false;
    this.showProfilePicture = data.showProfilePicture || false;
    this.showRegisterButtonTitle = data.showRegisterButtonTitle || false;
    this.showRegistrationButtonIcon = data.showRegistrationButtonIcon || false;
    this.showTime = data.showTime || false;
    this.showTimeIcon = data.showTimeIcon || false;
    this.timeColor = data.timeColor || new Color({});
    this.i18nLogin = data.CMS_LOGIN_BUTTON || null;
    this.i18nRegistration = data.CMS_REGISTRATION_BUTTON || null;
    this.i18nNotification = data.CMS_NOTIFICATION_BUTTON || null;
  }
}

class Color {
  hex: string;
  name: string;

  constructor(data: any) {
    this.hex = data.hex || '#ffffff';
    this.name = data.name || 'white';
  }
}

export class CmsGeneralHeaderSettings {
  borderColor: Color;
  menuIconColor: Color;
  menuTextColor: Color;
  backgroundColor: Color;
  menuBorderColor: Color;
  menuIconTextColor: Color;
  menuTextHoverColor: Color;
  menuBackgroundColor: Color;
  menuTextActiveColor: Color;
  mobileMenuIconColor: Color;
  menuBorderHoverColor: Color;
  menuBorderActiveColor: Color;
  menuBackgroundHoverColor: Color;
  menuBackgroundActiveColor: Color;

  constructor(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = new Color(data[key]);
      }
    }
  }
}
