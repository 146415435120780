<div>
  <!-- LOADER -->
  <div *ngIf="loadingState" class="spinner-backdrop">
    <div class="spinner-border spinner" role="status"></div>
  </div>

  <div
    class="corvus-payment-wrapper p-3"
    [ngClass]="{ 'corvus-payment-wrapper--is-no-card': !previousSavedCards.length && !chosenCard }"
  >
    <ng-container *ngIf="previousSavedCards.length && chosenCard">
      <div class="corvuspay-payment-form">
        <div class="saved-card-wrapper saved-card-wrapper--is-expand mb-3">
          <div class="c_choose-card-label-wrapper">
            <label class="label required" *ngIf="isMobile">{{ 'SELECT_CARD' | translate }}</label>
            <div class="c_create_new_cart_label" (click)="switchSavedCardOrNewCard('newCard')">
              <div class="c_plus-badge"></div>
              <div>{{ 'ALLSECURE_CARD_NUMBER_PLACEHOLDER' | translate }}</div>
              <div
                class="u_pointer close-icon-bar-mobile close-icon-bar-mobile--is-select-new-card"
                (click)="closeQuickBar()"
                [inlineSVG]="'assets/icons/close_cross.svg'"
              ></div>
            </div>
          </div>
          <div class="u_desktop-flex-column u_pointer">
            <label class="label required mb-0" *ngIf="!isMobile">{{ 'SELECT_CARD' | translate }}</label>
            <div class="saved-card-preview" (click)="openModalToChoseCard()">
              <div class="saved-card-stars">
                {{ chosenCard.lastFourDigits || previousSavedCards[0].lastFourDigits || ' ' }}
              </div>
              <div class="aztec-arrow-wrapper">
                <ng-template *ngTemplateOutlet="chevronLeftIcon"></ng-template>
                <ng-template *ngTemplateOutlet="deleteIcon"></ng-template>
              </div>
            </div>
          </div>
        </div>

        <!-- IF corvusFormCollapsed means we dont use corvus form for payment we use saved credit card   -->
        <div class="item-button-wrapper">
          <input
            class="item-button"
            type="submit"
            (click)="sendPaymentDataSavedCard()"
            value="{{ 'PAY_NOW' | translate }}"
            [disabled]="submitBtnDisabled"
          />
        </div>
        <div
          class="u_pointer close-icon-bar-desktop close-icon-bar--is-new-card-form"
          (click)="closeQuickBar()"
          [inlineSVG]="'assets/icons/close_cross.svg'"
        ></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!previousSavedCards.length && !chosenCard">
      <div class="c_choose-card-label-wrapper">
        <div></div>
        <div class="c_create_new_cart_label" (click)="switchSavedCardOrNewCard('newCard')">
          <div class="c_plus-badge"></div>
          <div>{{ 'ALLSECURE_CARD_NUMBER_PLACEHOLDER' | translate }}</div>
          <div
            class="u_pointer close-icon-bar-mobile close-icon-bar-mobile--is-select-new-card"
            (click)="closeQuickBar()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
      </div>
      <div class="c_message-style" *ngIf="!isCardRegister">
        {{ 'NUVEI_NO_SAVED_CARDS' | translate }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #choseSavedCardsModal>
  <div class="box select-cart-dialog">
    <ng-container *ngFor="let card of previousSavedCards; let i = index">
      <div class="select-card-option" (click)="chooseCard(i)">
        <div *ngIf="card.lastFourDigits" class="saved-card-stars">{{ card.lastFourDigits }}</div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #chevronLeftIcon>
  <div class="material-symbols-outlined u_pointer">arrow_left</div>
</ng-template>

<ng-template #deleteIcon>
  <div
    class="material-symbols-outlined u_pointer"
    (click)="deleteCard($event, chosenCard?.id || previousSavedCards[0].id)"
  >
    delete
  </div>
</ng-template>

<ng-template #modalTemplateDeleteCard>
  <div class="box delete-card-dialog">
    <div class="delete-card-dialog-buttons">
      <button class="item-button" (click)="deleteCardConfirm()">
        {{ 'DELETE_CARD' | translate }}
      </button>
      <button class="item-button" (click)="modalRef.close()">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>
