export class FooterIcons {
  public code: string;
  public url: string;
  public urlType: string;
  public title: string;
  public items: any;

  constructor(data) {
    this.code = data.code;
    this.url = data.url;
    this.urlType = data.urlType;
    this.title = data.title;
    this.items = data.items;
  }
}
