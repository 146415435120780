<!-- LAST USED PAYMENT CARD -->
<ng-container *ngIf="lastUsedPayment; else regularPayment">
  <div (click)="clickOnCard(lastUsedPayment.redirectionLink)">
    <div
      class="box bg-lightgray p-custom"
      [ngClass]="{ 'pinnbet-last-used-payment': environment.clientName === clientNames.ADMIRAL_PINNBET }"
    >
      <div class="last-used-payment-card-container d-flex flex-row align-items-center">
        <div class="payment-card-logo">
          <img [src]="lastUsedPayment.logo" alt="Last used payment logo" />
        </div>
        <div class="payment-card-name">
          <h2 class="h3 text-primary mb-0 font font--size-14 font--weight-700">{{ lastUsedPayment.name }}</h2>
          <div
            [ngClass]="{
              'pt-2 ot-lg-3 text-light font font--size-9 font--weight-500':
                environment.clientName !== clientNames.ADMIRAL_PINNBET
            }"
          >
            <div class="text-muted pb-1">{{ 'PAYMENT_CARD_LIMIT' | translate }}</div>
            <div *ngIf="lastUsedPayment.maxValue">
              {{ lastUsedPayment.minValue | number : '1.2-2' }} -
              {{ lastUsedPayment.maxValue | number : '1.2-2' | currency : lastUsedPayment.currency }}
            </div>
            <div *ngIf="!lastUsedPayment.maxValue">
              {{ 'PAYMENT_CARD_MIN_VALUE' | translate }}
              {{ lastUsedPayment.minValue | number : '1.2-2' | currency : lastUsedPayment.currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- REGULAR PAYMENT CARD -->
<ng-template #regularPayment>
  <div (click)="clickOnCard(payment.redirectionLink, true)">
    <div
      class="box bg-lightgray p-2 p-lg-3"
      [ngClass]="{ 'pinnbet-regular-payment': environment.clientName === clientNames.ADMIRAL_PINNBET }"
    >
      <div class="payment-card-container d-flex flex-row align-items-center">
        <div class="payment-card-logo">
          <img [src]="payment.logo" alt="Payment logo" />
        </div>
        <div class="payment-card-name">
          <h2
            [ngClass]="{
              'h4 text-black mb-0 font font--size-9 font--weight-700':
                environment.clientName !== clientNames.ADMIRAL_PINNBET
            }"
          >
            {{ payment.name }}
          </h2>
        </div>
      </div>

      <div
        [ngClass]="
          environment.clientName !== clientNames.ADMIRAL_PINNBET
            ? 'pt-2 ot-lg-3 text-light font font--size-9 font--weight-500'
            : 'pinnbet-limit-section'
        "
      >
        <div class="text-muted pb-1">{{ 'PAYMENT_CARD_LIMIT' | translate }}</div>
        <div *ngIf="payment.maxValue">
          {{ payment.minValue | number : '1.2-2' }} -
          {{ payment.maxValue | number : '1.2-2' | currency : payment.currency }}
        </div>
        <div *ngIf="!payment.maxValue">
          {{ 'PAYMENT_CARD_MIN_VALUE' | translate }}
          {{ payment.minValue | number : '1.2-2' | currency : payment.currency }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
