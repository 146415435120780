export class TransactionFilterModel {
  period: string;
  startDate: string;
  endDate: string;

  constructor(data?) {
    this.period = data?.period || '';
    this.startDate = data?.startDate || '';
    this.endDate = data?.endDate || '';
  }
}
