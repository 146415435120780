import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToasterService } from '../services/toaster.service';
import { AuthService } from '../services/auth.service';

// prevent toster errors, bcs we have errors popups on this urls (url is from event.url or error.url not url from route)
const PREVENT_TOSTER_ERRORS_ON_EVENTS_URLS = ['deposit/yo', 'withdrawal/airtel'];
const PREVENT_TOSTER_ERRORS_ON_ERRORS_URLS = [
  'withdrawal/yo',
  'withdrawal/airtel',
  'bank-account-withdrawal',
  'office-withdrawal',
  'payspot/withdrawal',
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toasterService: ToasterService, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: event => {
          if (event instanceof HttpResponse) {
            if (event.body && event.body.code === 1) {
              if (!PREVENT_TOSTER_ERRORS_ON_EVENTS_URLS.find(url => event.url.includes(url))) {
                this.toasterService.showError(event.body.message);
              }
              // Simply throw an error, and RxJS will pick that up and notify the consumer of the error.
              const errorObj = new Error(event.body.message);
              errorObj['status'] = event.status || 400;
              errorObj['body'] = event.body;
              throw errorObj;
            }
          }
          return event;
        },
        error: error => {
          // ignore helpcrunch chat errors
          if (error.url.includes('helpcrunch')) {
            return;
          }
          switch (error.status) {
            case 401:
              this.toasterService.showErrorTranslatingKey('UNAUTHORIZED');
              this.authService.logout('error 401');
              break;
            case 403:
              if (!PREVENT_TOSTER_ERRORS_ON_ERRORS_URLS.find(url => error.url.includes(url))) {
                this.toasterService.showErrorTranslatingKey('ERROR_INTERCEPTOR_PERMISION');
              }
              break;
            case 500:
              const serverErrorMessage = error?.error?.message || null;
              this.toasterService.showError(serverErrorMessage);
              break;
          }
        },
      })
    );
  }
}
