<div class="c_cms-header-menu">
  <ul>
    <ng-template #tipContent>{{ 'HELP' | translate }}</ng-template>
    <ng-container *ngIf="navigationMenu">
      <ng-container *ngFor="let menuItem of navigationMenu">
        <li
          [ngbTooltip]="tipContent"
          placement="right"
          [disableTooltip]="menuItem?.link !== '/help'"
          [routerLinkActive]="['active-link']"
          (click)="navigationService.navigate(menuItem)"
        >
          <ng-container *ngIf="!(menuItem.requireAuthorized && !player)">
            <a routerLink="{{ menuItem.url }}">
              <div class="d-flex align-items-center h-100">
                <i
                  *ngIf="menuItem?.image && menuItem.image.type === 'image/svg+xml'"
                  [setSVGAttributes]="{ height: '18px' }"
                  [inlineSVG]="imagesBaseUrl + menuItem?.image.path"
                  fallbackSVG="assets/images/default-left-menu-icon.svg"
                  class="d-flex align-items-center"
                ></i>
                <img
                  *ngIf="menuItem?.image && menuItem.image.type === 'image/png'"
                  [src]="imagesBaseUrl + menuItem?.image.path"
                  alt="icon"
                />
                <mat-icon *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
                <span
                  *ngIf="!menuItem?.localizationKey"
                  class="menu-item-name"
                  [innerHTML]="menuItem.text"
                ></span>
                <span *ngIf="menuItem?.localizationKey" class="menu-item-name">
                  {{ menuItem.localizationKey | translate }}
                </span>
              </div>
            </a>
          </ng-container>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
