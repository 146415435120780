<div>
  <ngb-accordion #a="ngbAccordion" activeIds="panel_{{ activePanelID }}">
    <ngb-panel *ngFor="let item of dataForAccordion; let i = index" id="panel_{{ i + 1 }}">
      <ng-template ngbPanelHeader>
        <ng-container
          *ngTemplateOutlet="
            panelHeader;
            context: {
              data: item,
              itemIndex: i,
              expandPanel: expandPanel.bind(this)
            }
          "
        >
        </ng-container>
      </ng-template>
      <ng-template ngbPanelContent>
        <ng-container
          *ngTemplateOutlet="
            panelContent;
            context: {
              data: item,
              itemIndex: i,
              closePanel: closePanel.bind(this)
            }
          "
        >
        </ng-container>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
