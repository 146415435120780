export enum PRODUCT_CODES {
  SPORT = 'SPORT',
  SLOT = 'SLOT',
  LIVE_DEALER = 'LIVE_DEALER',
  CASINO = 'CASINO',
  LIVE_CASINO = 'LIVE_CASINO',
  VIRTUAL_GAMES = 'VIRTUAL_GAMES',
  ELBET = 'ELBET',
  SEVEN_AGGREGATOR = 'SEVEN_AGGREGATOR',
  SPRIBE = 'SPRIBE',
  GOLDEN_RACE = 'GOLDEN_RACE',
  EXEFEED = 'EXEFEED',
  HR_LOTO = 'HR_LOTO',
  POKER = 'POKER',
  AVIATRIX = 'AVIATRIX',
}
