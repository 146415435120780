import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { DocumentScannerService } from '@services/document-scanner.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDialog } from '../../dialogs/custom-dialog/custom.dialog';

import {
  DetectionStatus,
  DisplayableQuad,
  RecognizerResultState,
  VideoRecognizer,
  WasmSDK,
  WasmSDKLoadSettings,
  createBlinkIdMultiSideRecognizer,
  createRecognizerRunner,
  isBrowserSupported,
  loadWasmModule,
} from '@microblink/blinkid-in-browser-sdk';
import { HelpersService } from '@services/helpers.service';
import { Subscription, interval, takeWhile } from 'rxjs';

const COUNTDOWN_VALUE = 10;

@Component({
  selector: 'app-live-document-scanner',
  templateUrl: './live-document-scanner.component.html',
  styleUrls: ['./live-document-scanner.component.scss'],
})
export class LiveDocumentScannerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() active = false;
  @Input() isProfileLocation = false;
  @Output() scanFinishedEvent = new EventEmitter<boolean>();
  @Output() switchToUploadPageEvent = new EventEmitter<boolean>();
  @Output() stepSkippedEvent = new EventEmitter<boolean>();
  private wasmSDK: WasmSDK;

  private scanFeedbackLock = false;
  private scanDetectionSuccessMsg: string;
  private scanDetectionCameraAtAngleMsg: string;
  private scanDetectionCameraTooHighMsg: string;
  private scanDetectionCameraTooNearMsg: string;
  public shouldHideUntilConfirmed = false;
  public documentSideMsg: string;
  public isMobile = this.helperService.isMobile();

  public isCameraActivated = false;
  public counter = COUNTDOWN_VALUE;
  private counterSubscription: Subscription;

  // UI elements for scanning feedback
  @ViewChild('cameraFeed') cameraFeed: ElementRef<HTMLVideoElement>;
  @ViewChild('cameraFeedback') cameraFeedback: ElementRef;
  @ViewChild('cameraGuides') scanFeedback: ElementRef<HTMLDivElement>;

  constructor(
    private scannerService: DocumentScannerService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private helperService: HelpersService
  ) {
    this.scanDetectionSuccessMsg = this.translateService.instant('SCAN_DETECTION_SUCCESS');
    this.scanDetectionCameraAtAngleMsg = this.translateService.instant('SCAN_DETECTION_CAMERA_AT_ANGLE');
    this.scanDetectionCameraTooHighMsg = this.translateService.instant('SCAN_DETECTION_CAMERA_TOO_HIGH');
    this.scanDetectionCameraTooNearMsg = this.translateService.instant('SCAN_DETECTION_CAMERA_TOO_NEAR');
    this.documentSideMsg = 'CRO_V2_SCAN_PERSONAL_ID';
  }

  ngOnInit(): void {
    this.startCounter();
  }

  ngOnChanges(): void {
    if (this.active) {
      this.performLiveScanDocument();
    }
  }

  ngOnDestroy(): void {
    if (this.wasmSDK) {
      this.wasmSDK?.delete();
    }
    if (this.counterSubscription) {
      this.counterSubscription?.unsubscribe();
    }
  }

  private loadScanSettings(): WasmSDKLoadSettings {
    // Check if browser is supported.
    if (!isBrowserSupported()) {
      console.log('This browser is not supported by the SDK!');
      return null;
    }
    const licenseKey = environment.microBlinkLicenceKey;
    const loadSettings = new WasmSDKLoadSettings(licenseKey);
    loadSettings.engineLocation = '/assets/microblink/resources';
    loadSettings.workerLocation = '/assets/microblink/resources/BlinkIDWasmSDK.worker.min.js';
    return loadSettings;
  }

  public performLiveScanDocument(): void {
    const loadSettings = this.loadScanSettings();
    if (!loadSettings) {
      return;
    }
    loadWasmModule(loadSettings).then(
      (wasmSDK: WasmSDK) => {
        console.log('The SDK was initialized successfully.');
        this.startLiveScan(wasmSDK);
        this.wasmSDK = wasmSDK;
      },
      (error: any) => {
        // Error happened during the initialization of the SDK.
        console.log('Error during the initialization of the SDK!', error);
      }
    );
  }

  // Scan identity document with web camera.
  private async startLiveScan(sdk: WasmSDK) {
    // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
    //
    // BlinkID Multi-side Recognizer - scan ID documents on both sides.
    const multiSideGenericIDRecognizer = await createBlinkIdMultiSideRecognizer(sdk);

    // [OPTIONAL] Recognizer settings.
    const settings = await multiSideGenericIDRecognizer.currentSettings();
    settings['returnEncodedFullDocumentImage'] = true; // Set recognizer to return cropped image it extracted the data from.
    settings.allowUncertainFrontSideScan = false; // Proceed with scanning the back side even if the front side result is uncertain.
    settings.returnEncodedFaceImage = true;
    await multiSideGenericIDRecognizer.updateSettings(settings);

    const detectionFailedMsg = this.translateService.instant('SCAN_DETECTION_FAILED');

    // Create a callbacks object that will receive recognition events, such as detected object location etc.
    const callbacks = {
      onQuadDetection: (quad: DisplayableQuad) => this.drawQuad(quad),
      onDetectionFailed: () => this.updateScanFeedback(detectionFailedMsg, true),
      // This callback is required for multi-side experience.
      onFirstSideResult: () => this.openBackSideConfirmationDialog(),
    };

    // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more recognizer objects.
    const recognizerRunner = await createRecognizerRunner(
      // SDK instance to use.
      sdk,
      // List of recognizer objects that will be associated with created RecognizerRunner object.
      [multiSideGenericIDRecognizer],
      // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition.
      false,
      // Callbacks object that will receive recognition events.
      callbacks
    );

    // 3. Create a VideoRecognizer object and attach it to HTMLVideoElement that will be used for displaying the camera feed.
    const videoRecognizer = await VideoRecognizer.createVideoRecognizerFromCameraStream(
      this.cameraFeed.nativeElement,
      recognizerRunner
    );

    // 4. Start the recognition and get results from callback.
    try {
      videoRecognizer.startRecognition(
        // 5. Obtain the results.
        async recognitionState => {
          if (!videoRecognizer) {
            return;
          }
          // Pause recognition before performing any async operation.
          videoRecognizer.pauseRecognition();

          if (recognitionState === RecognizerResultState.Empty) {
            return;
          }
          const recognitionResults = await multiSideGenericIDRecognizer.getResult();

          if (recognitionResults.state === RecognizerResultState.Empty) {
            return;
          }

          // 6. Release all resources allocated on the WebAssembly heap and associated with camera stream.
          // Release browser resources associated with the camera stream.
          videoRecognizer?.releaseVideoFeed();

          // Release memory on WebAssembly heap used by the RecognizerRunner.
          await recognizerRunner?.delete();

          // Release memory on WebAssembly heap used by the recognizer.
          await multiSideGenericIDRecognizer?.delete();

          // Clear any leftovers drawn to canvas.
          this.clearDrawCanvas();

          // Hide scanning screen and emit result.
          this.active = false;
          this.scannerService.setScanResult(recognitionResults);
          this.scanFinishedEvent.emit(true);
          localStorage.setItem('videoVefificationSuccess', 'true');
        }
      );
    } catch (error) {
      console.error('Error during initialization of VideoRecognizer:', error);
      return;
    }
  }

  // Utility functions for drawing detected quadrilateral onto canvas.
  private drawQuad(quad: DisplayableQuad) {
    this.clearDrawCanvas();
    // Based on detection status, show appropriate color and message.
    this.setupColor(quad);
    this.setupMessage(quad);
    this.applyTransform(quad.transformMatrix);
    const drawContext = this.cameraFeedback.nativeElement.getContext('2d');
    drawContext.beginPath();
    drawContext.moveTo(quad.topLeft.x, quad.topLeft.y);
    drawContext.lineTo(quad.topRight.x, quad.topRight.y);
    drawContext.lineTo(quad.bottomRight.x, quad.bottomRight.y);
    drawContext.lineTo(quad.bottomLeft.x, quad.bottomLeft.y);
    drawContext.closePath();
    drawContext.stroke();
  }

  // This function will make sure that coordinate system associated with detectionResult canvas will match the coordinate system of the image being recognized.
  private applyTransform(transformMatrix: Float32Array) {
    const canvasAR = this.cameraFeedback.nativeElement.width / this.cameraFeedback.nativeElement.height;
    const videoAR = this.cameraFeed.nativeElement.videoWidth / this.cameraFeed.nativeElement.videoHeight;
    let xOffset = 0;
    let yOffset = 0;
    let scaledVideoHeight = 0;
    let scaledVideoWidth = 0;
    if (canvasAR > videoAR) {
      // pillarboxing: https://en.wikipedia.org/wiki/Pillarbox
      scaledVideoHeight = this.cameraFeedback.nativeElement.height;
      scaledVideoWidth = videoAR * scaledVideoHeight;
      xOffset = (this.cameraFeedback.nativeElement.width - scaledVideoWidth) / 2.0;
    } else {
      // letterboxing: https://en.wikipedia.org/wiki/Letterboxing_(filming)
      scaledVideoWidth = this.cameraFeedback.nativeElement.width;
      scaledVideoHeight = scaledVideoWidth / videoAR;
      yOffset = (this.cameraFeedback.nativeElement.height - scaledVideoHeight) / 2.0;
    }
    // first transform canvas for offset of video preview within the HTML video element (i.e. correct letterboxing or pillarboxing)
    this.cameraFeedback.nativeElement.getContext('2d').translate(xOffset, yOffset);
    // second, scale the canvas to fit the scaled video
    this.cameraFeedback.nativeElement
      .getContext('2d')
      .scale(
        scaledVideoWidth / this.cameraFeed.nativeElement.videoWidth,
        scaledVideoHeight / this.cameraFeed.nativeElement.videoHeight
      );
    // finally, apply transformation from image coordinate system to
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
    this.cameraFeedback.nativeElement
      .getContext('2d')
      .transform(
        transformMatrix[0],
        transformMatrix[3],
        transformMatrix[1],
        transformMatrix[4],
        transformMatrix[2],
        transformMatrix[5]
      );
  }

  private clearDrawCanvas() {
    this.cameraFeedback.nativeElement.width = this.cameraFeedback.nativeElement.clientWidth;
    this.cameraFeedback.nativeElement.height = this.cameraFeedback.nativeElement.clientHeight;
    this.cameraFeedback.nativeElement
      .getContext('2d')
      .clearRect(0, 0, this.cameraFeedback.nativeElement.width, this.cameraFeedback.nativeElement.height);
  }

  private setupColor(displayable: DisplayableQuad) {
    let color = '#FFFF00FF';
    if (displayable.detectionStatus === 0) {
      color = '#FF0000FF';
    } else if (displayable.detectionStatus === 1) {
      color = '#00FF00FF';
    }
    const drawContext = this.cameraFeedback.nativeElement.getContext('2d');
    drawContext.fillStyle = color;
    drawContext.strokeStyle = color;
    drawContext.lineWidth = 5;
  }

  private setupMessage(displayable: DisplayableQuad) {
    switch (displayable.detectionStatus) {
      case DetectionStatus.Failed:
        this.updateScanFeedback('Scanning...', false);
        break;
      case DetectionStatus.Success:
      case DetectionStatus.FallbackSuccess:
        this.updateScanFeedback(this.scanDetectionSuccessMsg, false);
        break;
      case DetectionStatus.CameraAngleTooSteep:
        this.updateScanFeedback(this.scanDetectionCameraAtAngleMsg, false);
        break;
      case DetectionStatus.CameraTooFar:
        this.updateScanFeedback(this.scanDetectionCameraTooHighMsg, false);
        break;
      case DetectionStatus.CameraTooClose:
      case DetectionStatus.DocumentTooCloseToCameraEdge:
      case DetectionStatus.DocumentPartiallyVisible:
        this.updateScanFeedback(this.scanDetectionCameraTooNearMsg, false);
        break;
      default:
        console.warn('Unhandled detection status!', displayable.detectionStatus);
    }
  }

  // The purpose of this function is to ensure that scan feedback message is visible for at least 1 second.
  private updateScanFeedback(message: string, force: boolean) {
    if (this.isCameraActivated) {
      this.stopScanProgressCounter();
    }

    if (this.scanFeedbackLock && !force) {
      return;
    }
    this.scanFeedbackLock = true;
    this.scanFeedback.nativeElement.innerText = message;
    window.setTimeout(() => (this.scanFeedbackLock = false), 1000);
  }

  // Inform player to flip document.
  private openBackSideConfirmationDialog() {
    this.shouldHideUntilConfirmed = true;
    this.documentSideMsg = 'SCAN_POINT_BACK_SIDE';

    const modal = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: 'static',
    });

    modal.componentInstance.data = {
      title: this.translateService.instant('COMMON_NOTIFICATIONS'),
      message: this.translateService.instant('SHOW_DOCUMENT_BACK_SIDE'),
      closeButtonDeactivated: true,
      buttons: [
        {
          type: 'primary',
          label: this.translateService.instant('CONTINUE'),
          on_click: false,
        },
      ],
    };

    modal.result.then(result => {
      this.shouldHideUntilConfirmed = false;
    });
  }

  public switchToUpload() {
    this.switchToUploadPageEvent.emit();
  }

  public stepSkipped() {
    this.stepSkippedEvent.emit();
  }

  private startCounter(): void {
    this.isCameraActivated = true;

    // Start the countdown.
    this.counterSubscription = interval(1000)
      .pipe(takeWhile(() => this.counter > 0))
      .subscribe(() => {
        this.counter--;

        // Hide the counter when it reaches 0.
        if (this.counter <= 0) {
          this.counterSubscription?.unsubscribe();
        }
      });
  }

  private stopScanProgressCounter(): void {
    this.isCameraActivated = false;
    this.counter = COUNTDOWN_VALUE;
    this.counterSubscription?.unsubscribe();
  }
}
