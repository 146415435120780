import { FocusKeyManager } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomSelectItemComponent } from './custom-select-item/custom-select-item.component';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from 'src/app/shared/enums/client-names.enum';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('attr.role')
  role = 'list';

  /* Observable that emmits a list of items that are available to be selected by user */
  @Input() itemsObservable: Observable<(string | { value: any; text: string })[]>;
  @Input() fcontrol: FormControl = new FormControl('');
  @Input() orientationTop = false;
  @Input() showCloseBtn = false;
  @Input() patchValueObservable: Observable<string> = null;
  @Input() showStyles: { valid: boolean; invalid: boolean; disabled: boolean } = {
    valid: false,
    invalid: true,
    disabled: false,
  };

  /* Event emmiter that emmits event when user selects an item or types string that matches on of the items */
  @Output() selectEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<void>();
  @Output() openEvent = new EventEmitter<void>();

  @ViewChild('input') inputField: ElementRef<HTMLInputElement>;

  /* Element that wraps our view; We need it to determine if user clicked outside of our component */
  @ViewChild('wrapper') wrapper: ElementRef<HTMLDivElement>;

  @ViewChildren(CustomSelectItemComponent) listItemsElements: CustomSelectItemComponent[];
  private listKeyManager: FocusKeyManager<CustomSelectItemComponent>;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(e) {
    this.listKeyManager.onKeydown(e);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    if (e.key === 'Tab') {
      if (this.isDropdownVisible) {
        this.closeDropdown();
      }
    }

    if (e.key === 'Enter') {
      const element = this.myDocument.activeElement as HTMLElement;
      if (element.dataset['role'] === 'list-item') {
        if (this.isDropdownVisible) {
          this.onSelect(element['itemValue'] as any);
        }
      }
    }
  }
  /* Is dropdown visible or not */
  isDropdownVisible = false;

  /* What is typed inside of the input */
  private keyWord = new BehaviorSubject<string>('');
  public keyWordObservable = this.keyWord.asObservable();

  /* Max number of items displayed by dropdown at the same time */
  private maxNumberOfVisibleItems = 6;
  public heightOfOneItem = 38;
  public scrollContainerHeightObservable: Observable<number>;

  private patchValue$: Subscription;

  private inputFocusUnsubscribe: () => void;
  private documentClickUnsubscribe: () => void;

  public clientName = environment.clientName;
  public CLIENT_NAMES = CLIENT_NAMES;

  // TODO Add changeDetector
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private myDocument: Document) {}

  ngOnInit(): void {
    if (this.patchValueObservable === null) {
      if (this.fcontrol.value) {
        this.keyWord.next(this.fcontrol.value);
      }
      this.patchValue$ = this.fcontrol.valueChanges.subscribe(data => {
        this.keyWord.next(data);
      });
    } else {
      this.patchValue$ = this.patchValueObservable.subscribe(data => {
        this.keyWord.next(data);
      });
    }
    this.itemsObservable = this.itemsObservable.pipe(
      map(values =>
        values.map(val => {
          if (typeof val === 'string') {
            return { value: val, text: val };
          } else {
            return val;
          }
        })
      )
    );

    this.scrollContainerHeightObservable = this.itemsObservable.pipe(
      map(items => {
        if (items.length > this.maxNumberOfVisibleItems) {
          return this.maxNumberOfVisibleItems * this.heightOfOneItem;
        } else {
          /**
           * We need to add 2px here to avoid having scroll when the number of filtered items is less than or equal to maxNumberOfVisibleItems;
           * Scroll shows because we have border of 1px
           */
          return items.length * this.heightOfOneItem + 2;
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.wireList();

    this.inputFocusUnsubscribe = this.renderer.listen(this.inputField.nativeElement, 'click', e => {
      this.openDropdown();
    });

    this.documentClickUnsubscribe = this.renderer.listen(this.myDocument, 'click', e => {
      if (!this.wrapper.nativeElement.contains(e.target)) {
        /**
         * ENTER is considered as click event on button.
         * When we click enter dropdown closes and we become stuck in that input.
         * Because its already focused and we cant open dropdown again without first clicking away
         */

        if (this.isDropdownVisible) {
          this.closeDropdown();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.patchValue$) {
      this.patchValue$.unsubscribe();
    }
    this.inputFocusUnsubscribe();
    this.documentClickUnsubscribe();
  }

  /* Method used to register list items in listKeyManager */
  private wireList() {
    this.listKeyManager = new FocusKeyManager(this.listItemsElements);
  }

  public onClearInput() {
    this.fcontrol.patchValue('');
    this.keyWord.next('');
    this.closeDropdown();
    this.selectEvent.emit('');
  }

  public onSelect(selectedValue: { value: any; text: string }) {
    this.fcontrol.patchValue(selectedValue.value);
    this.keyWord.next(selectedValue.text);
    this.closeDropdown();
    this.selectEvent.emit(selectedValue.value);
  }

  private openDropdown() {
    if (!this.isDropdownVisible) {
      this.isDropdownVisible = true;
      this.openEvent.emit();
    }
  }

  private closeDropdown() {
    this.fcontrol.markAsDirty();
    this.fcontrol.markAsTouched();
    if (this.isDropdownVisible) {
      this.isDropdownVisible = false;
      this.closeEvent.emit();
    }
  }
}
