<div class="infoContainer">
  <div class="tournamentTitle">{{ tournament?.tournamentTitle }}</div>
  <div
    [ngClass]="{ mobile: isMobile }"
    *ngIf="tournament?.tournamentPrizePool || tournament?.manualPrize"
    class="tournamentPrizeContainer"
  >
    <ng-container *ngIf="tournament?.tournamentPrizePool; else manualAwarding">
      <span>{{ tournament?.tournamentPrizePool | number: '1.2-2' | currency: tournament?.currency }}</span>
    </ng-container>
    <ng-template #manualAwarding>
      <span>{{ tournament?.manualPrize }}</span>
    </ng-template>
  </div>
  <div *ngIf="tournament?.firstPrize" class="firstPlaceContainer">
    <i [setSVGAttributes]="{ height: '16px' }" inlineSVG="/assets/icons/medal.svg"></i>
    <span>{{ 'TOURNAMENT_FIRST_PRIZE' | translate }}</span>
    <span class="amount"> {{ tournament?.firstPrize }}</span>
  </div>
  <div class="numberOfAwardsContainer">
    <i [setSVGAttributes]="{ height: '16px' }" inlineSVG="/assets/icons/cup.svg"></i>
    <span>{{ 'TOURNAMENT_WIN_PLACES' | translate }}</span>
    <span class="amount"> {{ tournament?.numberOfAwardPrizes }}</span>
  </div>

  <div class="timeContiner">
    {{ 'TOURNAMENT_STARTS_IN' | translate }}
    <span>{{ tournament?.tournamentStartsOn | date: 'dd. MMM, y HH:mm' }}</span>
  </div>
  <div class="timeContiner">
    {{ 'TOURNAMENT_ENDS_ON' | translate }}
    <span>{{ tournament?.tournamentEndsOn | date: 'dd. MMM, y HH:mm' }}</span>
  </div>

  <div class="tournamentCriteriaContainer">{{ 'TOURNAMENT_YOUR_POSITION_POINTS' | translate }}</div>

  <!-- TITLE FOR GAMES ON TOURNAMENT -->
  <ng-content></ng-content>

  <app-tournament-games [lobbyPath]="lobbyPath" [tournament]="tournament"></app-tournament-games>

  <div
    [innerHtml]="tournament?.tournamentDescription | sanitizeHtml"
    class="tournamentDescriptionContainer"
  ></div>
</div>
