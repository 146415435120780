import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransactionHistory } from '@models/transaction-history.model';
import { Subject, Subscription } from 'rxjs';
import { TransactionService } from '@services/transaction.service';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { PRODUCT_CODES } from '@enums/product-codes.enum';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-product-transactions',
  templateUrl: './product-transactions.component.html',
  styleUrls: ['./product-transactions.component.scss'],
})
export class ProductTransactionsComponent implements OnInit, OnDestroy {
  public config;
  public transactions: TransactionHistory[];
  public isLoading: boolean;
  public actionButtonUrl;
  public regex = /_/g;
  public environment = environment;
  public clientNames = CLIENT_NAMES;

  private queryParam$: Subscription;

  private limit = 10;
  private offset = 0;

  private filterParams;
  private transactionParams;

  public showMoreBtnVisible: boolean;
  public backOnTop: boolean;
  public resetPaginationEmitter = new Subject();
  private paginationRequest = false;

  constructor(
    private toaster: ToasterService,
    private translate: TranslateService,
    private transactionService: TransactionService,
    private appStateFacadeService: AppStateFacadeService
  ) {}

  ngOnInit(): void {
    this.setupPagination();
    this.setQueryParamSubscription();
  }

  ngOnDestroy() {
    this.queryParam$.unsubscribe();
  }

  // get filter data from url
  private setQueryParamSubscription(): void {
    this.queryParam$ = this.appStateFacadeService.observeActiveQueryParamsStatus().subscribe(queryParams => {
      if (queryParams.startDate) {
        this.filterParams = {
          productId: queryParams.productId,
          walletType: queryParams.walletType,
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
        };

        // when we change filter period we need to reset pagination and start from page one again
        if (queryParams.filterChange === 'true') {
          this.resetPagination();
        }

        this.setActionButtonUrl(queryParams.product);
        this.getTransations();
      }
    });
  }

  // set no data action button url
  private setActionButtonUrl(product): void {
    switch (product) {
      case PRODUCT_CODES.SPORT:
        this.actionButtonUrl = '/sport-prematch';
        break;
      case PRODUCT_CODES.CASINO:
        this.actionButtonUrl = '/casino';
        break;
      case PRODUCT_CODES.LIVE_CASINO:
        this.actionButtonUrl = '/casino-live';
        break;
      case PRODUCT_CODES.VIRTUAL_GAMES:
        this.actionButtonUrl = '/virtual';
        break;
      case PRODUCT_CODES.SLOT:
        this.actionButtonUrl = '/slot';
        break;
      case PRODUCT_CODES.LIVE_DEALER:
        this.actionButtonUrl = '/live-dealer';
        break;
      case PRODUCT_CODES.HR_LOTO:
        this.actionButtonUrl = '/lotto';
        break;
      case PRODUCT_CODES.POKER:
        this.actionButtonUrl = '/poker';
        break;
    }
  }

  // get transctions
  private getTransations(): void {
    this.isLoading = true;
    this.transactionParams = {
      limit: this.limit,
      offset: this.offset,
    };
    this.transactionService
      .getTransactionHistory({ ...this.filterParams, ...this.transactionParams })
      .subscribe({
        next: data => {
          this.isLoading = false;
          this.setTransactionsData(data);
        },
        error: error => {
          this.isLoading = false;
          this.toaster.showError(this.translate.instant('UNABLE_TO_GET_TRANSACTIONS'));
        },
      });
  }

  // set transactions data
  private setTransactionsData(data): void {
    !this.paginationRequest && (this.backOnTop = false);
    if (this.paginationRequest) {
      this.transactions = [...(this.transactions ? this.transactions : []), ...data.records];
      this.showMoreBtnVisible = data.total > this.transactions.length;
      this.backOnTop = data.total === this.transactions.length;
      this.paginationRequest = false;
    } else {
      this.transactions = data.records;
      this.showMoreBtnVisible = data.total > this.transactions.length;
    }
    this.config.totalItems = data.total;
  }

  // Setup conf for ngx-pagination
  private setupPagination(): void {
    this.config = {
      itemsPerPage: this.limit,
      currentPage: 1,
    };
  }

  // Handle change page
  public pageChanged(event): void {
    this.paginationRequest = true;
    this.config.currentPage = event;
    this.offset = event * this.limit;
    this.getTransations();
  }

  private resetPagination(): void {
    this.resetPaginationEmitter.next(true);
    this.config.currentPage = 1;
    this.offset = 0;
  }
}
