import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { authGuardCanActivate, authGuardCanMatch } from '@guards/auth.guard';
import { unAuthGuardCanActivate, unAuthGuardCanMatch } from '@guards/unauth.guard';
import { tokenValidGuardCanActivate } from '@guards/token-valid.guard';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { commonRoutingModules } from './app-routing-common.modules';
import { LOBBY_CODES } from '@enums/lobby-codes.enum';
import { LOBBY_PATHS } from '@enums/lobby-paths.enum';
import { WorldCupComponent } from './shared/components/world-cup/world-cup.component';
import { croRegistrationGuardCanActivateV2 } from '@guards/registration-steps.guard';
import { StaticPageComponent } from './shared/components/static-page/static-page.component';
import { EuroCupComponent } from './shared/components/euro-cup/euro-cup.component';
import { visitLandingGuardCanMatch } from '@guards/visit-landing.guard';

let children: Routes;
switch (environment.clientName) {
  case CLIENT_NAMES.ADMIRAL_SERBIA:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/serbia-registration/serbia-registration.module').then(
            m => m.SerbiaRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'slot',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'poker',
        loadChildren: () => import('@modules/poker/poker.module').then(m => m.PokerModule),
        canMatch: [authGuardCanMatch],
      },
      {
        path: 'live-dealer',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.LIVE_CASINO, lobbyPath: LOBBY_PATHS.LIVE_CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
      {
        path: 'mini-games',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.MINI_GAMES, lobbyPath: LOBBY_PATHS.MINI_GAMES },
      },
      {
        path: 'worldcup',
        component: WorldCupComponent,
      },
      {
        path: 'eurocup',
        component: EuroCupComponent,
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_PINNBET:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/pinnbet-registration/pinnbet-registration.module').then(
            m => m.PinnbetRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'slot',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'poker',
        loadChildren: () => import('@modules/poker/poker.module').then(m => m.PokerModule),
        canMatch: [authGuardCanMatch],
      },
      {
        path: 'live-dealer',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.LIVE_CASINO, lobbyPath: LOBBY_PATHS.LIVE_CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
      {
        path: 'mini-games',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.MINI_GAMES, lobbyPath: LOBBY_PATHS.MINI_GAMES },
      },
      {
        path: 'create-password',
        loadChildren: () =>
          import('./modules/create-password/create-password.module').then(m => m.CreatePasswordModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'worldcup',
        component: WorldCupComponent,
      },
      {
        path: 'eurocup',
        component: EuroCupComponent,
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_MONTENEGRO:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/montenegro-registration/montenegro-registration.module').then(
            m => m.MontenegroRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'casino',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
      {
        path: 'mini-games',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.MINI_GAMES, lobbyPath: LOBBY_PATHS.MINI_GAMES },
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_MD_SHOP:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/md-shop-registration/md-shop-registration.module').then(
            m => m.MdShopRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'forgot-security-question',
        loadChildren: () =>
          import('./modules/forgot-security-question/forgot-security-question.module').then(
            m => m.ForgotSecurityQuestionModule
          ),
        canMatch: [authGuardCanMatch],
        canActivate: [authGuardCanActivate],
      },
      {
        path: 'casino',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
      {
        path: 'mini-games',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.MINI_GAMES, lobbyPath: LOBBY_PATHS.MINI_GAMES },
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_UGANDA:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/uganda-registration/uganda-registration.module').then(
            m => m.UgandaRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'casino',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'casino-live',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.LIVE_CASINO, lobbyPath: LOBBY_PATHS.LIVE_CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
      {
        path: 'mini-games',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.MINI_GAMES, lobbyPath: LOBBY_PATHS.MINI_GAMES },
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_NIGERIA:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/nigeria-registration/nigeria-registration.module').then(
            m => m.NigeriaRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'casino',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_BOSNIA:
    children = [
      ...commonRoutingModules,
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./modules/registrations/bosnia-registration/bosnia-registration.module').then(
            m => m.BosniaRegistrationModule
          ),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'forgot-security-question',
        loadChildren: () =>
          import('./modules/forgot-security-question/forgot-security-question.module').then(
            m => m.ForgotSecurityQuestionModule
          ),
        canMatch: [authGuardCanMatch],
        canActivate: [authGuardCanActivate],
      },
      {
        path: 'casino',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'casino-live',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.LIVE_CASINO, lobbyPath: LOBBY_PATHS.LIVE_CASINO },
      },
      {
        path: 'virtual',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.VIRTUAL, lobbyPath: LOBBY_PATHS.VIRTUAL },
      },
      {
        path: 'mini-games',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.MINI_GAMES, lobbyPath: LOBBY_PATHS.MINI_GAMES },
      },
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_CROATIA:
    children = [
      ...commonRoutingModules,
      {
        path: 'casino',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.CASINO, lobbyPath: LOBBY_PATHS.CASINO },
      },
      {
        path: 'casino-live',
        loadChildren: () => import('./modules/lobby/lobby.module').then(m => m.LobbyModule),
        data: { lobbyCode: LOBBY_CODES.LIVE_CASINO, lobbyPath: LOBBY_PATHS.LIVE_CASINO },
      },
      {
        path: 'lotto',
        loadChildren: () => import('@modules/lotto/lotto.module').then(m => m.LottoModule),
      },
      {
        path: 'horses',
        loadChildren: () => import('@modules/lotto/lotto.module').then(m => m.LottoModule),
      },
      {
        path: 'info',
        loadChildren: () =>
          import('./modules/external-content/external-content.module').then(m => m.ExternalContentModule),
      },
      {
        path: 'create-password',
        loadChildren: () =>
          import('./modules/create-password/create-password.module').then(m => m.CreatePasswordModule),
        canMatch: [unAuthGuardCanMatch],
        canActivate: [unAuthGuardCanActivate],
      },
      {
        path: 'goldenrace',
        loadChildren: () => import('@modules/goldenrace/goldenrace.module').then(m => m.GoldenraceModule),
      },
    ];
    break;
  default:
    children = [];
    break;
}

const defaultRoute = environment.CMSElements
  ? { path: '**', component: StaticPageComponent }
  : { path: '**', redirectTo: environment.appDefaultRoute };

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [...children, defaultRoute],
    canActivate: [tokenValidGuardCanActivate],
  },
];

if (environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA) {
  // Place landing guard on top level route.
  routes[0].canMatch = [visitLandingGuardCanMatch];
  // Place the landing page as the first route.
  routes.unshift({
    path: 'home',
    loadChildren: () =>
      import('@modules/landing-serbia/landing-serbia.module').then(m => m.LandingSerbiaModule),
    // loadChildren: () => import('@modules/landing/landing.module').then(m => m.LandingModule),
  });
}

if (environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET) {
  routes[0].canMatch = [visitLandingGuardCanMatch];
  routes.unshift({
    path: 'home',
    loadChildren: () =>
      import('@modules/landing-pinnbet/landing-pinnbet.module').then(m => m.LandingPinnbetModule),
  });
}

if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
  routes.unshift(
    {
      path: 'registration',
      canActivate: [croRegistrationGuardCanActivateV2],
      loadChildren: () =>
        import('./modules/registrations/croatia-registration-v2/croatia-registration-v2.module').then(
          m => m.CroatiaRegistrationV2Module
        ),
    },
    {
      path: 'login',
      loadChildren: () =>
        import('./modules/croatia-login-v2/croatia-login-v2.module').then(m => m.CroatiaLoginV2Module),
      canMatch: [unAuthGuardCanMatch],
      canActivate: [unAuthGuardCanActivate],
    }
  );
}

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
