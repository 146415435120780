import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AppStateFacadeService } from '@state/app-state.facade';
import { LobbyService } from '@services/lobby.service';
import { catchError, filter, first, map } from 'rxjs/operators';
import { LobbyState } from '../providers/lobby.state';
import { LOBBY_STATE_STATUS } from '../models/enums/LobbyStateStatus.enum';
import { LOBBY_PATHS } from '@enums/lobby-paths.enum';
import { IActiveLobbyFilters } from '../models/ActiveLobbyFilters.intreface';
import { GameDataModel } from '@models/game-data.model';
import { DOCUMENT } from '@angular/common';
import { LobbyProviderModel } from '../models/LobbyProvider.model';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-lobby-game-page',
  templateUrl: './lobby-game-page.component.html',
  styleUrls: ['./lobby-game-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LobbyGamePageComponent implements OnInit, OnDestroy {
  @ViewChild('gameContainer') gameContainer: ElementRef<HTMLDivElement>;
  @ViewChild('placeholders', { static: true }) placeholders: ElementRef<HTMLDivElement>;
  @ViewChild('gamesWrapper', { static: true }) gamesWrapper: ElementRef<HTMLDivElement>;
  @Input() getGamesFromParams = true;
  @Input() games: any;
  @Input() usedForSearch = false;
  public placehoderImageCountArray: any[] = [];
  public lastLoad = false;
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  public loadingNewGamesInProgress: boolean;
  public lobbyPath = this.route.parent.snapshot.data.lobbyPath;
  public isMobile = this.appFacade.getIsMobileStatus();
  public currentlyActiveSectionName = '';
  public showSectionTitleObservable: Observable<boolean>;
  public gamesObservable: Observable<any[]>;
  public gameLengthObservable: Observable<number>;
  public gamesAreLoading = false;
  public params = {
    lobby: '',
    offset: 0,
    // It's actually faster to load more games at once
    limit: 0,
    sections: '',
    providerName: null,
  };
  public CLIENT_NAMES = CLIENT_NAMES;

  private loadingGamesIObserver: IntersectionObserver;
  private gamesSubject: BehaviorSubject<any[]>;
  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private lobbyService: LobbyService,
    private appFacade: AppStateFacadeService,
    private lobbyState: LobbyState,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.gamesSubject = new BehaviorSubject([]);
    this.gamesObservable = this.gamesSubject.asObservable();
    this.gameLengthObservable = this.gamesObservable.pipe(
      map(games => games.length),
      catchError(() => of(0))
    );

    this.showSectionTitleObservable = this.appFacade
      .getCurrentSearchValueObservable()
      .pipe(map(searchValue => searchValue.length !== 0));
  }

  private setupIObserver() {
    this.loadingGamesIObserver = new IntersectionObserver(
      (data: IntersectionObserverEntry[]) => {
        if (this.lastLoad) {
          this.loadingGamesIObserver.disconnect();
        }
        if (data[0].intersectionRatio > 0) {
          this.updateParams();
          this.getGames();
        }
      },
      { root: this.document, threshold: 0, rootMargin: '400px' }
    );
    this.loadingGamesIObserver.observe(this.placeholders.nativeElement);
  }

  ngOnInit(): void {
    if (!this.route.parent.snapshot.data.lobbyPath) {
      this.lobbyPath = LOBBY_PATHS[this.games[0].lobbies[0]];
    }

    if (this.environment.clientName === CLIENT_NAMES.ADMIRAL_MONTENEGRO) {
      this.lobbyService.getActivePlayersNumberInterval();
    }

    if (this.getGamesFromParams && !this.usedForSearch) {
      this.lobbyState
        .getLobbyStateStatusObservable()
        .pipe(
          filter(
            (lobbyStateStatus: LOBBY_STATE_STATUS) => lobbyStateStatus >= LOBBY_STATE_STATUS.SECTIONS_SET
          ),
          first()
        )
        .subscribe(() => {
          this.setInitialNumberOfGames();
          this.setParamSubscription();
          this.setReloadSubscription();
        });
    } else if (!this.getGamesFromParams && !this.usedForSearch) {
      // In case we are on promotions page
      this.lastLoad = true;
      this.gamesSubject.next(this.games);
    } else {
      this.lastLoad = true;
      // If we need to get games from search we want to override our observable
      this.gamesObservable = this.appFacade.getSearchedGamesObservable();
      this.gameLengthObservable = this.gamesObservable.pipe(
        map(games => games.length),
        catchError(() => of(0))
      );
    }
  }

  private setInitialNumberOfGames() {
    const numberOfRowsVisible = 6;
    let gamesPerRow = 3;
    let coef = 1;
    const viewportWidth = window.innerWidth;
    switch (true) {
      case viewportWidth > 2000:
        gamesPerRow = 10;
        break;
      case viewportWidth > 1600:
        gamesPerRow = 9;
        break;
      case viewportWidth > 1400:
        gamesPerRow = 8;
        coef = 1.5;
        break;
      case viewportWidth > 1200:
        gamesPerRow = 7;
        coef = 1.5;
        break;
      case viewportWidth > 992:
        gamesPerRow = 6;
        coef = 2;
        break;
      case viewportWidth > 768:
        gamesPerRow = 5;
        coef = 2;
        break;
      case viewportWidth > 576:
        gamesPerRow = 4;
        coef = 3;
        break;
      default:
        gamesPerRow = 3;
        coef = 3;
        break;
    }
    // set limit of an api call for games based on visible are for games on current device
    this.placehoderImageCountArray = new Array(numberOfRowsVisible * gamesPerRow);
    this.params.limit = numberOfRowsVisible * gamesPerRow * coef;
  }

  setReloadSubscription() {
    this.subscriptions.add(
      this.lobbyService.reloadGamesInGamePageObservable.subscribe(reloadGameInfo => {
        if (reloadGameInfo.specialGamePage !== null) {
          return;
        }

        const activeLobbyCode = this.lobbyState.getActiveLobbyCode();
        const currentlyActiveFilters = this.lobbyState.getActiveFilters(activeLobbyCode);

        let reloadGames = false;

        reloadGameInfo.sectionCode = !reloadGameInfo.sectionCode ? '' : reloadGameInfo.sectionCode;
        reloadGameInfo.providerName = !reloadGameInfo.providerName ? '' : reloadGameInfo.providerName;

        if (currentlyActiveFilters.section) {
          if (
            reloadGameInfo.sectionCode.toLowerCase() === currentlyActiveFilters.section.code.toLowerCase()
          ) {
            reloadGames = true;
          }
        }

        if (currentlyActiveFilters.provider) {
          if (
            reloadGameInfo.providerName.toLowerCase() === currentlyActiveFilters.provider.name.toLowerCase()
          ) {
            reloadGames = true;
          }
        }

        if (reloadGames) {
          this.params.offset = 0;
          this.getGamesAndOverride();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    if (this.loadingGamesIObserver) {
      this.loadingGamesIObserver.disconnect();
    }

    this.lobbyService.clearActivePlayersNumberInterval();
  }

  // set subscription to params
  private setParamSubscription() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const sectionCode = params.sections;
        const providerName = params.providerName;

        const activeLobbyCode = this.lobbyState.getActiveLobbyCode();

        // Construct lobby filters so we can get section id
        const nextActiveLobbyFilters: IActiveLobbyFilters = this.lobbyState.generateActiveFilters();

        nextActiveLobbyFilters.section = this.lobbyState.getSectionBySectionCode(
          activeLobbyCode,
          sectionCode
        );

        // Reseting games, offset, flags

        this.lastLoad = false;
        this.gamesAreLoading = false;
        this.gamesSubject.next([]);
        this.params.offset = 0;
        if (this.loadingGamesIObserver) {
          this.loadingGamesIObserver.disconnect();
          this.loadingGamesIObserver = null;
        }

        if (providerName) {
          // Check if entry in map already exist

          // If it does we can just query our array of providers and find
          // one with the matching name.

          // If not we need to get providers from an API and only then
          // query array for provider with matching name.
          // Also set entry in map so next time we do not need to make an API call

          this.getProvidersAndExecute(nextActiveLobbyFilters, providers => {
            const selectedProvider = providers.find(
              singleProvider => singleProvider.name.toLowerCase() === providerName.toLowerCase()
            );

            this.lobbyState.setProvidersInSection(nextActiveLobbyFilters.section.id, providers);

            if (selectedProvider) {
              nextActiveLobbyFilters.provider = selectedProvider;
            } else {
              nextActiveLobbyFilters.provider = null;
            }

            this.lobbyState.setActiveFilters(activeLobbyCode, nextActiveLobbyFilters);
            this.lobbyState.setLobbyStateStatus(LOBBY_STATE_STATUS.ACTIVE_SECTION_SET);
            this.updateParams();
            this.getGames();
          });
        } else {
          // If providerName is not present just set filters that we constructed above
          this.lobbyState.setActiveFilters(activeLobbyCode, nextActiveLobbyFilters);
          this.lobbyState.setLobbyStateStatus(LOBBY_STATE_STATUS.ACTIVE_SECTION_SET);
          this.updateParams();
          this.getGames();
        }
      })
    );
  }

  private getProvidersAndExecute(
    activeLobbyFilters: IActiveLobbyFilters,
    callback: (providers: LobbyProviderModel[]) => void
  ) {
    if (this.lobbyState.providersInSectionExist(activeLobbyFilters.section.id)) {
      const providers = this.lobbyState.getProvidersInSection(activeLobbyFilters.section.id);
      callback(providers);
    } else {
      this.lobbyState.getProvidersInSectionFromAPI(activeLobbyFilters.section.id).subscribe(providers => {
        callback(providers);
      });
    }
  }

  private updateParams() {
    const activeLobbyCode = this.lobbyState.getActiveLobbyCode();
    const currentlyActiveLobbyFilters = this.lobbyState.getActiveFilters(activeLobbyCode);
    this.currentlyActiveSectionName = currentlyActiveLobbyFilters.section.name;

    this.params.sections =
      (currentlyActiveLobbyFilters &&
        currentlyActiveLobbyFilters.section &&
        currentlyActiveLobbyFilters.section.code) ||
      null;

    if (currentlyActiveLobbyFilters.provider !== null) {
      this.params.providerName = currentlyActiveLobbyFilters.provider.name;
    } else {
      this.params.providerName = null;
    }

    this.params.lobby = activeLobbyCode;
  }
  private getGames() {
    // Dont call for api in case if previous load was the last load and if the previous api call is still in progress

    this.gamesAreLoading = true;
    if (!this.lastLoad) {
      this.lobbyService
        .getLobbyGames(this.params)
        .pipe(
          catchError(() => of([])),
          map((data: any[]) => data.map(item => new GameDataModel().deserialize(item)))
        )
        .subscribe(games => {
          this.params.offset += this.params.limit;
          if (games.length === 0 || games.length < this.params.limit) {
            this.lastLoad = true;
          }

          // When user scrolls to fast rect.bottom becomes negative
          // and we get stuck on placeholder section.
          // Because of that we need to scroll user back up to the games container.

          if (this.gamesWrapper.nativeElement.getBoundingClientRect().bottom < 0) {
            if (this.lastLoad) {
              window.scrollTo({ top: this.gamesWrapper.nativeElement.getBoundingClientRect().height });
            } else {
              window.scrollTo({
                top: this.gamesWrapper.nativeElement.getBoundingClientRect().height - window.innerHeight,
              });
            }
          }

          this.gamesAreLoading = false;
          this.gamesSubject.next(this.gamesSubject.value.concat(games));

          if (!this.loadingGamesIObserver) {
            this.setupIObserver();
          } else {
            this.loadingGamesIObserver.unobserve(this.placeholders.nativeElement);
            this.loadingGamesIObserver.observe(this.placeholders.nativeElement);
          }
        });
    }
  }

  /**
   * Gets the games with params that are globaly configured and overrides the existsing games.
   * Used when we want to refresh the games in favorite section
   */

  private getGamesAndOverride() {
    this.lobbyService
      .getLobbyGames(this.params)
      .pipe(
        catchError(() => of([])),
        map((data: any[]) => data.map(item => new GameDataModel().deserialize(item)))
      )
      .subscribe(games => {
        this.gamesSubject.next(games);

        if (!this.loadingGamesIObserver) {
          this.setupIObserver();
        } else {
          this.loadingGamesIObserver.unobserve(this.placeholders.nativeElement);
          this.loadingGamesIObserver.observe(this.placeholders.nativeElement);
        }
      });
  }
}
