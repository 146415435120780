export const PINNBET_THEME_COLORS = [
  { backgroundColor: '#fff' },
  { sideMenuColor: '#181A1D' },
  { admiralButtonColor: '#fff' },
  { sideMenuBackgroundColor: '#fff' },
  { primaryColor: '#02A752' },
  { secondaryColor: '#fff' },
  { secondaryMenuColor: '#002157' },
  { successColor: '#65d72d' },
  { tertiaryColor: '#02A752' },
  { cardColor: '#fff' },
  { cardColorActive: '#BFE9D3' },
  { filterColor: '#fff' },
  { borderColor: '#313131' },
  { headerActiveElementColor: '#002b71' },
  { boxRowColor: '#F3F3F3' },
  { boxRowDesktopColor: '#fff' },
  { boxRowBorder: '0' },
  { inputColor: '#F3F3F3' },
  { boxInputBackgroundColor: '#fff' },
  { inputBorderColor: 'none' },
  { inputFontColor: '#2E2E2E' },
  { depositCardColor: '#F3F3F3' },
  { desktopCardColor: '#F3F3F3' },
  { lobbyGameSearchColor: '#fff' },
  { btnDepositClass: 'btn-outline-success' },
  { gameInfoBoxColor: 'rgba(33, 35, 37, 0.96)' },
  { activeWithdrawalBckgColor: 'rgb(150, 54, 54)' },
  { helpPageBackgroundColor: '#F3F3F3' },
  { btnDisabledColor: 'gray' },
  { btnPrimaryHoverColor: '#02A752' },
  { btnPrimaryBorderHoverColor: '#02A752' },
  { btnPrimaryBorderRadius: '0.5rem' },
  { btnAdmiralBorderRadius: '0.5rem' },
  { btnPrimaryShadowColor: 'rgba(222, 178, 10, 0.5)' },
  { lightGrayColor: '#6c757d' },
  { backgroundImage: 'slot-theme-background-image-light-green.svg' },
  { tournamentGradient: 'linear-gradient(116.84deg, #00378c 0.88%, #0065ff 100%)' },
  { leaderboardDialogGradient: 'linear-gradient(to bottom, #003180, #004FCF)' },
  { fontFamily: 'Montserrat, sans-serif' },
  { fontColor: '#2E2E2E' },
  { primaryButtonTextColor: '#ffffff' },
  { buttonOutlineColor: '#212529;' },
  { subgroupButtonColor: '#F3F3F3' },
  { labelTextTransform: 'none' },
  { labelFontSize: '12px' },
  { btnDarkColor: '#02A752' },
  { linkNoHrefColor: '#fff' },
  // toaster colors
  { toasterSuccessColor: '#02A752' },
  { toasterSuccessBackgroundColor: '#BFE9D3' },
  { toasterSuccessBorderColor: '#transparent' },
  { toasterSuccessAlertLinkColor: '#02A752' },
  { toasterDangerColor: '#EE2830' },
  { toasterDangerBackgroundColor: '#E9BFBF' },
  { toasterDangerBorderColor: 'transparent' },
  { toasterDangerAlertLinkColor: '#EE2830' },
  { titleFontSize: '24px' },
  { titleFontSizeMobile: '12px' },
  { customSelectHoverColor: '#e7e7e7' },
  { darkBtnHoverBackground: '#ccc' },
  // Date picker colors
  { datePickerBorderColor: '#34373b' },
  { datePickerActiveElementBackground: 'gray' },
  { datePickerSelectContainerBackground: '#fff' },
  { datePickerSelectContainerColor: '#2e2e2e' },
  { datePickerDayButtonBackground: '#ccc' },
  { datePickerDayButtonColor: '#2e2e2e' },
];
