<div [ngClass]="{ 'pinnbet-no-data-container': clientName === clientNames.ADMIRAL_PINNBET }">
  <div class="tab-content">
    <!-- This will be shown if we do not provide component with actionButtonText || description -->
    <h5 *ngIf="!description">{{ 'TRANSACTION_NO_ACTIVE_TRANSACTIONS' | translate }}</h5>
    <a
      appAdmiralButton
      [text]="'PLAY_NO_DATA_BUTTON'"
      [marginCostume]="'auto'"
      *ngIf="!actionButtonText && actionButtonUrl && !actionButtonQueryParam"
      [routerLink]="actionButtonUrl"
      class="btn btn-primary"
    ></a>

    <!-- This will be shown if we provide our component with actionButtonText || description -->
    <h5 *ngIf="description">{{ description | translate }}</h5>
    <a
      appAdmiralButton
      [text]="actionButtonText"
      [marginCostume]="'auto'"
      *ngIf="actionButtonText && actionButtonUrl && !actionButtonQueryParam"
      [routerLink]="actionButtonUrl"
      class="btn btn-primary"
    ></a>

    <!-- This will be shown only when we need to send actionButtonQueryParam for button-->
    <a
      appAdmiralButton
      [text]="actionButtonText"
      [marginCostume]="'auto'"
      *ngIf="actionButtonText && actionButtonUrl && actionButtonQueryParam"
      [routerLink]="actionButtonUrl"
      [queryParams]="actionButtonQueryParam"
      class="btn btn-primary"
    ></a>
  </div>
</div>
