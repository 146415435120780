import { inject } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { LocalStorageService } from '@services/local-storage.service';
import { Observable } from 'rxjs';
import { AppStateFacadeService } from '@state/app-state.facade';
import { CLIENT_NAMES } from '@enums/client-names.enum';

export const authGuardCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => true;

export const authGuardCanMatch: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);
  const appStateFacadeService: AppStateFacadeService = inject(AppStateFacadeService);
  const token: string = inject(LocalStorageService).getToken();
  const clientName: string = appStateFacadeService.getClientName();
  const query: URLSearchParams = new URLSearchParams(window.location.search);

  if (token || (clientName === CLIENT_NAMES.ADMIRAL_SERBIA && query.get('dt'))) {
    return true;
  } else {
    // Authorized route will be added to previous routes and player will be redirected to it, after log in.
    appStateFacadeService.addPreviousRoute(segments.join('/'));
    router.navigate(['login']);
    return false;
  }
};
