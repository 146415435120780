export class Wheel {
  id: number;
  name: string;
  nextSpinAvailableFrom: number | null;
  isSpinAvailable: boolean;
  playerInWheelSegment: boolean;
  prizes: Prize[];
  colors = {};

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.nextSpinAvailableFrom = data.nextSpinAvailableFrom;
    const now = new Date().getTime() + 1000; // add 1 second to avoid any issues with the time when spin is available imeadiately
    this.isSpinAvailable = this.nextSpinAvailableFrom ? this.nextSpinAvailableFrom <= now : false;
    this.playerInWheelSegment = data.playerInWheelSegment;
    this.prizes = data.prizes.map((prize, i) => new Prize(prize, data.prizes.length, i));
    this.prizes.reverse();
    this.colors = data.colors.standColor
      ? data.colors
      : {
          // if there is for some reason no color data in the response, use default colors
          standColor: '#C21F24',
          standLightShadow: '#DC6B56',
          standDarkShadow: '#4A0001',
          frameColor: '#C21F24',
          needleColor: '#FECA03',
          needleDarkShadow: '#BB9400',
          spinButtonColor: '#005aba',
          spinButtonLightShadow: '#4f95f7',
          spinButtonDarkShadow: '#003084',
        };
  }
}

export class Prize {
  id: number;
  prizeType: string;
  prizeDescription: string;
  icon: string;
  color: string;
  displayText: string;
  prizeValue: number;
  startAngle: number;
  endAngle: number;
  currentStartAngle?: number;
  currentEndAngle?: number;

  constructor(data: any, noOfPrizes: number, index: number) {
    this.id = data.id;
    this.prizeType = data.prizeType;
    this.prizeDescription = data.prizeDescription;
    this.icon = data.icon;
    this.color = data.color;
    this.displayText = data.displayText;
    this.prizeValue = data.prizeValue;
    this.startAngle = this.getAngle(noOfPrizes, index);
    this.endAngle = this.getAngle(noOfPrizes, index, this.startAngle);
    this.currentStartAngle = this.startAngle;
    this.currentEndAngle = this.endAngle;
  }

  public getAngle(prizes, index, startAngle?): number {
    const angle = 360 / prizes;
    return startAngle >= 0 ? startAngle + angle : angle * index;
  }
}
