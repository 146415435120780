<div [ngClass]="{ mobile: isMobile, profileLocation: isProfileLocation }" class="upload-file-container">
  <div [ngClass]="{ profileLocation: isProfileLocation }" class="title">
    {{ 'CRO_V2_PERSONAL_ID_LOAD' | translate }}
  </div>
  <div *ngIf="isScanInProgress" class="scan-progress-counter my-5">
    {{ 'IMAGES_IN_PROCESS' | translate }} {{ scanInProgressCounter }}
  </div>
  <div
    class="upload-buttons-wrapper"
    [ngClass]="{ hidden: !isUploadMenuActive, profileLocation: isProfileLocation }"
  >
    <fieldset [ngClass]="{ valid: uploadedFiles?.front, profileLocation: isProfileLocation }">
      <label for="image-file-front-side">{{ 'CRO_V2_UPLOAD_FRONT_SIDE' | translate }}</label>
      <input
        (change)="inputChanged($event)"
        data-type="front"
        id="image-file-front-side"
        type="file"
        accept="image/*"
        #imageFileFrontSide
      />
      <div *ngIf="!uploadedFiles?.front" class="upload-button-container">
        <button class="cro-button registration small">
          <div inlineSVG="/assets/icons/download.svg"></div>
          {{ 'CRO_V2_LOAD' | translate }}
        </button>
      </div>
      <div *ngIf="uploadedFiles?.front" class="checked-container">
        <div inlineSVG="/assets/icons/checkmark.svg"></div>
        <div>{{ 'CRO_V2_LOADED' | translate }}</div>
      </div>
    </fieldset>
    <fieldset [ngClass]="{ valid: uploadedFiles?.back, profileLocation: isProfileLocation }">
      <label for="image-file-back-side">{{ 'CRO_V2_UPLOAD_BACK_SIDE' | translate }}</label>
      <input
        (change)="inputChanged($event)"
        data-type="back"
        id="image-file-back-side"
        type="file"
        accept="image/*"
        #imageFileBackSide
      />
      <div *ngIf="!uploadedFiles?.back" class="upload-button-container">
        <button class="cro-button registration small">
          <div inlineSVG="/assets/icons/download.svg"></div>
          {{ 'CRO_V2_LOAD' | translate }}
        </button>
      </div>
      <div *ngIf="uploadedFiles?.back" class="checked-container">
        <div inlineSVG="/assets/icons/checkmark.svg"></div>
        <div>{{ 'CRO_V2_LOADED' | translate }}</div>
      </div>
    </fieldset>
  </div>

  <div id="img-scanning" class="img-scanning" [ngClass]="{ hidden: isUploadMenuActive }">
    <img #targetImage />
  </div>
  <div *ngIf="!isProfileLocation" [ngClass]="{ profileLocation: isProfileLocation }" class="rewardContainer">
    <app-reward-information-bar
      [rewardInformation]="'CRO_V2_IDENTITY_CARD_CHECK_REWARD_INFORMATION'"
    ></app-reward-information-bar>
  </div>
  <div [ngClass]="{ profileLocation: isProfileLocation }" class="proceed-button-container">
    <button
      [disabled]="(!uploadedFiles?.back && !uploadedFiles?.back) || isScanInProgress"
      (click)="handleUploadDocument()"
      class="cro-button"
    >
      {{ 'CONTINUE' | translate }}
    </button>
    <div *ngIf="!isProfileLocation && !isScanInProgress" (click)="stepSkipped()" class="link-no-scan">
      {{ 'CRO_V2_UPLOAD_LATER' | translate }}
    </div>
  </div>
</div>
