<div
  *ngIf="showAlert"
  id="alert"
  [ngClass]="alertClass"
  class="alert alert-dismissible alert-position"
  role="alert"
>
  <button type="button" (click)="close()" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span></button
  >{{ message }}
</div>
