<div *ngIf="filterOpen" id="filterContainer">
  <div class="searchInputWrapper">
    <label for="transactionPeriod" class="label">{{ 'TRANSACTION_FILTER_BY_PERIOD' | translate }}</label>
    <app-custom-select
      [itemsObservable]="searchPeriodsTranslatedObservable"
      (selectEvent)="onSelect($event)"
      [patchValueObservable]="searchPeriodsPatchValueObservable"
    ></app-custom-select>
  </div>
</div>
