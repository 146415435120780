export const ANIMATION_DURATION = 5000;

// There are 3 speeds when it comes to rotating digits
// 1. 'Slow' only the amount that is needed (eg. from 1 to 5, 1 -> 2 -> 3 -> 4 -> 5).
// 2. 'Fast' same as slow but adds couple of empty loops between start and end value.
// 3. 'Veryfast' || 'Blazingly fast' adds even more empty loops (limit is browsers rendering speed).
export type speed_t = 'slow' | 'fast' | 'veryfast';
export type direction_t = 'minus' | 'plus';

export interface IOdometerRequest {
  steps: number;
  speed: speed_t;
}

export interface IOdometerSpeedStarts {
  slow: number;
  fast: number;
  veryfast: number;
}

export interface IDisplayOdometer {
  isOdometer: true;
  odometerIndex: number;
  odometerStartingValue: number;
  odometerRequest: IOdometerRequest;
  speedStarts: IOdometerSpeedStarts;
  slowDiff: number;
  direction: direction_t;
  shouldBeDeleted: boolean;
}

export interface IDisplayText {
  isOdometer: false;
  textValue: string;
  shouldBeDeleted: boolean;
}

export interface IDigitByDigit {
  nextValue: string;
  currentValue: string;
  shouldBeDeleted?: boolean;
}
