import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '@services/helpers.service';
import { WithdrawalService } from '@services/withdrawal.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iban-helper-dialog',
  templateUrl: './iban-helper.dialog.html',
  styleUrls: ['./iban-helper.dialog.scss'],
})
export class IbanHelperDialog implements OnInit {
  public ibanHelpeForm: FormGroup;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public isLoading: boolean;
  public calculatedIbanNumber;
  public calculatedIbanNumberHasError = true;
  public calculatedIbanNumberError = null;
  public banks: BehaviorSubject<{ name: string; identifier: string }[]> = new BehaviorSubject([]);
  public banksObservable: Observable<{ value: string; text: string }[]>;

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private withdrawalService: WithdrawalService,
    private helpersService: HelpersService
  ) {
    this.buildSecurityQuestionForm();
  }

  ngOnInit(): void {
    this.banksObservable = this.banks
      .asObservable()
      .pipe(map(items => items.map(item => ({ value: item.identifier, text: item.name }))));
    this.getBanks();
  }

  private getBanks() {
    this.withdrawalService.getBanks().subscribe(allBanks => {
      this.banks.next(allBanks['records']);
      this.ibanHelpeForm.patchValue({ bank: allBanks['records'][0].identifier });
    });
  }

  private buildSecurityQuestionForm(): void {
    this.ibanHelpeForm = this.fb.group({
      bank: ['', Validators.required],
      bankAccountNumber: ['', Validators.required],
    });
  }

  public onBankSelect(bankIdentifier: any) {
    this.ibanHelpeForm.patchValue({ bank: bankIdentifier.value });
    this.calculateIban();
  }

  public formSubmit() {
    this.closeDialog(this.calculatedIbanNumber);
  }

  public calculateIban(): void {
    this.calculatedIbanNumber = null;
    this.calculatedIbanNumberHasError = true;
    this.calculatedIbanNumberError = null;

    if (this.ibanHelpeForm.invalid) {
      return;
    }
    this.bankAccountNumber.markAsTouched();

    this.withdrawalService.calculateIBanNumber(this.bankAccountNumber.value, this.bank.value).subscribe({
      next: data => {
        if (data['result']) {
          this.calculatedIbanNumberHasError = false;
          this.calculatedIbanNumber = data['result'];
        }
      },
      error: error => {
        this.calculatedIbanNumberHasError = true;
        this.calculatedIbanNumberError = error.message;
      },
    });
  }

  public onlyNumbersAllow(input) {
    this.ibanHelpeForm
      .get(input.target.getAttribute('formControlName'))
      .setValue(this.helpersService.allowOnlyNumbers(input.target.value));
  }

  public closeDialog(ibanNumber?) {
    this.activeModal.close(ibanNumber);
  }

  public get bankAccountNumber(): AbstractControl {
    return this.ibanHelpeForm.get('bankAccountNumber');
  }

  public get bank(): AbstractControl {
    return this.ibanHelpeForm.get('bank');
  }
}
