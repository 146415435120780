import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PreviousRouteInterface } from './interfaces/PreviousRoute.interface';

@Injectable({ providedIn: 'root' })
export class PreviousRouteService implements PreviousRouteInterface {
  constructor(public _appState, public router) {
    this.setCurrentUrl(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._appState.setPreviousUrl(this.getCurrentUrl());
        this.addPreviousRoute(this.getCurrentUrl());
        this.setCurrentUrl(event.url);
      }
    });
  }

  setCurrentUrl(currentUrl: string): void {
    this._appState.setCurrentUrl(currentUrl);
  }

  getCurrentUrl(): string {
    return this._appState.getCurrentUrl();
  }

  setPreviousUrl(previousUrl: string): void {
    this._appState.setPreviousUrl(previousUrl);
  }

  getPreviousUrl(): string {
    const previousUrl = this._appState.getPreviousUrl();
    const currentUrl = this.getCurrentUrl();

    if (!previousUrl) {
      return environment.appDefaultRoute;
    } else {
      return currentUrl === previousUrl ? environment.appDefaultRoute : previousUrl;
    }
  }

  getAfterLoginRoute(): string {
    const lastRoutesReversed = [...this.getLastRoutes()];
    lastRoutesReversed.reverse();
    const foundedRoute = lastRoutesReversed.find(
      route =>
        route !== '/login' &&
        route !== '/login-redirect' &&
        route !== '/registration' &&
        route !== '/forgot-password' &&
        route !== '/'
    );
    return foundedRoute ? foundedRoute : environment.appDefaultRoute;
  }

  addPreviousRoute(previousRoute: string): void {
    const lastRoutes = this.getLastRoutes();
    this.setLastRoutes([...lastRoutes, previousRoute]);
  }

  setLastRoutes(lastRoutes: string[]) {
    this._appState.setLastRoutes(lastRoutes);
  }

  getLastRoutes(): string[] {
    return this._appState.getLastRoutes();
  }
}
