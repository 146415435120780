import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';
@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
  @Input() description: string;
  @Input() actionButtonText: string;
  @Input() actionButtonUrl: string;
  @Input() actionButtonQueryParam;

  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  constructor() {}
}
