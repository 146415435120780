import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reward-information-bar',
  templateUrl: './reward-information-bar.component.html',
  styleUrls: ['./reward-information-bar.component.scss'],
})
export class RewardInformationBarComponent {
  @Input() rewardInformation: string;
}
