import { AfterViewInit, Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Player } from '@models/player.model';
import { DepositService } from '@services/deposit.service';
import { ToasterService } from '@services/toaster.service';
import { FormControl } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

declare var CorvusPay: any;

const test_keys = {
  ADMIRAL_SERBIA: 'PK_test_GFYAT3wjDii1cfKGUDjXdpLfcsnq2XU0zw',
  ADMIRAL_PINNBET: 'PK_test_DMSlwJMQPY5NFciV9JmuqS0uum01krUs8Q',
};

const production_keys = {
  ADMIRAL_PINNBET: 'PK_prod_bo2nOQrcniImvAsuVSKSoQ7k2U54USzVaA',
  ADMIRAL_SERBIA: 'PK_prod_oSl-Hf1tHqtAZLgUxcI0h_j3kP8ZVMFrCA',
};

const CORVUSPAY_STORE_PUBLIC_KEY = environment.production
  ? production_keys[environment.clientName]
  : test_keys[environment.clientName];
const CORVUS_PAYMENT_URL = `https://js.${environment.production ? '' : 'test.'}corvuspay.com/cjs.bundle.js`;
interface PaymentInfo {
  amount?: number;
  saveCard?: boolean;
  promoCode: string;
  newAmount?: number;
  tokenValue?: string;
}
@Component({
  selector: 'app-quick-bar-corvus-frame-deposit',
  templateUrl: './quick-bar-corvus-frame-deposit.component.html',
  styleUrls: ['./quick-bar-corvus-frame-deposit.component.scss'],
})
export class QuickBarCorvusFrameDepositComponent implements OnInit, AfterViewInit {
  @ViewChild('choseSavedCardsModal', { static: true }) choseSavedCardsModal: any;
  @ViewChild('modalTemplateDeleteCard', { static: true }) modalTemplateDeleteCard: any;
  public submitBtnDisabled = true;
  public cardFormIsReady = false;
  private paymentInfo: PaymentInfo;
  public errorMsg: string;
  private card: any;
  public currency = 'EUR';

  public checkBoxState = new FormControl(false);
  public previousSavedCards: any[];
  public loadingState: boolean;
  public chosenCard: any;
  public modalRef: NgbModalRef;
  public scriptLoaded = new BehaviorSubject<boolean>(false);
  constructor(
    private depositService: DepositService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.loadCorvusScript();
  }
  @Input() playerData!: Player;
  @Input() clientName!: string;
  @Input() expandCollapseQuickBar!: (stateOveride?) => void;
  @Input() closeQuickBar!: () => void;
  @Input() stopIntervalChangeItemsInBar!: () => void;
  @Input() paymentSuccess!: () => void;

  @Input() isMobile = true;
  @Input() depositAmount!: number;
  @Input() paymentProceed!: boolean;

  private requiredParameters = {
    publicKey: CORVUSPAY_STORE_PUBLIC_KEY,
  };

  private optionalParameters = {};

  private option = {
    showCvv: true, // Set to true if you want to show the CVV field
    hideCorvusPayLogo: false, // Set to true if you want to hide the CorvusPay logo
  };

  private style = {
    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--white').trim(), // Background color of the form
    // Because implementation of form not support form text color, we need to set it to white
    // backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--white').trim(), // Background color of the form
    fontFamily: 'Montserrat, sans-serif', // Font family of the form
    fontSize: 13, // Font size of the form
  };

  ngOnInit() {
    this.getPreviousSavedCards();
  }

  ngAfterViewInit(): void {
    this.currency = this.playerData.currency.toUpperCase().replace('.', '');
  }

  private loadCorvusScript() {
    const script = this.renderer.createElement('script');
    script.src = CORVUS_PAYMENT_URL;
    script.onload = () => {
      this.scriptLoaded.next(true);
    };

    this.renderer.appendChild(document.body, script);
  }

  public switchSavedCardOrNewCard(option?: string) {
    if (option === 'newCard') {
      this.router.navigate(['/profile/deposit/corvus'], {
        queryParams: { creditCardStatus: 'registerCard', amount: this.depositAmount },
      });
      this.closeQuickBar();
    } else {
      this.submitBtnDisabled = false;
    }
  }

  private initCorvusPay() {
    if (this.card) {
      this.card.destroy();
    }
    const corvuspay = CorvusPay.init(this.requiredParameters, this.optionalParameters);

    this.card = corvuspay.card(this.option, this.style, 'corvuspay-card-element');

    this.card.on('ready', () => (this.cardFormIsReady = true));

    this.card.on('show-error', errorMsg => (this.errorMsg = `Validation error: ${errorMsg}`));

    // This event is fired when card data is entered successfully and is valid
    this.card.on('card-ready', () => {
      this.submitBtnDisabled = false;
      this.errorMsg = null;
    });
  }

  public sendPaymentDataSavedCard() {
    this.loadingState = true;
    this.submitBtnDisabled = true;
    this.paymentInfo = {
      newAmount: this.depositAmount,
      tokenValue: this.chosenCard.referenceUuid,
      promoCode: '',
    };
    this.depositService.corvusFormDepositSavedCard(this.paymentInfo).subscribe({
      next: response => {
        /**
         * In response, after BE does cross-origin payment and returns payment_id, so we can send finishCardPayment
         * connection with credit card information.
         */
        response['status'] = response.code === 0 ? 'ok' : 'error';
        this.doOnFinishCardPayment(response, true);
      },
      error: error => {
        this.loadingState = false;
        this.submitBtnDisabled = true;
      },
    });
  }

  private doOnFinishCardPayment(response, savedCardProcess) {
    if (response.status === 'ok') {
      this.paymentSuccess();
      this.expandCollapseQuickBar();
      // if we pay using corvus frame, we need to call backend to refresh balance
      // because BackEnd don't know that payment passes successfully
      if (savedCardProcess) {
        this.toasterService.showSuccess('Payment successful');
        this.loadingState = false;
        this.submitBtnDisabled = true;
      } else {
        this.depositService
          .callApiToRefreshUserDepositAmount({ ...response, ...{ saveCard: this.paymentInfo.saveCard } })
          .subscribe(() => {
            this.toasterService.showSuccess('Payment successful');
            this.loadingState = false;
            this.submitBtnDisabled = true;
          });
      }
    } else {
      this.toasterService.showError(response.messages);
      this.loadingState = false;
      this.submitBtnDisabled = true;
    }
  }

  public openModalToChoseCard() {
    this.modalRef = this.modalService.open(this.choseSavedCardsModal, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: true,
    });
  }

  public chooseCard(index: number) {
    this.chosenCard = this.previousSavedCards[index];
    this.modalRef.close();
  }

  public deleteCard(event, index: number) {
    event.stopPropagation();

    this.modalRef = this.modalService.open(this.modalTemplateDeleteCard, {
      centered: true,
      scrollable: true,
      backdrop: true,
    });
  }

  public deleteCardConfirm() {
    this.modalRef.close();
    this.depositService.deleteCorvusSavedCreditCard(this.chosenCard).subscribe(() => {
      this.toasterService.showSuccess('Card deleted');
      this.getPreviousSavedCards();
    });
  }

  private getPreviousSavedCards() {
    this.depositService.getCorvusSavedCreditCards().subscribe((cardsData: any) => {
      this.previousSavedCards = cardsData;
      if (this.previousSavedCards.length > 0) {
        this.chosenCard = this.previousSavedCards[0];
        this.submitBtnDisabled = false;
      } else {
        this.submitBtnDisabled = true;
      }
    });
  }
}
