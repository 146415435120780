import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ANIMATION_DURATION } from '../utils/odometer.utils';
/**
 * Odometer v2 text column
 *
 *
 * **** Take a look at odometer.utils.ts to see all the types and globals used ****
 *
 *
 * Used for displaying the characters that are not digits
 */
@Component({
  selector: 'app-odometer-v2-text-col',
  templateUrl: './odometer-v2-text-col.component.html',
  styleUrls: ['./odometer-v2-text-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OdometerV2TextColComponent implements AfterViewInit {
  @Input() text = '';
  @Input() shouldBeDeleted = false;

  @ViewChild('display') display: ElementRef<HTMLDivElement>;

  constructor(private renderer: Renderer2) {}
  ngAfterViewInit(): void {
    // Wait for the animation to finish and then remove the item
    if (this.shouldBeDeleted) {
      setTimeout(() => {
        this.renderer.setStyle(this.display.nativeElement, 'display', 'none');
      }, ANIMATION_DURATION);
    }
  }
}
