<div *ngIf="deposit">
  <!-- QR CODE INFO -->
  <div class="ips-qr-code-message-wrapper" *ngIf="!qrCode">
    <div class="">
      {{ 'SCAN_QR_CODE_MSG' | translate }}
    </div>
    <div>
      <!--generate button to enable grcode -->
      <button type="submit" class="button mt-2" (click)="generateQRCode()">
        {{ 'GENERATE_QR' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="qrCode" class="ips-qr-code-wrapper">
    <div>
      <div class="count-down-timer-wrapper">
        <div>{{ 'QR_CODE_EXPIRES' | translate }}</div>
        <div>{{ timeLeft * 1000 | date : 'mm:ss' }}</div>
      </div>
      <!-- QR CODE -->
    </div>
    <div>
      <div>
        {{ 'SCAN_QR' | translate }}
      </div>
      <qrcode [qrdata]="qrCode" [width]="128" [errorCorrectionLevel]="'L'" [margin]="4"></qrcode>
    </div>
  </div>
</div>
