<div class="box">
  <header class="pb-2">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{'WITHDRAWAL_STATUS' | translate}}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close">
          <img
            *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
            [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
            alt=""
            (click)="closeModal()"
          />
          <div
            *ngIf="clientName === clientNames.ADMIRAL_PINNBET"
            (click)="closeModal()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3 h3 text-center">
      <div class="text-uppercase status-success" *ngIf="data.status === 'SUCCESS'">
        {{'WITHDRAWAL_STATUS_SUCCESSFUL' | translate}}
      </div>
      <div class="text-uppercase status-pending" *ngIf="data.status === 'PENDING'">
        {{'WITHDRAWAL_STATUS_PENDING' | translate}}
      </div>
      <div class="text-uppercase status-error" *ngIf="data.status === 'ERROR'">
        {{'WITHDRAWAL_STATUS_UNSUCCESSFUL' | translate}}
      </div>
    </div>
  </header>
  <div class="px-5 pt-3">
    <div class="row mb-2">
      <div class="col-12 text-center" [innerHTML]="data?.message | sanitizeHtml"></div>
    </div>
  </div>
  <hr />
  <div class="py-1 text-center">
    <div (click)="closeModal()" class="btn btn-outline-light px-6">OK</div>
  </div>
</div>
