export enum IMAGE_ORIENTATION {
  Up = 1,
  Down = 3,
  Right = 8,
  Left = 6,
  UpMirrored = 2,
  DownMirrored = 4,
  LeftMirrored = 5,
  RightMirrored = 7,
  Default = 0,
  NotJpeg = -1,
  NotDefined = -2,
}
