<div class="c_right-menu-container">
  <hr class="w-100" />
  <!--  Right menu conntent-->
  <ul class="list-menu">
    <ng-container *ngIf="rightMenuData">
      <ng-container *ngFor="let asset of rightMenuData.menuAsset">
        <ng-container *ngIf="asset.activeItem">
          <ng-container *ngFor="let menuItem of asset.items">
            <ng-container [ngSwitch]="menuItem.assetType">
              <ng-container *ngSwitchCase="'DIVIDER'">
                <li>
                  <hr />
                </li>
              </ng-container>
              <ng-container *ngSwitchCase="'BUTTON'">
                <li class="c_btn-container" (click)="clickOnBtn(menuItem)">
                  <div class="c_user-operation-button">
                    <mat-icon>{{ menuItem.btnIcon }}</mat-icon>
                    {{ menuItem.title }}
                  </div>
                </li>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <li (click)="navigateToLinkInMenu(menuItem)">
                  <a
                    [routerLinkActive]="['active-link']"
                    routerLink="{{ menuItem.url }}"
                    class="list-menu-title mb-0"
                  >
                    <div class="c_right-list-menu-item">
                      <mat-icon *ngIf="menuItem.icon && !menuItem.image">{{ menuItem.icon }}</mat-icon>
                      <img
                        class="c_asset-image"
                        *ngIf="menuItem.image && !menuItem.icon && !menuItem.image.path.endsWith('.svg')"
                        [src]="baseServerUrl + menuItem.image.path"
                        alt="image"
                      />
                      <i
                        *ngIf="menuItem.image && !menuItem.icon && menuItem.image.path.endsWith('.svg')"
                        [inlineSVG]="baseServerUrl + menuItem.image.path"
                        fallbackSVG="assets/images/default-left-menu-icon.svg"
                        class="icon"
                      >
                      </i>
                      <div class="c_no-icon-placeholder" *ngIf="!menuItem.icon && !menuItem.image"></div>
                      <div *ngIf="!menuItem?.localizationKey && menuItem.title && !menuItem.text">
                        {{ menuItem.title | titlecase }}
                      </div>
                      <div
                        class="inner-html-text"
                        *ngIf="!menuItem?.localizationKey && menuItem.text"
                        [innerHTML]="menuItem.text"
                      ></div>
                      <span
                        *ngIf="!menuItem?.localizationKey"
                        class="menu-item-name"
                        [innerHTML]="menuItem.text"
                      ></span>
                      <span *ngIf="menuItem?.localizationKey" class="menu-item-name">
                        {{ menuItem.localizationKey | translate }}
                      </span>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</div>
