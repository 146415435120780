import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticPageService } from '@services/static-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent {
  public pageHtml: string;
  constructor(
    private staticPageService: StaticPageService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title
  ) {
    const requestedRoute = this.route.snapshot.url[0].path;
    this.staticPageService.getStaticPage(requestedRoute).subscribe(pages => {
      if (pages[0]) {
        this.title.setTitle(pages[0].name);
        this.pageHtml = pages[0].html;
      } else {
        this.router.navigate([environment.appDefaultRoute]);
      }
    });
  }
}
