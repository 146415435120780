<nav
  #headerContainer
  id="headerContainer"
  [ngClass]="{'md-shop-nav': environment.clientName === CLIENT_NAMES.ADMIRAL_MD_SHOP}" 
  class="align-items-center py-3 py-xl-0 navbar navbar-border-bottom"
>
  <div class="headerButtons center left-margin">
    <span class="header-icon-button left-menu-icon d-xl-none" (click)="handleLeftSideBar()">
      <i
        [setSVGAttributes]="{ height: '14px' }"
        inlineSVG="/assets/images/hamburger.svg"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
    </span>
    <div class="logo">
      <a [routerLink]="[environment.appDefaultRoute]">
        <img [ngClass]="logoImageClassCreator()" [src]="logoImg" alt="logo" width="105" height="27" />
      </a>
    </div>
  </div>

  <!-- MAIN MENU -->
  <div class="nav-main ml-4 d-none d-xl-block">
    <ul>
      <ng-template #tipContent>{{ 'HELP' | translate }}</ng-template>
      <ng-container *ngFor="let menuItem of mainMenu?.menuItems">
        <li
          [ngbTooltip]="tipContent"
          placement="right"
          [disableTooltip]="menuItem?.url !== '/help'"
          [routerLinkActive]="['active']"
          (click)="navigationService.navigate(menuItem)"
        >
          <ng-container *ngIf="!(menuItem.requireAuthorized && !player)">
            <a routerLink="{{ menuItem.url }}">
              <div>
                <i
                  *ngIf="menuItem?.inactiveIcon || menuItem?.activeIcon"
                  [setSVGAttributes]="{ height: '18px' }"
                  [inlineSVG]="imagesBaseUrl + menuItem?.inactiveIcon || menuItem?.activeIcon"
                  fallbackSVG="assets/images/default-left-menu-icon.svg"
                ></i>
                <span *ngIf="menuItem?.url !== '/help'" class="menu-item-name">{{
                  menuItem.name | translate
                }}</span>
              </div>
            </a>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- CONTENT FOR AUTHORIZED IN PLAYER -->
  <div class="nav-user ml-auto headerButtons right-margin" *ngIf="player">
    <a *ngIf="showContinueRegistrationBtn" [routerLink]="['/registration']" class="registration-proceed-btn">
      {{ 'AUTHORIZED_HEADER.PROCEED_REGISTRATION' | translate | uppercase }}</a
    >
    <a
      [routerLink]="['/profile/deposit']"
      routerLinkActive="active"
      [class]="btnClass"
      class="header-icon-button"
    >
      <i
        [setSVGAttributes]="{ height: '14' }"
        inlineSVG="/assets/images/deposit.svg"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
      <span class="nav-user-text">{{ 'DEPOSIT' | translate }}</span>
    </a>
    <a [routerLink]="['/profile/messages']" routerLinkActive="active" class="header-icon-button">
      <span class="icon-wrapper">
        <i
          [setSVGAttributes]="{ height: '14px' }"
          inlineSVG="/assets/images/bell.svg"
          fallbackSVG="assets/images/default-left-menu-icon.svg"
        ></i>
        <span *ngIf="player && unreadMessages > 0" class="notification-counter">{{ unreadMessages }}</span>
      </span>
      <span>{{ 'MESSAGES' | translate }}</span>
    </a>
    <span *ngIf="showMenuUserIcon" class="header-icon-button user-icon">
      <i
        [setSVGAttributes]="{ height: '32px' }"
        inlineSVG="/assets/images/new-user.svg"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
        (click)="handleRightSideBar()"
        class="auth-required"
      ></i>
    </span>
    <span class="header-icon-button d-none d-xxl-inline-flex">
      <i
        inlineSVG="assets/images/clock-solid.svg"
        [setSVGAttributes]="{ height: '14px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
      <span> {{ clock | async | date: 'HH:mm' }}</span>
    </span>
  </div>
  <!-- CONTENT FOR UNAUTHORIZED IN PLAYER -->
  <div class="nav-user ml-auto pr-2 headerButtons right-margin" *ngIf="!player">
    <a [routerLink]="['/login']" routerLinkActive="active" class="header-icon-button"
      ><i
        inlineSVG="assets/images/login.svg"
        [setSVGAttributes]="{ height: '14px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
      <span>
        {{ 'LOGIN' | translate }}
      </span>
    </a>
    <a
      [routerLink]="['/registration']"
      routerLinkActive="active"
      *ngIf="showRegistrationBtn"
      class="header-icon-button"
    >
      <i
        inlineSVG="assets/images/registration.svg"
        [setSVGAttributes]="{ height: '14px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
      <span>
        {{ 'REGISTRATION' | translate }}
      </span>
    </a>
    <span class="header-icon-button d-none d-xxl-inline-flex">
      <i
        inlineSVG="assets/images/clock-solid.svg"
        [setSVGAttributes]="{ height: '14px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
      <span> {{ clock | async | date: 'HH:mm' }}</span>
    </span>
  </div>
</nav>
