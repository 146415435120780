import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransactionService } from '@services/transaction.service';
import { LobbyService } from '@services/lobby.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Location } from '@angular/common';
import { TransactionFilterService } from '@services/transaction-filter.service';
import { PRODUCT_CODES } from '@enums/product-codes.enum';
import { TRANSACTIONS_MOCK } from '../temp-mock-data/transactions-tabs-mock';
import { TRANSACTION_TYPE } from '@enums/transaction-type.enum';
import { TRANSACTION_WALLET_TYPE } from '@enums/transaction-wallet-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-transactions-header',
  templateUrl: './transactions-header.component.html',
  styleUrls: ['./transactions-header.component.scss'],
})
export class TransactionsHeaderComponent implements OnInit, OnDestroy {
  public filterOpen: boolean;
  public transactions = [];
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public activeTab;
  public transactionType: string;
  public transactionWalletType: string;
  private gameProducts;

  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  private filterQueryParams;
  public transactionQueryParams;

  private router$: Subscription;

  constructor(
    private router: Router,
    private location: Location,
    private lobbyService: LobbyService,
    private transactionService: TransactionService,
    private appStateFacadeService: AppStateFacadeService,
    private transactionFilterService: TransactionFilterService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setTransactionsHeaderData();
    this.getTransactionTabsBasedOnWallet();
    this.getGameProducts();
    this.setRouterSubscription();
    // Check is localization loaded if it is not then wait for observable to inform us;
    this.filterOpen = Object.keys(this.translate.translations).length > 0;
    this.translate.onDefaultLangChange.subscribe(
      language => (this.filterOpen = Object.keys(language.translations).length > 0)
    );
  }

  ngOnDestroy(): void {
    this.router$.unsubscribe();
  }

  // set transaction header
  private setTransactionsHeaderData(): void {
    const url = this.router.url;
    if (url.includes('game-transactions')) {
      this.transactionType = TRANSACTION_TYPE.GAME;
      this.transactionWalletType = TRANSACTION_WALLET_TYPE.MAIN;
    }
    if (url.includes('bonus-transactions')) {
      this.transactionType = TRANSACTION_TYPE.BONUS;
      this.transactionWalletType = TRANSACTION_WALLET_TYPE.BONUS;
    }
    if (url.includes('freebet-transactions')) {
      this.transactionType = TRANSACTION_TYPE.FREEBET;
      this.transactionWalletType = TRANSACTION_WALLET_TYPE.FREEBET;
    }
  }

  // get transaction tabs based on wallet type from mock data
  private getTransactionTabsBasedOnWallet(): void {
    this.transactions = this.transactionService.getTransactionTabsByWalletType(
      TRANSACTIONS_MOCK,
      this.transactionWalletType
    );
  }

  // get game products here so we dont need to call this method in all transaction history components to take product id
  private getGameProducts(): void {
    this.lobbyService.getGameProducts().subscribe(gameProducts => {
      this.gameProducts = gameProducts;
      this.getTransactions();
    });
  }

  // get transactions
  private getTransactions(): void {
    const url = this.router.url;
    // based on url filter transactions by product code
    if (url.includes(`profile/${this.transactionType}-transactions/sport-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.SPORT);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/casino-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.CASINO);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/live-casino-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.LIVE_CASINO);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/slot-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.SLOT);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/live-dealer-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.LIVE_DEALER);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/virtual-games-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.VIRTUAL_GAMES);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/lotto-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.HR_LOTO);
    }
    if (url.includes(`profile/${this.transactionType}-transactions/poker-transaction`)) {
      this.filterTransactions(PRODUCT_CODES.POKER);
    }
  }

  private setRouterSubscription(): void {
    this.router$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        /**
         *  when component is initialized, and user click again on for  ex. game transaction from right menu
         *  he will hit '/profile/game-transactions' route, in that case we need to filter first tab txns
         *  and reset filter value in service and filter component
         */
        if (event.url === `/profile/${this.transactionType}-transactions`) {
          this.transactionFilterService.resetFilterValue();
          this.filterTransactions(
            this.clientName === CLIENT_NAMES.ADMIRAL_CROATIA ? PRODUCT_CODES.CASINO : PRODUCT_CODES.SPORT
          );
        }

        /**
         *  on initialization route is for ex. 'profile/game-transactions/sport-transaction',
         *  and after that on every tab click route will have query params, so when
         *  user click browser back for right amount of time and hit initialization route,
         *  location.back() will get him to right route in history
         */
        if (event.url === `/profile/${this.transactionType}-transactions/sport-transaction`) {
          this.location.back();
        }
      }
    });
  }

  /*
    filter transactions by product code

    Every tab in game transactions page have "gameProducts" array.
    That array contains product code or codes that will be used to get transactions to be displayed when that tab is clicked.
    For ex. when we click on sport transactions, we need only SPORT product transactions, but when clicked on virtual games
    transactions we need combination of 'ELBET', 'SEVEN_AGGREGATOR', 'SPRIBE', 'GOLDEN_RACE', 'AVIATRIX' product transactions.
    To display those transactions we need to get and send product ids from that products.

    This function take product code when user click on some tab or on initialization of component and then based on that product code:

    *first - find transaction in TRANSACTIONS_MOCK,
    *second - filter gameProduct list that we get from BE and get all products that we have in TRANSACTION_MOCK transaction gameProducts list,
    and return ids from that products

    When we get ids, then we add them to transactionQueryParams and we get the filterQueryParams (start and end date)
    from transaction filter service, and after that send like queryParams to any product transaction component
    (sportTransactions, slotTransactions, live-dealerTransactions, virtualTransactions)
  */
  public filterTransactions(productCode): void {
    this.activeTab = productCode;
    // first
    const transaction = this.transactionService.getTransactionsByProductCode(productCode);
    // second
    const transactionGameProductIds = this.transactionService.getTransactionGameProductIds(
      this.gameProducts,
      transaction
    );
    this.applyFilter(transaction, transactionGameProductIds);
  }

  // apply filter
  private applyFilter(transaction, transactionProductIds): void {
    this.transactionQueryParams = {
      product: transaction.code,
      productId: transactionProductIds,
      walletType: this.transactionWalletType,
      filterChange: false,
    };
    this.filterQueryParams = this.transactionFilterService.getFilterQueryParams();
    const queryParams = { ...this.transactionQueryParams, ...this.filterQueryParams };
    const link = transaction.transactionLink.replace('{{transactionType}}', this.transactionType);
    this.router.navigate([link], { queryParams });
  }
}
