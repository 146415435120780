<div>
  <div class="allsecure-payment-wrapper">
    <div class="saved-card-wrapper p-3">
      <div class="c_choose-card-label-wrapper">
        <div class="c_create_new_cart_label" (click)="switchSavedCardOrNewCard()">
          <div class="c_plus-badge"></div>
          <div>{{ 'ALLSECURE_CARD_NUMBER_PLACEHOLDER' | translate }}</div>
          <div
            class="u_pointer close-icon-bar-mobile close-icon-bar-mobile--is-select-new-card"
            (click)="closeQuickBar()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
      </div>
      <div class="c_message-style" *ngIf="!isCardRegister">
        {{ 'NUVEI_NO_SAVED_CARDS' | translate }}
      </div>
      <div class="u_pointer" *ngIf="isCardRegister">
        <div class="row-saved-card-cvv-label">
          <label class="label required">{{ 'SELECT_CARD' | translate }}</label>
          <label class="label required">{{ 'ALLSECURE_CVV_NUMBER' | translate }}</label>
        </div>
        <div class="row-saved-card-cvv">
          <div class="saved-card-preview" (click)="openModalToChoseCard()">
            <div class="saved-card-stars" *ngIf="playerCards">
              {{ selectedCard?.lastFourDigits || playerCards[0]?.lastFourDigits }}
            </div>
            <div class="aztec-arrow-wrapper">
              <ng-template *ngTemplateOutlet="chevronLeftIcon"></ng-template>
              <ng-template *ngTemplateOutlet="deleteIcon"></ng-template>
            </div>
          </div>
          <div class="c_allsecure-cvv-frame">
            <ng-container *ngTemplateOutlet="cvvNumber"></ng-container>
          </div>
        </div>
      </div>
      <div class="c_error-message">
        <small *ngIf="cvvLabelMessage | async" class="invalid-feedback-allsecure">
          {{ cvvLabelMessage.getValue() | translate }}
        </small>
      </div>
      <!-- IF corvusFormCollapsed means we dont use corvus form for payment we use saved credit card   -->
      <div *ngIf="isCardRegister" class="item-button-wrapper mt-3">
        <input
          class="item-button"
          type="submit"
          (click)="interceptSubmitForRegisterdCard()"
          value="{{ 'PAY_NOW' | translate }}"
          [disabled]="!validCvv"
        />
      </div>
    </div>
  </div>
</div>

<ng-template #chevronLeftIcon>
  <div class="material-symbols-outlined u_pointer">arrow_left</div>
</ng-template>

<ng-template #deleteIcon>
  <div class="material-symbols-outlined u_pointer" (click)="deleteCard($event)">delete</div>
</ng-template>

<ng-template #choseSavedCardsModal>
  <div class="box select-cart-dialog">
    <ng-container *ngFor="let card of playerCards; let i = index">
      <div class="select-card-option" (click)="onChange(i)">
        <div *ngIf="card.lastFourDigits" class="saved-card-stars">{{ card.lastFourDigits }}</div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #modalTemplateDeleteCard>
  <div class="box delete-card-dialog">
    <div class="delete-card-dialog-buttons">
      <button class="item-button" (click)="deleteCardConfirm()">
        {{ 'DELETE_CARD' | translate }}
      </button>
      <button class="item-button" (click)="modalRef.close()">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #cvvNumber>
  <!-- CVV -->
  <div class="form-group cvv-container">
    <div id="cvv_div-bar"></div>
  </div>
</ng-template>
