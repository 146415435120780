export class LobbyProviderModel {
  id: number;
  name: string;
  logo: string;
  mobileLogo: string;
  text: string;
  gameCount: number;
  constructor(obj?: any) {
    this.id = (obj && obj.id) || null;
    this.gameCount = obj.gameCount;
    this.name = (obj && obj.name) || null;
    this.logo = obj?.logo?.includes('/assets/') ? obj.logo : null;
    this.mobileLogo = obj?.mobileLogo?.includes('/assets/') ? obj.mobileLogo : null;
    this.text = this.name
      .replace(/[_]/g, ' ')
      .split(' ')
      .map(item => item.charAt(0).toUpperCase() + item.substring(1))
      .join(' ');
  }
}
