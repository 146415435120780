import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { Deposit } from '@models/deposit.model';
import { Withdrawal } from '@models/withdrawal.model';
import { HelpersService } from '@services/helpers.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-card-detail-predefined-values',
  templateUrl: './payment-card-detail-predefined-values.component.html',
  styleUrls: ['./payment-card-detail-predefined-values.component.scss'],
})
export class PaymentCardDetailPredefinedValuesComponent implements OnInit, OnDestroy {
  @Input() payment: Deposit | Withdrawal;
  @Input() paymentForm: FormGroup;
  @Input() buttonText: string;
  @Input() activeWithdrawal: boolean;
  @Input() showInputValueLabel = true;
  @Input() predefinedValues: number[];
  @Input() paymentTandCLink: string;
  @Input() paymentTandCLabel: string;
  @Input() showPromoCode = false;
  @Input() showSubmitBtn = true;
  @Input() tAndCLink = '/terms-and-conditions/payment-terms';
  @Input() showCurrencyInInput?: string; // 'euro-currency' or 'rsd-currency' implement in future if needed
  // Template refs for additional Toc and Links
  @Input() additionalToCLinksTemplate: TemplateRef<any>;
  @Output() setPredefinedValueALL = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<string>();
  public selectedPredefinedValue;
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  public isPaymentPromoCodeEnabled = false;
  // Subscriptions
  private paymentPromoCode$: Subscription;

  constructor(private helpersService: HelpersService, private appStateFacadeService: AppStateFacadeService) {}

  ngOnInit(): void {
    this.paymentPromoCode$ = this.appStateFacadeService
      .getPaymentPromoCodeStatusObservable()
      .subscribe(status => (this.isPaymentPromoCodeEnabled = status));
  }

  ngOnDestroy(): void {
    this.paymentPromoCode$.unsubscribe();
  }

  public setPredefinedValue(value): void {
    if (this.activeWithdrawal) {
      return;
    }
    if (value === 0) {
      this.setPredefinedValueALL.emit();
    } else {
      this.paymentForm.patchValue({ amount: value.toString() });
    }
    this.selectedPredefinedValue = value;
    this.amount.markAsDirty();
  }

  public onlyNumbersAndLettersAllow(input) {
    this.paymentForm
      .get(input.target.getAttribute('formControlName'))
      .setValue(this.helpersService.allowOnlyNumbersAndLetters(input.target.value));
  }

  public resetPredefinedValue(): void {
    this.selectedPredefinedValue = '';
  }

  public changeAmount(direction) {
    if (!this.selectedPredefinedValue || this.selectedPredefinedValue === 0 || this.activeWithdrawal) {
      return;
    }
    const currentValue = Number(this.amount.value);
    const currentSelectedValue = Number(this.selectedPredefinedValue);
    let newValue = 0;

    if (direction === '+') {
      newValue = currentValue + currentSelectedValue;
    } else {
      if (currentValue === currentSelectedValue) {
        return;
      }
      newValue = currentValue - currentSelectedValue;
    }

    this.paymentForm.patchValue({ amount: String(newValue) });
  }

  public amountChanged(value) {
    this.valueChanged.emit(!this.amount.errors ? value : null);
  }

  public get amount(): AbstractControl {
    return this.paymentForm.get('amount');
  }

  public generateTAndConditionLink(): string {
    const link =
      this.payment.code === 'ALLSECURE_DEPOSIT' ? '/terms-and-conditions/allsecure-terms' : this.tAndCLink;
    return link;
  }
}
