<div class="c_counter">
  <div class="c_counter-wrapper" [ngClass]="{ 'c_counter-background': showBackground }">
    <div class="c_counter-label">
      <ng-container *ngIf="isInputDateBeforeToday">
        {{ labelForCounter | translate | uppercase }}
      </ng-container>
      <ng-container *ngIf="!isInputDateBeforeToday">
        {{ labelForCounter | translate | uppercase }}
      </ng-container>
    </div>
    <div class="c_counter-container">
      <div class="c_counter-category-container">
        <div class="c_digits-container">
          <div class="c_digit">{{ Math.floor(days / 10) }}</div>
          <div class="c_digit">{{ days % 10 }}</div>
        </div>
        <div class="c_category-label">
          {{ 'COMMON_DAYS' | translate }}
        </div>
      </div>
      <div class="c_counter-category-container">
        <div class="c_digits-container">
          <div class="c_digits-container">
            <div class="c_digit">{{ Math.floor(hours / 10) }}</div>
            <div class="c_digit">{{ hours % 10 }}</div>
          </div>
        </div>
        <div class="c_category-label">
          {{ 'COMMON_HOURS' | translate }}
        </div>
      </div>
      <div class="c_counter-category-container">
        <div class="c_digits-container">
          <div class="c_digit">{{ Math.floor(minutes / 10) }}</div>
          <div class="c_digit">{{ minutes % 10 }}</div>
        </div>
        <div class="c_category-label">
          {{ 'COMMON_MINUTES_SHORT' | translate }}
        </div>
      </div>
      <div class="c_counter-category-container">
        <div class="c_digits-container">
          <div class="c_digit">{{ Math.floor(this.seconds / 10) }}</div>
          <div class="c_digit">{{ this.seconds % 10 }}</div>
        </div>
        <div class="c_category-label">
          {{ 'COMMON_SECONDS_SHORT' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
