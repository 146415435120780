import { HelpersStringManipulationService } from '@services/helpers-string-manipulation.service';

export class HelperClientService extends HelpersStringManipulationService {
  constructor() {
    super();
  }

  // check for user agent string
  getUserAgent() {
    return navigator.userAgent;
  }

  // check if is android
  isAndroid() {
    return /Android/i.test(this.getUserAgent()) && !this.isWindows();
  }

  // check if is windows
  isWindows() {
    return /Windows Phone|IEMobile|WPDesktop/i.test(this.getUserAgent());
  }

  // check if is iPhone
  isIPhone() {
    return /iPhone/i.test(this.getUserAgent()) && !this.isIPad() && !this.isWindows();
  }

  isIPhoneNotch() {
    if (this.isIPhone()) {
      const notchSizes = [
        [414, 896],
        [375, 812],
      ];
      const matchSize = screenSize => screenSize[0] === screen.width && screenSize[1] === screen.height;

      return notchSizes.some(matchSize);
    }
  }

  // check if is iPod
  isIPod() {
    return /iPod/i.test(this.getUserAgent());
  }

  // check if is iPad
  isIPad() {
    // return /iPad/i.test(this.getUserAgent());
    // old method - don't work for new iPads and iPads Pro
    // return /iPad/i.test(this.getUserAgent());

    if (this.getIPadModel() !== 'Not an iPad') {
      return true;
    }
  }
  // iPad model checks.
  getIPadModel() {
    if (window.screen.height / window.screen.width === 1024 / 768) {
      // iPad, iPad 2, iPad Mini
      if (window.devicePixelRatio === 1) {
        return 'iPad 1, iPad 2, iPad Mini 1';
      }
      // iPad 3, 4, 5, Mini 2, Mini 3, Mini 4, Air, Air 2, Pro 9.7
      else {
        return 'iPad 3, iPad 4, iPad 5, iPad Air 1, iPad Air 2, iPad Mini 2, iPad Mini 3, iPad Mini 4, iPad Pro 9.7';
      }
    }
    // iPad Pro 10.5
    else if (window.screen.height / window.screen.width === 1112 / 834) {
      return 'iPad Pro 10.5';
    }
    // iPad Pro 12.9, Pro 12.9 (2nd Gen)
    else if (window.screen.height / window.screen.width === 1366 / 1024) {
      return 'iPad Pro 12.9, Pro 12.9 (2nd Gen)';
    }
    // Not an ipad
    else {
      return 'Not an iPad';
    }
  }

  // check if is ios
  iOS() {
    return this.isIPad() || this.isIPod() || this.isIPhone();
  }

  iOSversion(): number[] {
    // check if is iOS
    if (this.iOS()) {
      // iOS version will be like iPhone OS 4_3_3, you will get three digits
      const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || (0 as any), 10)];
    }
  }

  iOSversion15(): boolean {
    const version = this.iOSversion();
    if (version && version[0] >= 15) {
      return true;
    } else {
      return false;
    }
  }

  isTablet() {
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      this.getUserAgent().toLowerCase()
    );
  }

  // check if is mobile
  isMobile() {
    return this.isAndroid() || this.iOS();
  }

  /**
   * check if user is using a mobile device based on client width
   * return true if it does
   */

  isMobileBasedOnClientWidth() {
    return document.documentElement.clientWidth <= 1173;
  }

  isSafari() {
    return (
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1
    );
  }

  public getSessionData() {
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset;
    let verOffset;
    let ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    const isMobile = {
      Android: () => navigator.userAgent.match(/Android/i),
      BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
      iPad: () => {
        if (window.screen.height / window.screen.width === 1024 / 768) {
          if (window.devicePixelRatio === 1) {
            return 'iPad 1 or 2';
          } else {
            return 'iPad 3 to 5';
          }
        } else if (window.screen.height / window.screen.width === 1112 / 834) {
          return 'iPad Pro 10.5';
        } else if (window.screen.height / window.screen.width === 1366 / 1024) {
          return 'iPad Pro 12.9';
        } else {
          return 'unknown device';
        }
      },
      iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
      Opera: () => navigator.userAgent.match(/Opera Mini/i),
      Windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
      any: () =>
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows() || [isMobile.iPad()],
    };
    let osName = '';
    const clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
    ];

    for (const cs of clientStrings) {
      if (cs.r.test(nAgt)) {
        osName = cs.s;
        break;
      }
    }

    let osVersion: any = '';

    if (/Windows/.test(osName)) {
      osVersion = /Windows (.*)/.exec(osName)[1];
      osName = 'Windows';
    }

    switch (osName) {
      case 'Mac OS X':
        if (/Mac OS X (10[\.\_\d]+)/.exec(nAgt)) {
          osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        } else if (/Mac OS X (11[\.\_\d]+)/.exec(nAgt)) {
          osVersion = /Mac OS X (11[\.\_\d]+)/.exec(nAgt)[1];
        } else if (/Mac OS X (12[\.\_\d]+)/.exec(nAgt)) {
          osVersion = /Mac OS X (12[\.\_\d]+)/.exec(nAgt)[1];
        } else if (/Mac OS X (12[\.\_\d]+)/.exec(nAgt)) {
          osVersion = /Mac OS X (12[\.\_\d]+)/.exec(nAgt)[1];
        }
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] || 0);
        break;
    }
    const session = {};
    session['deviceHeight'] = screen.height;
    session['deviceWidth'] = screen.width;
    session['resolution'] =
      window.screen.width * window.devicePixelRatio + ' ' + window.screen.height * window.devicePixelRatio;
    session['windowHeight'] = window.innerHeight;
    session['windowWidth'] = window.innerWidth;
    session['platform'] = navigator.platform;
    session['javaEnabled'] = window.navigator.javaEnabled();
    session['browserName'] = browserName;
    session['fullVersion'] = fullVersion;
    session['majorVersion'] = majorVersion;
    session['appName'] = navigator.appName;
    session['appVersion'] = navigator.appVersion;
    session['userAgent'] = navigator.userAgent;
    session['engine'] = navigator.product;
    session['osName'] = osName;
    session['osVersion'] = osVersion;
    session['referral'] = JSON.parse(localStorage.getItem('referralCode')) || '';

    if (!this.isMobile()) {
      session['deviceVersion'] = null;
      session['deviceType'] = 'desktop';
    } else {
      session['deviceVersion'] = isMobile.any()[0];
      session['deviceType'] = 'mobile';
    }
    return session;
  }
}
