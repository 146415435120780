export const PERSONAL_QUESTIONS = [
  {
    code: 'MOTHERS_MAIDEN_NAME',
  },
  {
    code: 'FAVORITE_HOBBY',
  },
  {
    code: 'FAVORITE_CLUB',
  },
  {
    code: 'FAVORITE_BOOK',
  },
  {
    code: 'CHILDHOOD_HERO',
  },
  {
    code: 'BEST_FRIEND_NAME',
  },
  {
    code: 'MY_PET',
  },
  {
    code: 'MY_NICKNAME',
  },
  {
    code: 'MY_FIRST_CAR',
  },
  {
    code: 'MY_SECRET_CODE',
  },
];
