<div class="box">
  <header class="row py-2">
    <div class="col-9">
      <div class="h4 mb-0 mb-md-2 text-uppercase">{{'MD_SHOP_WITHDRAWAL_SECURITY_DIALOG' | translate}}</div>
    </div>
    <div class="col-3">
      <div class="close">
        <img
          *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
          [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
          (click)="closeModal()"
        />
        <div
          *ngIf="clientName === clientNames.ADMIRAL_PINNBET"
          class="u_pointer"
          (click)="closeModal()"
          [inlineSVG]="'assets/icons/close_cross.svg'"
        ></div>
      </div>
    </div>
  </header>
  <div class="row pt-3">
    <div class="col-12">
      <form [formGroup]="securityQuestionForm" (ngSubmit)="checkSecurityQuestion()">
        <!-- PASSWORD -->
        <div class="form-group">
          <label class="label required" for="password">{{ 'PASSWORD' | translate }}</label>
          <input
            #password
            type="password"
            class="form-control form-control-simple"
            id="password"
            formControlName="password"
            [ngClass]="{
                        'is-invalid':
                          (!securityQuestionForm.get('password').valid && securityQuestionForm.get('password').dirty)
                      }"
          />
          <a
            #hideShowIcon_password
            class="show-hide-password"
            id="password-toggle"
            (click)="maskUnmaskPassword(password, hideShowIcon_password)"
          >
            <div inlineSVG="assets/images/hide-eye.svg" class="hide-icon"></div>
            <div inlineSVG="assets/images/show-eye.svg" class="show-icon"></div>
          </a>
          <small
            *ngIf="
                      securityQuestionForm.get('password').hasError('validatePassword') &&
                        !securityQuestionForm.get('password').hasError('required')
                      "
            class="invalid-feedback"
          >
            {{ 'PASSWORD_VALIDATE_MESSAGE' | translate }}
          </small>
          <small
            *ngIf="
                      securityQuestionForm.get('password').hasError('required') && securityQuestionForm.get('password').touched
                      "
            class="invalid-feedback"
          >
            {{ 'REQUIRED_FIELD' | translate }}
          </small>
        </div>
        <!-- PERSONAL QUESTION-->
        <div class="form-group">
          <label for="personalQuestion" class="label required"
            >{{ 'WITHDRAWAL_PERSONAL_QUESTION' | translate }}</label
          >
          <app-custom-select
            [itemsObservable]="personalQuestionsCodesObservable"
            (selectEvent)="onSelectQuestion($event)"
            [fcontrol]="securityQuestionForm.get('personalQuestion')"
            [patchValueObservable]="patchPersonalQuestionSubject"
          ></app-custom-select>
        </div>
        <!-- PERSONAL ANSWER -->
        <div class="form-group">
          <label class="label required" for="personalAnswer"
            >{{ 'REGISTRATION_PERSONAL_ANSWER' | translate }}</label
          >
          <input
            type="text"
            class="form-control form-control-simple"
            formControlName="personalAnswer"
            [ngClass]="{
                    'is-invalid':
                      !securityQuestionForm.get('personalAnswer').valid &&
                      securityQuestionForm.get('personalAnswer').touched
                  }"
          />
          <small
            *ngIf="securityQuestionForm.get('personalAnswer').hasError('required')"
            class="invalid-feedback"
          >
            {{ 'REQUIRED_FIELD' | translate }}
          </small>
        </div>
        <div class="form-group d-flex pb-5">
          <div class="ml-auto">
            <small
              ><a (click)="requestSecurityQandAReset()" class="text-primary"
                >{{ 'MD_SHOP_WITHDRAWAL_SECURITY_DIALOG_FORGOT_ANSWER' | translate }}</a
              ></small
            >
          </div>
        </div>
        <button
          appAdmiralButton
          [type]="'submit-btn-style'"
          [text]="'CONTINUE'"
          marginCostume="auto"
          [disabledBtn]="securityQuestionForm.invalid || isLoading"
          [disabled]="securityQuestionForm.invalid || isLoading"
          class="btn btn-primary"
        ></button>
      </form>
    </div>
  </div>
</div>

<!-- LOADER -->
<div *ngIf="isLoading" class="spinner-border spinner" role="status"></div>
