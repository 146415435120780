import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { FocusableOption, FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
@Component({
  selector: 'app-custom-select-item',
  template: `{{ item.text }}`,
})
export class CustomSelectItemComponent implements FocusableOption {
  @HostBinding('attr.tabindex')
  tabIndex = -1;

  @HostBinding('attr.role')
  role = 'list-item';

  @Input() item: { value: any; text: string } = { value: '', text: 'NONE' };
  constructor(private elRef: ElementRef, private focusMonitor: FocusMonitor) {}
  focus(origin?: FocusOrigin): void {
    this.elRef.nativeElement.focus();
  }
}
