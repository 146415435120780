<div
  [formGroup]="paymentForm"
  [ngClass]="{ 'pinnbet-payment-card-details': environment.clientName === clientNames.ADMIRAL_PINNBET }"
>
  <div class="form-group">
    <div class="row">
      <div class="col-12">
        <label *ngIf="showInputValueLabel" class="label required">{{
          'ENTER_DESIRED_AMOUNT' | translate
        }}</label>
      </div>
      <div class="col-12">
        <input
          inputmode="decimal"
          type="text"
          class="form-control {{ showCurrencyInInput ? showCurrencyInInput : '' }}"
          (input)="resetPredefinedValue()"
          (keyup)="amountChanged($event.target.value)"
          formControlName="amount"
          maxlength="12"
          placeholder="{{ 'DEPOSIT_CARD_PLACEHOLDER_AMOUNT' | translate }}"
          [ngClass]="{
            'is-invalid': !paymentForm.get('amount').valid && paymentForm.get('amount').dirty,
            'is-valid': paymentForm.get('amount').valid && paymentForm.get('amount').dirty
          }"
        />
        <small class="invalid-feedback" *ngIf="paymentForm.get('amount').hasError('required')">
          {{ 'REQUIRED_FIELD' | translate }}
        </small>
        <small class="invalid-feedback" *ngIf="paymentForm.get('amount').hasError('amountInvalid')">
          {{ 'DEPOSIT_CARD_INVALID_FORMAT' | translate }}
        </small>
        <!-- condition  (min and !max error) - there is a case when user dont have available money to withdrawal
             and in that case we have both error message present -->
        <small
          class="invalid-feedback"
          *ngIf="paymentForm.get('amount').hasError('min') && !paymentForm.get('amount').hasError('max')"
        >
          {{ 'PAYMENT_CARD_MIN_VALUE_VALIDATION' | translate }}
          {{ payment.minValue | currency : payment.currency }}
        </small>
        <!-- MAX ERROR MESSAGE FOR ALL DEPOSITS EXCEPT ADMIRAL 360 AND RESERVED FUNDS -->
        <small
          class="invalid-feedback"
          *ngIf="
            paymentForm.get('amount').hasError('max') &&
            payment.deposit &&
            payment.code !== 'NXCS_DEPOSIT' &&
            payment.code !== 'RESERVED_FUNDS_DEPOSIT'
          "
        >
          {{ 'PAYMENT_CARD_MAX_VALUE_VALIDATION' | translate }}
          {{ payment.maxValue | currency : payment.currency }}
        </small>
        <!-- MAX ERROR MESSAGE FOR ALL WITHDRAWALS EXCEPT ADMIRAL 360, RESERVED FUNDS AND DEPOZITRON -->
        <small
          class="invalid-feedback"
          *ngIf="
            paymentForm.get('amount').hasError('max') &&
            !payment.deposit &&
            !['NXCS_WITHDRAWAL', 'DEPOZITRON_WITHDRAWAL', 'RESERVED_FUNDS_WITHDRAWAL'].includes(payment.code)
          "
        >
          {{ 'WITHDRAWAL_MAX_ERROR' | translate }}
        </small>
        <!-- MAX ERROR MESSAGE FOR DEPOZITRON -->
        <small
          class="invalid-feedback"
          *ngIf="
            paymentForm.get('amount').hasError('max') &&
            !payment.deposit &&
            payment.code === 'DEPOZITRON_WITHDRAWAL'
          "
        >
          {{ 'DEPOZITRON_MAX_ERROR' | translate }}
          {{ payment.maxValue | currency : payment.currency }}
        </small>
        <!-- ERROR MESSAGE FOR DEPOZITRON INVALID NUMBER-->
        <small
          class="invalid-feedback"
          *ngIf="
            paymentForm.get('amount').hasError('depozitronInvalidNumbet') &&
            payment.code === 'DEPOZITRON_WITHDRAWAL'
          "
        >
          {{ 'DEPOZITRON_NUMBER_NOT_VALID' | translate }} {{ payment.step }}
        </small>
        <!-- MAX ERROR MESSAGE FOR ADMIRAL 360, AND RESERVED FUNDS DEPOSIT AND WITHDRAWAL-->
        <small
          class="invalid-feedback"
          *ngIf="
            paymentForm.get('amount').hasError('max') &&
            (payment.code === 'NXCS_DEPOSIT' ||
              payment.code === 'NXCS_WITHDRAWAL' ||
              payment.code === 'RESERVED_FUNDS_DEPOSIT' ||
              payment.code === 'RESERVED_FUNDS_WITHDRAWAL')
          "
        >
          {{ 'ADMIRAL_360_MAX_ERROR' | translate }}
        </small>
      </div>
    </div>
  </div>
  <div class="form-group box pick-a-number" *ngIf="predefinedValues?.length">
    <div [ngClass]="{ 'label-buttons': environment.clientName !== clientNames.ADMIRAL_PINNBET }">
      <p>{{ 'SELECT_DESIRED_NUMBER' | translate }}</p>
    </div>
    <div class="buttons-container">
      <div class="amount-value">
        <div class="decrease text-center" (click)="changeAmount('-')">-</div>
        <div
          class="amount text-center"
          [ngClass]="[paymentForm.get('amount').valid ? 'amount-valid' : 'amount-invalid']"
        >
          {{ paymentForm.get('amount').value | safeNumber | number : '1.2-2' }}
        </div>
        <div class="increase text-center" (click)="changeAmount('+')">+</div>
      </div>
      <div class="predefined-values">
        <div
          class="pv text-center"
          *ngFor="let value of predefinedValues"
          [ngClass]="{ selected: selectedPredefinedValue === value }"
          (click)="setPredefinedValue(value)"
        >
          {{ value === 0 ? ('ALL' | translate) : (value | number : '1.0-0') }}
        </div>
      </div>
    </div>
  </div>

  <!-- T&C of the app -->
  <div class="row mb-3" *ngIf="showPromoCode && isPaymentPromoCodeEnabled">
    <div class="col-12">
      <label class="label">{{ 'REGISTRATION_SRB_REFERRAL_CODE' | translate }}</label>
    </div>
    <div class="col-12">
      <input
        type="text"
        class="form-control"
        formControlName="promoCode"
        placeholder="{{ 'COMMON_VERIFICATION_ENTER_CODE' | translate }}"
        maxlength="20"
        (input)="onlyNumbersAndLettersAllow($event)"
      />
    </div>
  </div>
  <div *ngIf="paymentForm.get('tAndC'); else paymentTandC">
    <div class="row">
      <div class="col-lg-7">
        <div class="form-group">
          <div class="mb-3">
            <app-custom-checkbox [checkboxFormControl]="paymentForm.get('tAndC')">
              <span>
                <small>{{ 'DEPOSIT_I_ACCEPT_THE' | translate }}</small>
                <a class="text-primary small" [routerLink]="[generateTAndConditionLink()]">
                  {{ 'DEPOSIT_T_AND_C' | translate }}
                </a>
                <small>{{ 'DEPOSIT_REDIRECT_TO_PAYMENT' | translate }}</small>
              </span>
            </app-custom-checkbox>
          </div>
        </div>
        <!--        if we have some additional condition here we can render it as input template-->
        <ng-container *ngIf="additionalToCLinksTemplate">
          <ng-container *ngTemplateOutlet="additionalToCLinksTemplate"></ng-container>
        </ng-container>
      </div>
      <div class="col-lg-5 text-center text-lg-right">
        <button
          type="submit"
          (click)="resetPredefinedValue()"
          [disabled]="paymentForm.invalid || !amount.value || activeWithdrawal"
          [ngClass]="{
            disabled: paymentForm.invalid || !amount.value || activeWithdrawal
          }"
          class="btn btn-color ml-2"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
  <!-- T&C of the payment -->
  <ng-template #paymentTandC>
    <div *ngIf="paymentForm.get('paymentTandC'); else noTandC" class="row">
      <div class="col-lg-7">
        <div class="form-group">
          <div class="mb-3">
            <app-custom-checkbox [checkboxFormControl]="paymentForm.get('paymentTandC')">
              <span>
                <a class="text-primary small" [href]="paymentTandCLink" target="_blank">
                  {{ paymentTandCLabel }}
                </a>
              </span>
            </app-custom-checkbox>
          </div>
        </div>
      </div>
      <div class="col-lg-5 text-center text-lg-right">
        <button
          type="submit"
          *ngIf="showSubmitBtn"
          [disabled]="paymentForm.invalid || !voucherCode.value"
          [ngClass]="{
            disabled: paymentForm.invalid || !voucherCode.value
          }"
          class="btn btn-color ml-2"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </ng-template>

  <!-- No T&C -->
  <ng-template #noTandC>
    <div class="col-lg-12 text-center text-center submit-button">
      <button
        type="submit"
        *ngIf="showSubmitBtn"
        (click)="resetPredefinedValue()"
        [disabled]="paymentForm.invalid || !amount.value || activeWithdrawal"
        [ngClass]="{
          disabled: paymentForm.invalid || !amount.value || activeWithdrawal
        }"
        class="btn btn-color ml-2"
      >
        {{ buttonText }}
      </button>
    </div>
  </ng-template>
</div>
