import { Menu } from './menu.model';

export class DesktopMenus {
  public mainMenu: Menu;
  public leftMenu: Menu;
  public rightMenu: Menu;

  constructor(data) {
    this.mainMenu = new Menu(data.mainMenu);
    this.leftMenu = new Menu(data.leftMenu);
    this.rightMenu = new Menu(data.rightMenu);
  }
}

export class MobileMenus {
  public mainMenu: Menu;
  public leftMenu: Menu;
  public rightMenu: Menu;

  constructor(data) {
    this.mainMenu = new Menu(data.mainMenu);
    this.leftMenu = new Menu(data.leftMenu);
    this.rightMenu = new Menu(data.rightMenu);
  }
}
