import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StaticPageService {
  constructor(private http: HttpClient) {}

  /**
   * get static page
   */
  getStaticPage(link): Observable<any> {
    return this.http.get(environment.apiUrl + 'static-page/find-by-name', { params: { link } });
  }
}
