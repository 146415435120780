export class JackpotModel {
  currentValue: number;
  id: number;
  jackpotTheme: 'BRONZE' | 'SILVER' | 'GOLD' | 'PLATINUM';
  name: string;
  timeBased: boolean;
  numberOfPrizes: number;
  awardedJackpots: number;
  currencyCode: string;
  startDate: number;
  endDate: number;
  jackpotWinners: JackpotWinners | null;
  timeBasedInitialState: boolean;
  constructor(data: any) {
    this.currentValue = data.currentValue;
    this.id = data.id;
    this.jackpotTheme = data.jackpotTheme;
    this.name = data.name;
    this.timeBased = data.timeBased;
    this.numberOfPrizes = data.numberOfPrizes;
    this.awardedJackpots = data.awardedJackpots;
    this.currencyCode = data.currencyCode;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.jackpotWinners = data.jackpotWinners?.length ? new JackpotWinners(data.jackpotWinners) : null;
    this.timeBasedInitialState = true;
  }
  public populateJackPotWithWinners(data: any) {
    this.jackpotWinners = data.jackpotWinners;
  }
}

export class JackpotWinners {
  id: number;
  player: string;
  jackpotValue: number;
  dateOfWin: number;
  constructor(data: any) {
    this.id = data.id;
    this.player = data.player;
    this.jackpotValue = data.jackpotValue;
    this.dateOfWin = data.dateOfWin;
  }
}
