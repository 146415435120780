import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

declare var ga;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  createTracker() {
    // Remove this when pinnbet get GA account
    if (environment.clientName !== CLIENT_NAMES.ADMIRAL_PINNBET) {
      ga('create', environment.gaKey, 'auto');
    }
  }

  setPage(page) {
    // Remove this when pinnbet get GA account
    if (environment.clientName !== CLIENT_NAMES.ADMIRAL_PINNBET) {
      ga('set', 'page', page);
    }
  }

  sendHit() {
    // Remove this when pinnbet get GA account
    if (environment.clientName !== CLIENT_NAMES.ADMIRAL_PINNBET) {
      ga('send', 'pageview');
    }
  }

  // create our event emitter to send our data to Google Analytics
  public eventEmitter(category: string, action: string, label: string = null, value: number = null): void {
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA) {
      ga('send', 'event', {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      });
    }
  }

  // create our event emitter to send our data to Google Analytics
  public eventEmitterCro(category: string, action: string, label: string = null, value: number = null): void {
    if (environment.gaKey && environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      ga('send', 'event', {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      });
    }
  }

  // set the user ID using signed-in user_id. USER_ID is a string
  public setUserIdSrb(USER_ID): void {
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA) {
      ga('set', 'userId', USER_ID);
    } else {
      return;
    }
  }
}
