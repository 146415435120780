import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

const ANGULAR_MATERIAL_MODULES = [MatFormFieldModule, MatInputModule, MatSelectModule, MatCheckboxModule];

@NgModule({
  imports: [...ANGULAR_MATERIAL_MODULES],
  exports: [...ANGULAR_MATERIAL_MODULES],
})
export class AngularMaterialModule {}
