<div class="user-operation-container headerButtons" *ngIf="player">
  <a
    [routerLink]="['/profile/deposit']"
    routerLinkActive="active"
    class="item_with_label"
  >
    <i
      [setSVGAttributes]="{ height: '14' }"
      inlineSVG="/assets/images/deposit.svg"
      fallbackSVG="assets/images/default-left-menu-icon.svg"
    ></i>
    <span class="nav-user-text">{{ 'DEPOSIT' | translate }}</span>
  </a>
  <a [routerLink]="['/profile/messages']" routerLinkActive="active" class="item_with_label">
      <span class="icon-wrapper position-relative">
        <i
          *ngIf="!headerCmsData.notificationButtonIcon"
          [setSVGAttributes]="{ height: '14px' }"
          inlineSVG="/assets/images/bell.svg"
          fallbackSVG="assets/images/default-left-menu-icon.svg"
        ></i>
        <mat-icon *ngIf="headerCmsData.notificationButtonIcon">{{ headerCmsData.notificationButtonIcon }}</mat-icon>
        <span *ngIf="player && unreadMessages > 0" class="notification-counter">{{ unreadMessages }}</span>
      </span>
    <span *ngIf="!headerCmsData.i18nNotification">{{ 'MESSAGES' | translate }}</span>
    <span *ngIf="headerCmsData.i18nNotification">{{ headerCmsData.i18nNotification | translate }}</span>
  </a>
  <span *ngIf="showMenuUserIcon" class="user-icon u_pointer">
    <i
      [setSVGAttributes]="{ height: '32px' }"
      inlineSVG="/assets/images/new-user.svg"
      fallbackSVG="assets/images/default-left-menu-icon.svg"
      (click)="handleRightSideBar()"
      class="auth-required"
    ></i>
  </span>
  <ng-container *ngTemplateOutlet="clockAndTime"></ng-container>
</div>
<div class="user-operation-container" *ngIf="!player">
  <a [routerLink]="['/login']" routerLinkActive="active"
     class="item_with_label item_with_label--is-login"
  >
    <i
      *ngIf="headerCmsData.showLoginButtonIcon && !headerCmsData.loginButtonIcon"
      inlineSVG="assets/images/login.svg"
      [setSVGAttributes]="{ height: '14px' }"
      fallbackSVG="assets/images/default-left-menu-icon.svg"
    ></i>
    <mat-icon *ngIf="headerCmsData.loginButtonIcon">{{ headerCmsData.loginButtonIcon }}</mat-icon>
    <span *ngIf="headerCmsData.showLoginButtonTitle">
      <span *ngIf="!headerCmsData.i18nLogin">{{ 'LOGIN' | translate }}</span>
      <span *ngIf="headerCmsData.i18nLogin">{{ headerCmsData.i18nLogin | translate }}</span>
    </span>
  </a>
  <a
    [routerLink]="['/registration']"
    routerLinkActive="active"
    *ngIf="showRegistrationBtn"
    class="item_with_label item_with_label--is-registration"
  >
    <i
      *ngIf="headerCmsData.showRegistrationButtonIcon && !headerCmsData.registrationButtonIcon"
      inlineSVG="assets/images/registration.svg"
      [setSVGAttributes]="{ height: '14px' }"
      fallbackSVG="assets/images/default-left-menu-icon.svg"
    ></i>
    <mat-icon *ngIf="headerCmsData.registrationButtonIcon">{{ headerCmsData.registrationButtonIcon }}</mat-icon>
    <span *ngIf="headerCmsData.showRegisterButtonTitle">
      <span *ngIf="!headerCmsData.i18nRegistration">{{ 'REGISTRATION' | translate }}</span>
      <span *ngIf="headerCmsData.i18nRegistration">{{ headerCmsData.i18nRegistration | translate }}</span>
    </span>
  </a>
  <ng-container *ngTemplateOutlet="clockAndTime"></ng-container>
</div>

<ng-template #clockAndTime>
  <span class="c_time-container d-none d-xxl-inline-flex" *ngIf="!isMobile && headerCmsData.showTime">
    <i
      *ngIf="headerCmsData.showTimeIcon"
      inlineSVG="assets/images/clock-solid.svg"
      [setSVGAttributes]="{ height: '14px' }"
      fallbackSVG="assets/images/default-left-menu-icon.svg"
    ></i>
    <span> {{ clock | async | date: 'HH:mm' }}</span>
  </span>
</ng-template>
