import { Injectable } from '@angular/core';
import { Subject, Observable, interval, Subscription } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class WebSocketNxcsService {
  private subject$: Subscription;
  private RXJSIntervalObservable$: Subscription;
  private urlForSocket: string;

  constructor() { }

  private WSSubject = new Subject();

  public WSSubjectObservable = this.WSSubject.asObservable();

  private subject: any;

  public close() {
    this.subject.complete();
    this.subject$.unsubscribe();
    this.RXJSIntervalObservable$.unsubscribe();
  }

  public connectToWS(url: string): Observable<any> {
    this.urlForSocket = url;
    this.subject = webSocket(this.urlForSocket);
    this.subscribeToSocket(this.subject);
    this.checkConnectionPingStart();
    return this.WSSubjectObservable;
  }

  public reconnectToWS() {
    this.subject = webSocket(this.urlForSocket);
    this.subscribeToSocket(this.subject);
    this.checkConnectionPingStart();
  }

  private subscribeToSocket(socket) {
    if (this.subject$) {
      this.subject$.unsubscribe();
    }
    this.subject$ = socket.subscribe(
      {
        next: data =>
          this.WSSubject.next({
            connectionStatus: 'nxcs connection success',
            payload: data,
          }),
        error: err => {
          console.warn('nxcs connection not success');
          this.WSSubject.next({
            connectionStatus: 'error',
            payload: {},
          });
          this.cancelConnectionPing();
        },
        complete: () => {
          console.warn('nxcs connection lost');
          this.cancelConnectionPing();
          this.WSSubject.next({
            connectionStatus: 'error',
            payload: {},
          });
        }
      }
    );
  }

  private cancelConnectionPing() {
    if (this.RXJSIntervalObservable$) {
      this.RXJSIntervalObservable$.unsubscribe();
    }
  }

  private checkConnectionPingStart() {
    this.cancelConnectionPing();
    this.RXJSIntervalObservable$ = interval(10000).subscribe(() => {
      this.subject.next('nxcs ping');
    });
  }
}
