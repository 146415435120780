import { Pipe, PipeTransform } from '@angular/core';

/*
 *
 * This pipe accepts the passed currency value only for easier searching of currency implementations in the future
 *
 */
@Pipe({
  name: 'currency',
})
export class CurrencyPipe implements PipeTransform {
  constructor() {}

  transform(value: any, currency) {
    if (!value) {
      return;
    }

    return `${value} ${currency}`;
  }
}
