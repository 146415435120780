import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paystack-deposit-status',
  templateUrl: './paystack-deposit-status.dialog.html',
  styleUrls: ['./paystack-deposit-status.dialog.scss'],
})
export class PaystackDepoistStatusDialog {
  public imagesBaseUrl = environment.imagesBaseUrl;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  // close modal
  public closeModal(): void {
    // redirect on route without params needed for deposit dialog
    const route = this.router.url.split('?')[0];
    this.router.navigate([route]);
    this.activeModal.close();
  }
}
