import { AfterViewInit, Component, Input } from '@angular/core';
import * as L from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { AppStateFacadeService } from '@state/app-state.facade';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
})
export class LeafletMapComponent implements AfterViewInit {
  // input from parent component
  @Input() mapMarkers;

  private map;
  private player = this.appStateFacadeService.getPlayerData();

  constructor(private appStateFacadeService: AppStateFacadeService) {}

  ngAfterViewInit(): void {
    this.findCoodrinatesOfCity();
  }

  private findCoodrinatesOfCity() {
    const provider = new OpenStreetMapProvider();
    const queryPromise = provider.search({
      query: this.player.city,
    });

    // create layer
    const layer = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 18,
      }
    );

    queryPromise.then(value => {
      if (value.length > 0) {
        const xCoor = value[0].x;
        const yCoor = value[0].y;
        // if city is found set view on that city
        this.map = L.map('map', { zoom: 12, layers: [layer] }).setView([yCoor, xCoor]);
      } else {
        // if city is not found set serbia global view
        this.map = L.map('map', { zoom: 6, layers: [layer] }).setView([44.276671273775186, 20.7861328125]);
      }

      // add markers from parent component
      this.addMarkers(this.mapMarkers);
    });
  }

  private addMarkers(mapMarkers): void {
    // create cluster for grouping markers when zoom out
    const markerCluster = new MarkerClusterGroup();
    // var bounds = L.latLngBounds(); Use this lines to fit bounds if ever needed

    mapMarkers.forEach(mapMarker => {
      // if lat or long are not present, skip iteration
      if (mapMarker.latitude === null || mapMarker.longitude === null) {
        return;
      }
      // create custom icon
      const myIcon = L.icon(mapMarker.icon);
      // create marker for each location
      const marker = L.marker([mapMarker.latitude, mapMarker.longitude], { icon: myIcon });

      // bounds.extend([mapMarker.latitude, mapMarker.longitude]); Use this lines to fit bounds if ever needed
      // create popup with data for each marker
      const popup = L.popup({ closeButton: false }).setContent(mapMarker.popup);

      // bind popup to marker
      marker.bindPopup(popup).on('click', this.clickZoom.bind(this));

      // add marker to cluster
      markerCluster.addLayer(marker);
    });

    // add cluster to map
    this.map.addLayer(markerCluster);
    // this.map.fitBounds(bounds); Use this lines to fit bounds if ever needed
  }

  // zoom marker to center of screen
  clickZoom(e) {
    this.map.setView(e.target.getLatLng(), 17);
  }
}
