import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { BlinkIdMultiSideRecognizerResult } from '@microblink/blinkid-in-browser-sdk';
import { DocumentScanPersonalData } from '@models/document-scan-personal-data.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentScannerService {
  private scanData: DocumentScanPersonalData = null;
  private isDocumentVerified: boolean;

  constructor(private http: HttpClient) {}

  public getScanData(): DocumentScanPersonalData {
    return this.scanData;
  }

  public removeScanData(): void {
    this.scanData = null;
  }

  public setScanResult(data: BlinkIdMultiSideRecognizerResult): void {
    if (!data) {
      return;
    }
    this.scanData = new DocumentScanPersonalData(data);
  }

  public getDocumentVerified(): boolean {
    return this.isDocumentVerified;
  }

  public setDocumentVerified(status: boolean): void {
    this.isDocumentVerified = status;
  }

  public getCroMicroBlinkDataToSend(playerId: number): any {
    const playerFullNameMicroblink = `${this.scanData.firstName}_${this.scanData.lastName}_microblink`;
    const tag = 'MicroBlink documents';
    const microBlinkDoc = [
      {
        playerId,
        location: null,
        createdOn: null,
        tag,
        data: this.scanData.frontImageBase64,
        fileName: `${playerFullNameMicroblink}_front_image.jpg`,
      },
      {
        playerId,
        location: null,
        createdOn: null,
        tag,
        data: this.scanData.backImageBase64,
        fileName: `${playerFullNameMicroblink}_back_image.jpg`,
      },
      {
        playerId,
        location: null,
        createdOn: null,
        tag,
        data: this.scanData.faceImageBase64,
        fileName: `${playerFullNameMicroblink}_face_image.jpg`,
      },
    ];

    return microBlinkDoc;
  }

  public uploadScannedDocument(): Observable<any> {
    const tag = 'MicroBlink documents';
    const fullName = `${this.scanData.firstName}_${this.scanData.lastName}_microblink`;
    const docs = [
      {
        tag,
        data: this.scanData.frontImageBase64,
        fileName: `${fullName}_front_image.jpg`,
        type: 'JPG',
      },
      {
        tag,
        data: this.scanData.backImageBase64,
        fileName: `${fullName}_back_image.jpg`,
        type: 'JPG',
      },
    ];

    const options = {
      params: new HttpParams().set('pid', this.scanData.personalIdNumber),
    };

    return this.http.post(environment.apiUrl + 'verification-doc/micro-blink', docs, options);
  }
}
