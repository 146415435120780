/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetaContentService {
  constructor(private meta: Meta, private title: Title) {}

  // set meta content
  public setMetaContent(urlAfterRedirects): void {
    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_SERBIA:
        this.setAdmiralBetSerbiaContent(urlAfterRedirects);
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
        this.setPinnBetSerbiaContent(urlAfterRedirects);
        break;
      case CLIENT_NAMES.ADMIRAL_BOSNIA:
        this.setAdmiralBetBosniaContent(urlAfterRedirects);
        break;
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        this.setAdmiralCroatiaContent(urlAfterRedirects);
        break;
      case CLIENT_NAMES.ADMIRAL_MD_SHOP:
        this.setAdmiralMdShopContent(urlAfterRedirects);
        break;
    }
  }

  // set admiral bet serbia content
  private setAdmiralBetSerbiaContent(url): void {
    // LIVE DEALER PAGE
    if (url.includes('/live-dealer')) {
      this.setPageTitle('AdmiralBet|Live Dealer za pun osećaj igranja|Više od Igre');
      this.setMetaKeywords('blackjack online, live dealer, admiralbet slot, rulet uživo, poker online');
      this.setMetaDescription(
        'BlackJack, Poker, Rulet samo za Vas! AdmiralBet Live Dealer Vam pruža jedinstvenu priliku za sjajan dobitak bez izlaska iz kuće. Čekamo te!'
      );

      // SLOT PAGE
    } else if (url.includes('/slot')) {
      this.setPageTitle('Slot igre i ostale online igre na sreću - AdmiralBet.rs');
      this.setMetaKeywords('online slot, rulet online, slot igre, online aparati, slotovi, admiralbet slot');
      this.setMetaDescription(
        'Najbolje online slot igre na AdmiralBet-u. Klasični naslovi kao što su Book of Ra, Wild Hot i Rulet. Osvojite velike nagrade i podignite svoje isplate na viši nivo.'
      );
    }
    // SPORT LIVE
    else if (url.includes('/sport-live')) {
      this.setPageTitle('Klađenje uživo - Live kvote i šanse za brzi dobitak - AdmiralBet');
      this.setMetaKeywords(
        'klađenje uživo, sportsko klađenje uživo, live klađenje, live kvote, admiralbet uživo'
      );
      this.setMetaDescription(
        'Klađenje uživo na AdmiralBet-u - Najveće live kvote za sve sportove. Fudbal, košarka, tenis i još mnogo toga. Iskusi užitak klađenja i brz dobitak. Čekamo te! '
      );
    }
    // SPORT - Admiral bonus kvota
    else if (url.includes('/sport-prematch?sport=AdmiralBet_bonus_kvota')) {
      this.setPageTitle('AdmiralBet: Admiral bonus kvota - Najbolje kvote za klađenje');
      this.setMetaDescription(
        'Admiral bonus kvota je posebna ponuda AdmiralBet kladionice koja svojim igračima omogućuje povećanje dobitka, čineći klađenje na sportske događaje profitabilnijim.'
      );
    }
    // SPORT - BASKETBALL - ABA
    else if (
      url.includes('/sport-prematch?sport=Ko%C5%A1arka&region=Me%C4%91unarodne') &&
      url.includes('competition=AdmiralBet_ABA_Liga')
    ) {
      this.setPageTitle('ABA Liga - Košarka - Najbolje Kvote | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na košarkaške utakmice ABA lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - BASKETBALL PLAYERS
    else if (url.includes('/sport-prematch?sport=Ko%C5%A1arka_igra%C4%8Di')) {
      this.setPageTitle('Košarkaški igrači klađenje - Najbolje kvote - AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na košarkaške igrače je popularna opcija među ljubiteljima košarke koji se klade na pojedinačne performanse igrača. Kladi se na košarkaške igrače uz AdmiralBet.'
      );
    }
    // SPORT - BASKETBALL
    else if (url.includes('/sport-prematch?sport=Ko%C5%A1arka')) {
      this.setPageTitle('Košarka klađenje - Najbolje kvote - AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na košarku omogućuje igračima da prate omiljene timove i igrače te se klade na različite ishode. Pokaži svoje sposobnosti i isprobaj svoju sreću uz AdmiralBet.'
      );
    }
    // SPORT - TENNIS
    else if (url.includes('/sport-prematch?sport=Tenis')) {
      this.setPageTitle('Tenis klađenje - Najbolje kvote - AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na tenis nudi brojne opcije za klađenje, uključujući pobednika meča, broj osvojenih gemova i setova, i mnoge druge opcije. Kladi se na teniske mečeve uz AdmiralBet.'
      );
    }
    // SPORT - Champions League
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Me%C4%91unarodne_klupske') &&
      url.includes('competition=Liga_%C5%A0ampiona')
    ) {
      this.setPageTitle('Liga Šampiona - Fudbal - Najbolje Kvote | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Lige Šampiona lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Europa League
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Me%C4%91unarodne_klupske') &&
      url.includes('competition=Liga_Evrope')
    ) {
      this.setPageTitle('Liga Evrope - Fudbal - Najbolje Kvote | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Lige Evrope lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - England 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Engleska') &&
      url.includes('competition=Engleska_1')
    ) {
      this.setPageTitle('Engleska 1 - Premijer Liga - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Engleske Premijer lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Germany 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Nema%C4%8Dka') &&
      url.includes('competition=Nema%C4%8Dka_1')
    ) {
      this.setPageTitle('Nemačka 1 - Bundesliga - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Nemačke Bundeslige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Spain 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=%C5%A0panija') &&
      url.includes('competition=%C5%A0panija_1')
    ) {
      this.setPageTitle('Španija 1 - LaLiga - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Španjolske LaLige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Italy 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Italija') &&
      url.includes('competition=Italija_1')
    ) {
      this.setPageTitle('Italija 1 - Serie A - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Talijanske Serie A lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - France 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Francuska') &&
      url.includes('competition=Francuska_1')
    ) {
      this.setPageTitle('Francuska 1 - Ligue 1 - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Francuske lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Netherlands 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Holandija') &&
      url.includes('competition=Holandija_1')
    ) {
      this.setPageTitle('Holandija 1 - Eredivisie - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Holandske lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Belgium 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Belgija') &&
      url.includes('competition=Belgija_1')
    ) {
      this.setPageTitle('Belgija 1 - Jupiler Pro League - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Belgijske lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Turkey 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Turska') &&
      url.includes('competition=Turska_1')
    ) {
      this.setPageTitle('Turska 1 - Super Lig - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Turske lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Portugal 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Portugalija') &&
      url.includes('competition=Portugalija_1')
    ) {
      this.setPageTitle('Portugalija 1 - Liga Portugal - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Portugalske lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Srbija Super liga
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Srbija') &&
      url.includes('competition=Srbija_1')
    ) {
      this.setPageTitle('Srbija Super Liga  - Fudbal | AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Srpske Super lige lakše je nego ikada na AdmiralBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - FOOTBALL
    else if (url.includes('/sport-prematch?sport=Fudbal')) {
      this.setPageTitle('Fudbal klađenje - Najbolje kvote - AdmiralBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal pruža ljubiteljima ovog sporta da se još više uključe u igru i osvoje potencijalno velike dobitke. AdmiralBet nudi široku ponudu fudbal klađenja.'
      );
    }
    // SPORT
    else if (url.includes('/sport-prematch')) {
      this.setPageTitle('AdmiralBet|Najbolje kvote, online igre i slot|Više od Igre');
      this.setMetaKeywords(
        'sportska kladionica, online klađenje, najbolje online kvote, kladionica admiralbet'
      );
      this.setMetaDescription(
        'Dođi u AdmiralBet da igramo zajedno - bogata ponuda sportskih igara, odlične kvote, online slotovi, Live Dealer i još mnogo toga, sve na jednom mestu. Čekamo te!'
      );
    }
    // VIRTUAL GAMES
    else if (url.includes('/virtual')) {
      this.setPageTitle('AdmiralBet|Virtualne igre - stvarna isplata|Više od Igre');
      this.setMetaKeywords('virtualni fudbal, virtualne trke, admiralbet virtualne igre, lucky six online');
      this.setMetaDescription(
        'Veliki izbor virtualnih igara za prave igrače. Virtualni fudbal, trke za svakodnevne uloge i još brže isplate. Čekamo te!'
      );
    }
    // GREEK LOTTO
    else if (url.includes('/help/category/105/card/126')) {
      this.setPageTitle('AdmiralBet: Lucky Greek loto - Igraj i osvoji velike nagrade!');
      this.setMetaKeywords('');
      this.setMetaDescription(
        'Igrajte Grčki Loto i osvojite velike nagrade! Lucky Greek održava se svakih 5 minuta. Odaberite 1-8 brojeva od ukupno 80 ponuđenih i pogodite sve kako bi pobedili.'
      );
    }
    // VIRTUAL GAMES HELP
    else if (url.includes('/help/category/105')) {
      this.setPageTitle('AdmiralBet|Elbet - pravila Virtualnih igara|Više od Igre');
      this.setMetaKeywords('');
      this.setMetaDescription(
        'Niste okušali sreću u Virtualnim igrama. Pravila Lucky Balls i Lucky Greek na jednom mestu. Uvek tu za Vas za lakšu igru i dobitak. Čekamo te!'
      );
    }
    // MINI GAMES
    else if (url.includes('/mini-games')) {
      this.setPageTitle('AdmiralBet|Mini igre - stvarna isplata|Više od Igre');
      this.setMetaKeywords('Aviator, Rocketman, Spaceman...');
      this.setMetaDescription('Veliki izbor mini igara ze prave igrače. Čekamo te!');
    }
    // PROMOTIONS
    else if (url.includes('/promotions')) {
      this.setPageTitle('Bonus dobrodošlice i promocije za sve igrače - AdmiralBet.rs');
      this.setMetaKeywords('admiralbet promocije, freebet, promocija klađenja, besplatno klađenje');
      this.setMetaDescription(
        'AdmiralBet nudi bonus dobrodošlice i najbolje promocije za sve igrače. Freebet-ovi, besplatna klađenja i bonusi na uplate. Registruj se i uživaj u pogodnostima!'
      );
    }
    // REGISTRATION
    else if (url.includes('/registration')) {
      this.setPageTitle('AdmiralBet|Registracija - Postani deo porodice|Više od Igre');
      this.setMetaDescription(
        'Registruj se i ostvari neverovatne bonuse samo na AdmiralBet.rs. Najbolje igre i najveće kvote su sada na samo nekoliko klikova od Vas. Čekamo te!'
      );
    }
    // DEPOSIT
    else if (url.includes('/profile/deposit')) {
      this.setPageTitle('AdmiralBet|Uplata sredstava|Više od igre');
      this.setMetaDescription(
        'Uplata sredstava putem kartice, payspot depozita, uplatnice ili na nekom od uplatnih mesta AdmiralBet-a. Nikad lakše klađenje. Čekamo te!'
      );
    }
    // WITHDRAWAL
    else if (url.includes('/profile/withdrawal')) {
      this.setPageTitle('AdmiralBet|Isplata sredstava|Više od Igra');
      this.setMetaDescription(
        'Isplatite svoje zarade direktno na Vaš račun ili podignite na nekom od uplatnih mesta od AdmiralBet-a. Čekamo te!'
      );
    }
    // FREEBET HELP
    else if (url.includes('/help/category/111')) {
      this.setPageTitle('AdmiralBet|Freebet Pravila|Više od Igre');
      this.setMetaDescription(
        'Freebetovi, bonusi, besplatna klađenja, za novoregirstrovane, ali i stare igrače. Pročitajte kako možete koristiti svoje freebet-ove. Čekamo te!'
      );
    }
    // LUCKY SIX
    else if (url.includes('/help/category/121/card/136')) {
      this.setPageTitle('AdmiralBet: Lucky Six 6/48 - Igraj i osvoji velike nagrade!');
      this.setMetaDescription(
        'Lucky Six 6/48 - Igra sa neograničenim mogućnostima pobede. Standardne i specijalne igre. Probaj svoju sreću i osvoji velike nagrade sa Lucky Six-om.'
      );
    }
    // BONUS HELP
    else if (url.includes('/help/category/126')) {
      this.setPageTitle('AdmiralBet|Kako do bonusa|Više od igre');
      this.setMetaDescription(
        'AdmiralBet Vam je pripremio najbolje bonuse. Možete ih koristi na online slot ili virtualnim igrama. Čekamo te!'
      );
      // DEFAULT CONTENT
    } else {
      this.setPageTitle('AdmiralBet: Sportsko klađenje, online igre i slotovi');
      this.setMetaKeywords(
        'millennium, sportska, kladionica, klađenje, uzivo, bonusi, kvota, online, slot, fudbal, tenis, kosarka, lucky, greentube, fazi, freebet, freespin, millenniumbet, admiral, live, casino, slot, table, rulet, novomatic, admiralbet'
      );
      this.setMetaDescription(
        'AdmiralBet je Vaša oaza zabave za sportsko klađenje, klađenje uživo, online slotove i live casino igre. Odlične kvote i široki spektar igara, sve na jednom mjestu!'
      );
    }
  }

  // set pinnbet serbia content
  private setPinnBetSerbiaContent(url): void {
    // LIVE DEALER PAGE
    if (url.includes('/live-dealer')) {
      this.setPageTitle('PinnBet|Live Dealer za pun osećaj igranja|Više od Igre');
      this.setMetaKeywords('blackjack online, live dealer, pinnbet slot, rulet uživo, poker online');
      this.setMetaDescription(
        'BlackJack, Poker, Rulet samo za Vas! PinnBet Live Dealer Vam pruža jedinstvenu priliku za sjajan dobitak bez izlaska iz kuće. Čekamo te!'
      );

      // SLOT PAGE
    } else if (url.includes('/slot')) {
      this.setPageTitle('Slot igre i ostale online igre na sreću - PinnBet.rs');
      this.setMetaKeywords('online slot, rulet online, slot igre, online aparati, slotovi, pinnbet slot');
      this.setMetaDescription(
        'Najbolje online slot igre na PinnBet-u. Klasični naslovi kao što su Book of Ra, Wild Hot i Rulet. Osvojite velike nagrade i podignite svoje isplate na viši nivo.'
      );
    }
    // SPORT LIVE
    else if (url.includes('/sport-live')) {
      this.setPageTitle('Klađenje uživo - Live kvote i šanse za brzi dobitak - PinnBet');
      this.setMetaKeywords(
        'klađenje uživo, sportsko klađenje uživo, live klađenje, live kvote, pinnbet uživo'
      );
      this.setMetaDescription(
        'Klađenje uživo na PinnBet-u - Najveće live kvote za sve sportove. Fudbal, košarka, tenis i još mnogo toga. Iskusi užitak klađenja i brz dobitak. Čekamo te! '
      );
    }
    // SPORT - Pinn bonus kvota
    else if (url.includes('/sport-prematch?sport=PinnBet_bonus_kvota')) {
      this.setPageTitle('PinnBet: Pinn bonus kvota - Najbolje kvote za klađenje');
      this.setMetaDescription(
        'Pinn bonus kvota je posebna ponuda PinnBet kladionice koja svojim igračima omogućuje povećanje dobitka, čineći klađenje na sportske događaje profitabilnijim.'
      );
    }
    // SPORT - BASKETBALL - ABA
    else if (
      url.includes('/sport-prematch?sport=Ko%C5%A1arka&region=Me%C4%91unarodne') &&
      url.includes('competition=PinnBet_ABA_Liga')
    ) {
      this.setPageTitle('ABA Liga - Košarka - Najbolje Kvote | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na košarkaške utakmice ABA lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - BASKETBALL PLAYERS
    else if (url.includes('/sport-prematch?sport=Ko%C5%A1arka_igra%C4%8Di')) {
      this.setPageTitle('Košarkaški igrači klađenje - Najbolje kvote - PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na košarkaške igrače je popularna opcija među ljubiteljima košarke koji se klade na pojedinačne performanse igrača. Kladi se na košarkaške igrače uz PinnBet.'
      );
    }
    // SPORT - BASKETBALL
    else if (url.includes('/sport-prematch?sport=Ko%C5%A1arka')) {
      this.setPageTitle('Košarka klađenje - Najbolje kvote - PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na košarku omogućuje igračima da prate omiljene timove i igrače te se klade na različite ishode. Pokaži svoje sposobnosti i isprobaj svoju sreću uz PinnBet.'
      );
    }
    // SPORT - TENNIS
    else if (url.includes('/sport-prematch?sport=Tenis')) {
      this.setPageTitle('Tenis klađenje - Najbolje kvote - PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na tenis nudi brojne opcije za klađenje, uključujući pobednika meča, broj osvojenih gemova i setova, i mnoge druge opcije. Kladi se na teniske mečeve uz PinnBet.'
      );
    }
    // SPORT - Champions League
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Me%C4%91unarodne_klupske') &&
      url.includes('competition=Liga_%C5%A0ampiona')
    ) {
      this.setPageTitle('Liga Šampiona - Fudbal - Najbolje Kvote | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Lige Šampiona lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Europa League
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Me%C4%91unarodne_klupske') &&
      url.includes('competition=Liga_Evrope')
    ) {
      this.setPageTitle('Liga Evrope - Fudbal - Najbolje Kvote | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Lige Evrope lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - England 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Engleska') &&
      url.includes('competition=Engleska_1')
    ) {
      this.setPageTitle('Engleska 1 - Premijer Liga - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Engleske Premijer lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Germany 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Nema%C4%8Dka') &&
      url.includes('competition=Nema%C4%8Dka_1')
    ) {
      this.setPageTitle('Nemačka 1 - Bundesliga - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Nemačke Bundeslige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Spain 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=%C5%A0panija') &&
      url.includes('competition=%C5%A0panija_1')
    ) {
      this.setPageTitle('Španija 1 - LaLiga - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Španjolske LaLige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Italy 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Italija') &&
      url.includes('competition=Italija_1')
    ) {
      this.setPageTitle('Italija 1 - Serie A - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Talijanske Serie A lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - France 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Francuska') &&
      url.includes('competition=Francuska_1')
    ) {
      this.setPageTitle('Francuska 1 - Ligue 1 - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Francuske lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Netherlands 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Holandija') &&
      url.includes('competition=Holandija_1')
    ) {
      this.setPageTitle('Holandija 1 - Eredivisie - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Holandske lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Belgium 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Belgija') &&
      url.includes('competition=Belgija_1')
    ) {
      this.setPageTitle('Belgija 1 - Jupiler Pro League - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Belgijske lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Turkey 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Turska') &&
      url.includes('competition=Turska_1')
    ) {
      this.setPageTitle('Turska 1 - Super Lig - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Turske lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Portugal 1
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Portugalija') &&
      url.includes('competition=Portugalija_1')
    ) {
      this.setPageTitle('Portugalija 1 - Liga Portugal - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Portugalske lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - Srbija Super liga
    else if (
      url.includes('/sport-prematch?sport=Fudbal&region=Srbija') &&
      url.includes('competition=Srbija_1')
    ) {
      this.setPageTitle('Srbija Super Liga  - Fudbal | PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal utakmice Srpske Super lige lakše je nego ikada na PinnBet.rs. Otkrijte velike kvote i širok izbor mogućnosti klađenja. Registrujte se danas!'
      );
    }
    // SPORT - FOOTBALL
    else if (url.includes('/sport-prematch?sport=Fudbal')) {
      this.setPageTitle('Fudbal klađenje - Najbolje kvote - PinnBet.rs');
      this.setMetaDescription(
        'Klađenje na fudbal pruža ljubiteljima ovog sporta da se još više uključe u igru i osvoje potencijalno velike dobitke. PinnBet nudi široku ponudu fudbal klađenja.'
      );
    }
    // SPORT
    else if (url.includes('/sport-prematch')) {
      this.setPageTitle('PinnBet|Najbolje kvote, online igre i slot|Više od Igre');
      this.setMetaKeywords('sportska kladionica, online klađenje, najbolje online kvote, kladionica pinnbet');
      this.setMetaDescription(
        'Dođi u PinnBet da igramo zajedno - bogata ponuda sportskih igara, odlične kvote, online slotovi, Live Dealer i još mnogo toga, sve na jednom mestu. Čekamo te!'
      );
    }
    // VIRTUAL GAMES
    else if (url.includes('/virtual')) {
      this.setPageTitle('PinnBet|Virtualne igre - stvarna isplata|Više od Igre');
      this.setMetaKeywords('virtualni fudbal, virtualne trke, pinnbet virtualne igre, lucky six online');
      this.setMetaDescription(
        'Veliki izbor virtualnih igara za prave igrače. Virtualni fudbal, trke za svakodnevne uloge i još brže isplate. Čekamo te!'
      );
    }
    // GREEK LOTTO
    else if (url.includes('/help/category/105/card/126')) {
      this.setPageTitle('PinnBet: Lucky Greek loto - Igraj i osvoji velike nagrade!');
      this.setMetaKeywords('');
      this.setMetaDescription(
        'Igrajte Grčki Loto i osvojite velike nagrade! Lucky Greek održava se svakih 5 minuta. Odaberite 1-8 brojeva od ukupno 80 ponuđenih i pogodite sve kako bi pobedili.'
      );
    }
    // VIRTUAL GAMES HELP
    else if (url.includes('/help/category/105')) {
      this.setPageTitle('PinnBet|Elbet - pravila Virtualnih igara|Više od Igre');
      this.setMetaKeywords('');
      this.setMetaDescription(
        'Niste okušali sreću u Virtualnim igrama. Pravila Lucky Balls i Lucky Greek na jednom mestu. Uvek tu za Vas za lakšu igru i dobitak. Čekamo te!'
      );
    }
    // MINI GAMES
    else if (url.includes('/mini-games')) {
      this.setPageTitle('PinnBet|Mini igre - stvarna isplata|Više od Igre');
      this.setMetaKeywords('Aviator, Rocketman, Spaceman...');
      this.setMetaDescription('Veliki izbor mini igara ze prave igrače. Čekamo te!');
    }
    // PROMOTIONS
    else if (url.includes('/promotions')) {
      this.setPageTitle('Bonus dobrodošlice i promocije za sve igrače - PinnBet.rs');
      this.setMetaKeywords('pinnbet promocije, freebet, promocija klađenja, besplatno klađenje');
      this.setMetaDescription(
        'PinnBet nudi bonus dobrodošlice i najbolje promocije za sve igrače. Freebet-ovi, besplatna klađenja i bonusi na uplate. Registruj se i uživaj u pogodnostima!'
      );
    }
    // REGISTRATION
    else if (url.includes('/registration')) {
      this.setPageTitle('PinnBet|Registracija - Postani deo porodice|Više od Igre');
      this.setMetaDescription(
        'Registruj se i ostvari neverovatne bonuse samo na PinnBet.rs. Najbolje igre i najveće kvote su sada na samo nekoliko klikova od Vas. Čekamo te!'
      );
    }
    // DEPOSIT
    else if (url.includes('/profile/deposit')) {
      this.setPageTitle('PinnBet|Uplata sredstava|Više od igre');
      this.setMetaDescription(
        'Uplata sredstava putem kartice, payspot depozita, uplatnice ili na nekom od uplatnih mesta PinnBet-a. Nikad lakše klađenje. Čekamo te!'
      );
    }
    // WITHDRAWAL
    else if (url.includes('/profile/withdrawal')) {
      this.setPageTitle('PinnBet|Isplata sredstava|Više od Igra');
      this.setMetaDescription(
        'Isplatite svoje zarade direktno na Vaš račun ili podignite na nekom od uplatnih mesta od PinnBet-a. Čekamo te!'
      );
    }
    // FREEBET HELP
    else if (url.includes('/help/category/111')) {
      this.setPageTitle('PinnBet|Freebet Pravila|Više od Igre');
      this.setMetaDescription(
        'Freebetovi, bonusi, besplatna klađenja, za novoregirstrovane, ali i stare igrače. Pročitajte kako možete koristiti svoje freebet-ove. Čekamo te!'
      );
    }
    // LUCKY SIX
    else if (url.includes('/help/category/121/card/136')) {
      this.setPageTitle('PinnBet: Lucky Six 6/48 - Igraj i osvoji velike nagrade!');
      this.setMetaDescription(
        'Lucky Six 6/48 - Igra sa neograničenim mogućnostima pobede. Standardne i specijalne igre. Probaj svoju sreću i osvoji velike nagrade sa Lucky Six-om.'
      );
    }
    // BONUS HELP
    else if (url.includes('/help/category/126')) {
      this.setPageTitle('PinnBet|Kako do bonusa|Više od igre');
      this.setMetaDescription(
        'PinnBet Vam je pripremio najbolje bonuse. Možete ih koristi na online slot ili virtualnim igrama. Čekamo te!'
      );
      // DEFAULT CONTENT
    } else {
      this.setPageTitle('PinnBet: Sportsko klađenje, online igre i slotovi');
      this.setMetaKeywords(
        'millennium, sportska, kladionica, klađenje, uzivo, bonusi, kvota, online, slot, fudbal, tenis, kosarka, lucky, greentube, fazi, freebet, freespin, millenniumbet, admiral, live, casino, slot, table, rulet, novomatic, pinnbet'
      );
      this.setMetaDescription(
        'PinnBet je Vaša oaza zabave za sportsko klađenje, klađenje uživo, online slotove i live casino igre. Odlične kvote i široki spektar igara, sve na jednom mjestu!'
      );
    }
  }

  // set admiral bet bosnia content
  private setAdmiralBetBosniaContent(url): void {
    // LIVE CASINO PAGE
    if (url.includes('/casino-live')) {
      this.setPageTitle('AdmiralBet|Live Dealer i uživo kazino za maksimalno uzbuđenje|Više od Igre');
      this.setMetaKeywords('blackjack online, live dealer, admiralbet slot, rulet uživo, poker online');
      this.setMetaDescription(
        'Zakorači u online Live Dealer kazino i osjeti potpunu čar Las Vegas kockarnica. Blekdžek, rulet, bakarat, poker - sve na jednom mjestu! Čekamo te!'
      );

      // CASINO PAGE
    } else if (url.includes('/casino')) {
      this.setPageTitle('AdmiralBet|Slot igre online, jackpot, bonusi i freespinovi |Više od Igre');
      this.setMetaKeywords('online slot, rulet online, slot igre, online aparati, slotovi, admiralbet slot');
      this.setMetaDescription(
        'Istraži najpopularnije online slot igre i zauzmi svoje mjesto odmah. Čekaju te vrhunski bonusi, besplatni spinovi i džek-potovi koji pomjeraju granice! Čekamo te!'
      );
    }
    // SPORT LIVE
    else if (url.includes('/sport-live')) {
      this.setPageTitle('AdmiralBet|Klađenje uživo uz najbolju ponudu i kvote!|Više od Igre');
      this.setMetaKeywords(
        'klađenje uživo, sportsko klađenje uživo, live klađenje, live kvote, admiralbet uživo'
      );
      this.setMetaDescription(
        'Velika ponuda sportskih igara u toku za najbolje iskustvo uživo klađenja. Iskoristi visoke kvote i sastavi dobitnu kombinaciju! Čekamo te!'
      );
    }
    // SPORT
    else if (url.includes('/sport-prematch')) {
      this.setPageTitle('AdmiralBet Kladionica|Online sportsko klađenje uz najbolje kvote|Više od Igre');
      this.setMetaKeywords(
        'sportska kladionica, online klađenje, najbolje online kvote, kladionica admiralbet'
      );
      this.setMetaDescription(
        'Pridruži se AdmiralBet sportskoj kladionici i uvjeri se zašto smo prepoznati kao najbolja platforma za online klađenje. Više od igre!. Čekamo te!'
      );
    }
    // VIRTUAL GAMES
    else if (url.includes('/virtual')) {
      this.setPageTitle('AdmiralBet|Najbolje Online Virtuelne Igre|Više od Igre');
      this.setMetaKeywords('virtualni fudbal, virtualne trke, admiralbet virtualne igre, lucky six online');
      this.setMetaDescription(
        'Pokreni svoju omiljenu virtuelnu igru i kladi se online u svakom trenutku. Lucky six, trke pasa, trke konja i druge igre za još bolju zabavu. Čekamo te!'
      );
    }
    // TOURNAMENTS
    else if (url.includes('/tournament')) {
      this.setPageTitle('AdmiralBet|Najveći broj online slot turnira|Više od Igre');
      this.setMetaDescription('Pronađi svoj omiljeni turniri i osvoji vrijedne novčane nagrade. Čekamo te!');
    }
    // PROMOTIONS
    else if (url.includes('/promotions')) {
      this.setPageTitle('AdmiralBet|Nestvarne promocije za online sport i kazino|Više od Igre');
      this.setMetaKeywords('admiralbet promocije, freebet, promocija klađenja, besplatno klađenje');
      this.setMetaDescription(
        'Pronađi omiljenu promociju za sportsko klađenje, slotove i online kazino. Čekamo te!'
      );
    }
    // REGISTRATION
    else if (url.includes('/registration')) {
      this.setPageTitle('AdmiralBet|Registracija - Postani deo porodice|Više od Igre');
      this.setMetaDescription(
        'Registruj se i ostvari neverovatne bonuse samo na AdmiralBet.ba. Najbolje igre i najveće kvote su sada na samo nekoliko klikova od Vas. Čekamo te!'
      );
    }
    // DEPOSIT
    else if (url.includes('/profile/deposit')) {
      this.setPageTitle('AdmiralBet|Uplata sredstava|Više od igre');
      this.setMetaDescription(
        'Uplata sredstava, Kliker ili xBon uplate, Novabanka ili na nekom od uplatnih mesta AdmiralBet-a. Nikad lakše klađenje. Čekamo te!'
      );
    }
    // WITHDRAWAL
    else if (url.includes('/profile/withdrawal')) {
      this.setPageTitle('AdmiralBet|Isplata sredstava|Više od Igra');
      this.setMetaDescription(
        'Isplatite svoje zarade direktno na Vaš račun ili podignite na nekom od uplatnih mesta od AdmiralBet-a. Čekamo te!'
      );
    }
    // FREEBET HELP
    else if (url.includes('/help/category/133')) {
      this.setPageTitle('AdmiralBet|Freebet Pravila|Više od Igre');
      this.setMetaDescription(
        'Freebetovi, bonusi, besplatna klađenja, za novoregirstrovane, ali i stare igrače. Pročitajte kako možete koristiti svoje freebet-ove. Čekamo te!'
      );
    }
    // BONUS HELP
    else if (url.includes('/help/category/134')) {
      this.setPageTitle('AdmiralBet|Kako do bonusa|Više od igre');
      this.setMetaDescription(
        'AdmiralBet Vam je pripremio najbolje bonuse. Možete ih koristi na online slot ili virtualnim igrama. Čekamo te!'
      );
      // DEFAULT CONTENT
    } else {
      this.setMetaKeywords(
        'sportska, kladionica, klađenje, uzivo, bonusi, kvota, online, slot, kazino, fudbal, tenis, kosarka, sinot, pragmatic, fazi, freebet, freespin, admiral, live, casino, table, rulet, novomatic, admiralbet'
      );
      this.setMetaDescription(
        'Pridruži se AdmiralBet sportskoj kladionici i uvjeri se zašto smo prepoznati kao najbolja platforma za online klađenje. Više od igre!'
      );
    }
  }

  // set admiral croatia content
  private setAdmiralCroatiaContent(url): void {
    // CASINO PAGE
    if (url.includes('/casino')) {
      this.setPageTitle('Admiral casino | Najbolji online casino bonusi | Samo je jedan najbolji');
      this.setMetaKeywords(
        'online slot, rulet online, slot igre, casino igre, casino online, slotovi, besplatne vrtnje, najbolji casino, casino bonus, besplatne vrtnje, jackpot, casino hrvatska'
      );
      this.setMetaDescription(
        'Dođi na Admiral casino i preuzmi najveći bonus dobrodošlice, uz besplatne vrtnje! Čeka te vrhunska zabava, bogati turniri i dnevne nagrade. Igraj i osvajaj dobitke na Admiral Online casinu.'
      );
    }
    // SPORT LIVE
    else if (url.includes('/sport-live')) {
      this.setPageTitle('Admiral Bet kladionica | Klađenje uživo uz najbolju ponudu | Nova dimenzija zabave');
      this.setMetaKeywords(
        'kladionica, klađenje uživo, live klađenje, live koeficijenti, live tečajevi, rezultati, online kladionica, najveći tečajevi, loto'
      );
      this.setMetaDescription(
        'Najšira ponuda za najbolje iskustvo uživo klađenja. Iskoristi visoke tečajeve i sastavi dobitnu kombinaciju! Nova dimenzija zabave!'
      );
    }
    // SPORT
    else if (url.includes('/sport-prematch')) {
      this.setPageTitle('Admiral Bet Kladionica | Online klađenje uz najbolje tečajeve');
      this.setMetaKeywords(
        'kladionica, online kladionica, live klađenje, najbolji  tečajevi, kladionica admiral bet, najveći koeficijenti'
      );
      this.setMetaDescription(
        'Pridruži se Admiral Bet kladionici u uživaj u najširoj ponudi, najvećim koeficijentima i najboljim nagradama. Nova dimenzija zabave!'
      );
    }
    // LOTO
    else if (url.includes('/lotto')) {
      this.setPageTitle('Admiral casino | Loto | Samo je jedan najbolji');
      this.setMetaKeywords(
        'Online casino, jackpot, sportska, kladionica, klađenje, uzivo, bonusi, online, slot, casino, nogomet, tenis, kosarka, loto, pragmatic, fazi, najbolji casino, freebet, freespin, admiral, live, casino, rezultati, novomatic, admiral bet, admiral casino'
      );
      this.setMetaDescription(
        'Dođi na Admiral Casino, uđi u svijet vrhunske online zabave uz najveće bonuse, sjajne dobitke i vrijedne nagrade! Samo je jedan najbolji - Admiral Casino!'
      );
    }
    // TOURNAMENTS
    else if (url.includes('/tournament')) {
      this.setPageTitle(
        'Admiral casino | Online turniri s najvećim nagradama i bonusima | Samo je jedan najbolji'
      );
      this.setMetaDescription(
        'Pronađi turnir za sebe iz najšire ponude dnevnih, tjednih i mjsesečnih casino turnira. Natječi se, uzmi bonus i osvoji nagradu!'
      );
    }
    // PROMOTIONS
    else if (url.includes('/promotions')) {
      this.setPageTitle(
        'Admiral casino | Nestvarne promocije za online casino i sport | Samo je jedan najbolji'
      );
      this.setMetaKeywords(
        'admiral promocije, free spinovi, casino bonusi, cashback, besplatne vrtnje, free bet '
      );
      this.setMetaDescription('Izaberi casino promociju po svojoj mjeri. Uzmi bonus i besplatne vrtnje!');
    }
    // REGISTRATION
    else if (url.includes('/registration')) {
      this.setPageTitle('Admiral casino | Najbolji casino bonusi i nagrade | Registriraj se');
      this.setMetaDescription(
        'Registriraj se i ostvari najveći bonus na Admiral.hr! Najbolja zabava, besplatne vrtnje, najveći bonusi i sjajni dobici tu su za tebe!'
      );
    }
    // DEPOSIT
    else if (url.includes('/profile/deposit')) {
      this.setPageTitle('Admiral casino | Uplata depozita | Samo je jedan najbolji');
      this.setMetaDescription(
        'Uplati za igru VISA, Mastercard, Maesto, Revolut karticom, A-Bon-om ili Aircash aplikacijom. Koristi pogodnosti Admiral 360 u Admiral poslovnicama i bankomatima diljem Hrvatske. Nikad lakše do bonusa. Uplati i igraj!'
      );
    }
    // WITHDRAWAL
    else if (url.includes('/profile/withdrawal')) {
      this.setPageTitle('Admiral casino | Isplata sredstava | Samo je jedan najbolji');
      this.setMetaDescription(
        'Odaberi kako, kada i gdje želiš isplatu svojeg dobitka. Isplati na svoj bankovni ili Aircash račun, podigni gotovinu u Admiral casinu ili poslovnicama diljem Hrvatske. '
      );
    }
    // DEFAULT CONTENT
    else {
      this.setPageTitle('Admiral casino | Samo je jedan najbolji');
      this.setMetaKeywords(
        'Online casino, jackpot, sportska, kladionica, klađenje, uzivo, bonusi, online, slot, casino, nogomet, tenis, kosarka, loto, pragmatic, fazi, najbolji casino, freebet, freespin, admiral, live, casino, rezultati, novomatic, admiral bet, admiral casino'
      );
      this.setMetaDescription(
        'Dođi na Admiral Casino, uđi u svijet vrhunske online zabave uz najveće bonuse, sjajne dobitke i vrijedne nagrade! Samo je jedan najbolji - Admiral Casino!'
      );
    }
  }

  private setAdmiralMdShopContent(url): void {
    // SPORT
    if (url.includes('/sport-prematch')) {
      this.setPageTitle('MDShop - Kladionica');
      this.setMetaKeywords('mdshop, kladionica, sportsko klađenje');
      this.setMetaDescription(
        'Dobrodošli u MDShop - najbolju online platformu za sportsko klađenje, najveće kvote i bogati kazino. Registruj se i osjeti nezaboravnu zabavu.'
      );
    }
    // SPORT LIVE
    else if (url.includes('/sport-live')) {
      this.setPageTitle('MDShop - Uživo klađenje za brz dobitak');
      this.setMetaKeywords('uživo klađenje, live kvote, mdshop');
      this.setMetaDescription(
        'Najveće kvote na mečeve uživo! Široka ponuda svih sportova na jednom mjestu i kada mečevi počnu. Live za prave igrače.'
      );
    }
    // CASINO PAGE
    else if (url.includes('/casino')) {
      this.setPageTitle('MDShop - Online Casino');
      this.setMetaKeywords('online casino, kazino, slot, rulet');
      this.setMetaDescription(
        'Najmoderniji Online Casino sa preko petsto Slot igara, ruletom uživo, blackjackom i svim vašim omiljenim provajderima. Zauzmi svoje mjesto!'
      );
    }
    // VIRTUAL GAMES
    else if (url.includes('/virtual')) {
      this.setPageTitle('MDShop - Lucky Six i Aviator');
      this.setMetaKeywords('lucky six, aviator, trke pasa, virtuali');
      this.setMetaDescription(
        'Vaše omiljene virtualne igre od sada i na online. Lucky Six, Aviator, trke pasa su samo dio onoga što možete naći u MD Shopu.'
      );
    }
    // PROMOTIONS
    else if (url.includes('/promotions')) {
      this.setPageTitle('MDShop - Promocije, Bonusi i Freebet');
      this.setMetaKeywords('bonus, freebet, freespin, kladionice');
      this.setMetaDescription(
        'Slot turniri, bonusi, freebet, freespinovi su samo dio onoga što te očekuje na promo strani MDShop. Vaše je da započnete putovanje, a mi ćemo ga produžiti.'
      );
    }
    // DEFAULT CONTENT
    else {
      this.setMetaKeywords('mdshop, kladionica, sportsko klađenje');
      this.setMetaDescription(
        'Dobrodošli u MDShop - najbolju online platformu za sportsko klađenje, najveće kvote i bogati kazino. Registruj se i osjeti nezaboravnu zabavu.'
      );
    }
  }

  // set title
  public setPageTitle(pageTitle) {
    const title = pageTitle ? pageTitle : '';
    this.title.setTitle(title);
  }

  // set meta keywords
  public setMetaKeywords(keywords): void {
    this.meta.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  // set meta description
  public setMetaDescription(description): void {
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
  }
}
