import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BonusService } from '@services/bonus.service';
import { environment } from '../../../../../../environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-bonus-confirmation',
  templateUrl: './bonus-confirmation.dialog.html',
  styleUrls: ['./bonus-confirmation.dialog.scss'],
})
export class BonusConfirmationDialog implements OnInit {
  // bonus for accepting
  @Input() bonus;
  public clientName = environment.clientName;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public CLIENT_NAMES = CLIENT_NAMES;
  constructor(private activeModal: NgbActiveModal, private bonusService: BonusService) {}

  ngOnInit(): void {
    this.setRemainingTime();
  }

  // transfer minutes to hours and minutes and display that value
  private setRemainingTime(): void {
    if (this.bonus.redeemActiveMinutes > 60) {
      const hours = Math.floor(this.bonus.redeemActiveMinutes / 60);
      const minutes = this.bonus.redeemActiveMinutes % 60;
      this.bonus.redeemRemainingTime = hours + ' h ' + minutes + ' min';
    } else {
      this.bonus.redeemRemainingTime = this.bonus.redeemActiveMinutes + ' min';
    }
  }

  // close bonus confirmation modal
  public closeModal() {
    // refresh active-bonuses list in active bonuses component
    this.bonusService.refreshPromotionsActivityTab.next('active-bonuses');
    this.activeModal.close();
  }

  // accept bonus
  public acceptBonus(): void {
    this.bonus.accepted = true;
    delete this.bonus.type;
    this.bonus.active = true;
    this.bonusService.acceptBonus(this.bonus).subscribe(data => {
      this.closeModal();
    });
  }
}
