import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ANIMATION_DURATION,
  direction_t,
  IDisplayOdometer,
  IOdometerSpeedStarts,
} from '../utils/odometer.utils';

/**
 * Odometer v2 column
 *
 *
 * **** Take a look at odometer.utils.ts to see all the types and globals used ****
 *
 *
 * How it works : Starting value is set by the parent and it's displayed immediately.
 *                Each time the new odometer is set if forwards itself to the handleRequest function.
 *
 *                Handle request does what it needs and notifies the taskSubject that the new task should be handled.
 *
 *                TaskSubject is a BehaviorSubject<task_t> that activates the animations and manipulates the DOM.
 *                (We could use queue here but there is no need to do that subject is just easier to use and maintain.)
 */

type task_t = 'spinPlus' | 'spinMinus' | 'resetPlus' | 'resetMinus' | 'delete';

@Component({
  selector: 'app-odometer-v2-col',
  templateUrl: './odometer-v2-col.component.html',
  styleUrls: ['./odometer-v2-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OdometerV2ColComponent implements AfterViewInit {
  public currentValue = 0;
  public direction: direction_t;
  private taskSubject = new BehaviorSubject<task_t>(null);

  @ViewChild('display', { static: true }) display: ElementRef<HTMLDivElement>;

  public displayArray: number[] = [];
  @Input() digitHeight = 20;
  @Input() set startingValue(value: number) {
    this.currentValue = value;
  }
  @Input() set odometer(disOdometer: IDisplayOdometer) {
    this.handleRequest(disOdometer);
    this.direction = disOdometer.direction;
  }

  @Input() speedStarts: IOdometerSpeedStarts = {
    slow: Number.MAX_SAFE_INTEGER,
    fast: Number.MAX_SAFE_INTEGER,
    veryfast: Number.MAX_SAFE_INTEGER,
  };

  constructor(private renderer: Renderer2) {}

  handleRequest(displayOdometer: IDisplayOdometer) {
    const req = displayOdometer.odometerRequest;

    if (req === null) {
      // First time null is emitted bacause we used BehaviorSubject
      return;
    }

    // Number of empty loops that should be executed

    let emptyLoopCount = 0;
    switch (req.speed) {
      case 'slow': {
        // No need to add extra loops
        break;
      }
      case 'fast': {
        // Add some extra loops
        // Number of empty loops should be the diff of the preceding value (i.e. slow value)

        emptyLoopCount = displayOdometer.slowDiff || 2;

        break;
      }
      case 'veryfast': {
        // Add even more extra loops

        emptyLoopCount = 5;

        // Add even more extra loops based on position
        // odometerIndex is the index that keeps track of digits only

        /**
         * What this does:
         *
         * First  "veryfast" digits spin count = 5,
         * Second "veryfast" digits spin count = 6,
         * Third  "veryfast" digits spin count = 7,
         * ...
         */

        if (displayOdometer.odometerIndex > displayOdometer.speedStarts.veryfast) {
          emptyLoopCount += displayOdometer.odometerIndex - displayOdometer.speedStarts.veryfast;
        }

        break;
      }
      default: {
        console.error(
          `[Error: Speed is not valid type] Expected 'slow' | 'fast' | 'veryfast' but got ${req.speed}`
        );
        return;
      }
    }

    // We know the currentValue and we now the value that should be set
    // based on the number of steps we have to take

    // Lets generate the values

    if (displayOdometer.direction === 'plus') {
      // ANIMATION FROM BOTTOM
      let tmpValue = this.currentValue;
      const realLoopArray = [];

      for (let i = 0; i < req.steps; i++) {
        tmpValue += 1;
        if (tmpValue === 10) {
          tmpValue = 0;
        }

        realLoopArray.push(tmpValue);
      }

      if (emptyLoopCount > 0) {
        const lastIndex = realLoopArray.length - 1 < 0 ? 0 : realLoopArray.length - 1;
        let valueInRealValues = realLoopArray[lastIndex];
        /**
         *
         * If the array is empty just use the value that is alrady set
         * Case : We spin from 'X' to 'X'
         *
         */
        if (valueInRealValues === undefined) {
          valueInRealValues = this.currentValue;
        }

        let numInLoop = valueInRealValues;

        const singleEmptyLoop = [];
        for (let i = 0; i < 10; i++) {
          numInLoop++;
          if (numInLoop === 10) {
            numInLoop = 0;
          }
          singleEmptyLoop.push(numInLoop);
        }

        let combinedEmptyLoops = [];
        for (let i = 0; i < emptyLoopCount; i++) {
          combinedEmptyLoops = combinedEmptyLoops.concat(singleEmptyLoop);
        }

        realLoopArray.splice(lastIndex, 0, ...combinedEmptyLoops);
      }

      this.displayArray = realLoopArray;

      setTimeout(() => {
        this.taskSubject.next('resetPlus');
        setTimeout(() => {
          this.taskSubject.next('spinPlus');
        }, 100);
      }, 10);
    } else if (displayOdometer.direction === 'minus') {
      // ANIMATION FROM TOP

      let tmpValue = this.currentValue;
      const realLoopArray = [];

      for (let i = 0; i < req.steps; i++) {
        tmpValue -= 1;
        if (tmpValue === -1) {
          tmpValue = 9;
        }

        realLoopArray.push(tmpValue);
      }

      if (emptyLoopCount > 0) {
        const lastIndex = realLoopArray.length - 1 < 0 ? 0 : realLoopArray.length - 1;
        let valueInRealValues = realLoopArray[lastIndex];

        if (valueInRealValues === undefined) {
          valueInRealValues = this.currentValue;
        }

        let numInLoop = valueInRealValues;
        const singleEmptyLoop = [];
        for (let i = 0; i < 10; i++) {
          numInLoop++;
          if (numInLoop === 10) {
            numInLoop = 0;
          }
          singleEmptyLoop.push(numInLoop);
        }

        let combinedEmptyLoops = [];
        for (let i = 0; i < emptyLoopCount; i++) {
          combinedEmptyLoops = combinedEmptyLoops.concat(singleEmptyLoop);
        }

        realLoopArray.splice(lastIndex, 0, ...combinedEmptyLoops);
      }
      realLoopArray.reverse();

      this.displayArray = realLoopArray;
      setTimeout(() => {
        this.taskSubject.next('resetMinus');
        setTimeout(() => {
          this.taskSubject.next('spinMinus');
          if (displayOdometer.shouldBeDeleted) {
            setTimeout(() => {
              this.taskSubject.next('delete');
            }, ANIMATION_DURATION - 110);
          }
        }, 100);
      }, 10);
    }
  }

  ngAfterViewInit(): void {
    this.taskSubject.subscribe((task: task_t) => {
      if (task === null) {
        // First time null is emitted bacause we used BehaviorSubject
        return;
      }

      if (task === 'spinPlus') {
        let yOffset = this.display.nativeElement.getBoundingClientRect().height - this.digitHeight;
        yOffset = yOffset * -1;
        this.renderer.setStyle(this.display.nativeElement, 'transition', `all ${ANIMATION_DURATION - 110}ms`);
        this.renderer.setStyle(this.display.nativeElement, 'transform', `translateY(${yOffset}px)`);
      } else if (task === 'resetPlus') {
        this.renderer.removeStyle(this.display.nativeElement, 'transition');
        this.renderer.setStyle(this.display.nativeElement, 'transform', 'translateY(0px)');
      } else if (task === 'spinMinus') {
        this.renderer.setStyle(this.display.nativeElement, 'transition', `all ${ANIMATION_DURATION - 110}ms`);
        this.renderer.setStyle(this.display.nativeElement, 'transform', `translateY(0px)`);
      } else if (task === 'resetMinus') {
        let yOffset = this.display.nativeElement.getBoundingClientRect().height - this.digitHeight;
        yOffset = yOffset * -1;
        this.renderer.removeStyle(this.display.nativeElement, 'transition');
        this.renderer.setStyle(this.display.nativeElement, 'transform', `translateY(${yOffset}px)`);
      } else if (task === 'delete') {
        this.renderer.setStyle(this.display.nativeElement, 'display', `none`);
      }
    });
  }
}
