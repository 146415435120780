import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppService } from './app.service';
import { ConfigData } from '@models/config-data/config-data.model';
import { HelpersService } from '@services/helpers.service';
import { LocalStorageService } from '@services/local-storage.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from 'src/app/shared/enums/client-names.enum';
import { DEFAULT_THEME_COLORS } from 'src/css/client-colors/default-colors';
import { MD_SHOP_THEME_COLORS } from 'src/css/client-colors/md-shop-colors';
import { NIGERIA_THEME_COLORS } from 'src/css/client-colors/nigeria-colors';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppStateFacadeService } from '@state/app-state.facade';
import { of, fromEvent, merge } from 'rxjs';
import { CROATIA_THEME_COLORS } from 'src/css/client-colors/croatia-colors';
import { PlayerService } from '@services/player.service';
import { Player } from '@models/player.model';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';
import { BalanceInfo } from '@models/balance-info.model';
import { PINNBET_THEME_COLORS } from 'src/css/client-colors/pinnbet-colors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('snackBarTemplate', { static: true }) snackBarTemplate: TemplateRef<HTMLElement>;
  private isLoggedIn = this.localStorageService.isLoggedIn();
  public isConfigLoaded: boolean;
  public pushToken;
  private internetConnectionModal: NgbModalRef;
  public player: Player;

  constructor(
    private appService: AppService,
    private helperService: HelpersService,
    private localStorageService: LocalStorageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private playerService: PlayerService
  ) {
    this.loadStartUpFunctions();
  }

  private loadStartUpFunctions() {
    this.setProjectColorsByClient();
    this.setLeftSidebarPresenceStatus();
    this.setMobileFlag();
    this.setGoogleAnalytics();
    this.setReferalCodeToLocalStorage();
    if (this.isLoggedIn) {
      this.getPlayerData();
    }
  }

  ngOnInit(): void {
    merge(of(navigator.onLine), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe(data => {
        if (!data) {
          this.noInternetConnectionMessage(true);
        } else {
          this.noInternetConnectionMessage(false);
        }
      });
    this.getConfigData();
    this.setLocalStorageSubscription();
  }

  // set referal code to local storage if there is any
  private setReferalCodeToLocalStorage() {
    this.route.queryParams.subscribe(queryParams => {
      this.appStateFacadeService.setActiveQueryParams(queryParams);
      if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
        if (queryParams['admreferral']) {
          this.localStorageService.setReferalCode(queryParams['admreferral']);
        }
      } else {
        if (queryParams['referral']) {
          this.localStorageService.setReferalCode(queryParams['referral']);
        }
      }
    });
  }

  // set left sidebar presence status
  private setLeftSidebarPresenceStatus() {
    window.innerWidth >= 1200
      ? this.appStateFacadeService.setIsLeftSidebarPresent(true)
      : this.appStateFacadeService.setIsLeftSidebarPresent(false);
  }

  // initialy set isMobile flag to local storage so it can be used in some parts of project where we can't get isMobile flag
  private setMobileFlag(): void {
    const isMobile = this.helperService.isMobile();
    const isTablet = this.helperService.isTablet();
    this.localStorageService.setMobileFlag(isMobile);
    this.appStateFacadeService.setIsMobileStatus(isMobile);
    this.appStateFacadeService.setIsTabletStatus(isTablet);
  }

  // set tracker for google analytics
  private setGoogleAnalytics(): void {
    // send google analytics hit when page is changed(URL)
    const navigationEndEvents = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    navigationEndEvents.subscribe((event: NavigationEnd) => {
      if (!environment.gaKey) {
        return;
      } else {
        this.googleAnalyticsService.createTracker();
        this.googleAnalyticsService.setPage(event.urlAfterRedirects);
        this.googleAnalyticsService.sendHit();
      }
    });
  }

  // get config data
  private getConfigData(): void {
    this.appService.getConfigData().subscribe(configData => {
      if (configData) {
        this.isConfigLoaded = true;
        this.appStateFacadeService.setConfigData(new ConfigData(configData, this.helperService.isMobile()));
        this.appStateFacadeService.setDefaultAppUrl(configData.defaultAppUrl);
        this.appStateFacadeService.setBottomMobileMenu(configData.mobileMenus.mainMenu.menuItems);
        // Set verification services determine witch is enabled
        this.appStateFacadeService.setBlinkingServiceStatus(configData.verificationServices.blinkingService);
        this.appStateFacadeService.setDocumentVerificationServiceStatus(
          configData.verificationServices.docVerificationService
        );
        this.appStateFacadeService.setMicroBlinkingServiceStatus(
          configData.verificationServices.microBlinkService
        );
        // Set statuses for enabling/disabling Promo code and Refer-a-Friend functionality.
        this.appStateFacadeService.setPaymentPromoCodeStatus(configData.promotionCodes.paymentPromoCode);
        this.appStateFacadeService.setRegistrationPromoCodeStatus(
          configData.promotionCodes.registrationPromoCode
        );
        this.appStateFacadeService.setReferFriendCodeStatus(configData.promotionCodes.referFriendCode);
      }
    });
    this.appStateFacadeService.setClientName(environment.clientName);
  }

  /* This subscriptions is used to handle multiple browser tabs login/logout actions
   When user does login/logout on one tab, this listener will be trigered on all
   other tabs and page will be reloaded*/
  private setLocalStorageSubscription() {
    window.addEventListener(
      'storage',
      event => {
        if (event.key === 'handlingTabs') {
          location.reload();
        }
      },
      false
    );
  }

  private setProjectColorsByClient() {
    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_MD_SHOP:
        this.setProjectColors(MD_SHOP_THEME_COLORS);
        break;
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
        this.setProjectColors(NIGERIA_THEME_COLORS);
        break;
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        this.setProjectColors(CROATIA_THEME_COLORS);
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
        this.setProjectColors(PINNBET_THEME_COLORS);
        break;
      default:
        this.setProjectColors(DEFAULT_THEME_COLORS);
        break;
    }
  }

  private setProjectColors(themeColors) {
    themeColors.forEach(color => {
      document.documentElement.style.setProperty(
        '--' + Object.keys(color)[0],
        Object.values(color)[0].toString()
      );
    });

    // If CMS mode is enabled, set background color from CMS, there we can expand CMS config for future general settings
    if (environment.CMSElements) {
      this.appStateFacadeService
        .getConfigData()
        .pipe(
          filter((data: ConfigData) => !!data),
          take(1)
        )
        .subscribe((data: ConfigData) => {
          const backgroundColor = JSON.parse(data.generalV2).filter(
            (generalData: any) => generalData.active
          )[0].style.pageBackgroundColor.hex;
          document.documentElement.style.setProperty('--backgroundColor', backgroundColor);
        });
    }
  }

  private noInternetConnectionMessage(openCloseFlag) {
    if (openCloseFlag) {
      this.internetConnectionModal = this.modalService.open(this.snackBarTemplate, {
        centered: true,
        scrollable: true,
        size: 'md',
        backdrop: 'static',
      });
    } else if (this.internetConnectionModal) {
      this.internetConnectionModal.close();
    }
  }

  // get player data and dispatch to store
  private getPlayerData(): void {
    this.playerService.getPlayer().subscribe(player => {
      this.player = new Player(player);
      this.loadBalance();
      this.appStateFacadeService.setPlayerData(new Player(player));
    });
  }

  // get player balance and dispatch to store
  private loadBalance(): void {
    this.playerService.getPlayerBalance().subscribe(balanceInfo => {
      this.appStateFacadeService.setPlayerBalance(new BalanceInfo(balanceInfo));
    });
    if (this.player.externalId) {
      this.playerService.getAdmiral360PlayerBalance().subscribe(admiral360BalanceInfo => {
        this.appStateFacadeService.setAdmiral360BalanceInfo(new Admiral360BalanceInfo(admiral360BalanceInfo));
      });
    }
  }
}
