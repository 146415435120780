import { Injectable } from '@angular/core';
import {
  AppStateInterface,
  BalanceInfoInterface,
  MessagesInterface,
  webSocketActonInterface,
} from './interfaces/AppStateInterface.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Player } from '@models/player.model';
import { ConfigData } from '@models/config-data/config-data.model';
import { BalanceInfo } from '@models/balance-info.model';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';

@Injectable({
  providedIn: 'root',
})
export class AppState implements AppStateInterface {
  private clientName = '';
  private playerData = new BehaviorSubject<Player>(null as any);
  private configAppData = new BehaviorSubject<ConfigData>(null as any);
  private playerBalance = new BehaviorSubject<BalanceInfo>(null as any);
  private admiral360BalanceInfo = new BehaviorSubject<Admiral360BalanceInfo>(null as any);
  private balanceInfo = new BehaviorSubject<BalanceInfoInterface>({
    playerBalanceInfo: null,
    admiral360BalanceInfo: null,
  });
  private messages = new BehaviorSubject<MessagesInterface>({
    total: 0,
    records: [],
  });

  private socketAction = new BehaviorSubject<webSocketActonInterface>(null as any);

  private currentUrl = new BehaviorSubject('');
  private previousUrl = new BehaviorSubject('');
  private lastRoutes = new BehaviorSubject([]);
  private isMobile = new BehaviorSubject(false);
  private isTablet = new BehaviorSubject(false);
  private mobileBottomMenuItems = new BehaviorSubject([]);
  private mobileBottomSecondaryMenuState = new Subject<boolean>();
  private logoImgPath = new BehaviorSubject('');
  private isleftSidebarPresent = new BehaviorSubject(false);
  private leftSideBarExpand = new BehaviorSubject(false);
  private rightSideBarExpand = new BehaviorSubject(false);
  private overlayShadeStatus = new BehaviorSubject(false);
  private mainCarouselVisible = new BehaviorSubject(false);
  private activeQueryParams = new BehaviorSubject<{ [key: string]: string }>({});
  private chatWindowWasOpenBeforeBarExpand = false;
  private chatWindowOpen = false;
  private particleSystemControl = new BehaviorSubject({ active: false, type: 'confetti' });
  private isSearchActive = new BehaviorSubject<boolean>(false);
  private searchedGames = new BehaviorSubject<any>([]);
  private currentSearchValue = new BehaviorSubject<string>('');
  private scrollToSearch = new Subject<void>();
  private defaultAppUrl = new BehaviorSubject<string>('');
  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // private appDownloadPopup = new BehaviorSubject(false);
  // private appQuickBarStatus = new BehaviorSubject(false);
  private nxcsLoginData = new BehaviorSubject(null as any);
  private blinkingService = new BehaviorSubject(null);
  private documentVerificationService = new BehaviorSubject(null);
  private microBlinkingService = new BehaviorSubject(null);
  private referFriendCodeStatus = new BehaviorSubject<boolean | null>(null);
  private registrationPromoCodeStatus = new BehaviorSubject<boolean>(false);
  private paymentPromoCodeStatus = new BehaviorSubject<boolean>(false);
  private luckyWheelResult = new Subject();

  private playerDataObservable = this.playerData.asObservable();
  private configAppDataObservable = this.configAppData.asObservable();
  private balanceInfoObservable = this.balanceInfo.asObservable();
  private messagesObservable = this.messages.asObservable();
  private socketActionObservable = this.socketAction.asObservable();
  private currentUrlObservable = this.currentUrl.asObservable();
  private mobileBottomMenuItemsObservable = this.mobileBottomMenuItems.asObservable();
  private mobileBottomSecondaryMenuStateObservable = this.mobileBottomSecondaryMenuState.asObservable();
  private leftSideBarObservable = this.leftSideBarExpand.asObservable();
  private rightSideBarObservable = this.rightSideBarExpand.asObservable();
  private overlayShadeStatusObservable = this.overlayShadeStatus.asObservable();
  private mainCarouselVisibleObservable = this.mainCarouselVisible.asObservable();
  private activeQuerryParamsObservable = this.activeQueryParams.asObservable();
  private particleSystemControlObservable = this.particleSystemControl.asObservable();
  private isSearchActiveObservable = this.isSearchActive.asObservable();
  private searchedGamesObservable = this.searchedGames.asObservable();
  private currentSearchValueObservable = this.currentSearchValue.asObservable();
  private scrollToSearchObservable = this.scrollToSearch.asObservable();
  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // private appDownloadPopupObservable = this.appDownloadPopup.asObservable();
  // private appQuickBarStatusObservable = this.appQuickBarStatus.asObservable();
  private nxcsLoginDataObservable = this.nxcsLoginData.asObservable();
  private blinkingServiceObservable = this.blinkingService.asObservable();
  private documentVerificationServiceObservable = this.documentVerificationService.asObservable();
  private microBlinkingServiceObservable = this.microBlinkingService.asObservable();
  private referFriendCodeStatusObservable = this.referFriendCodeStatus.asObservable();
  private registrationPromoCodeStatusObservable = this.registrationPromoCodeStatus.asObservable();
  private paymentPromoCodeStatusObservable = this.paymentPromoCodeStatus.asObservable();
  private luckyWheelResultObservable = this.luckyWheelResult.asObservable();

  setPlayerData(playerData) {
    this.playerData.next(playerData);
  }

  getPlayerData(): Player {
    return this.playerData.value;
  }

  getPlayerDataObservable() {
    return this.playerDataObservable;
  }

  setMessages(messages: MessagesInterface) {
    this.messages.next(messages);
  }

  getMessagesObservable(): Observable<MessagesInterface> {
    return this.messagesObservable;
  }

  getMessages(): MessagesInterface {
    return this.messages.value;
  }

  setConfigData(configData: ConfigData) {
    this.configAppData.next(configData);
  }

  getConfigDataObservable(): Observable<ConfigData> {
    return this.configAppDataObservable;
  }

  setSocketAction(socketAction: webSocketActonInterface) {
    this.socketAction.next(socketAction);
  }

  getSocketActionObservable(): Observable<webSocketActonInterface> {
    return this.socketActionObservable;
  }

  setBalanceInfo(data: BalanceInfoInterface): void {
    this.balanceInfo.next({
      playerBalanceInfo: data.playerBalanceInfo,
      admiral360BalanceInfo: data.admiral360BalanceInfo,
    });
  }
  getBalanceInfo(): Observable<BalanceInfoInterface> {
    return this.balanceInfoObservable;
  }

  setPlayerBalanceInfo(balance: BalanceInfo) {
    this.playerBalance.next(balance);
  }

  getPlayerBalanceInfo(): BalanceInfo {
    return this.playerBalance.value;
  }

  setPlayerAdmiral360BalanceInfo(admiral360BalanceInfo: Admiral360BalanceInfo) {
    this.admiral360BalanceInfo.next(admiral360BalanceInfo);
  }

  getPlayerAdmiral360BalanceInfo(): Admiral360BalanceInfo {
    return this.admiral360BalanceInfo.value;
  }

  // URLs
  setCurrentUrl(currentUrl): void {
    this.currentUrl.next(currentUrl);
  }

  getCurrentUrl(): string {
    return this.currentUrl.value;
  }

  observeCurrentUrl() {
    return this.currentUrlObservable;
  }

  setPreviousUrl(previousUrl): void {
    this.previousUrl.next(previousUrl);
  }

  getPreviousUrl(): string {
    return this.previousUrl.value;
  }

  setLastRoutes(lastRoutes: string[]): void {
    this.lastRoutes.next(lastRoutes);
  }

  getLastRoutes(): string[] {
    return this.lastRoutes.value;
  }

  // MOBILE
  setIsMobileStatus(boolean): void {
    this.isMobile.next(boolean);
  }

  getIsMobileStatus(): boolean {
    return this.isMobile.value;
  }

  setIsTabletStatus(boolean): void {
    this.isTablet.next(boolean);
  }

  getIsTabletStatus() {
    return this.isTablet.value;
  }

  setBottomMobileMenu(menuItems) {
    this.mobileBottomMenuItems.next([...menuItems]);
  }

  getBottomMobileMenu() {
    return this.mobileBottomMenuItemsObservable;
  }

  setBottomMenuSecondaryMenuState(newState: boolean = false) {
    this.mobileBottomSecondaryMenuState.next(newState);
  }

  getBottomMenuSecondaryMenuState() {
    return this.mobileBottomSecondaryMenuStateObservable;
  }

  //LOGO
  setLogoImgPath(logoImgPath): void {
    this.logoImgPath.next(logoImgPath);
  }

  getLogoImgPath(): string {
    return this.logoImgPath.value;
  }

  // SIDEBARS
  setIsLeftSidebarPresent(boolean): void {
    this.isleftSidebarPresent.next(boolean);
  }

  getIsLeftSidebarPresent(): boolean {
    return this.isleftSidebarPresent.value;
  }

  setLeftSideBarStatus(boolean): void {
    if (this.leftSideBarExpand.value !== boolean) {
      this.leftSideBarExpand.next(boolean);
    }
  }

  getLeftSideBarStatus(): boolean {
    return this.leftSideBarExpand.value;
  }

  observeLeftSideBarStatus() {
    return this.leftSideBarObservable;
  }

  setRightSideBarStatus(boolean): void {
    if (this.rightSideBarExpand.value !== boolean) {
      this.rightSideBarExpand.next(boolean);
    }
  }

  getRightSideBarStatus(): boolean {
    return this.rightSideBarExpand.value;
  }

  observeRightSideBarStatus() {
    return this.rightSideBarObservable;
  }

  setOverlayShadeStatus(boolean): void {
    this.overlayShadeStatus.next(boolean);
  }

  observeOverlayShadeStatus() {
    return this.overlayShadeStatusObservable;
  }

  // CAROUSEL
  setMainCarouselVisibility(newState: boolean): void {
    this.mainCarouselVisible.next(newState);
  }

  observeMainCarouselVisibility() {
    return this.mainCarouselVisibleObservable;
  }

  // ACTIVE QUERY PARAMS
  setActiveQueryParams(activeQuerryParams): void {
    this.activeQueryParams.next(activeQuerryParams);
  }

  getActiveQueryParams(): any {
    return this.activeQueryParams.value;
  }

  observeActiveQueryParams() {
    return this.activeQuerryParamsObservable;
  }

  // CHAT
  setChatWindowWasOpenBeforeBarExpand(boolean) {
    this.chatWindowWasOpenBeforeBarExpand = boolean;
  }

  getChatWindowWasOpenBeforeBarExpand() {
    return this.chatWindowWasOpenBeforeBarExpand;
  }

  setOpenChatWindow(boolean) {
    this.chatWindowOpen = boolean;
  }

  isChatWindowOpen(): boolean {
    return this.chatWindowOpen;
  }

  // PARTICLES
  setParticleSystemControl(active: boolean, type?: string) {
    const previousValue = this.particleSystemControl.value;
    const setState = Object.assign({}, previousValue, {
      active,
      type: type ? type : previousValue.type,
    });
    this.particleSystemControl.next(setState);
  }

  observeParticleSystemControl() {
    return this.particleSystemControlObservable;
  }

  // SEARCH STATUS
  setIsSearchActive(nextSearchStatus: boolean): void {
    this.isSearchActive.next(nextSearchStatus);
  }

  getIsSearchActive(): boolean {
    return this.isSearchActive.value;
  }

  observeSearchActiveStatus(): Observable<boolean> {
    return this.isSearchActiveObservable;
  }

  // SEARCHED GAMES
  // TODO Add valid type
  setSearchedGames(nextSearchedGames: any): void {
    this.searchedGames.next(nextSearchedGames);
  }

  observeSearchedGames(): Observable<any> {
    return this.searchedGamesObservable;
  }

  // CURRENT SEARCH
  setCurrentSearchValue(newSearch: string) {
    this.currentSearchValue.next(newSearch);
  }

  observeCurrentSearchValue(): Observable<string> {
    return this.currentSearchValueObservable;
  }

  setScrollToSearch(): void {
    this.scrollToSearch.next();
  }

  observeScrollToSearch(): Observable<void> {
    return this.scrollToSearchObservable;
  }

  setDefaultAppUrl(defaultAppUrl: string): void {
    this.defaultAppUrl.next(defaultAppUrl);
  }

  getDefaultAppUrl(): string {
    return this.defaultAppUrl.value;
  }

  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // setAppDownloadPopup(boolean): void {
  //   this.appDownloadPopup.next(boolean);
  // }
  // setAppQuickBarStatus(boolean): void {
  //   this.appQuickBarStatus.next(boolean);
  // }

  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // observeAppDownloadPopup() {
  //   return this.appDownloadPopupObservable;
  // }
  // observeQuickBarStatus() {
  //   return this.appQuickBarStatusObservable;
  // }

  setClientName(clientName) {
    this.clientName = clientName;
  }

  getClientName() {
    return this.clientName;
  }

  setNxcsLoginData(socketData: any) {
    this.nxcsLoginData.next(socketData);
  }

  getNxcsLoginDataObservable(): Observable<any> {
    return this.nxcsLoginDataObservable;
  }

  setBlinkingService(status) {
    this.blinkingService.next(status);
  }

  getBlinkingService(): Observable<boolean> {
    return this.blinkingServiceObservable;
  }

  setDocumentVerificationService(status) {
    this.documentVerificationService.next(status);
  }

  getDocumentVerificationService(): Observable<boolean> {
    return this.documentVerificationServiceObservable;
  }

  setMicroBlinkingService(status) {
    this.microBlinkingService.next(status);
  }

  getMicroBlinkingService(): Observable<boolean> {
    return this.microBlinkingServiceObservable;
  }

  setReferFriendCodeStatus(status: boolean): void {
    this.referFriendCodeStatus.next(status);
  }

  getReferFriendCodeStatusObservable(): Observable<boolean> {
    return this.referFriendCodeStatusObservable;
  }

  setRegistrationPromoCodeStatus(status: boolean): void {
    this.registrationPromoCodeStatus.next(status);
  }

  getRegistrationPromoCodeStatusObservable(): Observable<boolean> {
    return this.registrationPromoCodeStatusObservable;
  }

  setPaymentPromoCodeStatus(status: boolean): void {
    this.paymentPromoCodeStatus.next(status);
  }

  getPaymentPromoCodeStatusObservable(): Observable<boolean> {
    return this.paymentPromoCodeStatusObservable;
  }

  setLuckyWheelResult(result: any): void {
    this.luckyWheelResult.next(result);
  }

  getLuckyWheelResultObservable(): Observable<any> {
    return this.luckyWheelResultObservable;
  }
}
