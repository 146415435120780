<div
  class="relative"
  [ngClass]="{ 'pinnbet-custom-select-container': clientName === CLIENT_NAMES.ADMIRAL_PINNBET }"
  #wrapper
>
  <div class="relative">
    <button
      type="button"
      class="custom-select fake-form-control"
      #input
      [ngClass]="{
        'is-invalid': fcontrol.invalid && fcontrol.dirty && showStyles.invalid,
        'is-valid': fcontrol.valid && fcontrol.dirty && showStyles.valid
      }"
      [disabled]="fcontrol.disabled"
    >
      {{ keyWordObservable | async }}
    </button>

    <i
      class="icon icon-cross"
      *ngIf="(keyWordObservable | async) && fcontrol.disabled && showCloseBtn"
      (click)="onClearInput()"
    ></i>
  </div>

  <div
    class="cdk-wrapper"
    [ngClass]="{
      hidden: !isDropdownVisible,
      'orientation-bottom': !orientationTop,
      'orientation-top': orientationTop
    }"
  >
    <ng-container *ngIf="isDropdownVisible">
      <ng-container *ngIf="itemsObservable | async as items">
        <div *ngIf="items.length">
          <cdk-virtual-scroll-viewport
            [itemSize]="heightOfOneItem"
            class="list-container"
            [maxBufferPx]="300"
            [minBufferPx]="300"
            [ngStyle]="{
              height: (scrollContainerHeightObservable | async) + 'px'
            }"
          >
            <app-custom-select-item
              data-role="list-item"
              [itemValue]="item"
              *cdkVirtualFor="let item of items; let i = index"
              class="item"
              [item]="item"
              (click)="onSelect(item)"
              [ngClass]="{ active: i === arrowKeyLocation }"
            >
            </app-custom-select-item>
          </cdk-virtual-scroll-viewport>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
