export class Registration {
  public minYearAge: number;
  public maxYearAge: number;
  public isSmsVerificationMandatory: boolean;

  constructor(data: any) {
    this.minYearAge = data && data.minYearAge;
    this.maxYearAge = data && data.maxYearAge;
    this.isSmsVerificationMandatory = data && data.isSmsVerificationMandatory;
  }
}
