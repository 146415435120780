<div [ngClass]="{ 'lucky-wheel-mobile': isMobile }">
  <div class="wheel-container" [ngClass]="{ mobile: isMobile }" *ngIf="selectedWheel">
    <!-- WHEEL STAND -->
    <svg
      class="wheel-stand"
      [ngClass]="{ mobile: isMobile }"
      width="296"
      height="112"
      viewBox="0 0 296 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M147 0H206C215.5 -2.03401e-06 225.5 10.5 231 19.5C235.395 26.6925 237.216 33.7053 239.046 40.7539C240.269 45.4652 241.497 50.1925 243.5 55C247.5 64.6 252.833 69.3333 255 70.5L149.5 70.5L147 70.5L41.5 70.5C43.6667 69.3333 49 64.6 53 55C55.0031 50.1925 56.2305 45.4652 57.4538 40.7539C59.2838 33.7053 61.1046 26.6925 65.5 19.5C71 10.5 81 5.59539e-06 90.5 7.62939e-06L147 0Z"
        fill="url(#stand-0)"
      />
      <path
        d="M0 110C0 87.9086 17.9086 70 40 70H256C278.091 70 296 87.9086 296 110V112H0V110Z"
        fill="url(#stand-1)"
      />
    </svg>
    <!-- <img class="wheel-stand" [ngClass]="{'mobile': isMobile}" src="/assets/images/wheel-of-fortune/wheel_stand.svg"
            alt="Wheel Stand"> -->
    <!-- WHEEL -->
    <svg class="fortune-wheel" [ngClass]="{ mobile: isMobile }" viewBox="0 0 100 100">
      <!-- DEFINISIONS -->
      <defs>
        <filter id="spinBtnOutlineShadow" x="-50%" y="-50%" width="200%" height="200%">
          <!-- dx=0  dy=0 for full circle shadow -->
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>

        <filter id="redRingTopShadow">
          <feDropShadow dx="0" dy="-3" stdDeviation="1" />
        </filter>

        <filter id="inset-shadow-bottom">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0 
                      0 0 0 0 0 
                      0 0 0 0 0 
                      0 0 0 100 0"
            result="opaque-source"
          />
          <feGaussianBlur stdDeviation="3" />
          <feOffset dy="-2" />
          <feComposite operator="xor" in2="opaque-source" />
          <feComposite operator="in" in2="opaque-source" />
          <feComposite operator="over" in2="SourceGraphic" />
        </filter>

        <filter id="inset-shadow-top">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0 
                      0 0 0 0 0 
                      0 0 0 0 0 
                      0 0 0 100 0"
            result="opaque-source"
          />
          <feGaussianBlur stdDeviation="5" />
          <feOffset dy="4" />
          <feComposite operator="xor" in2="opaque-source" />
          <feComposite operator="in" in2="opaque-source" />
          <feComposite operator="over" in2="SourceGraphic" />
        </filter>

        <linearGradient id="needle-gradient-horizontal" x1="0%" y1="0%" x2="0%" y2="60%">
          <stop offset="0%" [attr.stop-color]="selectedWheel.colors.needleDarkShadow" />
          <stop offset="50%" [attr.stop-color]="selectedWheel.colors.needleDarkShadow" />
          <stop offset="50%" [attr.stop-color]="selectedWheel.colors.needleColor" />
          <stop offset="100%" [attr.stop-color]="selectedWheel.colors.needleColor" />
        </linearGradient>

        <linearGradient id="needle-gradient-vertical" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" [attr.stop-color]="selectedWheel.colors.needleDarkShadow" />
          <stop offset="50%" [attr.stop-color]="selectedWheel.colors.needleDarkShadow" />
          <stop offset="50%" [attr.stop-color]="selectedWheel.colors.needleColor" />
          <stop offset="100%" [attr.stop-color]="selectedWheel.colors.needleColor" />
        </linearGradient>

        <linearGradient id="btn-outer-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" [attr.stop-color]="selectedWheel.colors.spinButtonLightShadow" />
          <stop offset="50%" [attr.stop-color]="selectedWheel.colors.spinButtonColor" />
          <stop offset="100%" [attr.stop-color]="selectedWheel.colors.spinButtonDarkShadow" />
        </linearGradient>

        <linearGradient id="btn-inner-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" [attr.stop-color]="selectedWheel.colors.spinButtonDarkShadow" />
          <stop offset="50%" [attr.stop-color]="selectedWheel.colors.spinButtonColor" />
          <stop offset="100%" [attr.stop-color]="selectedWheel.colors.spinButtonLightShadow" />
        </linearGradient>

        <linearGradient id="stand-0" x1="148.25" y1="0" x2="148.25" y2="70.5" gradientUnits="userSpaceOnUse">
          <stop offset="0.26" [attr.stop-color]="selectedWheel.colors.standDarkShadow" />
          <stop offset="1" [attr.stop-color]="selectedWheel.colors.standColor" />
        </linearGradient>

        <linearGradient id="stand-1" x1="148" y1="70" x2="148" y2="112" gradientUnits="userSpaceOnUse">
          <stop offset="0.055" [attr.stop-color]="selectedWheel.colors.standLightShadow" />
          <stop offset="0.245" [attr.stop-color]="selectedWheel.colors.standColor" />
          <stop offset="1" [attr.stop-color]="selectedWheel.colors.standDarkShadow" />
        </linearGradient>
      </defs>
      <!-- MAIN RING -->
      <circle
        id="red-ring"
        cx="50"
        cy="50"
        r="45"
        stroke-width="10"
        fill="none"
        [attr.stroke]="selectedWheel.colors.frameColor"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="50"
        [attr.fill]="selectedWheel.colors.frameColor"
        [attr.stroke]="selectedWheel.colors.frameColor"
        stroke-width="0.1"
        filter="url(#inset-shadow-bottom)"
      ></circle>
      <circle
        class="opacity-04"
        cx="50"
        cy="50"
        r="44.5"
        fill="#000"
        stroke="#000"
        stroke-width="0.1"
        filter="url(#redRingTopShadow)"
      ></circle>
      <!-- WHEEL -->
      <g id="wheel-element" filter="url(#inset-shadow-top)">
        <!-- PRIZES -->
        <path
          *ngFor="let prize of selectedWheel.prizes; let i = index"
          [attr.d]="createSvgPath(prize)"
          [attr.fill]="prize.color"
        ></path>
        <ng-container *ngFor="let prize of selectedWheel.prizes; let i = index">
          <!-- TEXT IN PRIZE -->
          <text
            *ngIf="prize.displayText && prize.icon"
            id="prize_value_{{ prize.id }}"
            [attr.transform]="calculateTextTransform(prize, 25)"
            text-anchor="middle"
            dy=".3em"
            font-size="4"
            font-weight="500"
            fill="#fff"
            font-family="Alfa Slab One"
          >
            {{ prize.displayText }}
          </text>
          <!-- TEXT IN THE MIDDLE -->
          <text
            *ngIf="prize.displayText && !prize.icon"
            id="prize_value_{{ prize.id }}"
            [attr.transform]="calculateTextTransform(prize, 30)"
            text-anchor="middle"
            dy=".3em"
            font-size="4"
            font-weight="500"
            fill="#fff"
            font-family="Alfa Slab One"
          >
            {{ prize.displayText }}
          </text>
          <!-- ICON IN PRIZE ON END OF CIRCLE POSITION-->
          <svg:image
            *ngIf="prize.icon && prize.displayText"
            [attr.xlink:href]="baseUrl + prize.icon"
            x="-3"
            y="-3"
            height="6"
            width="6"
            [attr.transform]="calculateTextTransform(prize, 40)"
          ></svg:image>
          <!-- ICON IN PRIZE IN THE MIDDLE -->
          <svg:image
            *ngIf="prize.icon && !prize.displayText"
            [attr.xlink:href]="baseUrl + prize.icon"
            x="-3"
            y="-3"
            height="6"
            width="6"
            [attr.transform]="calculateTextTransform(prize, 30)"
          ></svg:image>
        </ng-container>

        <!-- PINS -->
        <circle
          id="pin_{{ i }}"
          *ngFor="let prize of selectedWheel.prizes; let i = index"
          [attr.cx]="calculatePinPosition(prize, 44.5).x"
          [attr.cy]="calculatePinPosition(prize, 44.5).y"
          r="0.6"
          fill="#000"
        ></circle>
      </g>
      <!-- SPIN BUTTON -->
      <g class="pointer">
        <!-- OUTER CIRCLE -->
        <circle
          cx="50"
          cy="50"
          r="11"
          filter="url(#spinBtnOutlineShadow)"
          fill="url(#btn-outer-gradient)"
        ></circle>
        <!-- MAIN BTN CIRCLE -->
        <circle id="spinButton" cx="50" cy="50" r="9" fill="url(#btn-inner-gradient)"></circle>
        <!-- TAP TO SPIN IMAGE -->
        <image
          id="pulseImage"
          (click)="spinTheWheel()"
          xlink:href="assets/images/wheel-of-fortune/tap_to_spin.webp"
          x="40"
          y="40"
          height="20px"
          width="20px"
          [ngClass]="{
            'display-none': !selectedWheel.playerInWheelSegment || !selectedWheel.isSpinAvailable
          }"
        ></image>
        <!-- SPIN BTN TEXT -->
        <text
          id="spinBtnText"
          x="50"
          y="50"
          text-anchor="middle"
          dominant-baseline="middle"
          font-weight="700"
          font-size="3px"
          fill="#fff"
        ></text>
        <svg:image class="display-none" id="spinBtnIcon" x="47" y="47" height="6" width="6"></svg:image>
      </g>
      <!-- NIDDLE -->
      <g id="needle">
        <polygon points="50,0 52,2 50,8 50,8 48,2" fill="url(#needle-gradient-horizontal)"></polygon>
        <polygon
          points="50,0 52,2 50,8 50,8 48,2"
          fill="url(#needle-gradient-vertical)"
          opacity="0.5"
        ></polygon>
      </g>
      <!-- <g id="needle">
            <polygon points="50,0 53,3 50,10 50,10 47,3" fill="url(#needle-gradient-horizontal)">
            </polygon>
            <polygon points="50,0 53,3 50,10 50,10 47,3" fill="url(#needle-gradient-vertical)" opacity="0.5">
            </polygon>
        </g> -->
      <!-- LIGHT BALLS CIRCLE STROKE -->
      <!-- <circle *ngFor="let light of lightBalls; let i = index" [attr.cx]="calculateLightBallsCoordinates(i, 47.3).cx"
            [attr.cy]="calculateLightBallsCoordinates(i, 47.3).cy" fill="#666" r="1.2" stroke="#fff" stroke-width="0.3"
            transform="rotate(270 50 50)">
        </circle> -->
      <!-- LIGHT BALLS CIRCLE -->
      <circle
        id="lightBalls"
        *ngFor="let light of lightBalls; let i = index"
        [style]="{ '--lbSpeed': lightBallsSpeed + 's' }"
        [attr.cx]="calculateLightBallsCoordinates(i, 47.3).cx"
        [attr.cy]="calculateLightBallsCoordinates(i, 47.3).cy"
        r="1.2"
        fill="#666"
        [style.animation-delay]="i * ((lightBallsSpeed * 2) / lightBalls.length) + 's'"
        class="light-balls-circle"
        transform="rotate(270 50 50)"
      ></circle>
    </svg>
  </div>
  <!-- AVAILABLE WHEELS -->
  <app-available-lucky-wheels
    [ngClass]="{ disabled: wheelSpinning }"
    (detectChange)="detectChange()"
    (selectWheel)="selectWheel($event)"
    [luckyWheelResultData]="luckyWheelResultData"
  ></app-available-lucky-wheels>
</div>
