import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient) {}

  /**
   * Get config data
   */
  public getConfigData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'start');
  }

  /**
   * External api call
   * @param path - path (url from composeProductUrl() method in transaction service)
   * @param params - query parameters ( language, token)
   */
  public externalServiceGetMethod(path: string, params): Observable<any> {
    return this.http.get(path, { params });
  }
}
