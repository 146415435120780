<!-- DRAGGABLE CONTAINER -->
<div *ngIf="player && clientName !== CLIENT_NAMES.ADMIRAL_CROATIA">
  <app-draggable-component></app-draggable-component>
</div>
<!-- LEFT MENU -->
<app-left-menu
  *ngIf="clientName !== CLIENT_NAMES.ADMIRAL_PINNBET && !environment.CMSElements"
  [player]="player"
></app-left-menu>
<app-left-menu-cms *ngIf="environment.CMSElements" [player]="player"></app-left-menu-cms>

<!-- RIGHT MENU -->
<app-right-menu
  *ngIf="clientName !== CLIENT_NAMES.ADMIRAL_CROATIA && !environment.CMSElements"
  [player]="player"
></app-right-menu>
<app-right-menu-cms
  *ngIf="environment.CMSElements && clientName !== CLIENT_NAMES.ADMIRAL_CROATIA"
  [player]="player"
></app-right-menu-cms>

<app-right-menu-cro
  *ngIf="player && clientName === CLIENT_NAMES.ADMIRAL_CROATIA && !environment.CMSElements"
  [player]="player"
></app-right-menu-cro>
<app-right-menu-cro-cms
  *ngIf="player && environment.CMSElements && clientName === CLIENT_NAMES.ADMIRAL_CROATIA"
  [player]="player"
></app-right-menu-cro-cms>

<section [ngClass]="{ content: clientName !== CLIENT_NAMES.ADMIRAL_PINNBET }">
  <!-- HEADER -->
  <div class="sticky-top">
    <app-header
      *ngIf="
        ![CLIENT_NAMES.ADMIRAL_CROATIA, CLIENT_NAMES.ADMIRAL_PINNBET].includes(clientName) &&
        !environment.CMSElements
      "
      [player]="player"
    ></app-header>
    <app-header-pinnbet
      *ngIf="clientName === CLIENT_NAMES.ADMIRAL_PINNBET && !environment.CMSElements"
      [player]="player"
    ></app-header-pinnbet>
    <app-header-cro
      *ngIf="clientName === CLIENT_NAMES.ADMIRAL_CROATIA && !environment.CMSElements"
    ></app-header-cro>
    <app-header-cms *ngIf="environment.CMSElements" [player]="player"></app-header-cms>
  </div>

  <!-- APP DOWNLOAD -->
  <!--  TODO when definitely quick bar pass testing delete commented old download app implementation search ALL code and clean with key [AB-3839]-->
  <!--  <app-download-popup-->
  <!--    *ngIf="isMobile && showDownloadPopup && downloadAppClients.includes(clientName)"-->
  <!--    (showAppDownloadPopup)="showAppDownloadPopup($event)"-->
  <!--  ></app-download-popup>-->

  <app-quick-bar></app-quick-bar>

  <!-- MAIN CONTENT -->
  <router-outlet></router-outlet>

  <!-- FOOTER -->
  <app-footer-cms *ngIf="environment.CMSElements"></app-footer-cms>
  <app-footer *ngIf="clientName !== CLIENT_NAMES.ADMIRAL_CROATIA && !environment.CMSElements"></app-footer>
  <app-footer-cro
    *ngIf="clientName === CLIENT_NAMES.ADMIRAL_CROATIA && !environment.CMSElements"
  ></app-footer-cro>
  <!-- PARTICLES FOR JACKPOT -->
  <app-particle-system [typeOfParticles]="'confetti'"></app-particle-system>

  <!-- MOBILE BOTTOM MENU -->
  <div class="sticky-bottom" *ngIf="mobileMenuVisible | async">
    <ng-container *ngIf="clientName === CLIENT_NAMES.ADMIRAL_PINNBET; else otherPlatformsBottomMenu">
      <app-mobile-bottom-menu-pinnbet [setActivePage]="mobileBottomRoute"></app-mobile-bottom-menu-pinnbet>
    </ng-container>
    <ng-template #otherPlatformsBottomMenu>
      <app-mobile-bottom-menu [setActivePage]="mobileBottomRoute"></app-mobile-bottom-menu>
    </ng-template>
  </div>
</section>

<!--Overlay Object which is activated on menu expand-->
<!--!!! IMPORTANT !!! Scroll event on body is controlled by this component-->
<app-overlay-shade></app-overlay-shade>

<!-- COOKIES -->
<app-cookies
  *ngIf="
    ![CLIENT_NAMES.ADMIRAL_CROATIA, CLIENT_NAMES.ADMIRAL_SERBIA, CLIENT_NAMES.ADMIRAL_PINNBET].includes(
      clientName
    )
  "
></app-cookies>
