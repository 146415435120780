<div
  [ngClass]="{
    'bigger-margin': lockedAmount,
    'pinnbet-progress-bar': environment.clientName === clientNames.ADMIRAL_PINNBET
  }"
  class="progressBarWrapper"
>
  <span
    [ngClass]="{ noLabel: !label, dropdownEnabled: lockedItemsLength }"
    *ngIf="totalAmount"
    class="percentageContainer"
  >
    {{ percentage | number : '1.2-2' }} %
  </span>
  <div *ngIf="label" class="ratioLabel">{{ labelShouldUseTranslatePipe ? (label | translate) : label }}</div>
  <ng-container *ngIf="totalAmount && totalAmount !== shareAmount; else wholeSumAvailable">
    <span class="relationContainer">
      ({{ shareAmount | number : '1.2-2' }} /
      {{ totalAmount | number : '1.2-2' | currency : currency }})</span
    >
  </ng-container>
  <div class="ratioWrapper">
    <div #progressBarContainer class="ratioContainer">
      <ng-template #wholeSumAvailable>
        <span class="relationContainer" *ngIf="totalAmount && totalAmount === shareAmount; else noAmount">
          {{ totalAmount | number : '1.2-2' | currency : currency }}
        </span>
      </ng-template>

      <ng-template #noAmount>
        <div (click)="navigate()" [ngClass]="{ linkActive: navigationUrl }" class="noAmount pointer">
          {{ noAmountMessage | translate }}
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="lockedAmount" class="lockedAmountContainer">
    {{ 'KEEP_UNLOCKING' | translate }}
    <span>{{ lockedAmount | number : '1.2-2' | currency : currency }}</span>
  </div>
</div>
