import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Player } from '@models/player.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Bank, Banks } from '@models/bank.model';
import { Deposit } from '@models/deposit.model';
import { IpsDepositService } from '@services/ips-deposit.service';
import { PaymentType } from '@models/ips-deposit.model';
import { WithdrawalService } from '@services/withdrawal.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { TranslateService } from '@ngx-translate/core';
import { DEPOSIT_CODES } from '@enums/deposit-codes.enum';
import { DepositService } from '@services/deposit.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@services/toaster.service';

@Component({
  selector: 'app-quick-bar-ips-bank-account-deposit',
  templateUrl: './quick-bar-ips-bank-account-deposit.component.html',
  styleUrls: ['./quick-bar-ips-bank-account-deposit.component.scss'],
})
export class QuickBarIpsBankAccountDepositComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chooseSavedBankModal', { static: true }) chooseSavedBankModal: any;
  public deposit: Deposit;

  public banksList: Bank[];
  public selectedBank: Bank;

  @Input() playerData!: Player;
  @Input() clientName!: string;
  @Input() expandCollapseQuickBar!: (stateOveride?) => void;
  @Input() closeQuickBar!: () => void;
  @Input() stopIntervalChangeItemsInBar!: () => void;
  @Input() paymentSuccess!: () => void;

  @Input() isMobile = true;
  @Input() depositAmount!: number;
  @Input() paymentProceed!: boolean;
  public modalRef: NgbModalRef;
  private depositSubscription: Subscription;
  private timeLeftSubscription: Subscription;
  public timeLeft: number; // In seconds.
  public deepLink: string;
  private isDepositFinishedSubscription: Subscription;
  public patchBanksSubject = new BehaviorSubject('');

  constructor(
    private toasterService: ToasterService,
    private depositService: DepositService,
    private ipsDepositService: IpsDepositService,
    private withdrawalService: WithdrawalService,
    private appStateFacadeService: AppStateFacadeService,
    private translateService: TranslateService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.isMobile = this.appStateFacadeService.getIsMobileStatus();
    this.setDepositSubscription();
    this.getBanks();
    this.getDepositConfig();
    this.checkActivePaymentState();
    this.setTimeLeftSubscription();
    this.setDepositFinishedSubscription();
  }

  ngOnDestroy(): void {
    this.depositSubscription.unsubscribe();
    this.timeLeftSubscription.unsubscribe();
    this.isDepositFinishedSubscription.unsubscribe();
  }

  private getDepositConfig(): void {
    this.depositService.getDeposit(DEPOSIT_CODES.IPS_DEPOSIT).subscribe((deposit: Deposit) => {
      if (deposit) {
        this.deposit = deposit;
        this.ipsDepositService.setDepositConfig(deposit);
      } else {
        this.router.navigate(['/profile/deposit']);
      }
    });
  }

  private checkActivePaymentState(): void {
    const activePaymentData = this.ipsDepositService.getActivePaymentData();
    if (activePaymentData && activePaymentData.paymentType === PaymentType.DEEP_LINK) {
      this.selectedBank = activePaymentData.bank;
      this.deepLink = activePaymentData.paymentData;
    }
  }

  private setDepositSubscription() {
    this.depositSubscription = this.ipsDepositService
      .getDepositConfigObservable()
      .subscribe((depositConfig: Deposit) => {
        this.deposit = depositConfig;
      });
  }

  private getBanks(): void {
    this.withdrawalService.getBanks().subscribe((data: Banks) => {
      this.banksList = data.records;
      const activePaymentData = this.ipsDepositService.getActivePaymentData();
      const selectedBankName =
        activePaymentData && activePaymentData.paymentType === PaymentType.DEEP_LINK
          ? activePaymentData.bank.name
          : this.translateService.instant('CHOOSE_BANK');
      this.patchBanksSubject.next(selectedBankName);
    });
  }

  private setTimeLeftSubscription(): void {
    this.timeLeftSubscription = this.ipsDepositService
      .getTimeLeftObservable()
      .subscribe((timeLeft: number) => {
        if (timeLeft !== null) {
          this.timeLeft = timeLeft;
          if (timeLeft === 0) {
            // When countdown is finished, enable form fields.
            this.deepLink = '';
          }
        }
      });
  }

  private setDepositFinishedSubscription(): void {
    this.isDepositFinishedSubscription = this.ipsDepositService
      .getIsDepositFinishedObservable()
      .subscribe((isDepositFinished: string) => {
        if (isDepositFinished) {
          // TODO vrati na prvu komponentu
          this.toasterService.showSuccess('Payment successful');
          this.expandCollapseQuickBar();
          this.deepLink = '';
          this.ipsDepositService.resetDepositFinishedValue();
        }
      });
  }

  // Disable amount and bank fields and initiate countdown.
  private startCountdown(): void {
    this.ipsDepositService.initCountdown({
      paymentType: PaymentType.DEEP_LINK,
      amount: this.depositAmount.toString(),
      bank: this.selectedBank,
      paymentData: this.deepLink,
      rpReference: this.ipsDepositService.extractRpReference(this.deepLink, 'productTxnId%3D', '%26'),
    });
  }

  public openModalToChoseBank() {
    this.modalRef = this.modalService.open(this.chooseSavedBankModal, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: true,
    });

    this.scrollToSelectedBank();
  }

  // In modal when a bank is selected, scroll modal list on a selected bank
  scrollToSelectedBank() {
    // Delay execution to ensure DOM updates
    setTimeout(() => {
      const selectedBankElement = document.querySelector('.select-bank-option--is-selected');
      if (selectedBankElement) {
        selectedBankElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }, 0); // Timeout to ensure the DOM has updated
  }

  public choseBankFromModal(index: number) {
    this.selectedBank = this.banksList[index];
    this.modalRef.close();
  }

  public createDeepLinkAndStartCountDown() {
    if (this.depositAmount && this.selectedBank) {
      const dataToSend = {
        amount: this.depositAmount,
        bankId: this.selectedBank.id,
      };
      this.ipsDepositService.generateIpsDepositData(this.deposit.url, true, dataToSend).subscribe({
        next: data => {
          this.deepLink = data.message;
          this.startCountdown();
        },
      });
    }
  }

  public onDepositDeepLink(): void {
    window.open(this.deepLink, '_blank');
  }
}
