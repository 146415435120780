import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Deposit } from '@models/deposit.model';
import { Withdrawal } from '@models/withdrawal.model';
import { HelpersService } from '@services/helpers.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-card-detail-code-value',
  templateUrl: './payment-card-detail-code-value.component.html',
  styleUrls: ['./payment-card-detail-code-value.component.scss'],
})
export class PaymentCardDetailCodeValueComponent implements OnInit, OnDestroy {
  @Input() payment: Deposit | Withdrawal;
  @Input() paymentForm: FormGroup;
  @Input() minDigitsLength: any;
  @Input() maxDigitsLength: any;
  @Input() buttonText: string;
  @Input() paymentTandCLink: string;
  @Input() paymentTandCLabel: string;
  @Input() showPromoCode = false;

  public isPaymentPromoCodeEnabled = false;
  // Subscriptions
  private paymentPromoCode$: Subscription;

  constructor(private helpersService: HelpersService, private appStateFacadeService: AppStateFacadeService) {}

  ngOnInit(): void {
    this.paymentPromoCode$ = this.appStateFacadeService
      .getPaymentPromoCodeStatusObservable()
      .subscribe(status => (this.isPaymentPromoCodeEnabled = status));
  }

  ngOnDestroy(): void {
    this.paymentPromoCode$.unsubscribe();
  }

  onlyNumbersAllow(input) {
    this.paymentForm
      .get(input.target.getAttribute('formControlName'))
      .setValue(this.helpersService.allowOnlyNumbers(input.target.value));
  }

  public get voucherCode(): AbstractControl {
    return this.paymentForm.get('voucherCode');
  }
}
