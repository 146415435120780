<div
  *ngIf="deposit"
  class="ips-payment-wrapper"
  [ngClass]="{ 'ips-payment-wrapper--is-deep-link': deepLink }"
>
  <div>
    {{ 'DEEP_LINK_TITLE' | translate }}
  </div>
  <div>
    <!-- BANKS -->
    <div class="form-group">
      <div class="row">
        <div class="col-12">
          <label class="label required">
            {{ 'BANK' | translate }}
          </label>

          <div class="u_desktop-flex-column">
            <div
              class="bank-preview"
              [ngClass]="{ 'bank-preview--is-disabled': deepLink }"
              (click)="openModalToChoseBank()"
            >
              <div>
                <ng-container *ngIf="!selectedBank; else selectedBankTemplate">{{
                  'CHOOSE_BANK' | translate
                }}</ng-container>
                <ng-template #selectedBankTemplate>{{ selectedBank.name }}</ng-template>
              </div>
              <div class="aztec-arrow-wrapper">
                <ng-template *ngTemplateOutlet="chevronLeftIcon"></ng-template>
              </div>
            </div>

            <div class="text-center p-2" *ngIf="this.selectedBank && !deepLink">
              <button
                class="item-button"
                (click)="createDeepLinkAndStartCountDown(); stopIntervalChangeItemsInBar()"
              >
                {{ 'PAY_NOW' | translate }}
              </button>
            </div>

            <ng-container *ngIf="deepLink">
              <!-- TIMER -->
              <div class="text-center">
                {{ 'LINK_EXPIRES' | translate }} {{ timeLeft * 1000 | date : 'mm:ss' }}
              </div>
              <!-- DEPOSIT BUTTON -->
              <div class="text-center">
                <button (click)="onDepositDeepLink()" class="item-button item-button--is-follow-link">
                  {{ 'IPS_DEPOSIT' | translate }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #chooseSavedBankModal>
  <div class="box select-bank-dialog">
    <div class="c_list-wrapper">
      <ng-container *ngFor="let bank of banksList; let i = index">
        <div
          class="select-bank-option"
          [ngClass]="{ 'select-bank-option--is-selected': bank.identifier === selectedBank?.identifier }"
          (click)="choseBankFromModal(i)"
        >
          <div>{{ bank.name }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #chevronLeftIcon>
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path class="aztec-arrow" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </svg>
</ng-template>
