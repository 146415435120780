<div id="cookieContainer" *ngIf="isNotificationVisible && !cookieAccepted">
  <div class="cookieHeader">
    <div class="text-center">{{ 'COOKIES' | translate }}</div>
    <div class="text-right custom-margin">
      <img
        class="pointer"
        [src]="environment.imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
        (click)="closeCookieNotification()"
        alt="Cookies popup close button"
      />
    </div>
  </div>

  <div class="cookieContent">
    {{ 'COOKIES_DESCRIPTION' | translate }}
    <a class="link" (click)="navigate('POLICY')">{{ 'COOKIES_POLICY' | translate }}</a>
    {{ 'COOKIES_P_AND_P' | translate }}
    <a class="link" (click)="navigate('PRIVACY-POLICY')">{{ 'COOKIES_PRIVACY_POLICY' | translate }}</a
    >.
  </div>

  <div class="d-flex justify-content-center align-content-center align-items-center">
    <a [class]="btnClass" class="btn btn-rounded btn-sm" (click)="acceptCookies()">
      <span class="nav-user-text">{{ 'I_UNDERSTAND' | translate }}</span>
    </a>
  </div>
</div>
