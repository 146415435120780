import { Bank } from './bank.model';

// Deposit timer, in seconds.
export const IPS_DEPOSIT_TIMER = 290;

export enum PaymentType {
  DEEP_LINK = 'DEEP_LINK',
  QR_CODE = 'QR_CODE',
}

export interface IpsDeposit {
  paymentType: PaymentType;
  amount: string;
  bank?: Bank;
  paymentData: string; // QR code or deep link.
  timeLeft?: number; // Current countdown value to store in local storage, before page reload.
  rpReference: string; // Reference ID: productTxnId (Deep Link) | RP (QR code)
}
