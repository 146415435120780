import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbAccordionModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CustomSelectModule } from './components/custom-select/custom-select.module';
import { OdometerV2Module } from './components/odometer-v2/odometer-v2.module';
import { MatIconModule } from '@angular/material/icon';
import { QuickBarModule } from '../layout/quick-bar/quick-bar.module';

// COMPONENTS
import { BootstrapAlertComponent } from './components/bootstrap-alert/bootstrap-alert.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { TransactionFilterComponent } from './components/transaction-filter/transaction-filter.component';
import { GameSliderComponent } from './components/game-slider/game-slider.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { MbetAccordionComponent } from './components/mbet-accordian/mbet-accordion.component';
import { Admiral360Component } from './components/admiral360/admiral360.component';
import { ImageWrapperComponent } from './components/image-wrapper/image-wrapper.component';
import { ProductTransactionsComponent } from './components/transaction-product-component/product-transactions/product-transactions.component';
import { TransactionsHeaderComponent } from './components/transaction-product-component/transactions-header/transactions-header.component';
import { CustomOdometerComponent } from './components/custom-odometer/custom-odometer.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { CustomRadioComponent } from './components/custom-radio/custom-radio.component';
import { ParticleSystemComponent } from './components/particle-system/particle-system.component';
import { LobbyGameComponent } from '@modules/lobby/lobby-game/lobby-game.component';
import { LobbyGamePageComponent } from '@modules/lobby/lobby-game-page/lobby-game-page.component';
import { JackpotComponent } from './components/jackpot/jackpot.component';
import { PaymentCardComponent } from './components/payments/payment-card/payment-card.component';
import { PaymentCardDetailHeaderComponent } from './components/payments/payment-card-detail-header/payment-card-detail-header.component';
import { PaymentCardDetailPredefinedValuesComponent } from './components/payments/payment-card-detail-predefined-values/payment-card-detail-predefined-values.component';
import { PaymentCardDetailCodeValueComponent } from './components/payments/payment-card-detail-code-value/payment-card-detail-code-value.component';
import { ButtonsBarComponent } from './components/buttons-bar/buttons-bar.component';
import { ShowMorePaginationButtonComponent } from './components/show-more-pagination-button/show-more-pagination-button.component';
import { AdmiralJackpotComponent } from './components/jackpot-standalone/angular-web-component/src/app/admira-jackpot/admiral-jackpot.component';
import { ReservedFundsComponent } from './components/reserved-funds/reserved-funds.component';
import { CountDownCounterComponent } from './components/count-down-counter/count-down-counter.component';
import { OverlayModule } from '@angular/cdk/overlay';

// PIPES
import { SafePipe } from '../core/pipes/safe.pipe';
import { CurrencyPipe } from '../core/pipes/currency.pipe';
import { SanitizeHtmlPipe } from '../core/pipes/sanitize-html.pipe';
import { FirstLetterPipe } from '../core/pipes/first-letter.pipe';
import { UtcOffsetPipe } from '../core/pipes/utc-offset.pipe';

// DIALOGS
import { CorvusDepositStatusDialog } from './dialogs/corvus-status-dialog/corvus-deposit-status.dialog';
import { BonusConfirmationDialog } from '../modules/profile/modules/promotions-activity/bonus-confirmation-dialog/bonus-confirmation.dialog';
import { ConfirmationDialog } from './dialogs/confirmation-dialog/confirmation.dialog';
import { TokenExpirationDialog } from './dialogs/token-expiration-dialog/token-expiration.dialog';
import { BonusFreebetWarningDialog } from './dialogs/bonus-freebet-warning-dialog/bonus-freebet-warning.dialog';
import { InfoDialog } from './dialogs/info-dialog/info.dialog';
import { AllsecureDepositStatusDialog } from './dialogs/allsecure-deposit-status-dialog/allsecure-deposit-status.dialog';
import { MessagesPopupDialog } from './dialogs/messages-popup/messages-popup.dialog';
import { PinnbetMessagesPopupDialog } from './dialogs/messages-popup-pinnbet/pinnbet-messages-popup.dialog';
import { KlikerXbonDepositStatusDialog } from './dialogs/kliker-xbon-deposit-status-dialog/kliker-xbon-deposit-status.dialog';
import { MobileGamePlayDialog } from './dialogs/mobile-game-play/mobile-game-play.dialog';
import { DepositStatusDialog } from './dialogs/deposit-status-dialog/deposit-status.dialog';
import { WithdrawalStatusDialog } from './dialogs/withdrawal-status-dialog/withdrawal-status.dialog';
import { InfoLeaderboardDialog } from './dialogs/info-leaderboard/info-leaderboard.dialog';
import { RedirectInfoDialog } from './dialogs/redirect-info-dialog/redirect-info.dialog';
import { SecurityQuestionDialog } from './dialogs/security-question-dialog/security-question.dialog';
import { PaystackDepoistStatusDialog } from './dialogs/paystack-deposit-status.dialog.html/paystack-deposit-status.dialog';
import { ConfirmCancelDialog } from './dialogs/confirm-cancel-dialog/confirm-cancel.dialog';
import { CustomDialog } from './dialogs/custom-dialog/custom.dialog';
import { IbanHelperDialog } from './dialogs/iban-helper-dialog/iban-helper.dialog';

// DIRECTIVE
import { AppendImageDirective } from './directive/append-image.directive';
import { AdmiralButtonDirective } from './directive/admiral-button.directive';
import { DelayLoadDirective } from './directive/delay-load.directive';

import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { TournamentInfoComponent } from './components/tournament-info/tournament-info.component';
import { TournamentGamesComponent } from './components/tournament-games/tournament-games.component';
import { TermsPersonalDataComponent } from '@modules/registrations/croatia-registration/terms-personal-data/terms-personal-data.component';
import { TermsConditionsTextComponent } from './components/terms-conditions-text/terms-conditions-text.component';
import { WorldCupComponent } from './components/world-cup/world-cup.component';
import { LiveDocumentScannerComponent } from './components/live-document-scanner/live-document-scanner.component';
import { UploadDocumentScannerComponent } from './components/upload-document-scanner/upload-document-scanner.component';
import { PoliticalExposureFormComponent } from './components/political-exposure-form/political-exposure-form.component';
import { PinnbetLobbyGameComponent } from '@modules/lobby/pinnbet/lobby-game/lobby-game.component';
import { PinnbetTournamentSliderComponent } from '@modules/tournament/pinnbet-tournament/tournament-slider/tournament-slider.component';
import { LanguageChooserV2Component } from './components/language-chooser-v2/language-chooser-v2.component';
import { LuckyWheel } from './components/lucky-wheel/lucky-wheel.component';
import { DraggableComponent } from './components/draggable/draggable.component';
import { JackpotV2Component } from './components/jackpot-v2/jackpot-v2.component';
import { AvailableLuckyWheelsComponent } from './components/lucky-wheel/available-lucky-wheels/available-lucky-wheels.component';
import { RewardInformationBarComponent } from '@modules/registrations/croatia-registration-v2/reward-information-bar/reward-information-bar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularMaterialModule } from '@modules/angular-material/angular-material.module';
import { StaticPageComponent } from './components/static-page/static-page.component';
import { EuroCupComponent } from './components/euro-cup/euro-cup.component';
import { TransactionDateFilterComponent } from './components/transaction-date-filter/transaction-date-filter.component';
import { SafeNumberPipe } from '../core/pipes/safeNumber.pipe';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  NgxPaginationModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  CustomSelectModule,
  InlineSVGModule,
  OdometerV2Module,
  OverlayModule,
  MatIconModule,
  DragDropModule,
  AngularMaterialModule,
  QuickBarModule,
];

const DIRECTIVE = [AppendImageDirective, AdmiralButtonDirective, DelayLoadDirective];

const COMPONENTS = [
  BootstrapAlertComponent,
  CarouselComponent,
  FileUploadComponent,
  TransactionFilterComponent,
  GameSliderComponent,
  LanguagePickerComponent,
  LanguageChooserV2Component,
  NoDataComponent,
  CookiesComponent,
  MbetAccordionComponent,
  CustomOdometerComponent,
  Admiral360Component,
  ImageWrapperComponent,
  TransactionsHeaderComponent,
  ProductTransactionsComponent,
  SlideshowComponent,
  PinnbetTournamentSliderComponent,
  ProgressBarComponent,
  LeafletMapComponent,
  // TODO kada napravis modul za kompnentu importovaces je kao modul
  // CustomCheckboxComponent,
  CustomRadioComponent,
  ParticleSystemComponent,
  LobbyGameComponent,
  PinnbetLobbyGameComponent,
  LobbyGamePageComponent,
  JackpotComponent,
  PaymentCardComponent,
  PaymentCardDetailHeaderComponent,
  PaymentCardDetailPredefinedValuesComponent,
  PaymentCardDetailCodeValueComponent,
  ButtonsBarComponent,
  AdmiralJackpotComponent,
  ShowMorePaginationButtonComponent,
  ReservedFundsComponent,
  LeaderboardComponent,
  TournamentInfoComponent,
  TournamentGamesComponent,
  TermsPersonalDataComponent,
  TermsConditionsTextComponent,
  WorldCupComponent,
  StaticPageComponent,
  LiveDocumentScannerComponent,
  UploadDocumentScannerComponent,
  PoliticalExposureFormComponent,
  CountDownCounterComponent,
  LuckyWheel,
  AvailableLuckyWheelsComponent,
  DraggableComponent,
  JackpotV2Component,
  RewardInformationBarComponent,
  EuroCupComponent,
  TransactionDateFilterComponent,
];

const DIALOGS = [
  BonusConfirmationDialog,
  ConfirmationDialog,
  CorvusDepositStatusDialog,
  TokenExpirationDialog,
  BonusFreebetWarningDialog,
  InfoDialog,
  AllsecureDepositStatusDialog,
  MessagesPopupDialog,
  PinnbetMessagesPopupDialog,
  KlikerXbonDepositStatusDialog,
  MobileGamePlayDialog,
  DepositStatusDialog,
  WithdrawalStatusDialog,
  InfoLeaderboardDialog,
  PaystackDepoistStatusDialog,
  RedirectInfoDialog,
  SecurityQuestionDialog,
  ConfirmCancelDialog,
  CustomDialog,
  IbanHelperDialog,
];

const PIPES = [SafePipe, CurrencyPipe, SanitizeHtmlPipe, FirstLetterPipe, UtcOffsetPipe, SafeNumberPipe];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, ...DIALOGS, ...DIRECTIVE],
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...PIPES, ...DIALOGS, ...DIRECTIVE],
})
export class SharedModule {}
