class Color {
  id: number;
  hex: string;
  name: string;

  constructor(data: any) {
    this.id = data.id;
    this.hex = data.hex;
    this.name = data.name;
  }
}

export class CmsGeneralRightBarSettings {
  primaryColor: Color;
  secondaryColor: Color;
  backgroundColor: Color;
  depositBtnBorderColor: Color;
  depositBtnColor: Color;
  depositBtnHoverColor: Color;
  depositBtnTextColor: Color;
  logoutBtnBorderColor: Color;
  logoutBtnColor: Color;
  logoutBtnHoverColor: Color;
  logoutBtnTextColor: Color;
  menuItemTextActiveColor: Color;
  menuItemTextColor: Color;
  menuItemTextHoverColor: Color;
  tertiaryColor: Color;

  getThisKeys() {
    return Object.keys(this);
  }

  constructor(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== null) {
        this[key] = new Color(data[key]);
      }
    }
    this.tertiaryColor = new Color(data.secondaryColor);
  }
}
