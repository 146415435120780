import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

/*
 * THIS IS DEFAULT CONFIRMATION DIALOG WITH CONFIRM OR CANCEL BUTTON
 * IT SHOULD BE USED JUST TO CONFIRM OR CANCEL SOMETHING!!!
 *
 * It accept translated title and message.
 *
 * Confirm send 'true', cancel 'false' to 'modalRef.result' event
 * in component from where dialog is called and in there you should
 * put rest of logic that you need. After that it close it self.
 */

@Component({
  selector: 'app-confirm-cancel-dialog',
  templateUrl: './confirm-cancel.dialog.html',
  styleUrls: ['./confirm-cancel.dialog.scss'],
})
export class ConfirmCancelDialog {
  public imagesBaseUrl = environment.imagesBaseUrl;

  constructor(private activeModal: NgbActiveModal) {}

  @Input() title;
  @Input() message;

  public closeDialog(type) {
    this.activeModal.close(type);
  }
}
