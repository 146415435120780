<div class="box">
  <header>
    <div class="row">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{ data.title }}</div>
      </div>
      <div class="col-3 text-right">
        <div *ngIf="!data.closeButtonDeactivated && !data.isCloseMarkBig" class="close">
          <img
            *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
            [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
            (click)="closeDialog(false)"
            alt="Confirmation dialog popup close button"
          />
          <div
            *ngIf="clientName === clientNames.ADMIRAL_PINNBET && !data.isCloseMarkBig"
            class="u_pointer"
            (click)="closeDialog(false)"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
        <div (click)="closeDialog(false)" *ngIf="data.isCloseMarkBig" class="big-close">
          <div
            inlineSVG="/assets/icons/close_cross.svg"
            [setSVGAttributes]="{ height: '17px', width:'17px'}"
          ></div>
        </div>
      </div>
    </div>
  </header>
  <!-- TEXT MESSAGE -->
  <div *ngIf="data.message">
    <div
      *ngIf="data.status && !data.innerHTML"
      class="descParagraph text-center status-success {{ data.status ? 'status-' + data.status.toLowerCase() : ''}}"
    >
      {{ data.message }}
    </div>
    <div *ngIf="!data.status && !data.innerHTML" class="descParagraph text-center">{{ data.message }}</div>
    <div
      *ngIf="data.innerHTML"
      class="descParagraph text-center {{ data.status ? 'status-' + data.status.toLowerCase() : ''}}"
      [innerHTML]="data.message | sanitizeHtml"
    ></div>

    <ng-container *ngIf="data.links">
      <ng-container *ngFor="let link of data.links">
        <div class="text-center p-3">
          <div>{{ link.linkDescription }}</div>
          <div class="mt-2">
            <a [href]="link.url">
              <div class="{{link.linkClass ? link.linkClass : ''}}">{{ link.linkText }}</div>
            </a>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- RENDER PASSED COMPONENT -->
  <ng-container #componentViewContainer></ng-container>

  <!-- INPUTS -->
  <div *ngIf="data.inputs" class="descParagraph border-t">
    <div class="row" *ngFor="let input of data.inputs">
      <div class="col-6">{{input.key}}</div>
      <div class="col-6 text-right">{{input.value}}</div>
    </div>
  </div>

  <!-- CHECKBOX -->
  <div *ngIf="data.checkbox" class="check-box border-t">
    <div class="check-box_item" *ngFor="let box of data.checkbox">
      <input
        type="checkbox"
        class="checkboxInput-{{box.id}}"
        [name]="box.label"
        (click)="setCheckboxResult(box)"
      />
      <label [for]="box.label">{{ box.label }}</label>
    </div>
  </div>

  <!-- BUTTONS -->
  <div *ngIf="data.buttons" class="btns border-t">
    <div
      class="btn-div"
      [ngClass]="{ 'disabled': disableButton }"
      *ngFor="let button of data.buttons"
      (click)="closeDialog(button.on_click)"
      class="btn btn-{{button.type}}"
    >
      <div class="text-uppercase">{{ button.label }}</div>
      <div *ngIf="button.label_input">{{ button.label_input }}</div>
    </div>
  </div>
</div>
