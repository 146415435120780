import { Component, Input, OnDestroy } from '@angular/core';
import { coinFallAnimation, switchAnimationChange } from '../../animations/animations';
import { Subscription, interval, switchMap, take, timer } from 'rxjs';
import { JackpotService } from '@services/jackpot.service';
import { HelpersService } from '@services/helpers.service';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '@services/local-storage.service';
import { JackpotModel, JackpotWinners } from '@models/jackpot.model';

const JACKPOT_ANIMATION_DURATION = 5000;

@Component({
  selector: 'app-jackpot-v2',
  templateUrl: './jackpot-v2.component.html',
  styleUrls: ['./jackpot-v2.component.scss'],
  animations: [switchAnimationChange('250ms'), coinFallAnimation()],
})
export class JackpotV2Component implements OnDestroy {
  public triggerOrigin: any;
  public isLoggedIn = this.localStorageService.isLoggedIn();
  private p_gameId: number;
  private timer$: Subscription = null;
  public isOpen = false;
  public clientName = environment.clientName;
  public currentJackpotWinners: JackpotWinners | null;
  public isMobileWinnerListOpen: boolean;
  public isCroClient = CLIENT_NAMES.ADMIRAL_CROATIA === environment.clientName;
  public CLIENT_NAMES = CLIENT_NAMES;
  @Input() isGameFavorite: boolean;
  @Input() gameName: boolean;
  @Input() set gameId(newGameId: number) {
    if (newGameId === null || isNaN(newGameId)) {
      return;
    }
    this.p_gameId = newGameId;
    this.getInitialJackpotsByGame();
  }
  public jackpots: JackpotModel[];
  public currentIndex = 0;
  public isMobile = this.helpersService.isMobile();

  private intervalApiCall$: Subscription;
  private jackpotChangeInterval$: Subscription;
  private timeBaseSwitchInterval$: Subscription;

  constructor(
    private jackpotService: JackpotService,
    private helpersService: HelpersService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnDestroy(): void {
    if (this.intervalApiCall$) {
      this.intervalApiCall$.unsubscribe();
    }
    if (this.jackpotChangeInterval$) {
      this.jackpotChangeInterval$.unsubscribe();
    }
    if (this.timeBaseSwitchInterval$) {
      this.timeBaseSwitchInterval$.unsubscribe();
    }
  }

  private getInitialJackpotsByGame() {
    this.jackpotService.getActiveJackpotsByGame(this.p_gameId).subscribe((data: JackpotModel[]) => {
      if (data.length) {
        this.jackpots = data;
        this.currentJackpotWinners = this.jackpots[this.currentIndex].jackpotWinners;
        this.setTimeBaseChangeInterval();
        if (this.isMobile) {
          this.setJackpotChangeInterval();
        }
        this.checkForJackpotValueChanges(this.p_gameId);
      } else {
        this.jackpots = [];
      }
    });
  }

  private setJackpotChangeInterval() {
    const jackpotTimingInterval =
      this.jackpots.length === 1 ? JACKPOT_ANIMATION_DURATION + 250 : (JACKPOT_ANIMATION_DURATION + 250) * 2;
    this.jackpotChangeInterval$ = interval(jackpotTimingInterval).subscribe(() => {
      this.isMobileWinnerListOpen = false;
      if (this.jackpots.length > 1) {
        this.jackpots.push(this.jackpots.shift());
      }

      if (this.jackpots.length !== 0) {
        this.currentJackpotWinners = this.jackpots[this.currentIndex].jackpotWinners;
      }

      if (this.timer$ !== null) {
        this.timer$.unsubscribe();
        this.timer$ = null;
      }

      this.timer$ = timer(0, JACKPOT_ANIMATION_DURATION)
        .pipe(take(2))
        .subscribe(() => {
          if (!this.jackpots[this.currentIndex].timeBased) {
            this.checkForCurrentlyDisplayedJackpotValueChange(this.jackpots[this.currentIndex].id);
          }
        });
    });
  }

  private checkForJackpotValueChanges(gameId: number) {
    this.intervalApiCall$ = interval(
      this.isMobile ? JACKPOT_ANIMATION_DURATION + 250 : (JACKPOT_ANIMATION_DURATION + 250) * 2
    )
      .pipe(switchMap(_ => this.jackpotService.getActiveJackpotsByGame(gameId)))
      .subscribe((data: JackpotModel[]) => {
        if (data && data.length) {
          if (this.jackpots.length !== data.length) {
            this.jackpots = data;
          }

          data.forEach(jackpotData => {
            const jackpot = this.jackpots.find(element => element.id === jackpotData.id);
            if (jackpot) {
              const index = this.jackpots.indexOf(jackpot);
              const item = this.jackpots[index];
              item.currentValue = jackpotData.currentValue;
              item.jackpotWinners = jackpotData.jackpotWinners;

              if (item.timeBased) {
                item.awardedJackpots = jackpotData.awardedJackpots;
              }
            }
          });
        } else {
          this.jackpots = [];
        }
      });
  }

  private checkForCurrentlyDisplayedJackpotValueChange(jackpotId: number) {
    this.jackpotService.getJackpotValue(jackpotId).subscribe(jackpotValue => {
      if (jackpotValue === null) {
        return;
      }

      this.jackpots[this.currentIndex].currentValue = jackpotValue;
    });
  }

  public toggle(trigger: any, jackpotIndex: number) {
    const callbackFunction = () => {
      this.triggerOrigin = trigger;
      this.isOpen = !this.isOpen;
    };

    this.setCurrentJackpotWinners(jackpotIndex, callbackFunction);
  }

  private setTimeBaseChangeInterval() {
    setTimeout(() => {
      if (this.isMobile) {
        this.jackpots[this.currentIndex].timeBasedInitialState =
          !this.jackpots[this.currentIndex].timeBasedInitialState;
      }

      this.timeBaseSwitchInterval$ = interval(JACKPOT_ANIMATION_DURATION * 2 + 250 * 2).subscribe(() => {
        if (!this.isMobile) {
          this.jackpots.forEach(jackpot => {
            jackpot.timeBasedInitialState = !jackpot.timeBasedInitialState;
          });
        }

        if (this.isMobile) {
          if (!this.jackpots[this.currentIndex].timeBased) {
            this.jackpots.forEach(jackpot => {
              if (!jackpot.timeBasedInitialState) {
                jackpot.timeBasedInitialState = !jackpot.timeBasedInitialState;
              }
            });
          }
          this.jackpots[this.currentIndex].timeBasedInitialState =
            !this.jackpots[this.currentIndex].timeBasedInitialState;
        }
      });
    }, JACKPOT_ANIMATION_DURATION + 250);
  }

  public toggleWinnerListMobile() {
    // protect from a scenario, we clicked on one jackpot and timout change currentIndex while we are waiting for winners from api
    const clickedJackPotIndex = this.currentIndex;

    const callbackFunction = () => {
      this.isMobileWinnerListOpen = !this.isMobileWinnerListOpen;
    };

    this.setCurrentJackpotWinners(clickedJackPotIndex, callbackFunction);
  }

  private setCurrentJackpotWinners(clickedJackPotIndex: number, callbackFunction: any) {
    this.jackpotService
      .getJackpotWinners(this.jackpots[clickedJackPotIndex].id)
      .subscribe((data: JackpotWinners) => {
        this.jackpots[clickedJackPotIndex].populateJackPotWithWinners(data);
        // protect from a scenario on mobile, when we click on one jackpot and timout change currentIndex while we are waiting for winners from api
        if (this.isMobile) {
          if (clickedJackPotIndex === this.currentIndex) {
            this.currentJackpotWinners = data;
            callbackFunction();
          }
        } else {
          this.currentJackpotWinners = data;
          callbackFunction();
        }
      });
  }
}
