<footer id="footer" *ngIf="isFooterVisible" class="print-hide" [ngClass]="{'md-shop-footer': environment.clientName === CLIENT_NAMES.ADMIRAL_MD_SHOP}">
  <div class="container" [ngClass]="[isMobile ? 'py-6' : 'py-4']">
    <!-- COLUMNS -->
    <div
      class="row justify-content-left text-left footer-column"
      *ngIf="footer?.columns && footer.columns.length"
    >
      <div class="col-lg-2 text-white" *ngFor="let column of footer.columns">
        <div *ngIf="column?.items.length">
          <span class="text-uppercase">
            <b>{{ column?.title | translate }}</b>
          </span>
          <hr />
          <div *ngFor="let item of column.items">
            <a class="row col-lg-12 text-light" (click)="navigate(item)">
              <p>{{ item?.label | translate }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- ICONS -->
    <div
      class="row justify-content-left text-left icons-wrapper"
      *ngIf="footer?.icons && footer.icons.length"
    >
      <small class="col-lg-12 text-white center" *ngFor="let iconHolder of footer.icons">
        <div class="text-left">
          <span (click)="navigate(iconHolder)" class="text-uppercase pointer">
            <b>{{ iconHolder?.title | translate }}</b>
          </span>
        </div>
        <hr class="hr" />
        <small *ngFor="let icon of iconHolder?.items">
          <a class="col-lg-12 text-light" (click)="navigate(icon)">
            <ng-container
              appAppendImage
              [parentNode]="true"
              [imgPath]="icon.logo"
              [className]="'iconImage footerImg'"
              [loadingStrategy]="'async'"
            >
            </ng-container>
            <span *ngIf="!icon.logo">{{ icon.label | translate }}</span>
          </a>
        </small>
      </small>
    </div>

    <!-- CERTS -->
    <div class="row certs-section" *ngIf="footer?.certs && footer.certs.length">
      <div class="col-12" *ngFor="let cert of footer.certs">
        <!-- Quick fix certs -->
        <div class="center" *ngIf="!isMobile">
          <div>
            <span
              appAppendImage
              [imgPath]="cert.logo"
              [className]="'certImgDimension'"
              [loadingStrategy]="'async'"
            >
            </span>
            <a class="col-lg-12 text-muted" (click)="navigate(cert)">
              {{ cert?.label | translate }}
            </a>
          </div>
        </div>

        <div *ngIf="isMobile">
          <div class="center pt-4 pb-2">
            <ng-container
              appAppendImage
              [parentNode]="true"
              [imgPath]="cert.logo"
              [className]="'iconImage certImgDimension float-left'"
              [loadingStrategy]="'async'"
            >
            </ng-container>
          </div>
          <div class="center">
            <a class="text-muted" (click)="navigate(cert)">
              {{ cert?.label | translate }}
            </a>
          </div>
        </div>
        <!-- Quick fix certs end-->
      </div>
    </div>

    <!-- LOGO -->
    <div class="row justify-content-center text-left pt-4" *ngIf="footer?.backgroundImg">
      <ng-container
        appAppendImage
        [parentNode]="true"
        [className]="'logo'"
        [imgPath]="footer.backgroundImg"
        [loadingStrategy]="'async'"
      >
      </ng-container>
    </div>

    <!-- DESCRIPTIONS -->
    <div
      class="row justify-content-center text-center"
      [ngClass]="{ 'pt-4': footer?.columns || footer?.icons || footer.certs }"
      *ngIf="footer?.footerDescription && footer.footerDescription.length"
    >
      <small class="col-lg-8 text-muted" *ngFor="let description of footer?.footerDescription">
        <p>{{ description.label | translate }}</p>
      </small>
    </div>

    <!-- VERSION -->
    <div class="row justify-content-center text-center">
      <small class="col-lg-8 text-muted">
        <p>{{ 'VERSION' | translate: { version: version } }}</p>
      </small>
    </div>
  </div>
</footer>
