<ng-container *ngIf="!paymentProceed; else paymentMethods">
  <!--<ng-container *ngIf="!corvusProceed; else corvusPayment">-->
  <div class="c_deposit-amount">
    <div
      class="c_payment-preset-container"
      [ngClass]="{ 'c_payment-preset-container--is-desktop ': !isMobile }"
    >
      <div class="c_payment-preset-row" [ngClass]="{ 'flex-0': !isMobile }">
        <div class="c_card-img">
          <ng-container *ngTemplateOutlet="cardIcon"></ng-container>
          <div class="text-light">{{ 'CARD_PAYMENT' | translate }}</div>
        </div>
        <ng-container *ngIf="isMobile">
          <ng-container *ngTemplateOutlet="inputAndBtn"></ng-container>
        </ng-container>
      </div>
      <div
        class="c_payment-preset-row c_payment-preset"
        [ngClass]="{ 'c_payment-preset--is-desktop ': !isMobile }"
      >
        <ng-container *ngFor="let amount of paymentPresets">
          <div
            class="c_payment-preset-item"
            [ngClass]="{ 'c_payment-preset-item--is-desktop ': !isMobile }"
            (click)="setDepositAmount(amount); stopIntervalChangeItemsInBar()"
          >
            <div class="c_payment-preset-amount">{{ amount | number : '1.2-2' }}</div>
            <div class="c_payment-preset-currency">{{ currency }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isMobile">
          <ng-container *ngTemplateOutlet="inputAndBtn"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #inputAndBtn>
  <div class="c_input-and-btn" [ngClass]="{ 'flex-2': !isMobile }">
    <div class="u_position-relative" [ngClass]="{ 'flex-2': !isMobile }">
      <form [formGroup]="depositForm" (ngSubmit)="proceedToPayment()">
        <div class="u_position-relative">
          <input
            class="form-control"
            formControlName="depositAmount"
            (click)="expandCollapseQuickBar(); stopIntervalChangeItemsInBar()"
            type="number"
          />
          <span class="input-currency">{{ currency }}</span>
          <div class="c_message-style" *ngIf="depositForm.get('depositAmount')?.errors?.min">
            {{ 'PAYMENT_CARD_MIN_VALUE' | translate }}: {{ deposit.minValue }}
          </div>
          <div class="c_message-style" *ngIf="depositForm.get('depositAmount')?.errors?.max">
            {{ 'DEPOSIT_MAX_VALUE' | translate }}: {{ deposit.maxValue }}
          </div>
        </div>
      </form>
    </div>
    <button
      class="item-button"
      [ngClass]="{
              'item-button--is-disabled': depositForm.invalid && depositForm.dirty && depositForm.invalid,
            }"
      [disabled]="depositForm.invalid"
      (click)="proceedToPayment(); stopIntervalChangeItemsInBar()"
    >
      {{ 'PAY_NOW' | translate }}
    </button>
    <div class="u_pointer" (click)="closeQuickBar()" [inlineSVG]="'assets/icons/close_cross.svg'"></div>
  </div>
</ng-template>

<ng-template #cardIcon>
  <svg width="24" height="22" viewBox="0 0 24 22">
    <path
      d="M21.9958 0.5H2.00417C0.895833 0.5 0 1.50781 0 2.75V19.25C0 20.4922 0.895833 21.5 2.00417 21.5H21.9958C23.1042 21.5 24 20.4922 24 19.25V2.75C24 1.50781 23.1042 0.5 21.9958 0.5ZM2.25417 2.75H21.7458C21.8833 2.75 21.9958 2.87656 21.9958 3.03125V5H2.00417V3.03125C2.00417 2.87656 2.11667 2.75 2.25417 2.75ZM21.7458 19.25H2.25417C2.11667 19.25 2.00417 19.1234 2.00417 18.9688V11H21.9958V18.9688C21.9958 19.1234 21.8833 19.25 21.7458 19.25ZM8 14.5625V16.4375C8 16.7469 7.775 17 7.5 17H4.5C4.225 17 4 16.7469 4 16.4375V14.5625C4 14.2531 4.225 14 4.5 14H7.5C7.775 14 8 14.2531 8 14.5625ZM16 14.5625V16.4375C16 16.7469 15.775 17 15.5 17H9.83333C9.55833 17 9.33333 16.7469 9.33333 16.4375V14.5625C9.33333 14.2531 9.55833 14 9.83333 14H15.5C15.775 14 16 14.2531 16 14.5625Z"
      class="card_img"
    />
  </svg>
</ng-template>

<ng-template #paymentMethods>
  <app-quick-bar-corvus-frame-deposit
    *ngIf="depositTypeToRun === 'corvus'"
    [expandCollapseQuickBar]="expandCollapseQuickBar.bind(this)"
    [closeQuickBar]="closeQuickBar.bind(this)"
    [stopIntervalChangeItemsInBar]="stopIntervalChangeItemsInBar.bind(this)"
    [clientName]="clientName"
    [isMobile]="isMobile"
    [playerData]="playerData"
    [depositAmount]="this.depositForm.get('depositAmount').value"
    [paymentSuccess]="paymentSuccess.bind(this)"
  ></app-quick-bar-corvus-frame-deposit>

  <app-quick-bar-ips-bank-account-deposit
    *ngIf="depositTypeToRun === 'ips-bank'"
    [expandCollapseQuickBar]="expandCollapseQuickBar.bind(this)"
    [closeQuickBar]="closeQuickBar.bind(this)"
    [stopIntervalChangeItemsInBar]="stopIntervalChangeItemsInBar.bind(this)"
    [clientName]="clientName"
    [isMobile]="isMobile"
    [playerData]="playerData"
    [depositAmount]="this.depositForm.get('depositAmount').value"
    [paymentSuccess]="paymentSuccess.bind(this)"
  ></app-quick-bar-ips-bank-account-deposit>

  <app-quick-bar-ips-qrcode
    *ngIf="depositTypeToRun === 'ips-qrcode'"
    [expandCollapseQuickBar]="expandCollapseQuickBar.bind(this)"
    [closeQuickBar]="closeQuickBar.bind(this)"
    [stopIntervalChangeItemsInBar]="stopIntervalChangeItemsInBar.bind(this)"
    [clientName]="clientName"
    [playerData]="playerData"
    [depositAmount]="this.depositForm.get('depositAmount').value"
    [isMobile]="isMobile"
    [paymentSuccess]="paymentSuccess.bind(this)"
  ></app-quick-bar-ips-qrcode>

  <app-quick-bar-allsecure-deposit
    *ngIf="depositTypeToRun === 'allsecure'"
    [expandCollapseQuickBar]="expandCollapseQuickBar.bind(this)"
    [closeQuickBar]="closeQuickBar.bind(this)"
    [clientName]="clientName"
    [playerData]="playerData"
    [depositAmount]="this.depositForm.get('depositAmount').value"
    [isMobile]="isMobile"
    [depositInformation]="deposit"
  >
  </app-quick-bar-allsecure-deposit>
</ng-template>
