import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Injectable({
  providedIn: 'root',
})
export class InfobipChatService {
  constructor() {}

  private environment = environment;
  private clients = CLIENT_NAMES;
  private infobipChat = window['liveChat'];

  public isCroatianStagingEnv(): boolean {
    return !this.environment.production && this.environment.clientName === this.clients.ADMIRAL_CROATIA;
  }

  public showInfobipChat(): void {
    if (this.infobipChat && this.isCroatianStagingEnv()) {
      this.infobipChat('show_launcher');
    }
  }

  public hideInfobipChat(): void {
    if (this.infobipChat && this.isCroatianStagingEnv()) {
      this.infobipChat('hide_launcher');
    }
  }
}
