import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  /**
   * upload file
   * @param params - Object with properties:
   * data - base64 string value of file,
   * fileName, string - file name
   * type, string - file type
   */
  uploadFile(data) {
    return this.http.post(environment.uploadFilePath, data);
  }
}
