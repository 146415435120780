export class IpsDepositResult {
  ipsStatus: string;
  isSuccess: boolean;
  txnId: string;
  createdOn: string;
  amount: string;
  rpReference: string;

  constructor(data: any) {
    this.ipsStatus = data.ipsStatus;
    this.isSuccess = data.ipsStatus === 'IPS DEPOSIT';
    this.txnId = data.txnId;
    this.createdOn = data.createdOn;
    this.amount = data.amount;
    this.rpReference = data.rpReference;
  }
}
