import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const DATE_FORMAT = 'YYYY-MM-DD';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  momentToday() {
    return moment();
  }

  // today
  today() {
    return moment().format(DATE_FORMAT);
  }

  // yesterday
  yesterday() {
    return moment().subtract(1, 'day').format(DATE_FORMAT);
  }

  // current week start
  currentWeekStart() {
    return moment().startOf('isoWeek').format(DATE_FORMAT);
  }

  // current week end
  currentWeekEnd() {
    return moment().endOf('isoWeek').format(DATE_FORMAT);
  }

  // current month start
  currentMonthStart() {
    return moment().startOf('month').format(DATE_FORMAT);
  }

  // current month end
  currentMonthEnd() {
    return moment().endOf('month').format(DATE_FORMAT);
  }

  // last week start
  lastWeekStart() {
    return moment().startOf('isoWeek').subtract(1, 'week').format(DATE_FORMAT);
  }

  // last week end
  lastWeekEnd() {
    return moment().endOf('isoWeek').subtract(1, 'week').format(DATE_FORMAT);
  }

  // last month start
  lastMonthStart() {
    return moment().startOf('month').subtract(1, 'month').format(DATE_FORMAT);
  }

  // last month end
  lastMonthEnd() {
    return moment().subtract(1, 'month').endOf('month').format(DATE_FORMAT);
  }

  // before 18 years
  before18Years() {
    return moment().subtract(18, 'year').format(DATE_FORMAT);
  }

  // is date valid
  isDateValid(day, month, year) {
    const date = moment(`${year}-${month}-${day}`);
    return date.isValid();
  }

  private getDateObject(momentDate: moment.Moment): NgbDateStruct {
    const dateObject = { day: 0, month: 0, year: 0 };
    dateObject.day = Number(momentDate.format('DD'));
    dateObject.month = Number(momentDate.format('MM'));
    dateObject.year = Number(momentDate.format('YYYY'));
    return dateObject;
  }

  // used for maxDate on player date of birth picker
  before18YearsObject() {
    const before18YearsDate = moment().subtract(18, 'year');
    return this.getDateObject(before18YearsDate);
  }

  todayObject() {
    const today = moment();
    return this.getDateObject(today);
  }

  // add param minutes to current timestamp (used for saving tokenDuration in localStorage after loign)
  addMinutesToCurrent(_minutes: number) {
    return moment().add(_minutes, 'minutes').unix();
  }

  // check if param timestamp is lower then current time (used for token expiration check)
  isLowerThenCurrent(_timestamp) {
    const beginningTime = moment();
    const endTime = moment(_timestamp);
    return beginningTime.isBefore(endTime);
  }

  isAfterToday(date) {
    return moment().isAfter(date);
  }

  isBeforeToday(date) {
    return moment().isBefore(date);
  }

  // format date object
  formatDate(_date): string {
    if (_date instanceof Date) {
      return moment(_date).format(DATE_FORMAT);
    }
    return _date;
  }

  formatTimestamp(timestamp): string {
    return moment(timestamp).format('DD.MM.YYYY HH:mm');
  }
}
