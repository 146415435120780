import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TournamentService {
  constructor(private http: HttpClient) {}

  /**
   * get tournaments
   */
  getTournaments(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'tournament', { params });
  }

  /**
   * get tournament by id
   * @param id - tournament ID
   */
  getTournament(tournamentId): Observable<any> {
    return this.http.get(environment.apiUrl + `tournament/${tournamentId}`);
  }

  /**
   * get tournament rank list
   * @param id - tournament ID
   * @param limit - how many items to get
   * @param offset - how many items from begining
   *
   */
  getRankList(id, limit = 100, offset = 0): Observable<any> {
    return this.http.get(
      environment.apiUrl + `tournament/${id}/rank?limit=${limit}&offset=${offset}&eligible=true`
    );
  }

  /**
   * get player current position in case when
   * he is not visible on current rank list
   * @param id - tournament ID
   * @param playerId - player ID
   * @param placesBefore - number of places before player
   * @param placesAfter - number of places after player
   *
   */
  getRankListForPLayer(id, playerId, placesBefore = 3, placesAfter = 3): Observable<any> {
    const params = { placesBefore, placesAfter };
    return this.http.get(environment.apiUrl + `tournament/${id}/rank-for-player/${playerId}`, { params });
  }

  /**
   * get game tournament info
   * @param gameId - game ID
   */
  getGameTournamentInfo(gameId): Observable<any> {
    return this.http.get(environment.apiUrl + `tournament/${gameId}/tournament-info`);
  }

  /**
   * get game tournament prizes
   * @param id - tournament ID
   */
  getTournamentPrizes(id): Observable<any> {
    return this.http.get(environment.apiUrl + `tournament/${id}/prizes`);
  }
}
