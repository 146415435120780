export class CroatiaAdditionalDataModel {
  public citizenship: number;
  public expiryDateOfDocument: Date;
  public documentCreatedBy: string;
  public documentId: string;
  public documentProvidedByCountry: number;
  public documentType: string;
  public exposure: string;
  public politician: boolean;
  public publicDuty: string;
  public sourceOfProperty: string;
  public otherSourceOfProperty: string;
  public address: string;
  public iban: any;
  public city: string;
  public zip: string;

  constructor(data, player) {
    this.citizenship = data.citizenship || null;
    this.expiryDateOfDocument = new Date(data.expiryDateOfDocument) || null;
    this.documentCreatedBy = data.documentCreatedBy || null;
    this.documentId = data.documentId || null;
    this.documentProvidedByCountry = data.documentProvidedByCountry || null;
    this.documentType = data.documentType || null;
    this.exposure = data.exposure || null;
    this.politician = data.politician || false;
    this.publicDuty = data.publicDuty || null;
    this.sourceOfProperty = data.sourceOfProperty || null;
    this.otherSourceOfProperty = data.otherSourceOfProperty || null;
    this.iban = { bankAccountNumber: player.bankAccount };
    this.address = player.address;
    this.city = player.city;
    this.zip = player.zip;
  }
}

export class CroPoliticalExposureDataModel {
  public politician: boolean;
  public politicalExposureType: string;
  public politicalPublicDutyType: string;
  public politicalSourceOfPropertyType: string;
  public politicalSourceOfPropertyCustomValue: string;

  constructor(data) {
    this.politician = data.politician || false;
    this.politicalExposureType = data.exposure || null;
    this.politicalPublicDutyType = data.publicDuty || null;
    this.politicalSourceOfPropertyType = data.sourceOfProperty || null;
    this.politicalSourceOfPropertyCustomValue = this.politician ? (data.otherSourceOfProperty || null) : null;
  }
}
