import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TOURNAMENT_STATUS } from '@enums/tournament-status.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '@services/helpers.service';
import { environment } from 'src/environments/environment';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-info-leaderboard',
  templateUrl: './info-leaderboard.dialog.html',
  styleUrls: ['./info-leaderboard.dialog.scss'],
})
export class InfoLeaderboardDialog implements OnInit {
  @ViewChild('dialogContent', { static: true }) dialogContent: ElementRef;
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer: ElementRef;
  @Input() tournament;
  @Input() sections;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public TOURNAMENT_STATUS = TOURNAMENT_STATUS;
  public player = this.appStateFacadeService.getPlayerData();
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;
  public foundPlayerIndexInitialRankList: number;
  public playerCurrentPositionRankList;
  public hideDots = false;
  public environment = environment;
  public rankList;

  constructor(
    private activeModal: NgbActiveModal,
    private appStateFacadeService: AppStateFacadeService,
    private helpersService: HelpersService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setBackgroundImage();
  }

  // close dialog
  public closeDialog() {
    this.activeModal.close();
  }

  // set background image of dialog
  private setBackgroundImage() {
    const backgroundGradient = document.documentElement.style.getPropertyValue('--leaderboardDialogGradient');
    this.helpersService.setLobbyBackgroundImage(
      this.dialogContent.nativeElement,
      this.renderer,
      backgroundGradient
    );
  }
}
