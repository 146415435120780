export class FooterCerts {
  public code: string;
  public url: string;
  public urlType: string;
  public parentCode: string;
  public type: string;
  public label: string;
  public logo: string;
  public logoHover: string;

  constructor(data) {
    this.code = data.code;
    this.url = data.url;
    this.urlType = data.urlType;
    this.parentCode = data.parentCode;
    this.type = data.type;
    this.label = data.label;
    this.logo = data.logo;
    this.logoHover = data.logoHover;
  }
}
