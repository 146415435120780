<div
  *ngIf="
    (isAndroid && appLinks[environment.clientName].androidLink) ||
    (!isAndroid && appLinks[environment.clientName]?.iosLink)
  "
  class="app-download-container"
  [ngClass]="{ pinnbet: environment.clientName === 'ADMIRAL_PINNBET' }"
>
  <div inlineSVG="assets/images/close-solid.svg" class="close-button" (click)="closeQuickBar()"></div>
  <div class="admiralbet-logo">
    <img
      [ngClass]="{ 'img-pinn': environment.clientName === 'ADMIRAL_PINNBET' }"
      [src]="appLinks[environment.clientName].platformLogoSrc"
      alt="Platform logo"
    />
  </div>
  <div class="admiralbet-label">
    <div class="text-admiral">{{ appLinks[environment.clientName].title }}</div>
    <div class="text-moto">{{ 'APP_DOWNLOAD_POPUP' | translate }}</div>
  </div>

  <a
    [href]="
      isAndroid ? appLinks[environment.clientName].androidLink : appLinks[environment.clientName]?.iosLink
    "
    class="download-logo"
  >
    <div
      [inlineSVG]="
        isAndroid
          ? appLinks[environment.clientName].androidDownloadIcon
          : appLinks[environment.clientName].iosDownloadIcon
      "
    ></div>
  </a>
</div>
