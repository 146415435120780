import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOBBY_CODES } from '@enums/lobby-codes.enum';
import { LOBBY_PATHS } from '@enums/lobby-paths.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOBBY_STATE_STATUS } from '../models/enums/LobbyStateStatus.enum';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LobbySectionModel } from '../models/LobbySection.model';
import { LobbyProviderModel } from '../models/LobbyProvider.model';
import { IActiveLobbyFilters } from '../models/ActiveLobbyFilters.intreface';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Injectable({ providedIn: 'root' })
export class LobbyState {
  constructor(private router: Router, private http: HttpClient) {}

  //#region Lobby Related State
  private lobbyCodes = LOBBY_CODES;
  private lobbyPaths = LOBBY_PATHS;

  private activeLobbyCode: LOBBY_CODES = null;
  private activeLobbyPath: string = null;

  getLobbyCodes(): typeof LOBBY_CODES {
    return this.lobbyCodes;
  }

  getLobbyPaths(): typeof LOBBY_PATHS {
    return this.lobbyPaths;
  }

  setActiveLobbyCode(newActiveLobbyCode: LOBBY_CODES): void {
    this.activeLobbyCode = newActiveLobbyCode;
  }

  getActiveLobbyCode(): LOBBY_CODES {
    return this.activeLobbyCode;
  }

  setActiveLobbyPath(newActiveLobbyPath: string): void {
    this.activeLobbyPath = newActiveLobbyPath;
  }

  getActiveLobbyPath(): string {
    return this.activeLobbyPath;
  }
  //#endregion

  //#region Lobby State Status
  private lobbyStateStatus: BehaviorSubject<LOBBY_STATE_STATUS> = new BehaviorSubject(
    LOBBY_STATE_STATUS.INITIALIZED
  );

  private lobbyStateStatusObservable = this.lobbyStateStatus.asObservable();

  setLobbyStateStatus(newLobbyStateStatus: LOBBY_STATE_STATUS) {
    this.lobbyStateStatus.next(newLobbyStateStatus);
  }

  getLobbyStateStatus(): LOBBY_STATE_STATUS {
    return this.lobbyStateStatus.value;
  }

  getLobbyStateStatusObservable(): Observable<LOBBY_STATE_STATUS> {
    return this.lobbyStateStatusObservable;
  }
  //#endregion

  //#region Lobby Sections Maps
  private lobbyToSectionsMap: Map<LOBBY_CODES, BehaviorSubject<LobbySectionModel[]>> = new Map();

  setSectionsInLobby(lobby: LOBBY_CODES, sections: LobbySectionModel[]) {
    // TODO hack for my casino section change
    if (
      environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
    ) {
      sections = sections.map(section => {
        if (section.code === 'MY_CASINO') {
          section.code = 'MY_SLOT';
        }
        return section;
      });
    }
    if (this.lobbyToSectionsMap.has(lobby)) {
      // BhaviorSubject already exists
      this.lobbyToSectionsMap.get(lobby).next(sections);
    } else {
      // Create new BehaviorSubject
      this.lobbyToSectionsMap.set(lobby, new BehaviorSubject(sections));
    }
  }

  getSectionsInLobbyObservable(lobby: LOBBY_CODES): Observable<LobbySectionModel[]> {
    return this.lobbyToSectionsMap.get(lobby);
  }

  getSectionsInLobby(lobby: LOBBY_CODES): LobbySectionModel[] {
    return this.lobbyToSectionsMap.get(lobby).value;
  }

  // this needs to be refactored after next backend update //
  getSectionFromAPI(params) {
    return this.http
      .get<any[]>(environment.apiUrl + 'casino/game-sections', { params })
      .pipe(map(items => items.map(item => new LobbySectionModel(item))));
  }

  clearSectionsInLobby() {
    this.lobbyToSectionsMap.clear();
  }

  sectionsInLobbyExist(lobby: LOBBY_CODES): boolean {
    return this.lobbyToSectionsMap.has(lobby);
  }

  getSectionBySectionCode(lobbyCode: LOBBY_CODES, sectionCode: string): LobbySectionModel | null {
    if (!this.sectionsInLobbyExist(lobbyCode)) {
      return null;
    }
    const sectionInLobby = this.getSectionsInLobby(lobbyCode);
    const section = sectionInLobby.find(
      singleSection => singleSection.code.toLowerCase() === sectionCode.toLowerCase()
    );

    if (section) {
      return section;
    } else {
      return sectionInLobby[0];
    }
  }
  //#endregion

  //#region SectionId to providers Map
  private sectionIdToProvidersMap: Map<number, BehaviorSubject<LobbyProviderModel[]>> = new Map();

  providersInSectionExist(sectionId): boolean {
    return this.sectionIdToProvidersMap.has(sectionId);
  }

  getProvidersInSectionFromAPI(sectionId: number): Observable<LobbyProviderModel[]> {
    return this.http
      .get<any[]>(environment.apiUrl + 'casino/game-providers', {
        params: new HttpParams().set('sectionId', sectionId),
      })
      .pipe(map(response => response.map(item => new LobbyProviderModel(item))));
  }

  setProvidersInSection(sectionId: number, providers: LobbyProviderModel[]) {
    if (this.sectionIdToProvidersMap.has(sectionId)) {
      this.sectionIdToProvidersMap.get(sectionId).next(providers);
    } else {
      this.sectionIdToProvidersMap.set(sectionId, new BehaviorSubject(providers));
    }
  }

  getProvidersInSection(sectionId: number): LobbyProviderModel[] {
    return this.sectionIdToProvidersMap.get(sectionId).value;
  }

  getProvidersInSectionObservable(sectionId: number): Observable<LobbyProviderModel[]> {
    return this.sectionIdToProvidersMap.get(sectionId);
  }
  //#endregion

  //#region Active Lobby Filters
  private lobbyToActiveLobbyFilters: Map<LOBBY_CODES, BehaviorSubject<IActiveLobbyFilters>> = new Map();

  setActiveFiltersDefaults(lobby: LOBBY_CODES) {
    if (!this.lobbyToActiveLobbyFilters.has(lobby)) {
      this.lobbyToActiveLobbyFilters.set(lobby, new BehaviorSubject(this.generateActiveFilters()));
    }
  }

  generateActiveFilters(
    section: LobbySectionModel = null,
    provider: LobbyProviderModel = null,
    tournamentId: number = null
  ): IActiveLobbyFilters {
    return { section, provider, tournamentId };
  }

  setActiveFilters(lobby: LOBBY_CODES, activeLobbyFilters: IActiveLobbyFilters): void {
    if (this.lobbyToActiveLobbyFilters.has(lobby)) {
      this.lobbyToActiveLobbyFilters.get(lobby).next(activeLobbyFilters);
    } else {
      this.lobbyToActiveLobbyFilters.set(lobby, new BehaviorSubject(activeLobbyFilters));
    }
  }

  getActiveFilters(lobby: LOBBY_CODES): IActiveLobbyFilters | null {
    if (!this.lobbyToActiveLobbyFilters.has(lobby)) {
      return null;
    }
    return this.lobbyToActiveLobbyFilters.get(lobby).value;
  }

  getActiveFiltersObservable(lobby: LOBBY_CODES): Observable<IActiveLobbyFilters> {
    this.setActiveFiltersDefaults(lobby);
    return this.lobbyToActiveLobbyFilters.get(lobby);
  }

  navigateToActiveFilters(lobbyPath: string, activeFilters: IActiveLobbyFilters) {
    if (activeFilters.tournamentId && activeFilters.section.code.toLowerCase() === 'tournament') {
      this.router.navigate([
        '/',
        lobbyPath,
        activeFilters.section.code.toLowerCase(),
        activeFilters.tournamentId,
      ]);
      return;
    }
    // If provider exist navigate to section/providers/provider
    if (activeFilters.provider) {
      this.router.navigate([
        '/',
        lobbyPath,
        activeFilters.section.code.toLowerCase(),
        'provider',
        activeFilters.provider.name,
      ]);
      return;
    }
    // if we are here providers and tournamet id do not exist, navigate only to section
    if (activeFilters.section) {
      this.router.navigate(['/', lobbyPath, activeFilters.section.code.toLowerCase()]);
    }
  }
  //#endregion
}
