import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigData } from '@models/config-data/config-data.model';
import { Footer } from '@models/config-data/footer/footer.model';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Version } from 'src/config/version.config';
import { GLOBAL_VARIABLES } from '@enums/global-variables.enum';
import { AppStateFacadeService } from '@state/app-state.facade';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NavigationService } from '@services/navigation.service';
import { heightAnimation } from '../../shared/animations/animations';
import { FooterColumnItem } from '@models/config-data/footer/footer-column-item.model';

const HIDE_FOOTER_ON_EXACT_URLS = ['/sport-prematch', '/sport-live', '/sport-results', '/worldcup'];
const HIDE_FOOTER_ON_START_WITH_URLS = ['/lotto', '/horses', '/worldcup', '/profile/blinking-verification'];

const MOCKED_ACCORDION_ITEMS = [
  { id: '1first', title: 'ACCORDION_TITLE_1', description: 'ACCORDION_DESCRIPTION_1' },
];

@Component({
  selector: 'app-footer-cro',
  templateUrl: './footer-cro.component.html',
  styleUrls: ['./footer-cro.component.scss'],
  animations: [heightAnimation('200ms')],
})
export class FooterCroComponent implements OnInit, OnDestroy {
  public environment = environment;
  public footer: Footer;
  public logoImg: string;
  public isFooterVisible = true;
  public certHeight: number;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public version = Version;
  public accordionItems: { description: string; id: string; title: string }[] = MOCKED_ACCORDION_ITEMS;

  private configDataObservable: Observable<any>;
  private configData$: Subscription;
  private router$: Subscription;
  public accordionDropDownOpen: boolean;
  public showAccordionInfo: boolean;

  constructor(
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private navigationService: NavigationService
  ) {
    this.setRouterSubscription();
  }

  ngOnInit(): void {
    this.setupStore();
    this.setConfigDataSubscription();
  }

  ngOnDestroy() {
    this.configData$.unsubscribe();
    this.router$.unsubscribe();
  }

  // set up store
  private setupStore(): void {
    this.configDataObservable = this.appStateFacadeService.getConfigData();
  }

  // set config data subsription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable.subscribe((configData: ConfigData) => {
      this.footer = configData?.footer;
      this.calculateCertHeight();
    });
  }

  // set router subscription
  private setRouterSubscription() {
    this.showAccordionInfo = this.router.url.includes('/casino');
    this.router$ = this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        // url after redirects without params
        const urlAfterRedirects = data.urlAfterRedirects.split('?')[0];
        if (this.isMobile) {
          this.isFooterVisible = !HIDE_FOOTER_ON_EXACT_URLS.find(url => url === urlAfterRedirects);
          if (this.isFooterVisible) {
            this.isFooterVisible = !HIDE_FOOTER_ON_START_WITH_URLS.find(url =>
              urlAfterRedirects.startsWith(url)
            );
          }
        }
        this.showAccordionInfo = data.urlAfterRedirects.includes('/casino');
      }
    });
  }

  // calculate cert max height
  private calculateCertHeight() {
    const length = this.footer?.certs?.length || 2;
    this.certHeight = GLOBAL_VARIABLES.MAX_OVERALL_CERT_HEIGHT / length;
  }

  // navigate to internal or external url
  public navigate(footerItem: FooterColumnItem): void {
    if (!footerItem.url || footerItem.code === 'CHANGE_COOKIE_PREFERENCES') {
      return;
    }

    // proceed registration dialog
    const player = this.appStateFacadeService.getPlayerData();
    if (
      environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA &&
      player &&
      player &&
      !player.registrationCompleted
    ) {
      this.navigationService.checkIfOnlyF2FLeftAndInProcess(player);
      return;
    }

    if (footerItem.urlType === 'INTERNAL') {
      if (footerItem.url.includes('http')) {
        window.open(footerItem.url, '_self');
      } else {
        this.router.navigateByUrl(footerItem.url);
        window.scrollTo(0, 0);
      }
    }

    if (footerItem.urlType === 'EXTERNAL') {
      window.open(footerItem.url, '_blank');
    }
  }
  public openAccordionInformation() {
    this.accordionDropDownOpen = !this.accordionDropDownOpen;
  }
}
