import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DepositIntesa } from '@models/deposit-intesa.model';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { SportradarAdsService } from '@services/sportradar-ads.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-corvus-deposit-status',
  templateUrl: './corvus-deposit-status.dialog.html',
  styleUrls: ['./corvus-deposit-status.dialog.scss'],
})
export class CorvusDepositStatusDialog implements OnInit, OnDestroy {
  // This is a data that is sent from source component
  @Input() data: any;

  private playerSubscription: Subscription;

  public imagesBaseUrl = environment.imagesBaseUrl;
  public depositIntesa: DepositIntesa;
  public depositStatus: boolean;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  constructor(
    public activeModal: NgbActiveModal,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private sportradarAdsService: SportradarAdsService
  ) {}

  ngOnInit(): void {
    this.depositIntesa = this.data;
    this.checkDepositStatus(this.depositIntesa);
    this.trackBettingDeposit();
    if (this.data['EXTRA.TRXDATE']) {
      this.setDate(this.data['EXTRA.TRXDATE']);
    }
  }

  ngOnDestroy(): void {
    if (this.playerSubscription) {
      this.playerSubscription.unsubscribe();
    }
  }

  // Manual formating date because dateParam from Intesa gateway is not in good format for pipe
  private setDate(dateParam) {
    const dateString = dateParam.split(' ');
    const dateFormat = `${dateParam.slice(0, 4)}-${dateParam.slice(4, 6)}-${dateParam.slice(6, 8)} ${
      dateString[1]
    }`;
    this.depositIntesa = { ...this.depositIntesa, extraTrxDate: new Date(dateFormat) };
  }

  // check deposit status
  private checkDepositStatus(deposit) {
    // deposit type RESPONSE and txn data
    if (deposit.type === 'RESPONSE') {
      this.depositStatus = deposit.txnSuccess === 'true';
    } else {
      // deposit type MESSAGE and errorMessage
      this.depositStatus = false;
    }

    if (this.depositStatus) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: 'depozit_uplacen',
        value: this.getAmount(),
        currency: deposit.amount.split(' ')[1],
      });
      this.sendGoogleAnalytics();
    }
  }

  // Set Sportradar event for deposit.
  private trackBettingDeposit(): void {
    this.playerSubscription = this.appStateFacadeService
      .getPlayerDataObservable()
      .pipe(filter(playerData => !!playerData))
      .subscribe(player => {
        if (this.depositStatus) {
          const amountWithCurrency = this.depositIntesa.amount.split(' ');
          const payload = {
            action: 'created',
            userId: String(player.id),
            transactionId: this.depositIntesa.TransId || this.data.transactionId || '',
            amount: amountWithCurrency[0],
            currency: amountWithCurrency[1],
          };
          this.sportradarAdsService.setSportradarEvent('track.betting.deposit', payload);
        }
      });
  }

  // close modal
  public closeModal(): void {
    // redirect on route without params needed for deposit dialog
    const route = this.router.url.split('?')[0];
    this.router.navigate([route]);
    this.activeModal.close();
  }

  // send google analytics event for successful deposit
  private sendGoogleAnalytics(): void {
    const depositAmount = this.getAmount();
    this.googleAnalyticsService.eventEmitterCro('Deposit', 'Deposit', 'Uplata karticom', depositAmount);
    this.googleAnalyticsService.eventEmitter('Depozit', 'Depozit', 'Uplata karticom', depositAmount);
  }

  // get amount from query params
  private getAmount(): number {
    // amount come in string format 1000.00 RSD from intesa payment, we need to get only amount in number format for ga event
    const splitAmount = this.depositIntesa.amount.split(' ');
    return Number(splitAmount[0]);
  }
}
