import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

let TRANSACTIONS_TABS;

export const SPORT_TAB_TRANSACTIONS = {
  id: 1,
  translateKey: 'GAME_TRANSACTIONS_SPORT_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/sport-transaction',
  code: 'SPORT',
  url: 'sport-transaction',
  gameProducts: ['SPORT'],
  walletTypes:
    environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA
      ? ['MAIN', 'FREEBET']
      : ['MAIN', 'BONUS', 'FREEBET'],
};

export const CASINO_TAB_TRANSACTIONS = {
  id: 2,
  translateKey: 'GAME_TRANSACTIONS_CASINO_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/casino-transaction',
  code: 'CASINO',
  url: 'casino-transaction',
  gameProducts: ['CASINO'],
  walletTypes: ['MAIN', 'BONUS'],
};

export const LIVE_CASINO_TAB_TRANSACTIONS = {
  id: 3,
  translateKey: 'GAME_TRANSACTIONS_LIVE_CASINO_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/live-casino-transaction',
  code: 'LIVE_CASINO',
  url: 'live-casino-transaction',
  gameProducts: ['LIVE_CASINO'],
  walletTypes: ['MAIN', 'BONUS'],
};

export const SLOT_TAB_TRANSACTIONS = {
  id: 4,
  translateKey: 'GAME_TRANSACTIONS_CASINO_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/slot-transaction',
  code: 'SLOT',
  url: 'slot-transaction',
  gameProducts: ['CASINO'], // TODO need to be CASINO bcs SLOT doesnt exist in GAME PRODUCTS array
  walletTypes: ['MAIN', 'BONUS'],
};

export const LIVE_DEALER_TAB_TRANSACTIONS = {
  id: 5,
  translateKey: 'GAME_TRANSACTIONS_LIVE_CASINO_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/live-dealer-transaction',
  code: 'LIVE_DEALER',
  url: 'live-dealer-transaction',
  gameProducts: ['LIVE_CASINO'], // TODO need to be LIVE_CASINO bcs LIVE_DEALER doesnt exist in GAME PRODUCTS array
  walletTypes: ['MAIN', 'BONUS'],
};

export const VIRTUAL_TAB_TRANSACTIONS = {
  id: 6,
  translateKey: 'GAME_TRANSACTIONS_VIRTUAL_GAMES_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/virtual-games-transaction',
  code: 'VIRTUAL_GAMES',
  url: 'virtual-games-transaction',
  gameProducts: ['ELBET', 'SEVEN_AGGREGATOR', 'SPRIBE', 'GOLDEN_RACE', 'EXEFEED', 'AVIATRIX'],
  walletTypes: ['MAIN', 'BONUS'],
};

export const LOTTO_TAB_TRANSACTIONS = {
  id: 7,
  translateKey: 'GAME_TRANSACTIONS_LOTTO_GAMES_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/lotto-transaction',
  code: 'HR_LOTO',
  url: 'lotto-transaction',
  gameProducts: ['HR_LOTO'],
  walletTypes: ['MAIN'],
};

export const POKER_TAB_TRANSACTIONS = {
  id: 8,
  translateKey: 'GAME_TRANSACTIONS_POKER_GAMES_TAB',
  transactionLink: '/profile/{{transactionType}}-transactions/poker-transaction',
  code: 'POKER',
  url: 'poker-transaction',
  gameProducts: ['POKER'],
  walletTypes: ['MAIN'],
};

switch (environment.clientName) {
  case CLIENT_NAMES.ADMIRAL_SERBIA:
    TRANSACTIONS_TABS = [
      SPORT_TAB_TRANSACTIONS,
      SLOT_TAB_TRANSACTIONS,
      LIVE_DEALER_TAB_TRANSACTIONS,
      VIRTUAL_TAB_TRANSACTIONS,
      POKER_TAB_TRANSACTIONS,
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_PINNBET:
    TRANSACTIONS_TABS = [
      SPORT_TAB_TRANSACTIONS,
      SLOT_TAB_TRANSACTIONS,
      // LIVE_DEALER_TAB_TRANSACTIONS,
      VIRTUAL_TAB_TRANSACTIONS,
      // POKER_TAB_TRANSACTIONS,
    ];
    break;
  case CLIENT_NAMES.ADMIRAL_CROATIA:
    TRANSACTIONS_TABS = [CASINO_TAB_TRANSACTIONS, SPORT_TAB_TRANSACTIONS, LOTTO_TAB_TRANSACTIONS];
    break;
  default:
    TRANSACTIONS_TABS = [SPORT_TAB_TRANSACTIONS, CASINO_TAB_TRANSACTIONS, VIRTUAL_TAB_TRANSACTIONS];
    break;
}

export const TRANSACTIONS_MOCK = TRANSACTIONS_TABS;
