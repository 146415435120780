import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigData } from '@models/config-data/config-data.model';
import { Footer } from '@models/config-data/footer/footer.model';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Version } from 'src/config/version.config';
import { GLOBAL_VARIABLES } from '@enums/global-variables.enum';
import { AppStateFacadeService } from '@state/app-state.facade';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NavigationService } from '@services/navigation.service';

const HIDE_FOOTER_ON_EXACT_URLS = [
  '/sport-prematch',
  '/sport-live',
  '/sport-results',
  '/worldcup',
  '/eurocup',
];
const HIDE_FOOTER_ON_START_WITH_URLS = [
  /**
   * TODO when refactor of routing is done please uncomment and modify, those paths
   * check mobile menu how behave after hiding footer on new paths
   */
  // '/virtual/elbet',
  // '/virtual/nsoft',
  // '/virtual/spribe',
  // '/virtual/golden-race',
  '/lotto',
  '/poker',
  '/horses',
  '/worldcup',
  '/eurocup',
  '/profile/blinking-verification',
];

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public environment = environment;
  public footer: Footer;
  public logoImg: string;
  public isFooterVisible = true;
  public certHeight: number;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public version = Version;
  public CLIENT_NAMES = CLIENT_NAMES;

  private configDataObservable: Observable<any>;
  private configData$: Subscription;
  private router$: Subscription;

  constructor(
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private navigationService: NavigationService
  ) {
    if (this.isMobile) {
      this.setRouterSubscription();
    }
  }

  ngOnInit(): void {
    this.setupStore();
    this.setConfigDataSubscription();
  }

  ngOnDestroy() {
    this.configData$.unsubscribe();
    this.router$?.unsubscribe();
  }

  // set up store
  private setupStore(): void {
    this.configDataObservable = this.appStateFacadeService.getConfigData();
  }

  // set config data subsription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable.subscribe((configData: ConfigData) => {
      this.footer = configData?.footer;
      this.calculateCertHeight();
    });
  }

  // set router subscription
  private setRouterSubscription() {
    // cover case when footer initialize after route change
    this.testRouteForFooterVisibility(this.router.url.split('?')[0]);
    this.router$ = this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        // url after redirects without params
        this.testRouteForFooterVisibility(data.urlAfterRedirects.split('?')[0]);
      }
    });
  }

  private testRouteForFooterVisibility(pageUrl) {
    this.isFooterVisible = !HIDE_FOOTER_ON_EXACT_URLS.find(url => url === pageUrl);
    if (this.isFooterVisible) {
      this.isFooterVisible = !HIDE_FOOTER_ON_START_WITH_URLS.find(url => pageUrl.startsWith(url));
    }
  }

  // calculate cert max height
  private calculateCertHeight() {
    const length = this.footer?.certs?.length || 2;
    this.certHeight = GLOBAL_VARIABLES.MAX_OVERALL_CERT_HEIGHT / length;
  }

  // navigate to internal or external url
  public navigate(footerItem): void {
    if (!footerItem.url) {
      return;
    }

    // proceed registration dialog
    const player = this.appStateFacadeService.getPlayerData();
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA && player && !player.registrationCompleted) {
      this.navigationService.checkIfOnlyF2FLeftAndInProcess(player);
      return;
    }

    if (footerItem.urlType === 'INTERNAL') {
      if (footerItem.url.includes('http')) {
        window.open(footerItem.url, '_self');
      } else {
        this.router.navigateByUrl(footerItem.url);
        window.scrollTo(0, 0);
      }
    }

    if (footerItem.urlType === 'EXTERNAL') {
      window.open(footerItem.url, '_blank');
    }
  }
}
