import { AbstractControl, ValidationErrors } from '@angular/forms';

export function amountValidator(control: AbstractControl): ValidationErrors | null {
  const amount = control.get('amount');
  const ALLOW_NUMBERS_DOT_COMA_AND_1_OR_2_DECIMALS = /^[0-9]*([,.][0-9]{1,2})?$/;

  if (!amount.value) {
    return null;
  }

  // check amount format
  if (!ALLOW_NUMBERS_DOT_COMA_AND_1_OR_2_DECIMALS.test(amount.value)) {
    amount.setErrors({ amountInvalid: true });
  }

  // remove characters that are not allowed
  const ALLOW_NUMBERS_DOT_COMA = /[^0-9.,]/g;

  /**
   * Allow comma to be typed but replace it with dot
   * ( Ios input type numeric does not include dot).
   * and our backend does not accept comma.
   */
  if (amount.value.includes(',')) {
    control.patchValue({ amount: amount.value.replace(',', '.') });
  }

  // remove every character that is not allowed
  if (ALLOW_NUMBERS_DOT_COMA.test(amount.value)) {
    control.patchValue({ amount: amount.value.replace(ALLOW_NUMBERS_DOT_COMA, '') });
  }

  return null;
}
