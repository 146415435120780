import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-count-down-counter',
  templateUrl: './count-down-counter.component.html',
  styleUrls: ['./count-down-counter.component.scss'],
})
export class CountDownCounterComponent implements OnInit, OnDestroy {
  private momentToCountDown: Moment;
  public countEnded = false;
  public Math = Math;
  public hours: any = 0;
  public days: any = 0;
  public minutes: any = 0;
  public seconds: any = 0;
  private intervalSubscription$: Subscription;
  public isInputDateBeforeToday: boolean;
  @Input() labelForCounter = '';
  /**
   * time witch we have to count down from today
   */
  @Input() set countDownTime(timeForCountDown: number) {
    this.momentToCountDown = moment(timeForCountDown);
    this.isInputDateBeforeToday = this.momentToCountDown.isBefore(moment());
  }

  @Input() showBackground = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.prepareCounter();
  }

  ngOnDestroy(): void {
    this.intervalSubscription$.unsubscribe();
  }

  prepareCounter() {
    const intervalRXJS = timer(0, 1000);
    this.intervalSubscription$ = intervalRXJS.subscribe((): void => {
      const diffTodayToTime = this.isInputDateBeforeToday
        ? moment().diff(this.momentToCountDown)
        : this.momentToCountDown.diff(moment());
      if (diffTodayToTime <= 0) {
        this.countEnded = true;
        this.intervalSubscription$.unsubscribe();
        return;
      }
      this.days = Math.floor(moment.duration(diffTodayToTime).asDays());
      this.hours = moment.duration(diffTodayToTime).hours();
      this.minutes = moment.duration(diffTodayToTime).minutes();
      this.seconds = moment.duration(diffTodayToTime).seconds();
      this.cdRef.detectChanges();
    });
  }
}
