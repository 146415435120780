import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Player } from '../../shared/models/player.model';
import { baseServerUrl, environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BalanceInfo } from '@models/balance-info.model';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  constructor(private http: HttpClient) {}

  /**
   * Get player
   */
  getPlayer(): Observable<Player> {
    return this.http.get<Player>(environment.apiUrl + 'player');
  }

  /**
   * Update player
   * @param player - updated player from form
   * @param id - player id
   */
  updatePlayer(player, id): Observable<any> {
    return this.http.put(environment.apiUrl + 'registration/' + id, player);
  }

  /**
   * Get player balance
   */
  getPlayerBalance(): Observable<BalanceInfo> {
    return this.http.get<BalanceInfo>(environment.apiUrl + 'player/balance-info');
  }

  /**
   * Get nxcs (Admiral360) player balance
   */
  getAdmiral360PlayerBalance(): Observable<Admiral360BalanceInfo> {
    return this.http.get<Admiral360BalanceInfo>(
      baseServerUrl + '/nxcs-deposit/erne/api/nxcs-player/balance-info'
    );
  }

  /**
   * Get player phone number
   */
  getPhoneNumber(): Observable<any> {
    return this.http.get(environment.apiUrl + 'registration/check-cell-phone');
  }

  /**
   * Save phone number
   * @param fullNumber phoneCode + celPhone
   */
  savePhoneNumber(fullNumber): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'registration/mobile', { fullNumber });
  }

  /**
   * Verify phone number
   * @param verificationCode code for verification;
   */
  verifyPhoneNumber(verificationCode): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'registration/mobile/verify', { verificationCode });
  }

  /**
   * Delete phone verificationInfo
   */
  deletePhoneVerificationInfo(): Observable<any> {
    return this.http.delete(environment.apiUrl + 'registration/mobile/verify');
  }

  /**
   * Upload avatar
   * @param userId - user id;
   * @param avatar - object has properties data, fileName, type;
   */
  uploadAvatar(userId, avatar): Observable<any> {
    const path = environment.uploadAvatarPath.replace('{playerId}', userId);
    return this.http.post<any>(path, avatar);
  }

  /**
   * Get player email
   */
  getEmail(): Observable<any> {
    return this.http.get(environment.apiUrl + 'registration/check-email');
  }

  /**
   * Delete email verificationInfo
   */
  deleteEmailVerificationInfo(): Observable<any> {
    return this.http.delete(environment.apiUrl + 'registration/email/verify');
  }

  /**
   * Save email
   * @param email email for verification;
   */
  saveEmail(email): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'registration/email', { email });
  }

  /**
   * Verify email
   * @param verificationCode code for verification;
   */
  verifyEmail(verificationCode): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'registration/email/verify', { verificationCode });
  }

  /**
   * Get player reserved funds
   */
  getReservedFunds(): Observable<any> {
    return this.http.get<any>(baseServerUrl + '/reserved-funds/erne/api/reserved-funds/available');
  }

  /**
   * Validate IBAN
   * @param bankAccountNumber  verification;
   */
  checkIbanAccount(bankAccountNumber): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/iban?onlyValidateIBAN=true', { bankAccountNumber });
  }

  /**
   * Get player additional data
   */
  getAdditionalPlayerData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'player/additional-information');
  }

  /**
   * Save player additional data
   */
  savePlayerAdditionalData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/additional-information', data);
  }

  /**
   * Get player additional info data
   */
  getAddtionalPoliticianInfoOptions(): Observable<any> {
    return this.http.get(environment.apiUrl + 'player/additional-information-options');
  }

  /**
   *  Save player's political exposure info data
   */
  savePoliticianInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/politician-information', data);
  }

  /**
   * accept cashback
   */
  acceptCashback(cashbackId: number): Observable<any> {
    return this.http.put(environment.apiUrl + `cashback/${cashbackId}/claim`, {});
  }

  /**
   * Get registration player data (CRO REGISTRATION)
   */
  getRegistrationPlayerData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'registration/step-five');
  }

  /**
   * Get player rewards (CRO REGISTRATION)
   */
  getPLayerRewards(): Observable<any> {
    return this.http.get(environment.apiUrl + 'registration/rewards');
  }

  /**
   * Claim player rewards (CRO REGISTRATION)
   */
  claimPlayerRewards(): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/rewards', {});
  }

  /**
   * Get player f2f verification
   */
  checkPlayerF2FVerification(): Observable<any> {
    return this.http.get(environment.apiUrl + 'player/f2f-verified');
  }

  /**
   * Get player's face image. (CRO REGISTRATION)
   */
  getPlayerFaceImage(): Observable<any> {
    return this.http.get(`${environment.uploadFilePath}/micro-blink-face-image`);
  }

  /**
   * Check if identity verification is in progress. (CRO REGISTRATION)
   */
  isIdentityVerificationInProgress(): Observable<{ response: boolean }> {
    return this.http.get<{ response: boolean }>(environment.apiUrl + 'registration/is-f2f-in-progress');
  }
}
