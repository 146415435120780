import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Version } from './config/version.config';

/**
 * Hammerjs must be imported for gestures
 */
import 'hammerjs';

if (environment.production) {
  enableProdMode();
} else {
  console.log('VERSION FROM JS VERSION FILE:', (window as any).build_version);
  console.log('VERSION FROM CONFIG FILE:', Version);
}

// check version before load application
if ((window as any).build_version) {
  if ((window as any).build_version !== Version) {
    if (!environment.production) {
      console.warn('Reloading app to synchronize versions');
    }

    const reloadExpireTime = getReloadExpireTime();

    if (!reloadExpireTime) {
      setReloadExpireTime();
    } else {
      if (new Date().getTime() < reloadExpireTime) {
        // If the time is not expired just load app without reload
        platformBrowserDynamic()
          .bootstrapModule(AppModule)
          .catch(err => console.error(err));
      } else {
        // If the time is expired, set new expire time and reload
        setReloadExpireTime();
      }
    }
  } else {
    if (!environment.production) {
      console.warn('Versions are synchronized. Loading app...');
    }
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
  if (!environment.production) {
    console.warn('Build version from build_version.js file is undefined');
  }
}

// set time of reload to local storage
function setReloadExpireTime() {
  const expireTime = new Date().getTime() + 1800000;
  localStorage.setItem('reloadAppExpireTime', JSON.stringify(expireTime));
  location.reload();
}

// get time of reload to local storage
function getReloadExpireTime() {
  const expireTimeStr = localStorage.getItem('reloadAppExpireTime');

  if (!expireTimeStr) {
    return null;
  }
  const expireTime = JSON.parse(expireTimeStr);
  return expireTime;
}
