import { MenuItem } from './menu-item.model';

export class Menu {
  public isEnabled: boolean;
  public menuItems: MenuItem[];

  constructor(data) {
    this.isEnabled = data.isEnabled;
    this.menuItems = [];
    data.menuItems.forEach(menuItem => this.menuItems.push(new MenuItem(menuItem)));
  }
}
