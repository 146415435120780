import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-bonus-freebet-warning',
  templateUrl: 'bonus-freebet-warning.dialog.html',
  styleUrls: ['./bonus-freebet-warning.dialog.scss'],
})
export class BonusFreebetWarningDialog {
  public imagesBaseUrl = environment.imagesBaseUrl;
  @Input() public wrapper;
  @Input() public form;

  constructor(public helpers: HelpersService, public activeModal: NgbActiveModal) {}

  // proceed
  public proceed(): void {
    this.wrapper = null;
    this.activeModal.close(true);
  }

  // close dialog
  public closeModal(): void {
    this.wrapper = null;
    this.activeModal.close();
  }
}
