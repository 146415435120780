import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Wheel } from '@models/lucky-wheel.model';
import { PromotionService } from '@services/promotion.service';
import { AppStateFacadeService } from '@state/app-state.facade';

@Component({
  selector: 'app-available-lucky-wheels',
  templateUrl: './available-lucky-wheels.component.html',
  styleUrls: ['./available-lucky-wheels.component.scss'],
})
export class AvailableLuckyWheelsComponent implements OnInit, OnChanges {
  public luckyWheels: Wheel[];
  private countdownIntervals = {};
  public countdownValues = {};
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isRightArrowHidden: boolean;
  public isLeftArrowHidden: boolean;
  public arrowBackgroundColor: 'bgColor' | 'cardColor' = 'cardColor';
  @Input() luckyWheelResultData: any;
  @Output() detectChange = new EventEmitter<void>();
  @Output() selectWheel = new EventEmitter<Wheel>();
  @ViewChild('awailableWheelsContainer', { static: true })
  awailableWheelsContainer: ElementRef<HTMLDivElement>;

  constructor(
    private promotionService: PromotionService,
    private appStateFacadeService: AppStateFacadeService
  ) {}

  ngOnInit(): void {
    this.getLuckyWheels();
    this.detectChange.emit();
    // Initial check
    const mutObs = new MutationObserver(() => {
      this.handleArrowsVisibilityOnScroll();
      mutObs.disconnect();
    });
    mutObs.observe(this.awailableWheelsContainer.nativeElement, { childList: true });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.luckyWheelResultData) {
      if (!changes.luckyWheelResultData.currentValue) {
        return;
      }
      const selectedWheel = changes.luckyWheelResultData.currentValue;
      const wheelInList = this.luckyWheels.find(wheel => wheel.id === selectedWheel.id);
      wheelInList.nextSpinAvailableFrom = selectedWheel.nextSpinAvailableFrom;
      wheelInList.isSpinAvailable = selectedWheel.isSpinAvailable;
      if (!wheelInList.isSpinAvailable) {
        this.stopAnimation(wheelInList);
        this.setSpinAvailableTimer(wheelInList);
      }
    }
  }

  private stopAnimation(wheel) {
    const wheelEl = document.querySelector(`#spin-animation_${wheel.id}`) as HTMLElement;
    const style = window.getComputedStyle(wheelEl);
    const matrix = style.transform;
    const values = matrix.split('(')[1].split(')')[0].split(',');
    const a = parseFloat(values[0]);
    const b = parseFloat(values[1]);
    const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
    wheelEl.style.setProperty('--rotate', angle + 'deg');
    // wheelEl.classList.add('slowly-stop-animation');
  }

  public getLuckyWheels() {
    this.promotionService.getLuckyWheels().subscribe({
      next: response => {
        this.luckyWheels = response.map(wheel => new Wheel(wheel));
        // this.luckyWheels.pop();
        this.luckyWheels.forEach(wheel => {
          if (wheel.playerInWheelSegment && !wheel.isSpinAvailable) {
            this.setSpinAvailableTimer(wheel);
          }
        });
        this.selectWheel.next(this.luckyWheels[0]);
      },
    });
  }

  public select(wheel: Wheel) {
    this.selectWheel.next(wheel);
  }

  public setSpinAvailableTimer(wheel: any) {
    let now = new Date().getTime();
    let timeLeft = wheel.nextSpinAvailableFrom - now;

    if (this.countdownIntervals[wheel.id]) {
      clearInterval(this.countdownIntervals[wheel.id]);
    }

    const updateCountdown = () => {
      now = new Date().getTime();
      timeLeft = wheel.nextSpinAvailableFrom - now;

      if (timeLeft <= 0) {
        clearInterval(this.countdownIntervals[wheel.id]);
        // refresh data so we can get fresh data from the server
        wheel.isSpinAvailable = true;
      } else {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

        // Display the result to the user, for example:
        this.countdownValues[wheel.id] = '';
        if (days > 0) {
          this.countdownValues[wheel.id] = days + 'd ';
        }
        if (hours > 0) {
          this.countdownValues[wheel.id] += hours + 'h ';
        }
        if (minutes > 0) {
          this.countdownValues[wheel.id] += minutes + 'm ';
        }
      }
      this.detectChange.emit();
    };

    // Call the function once immediately
    updateCountdown();

    // Then set the interval for subsequent updates
    if (timeLeft > 0) {
      this.countdownIntervals[wheel.id] = setInterval(updateCountdown, 10000);
    }
  }

  public scroll(direction: string) {
    const scrollLength = direction === 'right' ? 400 : -400;
    this.awailableWheelsContainer.nativeElement.scrollBy({ left: scrollLength, behavior: 'smooth' });

    setTimeout(() => {
      this.handleArrowsVisibilityOnScroll();
    }, 400);
  }

  private handleArrowsVisibilityOnScroll() {
    const container = this.awailableWheelsContainer.nativeElement;
    if (container.scrollLeft + container.clientWidth !== container.scrollWidth) {
      this.isRightArrowHidden = false;
    }

    if (container.scrollLeft + container.clientWidth === container.scrollWidth) {
      this.isRightArrowHidden = true;
    }

    if (container.scrollLeft !== 0) {
      this.isLeftArrowHidden = false;
    }

    if (container.scrollLeft === 0) {
      this.isLeftArrowHidden = true;
    }
    this.detectChange.emit();
  }
}
