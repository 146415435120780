import { IImage } from '../header-cms/header-cms-menu-component/header-cms-menu.component';

class MenuItem {
  icon: string;
  image: null | IImage;
  index: number;
  link: string;
  url: string;
  text: string;
  title: string;
  type: string;
  localizationKey: string | null;
  assetType?: string;
  btnIcon?: string;

  constructor(data: any) {
    this.icon = data.icon || '';
    this.image = data.image || null;
    this.index = data.index || 0;
    this.link = data.link || '';
    this.url = data.link || '';
    this.text = data.text || '';
    this.title = data.title || '';
    this.assetType = data.assetType || null;
    this.btnIcon = data.btnIcon || null;
    this.type = data.typeOfLink || 'INTERNAL_LINK';
    this.localizationKey = data.localizationKey || null;
  }
}

class Asset {
  activeItem: string;
  id: number;
  items: MenuItem[];
  title: string;

  constructor(data: any) {
    this.activeItem = data.activeItem || '';
    this.id = data.id || 0;
    this.items = data.items ? data.items.map((item: MenuItem) => new MenuItem(item)) : [];
    this.title = data.title || '';
  }
}

export class SideBar {
  active: boolean;
  menuAsset: MenuItem[]; // Replace with the actual type of the menu items if known

  constructor(active: boolean, menuAsset: any) {
    this.active = active;
    this.menuAsset = menuAsset.map((asset: any) => new Asset(asset));
  }
}

export class SideMenusConfigurations {
  desktopWidth: { leftSideBar: SideBar; leftSection: SideBar; rightSideBar: SideBar };
  tabletWidth: { leftSideBar: SideBar; leftSection: SideBar; rightSideBar: SideBar };
  mobileWidth: { leftSideBar: SideBar; leftSection: SideBar; rightSideBar: SideBar };

  constructor(data: any) {
    this.desktopWidth = {
      leftSideBar: new SideBar(data.desktopWidth.leftSideBar.active, data.desktopWidth.leftSideBar.menuItems),
      leftSection: new SideBar(data.desktopWidth.leftSection.active, data.desktopWidth.leftSection.menuItems),
      rightSideBar: new SideBar(
        data.desktopWidth.rightSideBar.active,
        data.desktopWidth.rightSideBar.menuItems
      ),
    };
    this.tabletWidth = {
      leftSideBar: new SideBar(data.tabletWidth.leftSideBar.active, data.tabletWidth.leftSideBar.menuItems),
      leftSection: new SideBar(data.tabletWidth.leftSection.active, data.tabletWidth.leftSection.menuItems),
      rightSideBar: new SideBar(
        data.tabletWidth.rightSideBar.active,
        data.tabletWidth.rightSideBar.menuItems
      ),
    };
    this.mobileWidth = {
      leftSideBar: new SideBar(data.mobileWidth.leftSideBar.active, data.mobileWidth.leftSideBar.menuItems),
      leftSection: new SideBar(data.mobileWidth.leftSection.active, data.mobileWidth.leftSection.menuItems),
      rightSideBar: new SideBar(
        data.mobileWidth.rightSideBar.active,
        data.mobileWidth.rightSideBar.menuItems
      ),
    };
  }
}
