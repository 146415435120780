import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionFilterModel } from '@models/transaction-filter-model';
import { TranslateService } from '@ngx-translate/core';
import { TransactionFilterService } from '@services/transaction-filter.service';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-transaction-filter',
  templateUrl: './transaction-filter.component.html',
  styleUrls: ['./transaction-filter.component.scss'],
})
export class TransactionFilterComponent implements OnInit, OnDestroy {
  /**
   * List of search periods
   * Has to be a behaviouSubject because we need to change periond based on active tab
   */
  private searchPeriodsSubject = new BehaviorSubject<string[]>([]);
  public searchPeriodsObservable = this.searchPeriodsSubject.asObservable();

  /**
   * Translated search periods that will be displayed
   */
  searchPeriodsTranslatedObservable = this.searchPeriodsObservable.pipe(
    map(items => items.map(item => this.translateService.instant(item)))
  );
  searchPeriodsPatchValueObservable = this.searchPeriodsTranslatedObservable.pipe(map(items => items[0]));

  @Input() filterOpen: boolean;
  @Input() transactionQueryParams;
  @Input() haveActiveFilterSection: boolean;

  private filter$: Subscription;
  private activeFilterSection$: Subscription;

  private filterQueryParams = {};

  /*
   * we will using assign f-tn to past value to this object because this filter will change
   * its value on overy filter change and we dont want it to have references to other objects
   */
  public filter = new TransactionFilterModel();

  constructor(
    private router: Router,
    private transactionFilterService: TransactionFilterService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.searchPeriodsSubject.next(
      this.haveActiveFilterSection
        ? this.transactionFilterService.getFilterPeriods(this.haveActiveFilterSection)
        : this.transactionFilterService.getFilterPeriods()
    );

    this.setFilterModelSubscription();
    this.setActiveFilterSectionSubscription();
    // assign init value to filter model, that will be TODAY
    Object.assign(this.filter, this.transactionFilterService.getInitialFilterValue());
  }

  ngOnDestroy(): void {
    // reset filter model, set it back to initial TODAY value
    this.transactionFilterService.resetFilterValue();
    this.transactionQueryParams = null;
    this.filter$.unsubscribe();
    // unset active section on destroy
    this.transactionFilterService.addActiveFilterSection(false);
    this.activeFilterSection$.unsubscribe();
  }

  // set filter model subscription to listen for changes on filter model from other components or service
  private setFilterModelSubscription() {
    this.filter$ = this.transactionFilterService.filterModelObservable.subscribe(newFilterState => {
      // check if new filter state is not equal to state that is already on scope
      if (JSON.stringify(newFilterState) !== JSON.stringify(this.filter)) {
        Object.assign(this.filter, newFilterState);
      }
    });
  }

  // observe if we neeed to update filter with active filter section
  private setActiveFilterSectionSubscription() {
    this.activeFilterSection$ = this.transactionFilterService.activeFilterSectionObservable.subscribe(
      addActiveFilterSection => {
        this.searchPeriodsSubject.next(
          addActiveFilterSection
            ? this.transactionFilterService.getFilterPeriods(addActiveFilterSection)
            : this.transactionFilterService.getFilterPeriods()
        );
      }
    );
  }

  public onSelect(translatedPeriod: string) {
    const periods = this.searchPeriodsSubject.value;
    const period = periods.find(
      singlePeriod => this.translateService.instant(singlePeriod) === translatedPeriod
    );
    this.onFilterChange(period);
  }

  // when period filter is changed set dates for that period
  public onFilterChange(period): any {
    this.filter.period = period;

    // We have to override this observable to return selected item instead of first that is default
    this.searchPeriodsPatchValueObservable = of([period]).pipe(
      map(items => items.map(item => this.translateService.instant(item)))
    );

    Object.assign(this.filter, this.transactionFilterService.setFilter(period));
    // add start and end date to filter query params from filter service
    this.filterQueryParams = {
      filterChange: true,
      ...this.transactionFilterService.getFilterQueryParams(this.haveActiveFilterSection),
    };
    this.applyFilterSearch();
  }

  // set params to route url
  private applyFilterSearch(): void {
    // add transactions query params to filter query params and navigate
    const queryParams = { ...this.transactionQueryParams, ...this.filterQueryParams };
    this.router.navigate([], { queryParams });
  }
}
