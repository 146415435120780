export const NIGERIA_THEME_COLORS = [
  { backgroundColor: '#181a1d' },
  { sideMenuColor: '#000000' },
  { admiralButtonColor: '#000000' },
  { sideMenuBackgroundColor: '#000000' },
  { primaryColor: '#ACD927' },
  { secondaryColor: '#204430' },
  { secondaryMenuColor: '#204430' },
  { successColor: '#98ff65' },
  { tertiaryColor: '#98ff65' },
  { cardColor: '#212325' },
  { cardColorActive: '#2e3238' },
  { filterColor: '#181a1d' },
  { borderColor: '#313131' },
  { headerActiveElementColor: '#627232' },
  { boxRowColor: '#26282a' },
  { boxRowDesktopColor: '#26282a' },
  { boxRowBorder: '1px solid #2f3235' },
  { inputColor: '#282b2d' },
  { boxInputBackgroundColor: '#282b2d' },
  { inputFontColor: '#fff' },
  { inputBorderColor: '#34373b' },
  { depositCardColor: '#313131' },
  { desktopCardColor: '#212325' },
  { lobbyGameSearchColor: '#2f3334' },
  { btnDepositClass: 'btn-outline-success' },
  { gameInfoBoxColor: 'rgba(33, 35, 37, 0.96)' },
  { activeWithdrawalBckgColor: 'rgb(150, 54, 54)' },
  { helpPageBackgroundColor: '#2b2d30' },
  { btnDisabledColor: '#ACD927' },
  { btnPrimaryHoverColor: '#819d11' },
  { btnPrimaryBorderHoverColor: '#758f12' },
  { btnPrimaryBorderRadius: '2rem' },
  { btnAdmiralBorderRadius: '4px' },
  { btnPrimaryShadowColor: 'rgba(129, 149, 9, 0.5)' },
  { lightGrayColor: '#6c757d' },
  { backgroundImage: 'slot-theme-background-image-green.svg' },
  { tournamentGradient: 'linear-gradient(116.84deg, #204632 0.88%, #30694b 100%)' },
  { leaderboardDialogGradient: 'linear-gradient(to bottom, #102319, #30694b)' },
  { fontFamily: 'Montserrat, sans-serif' },
  { fontColor: '#fff' },
  { primaryButtonTextColor: '#212529' },
  { buttonOutlineColor: '#212529;' },
  { subgroupButtonColor: '#181a1d' },
  { labelTextTransform: 'uppercase' },
  { labelFontSize: '10px' },
  { btnDarkColor: '#fff' },
  { linkNoHrefColor: 'inherit' },
  // toaster colors
  { toasterSuccessColor: '#c9ffaf' },
  { toasterSuccessBackgroundColor: '#1e3314' },
  { toasterSuccessBorderColor: '#2b471c' },
  { toasterSuccessAlertLinkColor: '#a7ff7c' },
  { toasterDangerColor: '#ff92b7' },
  { toasterDangerBackgroundColor: '#330917' },
  { toasterDangerBorderColor: '#470d21' },
  { toasterDangerAlertLinkColor: '#ff5f95' },
  { titleFontSize: '1.125rem' },
  { titleFontSizeMobile: '1.125rem' },
  { customSelectHoverColor: '#38393b' },
  { darkBtnHoverBackground: '#070708' },
  // Date picker colors
  { datePickerBorderColor: '#34373b' },
  { datePickerActiveElementBackground: '#393e41' },
  { datePickerSelectContainerBackground: '#282b2d' },
  { datePickerSelectContainerColor: '#fff' },
  { datePickerDayButtonBackground: '#282b2d' },
  { datePickerDayButtonColor: '#fff' },
];
