import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderCmsComponent } from './header-cms.component';
import { HeaderCmsUserOperationComponent } from './user-operation-elements/header-cms-user-operation.component';
import { SharedModule } from '../../shared/shared.module';
import { HeaderCmsMenuComponent } from './header-cms-menu-component/header-cms-menu.component';



@NgModule({
  declarations: [
    HeaderCmsComponent,
    HeaderCmsUserOperationComponent,
    HeaderCmsMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    HeaderCmsComponent
  ]
})
export class CmsHeaderModule { }
