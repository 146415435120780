import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Deposit } from '../../shared/models/deposit.model';
import { baseServerUrl, environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  constructor(private http: HttpClient) {}

  /**
   * Get deposits
   */
  getDeposits(): Observable<Deposit[]> {
    return this.http.get<Deposit[]>(environment.apiUrl + 'payment-product/deposit');
  }

  /**
   * Get deposit by code
   */
  getDeposit(code): Observable<Deposit> {
    return this.http.get<Deposit>(environment.apiUrl + `payment-product/deposit/${code}`);
  }

  /**
   * Get allsecure deposit
   */
  getAllsecureDeposit(txnId): Observable<any> {
    return this.http.get(baseServerUrl + `/allsecure-deposit/erne/api/allsecure/txn-details/${txnId}`);
  }

  /**
   * Get all cards that are remebered for player
   */
  getAllsecurePlayerCreditCards(): Observable<any> {
    return this.http.get(baseServerUrl + `/allsecure-deposit/erne/api/allsecure/player-credit-cards`);
  }

  /**
   * Delete allsecure card
   */
  deleteCard(data): Observable<any> {
    return this.http.post(baseServerUrl + `/allsecure-deposit/erne/api/allsecure/delete-credit-card`, data);
  }

  /**
   * Send glovo voucher
   */
  sendGlovoVoucher(code): Observable<any> {
    return this.http.post(baseServerUrl + `/glovo-deposit/erne/api/glovo/voucher`, { code });
  }

  /**
   * Send voucher
   */
  sendVoucher(code): Observable<any> {
    return this.http.post(baseServerUrl + `/voucher-deposit/erne/api/voucher`, { code });
  }

  /**
   * Allsecure deposit
   * @param url - deposit url
   * @param data - Object with properties: amount, allsecure payment token
   */
  allsecureDeposit(url, data): Observable<any> {
    return this.http.post(url, data);
  }

  /**
   * @param url - deposit url
   * @param voucherCode - voucher code
   */
  depositVoucher(url, voucherCode, promoCode?): Observable<any> {
    const postDTO = promoCode ? { voucherCode, promoCode } : { voucherCode };
    return this.http.post(url, postDTO);
  }

  /**
   * @param promoCode - deposit promoCode included
   * response is boolean
   */
  promoCodeForCreditCard(promoCode) {
    return this.http.get(`${environment.apiUrl}bonus/promo-code?promoCode=${promoCode}`);
  }

  /**
   * @param url - deposit url
   * @param amount - amount
   */
  depositAmount(url, amount, promoCode?): Observable<any> {
    const postDTO = promoCode ? { amount, promoCode } : { amount };
    return this.http.post(url, postDTO);
  }

  /**
   * @param url - deposit url
   * @param voucherCode - amount
   */
  depositCoupon(url, voucherCode, promoCode?): Observable<any> {
    const postDTO = promoCode ? { coupon: voucherCode, promoCode } : { coupon: voucherCode };
    return this.http.post(url, postDTO);
  }

  /**
   * @param url - deposit url
   * @param data - deposit data for corvus iban
   */
  corvusIbanDeposit(url, data): Observable<any> {
    return this.http.post(url, data);
  }

  /**
   * @param amount - deposit data for corvus form
   * Corvus form witch are implemented in quick bar here send payment
   * information to BE.
   */
  corvusFormDeposit(amount): Observable<any> {
    return this.http.post(baseServerUrl + '/corvus-deposit/erne/api/payment/corvus-deposit/frame', amount, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  corvusFormDepositSavedCard(paymentInfo): Observable<any> {
    return this.http.post(
      baseServerUrl + '/corvus-deposit/erne/api/payment/corvus-deposit/frame/subscription-payment',
      paymentInfo,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  /**
   * Get Corvus form saved credit cards
   */
  getCorvusSavedCreditCards(): Observable<any> {
    return this.http.get(
      baseServerUrl + '/corvus-deposit/erne/api/payment/corvus-deposit/player-credit-cards'
    );
  }

  /**
   * Delete saved credit card corvus frame
   */
  deleteCorvusSavedCreditCard(data: string): Observable<any> {
    return this.http.post(
      baseServerUrl + '/corvus-deposit/erne/api/payment/corvus-deposit/delete-credit-card',
      data
    );
  }

  /**
   * @param objectForPost object recieved from corvus frame sucesuful payment process
   * After a successful payment process in corvus frame, we need to update user deposit amount
   * becouse we must give player instant/real information about his balance
   */
  callApiToRefreshUserDepositAmount(objectForPost) {
    return this.http.post(
      baseServerUrl + '/corvus-deposit/erne/api/payment/corvus-deposit/frame/process-payment',
      objectForPost,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  /**
   * Admiral360 deposit
   * save internal transaction from nxcs to mbase
   * @param url - deposit url
   * @param amount - amount
   */
  saveBalanceToMbase(url, amount): Observable<any> {
    return this.http.post(url, { amount });
  }

  /**
   * Payspot deposit locations
   */
  getPayspotDepositLocation(): Observable<any> {
    return this.http.get(environment.apiUrl + 'payspot/locations');
  }

  /**
   * Altapay deposit locations
   */
  getAltapayDepositLocation(): Observable<any> {
    return this.http.get(environment.apiUrl + 'alta-pay/locations');
  }

  /**
   * Admiral office  locations
   * @params - include city param (name of the city) to return offices for that city
   * or without to return all offices
   */
  getAdmiralOfficeLocations(): Observable<any> {
    return this.http.get(environment.apiUrl + 'office');
  }

  /**
   * Save player online balance from reserved funds
   * @param data { increase = false, amount, playerId, currencyId }
   */
  saveReservedFundsToMbase(data): Observable<any> {
    return this.http.post(baseServerUrl + '/reserved-funds/erne/api/reserved-funds/transfer', data);
  }

  /**
   * Get A-Voucher code
   * @param url
   */
  getAvoucherCode(url): Observable<any> {
    return this.http.get(url);
  }

  /**
   * Validate S-BON code
   * @param url
   * @param vaucherCode
   */
  validateSboneVaucher(url, vaucherCode): Observable<any> {
    return this.http.get(url + '/balance', {
      params: new HttpParams().set('voucherCode', vaucherCode),
    });
  }

  /**
   * Deposit S-BON code
   * @param url
   * @param data
   */
  depositSboneVaucher(url, data): Observable<any> {
    return this.http.post(url + '/deposit', data);
  }

  /**
   * Get Nuvei payment options previus used credit cards
   */
  getNuveiPaymentOptions(operation: string) {
    return this.http.get(baseServerUrl + `/nuvei-${operation}/erne/api/nuvei-${operation}/payment-options`);
  }

  /**
   * Delete saved card
   */
  deleteNuveiCard(cardId): Observable<any> {
    return this.http.post(
      baseServerUrl + `/nuvei-deposit/erne/api/nuvei-deposit/payment-option/delete/${cardId}`,
      {}
    );
  }

  getNuveiConfig(): Observable<any> {
    return this.http.get(baseServerUrl + '/nuvei-deposit/erne/api/nuvei-deposit/get-config');
  }

  /**
   * Get Nuvei payment token
   */
  getNuveiOpenOrder(objectForSend): Observable<any> {
    return this.http.post(baseServerUrl + '/nuvei-deposit/erne/api/nuvei-deposit/open-order', {
      ...objectForSend,
    });
  }

  /**
   * Get Nuvei payment token
   */
  withdrawNuveiBalance(data: { amount: number; userPaymentOptionId: number }): Observable<any> {
    return this.http.post(baseServerUrl + '/nuvei-withdrawal/erne/api/nuvei-withdrawal/withdrawal', data);
  }

  /**
   * Okto deposit on deposit request we send amount and get qr code
   */
  getOktoDepositQRCode(amountForDeposit): Observable<any> {
    return this.http.post(
      baseServerUrl + `/okto-deposit/erne/api/okto-deposit/init-deposit?amount=${amountForDeposit}`,
      {}
    );
  }

  /**
   * Get OKTO deposit requests approved / or pending
   */
  getOktoDepositRequests(): Observable<any> {
    return this.http.get(baseServerUrl + '/okto-deposit/erne/api/okto-deposit/payment-code');
  }

  /**
   * Cancel OKTO deposit request
   */
  cancelOktoDepositRequest(requestID: number): Observable<any> {
    return this.http.post(
      baseServerUrl + `/okto-deposit/erne/api/okto-deposit/deactivate-code/${requestID}`,
      {},
      { responseType: 'text' }
    );
  }
}
