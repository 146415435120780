import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@services/language.service';
import { Language } from '@models/language.model';
import { LocalStorageService } from '@services/local-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

export enum DIRECTION {
  UP = 'up',
  DOWN = 'down',
}

export enum SWITCH_BTN_TYPES {
  LANGUAGE_CHOOSER = 'languageChooser',
  CHATCLIENT = 'chatClient',
}

@Component({
  selector: 'app-language-chooser-v2',
  templateUrl: './language-chooser-v2.component.html',
  styleUrls: ['./language-chooser-v2.component.scss'],
})
export class LanguageChooserV2Component implements OnInit {
  public activeLanguage: string;
  public languagesList: Language[];
  public chooserExpanded = false;
  public btnTypes = SWITCH_BTN_TYPES;

  public unreadChat;
  public chatEnabled: boolean;

  @Input() scroll = DIRECTION.UP;
  @Input() type: string;

  constructor(
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.activeLanguage = event.lang;
    });
    this.getLanguages();
    this.setApplicationInitLanguage();
  }

  // get languages
  private getLanguages(): void {
    this.languageService.getLanguages().subscribe(data => {
      if (this.scroll === DIRECTION.UP) {
        this.languagesList = [
          ...data.filter(filterData => filterData.isocode2 !== this.activeLanguage),
          data.find(findData => findData.isocode2 === this.activeLanguage),
        ];
      } else {
        this.languagesList = [
          data.find(findData => findData.isocode2 === this.activeLanguage),
          ...data.filter(filterData => filterData.isocode2 !== this.activeLanguage),
        ];
      }
    });
  }

  // set app language
  private setApplicationInitLanguage(): void {
    this.activeLanguage = this.localStorageService.getLanguage();
  }

  public changeLanguage(language) {
    if (language !== this.activeLanguage) {
      this.activeLanguage = language;
      this.localStorageService.setLanguage(language);
      this.translate.getTranslation(language);
      this.translate.use(language);
      this.chooserExpanded = false;
      location.reload();
    }
  }
  public expandLangChooser() {
    this.chooserExpanded = !this.chooserExpanded;
  }
}
