<div class="transaction-filter-container">
  <div *ngIf="haveActiveFilterSection" class="filter-type-container">
    <label class="label required">{{ 'CHOOSE_TRANSACTION_FILTER_TYPE' | translate }}</label>
    <select
      class="custom-select filter-type-selector pointer"
      (change)="onFilterTypeChange($event.target.value)"
    >
      <option [value]="filterType.DATE_PICKER">
        {{ 'SEARCH_BY_DATE' | translate }}
      </option>
      <option [value]="filterType.ACTIVE_ONLY">
        {{ 'SEARCH_ACTIVE_TICKETS' | translate }}
      </option>
    </select>
  </div>

  <div *ngIf="showDatepicker">
    <label for="transactionPeriod" class="label">{{ 'TRANSACTION_FILTER_BY_DATE' | translate }}</label>
    <input
      type="text"
      ngbDatepicker
      #dp="ngbDatepicker"
      id="transactionDate"
      name="dp"
      autocomplete="off"
      class="form-control date-picker-input"
      [formControl]="selectedDate"
      [minDate]="lastMonthObject"
      [maxDate]="todayObject"
      (click)="dp.toggle()"
      (dateSelect)="onDateSelect($event)"
      readonly
    />
  </div>
</div>
