import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { HelpersService } from '@services/helpers.service';
import { AppStateFacadeService } from '@state/app-state.facade';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private helperService: HelpersService,
    private appStateFacadeService: AppStateFacadeService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.localStorageService.getToken();
    const language = this.localStorageService.getLanguage();
    const isMobileStatus = this.appStateFacadeService.getIsMobileStatus();

    const headers = {
      'Content-Language': `${language}`,
      'X-MBASE-TOKEN': `${token}`,
      'X-PORTAL': isMobileStatus ? 'MOBILE' : 'WEB_BROWSER',
    };

    const requestChanges = {
      setHeaders: headers,
    };

    // add timestamp to every GET http req (cache API problem fix)
    if (request.method === 'GET') {
      requestChanges['params'] = request.params.append('t', Date.now());
    }

    if (request.method === 'POST' || request.method === 'PUT') {
      requestChanges['body'] = this.helperService.trimObjectProperties(request.body);
    }

    if (this.localStorageService.getReferalCode()) {
      headers['Referral'] = this.localStorageService.getReferalCode();
    }

    request = request.clone(requestChanges);
    return next.handle(request);
  }
}
