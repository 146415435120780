import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseServerUrl, environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Bonus } from '@models/bonus.model';
@Injectable({ providedIn: 'root' })
export class BonusService {
  public refreshPromotionsActivityTab = new BehaviorSubject<string>('');
  public getRefreshedPromotionsActivityTab = this.refreshPromotionsActivityTab.asObservable();

  private activeBonuses = new BehaviorSubject<any>({});
  public getActiveBonusesObservable = this.activeBonuses.asObservable();

  private activeFreebets = new BehaviorSubject<any>({});
  public getActiveFreebtesObservable = this.activeFreebets.asObservable();

  private promotionsActivityHistory = new BehaviorSubject<any>({});
  public getPromotionsActivityHistoryObservable = this.promotionsActivityHistory.asObservable();

  constructor(private http: HttpClient) {}

  // get active bonuses
  getActiveBonuses(): void {
    this.http.get(environment.apiUrl + 'player/bonuses?active=true').subscribe(data => {
      this.activeBonuses.next(data);
    });
  }

  // get total bonus amount
  getTotalBonusAmount(): Observable<any> {
    return this.http.get(environment.apiUrl + 'app-configuration/total-bonus-amount');
  }

  // get game total bonus amount
  getGameBonusAmount(gameId): Observable<any> {
    return this.http.get(environment.apiUrl + `player/total-bonus-amount?gameId=${gameId}`);
  }

  // get active freebets
  getActiveFreebets(): void {
    this.http.get(environment.apiUrl + 'player/bonuses?isFreebet=true').subscribe(data => {
      this.activeFreebets.next(data);
    });
  }

  // get promotions activity history
  getPromotionsActivityHistory(params?): void {
    this.http.get(environment.apiUrl + 'player/bonuses?active=false', { params }).subscribe(data => {
      this.promotionsActivityHistory.next(data);
    });
  }

  /**
   * accept bonus
   * @param bonus - Bonus Model
   */
  acceptBonus(bonus: Bonus): Observable<any> {
    return this.http.put(environment.apiUrl + 'player/bonuses/' + bonus.id, bonus);
  }

  /**
   * accept bonus CRO
   */
  acceptPlayerBonus(playerId, bonusId): Observable<any> {
    return this.http.post(environment.apiUrl + `player/${playerId}/bonus/${bonusId}/eligible`, {});
  }

  /**
   * check if bonus or freebet will be deactivated
   * @param withdrawalAmount - amount for withdrawal
   */
  checkIfBonusOrFreebetWillBeDeactivated(withdrawalAmount: number): Observable<any> {
    return this.http.get(environment.apiUrl + 'bonus', {
      params: {
        amount: withdrawalAmount.toString(),
      },
    });
  }

  /**
   * get locked bonuses
   * @param type - BONUS || FREEBET
   */
  getLockedBonuses(type): Observable<any> {
    return this.http.get(environment.apiUrl + 'player/locked-bonuses', {
      params: {
        type,
      },
    });
  }

  /**
   * GET bonus buy by id
   * @param id - bonus buy id
   */
  getBonusBuy(id): Observable<any> {
    return this.http.get(environment.apiUrl + `bonus/bonus-buy/${id}`);
  }

  /**
   * buy bonus
   * @param amount - player balance amount to buy bonus
   */
  buyBonus(amount): Observable<any> {
    return this.http.post(baseServerUrl + '/bonus-buy-withdrawal/erne/api/bonus-buy-withdrawal', { amount });
  }
}
