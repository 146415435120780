export class Player {
  activationDate: string;
  address: string;
  authorities: null;
  bankAccount: string;
  blinkingCustomerId: string;
  cellPhone: number;
  phoneCode: string;
  city: string;
  country: number;
  currency: string;
  currencyId: number;
  dateOfBirth: string;
  dateOfBirthStr: string;
  email: string;
  firstName: string;
  gender: string;
  id: number;
  externalId: string;
  lastName: string;
  loginAttemptCounter: number;
  note: null;
  password: string;
  permission: null;
  phoneNumber: number;
  pid: string;
  passportNumber: string;
  profileImageUrl: null;
  profileRole: string;
  roles: null;
  status: string;
  taxEligible: boolean;
  token: null;
  updatedBy: null;
  updatedOn: null;
  username: string;
  verificationDoc: null;
  verificationMimeType: null;
  wallets: null;
  zip: null;
  acceptedTandC: boolean;
  allowProcessingOfAbon: boolean;
  subscriber: boolean;
  uploadedAdditionalInfo: boolean;
  allowProcessingOfPersonalDocuments: boolean;
  allowProcessingOfPersonalInfo: boolean;
  microBlinkDoc: any[] | null;
  microBlinkValidatedStatus: boolean;
  referAFriendCode: string;
  politician: boolean;
  politicalQuestions: any | null;
  registrationSteps: { registration_step: string; is_completed: boolean | null }[];
  registrationCompleted: boolean;
  registrationProcessCompleted: boolean;

  constructor(data) {
    this.activationDate = data.activationDate;
    this.address = data.address;
    this.authorities = data.authorities;
    this.bankAccount = data.bankAccount;
    this.blinkingCustomerId = data.blinkingCustomerId;
    this.cellPhone = data.cellPhone;
    this.phoneCode = data.phoneCode;
    this.city = data.city;
    this.country = data.country === 0 ? null : data.country;
    this.currency = data.currency;
    this.currencyId = data.currencyId === 0 ? null : data.currencyId;
    this.dateOfBirth = data.dateOfBirth;
    this.dateOfBirthStr = data.dateOfBirthStr;
    this.email = data.email;
    this.firstName = data.firstName;
    this.gender = data.gender === null ? 'MALE' : data.gender;
    this.id = data.id;
    this.externalId = data.externalId;
    this.lastName = data.lastName;
    this.loginAttemptCounter = data.loginAttemptCounter;
    this.note = data.note;
    this.password = data.password;
    this.permission = data.permission;
    this.phoneNumber = data.phoneNumber;
    this.pid = data.pid;
    this.passportNumber = data.passportNumber;
    this.profileImageUrl = data.profileImageUrl;
    this.profileRole = data.profileRole;
    this.roles = data.roles;
    this.status = data.status;
    this.taxEligible = data.taxEligible;
    this.token = data.token;
    this.updatedBy = data.updatedBy;
    this.updatedOn = data.updatedOn;
    this.username = data.username;
    this.verificationDoc = data.verificationDoc;
    this.verificationMimeType = data.verificationMimeType;
    this.wallets = data.wallets;
    this.zip = data.zip;
    this.acceptedTandC = data.acceptedTandC;
    this.allowProcessingOfAbon = data.allowProcessingOfAbon;
    this.subscriber = data.subscriber;
    this.allowProcessingOfPersonalDocuments = data.allowProcessingOfPersonalDocuments;
    this.allowProcessingOfPersonalInfo = data.allowProcessingOfPersonalInfo;
    this.microBlinkDoc = data.microBlinkDoc;
    this.microBlinkValidatedStatus = data.microBlinkValidatedStatus;
    this.referAFriendCode = data.referAFriendCode;
    this.politician = data.politician;
    this.politicalQuestions = data.politicalQuestions;
    this.registrationSteps = data.registrationSteps;
    this.registrationCompleted = data.registrationCompleted;
    this.registrationProcessCompleted = data.registrationProcessCompleted;
  }
}
