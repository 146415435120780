<div class="box">
  <header class="py-2">
    <div class="row pl-2 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{ 'BONUS_FREEBET_WARNING_TITLE' | translate }}</div>
      </div>
    </div>
  </header>
  <div class="row pt-4">
    <div class="col-12">
      <div class="h5 mb-2 mb-md-2">{{ 'BONUS_FREEBET_WARNING_TEXT' | translate }}</div>
      <br />
      <div *ngIf="wrapper?.bonuses && wrapper?.bonuses.length">
        {{ 'BONUS_FREEBET_WARNING_BONUSES' | translate }} :
        <div *ngFor="let bonus of wrapper.bonuses">
          <div class="box-row mb-2 p-2">
            <div class="d-flex">
              <div class="flex-fill text-xl-center">
                <div class="row row-sm py-2 h-100">
                  <div class="col-sm-2 col-xl-1 mb-2 mb-lg-0">
                    <small class="text-muted">{{ 'BONUS_FREEBET_WARNING_BONUSES_ID' | translate }}</small>
                    <div>{{ bonus.id }}</div>
                  </div>
                  <div class="col-sm-10 col-xl-5 mb-2 mb-lg-0">
                    <small class="text-muted">{{ 'BONUS_FREEBET_WARNING_BONUSES_NAME' | translate }}</small>
                    <div>{{ bonus?.name }}</div>
                  </div>
                  <div class="col-sm-6 col-xl-3 mb-2 mb-lg-0">
                    <small class="text-muted">{{ 'BONUS_FREEBET_WARNING_BONUSES_AMOUNT' | translate }}</small>
                    <div class="text-primary">
                      {{ bonus?.amount | number: '1.2-2' | currency: bonus?.currency }}
                    </div>
                  </div>
                  <ng-template #tipContent_amount_first
                    >{{'BONUS_FREEBET_WARNING_BONUSES_MAX_AMOUNT_INFO' | translate }}</ng-template
                  >
                  <div
                    class="col-sm-6 col-xl-3 mb-2 mb-lg-0"
                    #tooltipInstance="ngbTooltip"
                    placement="top"
                    [ngbTooltip]="tipContent_amount_first"
                    [autoClose]="'inside'"
                    (click)="helpers.closeTooltipOnClick(tooltipInstance, 5000)"
                  >
                    <small class="text-muted"
                      >{{ 'BONUS_FREEBET_WARNING_BONUSES_MAX_AMOUNT' | translate }}</small
                    >
                    <div>
                      {{ bonus?.maxWithdrawableAmount | number: '1.2-2' | currency: bonus?.currency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="h6 mb-2 mb-md-2 text-right">
            {{ 'BONUS_FREEBET_WARNING_BONUS_SUM' | translate }} : {{ wrapper?.bonusSum | number: '1.2-2' |
            currency: bonus?.currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="col-12 col-lg-12 text-right pt-2 pt-lg-0">
    <button (click)="proceed()" class="btn btn-primary btn-md">{{ 'CONTINUE' | translate }}</button>
    <button (click)="closeModal()" class="btn btn-outline-light btn-md ml-2">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
