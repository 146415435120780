<section
  class="button-bar-section print-hide"
  [ngClass]="{ 'pinnbet-button-bar': environment.clientName === clientNames.ADMIRAL_PINNBET }"
>
  <div
    *ngIf="!isMobile && !isLeftArrowHidden"
    class="arrow-wrapper arrow-wrapper_{{ arrowBackgroundColor }} arrow-wrapper_left"
    [ngClass]="theme"
  >
    <div class="arrow-animation icon icon-chevron-left" (click)="scrollLeft()"></div>
    <div class="shadow shadow-left shadow-left_{{ arrowBackgroundColor }}" [ngClass]="theme"></div>
  </div>
  <div class="button-bar-container" #buttonsBarContainer [ngClass]="{ dragging: dragging }">
    <span
      [id]="button.id"
      [ngClass]="{ active: router.url.includes(button.url) }"
      (click)="emitEvent(button)"
      *ngFor="let button of buttonBarItems"
      class="button"
    >
      <small>
        {{ button.translateKey ? (button.translateKey | translate) : button.text }}
      </small>
      <span *ngIf="button.totalOfItem" class="c_button-bar-total">({{ button.totalOfItem }})</span>
    </span>
  </div>
  <div
    *ngIf="!isMobile && !isRightArrowHidden"
    class="arrow-wrapper arrow-wrapper_{{ arrowBackgroundColor }} arrow-wrapper_right"
    [ngClass]="theme"
  >
    <div class="arrow-animation icon icon-chevron-right" (click)="scrollRight()"></div>
    <div class="shadow shadow-right shadow-right_{{ arrowBackgroundColor }}"></div>
  </div>
</section>
