import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/** This service can be used to store and provide html elements through the application
 *
 *  usage example:
 *
 *  IN SERVICE:
 *  declare property public name_of_element: HTMLElement
 *
 *  IN COMPONENT:
 *  @ViewChild('name_of_element') name_of_element: ElementRef;
 *  ngAfterViewInit() method set name_of_element.nativeElement to the AppHtmlElementsService property
 */

@Injectable({
  providedIn: 'root',
})
export class AppHtmlElementsService {
  public headerContainerElement: HTMLElement;
  public headerCarouselElement: HTMLElement;
  private headerContainerStatus = new Subject<void>();
  public headerContainerStatusObservable = this.headerContainerStatus.asObservable();

  public setHeaderContainerStatus() {
    this.headerContainerStatus.next();
  }

  constructor() {}
}
