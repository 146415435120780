import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { CarouselImage } from '../../state/interfaces/CarouselImage';
import { CarouselSlide } from '../../state/interfaces/CarouselSlide';
import { SlideshowService } from '@services/slideshow.service';
import { PROMOTION_TYPE } from '@enums/promotion-type.enum';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TOURNAMENT_STATUS } from '@enums/tournament-status.enum';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PinnbetGameInfoDialog } from '@modules/lobby/game-info-pinnbet/pinnbet-game-info.dialog';
import { LobbyState } from '@modules/lobby/providers/lobby.state';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SlideshowService],
  animations: [
    trigger('slideIn', [
      state(
        'outOfView',
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        })
      ),
      state(
        'slideInView',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      transition('outOfView => slideInView', [animate('1s')]),
      transition('slideInView => outOfView', [animate('10ms 500ms')]),
    ]),
  ],
})
// eslint-disable-next-line
export class SlideshowComponent implements OnInit, OnDestroy, OnChanges, DoCheck, AfterViewInit {
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public TOURNAMENT_STATUS = TOURNAMENT_STATUS;
  private urlCache: (string | CarouselImage)[];
  public hidden = this.isMobile ? true : false;
  public PROMOTION_TYPE = PROMOTION_TYPE;
  public slides: CarouselSlide[] = [];
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  private autoplayIntervalId: any;
  private slide$: Subscription;
  public hideRightArrow = false;
  public hideLeftArrow = false;
  private isHidden = false;
  private initial = true;
  public slideIndex = -1;

  // Property Binding Options
  @Input() imageUrls: (string | CarouselImage)[];
  @Input() backgroundPosition = 'center center';
  @Input() autoPlayWaitForLazyLoad = true;
  @Input() backgroundRepeat = 'no-repeat';
  @Input() stopAutoPlayOnSlide = true;
  @Input() backgroundSize = 'cover';
  @Input() buttonPosition = 'left';
  @Input() autoPlayInterval = 3333;
  @Input() disableSwiping = false;
  @Input() hideOnNoSlides = false;
  @Input() isButtonAllowed = true;
  @Input() captionColor = '#FFF';
  @Input() showCaptions = true;
  @Input() enableZoom = false;
  @Input() fullscreen = true;
  @Input() enablePan = false;
  @Input() autoPlay = false;
  @Input() lazyLoad = false;
  @Input() height = '100%';
  @Input() noLoop = false;
  @Input() minHeight: string;
  @Input() isSwipeArrowIncluded = false;
  @Input() isBottomGradientIncluded = false;
  @Input() areDotsIncluded = false;

  // Event Emitters
  @Output() IndexChanged = new EventEmitter<number>();
  @Output() SwipeRight = new EventEmitter<number>();
  @Output() SlideRight = new EventEmitter<number>();
  @Output() SlideLeft = new EventEmitter<number>();
  @Output() SwipeLeft = new EventEmitter<number>();
  @Output() gameInfoOpened = new EventEmitter<any>();
  @Output() leaderboardOpened = new EventEmitter<any>();

  // Element References
  @ViewChild('container') container: ElementRef;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private slideshowService: SlideshowService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private router: Router,
    private modalService: NgbModal,
    private lobbyState: LobbyState
  ) {}

  /** LIFECYCLE HOOKS START **/
  // eslint-disable-next-line
  ngOnInit(): void {
    this.animateSlideAndAvoidAnimationGlitch();
    this.slide$ = this.slideshowService.slideEvent.subscribe((indexDirection: number) => {
      this.onSlide(indexDirection, true);
    });
  }

  // eslint-disable-next-line
  ngOnDestroy(): void {
    if (this.slide$ && !this.slide$.closed) {
      this.slide$.unsubscribe();
    }

    this.slideshowService.unbind(this.container);

    if (this.autoplayIntervalId) {
      this.ngZone.runOutsideAngular(() => clearInterval(this.autoplayIntervalId));
      this.autoplayIntervalId = null;
    }
  }

  // eslint-disable-next-line
  ngOnChanges(changes: SimpleChanges) {
    if (changes['imageUrls']) {
      this.slideIndex = 0;
    }

    if (changes['noLoop']) {
      if (changes['noLoop'].currentValue) {
        this.hideLeftArrow = this.slideIndex <= 0;
        this.hideRightArrow = this.slideIndex === this.slides.length - 1;
      } else {
        this.hideLeftArrow = false;
        this.hideRightArrow = false;
      }

      this.cdRef.detectChanges();
    }
  }

  // eslint-disable-next-line
  ngDoCheck() {
    // if this is the first being called, create a copy of the input
    if (this.imageUrls && this.imageUrls.length > 0) {
      if (this.initial) {
        this.urlCache = Array.from(this.imageUrls);
      }

      if (this.isHidden) {
        this.renderer.removeStyle(this.container.nativeElement, 'display');
        this.isHidden = false;
      }

      this.setSlides();
    } else if (this.hideOnNoSlides === true) {
      this.renderer.setStyle(this.container.nativeElement, 'display', 'none');
      this.isHidden = true;
    }

    this.setStyles();
    this.handleAutoPlay();
    this.slideshowService.disableSwiping = this.disableSwiping;
    this.slideshowService.enableZoom = this.enableZoom;
    this.slideshowService.enablePan = this.enablePan;
  }

  // eslint-disable-next-line
  ngAfterViewInit(): void {
    this.slideshowService.bind(this.container);
  }
  /** LIFECYCLE HOOKS END **/

  /**
   * @description Keep the styles up to date with the input
   */
  private setStyles(): void {
    if (this.height) {
      this.renderer.setStyle(this.container.nativeElement, 'height', this.height);
    }

    if (this.minHeight) {
      this.renderer.setStyle(this.container.nativeElement, 'min-height', this.minHeight);
    }
  }

  // animate every slide uppon initilazion on mobile
  private animateSlideAndAvoidAnimationGlitch() {
    setTimeout(() => {
      if (this.isMobile) {
        if (this.imageUrls.length > 1) {
          this.imageUrls.forEach(element => {
            this.slide(1);
          });
        }
        this.hidden = false;
        this.cdRef.detectChanges();
      }
    }, 0);
    if (this.noLoop) {
      this.hideLeftArrow = true;
    }
  }

  /**
   * @description Check to make sure slide images have been set or haven't changed
   */
  private setSlides(): void {
    if (this.imageUrls) {
      if (this.checkCache() || this.initial === true) {
        this.initial = false;
        this.urlCache = Array.from(this.imageUrls);
        this.slides = [];

        this.buildSlideArray();
        this.cdRef.detectChanges();
      }
    }
  }

  /**
   * @param index
   * @description set the index to the desired index - 1 and simulate a right slide
   */
  getSlideStyle(index: number) {
    const slide = this.slides[index];

    if (slide && slide.loaded) {
      return {
        'background-image': 'url(' + slide.image.url + ')',
        'background-size': slide.image.backgroundSize || this.backgroundSize,
        'background-position': slide.image.backgroundPosition || this.backgroundPosition,
        'background-repeat': slide.image.backgroundRepeat || this.backgroundRepeat,
        cursor: slide.image.cursor || 'default',
      };
    } else {
      // doesn't compile correctly if returning an empty object, sooooo.....
      return {
        'background-image': undefined,
        'background-size': undefined,
        'background-position': undefined,
        'background-repeat': undefined,
        cursor: undefined,
      };
    }
  }

  private buildSlideArray(): void {
    for (const image of this.imageUrls) {
      this.slides.push({
        image: typeof image === 'string' ? { url: image } : image,
        action: '',
        leftSide: false,
        rightSide: false,
        selected: false,
        loaded: true,
      });
    }
    if (this.slideIndex === -1) {
      this.slideIndex = 0;
    }

    this.slides[this.slideIndex].selected = true;
    this.IndexChanged.emit(this.slideIndex);
  }

  /**
   * @description compare image array to the cache, returns false if no changes
   */
  private checkCache(): boolean {
    return !(
      this.urlCache.length === this.imageUrls.length &&
      this.urlCache.every((cacheElement, i) => cacheElement === this.imageUrls[i])
    );
  }

  /**
   * @param indexDirection
   * @param isSwipe
   * @description Set the new slide index, then make the transition happen.
   */
  private slide(indexDirection: number, isSwipe?: boolean): void {
    const oldIndex = this.slideIndex;

    if (this.setSlideIndex(indexDirection)) {
      if (this.slides[this.slideIndex] && !this.slides[this.slideIndex].loaded) {
        this.loadRemainingSlides();
      }

      if (indexDirection === 1) {
        this.slideRight(oldIndex, isSwipe);
      } else {
        this.slideLeft(oldIndex, isSwipe);
      }

      this.slides[oldIndex].selected = false;
      this.slides[this.slideIndex].selected = true;
    }
    this.cdRef.detectChanges();
  }

  /**
   * @description if lazy loading in browser, start loading remaining slides
   * @todo: figure out how to not show the spinner if images are loading fast enough
   */
  private loadRemainingSlides(): void {
    for (let i = 0; i < this.slides.length; i++) {
      if (!this.slides[i].loaded) {
        new Promise(resolve => {
          const tmpImage = this.imageUrls[i];
          const loadImage = new Image();
          loadImage.addEventListener('load', () => {
            this.slides[i].image = typeof tmpImage === 'string' ? { url: tmpImage } : tmpImage;
            this.slides[i].loaded = true;
            this.cdRef.detectChanges();
          });
          loadImage.src = typeof tmpImage === 'string' ? tmpImage : tmpImage.url;
        });
      }
    }
  }

  /**
   * @param oldIndex
   * @param isSwipe
   * @description This function handles the variables to move the CSS classes around accordingly.
   *              In order to correctly handle animations, the new slide as well as the slides to
   *              the left and right are assigned classes.
   */
  private slideRight(oldIndex: number, isSwipe?: boolean): void {
    if (isSwipe === true) {
      this.SwipeRight.emit(this.slideIndex);
    } else {
      this.SlideRight.emit(this.slideIndex);
    }

    this.slides[this.getRightSideIndex(oldIndex)].rightSide = false;
    this.slides[oldIndex].rightSide = true;
    this.slides[oldIndex].action = 'slideOutRight';
    this.slides[this.slideIndex].leftSide = false;
    this.slides[this.getLeftSideIndex()].leftSide = true;
    this.slides[this.slideIndex].action = 'slideInLeft';
  }

  /**
   * @param oldIndex
   * @param isSwipe
   * @description This function handles the variables to move the CSS classes around accordingly.
   *              In order to correctly handle animations, the new slide as well as the slides to
   *              the left and right are assigned classes.
   */
  private slideLeft(oldIndex: number, isSwipe?: boolean): void {
    if (isSwipe === true) {
      this.SwipeLeft.emit(this.slideIndex);
    } else {
      this.SlideLeft.emit(this.slideIndex);
    }

    this.slides[this.getLeftSideIndex(oldIndex)].leftSide = false;
    this.slides[oldIndex].leftSide = true;
    this.slides[oldIndex].action = 'slideOutLeft';
    this.slides[this.slideIndex].rightSide = false;
    this.slides[this.getRightSideIndex()].rightSide = true;
    this.slides[this.slideIndex].action = 'slideInRight';
  }

  /**
   * @param i
   * @description get the index for the slide to the right of the new slide
   */
  private getRightSideIndex(i?: number): number {
    if (i === undefined) {
      i = this.slideIndex;
    }

    if (++i >= this.slides.length) {
      i = 0;
    }

    return i;
  }

  /**
   * @param i
   * @description get the index for the slide to the left of the new slide
   */
  private getLeftSideIndex(i?: number): number {
    if (i === undefined) {
      i = this.slideIndex;
    }

    if (--i < 0) {
      i = this.slides.length - 1;
    }

    return i;
  }

  /**
   * @param indexDirection
   * @description This is just treating the url array like a circular list.
   */
  private setSlideIndex(indexDirection: number): boolean {
    let willChange = true;
    this.slideIndex += indexDirection;

    if (this.noLoop) {
      this.hideRightArrow = this.slideIndex === this.slides.length - 1;
      this.hideLeftArrow = false;
    }

    if (this.slideIndex < 0) {
      if (this.noLoop) {
        this.slideIndex -= indexDirection;
        willChange = false;
        this.hideLeftArrow = true;
      } else {
        this.slideIndex = this.slides.length - 1;
      }
    } else if (this.slideIndex >= this.slides.length) {
      if (this.noLoop) {
        this.slideIndex -= indexDirection;
        willChange = false;
        this.hideRightArrow = true;
      } else {
        this.slideIndex = 0;
      }
    }

    if (willChange) {
      this.IndexChanged.emit(this.slideIndex);
    }

    return willChange;
  }

  /**
   * @param stopAutoPlay
   * @description Start or stop autoPlay, don't do it at all server side
   */
  private handleAutoPlay(stopAutoPlay?: boolean): void {
    if (stopAutoPlay === true || this.autoPlay === false) {
      if (this.autoplayIntervalId) {
        this.ngZone.runOutsideAngular(() => clearInterval(this.autoplayIntervalId));
        this.autoplayIntervalId = null;
      }
    } else if (!this.autoplayIntervalId) {
      this.ngZone.runOutsideAngular(() => {
        this.autoplayIntervalId = setInterval(() => {
          if (
            !this.autoPlayWaitForLazyLoad ||
            (this.autoPlayWaitForLazyLoad &&
              this.slides[this.slideIndex] &&
              this.slides[this.slideIndex].loaded)
          ) {
            this.ngZone.run(() => this.slide(1));
          }
        }, this.autoPlayInterval);
      });
    }
  }

  public onSlide(indexDirection: number, isSwipe?: boolean): void {
    this.appStateFacadeService.setSecondaryMenuState();
    this.handleAutoPlay(this.stopAutoPlayOnSlide);
    this.slide(indexDirection, isSwipe);
  }

  /**
   * @param index
   * @description set the index to the desired index - 1 and simulate a right slide
   */

  /**
   *
   * @param index
   * @description Called form parent lobby-carousel.component.ts
   */
  goToSlide(index: number) {
    this.appStateFacadeService.setSecondaryMenuState();
    const beforeClickIndex = this.slideIndex;
    this.slideIndex = index - 1;

    this.setSlideIndex(1);

    if (this.slides[this.slideIndex] && !this.slides[this.slideIndex].loaded) {
      this.loadRemainingSlides();
    }

    this.handleAutoPlay(this.stopAutoPlayOnSlide);
    this.slideRight(beforeClickIndex);
    this.slides[beforeClickIndex].selected = false;
    this.slides[this.slideIndex].selected = true;

    this.cdRef.detectChanges();
  }

  // navigate with external or internal link and inform parrent component
  public navigateByUrl(event, url) {
    if (url) {
      event.stopPropagation();
      this.appStateFacadeService.setSecondaryMenuState();
      // External link
      if (url.startsWith('www') || url.startsWith('http')) {
        window.open(url, '_blank');
        // Internal link
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  /**
   * @param index
   * @param slide
   * @description a trackBy function for the ngFor loops
   */
  public trackByFn(index: number, slide: CarouselSlide): any {
    return slide.image;
  }

  public openGameInfo(event, game, tournamentId) {
    event.stopPropagation();
    this.gameInfoOpened.emit({ game, tournamentId });
  }

  public openLeaderboard(event) {
    event && event.stopPropagation();
    this.leaderboardOpened.emit();
  }

  public openPinbetGameInfoDialog(game): void {
    const activeLobbyPath = this.lobbyState.getActiveLobbyPath();
    const modalRef = this.modalService.open(PinnbetGameInfoDialog, {
      centered: this.isMobile ? false : true,
      scrollable: false,
      keyboard: false,
    });

    modalRef.componentInstance.game = game;
    modalRef.componentInstance.lobbyPath = activeLobbyPath;
  }

  public openPromotionDetails(promotionId: number) {
    this.router.navigate([`/promotions/${promotionId}`]);
  }
}
