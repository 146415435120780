<nav id="sidebar-right" class="sidebar sidebar-right" [ngClass]="{ expanded: rightBarExpanded }">
  <div>
    <div
      #contentOfMenu
      (swiperight)="closeRightMenuOnSwipe()"
      id="right-sidebar-content"
      class="sidebar-content sidebar-content-cro"
    >
      <div class="c_right-menu-header container-padding">
        <div class="c_right-menu-username">{{ player?.username }}</div>
        <div
          class="c_right-menu-dropdown-detect"
          (click)="openBalanceInformation(); $event.stopPropagation()"
        >
          <div class="dropdownArrow" [ngClass]="{ 'dropdownArrow--is-active': balanceDropDownOpen }"></div>
        </div>
      </div>
      <div class="wallet-container container-padding">
        <div @heightAnimation class="c_balance-info-container" *ngIf="balanceDropDownOpen">
          <div class="u_border-bottom-separator">
            <ul>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'PLAYER_ID',
                    value: player?.id,
                    currency: false
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RIGHT_MENU.MONEY',
                    value: balanceInfo?.balance,
                    currency: true
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RESERVED',
                    value: balanceInfo?.internalReservedFunds,
                    currency: true,
                    link: '/profile/withdrawal/reserved'
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RIGHT_MENU.FOR_PAYOUT',
                    value: balanceInfo?.availableForWithdrawal,
                    currency: true,
                    link: '/profile/withdrawal'
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'CURRENT_WITHDRAWAL_REQUEST_TEXT',
                    value: balanceInfo?.systemReservedFunds,
                    currency: true,
                    link: '/profile/withdrawal/withdrawal-requests'
                  }
                "
              ></li>
            </ul>
          </div>
          <div class="u_border-bottom-separator pt-2">
            <ul>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'BALANCE_INFO_BONUS',
                    value: balanceInfo?.bonus,
                    currency: true
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'ACTIVE_BONUS_SPENT_AMOUNT',
                    value: balanceInfo?.spentAmount,
                    currency: true
                  }
                "
              ></li>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'RIGHT_MENU.NEEDED',
                    value: balanceInfo?.bonusRequiredAmount,
                    currency: true
                  }
                "
              ></li>
            </ul>
          </div>
          <ng-container *ngIf="balanceInfo?.freebet">
            <div class="u_border-bottom-separator pt-2">
              <ul>
                <li
                  *ngTemplateOutlet="
                    balanceItem;
                    context: {
                      label: 'BALANCE_INFO_FREEBET',
                      value: balanceInfo?.freebet,
                      currency: true
                    }
                  "
                ></li>
                <li
                  *ngTemplateOutlet="
                    balanceItem;
                    context: {
                      label: 'FREEBET_UNAVAILABLE_PART1',
                      value: balanceInfo?.availableFreebet,
                      currency: true,
                      helpInfo: iconForAdditionalInfo
                    }
                  "
                ></li>
                <ng-template #iconForAdditionalInfo>
                  <i
                    [ngbTooltip]="tipContent"
                    placement="top"
                    [setSVGAttributes]="{ height: '18px' }"
                    [inlineSVG]="'assets/images/mobile-menu-icon/menu_help_l-menu.svg'"
                    fallbackSVG="assets/images/default-left-menu-icon.svg"
                  ></i>
                </ng-template>
                <ng-template #tipContent>
                  {{
                    'FREEBET_UNAVAILABLE'
                      | translate
                        : {
                            unavailable:
                              balanceInfo.freebetDiff | number : '1.2-2' | currency : player?.currency,
                            unwithdrawable:
                              balanceInfo.freebetUnwithdrawableAmount
                              | number : '1.2-2'
                              | currency : player?.currency
                          }
                  }}
                </ng-template>
              </ul>
            </div>
          </ng-container>
          <div class="u_border-bottom-separator pt-2">
            <ul>
              <li
                *ngTemplateOutlet="
                  balanceItem;
                  context: {
                    label: 'DEPOSIT_METHOD_ADMIRAL_360',
                    value: admiral360BalanceInfo?.balance,
                    currency: true,
                    link: '/profile/withdrawal/admiral-360'
                  }
                "
              ></li>
            </ul>
          </div>
        </div>
        <div *ngIf="!finalizeRegistration" class="pt-4">
          <i
            class="btn_icon btn_icon--is-deposit"
            #depositIcon
            [inlineSVG]="'assets/icons/deposit_btn_icon.svg'"
          >
          </i>
          <div
            appAdmiralButton
            type="{{ BUTTON_TYPES.DEPOSIT }}"
            [backgroundColor]="currentTheme?.depositBtnColor.hex"
            [textColor]="currentTheme?.depositBtnTextColor.hex"
            [icon]="depositIcon"
            [marginCostume]="'auto'"
            (click)="
              navigateToLinkInMenu({
                type: 'INTERNAL_LINK_SUBMENU',
                url: '/profile/deposit'
              })
            "
          ></div>
        </div>
        <div *ngIf="finalizeRegistration" class="d-flex flex-column pt-4">
          <div
            class="btn-div btn btn-primary"
            text="{{ 'PROCEED_REGISTRATION_COMPONENT_BUTTON' }}"
            (click)="
              navigateToLinkInMenu({
                type: 'INTERNAL_LINK_SUBMENU',
                url: '/registration',
                urlType: 'register'
              })
            "
          >
            {{ 'PROCEED_REGISTRATION_COMPONENT_BUTTON' | translate | uppercase }}
          </div>
        </div>
      </div>
      <div class="mt-4 container-padding">
        <div
          class="c_right-list-menu-item c_right-list-menu-item--is-cro notifications-container"
          *ngIf="unreadMessages > 0"
        >
          <div class="c_right-menu-item-font c_right-list-menu-item--is-cro notifications-container">
            {{ 'INTERNAL_LINK_SUBMENU.NOTIFICATIONS_MESSAGES' | translate }}
          </div>
          <div class="notifications-counter">
            {{ unreadMessages }}
          </div>
        </div>
        <div class="c_link-list">
          <app-right-menu-links-cms
            [navigateToLinkInMenu]="navigateToLinkInMenu.bind(this)"
          ></app-right-menu-links-cms>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template
  #balanceItem
  let-label="label"
  let-value="value"
  let-currency="currency"
  let-link="link"
  let-helpInfo="helpInfo"
>
  <div class="c_balance-row-item">
    <span *ngIf="link" class="c_balance-row-link" (click)="openLinkForLabel(link)">{{
      label | translate
    }}</span>
    <div *ngIf="!link">{{ label | translate }}</div>
    <span *ngIf="helpInfo" class="p-2">
      <ng-container *ngTemplateOutlet="helpInfo"></ng-container>
    </span>
    <div class="c_balance-row-value" *ngIf="!currency">{{ value }}</div>
    <div class="c_balance-row-value" *ngIf="currency">
      {{ value | number : '1.2-2' | currency : player?.currency }}
    </div>
  </div>
</ng-template>
