<div class="background-helper" [ngClass]="{ isCroClient: isCroClient }">
  <div
    *ngIf="jackpots && jackpots.length"
    class="jackpot-wrapper"
    [ngClass]="{ isCroClient: isCroClient, 'border-bottom-glow': !isCroClient }"
  >
    <div [ngClass]="{ mobile: isMobile }" *ngIf="!isCroClient" class="shine-img-container">
      <img src="assets/images/shine-effect.png" />
    </div>

    <div [ngClass]="{ isCroClient: isCroClient }" *ngIf="isMobile" class="coin-cover"></div>
    <div [ngClass]="{ desktop: !isMobile, mobile: isMobile, isCroClient: isCroClient }">
      <ng-container *ngFor="let jackpot of jackpots; let i = index">
        <!-- PROGRESSIVE JACKPOT -->
        <div
          (click)="!isMobile && toggle(trigger, i)"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          *ngIf="!jackpot.timeBased && (i === 0 || !isMobile)"
          class="jackpot-container"
          [ngClass]="{ 'desktop-jackpot': !isMobile, mobile: isMobile, isCroClient: isCroClient }"
        >
          <div *ngIf="!isMobile" class="coins-container-desktop-progressive">
            <img
              [src]="
                'assets/images/coins-' +
                (isCroClient ? 'cro-' : '') +
                jackpot.jackpotTheme.toLowerCase() +
                '.png'
              "
            />
          </div>

          <div [ngClass]="{ isCroClient: isCroClient }" *ngIf="isMobile" class="coins-container-mobile">
            <i
              [@coinFallAnimation]="{ value: isMobile, params: { animationTiming: '200ms 200ms linear' } }"
              *ngIf="jackpot.jackpotTheme === 'BRONZE'"
              [inlineSVG]="isCroClient ? 'assets/images/coin-cro.svg' : 'assets/images/coin.svg'"
              [ngClass]="{ isCroClient: isCroClient }"
              class="coin coin-0-mobile"
            ></i>
            <i
              [@coinFallAnimation]="{ value: isMobile, params: { animationTiming: '200ms 200ms linear' } }"
              *ngIf="
                jackpot.jackpotTheme === 'SILVER' ||
                jackpot.jackpotTheme === 'GOLD' ||
                jackpot.jackpotTheme === 'PLATINUM'
              "
              [inlineSVG]="isCroClient ? 'assets/images/coin-cro.svg' : 'assets/images/coin.svg'"
              [ngClass]="{
                isCroClient: isCroClient,
                'different-position': jackpot.jackpotTheme === 'SILVER' || jackpot.jackpotTheme === 'GOLD'
              }"
              class="coin coin-1-mobile"
            ></i>
            <i
              [@coinFallAnimation]="{ value: isMobile, params: { animationTiming: '200ms 300ms linear' } }"
              *ngIf="
                jackpot.jackpotTheme === 'SILVER' ||
                jackpot.jackpotTheme === 'GOLD' ||
                jackpot.jackpotTheme === 'PLATINUM'
              "
              [inlineSVG]="isCroClient ? 'assets/images/coin-cro.svg' : 'assets/images/coin.svg'"
              [ngClass]="{
                isCroClient: isCroClient,
                'different-position': jackpot.jackpotTheme === 'SILVER' || jackpot.jackpotTheme === 'GOLD'
              }"
              class="coin coin-2-mobile"
            ></i>
            <i
              [@coinFallAnimation]="{ value: isMobile, params: { animationTiming: '200ms 500ms linear' } }"
              *ngIf="jackpot.jackpotTheme === 'GOLD' || jackpot.jackpotTheme === 'PLATINUM'"
              [inlineSVG]="isCroClient ? 'assets/images/coin-cro.svg' : 'assets/images/coin.svg'"
              [ngClass]="{
                isCroClient: isCroClient,
                'different-position': jackpot.jackpotTheme === 'SILVER' || jackpot.jackpotTheme === 'GOLD'
              }"
              class="coin coin-3-mobile"
            ></i>
            <i
              [@coinFallAnimation]="{ value: isMobile, params: { animationTiming: '200ms 700ms linear' } }"
              *ngIf="jackpot.jackpotTheme === 'PLATINUM'"
              [inlineSVG]="isCroClient ? 'assets/images/coin-cro.svg' : 'assets/images/coin.svg'"
              [ngClass]="{ isCroClient: isCroClient }"
              class="coin coin-4-mobile"
            ></i>
          </div>

          <div
            switchAnimationChange
            class="progressive-mobile-jackpot-wrapper"
            *ngIf="isMobile && !jackpot.timeBased && i === 0"
          >
            <app-odometer-v2
              @switchAnimationChange
              *ngIf="!jackpot.timeBased && i === 0"
              [nextValue]="jackpot.currentValue"
            ></app-odometer-v2>
            <div @switchAnimationChange *ngIf="!jackpot.timeBased && i === 0" class="currency-container">
              {{ jackpot.currencyCode }}
            </div>
          </div>

          <app-odometer-v2 *ngIf="!isMobile" [nextValue]="jackpot.currentValue"></app-odometer-v2>
          <div *ngIf="!isMobile" class="currency-container">{{ jackpot.currencyCode }}</div>
        </div>

        <!-- TIME BASED JACKPOT -->
        <div
          (click)="!isMobile && toggle(trigger, i)"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          *ngIf="jackpot.timeBased && (i === 0 || !isMobile)"
          class="jackpot-container"
          [ngClass]="{ 'desktop-jackpot': !isMobile, mobile: isMobile, isCroClient: isCroClient }"
        >
          <div
            [ngClass]="{ mobile: isMobile, isCroClient: isCroClient }"
            class="coins-container-desktop-time-based"
          >
            <img [src]="isCroClient ? 'assets/images/umbrella-cro.gif' : 'assets/images/umbrella.gif'" />
          </div>
          <div [ngClass]="{ mobile: isMobile }" class="time-based-wrapper">
            <div
              [ngClass]="{ mobile: isMobile }"
              *ngIf="jackpot.timeBasedInitialState"
              @switchAnimationChange
              class="time-based-container"
            >
              <div class="number-of-prizes-container">
                {{ jackpot.awardedJackpots }}/{{ jackpot.numberOfPrizes }}
              </div>
              <app-odometer-v2 [nextValue]="jackpot.currentValue"></app-odometer-v2>
              <div class="currency-container">{{ jackpot.currencyCode }}</div>
            </div>
            <div
              *ngIf="!jackpot.timeBasedInitialState"
              @switchAnimationChange
              [ngClass]="{ mobile: isMobile }"
              class="time-based-container time"
            >
              <div [innerHTML]="'JACKPOT_RAIN' | translate"></div>
              <div>
                <b>{{ jackpot.startDate | date : 'd.M.' }}</b> | {{ jackpot.startDate | date : 'HH:mm' }}
                {{ 'JACKPOT_ENDS_ON' | translate }} <b>{{ jackpot.endDate | date : 'd.M.' }}</b> |
                {{ jackpot.endDate | date : 'HH:mm' }}
              </div>
              <div [ngClass]="{ isCroClient: isCroClient }" class="currency-wrapper">
                <app-odometer-v2 [nextValue]="jackpot.currentValue"></app-odometer-v2>
                <div class="currency-container">{{ jackpot.currencyCode }}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      [ngClass]="{ isCroClient: isCroClient }"
      (click)="toggleWinnerListMobile()"
      class="winners-list-button"
      *ngIf="isMobile"
    >
      <i [inlineSVG]="'assets/icons/arrow-down.svg'"></i>
    </div>
    <div
      [ngClass]="{ isCroClient: isCroClient }"
      *ngIf="isMobileWinnerListOpen"
      class="winners-list-container"
    >
      <div class="game">
        <div>{{ gameName }}</div>
        <span
          class="favoriteStar"
          [inlineSVG]="
            clientName === CLIENT_NAMES.ADMIRAL_CROATIA
              ? 'assets/icons/heart-active.svg'
              : 'assets/images/star-solid.svg'
          "
          *ngIf="isLoggedIn"
          [ngClass]="{ active: isGameFavorite }"
        ></span>
      </div>
      <ng-container *ngTemplateOutlet="jackpotList"></ng-container>
    </div>
  </div>
</div>

<ng-template
  #jackpotList
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayOrigin]="triggerOrigin"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div
    class="winners-list"
    [ngClass]="{ mobile: isMobile, isCroClient: isCroClient }"
    *ngIf="currentJackpotWinners && currentJackpotWinners.length; else noJackpotWinnersLabel"
  >
    <div class="username-container">
      <div class="title">
        {{ 'TOURNAMENT_RANK_LIST_PLAYER' | translate }}
      </div>
      <div *ngFor="let winner of currentJackpotWinners">{{ isCroClient ? winner.id : winner.player }}</div>
    </div>
    <div class="date-container">
      <div class="title">{{ 'COMMON_DATE_TIME' | translate }}</div>
      <div *ngFor="let winner of currentJackpotWinners">{{ winner.dateOfWin | date : 'd/M/y HH:mm' }}</div>
    </div>
    <div class="amount-container">
      <div class="title">{{ 'DEPOSIT_AMOUNT' | translate }}</div>
      <div
        [ngClass]="{ isCroClient: isCroClient }"
        class="amount"
        *ngFor="let winner of currentJackpotWinners"
      >
        {{ winner.jackpotValue | number : '1.2-2' }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noJackpotWinnersLabel>
  <div [ngClass]="{ isCroClient: isCroClient }" class="no-jackpot-winners-label">
    {{ 'NO_JACKPOT_WINNERS' | translate }}
  </div>
</ng-template>
