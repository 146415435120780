import { BonusRedeem } from './bonus-redeem.model';

export class Bonus {
  txnId: number;
  bonusId: number;
  redemptionAmountTypeId: number;
  initAmount: number;
  amount: number;
  wagerRequiredAmount: number;
  wagerAmount: number;
  spentRequiredAmount: number;
  spentAmount: number;
  wonRequiredAmount: number;
  wonAmount: number;
  redeemedAmount: number;
  active: boolean;
  priority: string;
  redeemActive: number;
  redeemActiveMinutes: number;
  createdOn: number;
  type: string;
  name: string;
  description: string;
  startDate: number;
  endDate: number;
  redeems: BonusRedeem[];
  accepted: boolean;
  balanceRequiredAmount: number;
  bonus: any;
  isNewBonus: boolean;
  bonusAccepted: boolean;
  id: number;
  dateExhausted: number;
  status: string;
  redeemRemainingTime: string;
  currency: string;

  constructor(data) {
    this.txnId = data.txnId || null;
    this.bonusId = data.bonusId || null;
    this.redemptionAmountTypeId = data.redemptionAmountTypeId || null;
    this.initAmount = data.initAmount || null;
    this.amount = data.amount || null;
    this.wagerRequiredAmount = data.wagerRequiredAmount || null;
    this.wagerAmount = data.wagerAmount || null;
    this.spentRequiredAmount = data.spentRequiredAmount || null;
    this.spentAmount = data.spentAmount || null;
    this.wonRequiredAmount = data.wonRequiredAmount || null;
    this.wonAmount = data.wonAmount || null;
    this.redeemedAmount = data.redeemedAmount || null;
    this.active = data.active || null;
    this.priority = data.priority || null;
    this.redeemActive = data.redeemActive || null;
    this.redeemActiveMinutes = data.redeemActiveMinutes || null;
    this.createdOn = data.createdOn || null;
    this.type = data.type || null;
    this.name = data.name || null;
    this.description = data.description || null;
    this.startDate = data.startDate || null;
    this.endDate = data.endDate || null;
    this.redeems = data.redeems || null;
    this.accepted = data.accepted || null;
    this.balanceRequiredAmount = data.balanceRequiredAmount || null;
    this.bonus = data.bonus || null;
    this.isNewBonus = data.isNewBonus || null;
    this.bonusAccepted = data.bonusAccepted || null;
    this.dateExhausted = data.dateExhausted || null;
    this.id = data.id || null;
    this.status = data.status || null;
    this.redeemRemainingTime = data.redeemRemainingTime || null;
    this.currency = data.currency || null;
  }
}
