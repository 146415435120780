<div class="box">
  <header class="py-2 py-lg-3">
    <div class="row pl-3 pr-3">
      <div class="col-12">
        <div class="h4 mb-0 mb-md-2 text-uppercase text-center">{{ 'IBAN_HELPER' | translate}}</div>
      </div>
    </div>
  </header>
  <p class="pl-3 pr-3">{{ 'CRO_V2_IBAN_HELPER_DESC' | translate }}</p>
  <div class="row pl-3 pr-3 pt-4">
    <div class="col-12">
      <form [formGroup]="ibanHelpeForm" (ngSubmit)="formSubmit()">
        <!-- BANKS -->
        <div class="form-group">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-label for="bank" class="required">{{ 'BANK' | translate }}</mat-label>
            <mat-select (selectionChange)="onBankSelect($event)" formControlName="bank">
              <ng-container *ngFor="let bank of banks.value">
                <mat-option [value]="bank.identifier">{{ bank.name }}</mat-option>
              </ng-container>
            </mat-select>
            <mat-error
              *ngIf="ibanHelpeForm.get('bank').hasError('required') && ibanHelpeForm.get('bank').hasError('touched')"
              >{{ 'WITHDRAWAL_REQUIERD_FIELD' | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-label for="bankAccountNumber" class="required"
              >{{ 'WITHDRAWAL_BANK_ACCOUNT' | translate }}</mat-label
            >
            <input
              (input)="onlyNumbersAllow($event); calculateIban()"
              inputmode="numeric"
              minlength="10"
              maxlength="10"
              matInput
              type="text"
              formControlName="bankAccountNumber"
              placeholder="{{ 'ENTER_BANK_ACCOUNT_NUMBER' | translate }}"
              autocomplete="off"
              autofocus
              required
            />
            <mat-error *ngIf="ibanHelpeForm.get('bankAccountNumber').hasError('required')">
              {{ 'WITHDRAWAL_REQUIERD_FIELD' | translate }}
            </mat-error>
            <mat-error *ngIf="ibanHelpeForm.get('bankAccountNumber').hasError('minlength')">
              {{ 'TEN_DIGITS_VALIDATION' | translate }}
            </mat-error>
            <mat-error *ngIf="calculatedIbanNumberHasError"> {{ calculatedIbanNumberError }} </mat-error>
          </mat-form-field>
        </div>
        <div class="row text-center">
          <div class="col-12 pb-2">{{ 'YOUR_IBAN' | translate }}</div>
          <div class="iban-placeholder" *ngIf="!calculatedIbanNumber">
            {{ 'PLACEHOLDER_IBAN' | translate }}
          </div>
          <div class="iban-placeholder" *ngIf="calculatedIbanNumber">{{ calculatedIbanNumber }}</div>
        </div>
        <!-- SUBMIT BTN -->
        <div class="pt-3">
          <button
            class="cro-button"
            type="submit"
            [disabled]="ibanHelpeForm.invalid || calculatedIbanNumberHasError"
          >
            {{ 'OTHER_SAVE' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="close-container-outside-dialog">
  <i
    (click)="closeDialog()"
    class="closeButton"
    [setSVGAttributes]="{ height: '17px', width: '17px' }"
    inlineSVG="/assets/icons/close_cross.svg"
  >
  </i>
</div>

<!-- LOADER -->
<div *ngIf="isLoading" class="spinner-border spinner" role="status"></div>
