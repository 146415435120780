<nav
  (swipeleft)="closeLeftMenuOnSwipe()"
  id="sidebar-left"
  class="sidebar sidebar-left"
  [ngClass]="{ expanded: leftBarExpanded }"
  *ngIf="lefMenuData?.active === true"
>
  <aside (click)="handleLeftSideBar()">
    <div class="sidebar-toggle">
      <i class="icon icon-menu"></i>
    </div>
    <app-language-picker></app-language-picker>

    <div
      (swipeleft)="closeLeftMenuOnSwipe()"
      id="left-sidebar-content"
      class="sidebar-content"
      #contentOfMenu
    >
      <ul>
        <ng-container *ngIf="lefMenuData">
          <ng-container *ngFor="let asset of lefMenuData.menuAsset">
            <div class="left-sidebar-content">
              <ng-container *ngIf="asset.activeItem">
                <ng-container *ngFor="let menuItem of asset.items">
                  <ng-container [ngSwitch]="menuItem.assetType">
                    <ng-container *ngSwitchCase="'DIVIDER'">
                      <li>
                        <hr />
                      </li>
                    </ng-container>
                    <ng-container *ngSwitchCase="'BUTTON'">
                      <li *ngIf="leftBarExpanded">
                        <div class="c_user-operation-button">
                          <mat-icon>{{ menuItem.btnIcon }}</mat-icon>
                          {{ menuItem.title }}
                        </div>
                      </li>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <li [routerLinkActive]="['active-link']" (click)="navigationService.navigate(menuItem)">
                        <a routerLink="{{ menuItem.url }}" [ngClass]="{ 'li-collapsed': !leftBarExpanded }">
                          <mat-icon *ngIf="menuItem.icon && !menuItem.image">{{ menuItem.icon }}</mat-icon>
                          <img
                            class="c_asset-image"
                            *ngIf="menuItem.image && !menuItem.icon && !menuItem.image.path.endsWith('.svg')"
                            [src]="baseServerUrl + menuItem.image.path"
                            alt="image"
                          />
                          <i
                            *ngIf="menuItem.image && !menuItem.icon && menuItem.image.path.endsWith('.svg')"
                            [inlineSVG]="baseServerUrl + menuItem.image.path"
                            fallbackSVG="assets/images/default-left-menu-icon.svg"
                            class="icon"
                          >
                          </i>
                          <div class="c_no-icon-placeholder" *ngIf="!menuItem.icon && !menuItem.image"></div>
                          <div
                            *ngIf="
                              !menuItem?.localizationKey &&
                              menuItem.title &&
                              !menuItem.text &&
                              leftBarExpanded
                            "
                          >
                            {{ menuItem.title | titlecase }}
                          </div>
                          <div
                            class="inner-html-text"
                            *ngIf="!menuItem?.localizationKey && menuItem.text && leftBarExpanded"
                            [innerHTML]="menuItem.text"
                          ></div>
                          <span
                            *ngIf="!menuItem?.localizationKey"
                            class="menu-item-name"
                            [innerHTML]="menuItem.text"
                          ></span>
                          <span *ngIf="menuItem?.localizationKey && leftBarExpanded" class="menu-item-name">
                            {{ menuItem.localizationKey | translate }}
                          </span>
                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </aside>
</nav>
