import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickBarComponent } from './quick-bar.component';
import { AppDownloadPopupComponent } from './app-download-popup/app-download-popup.component';
import { QuickBarCorvusFrameDepositComponent } from './deposit-switch-by-client/quick-bar-corvus-frame-deposit/quick-bar-corvus-frame-deposit.component';
import { DepositSwitchByClientComponent } from './deposit-switch-by-client/deposit-switch-by-client.component';
import { QuickBarIpsQRCodeComponent } from './deposit-switch-by-client/quick-bar-ips-qrcode/quick-bar-ips-qrcode.component';
import { QuickBarIpsBankAccountDepositComponent } from './deposit-switch-by-client/quick-bar-ips-bank-account-deposit/quick-bar-ips-bank-account-deposit.component';
import { QuickBarAllsecureDepositComponent } from './deposit-switch-by-client/quick-bar-allsecure-deposit/quick-bar-allsecure-deposit.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TournamentListQuickBarComponent } from './tournament-list-quick-bar/tournament-list-quick-bar.component';
import { CustomCheckboxComponent } from '../../shared/components/custom-checkbox/custom-checkbox.component';
import { CustomSelectModule } from '../../shared/components/custom-select/custom-select.module';
import { QRCodeModule } from 'angularx-qrcode';
import { MatIconModule } from '@angular/material/icon';

const COMPONENTS = [
  QuickBarComponent,
  AppDownloadPopupComponent,
  QuickBarCorvusFrameDepositComponent,
  TournamentListQuickBarComponent,
  // TODO napravi modul za sheckbox pa da ga moze importovati i u share i u tvoj modul
  CustomCheckboxComponent,
  DepositSwitchByClientComponent,
  QuickBarIpsBankAccountDepositComponent,
  QuickBarIpsQRCodeComponent,
  QuickBarAllsecureDepositComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    TranslateModule,
    InlineSVGModule,
    FormsModule,
    CustomSelectModule,
    ReactiveFormsModule,
    QRCodeModule,
    MatIconModule,
  ],
  exports: [...COMPONENTS],
})
export class QuickBarModule {}
