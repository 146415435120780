import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  direction_t,
  IDigitByDigit,
  IDisplayOdometer,
  IDisplayText,
  IOdometerRequest,
  IOdometerSpeedStarts,
  speed_t,
} from '../utils/odometer.utils';
/**
 * Odometer v2
 *
 *
 * **** Take a look at odometer.utils.ts to see all the types and globals used ****
 *
 *
 * How it works : Value is received through input binding. Firts time value is sent odometer just displays
 *                that value on the screen. Every next time it will do the animation.
 *
 *                There are 3 cases -
 *                    Lenght stays the same. - EASY - This can mean that the value could either increased or decreased.
 *                    Length goes up.        - HARD - Value increased.
 *                    Length goes down.      - HARD - Value decreased.
 *
 *
 *                Number value is converted to a string using the decimalPipe with 1.2-2 pattern.
 *                String is split by the "" (empty) and every character is mapped to either
 *                { isOdometer: true, ... }  if its value is a number and
 *                { isOdometer: false, ... } if the value isn't a number.
 *
 *                Based on 'isOdomter' property we render 'odometer-col' or 'odometer-text-col'.
 *
 *
 * Speeds       : There are 3 "speed modes" digits could spin in -
 *                    Slow     - Spin only the necessary amount.
 *                    Fast     - Based on slow spinner. If slow spinner goes from 2 to 5, fast spinner will make 3 (5 - 2) full loops.
 *                    Veryfast - Spins the minimum of 5 loops and adds the aditional loop based on index (Not important. Doesn't make much difference).
 *
 */
@Component({
  selector: 'app-odometer-v2',
  templateUrl: './odometer-v2.component.html',
  styleUrls: ['./odometer-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe],
})
export class OdometerV2Component implements AfterViewInit {
  private currentValue = 0;
  public displayArray: (IDisplayOdometer | IDisplayText)[] = [];

  constructor(private renderer: Renderer2, private decimalPipe: DecimalPipe) {}

  ngAfterViewInit(): void {
    if (this.centerCounter) {
      this.renderer.setStyle(this.valuesWrapper.nativeElement, 'margin', `auto`);
    }
    this.renderer.setStyle(this.valuesWrapper.nativeElement, 'height', `${this.digitHeight}px`);
    this.renderer.setStyle(this.valuesWrapper.nativeElement, 'line-height', `${this.digitHeight}px`);
  }

  @Input() digitHeight = 20;
  @Input() centerCounter = false;

  @ViewChild('valuesWrapper') valuesWrapper: ElementRef<HTMLDivElement>;

  @Input() set nextValue(nextVal: number) {
    // Firts time value is sent just display it.
    if (this.currentValue === 0) {
      this.currentValue = nextVal;
      this.displayArray = this.mapNumberStringToArray(this.transform(nextVal));
      return;
    }

    if (nextVal === this.currentValue) {
      return;
    }

    const transformedCurrentValue = this.transform(this.currentValue);
    const transformedNextValue = this.transform(nextVal);

    /**
     * There are two cases eather diff is positive or negative
     * Based on that we have to figure out how to spin our odometer
     * */
    const direction: direction_t = nextVal > this.currentValue ? 'plus' : 'minus';

    // Lengths are the same
    if (transformedCurrentValue.length === transformedNextValue.length) {
      // ===========================================
      // /************** Just calc ****************/
      // ===========================================

      const transformedNextValueArr = transformedNextValue.split('');
      const transformedCurrentValueArr = transformedCurrentValue.split('');

      const digitByDigit: IDigitByDigit[] = transformedNextValueArr.map((nextV, index) => ({
        nextValue: nextV,
        currentValue: transformedCurrentValueArr[index],
      }));

      /**
       * We need a way to figure out where is the last digit that needs to spin in slow mode
       *
       * Examples :
       *        "It's first and the last"
       *        Current Value : 1,932.34 ---- Length : 8
       *                            ^ 2 is the last digit that needs to spin in slow mode
       *        Next Value    : 1,934.65 ---- Length : 8
       *        Diff          :     2.31 ---- Length : 4
       *
       *        lastSlowSpinnerIndex : 8 - 4 = 4
       *
       *        "It's last but not the first"
       *        Current Value : 1,932.34 ---- Length : 8
       *                            ^ 1 is the last digit that needs to spin in slow mode
       *                              In this case 3 will spin to 4 also in slow mode which is what we want
       *        Next Value    : 1,941.65 ---- Length : 8
       *        Diff          :     9.31 ---- Length : 4
       *
       *        lastSlowSpinnerIndex : 8 - 4 = 4
       */
      let firstSlowIndex: number = null;

      transformedNextValueArr.map((n, index) => {
        const c = transformedCurrentValueArr[index];
        if (n !== c) {
          if (firstSlowIndex === null) {
            firstSlowIndex = index;
          }
        }
      });

      const slowIndex = firstSlowIndex;
      /**
       * fastIndex is the next index after slow
       *
       * There is a catch :
       *        In the case above named "It's first and the last" 2 will spin slow. That means that the "." will spin fast (Impossible)
       *        Which is not what we want. We want 3 to spin "fast" and 4 to spin "veryfast".
       *        We'll fix this issue later.
       *
       */
      const fastIndex = slowIndex + 1;
      // Not needed for now : const veryFastIndex = slowIndex + 2;

      this.displayArray = this.generateDisplayArray(
        transformedCurrentValue,
        digitByDigit,
        slowIndex,
        fastIndex,
        direction
      );
    } else if (transformedCurrentValue.length < transformedNextValue.length) {
      // ===========================================
      // /************** Add digit ****************/
      // ===========================================

      /**
       * Problem here is to generate the right starting array
       * Example:
       *        CurrentValue :   997.34
       *        NextValue    : 1,002.88
       *        What we want : 0,997.34
       */

      // We need to multiply nextVal by 10, transform it to a string, and then replace the '1' with the '0'

      // Important: This array will be mutated
      const transformedTMPArr = transformedNextValue.split('');
      const transformedNextValueArrOriginal = transformedNextValue.split('');

      /**
       * Construct the string that has the placeholders for all other digits that
       * will be used by the next value that comes.
       *
       * How is it done?
       *
       * Example:
       *        CurrentValue :   997.34 ---- Length = 6
       *        NextValue    : 1,002.88 ---- Length = 8
       *                         ^ Replace starts here
       *        replaceStartIndex = 2 (8 - 6);
       *        replaceEndIndex = 8 (Just the last index)
       *  */

      const replaceStartIndex = transformedNextValue.length - transformedCurrentValue.length;
      const replaceEndIndex = transformedNextValue.length - 1;

      transformedTMPArr.splice(replaceStartIndex, replaceEndIndex, ...transformedCurrentValue.split(''));

      // Replace all of the numbers at the start with a 0
      // so we have a clean state that we can then work on
      for (let i = 0; i < replaceStartIndex; i++) {
        if (this.isNotANumber(transformedTMPArr[i])) {
          // It's a "," | "."
        } else {
          transformedTMPArr[i] = '0';
        }
      }

      // Make the {next, curr}[] based on which we will be able to tell
      // what digits need turning and by how much

      const digitByDigit: IDigitByDigit[] = transformedNextValueArrOriginal.map((v, i) => ({
        nextValue: v,
        currentValue: transformedTMPArr[i],
      }));

      // If we are adding new digits only the first digit should spin in slow mode
      const slowIndex = 0;
      const fastIndex = slowIndex + 1;

      const textThatShouldBeProcessed = transformedTMPArr.join('');

      this.displayArray = this.generateDisplayArray(
        textThatShouldBeProcessed,
        digitByDigit,
        slowIndex,
        fastIndex,
        direction
      );
    } else {
      // ===========================================
      // /************* Remove digit **************/
      // ===========================================

      /**
       * In this case we have a problem because of the lengths of the arrays.
       *
       * Example:
       *        CurrentValue : 1,232.87 ---- Length = 8
       *        NextValue    :   994,32 ---- Length = 6
       *                       ^^ These two places shouldBeDeleted
       *
       *        "shouldBeDeleted" flag tells the component that it should delete itself after the animation.
       *
       * How it works:
       *        Firts of all we need to reverse the arrays.
       *        Loop through the bigger array (CurrentValue).
       *
       *        CurrentValueRev : 78.232,1
       *        NextValueRev    : 23,499__
       *
       *        If the index in the bigger array does not exist in the smaller array
       *        it means that current item in a loop should be deleted after the animation is done.
       */
      const transformedNextValueArrRev = transformedNextValue.split('').reverse();
      const transformedCurrValueArrRev = transformedCurrentValue.split('').reverse();

      const result = transformedCurrValueArrRev
        .map((v, i) => ({
          currentValue: v,
          // Here instead of setting '0' we should check if the item is number
          // and only then set '0'. But it doesn't matter beacuse we are deleting it anyway.
          // Also that item has isOdometer: false so it will never use that value
          nextValue: transformedNextValueArrRev[i] === undefined ? '0' : transformedNextValueArrRev[i],
          shouldBeDeleted: transformedNextValueArrRev[i] === undefined,
        }))
        .reverse();

      // Only first value should spin in slow mode
      const slowIndex = 0;
      const fastIndex = slowIndex + 1;

      this.displayArray = this.generateDisplayArray(
        transformedCurrentValue,
        result,
        slowIndex,
        fastIndex,
        direction
      );
    }

    this.currentValue = nextVal;
  }

  private isNotANumber(val: any) {
    return isNaN(Number(val));
  }

  private transform(number: number) {
    return this.decimalPipe.transform(number, '1.2-2');
  }

  /**
   *
   * @param transformedCurrentValue String created from number that should be processed
   * @param digitByDigit Array of grouped digits
   * @param slowIndexStart
   * @param fastIndexStart
   * @param direction
   * @returns Array of IDisplay's that are ready to be used in html
   *
   */
  private generateDisplayArray(
    transformedCurrentValue: string,
    digitByDigit: IDigitByDigit[],
    slowIndexStart: number,
    fastIndexStart: number,
    direction: direction_t
  ): (IDisplayOdometer | IDisplayText)[] {
    // Map that keeps track of when the each section of speeds start
    // We use it in "odometer-v2-col" to alter the "veryfast" speed and
    // make it seem more random.
    const speedStarts: IOdometerSpeedStarts = { slow: null, fast: null, veryfast: null };

    /**
     * Difference that the last slow spinner has to make.
     * Used in fast spinner to determine how many loops it should do.
     * */
    let slowDiff = 0;

    // Remapping the array if IDisplay's and setting valid values
    return this.mapNumberStringToArray(transformedCurrentValue).map((displayItem, index, arr) => {
      if (displayItem.isOdometer) {
        // IDisplayOdometer

        const digitByDigitItem = digitByDigit[index];

        let difference = 0;

        /**
         * Calculate the difference based on direction.
         * There are 2 cases in every direction which makes 4 cases in total.
         *
         * N : NextValue
         * C : CurrentValue
         * Plus:
         *    C    N
         *    3 -> 7 --> 4, 5, 6, 7
         *
         *    N - C = 4
         *    Take 4 steps in Plus direction
         *
         *
         *    C    N
         *    7 -> 3 --> 8, 9, 0, 1, 2, 3
         *
         *    N - C = -4
         *    -4 + 10 = 6
         *    Take 6 steps in Plus direction
         *
         *    **** Plus comes down to N - C ****
         *
         *
         *
         * Minus:
         *    C    N
         *    5 -> 3 --> 4, 3
         *
         *    C - N = 2
         *    Take 2 steps in Minus direction
         *
         *
         *    C    N
         *    3 -> 5 --> 2, 1, 0, 9, 8, 7, 6, 5
         *
         *    C - N = -2
         *    -2 + 10 = 8
         *    Take 8 steps in Minus direction
         *
         *    **** Minus comes down to C - N ****
         *
         * Important : In both cases if difference is < 0 add 10 to it and it will wrap in around
         *  */
        if (direction === 'plus') {
          difference = Number(digitByDigitItem.nextValue) - Number(digitByDigitItem.currentValue);
        } else {
          difference = Number(digitByDigitItem.currentValue) - Number(digitByDigitItem.nextValue);
        }

        if (difference < 0) {
          difference += 10;
        }

        let speed: speed_t = 'veryfast';

        // All the items before slowIndexEnd should also spin in slow mode

        if (index <= slowIndexStart) {
          if (speedStarts.slow === null) {
            /**
             * OdometerIndex
             *
             * Index that keeps track of digits only.
             * We use it in "odometer-v2-col" to alter the "veryfast" speed and
             * make it seem more random.
             *
             * Example:
             *        CurrentValue  : 1,234.56
             *        OdometerIndex : 0 123 45
             */
            speedStarts.slow = displayItem.odometerIndex;
            // If Item after the slow index is "," | "." move fastIndex + 1 to skip it
          }
          slowDiff = difference;
          if (slowIndexStart === index && !arr[index + 1]?.isOdometer) {
            fastIndexStart += 1;
          }
          speed = 'slow';
        } else if (index === fastIndexStart) {
          if (speedStarts.fast === null) {
            speedStarts.fast = displayItem.odometerIndex;
          }
          speed = 'fast';
        } else {
          if (speedStarts.veryfast === null) {
            speedStarts.veryfast = displayItem.odometerIndex;
          }
          // Speed is already 'veryfast'
        }

        const odometerRequest: IOdometerRequest = {
          steps: difference,
          speed,
        };

        displayItem.odometerRequest = odometerRequest;
        displayItem.speedStarts = speedStarts;
        displayItem.slowDiff = slowDiff;
        displayItem.direction = direction;
        displayItem.shouldBeDeleted = digitByDigitItem.shouldBeDeleted;
        return displayItem;
      } else {
        // IDisplayText
        displayItem.shouldBeDeleted = digitByDigit[index].shouldBeDeleted || false;
        return displayItem;
      }
    });
  }

  /**
   *
   * @param str string that will be processed
   * @returns Array of IDisplay's
   */
  mapNumberStringToArray(str: string): (IDisplayOdometer | IDisplayText)[] {
    let odometerIndex = 0;
    return str.split('').map(singleValue => {
      if (this.isNotANumber(singleValue)) {
        // IDisplayText
        return {
          isOdometer: false,
          textValue: singleValue,
          shouldBeDeleted: false,
        };
      } else {
        // IDisplayOdometer
        return {
          isOdometer: true,
          odometerIndex: odometerIndex++,
          odometerStartingValue: Number(singleValue),
          odometerRequest: null,
          speedStarts: null,
          slowDiff: 0,
          direction: 'plus',
          shouldBeDeleted: false,
        };
      }
    });
  }
  // TESTING

  public sendValue(num: number) {
    this.nextValue = num;
  }
}
