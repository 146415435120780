import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from './notification.service';
import { SoliticsEventReportingService } from './solitics-event-reporting.service';
import { SalesForceChatService } from './sales-force-chat.service';
import { IpsDepositService } from './ips-deposit.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private ngbModalService: NgbModal,
    private soliticsEventReportService: SoliticsEventReportingService,
    private salesforceChatService: SalesForceChatService,
    private ipsDepositService: IpsDepositService
  ) {}

  /**
   * Login
   * @param data - Object with properties: email, password and session details
   */
  public login(data): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'auth', data);
  }

  /**
   * Logout
   */
  logout(from): void {
    // eslint-disable-next-line
    console.log('logout from: ', from);
    const that = this;
    // close ws connection
    this.notificationService.closeConnectionMbase();

    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_SERBIA:
        this.salesforceChatService.setSalesForceChat();
        this.soliticsEventReportService.logout();
        // IPS payment - remove subscriptions, timer and page reload listener.
        this.ipsDepositService.clearSubscriptions();
        this.logoutfromServer('Serbia case');
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
        this.salesforceChatService.setSalesForceChat();
        this.soliticsEventReportService.logout();
        // IPS payment - remove subscriptions, timer and page reload listener.
        this.ipsDepositService.clearSubscriptions();
        this.logoutfromServer('Pinnbet case');
        break;
      case CLIENT_NAMES.ADMIRAL_MONTENEGRO:
      case CLIENT_NAMES.ADMIRAL_MD_SHOP:
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
      case CLIENT_NAMES.ADMIRAL_BOSNIA:
        this.soliticsEventReportService.logout();
        // logout fallback mechanism if helpcrunch callback failed
        setTimeout(() => {
          this.logoutfromServer('fallback HelprCrunch');
        }, 2000);

        // HelpCrunch chat logout need to be execute before app logout user
        if ((window as any).HelpCrunch) {
          (window as any).HelpCrunch('logout', () => {
            this.logoutfromServer('HelpCrunch case');
          });
        } else {
          this.logoutfromServer('undefined HelpCrunch');
        }
        break;
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        // close nxcs ws connection
        this.notificationService.closeConnectionNxcs();
        this.logoutfromServer('Croatia case');
        break;
      case CLIENT_NAMES.ADMIRAL_UGANDA:
        // logout fallback mechanism if helpcrunch callback failed
        setTimeout(() => {
          this.logoutfromServer('fallback uganda');
        }, 2000);

        if ((window as any).Tawk_API) {
          (window as any).Tawk_API.endChat();
          (window as any).Tawk_API.logout(function (error) {
            if (error) {
              console.log('Tawk_API logout error:', error);
            } else {
              that.logoutfromServer('Uganda case from Tawk_API logout');
            }
          });
        }
        break;
      default:
        this.logoutfromServer('default');
        break;
    }
  }

  // logout from server
  private logoutfromServer(from): void {
    // eslint-disable-next-line
    console.log('logoutfromServer:', from);
    const request = this.http.post(environment.apiUrl + 'auth/revoke', {});
    // close all active modals on logout if there is some open
    this.ngbModalService.dismissAll();
    request.subscribe({
      next: data => {
        this.localStorageService.clearStorage();
        location.reload();
      },
      error: error => {
        this.localStorageService.clearStorage();
        location.reload();
      },
    });
  }

  /** Change password
   * @param data - object with properties : token, oldPassword, newPassword
   * @param playerId - property to reference logged in player for change password
   */
  changePassword(data, playerId) {
    return this.http.put(environment.apiUrl + `player/${playerId}/change-password`, data);
  }

  /**
   * Forgot password
   * @param data - Object with properties: email
   */
  forgotPassword(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/reset-password-link', data);
  }

  /**
   * Reset password
   * @param token - Token extracted from url
   * @param data - Object with properties: newPassword, repeatedPassword
   */
  resetPassword(token: string, data): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/reset-password/' + token, data);
  }

  /**
   * get all sessions
   * @param params - Object with properties: limit, offset
   */
  getAllSessions(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'session', { params });
  }

  /**
   * @param promoCode for registration
   * api return boolean for code validity IF it true,
   * then proceed with registration if it false send message to user and
   * continue with registration process.
   */
  checkPromoCode(promoCode) {
    return this.http.get(`${environment.apiUrl}promo-code/${promoCode}`);
  }

  /**
   * @param code Code that will be checked.
   * Expected response from API is boolean value, based on code validity.
   */
  public checkReferFriendCode(code: string) {
    return this.http.get(`${environment.apiUrl}refer-friend/${code}`);
  }

  /**
   * @param email for registration
   * Infobip check email validity
   * then proceed with registration if it's success response.
   */

  checkEmailByInfobip(email) {
    return this.http.post(environment.apiUrl + 'registration/email-info-bip-validation', {
      to: email,
    });
  }

  /**
   * sign up (register)
   * @param data - Object with properties: email, username, password,
   *  repeatedPassword, firstName, lastName, dateOfBirthStr, address,
   * zip, city, pid, acceptedTandC, subscriber, gender
   */
  register(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration', data);
  }

  /**
   * resend activation email
   * @param data - Object with properties: newEmail and email
   */
  resendActivationEmail(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/resend-activation', data);
  }

  /**
   * resend activation sms
   * @param data - Object with properties: newFullNumber and fullNumber
   */
  resendActivationSms(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/resend-activation', data);
  }

  /**
   *  activate account
   * @param activationToken - Token extracted from url
   */
  activateAccount(activationToken): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/activation', { activationToken });
  }

  /**
   *  activate account by code with email
   * @param email - email to
   * @param verificationCode - Code from email to which code was sent
   */
  codeAccountActivationWithEmail(email, verificationCode): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/activation', { email, verificationCode });
  }

  /**
   *  activate account by code with email
   * @param email - email to
   * @param verificationCode - Code from email to which code was sent
   * @param reactivation - account reactivation param true/false
   */
  queryParamAccountActivationCRO(email, verificationCode, reactivation): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/activation', {
      email,
      verificationCode,
      reactivation,
    });
  }

  /**
   *  activate account by code with phone number
   * @param fullNumber - send sms to
   * @param verificationCode - Code from mobile device to which code was sent
   */
  codeAccountActivationWithPhoneNumber(fullNumber, verificationCode): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/activation', { fullNumber, verificationCode });
  }

  /**
   *  update token
   *
   */
  updateToken(): Observable<any> {
    return this.http.post(environment.apiUrl + 'heartbeat', {});
  }

  /**
   * Method for request for reset security question for MD Shop client
   */
  requestResetMDShopSecurityQuestion(): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/reset-security-question', {});
  }

  /**
   * Method for reset security question for MD Shop client
   * @data - object with password and personalQandA
   */
  resetMDShopSecurityQuestion(data): Observable<any> {
    return this.http.put(environment.apiUrl + 'player/reset-security-question', data);
  }

  /**
   *  suggest username based on email
   * @param email - email for suggestion
   */
  suggestsUsername(email): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/suggest-username', { email });
  }

  /**
   * Proceed without phone and increase player step cro registration
   */
  increaseStepSkipUsingPhone(): Observable<any> {
    return this.http.post(environment.apiUrl + 'registration/mobile?skip=true', {});
  }

  /**
   * Check oib validation
   * @param oibData - object with properies firstName, lastName, pid, dateOfBirthStr
   */
  oibCheck(oibData, initialCall): Observable<any> {
    return this.http.post(environment.apiUrl + `registration/check-pid?initial-call=${initialCall}`, oibData);
  }

  /**
   * Login player from iOS application
   * @param data - defence token, session data from IOS app
   */
  loginIOSPLayer(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/consume-dt', data);
  }

  /**
   * Create password
   * @param data - token, new password, repeted password
   */
  createPlayerPassword(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/create-password', data);
  }

  getCountries(): Observable<any> {
    return this.http.get(environment.apiUrl + 'country');
  }

  croRegistrationByStep(step: string, submitData: any, params?: any): Observable<any> {
    return this.http.post(environment.apiUrl + `registration/${step}`, submitData, { params });
  }
}
