import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-info',
  templateUrl: './info.dialog.html',
  styleUrls: ['./info.dialog.scss'],
})
export class InfoDialog {
  /**data includes propery description
   * @param description - translate key for description
   */
  @Input() data;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;

  public imagesBaseUrl = environment.imagesBaseUrl;
  constructor(private activeModal: NgbActiveModal) {}

  public closeDialog() {
    this.activeModal.close();
  }
}
