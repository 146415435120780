import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { DateService } from '@services/date.service';
import { AppStateFacadeService } from '@state/app-state.facade';

export interface QuickBarItems {
  itemType: string;
  active: boolean;
  duration: number;
  tournamentID?: number;
}
@Injectable({
  providedIn: 'root',
})
export class QuickBarService {
  private quickBarStates = new BehaviorSubject<'hide' | 'show' | 'expand'>('hide');
  private activeItemsInBar = new BehaviorSubject<{ itemType: string; active: boolean; duration: number }[]>(
    []
  );

  public quickBarStates$ = this.quickBarStates.asObservable();
  public activeItemsInBar$ = this.activeItemsInBar.asObservable();
  constructor(
    private localStorageService: LocalStorageService,
    private dateService: DateService,
    private appStateFacadeService: AppStateFacadeService
  ) {}

  setQuickBarStates(state?: 'hide' | 'show' | 'expand'): void {
    this.checkQuickBarVisibility(state);
  }

  public wakeUpQuickBarOnNotification(): void {
    this.localStorageService.removeFromLocalStorage('showQuickBar');
    this.activeItemsInBar.next([{ itemType: 'cardDeposit', active: true, duration: 1000 }]);
    // this.appStateFacadeService.setAppQuickBarStatus(true);
    this.checkQuickBarVisibility('expand');
  }

  setActiveItemsInBar(items: QuickBarItems[]): void {
    this.activeItemsInBar.next(items);
    // if(items.length > 0) { this.appStateFacadeService.setAppQuickBarStatus(true); }
    this.checkQuickBarVisibility();
  }

  private checkQuickBarVisibility(state?): void {
    // IF state is passet overide all other checks
    const stateDependOfContent = this.activeItemsInBar.value.length > 0 ? 'show' : 'hide';
    const popup = this.localStorageService.getShowQuickBarStatus();
    if (!popup || state === 'expand') {
      this.quickBarStates.next(state || stateDependOfContent);
      return;
    }
    const durationDate = popup['date'];
    if (this.dateService.isAfterToday(durationDate) || state === 'show') {
      this.quickBarStates.next(stateDependOfContent);
      this.localStorageService.removeFromLocalStorage('showQuickBar');
    } else {
      this.quickBarStates.next('hide');
      // this.appStateFacadeService.setAppQuickBarStatus(false);
    }
  }

  public closeQuickBar(): void {
    const tomorrow = this.dateService.momentToday().add(1, 'day');
    const duration = {
      day: 1,
      date: tomorrow,
    };
    this.localStorageService.setShowQuickBar(duration);
    // this.appStateFacadeService.setAppQuickBarStatus(false);
    this.quickBarStates.next('hide');
  }

  public getQuickBarHeight(): string {
    const isDownloadAppIsActive = this.activeItemsInBar
      .getValue()
      .find(item => item.itemType === 'downloadApp' && item.active);
    const isPaymentActive = this.activeItemsInBar
      .getValue()
      .find(item => item.itemType === 'cardDeposit' && item.active);
    const state = this.quickBarStates.getValue();
    switch (true) {
      case isDownloadAppIsActive && state !== 'hide':
        return 'calc(100% - 50px)';
      case isPaymentActive && state === 'expand':
        return 'calc(100% - 140px)';
      case isPaymentActive && state !== 'hide':
        return 'calc(100% - 90px)';
      default:
        return '100%';
    }
  }
}
