import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomSelectItemComponent } from './custom-select-item/custom-select-item.component';
import { CustomSelectComponent } from './custom-select.component';

@NgModule({
  imports: [ScrollingModule, CommonModule],
  declarations: [CustomSelectComponent, CustomSelectItemComponent],
  exports: [CustomSelectComponent],
})
export class CustomSelectModule {}
