import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BalanceInfo } from '@models/balance-info.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BonusService } from '@services/bonus.service';
import { DepositService } from '@services/deposit.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PlayerService } from '@services/player.service';
import { ToasterService } from '@services/toaster.service';
import { WithdrawalService } from '@services/withdrawal.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { BalanceInfoInterface } from '@state/interfaces/AppStateInterface.interface';
import { amountValidator } from '@validators/amount.validator';
import { Observable, Subscription } from 'rxjs';
import { BonusFreebetWarningDialog } from '../../dialogs/bonus-freebet-warning-dialog/bonus-freebet-warning.dialog';

@Component({
  selector: 'app-reserved-funds',
  templateUrl: './reserved-funds.component.html',
  styleUrls: ['./reserved-funds.component.scss'],
})
export class ReservedFundsComponent implements OnInit, OnDestroy {
  public reservedForm: FormGroup;
  public reservedBalance = 0;
  public isWithdrawal: boolean;
  public isLoading: boolean;
  public balanceInfo: BalanceInfo;
  public player;

  public reserved_payment = {
    id: 0,
    code: 'RESERVED_FUNDS_DEPOSIT',
    deposit: true,
    currency: '€',
    logo: '../../../../../../assets/images/rezervirano.png',
    minValue: 0,
    maxValue: 0,
    name: this.localStorageService.getLanguage() === 'hr' ? 'Rezervirano' : 'Reserved',
    predefinedValues: [0, 5, 10, 20, 50, 100],
  };

  private balanceInfoObservable: Observable<any>;
  private balanceInfo$: Subscription;
  private playerObservable: Observable<any>;
  private player$: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private depositService: DepositService,
    private withdrawalService: WithdrawalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private bonusService: BonusService,
    private modalService: NgbModal,
    private playerService: PlayerService,
    private appStateFacadeService: AppStateFacadeService,
    private localStorageService: LocalStorageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.setupStore();
    this.setInitArrowDirection();
    this.getReservedFundsData();
    this.setBalanceInfoSubscription();
    this.setPlayerSubscription();
  }

  ngOnDestroy(): void {
    this.balanceInfo$.unsubscribe();
    this.player$.unsubscribe();
  }

  private buildForm() {
    this.reservedForm = this.fb.group(
      {
        amount: [''],
      },
      {
        validators: [amountValidator],
      }
    );
  }

  private setupStore(): void {
    this.balanceInfoObservable = this.appStateFacadeService.getBalanceInfo();
    this.playerObservable = this.appStateFacadeService.getPlayerDataObservable();
  }

  private setInitArrowDirection(): void {
    // withdrawal online >>> office
    // deposit online <<< office
    const url = this.router.url;
    this.isWithdrawal = url.includes('withdrawal/reserved') ? true : false;
    this.reserved_payment.code = this.isWithdrawal ? 'RESERVED_FUNDS_WITHDRAWAL' : 'RESERVED_FUNDS_DEPOSIT';
    this.reserved_payment.deposit = !this.isWithdrawal;
  }

  private getReservedFundsData(): void {
    this.playerService.getReservedFunds().subscribe(data => {
      this.setReservedBalance(data);
    });
  }

  private setReservedBalance(data): void {
    this.reservedBalance = data.balance;
    this.setMaxValidatorValue();
  }

  private setBalanceInfoSubscription(): void {
    this.balanceInfo$ = this.balanceInfoObservable.subscribe((balanceState: BalanceInfoInterface) => {
      if (balanceState) {
        this.balanceInfo = balanceState.playerBalanceInfo;
        this.setMaxValidatorValue();
      }
    });
  }

  private setPlayerSubscription(): void {
    this.player$ = this.playerObservable.subscribe(playerData => {
      this.player = playerData;
      this.reserved_payment.currency = playerData?.currency;
      this.reserved_payment.maxValue =
        playerData && playerData.currency.toLowerCase() === '€' ? 30000 : 100000;
    });
  }

  // transfer money
  public onFormSubmit(): void {
    this.isLoading = true;
    // withdrawal
    if (this.isWithdrawal) {
      this.bonusService.checkIfBonusOrFreebetWillBeDeactivated(this.amount.value).subscribe(data => {
        if (data.bonuses && data.bonuses.length) {
          this.openBonusWarningDialog(data);
        } else {
          this.doWithdrawal();
        }
      });
    } else {
      // deposit
      this.doDeposit();
    }
  }

  // open bonus warning dialog
  private openBonusWarningDialog(data) {
    this.isLoading = false;
    const modalRef = this.modalService.open(BonusFreebetWarningDialog, {
      centered: true,
      scrollable: false,
      size: 'md',
      backdrop: 'static',
    });

    modalRef.componentInstance.wrapper = data;

    modalRef.result.then(accepted => {
      if (accepted) {
        this.doWithdrawal();
      }
    });
  }

  public doWithdrawal() {
    const dataToSend = {
      increase: true,
      amount: this.amount.value,
      playerId: this.player?.id,
      currencyId: this.player?.currencyId,
    };
    this.withdrawalService.saveReservedFundsFromMbase(dataToSend).subscribe({
      next: data => {
        this.afterTransfer(data);
      },
      error: error => {
        this.isLoading = false;
      },
    });
  }

  public doDeposit() {
    const dataToSend = {
      increase: false,
      amount: this.amount.value,
      playerId: this.player?.id,
      currencyId: this.player?.currencyId,
    };
    this.depositService.saveReservedFundsToMbase(dataToSend).subscribe({
      next: data => {
        this.googleAnalyticsService.eventEmitterCro('Deposit', 'Deposit', 'Rezervirano', data.balance);
        this.afterTransfer(data);
      },
      error: error => {
        this.isLoading = false;
      },
    });
  }

  private afterTransfer(data): void {
    this.isLoading = false;
    this.setReservedBalance(data);
    this.amount.reset();
    this.toaster.showSuccess(this.translate.instant('MONEY_TRANSFER_SUCCESSFUL'));
  }

  // change arrow direction
  public toggleSide(): void {
    this.isWithdrawal = !this.isWithdrawal;
    this.reserved_payment.deposit = !this.isWithdrawal;
    this.isWithdrawal
      ? (this.reserved_payment.code = 'RESERVED_FUNDS_WITHDRAWAL')
      : (this.reserved_payment.code = 'RESERVED_FUNDS_DEPOSIT');
    this.setMaxValidatorValue();
  }

  // set max amount value to validator amount field
  private setMaxValidatorValue(): void {
    const maxAmountValue = this.isWithdrawal
      ? this.balanceInfo?.availableForWithdrawal
      : this.reservedBalance;
    this.addAmountValidators(maxAmountValue);
  }

  // add amount validators after we got needed data from backend
  private addAmountValidators(maxAmountValue) {
    this.amount.setValidators([Validators.max(maxAmountValue), Validators.required, Validators.min(1)]);
    this.amount.updateValueAndValidity();
  }

  public setPredefinedValueAllAvailableAmount(): void {
    this.isWithdrawal
      ? this.reservedForm.patchValue({ amount: this.balanceInfo.availableForWithdrawal.toString() })
      : this.reservedForm.patchValue({ amount: this.reservedBalance.toString() });
  }

  // getters
  public get amount(): AbstractControl {
    return this.reservedForm.get('amount');
  }
}
