import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements TranslateLoader {
  constructor(private http: HttpClient) {}

  // get translation
  getTranslation(language: string): Observable<any> {
    return this.http.get(environment.apiUrl + `locale`);
  }

  getLanguages(): Observable<any> {
    return this.http.get(environment.apiUrl + `language`);
  }
}
