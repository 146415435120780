import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Player } from '@models/player.model';
import { filter } from 'rxjs/operators';
import { ConfigData } from '@models/config-data/config-data.model';
import { CmsGeneralHeaderSettings } from '../header-cms/cmsHeader.model';
import { AppStateFacadeService } from '@state/app-state.facade';
import { SideBar, SideMenusConfigurations } from './cmsSideMenu.model';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { NavigationService } from '@services/navigation.service';
import { baseServerUrl } from 'src/environments/environment';

@Component({
  selector: 'app-left-menu-cms',
  templateUrl: './left-menu-cms.component.html',
  styleUrls: ['./left-menu-cms.component.scss'],
})
export class LeftMenuCmsComponent implements OnInit {
  @ViewChild('contentOfMenu', { static: false }) contentOfMenu: ElementRef;
  @Input() player: Player;
  public baseServerUrl = baseServerUrl;
  public lefMenuData: SideBar;
  public leftBarExpanded: boolean;
  private sideMenuData: SideMenusConfigurations;
  private configDataObservable$: Subscription;

  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isTablet = this.appStateFacadeService.getIsTabletStatus();
  private windowWidth: number;
  private leftBarStatus$: Subscription;
  private currentTheme: any;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public navigationService: NavigationService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
    this.setLeftMenuType();
  }

  ngOnInit() {
    this.setLeftMenuSubscription();
    this.configDataObservable$ = this.appStateFacadeService
      .getConfigData()
      .pipe(filter((data: ConfigData) => !!data))
      .subscribe((data: ConfigData) => {
        this.sideMenuData = new SideMenusConfigurations(JSON.parse(data.sideMenuV2));
        this.currentTheme = new CmsGeneralHeaderSettings(
          JSON.parse(data.generalV2).filter((dataGeneral: any) => dataGeneral.active)[0].style.leftSidebar
        );
        this.setLeftMenuType();
        this.setStyleOfSideBar();
      });
  }

  setLeftMenuType(): void {
    if (!this.isMobile && !this.isTablet) {
      switch (true) {
        case this.windowWidth < 576: // Extra small devices (portrait phones, less than 576px)
          this.lefMenuData = this.sideMenuData.mobileWidth.leftSideBar;
          break;
        case this.windowWidth >= 576 && this.windowWidth < 768: // Small devices (landscape phones, 576px and up)
          this.lefMenuData = this.sideMenuData.tabletWidth.leftSideBar;
          break;
        case this.windowWidth >= 768: // Medium devices (tablets, 768px and up) and Large devices (desktops, 992px and up)
          this.lefMenuData = this.sideMenuData.desktopWidth.leftSideBar;
          break;
        default:
          this.lefMenuData = this.sideMenuData.desktopWidth.leftSideBar;
      }
    } else {
      this.lefMenuData = this.isTablet
        ? this.sideMenuData.tabletWidth.leftSideBar
        : this.sideMenuData.mobileWidth.leftSideBar;
    }
  }

  private setStyleOfSideBar() {
    this.elementRef.nativeElement.style.setProperty(
      '--backgroundColor',
      this.currentTheme.backgroundColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuItemIconColor',
      this.currentTheme.menuItemIconColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuItemIconHoverColor',
      this.currentTheme.menuItemIconHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuItemIconActiveColor',
      this.currentTheme.menuItemIconActiveColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuItemTextColor',
      this.currentTheme.menuItemTextColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuItemTextHoverColor',
      this.currentTheme.menuItemTextHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuItemTextActiveColor',
      this.currentTheme.menuItemTextActiveColor.hex
    );
    this.elementRef.nativeElement.style.setProperty('--mainColor', this.currentTheme.mainColor.hex);
  }

  private setLeftMenuSubscription(): void {
    this.leftBarStatus$ = this.appStateFacadeService.observeLeftBarStatus().subscribe((status: boolean) => {
      this.leftBarExpanded = status;
      if (this.contentOfMenu) {
        this.renderer.setProperty(this.contentOfMenu.nativeElement, 'scrollTop', '0');
      }
    });
  }

  public handleLeftSideBar() {
    this.appStateFacadeService.expandCollapseLeftSideBar();
  }

  // close left menu on swipe
  imagesBaseUrl: any;
  public closeLeftMenuOnSwipe() {
    if (!this.leftBarExpanded) {
      return;
    }

    if (!this.appStateFacadeService.getIsLeftSidebarPresent() && this.isMobile) {
      this.handleLeftSideBar();
    }
  }

  public navigateToLinkInMenu(link, event: PointerEvent) {
    event.preventDefault();
    this.appStateFacadeService.collapseAllSideBars();
    this.navigationService.navigate(link);
  }
}
