import { Injectable } from '@angular/core';
import { Office } from '../../shared/models/office.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseServerUrl, environment } from '../../../environments/environment';
import { Withdrawal } from '@models/withdrawal.model';

@Injectable({ providedIn: 'root' })
export class WithdrawalService {
  constructor(private http: HttpClient) {}

  /**
   * Get depozitron offices
   * @param params - Representing withdrawal request data - consists of propertie amount
   */
  getDepozitronOffices(params): Observable<Office[]> {
    return this.http.post<Office[]>(
      baseServerUrl + '/depozitron-withdrawal/erne/api/depozitron/available-locations',
      params
    );
  }

  /**
   * Get depozitron config
   */
  getDepozitronConfig(): Observable<any> {
    return this.http.get(baseServerUrl + '/depozitron-withdrawal/erne/api/depozitron/config');
  }

  /**
   * Get offices
   * @params - include city param (name of the city)
   */
  getOffices(params): Observable<Office[]> {
    return this.http.get<Office[]>(environment.apiUrl + 'office/for-withdrawal', { params });
  }

  /**
   * Get cities
   */
  getCities(): Observable<any> {
    return this.http.get(environment.apiUrl + 'office/distinct-cities');
  }

  /**
   * Method for fetching all withdrawals
   */
  getWithdrawals(): Observable<Withdrawal[]> {
    return this.http.get<Withdrawal[]>(environment.apiUrl + 'payment-product/withdrawal');
  }

  /**
   * Method for fetching all withdrawals requests
   * @param params - consists of limit and offset
   */
  getWithdrawalRequests(params): Observable<any> {
    return this.http.get(environment.apiUrl + 'withdrawals/list', { params });
  }

  /**
   * Method for withdrawal type by code
   * @param code typeof string for getting a particular withdrawal type can be found in withdrawal-types.enum
   */
  getWithdrawal(code): Observable<any> {
    return this.http.get(environment.apiUrl + 'payment-product/withdrawal/' + code);
  }

  /**
   * Method for updating withdrawal request via provided id
   * Setting withdrawal request on cancel
   * @param withdrawal - Representing withdrawal object for update
   * @param type - Representing type of withdrawal as Office/Bank/Skrill
   */
  cancelWithdrawalRequest(withdrawal, url: string): Observable<any> {
    const id = withdrawal.id;
    return this.http.put(`${url}/${id}`, withdrawal);
  }

  /**
   * Method for creating new office withdrawal request
   * @param formValue - Representing withdrawal request data - consists of properties officeId and amount
   */
  createWithdrawalRequest(formValue, url: string): Observable<any> {
    return this.http.post<any>(url, formValue);
  }

  /**
   * Method for fetching bank account data for logged user
   */
  getBankAccountData(): Observable<any> {
    return this.http.get<Withdrawal>(environment.apiUrl + 'player/bank-account-number');
  }

  /**
   * Method for fetching blinking bank account data for logged user
   */
  getBlinkingBankAccounts(): Observable<any> {
    return this.http.get<Withdrawal>(environment.apiUrl + 'player/active-bank-accounts');
  }

  /**
   * Method for updating bank account data
   * @param bankAccountObjectPayload - object with property bankAccountNumber
   */
  updateBankAccount(bankAccountObjectPayload): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'player/bank-account-number', bankAccountObjectPayload);
  }

  /**
   * Method for updating IBAN bank account data
   * @param bankAccountNumber  bank number;
   */
  updateIbanBankAccount(bankAccountNumber): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/iban', { bankAccountNumber });
  }

  /**
   * Method for checking security question for MD Shop client
   * @data - object with password and personalQandA
   */
  checkMDShopSecurityQuestion(data): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'player/validate-withdrawal', data);
  }

  /**
   * @param url - withdrawal url
   * @param amount - amount for withdrawal
   */
  withdrawalAmount(url, amount): Observable<any> {
    return this.http.post<any>(url, { amount });
  }

  /**
   * Admiral360 withdrawal
   * save internal transaction from mbase to nxcs
   * @param url - withdrawal url
   * @param amount - amount
   */
  saveBalanceFromMbase(url, amount): Observable<any> {
    return this.http.post(url, { amount });
  }

  /**
   * Check if player have active withdrawal
   */
  checkIfActiveWithdrawalExists(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'player/exists-active-withdrawal');
  }

  /**
   * Send code on SMS or EMAIL for withdrawal in office for CRO
   */
  sendEmailOrSms(code: string, type: string): Observable<any> {
    return this.http.post(environment.apiUrl + `player/withdrawal-notice/${code}?type=${type}`, {});
  }

  /**
   * Save player reserved funds
   * @param data { increase = true, amount, playerId, currencyId }
   */
  saveReservedFundsFromMbase(data): Observable<any> {
    return this.http.post(baseServerUrl + '/reserved-funds/erne/api/reserved-funds/transfer', data);
  }

  /**
   * get banks
   */
  getBanks(): Observable<any> {
    return this.http.get(environment.apiUrl + 'bank');
  }

  /**
   * calculate iban
   * @param bankAccountNumber account number
   * @param bankIdentifier identifier of the bank
   */
  calculateIBanNumber(bankAccountNumber, bankIdentifier): Observable<any> {
    return this.http.post(environment.apiUrl + 'player/iban-help', {
      bankAccount: bankAccountNumber,
      bankIdentifier,
    });
  }

  /**
   * post Okto request to approval
   * @param amaunt - amount
   * @returns qrcode || message
   */
  postOktoCashWithdrawal(amaunt): Observable<any> {
    return this.http.post(
      baseServerUrl + `/okto-withdrawal/erne/api/okto-withdrawal/init-withdrawal?amount=${amaunt}`,
      {}
    );
  }

  /**
   * get approved request
   * @param requestId - request
   * @returns qrcode || message
   */
  getApprovedOktoRequest(requestId: string): Observable<any> {
    return this.http.get(baseServerUrl + '/okto-withdrawal/erne/api/okto-withdrawal/payment-code');
  }

  /**
   * cancel withdrawal request
   * @param requestID - request
   * return message
   */
  cancelOktoWithdrawalRequest(requestID: number): Observable<any> {
    return this.http.post(
      baseServerUrl + `/okto-withdrawal/erne/api/okto-withdrawal/deactivate-code/${requestID}`,
      {},
      { responseType: 'text' }
    );
  }
}
