import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Observable, interval } from 'rxjs';
import { Player } from 'src/app/shared/models/player.model';
import { filter, map } from 'rxjs/operators';
import { ConfigData } from '@models/config-data/config-data.model';
import { environment } from 'src/environments/environment';
import { Menu } from '@models/config-data/menu/menu.model';
import { NavigationService } from '@services/navigation.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { AppHtmlElementsService } from '@services/app-html-elements.service';
import {
  BalanceInfoInterface,
  MessagesInterface,
} from '../../shared/state/interfaces/AppStateInterface.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@services/local-storage.service';

@Component({
  selector: 'app-header-cro',
  templateUrl: './header-cro.component.html',
  styleUrls: ['./header-cro.component.scss'],
})
export class HeaderCroComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('headerContainer') headerContainer: ElementRef;
  public player: Player;
  public btnClass: string;
  public loginLink: 'login' | 'login-redirect';
  public loginQueryParams: { kskId: string } | null;
  public unreadMessages: number;
  public showRegistrationBtn = true;
  public clock = interval(1000).pipe(map(() => new Date())); // clock implementation
  public playerRegistrationStep;
  public showUserInfoAndDepositBtn = true;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public showContinueRegistrationBtn: boolean;
  public balanceInfo: BalanceInfoInterface;

  // Config
  public mainMenu: Menu;
  public logoImg: string;
  public environment = environment;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public unauthorizedUser: boolean;

  // Observables
  private routerObservable: Observable<any>;
  private unreadMessagesObservable: Observable<any>;
  private configDataObservable: Observable<any>;
  private playerDataObservable: Observable<Player>;

  // Subscriptions
  private router$: Subscription;
  private unreadMessages$: Subscription;
  private configData$: Subscription;
  private playerData$: Subscription;
  private balanceInfo$: Subscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public navigationService: NavigationService,
    private appStateFacadeService: AppStateFacadeService,
    private appHtmlElementsService: AppHtmlElementsService,
    private localStorageService: LocalStorageService
  ) {
    this.setupStore();
  }

  ngOnInit(): void {
    this.setConfigDataSubscription();
    this.setUnreadMessagesSubscription();
    this.setPlayerSubscription();
    this.setRouterSubscription();
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['kskId']) {
        this.localStorageService.setKskId(queryParams['kskId']);
        window.history.replaceState(null, '', location.pathname);
      }
    });
  }

  ngAfterViewInit() {
    this.appHtmlElementsService.headerContainerElement = this.headerContainer.nativeElement;
    this.appHtmlElementsService.setHeaderContainerStatus();
  }

  ngOnDestroy() {
    this.configData$.unsubscribe();
    this.unreadMessages$.unsubscribe();
    this.playerData$.unsubscribe();
    this.balanceInfo$.unsubscribe();
    if (this.router$) {
      this.router$.unsubscribe();
    }
  }

  // setup store
  private setupStore(): void {
    this.unreadMessagesObservable = this.appStateFacadeService.getMessagesObservable();
    this.configDataObservable = this.appStateFacadeService.getConfigData();
    this.playerDataObservable = this.appStateFacadeService.getPlayerDataObservable();
    this.routerObservable = this.appStateFacadeService.observeCurrentUrlStatus();
  }

  // set config subscription
  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable.subscribe((configData: ConfigData) => {
      // sometimes configData is null at first and in that case we GET https://admiralbet.rsundefined/ net::ERR_NAME_NOT_RESOLVED
      // where undefined is result of previous logic configData?.logo
      if (configData) {
        this.logoImg = environment.imagesBaseUrl + configData.logo;
        this.mainMenu = configData.appMenus.mainMenu;
        this.appStateFacadeService.setLogoImgPath(this.logoImg);
      }
    });
  }

  private setUnreadMessagesSubscription(): void {
    this.unreadMessages$ = this.unreadMessagesObservable.subscribe((data: MessagesInterface) => {
      this.unreadMessages = data.total;
    });
  }

  private setPlayerSubscription(): void {
    this.playerData$ = this.playerDataObservable.pipe(filter(data => !!data)).subscribe(player => {
      this.player = player;
      this.showContinueRegistrationBtn = !this.isMobile && !this.player.registrationCompleted;
    });
    this.balanceInfo$ = this.appStateFacadeService
      .getBalanceInfo()
      .subscribe((balance: BalanceInfoInterface) => {
        this.balanceInfo = balance;
      });
  }

  // for continue registration cro button
  private setRouterSubscription(): void {
    this.router$ = this.routerObservable.subscribe(currentUrl => {
      if (currentUrl.includes('/registration')) {
        this.showRegistrationBtn = false;
        this.showContinueRegistrationBtn = false;
        this.showUserInfoAndDepositBtn = false;
      } else {
        this.showContinueRegistrationBtn =
          !this.isMobile && this.player && !this.player.registrationCompleted;
        this.showUserInfoAndDepositBtn = true;
      }
    });
  }

  public handleLeftSideBar() {
    this.appStateFacadeService.expandCollapseLeftSideBar();
  }

  public handleRightSideBar() {
    const chatWindowOpen = this.appStateFacadeService.isChatWindowOpen();
    this.appStateFacadeService.setChatWindowWasOpenBeforeRightBarExpand(chatWindowOpen);
    this.appStateFacadeService.expandCollapseRightSideBar();
  }

  authRouteNavigation(event, url) {
    this.navigationService.checkRegistrationStatusAndNavigate({
      url,
      type: 'INTERNAL_LINK_SUBMENU',
    });
  }
}
