<div [formGroup]="paymentForm">
  <div class="form-group">
    <div class="row">
      <div class="col-12">
        <label class="label required">{{ 'COMMON_VERIFICATION_ENTER_CODE' | translate }}</label>
      </div>
      <div class="col-12">
        <input
          inputmode="numeric"
          type="text"
          class="form-control"
          [minlength]="minDigitsLength"
          [maxlength]="maxDigitsLength"
          formControlName="voucherCode"
          (input)="onlyNumbersAllow($event)"
          placeholder="{{ 'COMMON_VERIFICATION_ENTER_CODE' | translate }}"
          [ngClass]="{
            'is-invalid':
              (paymentForm.get('voucherCode').hasError('required') &&
                paymentForm.get('voucherCode').touched) ||
              (paymentForm.get('voucherCode').hasError('minlength') &&
                paymentForm.get('voucherCode').touched),
            'is-valid': paymentForm.get('voucherCode').valid && paymentForm.get('voucherCode').touched
          }"
        />
        <small class="invalid-feedback" *ngIf="paymentForm.get('voucherCode').hasError('required')">
          {{ 'REQUIRED_FIELD' | translate }}
        </small>
        <small *ngIf="paymentForm.get('voucherCode').hasError('minlength')" class="invalid-feedback">
          {{ 'CODE_MUST_CONTAIN' | translate }} {{ minDigitsLength }} {{ 'CHARACTERS' | translate }}
        </small>
      </div>
    </div>
    <div class="row mt-3" *ngIf="showPromoCode && isPaymentPromoCodeEnabled">
      <div class="col-12">
        <label class="label">{{ 'COMMON_PROMO_CODE' | translate }}</label>
      </div>
      <div class="col-12">
        <input
          type="text"
          class="form-control"
          formControlName="promoCode"
          placeholder="{{ 'COMMON_VERIFICATION_ENTER_CODE' | translate }}"
        />
      </div>
    </div>
  </div>
  <!-- T&C of the app -->
  <div *ngIf="paymentForm.get('tAndC'); else paymentTandC">
    <div class="row">
      <div class="col-lg-7">
        <div class="form-group">
          <div class="mb-3">
            <app-custom-checkbox [checkboxFormControl]="paymentForm.get('tAndC')">
              <span>
                <small>{{ 'DEPOSIT_I_ACCEPT_THE' | translate }}</small>
                <a class="text-primary small" [routerLink]="['/terms-and-conditions/payment-terms']">
                  {{ 'DEPOSIT_T_AND_C' | translate }}
                </a>
                <small>{{ 'DEPOSIT_REDIRECT_TO_PAYMENT' | translate }}</small>
              </span>
            </app-custom-checkbox>
          </div>
        </div>
      </div>
      <div class="col-lg-5 text-center text-lg-right">
        <button
          type="submit"
          [disabled]="paymentForm.invalid || !voucherCode.value"
          [ngClass]="{
            disabled: paymentForm.invalid || !voucherCode.value
          }"
          class="btn btn-success ml-2"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
  <!-- T&C of the payment -->
  <ng-template #paymentTandC>
    <div *ngIf="paymentForm.get('paymentTandC'); else noTandC" class="row">
      <div class="col-lg-7">
        <div class="form-group">
          <div class="mb-3">
            <app-custom-checkbox [checkboxFormControl]="paymentForm.get('paymentTandC')">
              <span>
                <a class="text-primary small" [href]="paymentTandCLink" target="_blank">
                  {{ paymentTandCLabel }}
                </a>
              </span>
            </app-custom-checkbox>
          </div>
        </div>
      </div>
      <div class="col-lg-5 text-center text-lg-right">
        <button
          type="submit"
          [disabled]="paymentForm.invalid || !voucherCode.value"
          [ngClass]="{
            disabled: paymentForm.invalid || !voucherCode.value
          }"
          class="btn btn-success ml-2"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </ng-template>
  <!-- No T&C -->
  <ng-template #noTandC>
    <div class="col-lg-12 text-center text-center">
      <button
        type="submit"
        [disabled]="paymentForm.invalid || !voucherCode.value"
        [ngClass]="{
          disabled: paymentForm.invalid || !voucherCode.value
        }"
        class="btn btn-success ml-2"
      >
        {{ buttonText }}
      </button>
    </div>
  </ng-template>
</div>
