import { animate, style, transition, trigger } from '@angular/animations';

// eslint-disable-next-line
export const heightAnimation = (animationTiming: string) => {
  return trigger('heightAnimation', [
    transition(':enter', [style({ height: '0' }), animate(animationTiming, style({ height: '*' }))]),
    transition(':leave', [style({ height: '*' }), animate(animationTiming, style({ height: '0' }))]),
  ]);
};

// eslint-disable-next-line
export const fadeAnimationTop = (animationTiming: string) => {
  return trigger('fadeAnimationTop', [
    transition(':enter', [
      style({ transform: 'translateY(-100%)' }),
      animate(animationTiming, style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0%)' }),
      animate(animationTiming, style({ transform: 'translateY(-100%)' })),
    ]),
  ]);
};

// eslint-disable-next-line
export const switchAnimationChange = (animationTiming: string) => {
  return trigger('switchAnimationChange', [
    transition(':enter', [
      style({ transform: 'translateY(200%)' }),
      animate(animationTiming, style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0%)' }),
      animate(0, style({ transform: 'translateY(-100%)' })),
    ]),
  ]);
};

// eslint-disable-next-line
export const coinFallAnimation = () => {
  return trigger('coinFallAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(-200%)' }),
      animate('{{animationTiming}}', style({ transform: 'translateY(0%)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0%)' }),
      animate(0, style({ transform: 'translateY(100%)' })),
    ]),
  ]);
};
