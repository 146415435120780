export enum SOCKET_ACTION_TYPES {
  BALANCE_INFO = '[Balance] Balance has changed',
  MESSAGE_COUNT = '[Message] New message arived',
  SESSION_DURATION = '[Session] Session duration changed',
  SESSION_EXPIRATION = '[Session] Session expired',
  JACKPOT_CURRENT_VALUE = '[Jackpot] Jackpot new value',
  JACKPOT = '[Jackpot] Jackpot won',
  BONUS_CONFIRMATION = '[Bonus] Bonus confirmation',
  BONUS_NOTIFICATION = '[Bonus] Bonus notification',
  BONUS_REDEMPTION = '[WebSocket] Bonus Redemption',
  NO_FUNDS = '[WebSocket] No funds',
}
