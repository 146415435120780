<div
  (scroll)="scroll($event)"
  *ngIf="pageContent"
  [innerHTML]="pageContent | sanitizeHtml"
  class="termsPersonalDataContainer"
></div>

<div (click)="jumpToTop(dialogContent)" *ngIf="isJumpToTopAvailable" class="jump-to-top">
  <div [setSVGAttributes]="{ height: '20px', width: '20px' }" inlineSVG="/assets/icons/carret-up.svg"></div>
</div>
