<div class="box">
  <!-- HEADER -->
  <header class="pb-2">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{'DEPOSIT_STATUS' | translate}}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close">
          <img
            *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
            [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
            (click)="closeModal()"
          />
          <div
            *ngIf="clientName === clientNames.ADMIRAL_PINNBET"
            class="u_pointer"
            (click)="closeModal()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3 h3 text-center">
      <div class="text-uppercase status-success" *ngIf="depositStatus">
        {{'DEPOSIT_STATUS_SUCCESSFUL' | translate}}
      </div>
      <div class="text-uppercase status-error" *ngIf="!depositStatus">
        {{'DEPOSIT_STATUS_UNSUCCESSFUL' | translate}}
      </div>
    </div>
  </header>
  <!-- BODY -->
  <div class="px-5 pt-3">
    <!-- TXN SUCCESS MESSAGE -->
    <div *ngIf="depositStatus">
      <div class="row mb-2">
        <div class="col-4">
          <span class="text-muted">{{depositIntesa.amount}}</span>
        </div>
        <div class="col-8 text-right">
          <span class="text-light">{{'SUCCESS_DEPOSITET_TO_ACCOUNT' | translate}}</span>
        </div>
      </div>
      <hr />
    </div>
    <!-- TXN ERROR MESSAGE -->
    <div *ngIf="!depositStatus">
      <div class="text-light mb-2 center">{{depositIntesa.errorMessage}}</div>
    </div>

    <div class="row mb-2" *ngIf="depositStatus && depositIntesa.oid && depositIntesa.oid !== 'null'">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_ORDER_ID' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ depositIntesa.oid }}</span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="depositIntesa.AuthCode">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_AUTHORIZATION_CODE' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ depositIntesa.AuthCode }}</span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="depositIntesa.Response">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_PAYMENT_STATUS' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ depositIntesa.Response }}</span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="depositIntesa.extraTrxDate">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_TRANSACTION_DATE' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ depositIntesa.extraTrxDate | date: 'd MMM, y' }}</span>
      </div>
    </div>
    <!-- NOVABANKA TRANSACTION DATE -->
    <div class="row mb-2" *ngIf="data.dateTime">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_TRANSACTION_DATE' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ data.dateTime | date: 'd MMM, y' }}</span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="depositIntesa.ProcReturnCode">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_TRANSACTION_STATUS_CODE' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ depositIntesa.ProcReturnCode }}</span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="depositIntesa.TransId">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_TRANSACTION_ID' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ depositIntesa.TransId }}</span>
      </div>
    </div>
    <div class="row mb-2" *ngIf="depositIntesa.mdStatus">
      <div class="col-9">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_STATUS_CODE_3D_TRANSACTION' | translate }}</span>
      </div>
      <div class="col-3 text-right">
        <span class="text-light">{{ depositIntesa.mdStatus }}</span>
      </div>
    </div>
  </div>
  <hr />
  <div class="py-1 text-center">
    <div (click)="closeModal()" class="btn btn-outline-light px-6">OK</div>
  </div>
</div>
