import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OdometerV2ColComponent } from './odometer-v2-col/odometer-v2-col.component';
import { OdometerV2TextColComponent } from './odometer-v2-text-col/odometer-v2-text-col.component';
import { OdometerV2Component } from './odometer-v2/odometer-v2.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OdometerV2Component, OdometerV2ColComponent, OdometerV2TextColComponent],
  exports: [OdometerV2Component],
})
export class OdometerV2Module {}
