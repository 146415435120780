export class MenuItem {
  public id: number;
  public name: string;
  public url: string;
  public urlType: string;
  public type: string;
  public dialog: string;
  public subMenu: MenuItem[];
  public breakline: boolean;
  public requireAuthorized: boolean;
  public inactiveIcon: string;
  public bsClass: string;
  public horizontal: boolean;
  public activeIcon: string;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.url = data.url;
    this.type = data.type;
    this.dialog = data.dialog;
    this.subMenu = [];
    this.breakline = data.breakline;
    this.inactiveIcon = data.inactiveIcon || '';
    this.requireAuthorized = data.requireAuthorized;
    this.bsClass = data.bsClass;
    this.urlType = data.urlType;
    this.horizontal = data.horizontal;
    this.activeIcon = data.activeIcon;
    if (data.subMenu) {
      data.subMenu.forEach(menuItem => this.subMenu.push(new MenuItem(menuItem)));
    }
  }
}
