import { FooterColumnItem } from './footer-column-item.model';

export class FooterColumn {
  public title: string;
  public items: FooterColumnItem[];
  public code: string;
  public url: string;
  public urlType: string;

  constructor(data) {
    this.title = data.title;
    this.items = [];
    data.items.forEach(item => this.items.push(new FooterColumnItem(item)));
    this.code = data.code;
    this.url = data.url;
    this.urlType = data.urlType;
  }
}
