import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateFacadeService } from '@state/app-state.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-world-cup',
  templateUrl: './world-cup.component.html',
  styleUrls: ['./world-cup.component.scss'],
})
export class WorldCupComponent {
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public iframeUrl = environment.production
    ? 'https://worldcup.admiralbet.rs/1'
    : 'https://ntestsrb-sport.admiralbet.rs/website-contest/1';

  constructor(private appStateFacadeService: AppStateFacadeService, private router: Router) { }

  @HostListener('window:message', ['$event'])
  onSportMessage(event) {
    if (event.data.type === 'REDIRECT') {
      this.router.navigate([event.data.url]);
    }
  }
}
