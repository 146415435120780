import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PromotionService } from '@services/promotion.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { Subject, Subscription } from 'rxjs';
import { Wheel } from '@models/lucky-wheel.model';
import { environment } from 'src/environments/environment';
import { HelpersService } from '@services/helpers.service';
import { ToasterService } from '@services/toaster.service';

@Component({
  selector: 'app-lucky-wheel',
  templateUrl: './lucky-wheel.component.html',
  styleUrls: ['./lucky-wheel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LuckyWheel implements OnInit, OnDestroy {
  private luckyWheelResult$: Subscription;
  private luckyWheelClaimedPrize$: Subscription;
  private playAudio$: Subscription;
  public selectedWheel: Wheel;
  public wheelSpinning: boolean;
  public luckyWheelResultData: any;
  public currency = this.appStateFacadeService.getPlayerData().currency;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isAndroid = this.helpersService.isAndroid();
  public baseUrl = environment.imagesBaseUrl;
  // represent the ratio of a circle's circumference to its radius
  public TAU = Math.PI * 2;
  // angle of prize in the circle
  public ang = 0;
  // radius of the wheel
  public radius = 44.5;
  // speed of light balls in seconds
  public lightBallsSpeed = 1.5;
  // prizes arcs values in radians
  private prizeArcs = [];
  // light balls number in the wheel stroke circle
  public lightBalls = Array(10);
  // last active prize when spiining animation end
  private lastActivePrize: any;
  private componentDestroyed: boolean;
  private firstSpinAnimation;
  // ticker animation
  private tickerAnim;
  // flag to check if ticker animation is ending
  private tickerAnimPartOneEnding = false;
  //tick audio sound for needle animation
  private audio = new Audio('assets/images/wheel-of-fortune/tick.mp3');
  // do not remove next line, it is needed for audio to work on iOS
  private audioContext = new window.AudioContext();

  private wheelEl: HTMLElement;
  private needleEl: HTMLElement;
  private spinBtnEl: HTMLElement;
  private spinBtnTextEl: HTMLElement;
  private spinBtnIconEl: HTMLElement;
  private pulseImageEl: HTMLElement;

  checkTimeout_backend: any;
  checkTimeout_tickerPartOne: any;
  checkTimeout_niddle: any;

  private playAudioSubject = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private promotionService: PromotionService,
    private appStateFacadeService: AppStateFacadeService,
    private helpersService: HelpersService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.luckyWheelResultSubscription();
    this.luckyWheelClaimPrizeSubscription();
  }

  ngOnDestroy(): void {
    this.componentDestroyed = true;
    this.luckyWheelResult$.unsubscribe();
    this.luckyWheelClaimedPrize$.unsubscribe();
  }

  public detectChange() {
    this.cdr.detectChanges();
  }

  public selectWheel(wheel: Wheel) {
    this.resetWheel();
    this.selectedWheel = wheel;
    this.cdr.detectChanges();
    this.getElements();
    this.prizeArcs = this.selectedWheel.prizes.map(prize => {
      const prizeAngleDegrees = prize.endAngle - prize.startAngle;
      const prizeAngleRadians = (prizeAngleDegrees / 360) * this.TAU;
      return prizeAngleRadians;
    });
  }

  // send a request to the server that wheel needs to be spin, socket returns result of the spin
  public spinTheWheel() {
    if (this.wheelSpinning) {
      return;
    }
    this.promotionService.spinTheWheel(this.selectedWheel.id).subscribe({
      next: response => {
        this.wheelSpinning = true;
        this.firstSpinAnimation = this.wheelEl.animate(
          [
            { transform: `translate(50%, 50%) rotate(0deg) translate(-50%, -50%)` },
            { transform: `translate(50%, 50%) rotate(360deg) translate(-50%, -50%)` },
          ],
          {
            duration: 1000, // 1 second for a full rotation
            iterations: Infinity,
            easing: 'linear',
          }
        );
        // inside spin button show value of prize while spinning
        this.spinBtnTextEl.classList.remove('display-none');
        this.spinBtnIconEl.classList.remove('display-none');
        // hide pulse image in that case
        this.pulseImageEl.classList.add('display-none');
        // speed of light balls animation while spinning
        this.lightBallsSpeed = 0.5;
        this.cdr.detectChanges();
        this.runTickerAnimation();
      },
    });
  }

  // catch the result of the spin from the socket
  private luckyWheelResultSubscription() {
    this.luckyWheelResult$ = this.appStateFacadeService.getLuckyWheelResultObservable().subscribe(result => {
      if (!result || result.message === 'ERROR') {
        this.toasterService.showErrorTranslatingKey('LUCKY_WHEEL_SPIN_ERROR');
        this.firstSpinAnimation.cancel();
        cancelAnimationFrame(this.tickerAnim);
        if (this.playAudio$) {
          this.playAudio$.unsubscribe();
        }
        this.selectWheel(this.selectedWheel);
        this.cdr.detectChanges();
        return;
      }
      this.spin(result);
    });
  }

  private spin(result: any) {
    // if the result is not for the selected wheel, socket is sending the result for some other wheel
    if (result.wheelId !== this.selectedWheel.id) {
      return;
    }

    // Generate random float in range min-max:
    const rand = (min, max) => Math.random() * (max - min) + min;
    // Fix negative modulo stackoverflow.com/a/71167019/383904
    const mod = (n, m) => ((n % m) + m) % m;

    const animationDuration = rand(4000, 5000);
    this.checkTimeout_tickerPartOne = setTimeout(() => {
      this.tickerAnimPartOneEnding = true;
    }, animationDuration * 0.6);

    this.cdr.detectChanges();

    // const winningPrize = this.selectedWheel.prizes.find(prize => prize.id === result.prizeId);
    const winningPrizeIndex = this.selectedWheel.prizes.findIndex(prize => prize.id === result.prizeId);
    // Calculate cumulative arc for each prize
    const cumulativeArcs = [0];
    for (let i = 1; i < this.selectedWheel.prizes.length; i++) {
      cumulativeArcs[i] = cumulativeArcs[i - 1] + this.prizeArcs[i - 1];
    }

    // fix negative degrees using a helper modulo function
    const angAbs = mod(this.ang, this.TAU);

    let angNew;
    if (winningPrizeIndex === cumulativeArcs.length - 1) {
      // handle the case when index is the last element in the array
      angNew = cumulativeArcs[0];
    } else {
      angNew = cumulativeArcs[winningPrizeIndex + 1];
    }

    // since we don't want to end the spin exactly on the prize edge, subtract same random 0 to arc degrees:
    angNew -= rand(0.25, this.prizeArcs[winningPrizeIndex] - 0.25);

    // fix negative degrees using a helper modulo function
    angNew = mod(angNew, this.TAU);

    // now that we have the two angles (angAbs and angNew) get the angles difference:
    const angDiff = mod(angNew - angAbs, this.TAU);

    // min number of spins before stopping
    const minNoOfSpins = this.TAU * Math.floor(rand(3, 4)); // extra 3 or 4 full turns

    // calculate angle based on the difference + min number of spins
    this.ang += angDiff + minNoOfSpins;

    // cancel the first animation and get current angle of the wheel
    this.firstSpinAnimation.cancel();
    cancelAnimationFrame(this.tickerAnim);
    this.cdr.detectChanges();

    const currentAngleFirstAnimation = this.getCurrentAngle(this.wheelEl);

    const secondSpinAnimation = this.wheelEl.animate(
      [
        { transform: `translate(50%, 50%) rotate(${currentAngleFirstAnimation}deg) translate(-50%, -50%)` },
        { transform: `translate(50%, 50%) rotate(${this.ang}rad) translate(-50%, -50%)` },
      ],
      {
        duration: animationDuration,
        easing: 'ease-out',
        fill: 'forwards',
      }
    );

    // start the ticker animation
    this.runTickerAnimation();

    // when the spin animation ends
    secondSpinAnimation.addEventListener('finish', () => {
      if (this.componentDestroyed) {
        return;
      }
      // reset ticker animation part one to default state
      this.tickerAnimPartOneEnding = false;
      // stop the ticker animation
      cancelAnimationFrame(this.tickerAnim);
      if (this.playAudio$) {
        this.playAudio$.unsubscribe();
      }

      // Clear the ticker timeout when the wheel stops spinning
      clearTimeout(this.checkTimeout_tickerPartOne);
      this.checkTimeout_backend = setTimeout(() => {
        this.selectWheel(this.selectedWheel);
        this.cdr.detectChanges();
        this.notifyBackendThatWheelHasStopped(result);
      }, 1500);
    });
  }

  // notify backend that wheel has stopped, socket will popup message fro prize claim
  private notifyBackendThatWheelHasStopped(result: any) {
    this.promotionService.sendMessage(result.queueId).subscribe({
      next: () => {},
    });
  }

  // catch the result of the prize claim from message popup
  private luckyWheelClaimPrizeSubscription() {
    clearTimeout(this.checkTimeout_backend);
    this.luckyWheelClaimedPrize$ = this.promotionService.luckyWheelClaimedPrize$.subscribe(result => {
      const now = new Date().getTime();
      if (this.selectedWheel.id === result.wheelId) {
        this.selectedWheel.nextSpinAvailableFrom = result.nextSpinAvailableFrom;
        this.selectedWheel.isSpinAvailable = result.nextSpinAvailableFrom < now;
        if (this.selectedWheel.isSpinAvailable) {
          this.pulseImageEl.classList.remove('display-none');
        }
        // set spin available timer with fresh data in child component
        this.luckyWheelResultData = this.selectedWheel;
        this.cdr.detectChanges();
      }
    });
  }

  // this function creates the path for each prize
  public createSvgPath(prize) {
    const start = this.anglesToRadians(50, 50, this.radius, prize.startAngle);
    const end = this.anglesToRadians(50, 50, this.radius, prize.endAngle);
    const largeArcFlag = prize.endAngle - prize.startAngle <= 180 ? '0' : '1';
    // d is the path attribute for the svg element
    const d = [
      // "Move to (x,y)" the center of the circle
      'M',
      50,
      50,
      // "Line to (x,y)" draws a line from the current position to the position specified by the following two numbers
      'L',
      start.x,
      start.y,
      // "Arc (rx, ry, x-axis-rotation, large-arc-flag, sweep-flag, x, y)" draws an elliptical arc from the current point to the end point specified by x and y
      'A',
      this.radius,
      this.radius,
      0,
      largeArcFlag,
      1,
      end.x,
      end.y,
      'Z',
    ];

    return d.join(' '); // combine all elements of an array into a single string
  }

  // conversion is needed to calculate some points on the circle
  private anglesToRadians(centerX, centerY, radius, angleInDegrees) {
    // subtract 90 degrees from the angle so 0 degrees is at the top of the circle where needle is pointing
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0; // converting the angle from degrees to radians

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  public calculateLightBallsCoordinates(i: number, radius: number) {
    const angleOfLightBall = (this.TAU * i) / this.lightBalls.length;

    const cx = 50 + radius * Math.cos(angleOfLightBall);
    const cy = 50 + radius * Math.sin(angleOfLightBall);
    return { cx, cy };
  }

  public calculatePinPosition(prize, radius) {
    return this.anglesToRadians(50, 50, radius, prize.startAngle);
  }

  public calculateTextTransform(prize, radius) {
    const midAngle = (prize.startAngle + prize.endAngle) / 2;
    // 30 is a smaller radius to position the text inside the wheel
    const position = this.anglesToRadians(50, 50, radius, midAngle);
    // rotation of the text
    const rotation = `rotate(${-90 + midAngle})`;
    return `translate(${position.x}, ${position.y}) ${rotation}`;
  }

  // return the current angle of the element
  private getCurrentAngle(element) {
    const transform = window.getComputedStyle(element).transform;
    let angle;

    if (transform && transform !== 'none') {
      const values = transform.split('(')[1].split(')')[0].split(',');
      const a = parseFloat(values[0]);
      const b = parseFloat(values[1]);
      angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
    } else {
      angle = 0;
    }

    return angle < 0 ? angle + 360 : angle;
  }

  // This run ticker animation and checks which prize the needle is currently pointing to
  // this function is called recursively using requestAnimationFrame and run all the time when the wheel is spinning
  private runTickerAnimation(): void {
    // https://css-tricks.com/get-value-of-css-rotation-through-javascript/

    // Get the current rotation angle of the wheel
    const wheelAngle = this.getCurrentAngle(this.wheelEl);
    // Invert the wheel angle
    const invertedWheelAngle = 360 - wheelAngle;
    // Check each prize
    this.selectedWheel.prizes.forEach((prize, i) => {
      prize.currentStartAngle = (prize.startAngle - invertedWheelAngle + 360) % 360;
      prize.currentEndAngle = (prize.endAngle - invertedWheelAngle + 360) % 360;

      // this condition checks if 0 angle is between start and end angle of the prize
      // and goes through just one time for each prize when condition is true
      if (
        prize.currentEndAngle - prize.currentStartAngle < 0 &&
        (!this.lastActivePrize || this.lastActivePrize.id !== prize.id)
      ) {
        this.lastActivePrize = prize;

        if (this.tickerAnimPartOneEnding) {
          // remove tick-start animation
          this.needleEl.classList.remove('tick-start');
          // start tick-end animation
          this.startTickAnimation('tick-end');
        } else {
          // remove tick-end animation
          this.needleEl.classList.remove('tick-end');
          // start tick-start animation
          this.startTickAnimation('tick-start');
        }

        // Set the background color of the spin button to have winning prize color and value
        this.spinBtnEl.style.fill = prize.color;
        if (prize.displayText) {
          this.spinBtnTextEl.textContent = prize.displayText;
          this.spinBtnTextEl.classList.remove('display-none');
          this.spinBtnIconEl.classList.add('display-none');
        } else {
          this.spinBtnIconEl.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'href',
            `${this.baseUrl}/${prize.icon}`
          );
          this.spinBtnTextEl.classList.add('display-none');
          this.spinBtnIconEl.classList.remove('display-none');
        }
      }
    });
    this.tickerAnim = requestAnimationFrame(this.runTickerAnimation.bind(this));
  }

  private startTickAnimation(animationPart: string): void {
    if (this.isAndroid) {
      if (!this.audio.paused) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      this.audio.play();
    } else {
      this.playAudioSubject.next();
    }

    this.needleEl.classList.add(animationPart);
    this.needleEl.style.animation = 'none';
    clearTimeout(this.checkTimeout_niddle);
    this.checkTimeout_niddle = setTimeout(() => {
      this.needleEl.style.animation = null;
    }, 10);
  }

  private getElements(): void {
    this.wheelEl = document.querySelector('#wheel-element');
    this.needleEl = document.querySelector('#needle');
    this.pulseImageEl = document.querySelector('#pulseImage');
    if (!this.isAndroid) {
      this.pulseImageEl.addEventListener('click', () => {
        this.playAudio$ = this.playAudioSubject.subscribe(() => {
          this.audio.play();
        });
      });
    }
    this.spinBtnEl = document.querySelector('#spinButton');
    this.spinBtnTextEl = document.querySelector('#spinBtnText');
    this.spinBtnIconEl = document.querySelector('#spinBtnIcon');
    this.resetElementsToDefaults();
  }

  private resetWheel() {
    this.ang = 0;
    this.selectedWheel = null;
    this.cdr.detectChanges();
  }

  private resetElementsToDefaults() {
    this.audio.playbackRate = 2.0; // Double the speed
    this.needleEl.setAttributeNS(null, 'class', '');
    this.spinBtnEl.style.fill = 'url(#btn-inner-gradient)';
    this.spinBtnTextEl.classList.add('display-none');
    this.spinBtnIconEl.classList.add('display-none');
    this.lightBallsSpeed = 1.5;
    this.wheelSpinning = false;
  }
}
