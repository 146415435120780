import { FormControl } from '@angular/forms';

export function passwordValidator(c: FormControl) {
  // one capitalized letter, one number and minimum eight characters
  const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/;

  // if input is not dirty don't display error
  if (!c.dirty) {
    return null;
  }

  return PASSWORD_REGEX.test(c.value) ? null : { validatePassword: true };
}
