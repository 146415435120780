<div class="clearfix">
  <div class="box">
    <header class="mb-2">
      <h4 class="text-white mb-2">
        <span *ngIf="transactionType === 'game'">{{
          'GAME_TRANSACTIONS_BETTING_AND_GAMES' | translate | uppercase
        }}</span>
        <span *ngIf="transactionType === 'bonus'">{{ 'BONUS_TRANSACTIONS' | translate | uppercase }}</span>
        <span *ngIf="transactionType === 'freebet'">{{
          'FREEBET_TRANSACTIONS' | translate | uppercase
        }}</span>
      </h4>
    </header>

    <div class="tab-wrap">
      <div class="transactionsSectionContainer">
        <div class="tab-items">
          <app-buttons-bar
            (navigationEvent)="filterTransactions($event.code)"
            [buttonBarItems]="transactions"
            arrowBackgroundColor="cardColor"
          >
          </app-buttons-bar>
        </div>
      </div>

      <ng-container *ngIf="clientName === clientNames.ADMIRAL_CROATIA; else transactionByDateFilter">
        <app-transaction-filter
          #filterComponent
          [filterOpen]="filterOpen"
          [transactionQueryParams]="transactionQueryParams"
        >
        </app-transaction-filter>
      </ng-container>

      <ng-template #transactionByDateFilter>
        <app-transaction-date-filter [transactionQueryParams]="transactionQueryParams">
        </app-transaction-date-filter>
      </ng-template>

      <router-outlet></router-outlet>
    </div>
  </div>
</div>
