<div
  id="draggable"
  [ngClass]="[showLuckyWheelDraggable ? 'show' : 'hide']"
  cdkDrag
  (cdkDragStarted)="handleDragStart()"
  (cdkDragEnded)="handleDragEnd()"
  (click)="handleClick()"
>
  <div class="spinner-box">
    <img class="spin-anim" src="assets/images/wheel-of-fortune/wheel.png" alt="" />
  </div>
</div>
