<div
  [ngClass]="{ general: message.messageType === MESSAGE_TYPE.GENERAL }"
  class="popup-dialog box text-center c_popup-dialog-border"
>
  <div class="section section-header">
    <div *ngIf="!message?.jackpotAssignedId && !message?.cashbackId && !message.luckyWheelPrizeAwardQueueId">
      <i
        (click)="closeDialog()"
        class="pointer close-icon"
        inlineSVG="assets/images/close-cross.svg"
        [setSVGAttributes]="{ height: '16px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
    </div>
  </div>

  <div
    *ngIf="message.messageType !== MESSAGE_TYPE.GENERAL; else generalMessageContainer"
    class="c_image-background mb-2"
  >
    <img class="imgType mt-3" src="../../../../assets/images/{{ activeImg }}.png" alt="" />
  </div>

  <ng-template #generalMessageContainer>
    <div class="titleGeneral">{{ message.title }}</div>
  </ng-template>

  <div [ngClass]="{ general: message.messageType === MESSAGE_TYPE.GENERAL }" class="p-4">
    <p
      #htmlData
      *ngIf="message?.text"
      [ngClass]="{ mobile: isMobile }"
      class="text-center descParagraph"
      [innerHTML]="message?.text | sanitizeHtml"
    ></p>
    <p
      *ngIf="message?.textForTranslate"
      [ngClass]="{ mobile: isMobile }"
      class="text-center descParagraph"
      [innerHTML]="message?.textForTranslate | translate | sanitizeHtml"
    ></p>
    <!-- SUB TYPE REDIRECT -->
    <div
      *ngIf="message?.subType === MESSAGE_SUB_TYPE.REDIRECT && message?.buttonUrl && message?.buttonCode"
      class="text-center"
    >
      <div class="mb-1">
        <div (click)="navigate(message.buttonUrl)" class="c_popup-notification-btn">
          {{ message?.buttonCode }}
        </div>
      </div>
    </div>
  </div>

  <!-- SUB TYPE GAMES -->
  <div
    class="gamesContainer"
    *ngIf="message?.subType === MESSAGE_SUB_TYPE.GAMES && message?.games && message?.games.length"
  >
    <app-game-slider [games]="message?.games" (gameInfoOpened)="gameInfoOpened()"></app-game-slider>
  </div>

  <!-- SUB TYPE JACKPOT -->
  <div
    *ngIf="message?.subType === MESSAGE_SUB_TYPE.JACKPOT && message?.jackpotAssignedId"
    class="mb-1 jackpot"
  >
    <div (click)="acceptJackpot(message.jackpotAssignedId)" class="c_popup-notification-btn">
      {{ message?.buttonCode || ('JACKPOT_ACCEPTANCE_LABEL' | translate) }}
    </div>
  </div>

  <!-- SUB TYPE CASHBACK -->
  <div *ngIf="message?.subType === MESSAGE_SUB_TYPE.CASHBACK && message?.cashbackId" class="mb-1 jackpot">
    <div (click)="acceptCashback(message.cashbackId)" class="c_popup-notification-btn">
      {{ message?.buttonCode || ('JACKPOT_ACCEPTANCE_LABEL' | translate) }}
    </div>
  </div>

  <!-- SUB TYPE LUCKY WHEEL -->
  <div
    *ngIf="message?.subType === MESSAGE_SUB_TYPE.LUCKY_WHEEL && message?.luckyWheelPrizeAwardQueueId"
    class="mb-1 jackpot"
  >
    <div
      (click)="acceptLuckyWheelPrize(message.luckyWheelPrizeAwardQueueId)"
      class="c_popup-notification-btn"
    >
      {{ message?.buttonCode || ('JACKPOT_ACCEPTANCE_LABEL' | translate) }}
    </div>
  </div>
</div>
