export const CROATIA_THEME_COLORS = [
  { backgroundColor: '#0C092A' },
  { sideMenuColor: '#0D1A3C' },
  { admiralButtonColor: '#0D1A3C' },
  { sideMenuBackgroundColor: '#0D1A3C' },
  { primaryColor: '#4188FE' },
  { secondaryColor: '#002157' },
  { secondaryMenuColor: '#002157' },
  { successColor: '#98ff65' },
  { tertiaryColor: '#4188FE' },
  { cardColor: '#0D1A3C' },
  { cardColorActive: '#0b3b87' },
  { filterColor: '#181a1d' },
  { borderColor: '#313131' },
  { headerActiveElementColor: '#002b71' },
  { boxRowColor: '#002157' },
  { boxRowDesktopColor: '#002157' },
  { boxRowBorder: '1px solid #2f3235' },
  { inputColor: '#0C092A' },
  { boxInputBackgroundColor: '#0C092A' },
  { inputFontColor: '#fff' },
  { inputBorderColor: '#999999' },
  { depositCardColor: '#002157' },
  { desktopCardColor: '#0D1A3C' },
  { lobbyGameSearchColor: '#002b71' },
  { btnDepositClass: 'btn-outline-success' },
  { gameInfoBoxColor: 'rgba(33, 35, 37, 0.96)' },
  { activeWithdrawalBckgColor: 'rgb(150, 54, 54)' },
  { helpPageBackgroundColor: '#002157' },
  { btnDisabledColor: '#4188FE' },
  { btnPrimaryHoverColor: '#3173de' },
  { btnPrimaryBorderRadius: '2rem' },
  { btnAdmiralBorderRadius: '4px' },
  { btnPrimaryBorderHoverColor: '#3173de' },
  { btnPrimaryShadowColor: 'rgba(32, 103, 217, 0.5)' },
  { lightGrayColor: '#6c757d' },
  { backgroundImage: 'slot-theme-background-image-blue.svg' },
  { tournamentGradient: 'linear-gradient(116.84deg, #00378c 0.88%, #0065ff 100%)' },
  { leaderboardDialogGradient: 'linear-gradient(to bottom, #003180, #004FCF)' },
  { fontFamily: 'Barlow, sans-serif' },
  { fontColor: '#fff' },
  { primaryButtonTextColor: '#ffffff' },
  { buttonOutlineColor: '#212529;' },
  { subgroupButtonColor: '#0C092A' },
  { labelTextTransform: 'uppercase' },
  { labelFontSize: '10px' },
  { btnDarkColor: '#fff' },
  { linkNoHrefColor: 'inherit' },
  // toaster colors
  { toasterSuccessColor: '#c9ffaf' },
  { toasterSuccessBackgroundColor: '#1e3314' },
  { toasterSuccessBorderColor: '#2b471c' },
  { toasterSuccessAlertLinkColor: '#a7ff7c' },
  { toasterDangerColor: '#ff92b7' },
  { toasterDangerBackgroundColor: '#330917' },
  { toasterDangerBorderColor: '#470d21' },
  { toasterDangerAlertLinkColor: '#ff5f95' },
  { titleFontSize: '1.125rem' },
  { titleFontSizeMobile: '1.125rem' },
  { customSelectHoverColor: '#38393b' },
  { darkBtnHoverBackground: '#070708' },
  // Date picker colors
  { datePickerBorderColor: '#34373b' },
  { datePickerActiveElementBackground: '#393e41' },
  { datePickerSelectContainerBackground: '#0C092A' },
  { datePickerSelectContainerColor: '#fff' },
  { datePickerDayButtonBackground: '#0C092A' },
  { datePickerDayButtonColor: '#fff' },
];
