<div
  class="gameSliderContainer"
  [ngClass]="{
    'pinnbet-game-slider': environment.clientName === clientNames.ADMIRAL_PINNBET,
    'desktop-casino': !isMobile && location === 'CASINO_CAROUSEL',
    'desktop-promotion-item': !isMobile && location === 'PROMOTION_ITEM'
  }"
>
  <!-- LEFT ARROW -->
  <div class="iconWrapper" *ngIf="!isMobile && games.length > numberOfVisibleGames">
    <span class="icon icon-chevron-left" (click)="sideScroll($event, 'left')"></span>
  </div>
  <!-- GAME CONTAINER -->
  <div class="gamesContainer padding" [id]="componentId">
    <div
      *ngFor="let game of games"
      (click)="navigateToGameInfo(game); $event.stopPropagation()"
      class="gameImgWrapper gamesGap"
    >
      <img src="{{ environment.imagesBaseUrl + game.gameIconLocation }}" alt="" />
    </div>
  </div>
  <!-- RIGHT ARROW -->
  <div class="iconWrapper" *ngIf="!isMobile && games.length > numberOfVisibleGames">
    <span class="icon icon-chevron-right" (click)="sideScroll($event, 'right')"></span>
  </div>
</div>
