import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';

/**
 * This guard will check token expiration time and if token has expired
 * localstorage will be cleared and 401 response for authorized api calls
 * won't happen.
 */

export const tokenValidGuardCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const localStorageService: LocalStorageService = inject(LocalStorageService);

  const duration = Number(localStorage.getItem('tokenDuration'));
  const tokenExpirationTime = duration * 1000;
  const currentTime = Date.now();
  if (tokenExpirationTime < currentTime) {
    localStorageService.clearStorage();
  }
  return true;
};
