import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStateFacadeService } from '@state/app-state.facade';
import { CustomDialog } from '../../dialogs/custom-dialog/custom.dialog';
import { LuckyWheel } from '../lucky-wheel/lucky-wheel.component';
import { PromotionService } from '@services/promotion.service';
import { Subscription, interval, startWith } from 'rxjs';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { HelpersService } from '@services/helpers.service';

const LUCKY_WHEEL_API_INTERVAL = 60000;
@Component({
  selector: 'app-draggable-component',
  templateUrl: './draggable.component.html',
  styleUrls: ['./draggable.component.scss'],
})
export class DraggableComponent implements AfterViewInit, OnDestroy {
  public showLuckyWheelDraggable = false;
  public dragging = false;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  private intervalApiCall$: Subscription;
  private scrollWidth = this.isMobile ? 10 : 20;
  private additionalTop = this.isMobile ? 50 : 20;
  private additionalLeft = this.isMobile ? 0 : 20;
  @ViewChild(CdkDrag) private draggable: CdkDrag;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private modalService: NgbModal,
    private promotionsService: PromotionService,
    private cdr: ChangeDetectorRef,
    private helpersService: HelpersService
  ) {}

  ngAfterViewInit(): void {
    if (this.isMobile) {
      const iOSVersion = this.helpersService.iOSversion();
      if (iOSVersion && iOSVersion[0] < 16) {
        // iOS 15 and below
        window.addEventListener('orientationchange', () => {
          setTimeout(() => {
            this.positionDraggableElement();
          }, 500);
        });
      } else {
        // other devices
        screen.orientation.addEventListener('change', event => {
          setTimeout(() => {
            this.positionDraggableElement();
          }, 500);
        });
      }
    } else {
      window.addEventListener('resize', this.resizeHandler);
    }
    this.showDraggableComponent();
  }

  ngOnDestroy(): void {
    if (this.intervalApiCall$) {
      this.intervalApiCall$.unsubscribe();
    }

    window.removeEventListener('resize', this.resizeHandler);
  }

  private showDraggableComponent() {
    let hasCalledCreateDraggable = false;
    this.intervalApiCall$ = interval(LUCKY_WHEEL_API_INTERVAL)
      .pipe(startWith(0))
      .subscribe(() => {
        this.promotionsService.getLuckyWheels().subscribe(data => {
          if (data.length) {
            const now = new Date().getTime() + 1000; // add 1 second to avoid any issues with the time when spin is available imeadiately
            const showDraggable = data.find(
              wheel => wheel.playerInWheelSegment && wheel.nextSpinAvailableFrom <= now
            );
            if (showDraggable) {
              this.showLuckyWheelDraggable = true;
              this.cdr.detectChanges();
              // Check if createDraggable has been called before
              if (!hasCalledCreateDraggable) {
                this.positionDraggableElement();

                // Set the flag to true after calling the function
                hasCalledCreateDraggable = true;
              }
            } else {
              this.showLuckyWheelDraggable = false;
            }
          }
        });
      });
  }

  private positionDraggableElement() {
    const myElement = document.getElementById('draggable');
    if (!myElement) {
      return;
    }
    this.draggable.reset();
    // Set initial position to bottom right corner
    myElement.style.left =
      window.innerWidth - myElement.offsetWidth - this.scrollWidth - this.additionalLeft + 'px';
    myElement.style.top =
      window.innerHeight - myElement.offsetHeight - this.scrollWidth - this.additionalTop + 'px';
  }

  public handleClick() {
    if (this.dragging) {
      this.dragging = false;
      return;
    }
    const draggableContentDilaog = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: false,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });

    draggableContentDilaog.componentInstance.data = {
      closeButtonDeactivated: false,
      component: LuckyWheel,
    };
  }

  public handleDragStart(): void {
    this.dragging = true;
  }

  public handleDragEnd(): void {
    if (this.isMobile) {
      this.dragging = false;
    }
  }

  private resizeHandler = () => {
    this.positionDraggableElement();
  };
}
