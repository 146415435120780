export class BalanceInfo {
  balance: number;
  bonus: number;
  currencyIso: string;
  freebet: number;
  availableFreebet: number;
  freebetDiff: number;
  freebetUnwithdrawableAmount: number;
  level: any;
  nextLevel: any;
  nextLevelRequiredPoints: number;
  playerId: number;
  points: number;
  internalReservedFunds: number;
  systemReservedFunds: number;
  username: string;
  reservedDepositTurnover: any = '';
  internalProductsBalance: any = '';
  availableForWithdrawal: any = '';
  spentAmount: number;
  spentDiffAmount: number;
  nxcsAvailableForWithdrawal: any = '';
  unwithdrawableAmount: number;
  activeBonusId: number;
  bonusName: string;
  bonusRequiredAmount: number;
  freebetInitUnwithdrawableAmount: number;
  freebetLockedAmount: number;
  freebetName: string;
  freebetUnwithdrawableAmountDiff: number;
  lockedBonusCount: number;
  lockedFreebetCount: number;
  potentialRedemptionAmount: number;

  constructor(data) {
    this.freebetUnwithdrawableAmountDiff = data.freebetUnwithdrawableAmountDiff || 0;
    this.freebetName = data.freebetName || '';
    this.freebetInitUnwithdrawableAmount = data.freebetInitUnwithdrawableAmount || 0;
    this.freebetLockedAmount = data.freebetLockedAmount || 0;
    this.bonusRequiredAmount = data.bonusRequiredAmount || 0;
    this.bonusName = data.bonusName || '';
    this.activeBonusId = data.activeBonusId || null;
    this.balance = data.balance || 0;
    this.bonus = data.bonus || 0;
    this.currencyIso = data.currencyIso || '';
    this.freebet = data.freebet || 0;
    this.availableFreebet = data.availableFreebet || 0;
    this.freebetDiff = data.freebetDiff || 0;
    this.freebetUnwithdrawableAmount = data.freebetUnwithdrawableAmount || 0;
    this.level = data.level || null;
    this.nextLevel = data.level || null;
    this.nextLevelRequiredPoints = data.nextLevelRequiredPoints || 0;
    this.playerId = data.id || 0;
    this.points = data.points || 0;
    this.internalReservedFunds = data.internalReservedFunds || 0;
    this.systemReservedFunds = data.systemReservedFunds || 0;
    this.internalProductsBalance = data.internalProductsBalance || 0;
    this.username = data.username || data.email || '';
    this.reservedDepositTurnover = data.reservedDepositTurnover || 0;
    this.availableForWithdrawal = data.availableForWithdrawal || 0;
    this.spentAmount = data.spentAmount || 0;
    this.spentDiffAmount = data.spentDiffAmount || 0;
    this.nxcsAvailableForWithdrawal = data.nxcsAvailableForWithdrawal || 0;
    this.unwithdrawableAmount = data.unwithdrawableAmount;
    this.lockedBonusCount = data.lockedBonusCount || 0;
    this.lockedFreebetCount = data.lockedFreebetCount || 0;
    this.potentialRedemptionAmount = data.potentialRedemptionAmount || 0;
  }
}
