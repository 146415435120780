import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { AppStateFacadeService } from '@state/app-state.facade';
import { map } from 'rxjs/operators';
import { PlayerService } from '@services/player.service';

const nextStepCalc = {
  STEP_TWO: 'email-verification',
  STEP_THREE: 'phone-verification',
  STEP_FOUR: 'identity-card-check',
  STEP_FIVE: 'personal-data',
  STEP_SIX: 'identity-check',
};

function checkIsPlayerAllowed(
  registrationSteps: { registration_step: string; is_completed: boolean | null }[],
  registrationProcessCompleted: boolean,
  registrationCompleted: boolean,
  url: string,
  router: Router,
  defaultAppUrl: string
): boolean {
  if (!registrationCompleted) {
    const path = url.split('/').pop();

    const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value);

    const foundKey = getKeyByValue(nextStepCalc, path);

    const comingFromSkippedStep = registrationSteps.find(
      step => step.registration_step === foundKey && step.is_completed === false
    );

    if (!registrationProcessCompleted) {
      const foundStep = registrationSteps.find(step => step.is_completed === null);

      const querryParams = url.split('?')[1];

      const nextStepUrl =
        nextStepCalc[foundStep.registration_step] + (querryParams ? `?${querryParams}` : '');

      if (!comingFromSkippedStep) {
        if (path === nextStepUrl) {
          return true;
        } else {
          router.navigate([
            nextStepUrl
              ? querryParams
                ? `/registration/${nextStepUrl}?${querryParams}`
                : `/registration/${nextStepUrl}`
              : defaultAppUrl,
          ]);
          return false;
        }
      } else {
        return true;
      }
    } else {
      const stepTwoIsCompleted = registrationSteps.find(
        step => step.registration_step === 'STEP_TWO'
      ).is_completed;
      // THIS ENABLES APLICATION VISIT BY EMAIL VERIFICATION LINK
      if (url.includes('email-verification') && !stepTwoIsCompleted) {
        return true;
      }
      router.navigate(['/registration/unfinished']);
    }
  } else {
    router.navigate([defaultAppUrl]);
  }
}

export const croRegistrationGuardCanActivateV2: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);
  const playerService: PlayerService = inject(PlayerService);
  const defaultAppUrl: string = inject(AppStateFacadeService).getDefaultAppUrl();

  const url: string = state.url;
  const authorizedStepUrls = [
    'email-verification',
    'phone-verification',
    'identity-card-check',
    'personal-data',
    'identity-check',
  ];
  const isAuthorizedRoute: boolean = authorizedStepUrls.some(urlPath => url.includes(urlPath));
  const isLoggedIn: boolean = inject(LocalStorageService).isLoggedIn();

  if (!isLoggedIn && !isAuthorizedRoute) {
    return true;
  }

  if (!isLoggedIn && isAuthorizedRoute) {
    router.navigate(['/registration/start-registration']);
    return false;
  }

  if (isLoggedIn && !isAuthorizedRoute) {
    const currentRoute = url.split('/').pop();
    if (currentRoute === 'unfinished' || currentRoute === 'welcome') {
      return true;
    }
  }

  return playerService
    .getPlayer()
    .pipe(
      map(player =>
        checkIsPlayerAllowed(
          player.registrationSteps,
          player.registrationProcessCompleted,
          player.registrationCompleted,
          url,
          router,
          defaultAppUrl
        )
      )
    );
};
