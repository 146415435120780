import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Promotion } from 'src/app/shared/models/promotion.model';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  public luckyWheelClaimedPrize = new Subject<any>();
  public luckyWheelClaimedPrize$ = this.luckyWheelClaimedPrize.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Get promotions by location
   * @param location - Location of promotions
   */
  public getPromotionsByLocation(location: string): Observable<any> {
    return this.http.get<any[]>(environment.apiUrl + `adpromotions/location/${location}`).pipe(
      map(promotions => {
        promotions.forEach(promotion => {
          promotion.image = promotion.image ? `${environment.imagesBaseUrl}${promotion.image}` : '';
          promotion.imageMin = promotion.imageMin ? `${environment.imagesBaseUrl}${promotion.imageMin}` : '';
          promotion.imageMinMob = promotion.imageMinMob
            ? `${environment.imagesBaseUrl}${promotion.imageMinMob}`
            : '';
          promotion.imageMob = promotion.imageMob ? `${environment.imagesBaseUrl}${promotion.imageMob}` : '';
          promotion.wideImage = promotion.wideImage
            ? `${environment.imagesBaseUrl}${promotion.wideImage}`
            : '';
          promotion.wideImageMob = promotion.wideImageMob
            ? `${environment.imagesBaseUrl}${promotion.wideImageMob}`
            : '';
          promotion.shortDescription = this.removeEmptyElements(
            (promotion.shortDescription =
              promotion.shortDescription && promotion.shortDescription.split('\n'))
          );
          if (promotion.fullDescription) {
            promotion.fullDescription = promotion.fullDescription && promotion.fullDescription.split('\n');
          }
        });
        return promotions;
      })
    );
  }

  public getPromotionActiveCategories() {
    return this.http.get<any[]>(environment.apiUrl + `adpromotions/product-types`);
  }

  /**
   * Get promotions by id
   * @param promotionId - Promotion id
   */
  public getPromotionById(promotionId): Observable<any> {
    return this.http.get<Promotion>(environment.apiUrl + `adpromotions/${promotionId}`);
  }

  /**
   * Remove empty elements
   * @param list - List of promotion short descriptions
   */
  private removeEmptyElements(list): any {
    return (list = list.filter(function (el) {
      return el !== '';
    }));
  }

  /**
   * Get lucky wheels configuations
   */
  public getLuckyWheels(): Observable<any> {
    return this.http.get<any[]>(environment.apiUrl + `lucky-wheel`);
  }

  /**
   * This will send a request to the server that wheel needs to be spin.
   * After that socket will be used to get the result of the spin.
   * @param id - Wheel id
   */
  public spinTheWheel(id: number): Observable<any> {
    return this.http.post<any[]>(environment.apiUrl + `lucky-wheel/spin`, { wheelId: id });
  }

  /**
   * Notify backend that wheel is stoped
   * @param queueId - will be used to notify the backend that wheel is stoped
   */
  public sendMessage(queueId: number): Observable<any> {
    return this.http.put<any[]>(environment.apiUrl + `lucky-wheel/send-message/${queueId}`, {});
  }

  /**
   * Notify backend that wheel is stoped and prize need to be claimed
   * @param queueId - will be used to claim the prize, and it will sent thru the socket
   */
  public claimLuckyWheelPrize(queueId: number): Observable<any> {
    return this.http.put<any[]>(environment.apiUrl + `lucky-wheel/claim/${queueId}`, {});
  }
}
