<div class="box">
  <!-- HEADER -->
  <header class="pb-2">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{ 'DEPOSIT_STATUS' | translate }}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close pointer">
          <div
            *ngIf="clientName === clientNames.ADMIRAL_PINNBET; else otherClientsCloseBtn"
            (click)="closeModal()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
          <ng-template #otherClientsCloseBtn>
            <img [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'" (click)="closeModal()" />
          </ng-template>
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3 h3 text-center text-uppercase">
      <div *ngIf="depositResult.isSuccess; else depositFailed" class="status-success">
        {{ 'DEPOSIT_STATUS_SUCCESSFUL' | translate }}
      </div>
      <ng-template #depositFailed>
        <div class="status-error">{{ 'DEPOSIT_STATUS_UNSUCCESSFUL' | translate }}</div>
      </ng-template>
    </div>
  </header>
  <!-- BODY -->
  <div class="px-5 pt-3">
    <!-- TRANSACTION RESULT -->
    <div *ngIf="depositResult.isSuccess; else depositFailureDescription">
      <!-- TRANSACTION AMOUNT AND SUCCESS MESSAGE -->
      <div class="row mb-2">
        <div class="col-4">
          <span class="text-muted">{{ depositResult.amount }}</span>
        </div>
        <div class="col-8 text-right">
          <span class="text-light">{{ 'SUCCESS_DEPOSITET_TO_ACCOUNT' | translate }}</span>
        </div>
      </div>
      <hr />

      <!-- DEPOSIT TYPE -->
      <div *ngIf="depositResult.ipsStatus" class="row mb-2">
        <div class="col-6">
          <span class="text-muted">{{ 'PAYMENT_METHOD' | translate }}</span>
        </div>
        <div class="col-6 text-right">
          <span class="text-light">{{ 'IPS_SCAN' | translate }}</span>
        </div>
      </div>
      <!-- RP REFERENCE -->
      <div *ngIf="depositResult.rpReference" class="row mb-2">
        <div class="col-6">
          <span class="text-muted">{{ 'RP_REFERENCE' | translate }}</span>
        </div>
        <div class="col-6 text-right">
          <span class="text-light">{{ depositResult.rpReference }}</span>
        </div>
      </div>
      <!-- ORDER ID -->
      <div *ngIf="depositResult.txnId" class="row mb-2">
        <div class="col-6">
          <span class="text-muted">{{ 'DEPOSIT_STATUS_ORDER_ID' | translate }}</span>
        </div>
        <div class="col-6 text-right">
          <span class="text-light">{{ depositResult.txnId }}</span>
        </div>
      </div>
      <!-- TRANSACTION DATE -->
      <div *ngIf="depositResult.createdOn" class="row mb-2">
        <div class="col-6">
          <span class="text-muted">{{ 'DEPOSIT_STATUS_TRANSACTION_DATE' | translate }}</span>
        </div>
        <div class="col-6 text-right">
          <span class="text-light">{{ depositResult.createdOn | date : 'd MMM y, HH:mm:ss' }}</span>
        </div>
      </div>
    </div>

    <!-- TRANSACTION ERROR MESSAGE -->
    <ng-template #depositFailureDescription>
      <div class="text-light mb-2 center">{{ 'IPS_DEPOSIT_FAILURE' | translate }}</div>
    </ng-template>
  </div>
  <hr />
  <div class="py-1 text-center">
    <div (click)="closeModal()" class="btn btn-outline-light px-6">OK</div>
  </div>
</div>
