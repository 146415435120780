<nav id="sidebar-right" class="sidebar sidebar-right" [ngClass]="{ expanded: rightBarExpanded }">
  <div>
    <div
      (swiperight)="closeRightMenuOnSwipe()"
      #contentOfMenu
      id="right-sidebar-content"
      class="sidebar-content sidebar-content--is-right"
    >
      <div class="c_right-menu__header">
        <div class="c_right-menu__logo_and_close">
          <div class="logo">
            <a [routerLink]="[environment.appDefaultRoute]">
              <img
                [ngClass]="
                  CLIENT_NAMES.ADMIRAL_NIGERIA === environment.clientName ||
                  CLIENT_NAMES.ADMIRAL_PINNBET === environment.clientName
                    ? 'biggerImg'
                    : 'c_right_menu-logo'
                "
                [src]="logoImg"
                alt="logo"
                width="105"
                height="27"
              />
            </a>
          </div>
          <div *ngIf="chatEnabled" class="pointer clickableArea chatIconColor" (click)="toggleChat()">
            <span inlineSVG="assets/icons/green_chat_icon.svg"></span>
            <sub
              *ngIf="unreadChat > 0"
              class="align-top top text-white badge badge-danger p-1 d-inline-block ml-n1"
              >{{ unreadChat }}</sub
            >
          </div>
          <div
            class="u_pointer"
            (click)="handleRightSideBar()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
        <div class="c_right-menu__deposit-btn-container mt-4 w-100">
          <i
            class="btn_icon btn_icon--is-deposit"
            #depositIcon
            [inlineSVG]="'assets/icons/deposit_btn_icon.svg'"
          >
          </i>
          <div
            *ngIf="this.currentTheme"
            class="w-100"
            appAdmiralButton
            type="{{ BUTTON_TYPES.DEPOSIT }}"
            [backgroundColor]="currentTheme?.depositBtnColor.hex"
            [textColor]="currentTheme?.depositBtnTextColor.hex"
            [borderStyle]="'solid 1px ' + currentTheme?.depositBtnBorderColor.hex"
            [icon]="depositIcon"
            (click)="
              navigateToLinkInMenu({
                type: 'INTERNAL_LINK_SUBMENU',
                url: '/profile/deposit'
              })
            "
          ></div>
        </div>
        <!-- LUCKY WHEEL -->
        <div class="lucky-wheel-container" *ngIf="showWheel" (click)="openLuckyWheel()">
          <img
            class="wheel"
            [ngClass]="{ 'spin-anim': spinWheel }"
            src="assets/images/wheel-of-fortune/wheel.png"
            alt=""
          />
          <div class="text-grey font-weight-bold">{{ 'LUCKY_WHEEL' | translate }}</div>
        </div>
        <hr class="w-100" />
      </div>
      <div class="c_right-menu-profile-container">
        <div class="c_right-menu-info-list">
          <div class="c_right-menu-profile-font c_right-menu-profile-font--is-firstAndLastName pt-1 pb-1">
            {{ player?.firstName }} {{ player?.lastName }}
          </div>
          <div class="c_right-menu-profile-font c_right-menu-profile-font--is-nickName pt-1 pb-1">
            {{ player?.username }}
          </div>
          <div class="c_right-menu-profile-font c_right-menu-profile-font--is-gray-font pt-1 pb-1">
            {{ 'PLAYER_ID' | translate }} :
            <span class="c_right-menu-profile-font c_right-menu-profile playerId pt-1 pb-1">{{
              player?.id
            }}</span>
          </div>
        </div>
        <div class="c_right-menu-container">
          <label class="hoverable" for="fileInputRightMenuImage">
            <img
              class="c_right-menu-user-avatar"
              [ngClass]="{ 'c_right-menu-user-avatar--is-unauthorized': unauthorizedUser }"
              async
              [src]="
                player?.profileImageUrl
                  ? environment.imagesBaseUrl + player?.profileImageUrl
                  : 'assets/profile.png'
              "
              alt="Right menu user avatar"
            />
            <div *ngIf="!isMobile" class="hover-text text-black">{{ 'CHANGE_AVATAR' | translate }}</div>
            <div
              *ngIf="!isMobile"
              class="background"
              [ngClass]="{ 'default-avatar': !player?.profileImageUrl }"
            ></div>
            <input id="fileInputRightMenuImage" type="file" (change)="changeAvatar($event)" />
          </label>
        </div>
      </div>
      <!--      Blinking verification button    -->
      <div>
        <div *ngIf="unauthorizedUser" class="min-height pt-2">
          <a
            [routerLink]="['/profile/blinking-verification']"
            class="btn-rounded btn-block c_orange-btn"
            (click)="handleRightSideBar()"
          >
            <span class="text-white float-left"> {{ 'BLINKING_LINK_BTN' | translate }} </span>
            <span class="float-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 0 24 24"
                width="20px"
                fill="#ffffff"
              >
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g>
                    <polygon points="15.5,5 11,5 16,12 11,19 15.5,19 20.5,12" />
                    <polygon points="8.5,5 4,5 9,12 4,19 8.5,19 13.5,12" />
                  </g>
                </g>
              </svg>
            </span>
            <span class="clearfix"></span>
          </a>
        </div>
      </div>
      <div class="c_right-menu-balance-information-first-row mt-1">
        <div class="c_right-menu-profile-font c_right-menu-profile-font--is-gray-font pt-1 pb-1">
          {{ 'AVAILABLE_FUNDS_RIGHT_MENU' | translate }}
        </div>
        <div class="c_right-menu-profile-font c_right-menu-profile-font--is-secondary-color pt-1 pb-1">
          {{ balanceInfo.balance | number : '1.2-2' | currency : player?.currency }}
        </div>
      </div>
      <div *ngIf="player?.externalId" class="c_right-menu-balance-information-row mt-1">
        <!-- ADMIRAL 360 -->
        <div>
          <span
            class="c_right-menu-profile-font c_right-menu-profile-font--is-gray-font pt-1 pb-1"
            [routerLink]="['/profile/deposit/admiral-360']"
            (click)="handleRightSideBar()"
            >{{ 'ADMIRAL_360' | translate }}</span
          >
        </div>
        <div>
          <span class="c_right-menu-profile-font c_right-menu-profile-font--is-secondary-color pt-1 pb-1">{{
            admiral360BalanceInfo?.balance | number : '1.2-2' | currency : player?.currency
          }}</span>
        </div>
      </div>
      <div class="c_right-menu-balance-information-row mt-2">
        <div class="flex-grow-1 flex-shrink-1 flex-basis-1 mw-50">
          <span
            class="userDataLabel link"
            [routerLink]="['/profile/withdrawal/withdrawal-requests']"
            (click)="handleRightSideBar()"
            >{{ 'RESERVED' | translate }}
          </span>
          <div class="h5 m-0 text-primary-color">
            {{ balanceInfo.systemReservedFunds | number : '1.2-2' | currency : player?.currency }}
          </div>
        </div>
        <div class="flex-grow-1 flex-shrink-1 flex-basis-1 mw-50 text-right">
          <span
            class="userDataLabel link"
            [routerLink]="['/profile/withdrawal']"
            (click)="handleRightSideBar()"
          >
            {{ 'AVAILABLE_FOR_WITHDRAWAL' | translate }}
          </span>
          <div class="h5 m-0 text-primary-color">
            {{ balanceInfo.availableForWithdrawal | number : '1.2-2' | currency : player?.currency }}
          </div>
        </div>
      </div>
      <div class="c_right-menu-balance-information-row mt-2">
        <div>
          <small>{{ 'BONUS' | translate }}</small>
          <div class="h5 m-0 text-tertiary">
            {{ balanceInfo.bonus | number : '1.2-2' | currency : player?.currency }}
          </div>
        </div>
        <div class="text-right">
          <small>{{ 'FREEBET' | translate }}</small>
          <div class="h5 m-0 text-tertiary">
            {{ balanceInfo.availableFreebet | number : '1.2-2' | currency : player?.currency }}
          </div>
        </div>
      </div>
      <hr class="w-100" />

      <!--      Progress bar panel  -->
      <div>
        <!-- AVAILABLE FOR WITHDRAWAL -->
        <div
          class="c_right-menu-profile-font c_right-menu-profile-font--is-progress-title text-primary-color"
        >
          {{ 'AVAILABLE_FOR_WITHDRAWAL' | translate }}
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <ng-template #tipContent>{{ 'WITHDRAWAL_NOTE' | translate }}</ng-template>
            <app-progress-bar
              [totalAmount]="balanceInfo.balance"
              [shareAmount]="balanceInfo.availableForWithdrawal"
              [noAmountMessage]="'NO_FUNDS'"
              [navigationUrl]="'/profile/deposit'"
              #tooltipInstance1="ngbTooltip"
              placement="top"
              [ngbTooltip]="balanceInfo.balance && tipContent"
              [autoClose]="'inside'"
              [currency]="player?.currency"
              (click)="helpersService.closeTooltipOnClick(tooltipInstance1, 5000)"
              (navigation)="handleRightSideBar()"
            >
            </app-progress-bar>
          </div>
        </div>
        <div>
          <div
            class="c_right-menu-profile-font c_right-menu-profile-font--is-progress-title text-primary-color"
          >
            {{ 'BONUS' | translate }}
          </div>
          <div class="row mb-2">
            <div [class]="lockedBonuses.length ? 'col-10 progressBarContainer' : 'col-12'">
              <app-progress-bar
                [lockedItemsLength]="lockedBonuses.length"
                [totalAmount]="balanceInfo.bonusRequiredAmount"
                [navigationUrl]="'/promotions'"
                [shareAmount]="balanceInfo.spentAmount"
                [lockedAmount]="balanceInfo.potentialRedemptionAmount"
                [labelShouldUseTranslatePipe]="false"
                [label]="balanceInfo.bonusName"
                [noAmountMessage]="'BONUS_ACTIVITY_NO_ACTIVE_BONUSES'"
                [currency]="player?.currency"
                (navigation)="handleRightSideBar()"
              >
              </app-progress-bar>
            </div>
            <div
              (click)="dropdownToggle(BONUS_TYPES.BONUS)"
              *ngIf="lockedBonuses.length"
              class="col-2 dropdownArrowContainer"
            >
              <div [ngClass]="{ active: lockedBonusesForDisplay.length }" class="dropdownArrow"></div>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="lockedBonusesForDisplay.length" @heightAnimation class="lockedBonusesContainer row">
            <div *ngFor="let lockedBonus of lockedBonusesForDisplay" class="col-12 lockedBonusItem">
              <div class="bonusName">{{ lockedBonus.name }}</div>
              <div class="col-12 bonusTotalAmountLockedContainer">
                (0.00 / {{ lockedBonus.totalAmount | number : '1.2-2' | currency : player?.currency }})
              </div>
              <div class="d-flex">
                <div class="progressBar col-10">
                  <span>{{ lockedBonus.lockedAmount | number : '1.2-2' | currency : player?.currency }}</span>
                </div>
                <div class="col-2 lockContainer">
                  <img src="assets/icons/lock.svg" alt="Bonuses lock container" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- FREEBET AVAILABLE FUNDS -->
          <div
            class="c_right-menu-profile-font c_right-menu-profile-font--is-progress-title text-primary-color"
          >
            {{ 'FREEBET' | translate }}
          </div>
          <div class="row mb-2">
            <div [class]="lockedFreebets.length ? 'col-10 progressBarContainer' : 'col-12'">
              <app-progress-bar
                [lockedItemsLength]="lockedFreebets.length"
                [totalAmount]="balanceInfo.freebetInitUnwithdrawableAmount"
                [shareAmount]="balanceInfo.freebetUnwithdrawableAmountDiff"
                [lockedAmount]="balanceInfo.freebetLockedAmount"
                [label]="balanceInfo.freebetName"
                [noAmountMessage]="'NO_FREEBET_FUNDS'"
                [navigationUrl]="'/promotions'"
                [labelShouldUseTranslatePipe]="false"
                #tooltipInstance="ngbTooltip"
                [ngbTooltip]="balanceInfo.freebetInitUnwithdrawableAmount && tipContentFreeBet"
                placement="top"
                [autoClose]="'inside'"
                [currency]="player?.currency"
                (click)="helpersService.closeTooltipOnClick(tooltipInstance, 10000)"
                (navigation)="handleRightSideBar()"
              >
              </app-progress-bar>
              <ng-template #tipContentFreeBet>{{
                'FREEBET_UNAVAILABLE'
                  | translate
                    : {
                        unavailable: balanceInfo.freebetDiff | number : '1.2-2' | currency : player?.currency,
                        unwithdrawable:
                          balanceInfo.freebetUnwithdrawableAmount
                          | number : '1.2-2'
                          | currency : player?.currency
                      }
              }}</ng-template>
            </div>
            <div
              (click)="dropdownToggle(BONUS_TYPES.FREEBET)"
              *ngIf="balanceInfo.freebetInitUnwithdrawableAmount && lockedFreebets.length"
              class="col-2 dropdownArrowContainer"
            >
              <div [ngClass]="{ active: lockedFreebetsForDisplay.length }" class="dropdownArrow"></div>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="lockedFreebetsForDisplay.length" @heightAnimation class="lockedBonusesContainer row">
            <div *ngFor="let lockedFreebet of lockedFreebetsForDisplay" class="col-12 lockedBonusItem">
              <div class="bonusName">{{ lockedFreebet.name }}</div>
              <div class="col-12 bonusTotalAmountLockedContainer">
                (0.00 / {{ lockedFreebet.totalAmount | number : '1.2-2' | currency : player?.currency }})
              </div>
              <div class="d-flex">
                <div class="progressBar col-10">
                  <span>{{
                    lockedFreebet.totalAmount | number : '1.2-2' | currency : player?.currency
                  }}</span>
                </div>
                <div class="col-2 lockContainer">
                  <img src="assets/icons/lock.svg" alt="Freebets lock container" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c_link-list">
        <app-right-menu-links-cms
          [navigateToLinkInMenu]="navigateToLinkInMenu.bind(this)"
        ></app-right-menu-links-cms>
      </div>
    </div>
  </div>
</nav>
