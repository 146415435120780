import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent {
  @Input() payment;
  @Input() lastUsedPayment;
  @Input() paymentDisabled: boolean;
  @Output() selectedPayment = new EventEmitter<any>();
  @Output() paymentNotAllowedClick = new EventEmitter<boolean>();
  public environment = environment;
  public clientNames = CLIENT_NAMES;

  constructor(private router: Router) {}

  public setLastSelectedPayment(payment) {
    this.selectedPayment.emit(payment);
  }

  public clickOnCard(link, saveToLastSelected?) {
    if (!this.paymentDisabled) {
      this.router.navigateByUrl(`${this.router.url}/${link}`);
      if (saveToLastSelected) {
        this.setLastSelectedPayment(this.payment);
      }
    } else {
      this.paymentNotAllowedClick.emit(true);
    }
  }
}
