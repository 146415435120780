<div class="box">
  <header class="py-2 py-lg-3">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{ depositName }}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close">
          <img
            *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
            [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
            (click)="closeDialog()"
          />
          <div
            *ngIf="clientName === clientNames.ADMIRAL_PINNBET"
            class="u_pointer"
            (click)="closeDialog()"
            [inlineSVG]="'assets/icons/close_cross.svg'"
          ></div>
        </div>
      </div>
    </div>
  </header>
  <p *ngIf="!message" class="descParagraph text-center">{{ 'DEPOSIT_REDIRECT_TO_PAYMENT' | translate}}</p>
  <p *ngIf="message" class="descParagraph text-center">{{ message | translate}}</p>
  <div class="row justify-content-center">
    <a id="redirectButton" class="btn btn-primary" (click)="closeDialog()">
      {{ 'DEPOSIT_CARD_DEPOSIT' | translate }}
    </a>
  </div>
</div>
