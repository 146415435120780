import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Player } from '@models/player.model';
import { DepositService } from '@services/deposit.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@services/toaster.service';
import { environment } from '../../../../../environments/environment';
import { HelpersService } from '@services/helpers.service';
import { BehaviorSubject } from 'rxjs';
import { DEPOSIT_CODES } from '@enums/deposit-codes.enum';
import { Router } from '@angular/router';
import { Deposit } from '@models/deposit.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quick-bar-allsecure-deposit',
  templateUrl: './quick-bar-allsecure-deposit.component.html',
  styleUrls: ['./quick-bar-allsecure-deposit.component.scss'],
})
export class QuickBarAllsecureDepositComponent implements OnInit, OnDestroy {
  @ViewChild('choseSavedCardsModal', { static: true }) choseSavedCardsModal: any;
  @ViewChild('modalTemplateDeleteCard', { static: true }) modalTemplateDeleteCard: any;
  @Input() expandCollapseQuickBar!: (stateOveride?) => void;
  @Input() closeQuickBar!: () => void;
  @Input() depositInformation: Deposit;
  @Input() playerData!: Player;
  @Input() clientName!: string;
  @Input() depositAmount!: number;
  public playerCards: any[];
  public selectedCard: any;
  public isCardRegister: number;
  public modalRef: NgbModalRef;
  public cvvLabelMessage: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private payment;
  public validCvv: boolean;

  constructor(
    private depositService: DepositService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toaster: ToasterService,
    private helpersService: HelpersService,
    private router: Router
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    // get Paymentjs script
    if (window['PaymentJs']) {
      const PaymentJs = window['PaymentJs'];
      this.payment = new PaymentJs();
    }
    this.checkForRegisterCards();
    this.expandCollapseQuickBar('expand');
  }

  // check if player has register cards
  private checkForRegisterCards(): void {
    this.playerCards = [];
    this.depositService.getAllsecurePlayerCreditCards().subscribe(cards => {
      if (cards) {
        this.playerCards.push(...cards);
        this.isCardRegister = this.playerCards.length;
        this.selectedCard = this.playerCards[0];
        // initialize payment
        this.initializePaymentForRegisterCard();
      }
    });
  }

  public switchSavedCardOrNewCard() {
    this.router.navigate(['/profile/deposit/allsecure'], {
      queryParams: { creditCardStatus: 'registerCard', amount: this.depositAmount },
    });
    this.closeQuickBar();
  }

  public deleteCard(event) {
    event.stopPropagation();

    this.modalRef = this.modalService.open(this.modalTemplateDeleteCard, {
      centered: true,
      scrollable: true,
      backdrop: true,
    });
  }

  public deleteCardConfirm(): void {
    this.depositService.deleteCard(this.selectedCard).subscribe(response => {
      this.toaster.showSuccess(response.message);
      this.checkForRegisterCards();
    });
  }

  public onChange(index: number): void {
    this.selectedCard = this.playerCards[index];
    this.modalRef.close();
  }

  public openModalToChoseCard() {
    this.modalRef = this.modalService.open(this.choseSavedCardsModal, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: true,
    });
  }

  // intercept form submit to check cvv for register card
  public interceptSubmitForRegisterdCard(): void {
    const that = this;
    this.payment.refreshCvv(
      function () {
        that.cvvLabelMessage.next(null);
        that.submitDepositData();
      },
      function (error) {
        if (error[0].key === 'errors.blank') {
          that.cvvLabelMessage.next('REQUIRED_FIELD');
        }
        if (error[0].key === 'errors.invalid') {
          that.cvvLabelMessage.next('INVALID_FORMAT');
        }
      }
    );
  }

  public submitDepositData() {
    const dataToSend = {
      amount: this.depositAmount,
      referenceUuid: this.selectedCard.referenceUuid,
      threeDSecureData: {
        browserScreenHeight: window.innerHeight.toString(),
        browserScreenWidth: window.innerWidth.toString(),
        browserTimezone: new Date().getTimezoneOffset().toString(),
        browserColorDepth: window.screen.colorDepth.toString(),
        browserAcceptHeader: '',
        browserLanguage: navigator.language,
        browserUserAgent: navigator.userAgent,
      },
    };

    this.depositService.allsecureDeposit(this.depositInformation.url, dataToSend).subscribe({
      next: data => {
        if (data.code) {
          data.code === 0
            ? this.toaster.showSuccess(this.translate.instant(data.message))
            : this.toaster.showError(this.translate.instant(data.message));
          // on succes check registerd cards for different form if player want to deposit again
          this.checkForRegisterCards();
        } else {
          window.location.href = data.url;
        }
      },
      error: error => {
        if (error.message) {
          this.toaster.showError(error.message);
        }
      },
    });
  }

  private initializePaymentForRegisterCard(): void {
    const that = this;
    if (this.payment) {
      this.payment.initCvvRefresh(
        environment.allsecurePublicIntegrationKey,
        that.selectedCard.referenceUuid,
        'cvv_div-bar',
        function (payment) {
          setTimeout(() => {
            payment.setCvvStyle({
              display: 'block',
              width: '100%',
              height: '34px',
              // padding: '0 1.25rem',
              'font-size': '0.75rem',
              'font-weight': '400',
              'line-height': '1.5',
              color: 'white',
              'background-color': '#282b2d',
              'background-clip': 'padding-box',
              border: '1px solid #34373b',
              'border-radius': '0.25rem',
              margin: '0',
              'font-family': '"Montserrat", sans-serif',
              outline: 'none',
              'letter-spacing': 'normal',
            });
            payment.cvvOn('input', function (event) {
              that.validCvv = event.validCvv;
            });
          }, 200);
        },
        function (errors) {
          console.warn('Allsecure errors:', errors);
        }
      );
    }
  }
}
