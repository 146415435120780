import { inject } from '@angular/core';
import {
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { environment } from '../../../environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { Location } from '@angular/common';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';

export const unAuthGuardCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);
  const token: string = inject(LocalStorageService).getToken();

  if (!token) {
    return true;
  } else {
    router.navigate([environment.appDefaultRoute]);
    return false;
  }
};

export const unAuthGuardCanMatch: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const token: string = inject(LocalStorageService).getToken();
  const currentLocation: string = inject(Location).path();
  const router: Router = inject(Router);
  const toasterService: ToasterService = inject(ToasterService);
  const translate: TranslateService = inject(TranslateService);
  const urlToken: string = router.getCurrentNavigation().extractedUrl.queryParamMap.get('token');

  if (!token) {
    return true;
  } else {
    /* Uganda forget password message hack.
      When user click on forgot password on login page on desktop, and he is already logged in on mobile,
      he will get link for changing password to mobile number and after click on link this guard will redirect
      him from forgot password module to default route, because this guard won't let him access module if he is
      logged in, so in that case we need to get him noticed what is the problem.
    */
    if (
      environment.clientName === CLIENT_NAMES.ADMIRAL_UGANDA &&
      currentLocation.includes('/forgot-password/password-change?token=') &&
      urlToken
    ) {
      // without timeout, toaster message doesn't appear at all
      setTimeout(() => {
        toasterService.showError(translate.instant('UGANDA_FORGOT_PASSWORD_USER_IS_LOGGED_IN'));
      }, 1000);
    }
    router.navigate([environment.appDefaultRoute]);
    return false;
  }
};
