<ng-container *ngIf="typeOfRadioBtn === RADIO_BTN_TYPES.TOUCH_REGION_BTN">
  <div class="d-flex flex-column align-items-center">
    <div class="pb-1">
      <span (click)="radioClicked()" class="user-select-none label-text" *ngTemplateOutlet="label"></span>
    </div>
    <div class="d-flex flex-column align-items-center">
      <button
        type="button"
        class="custom-radio--is-touch_area d-flex align-items-center justify-content-center"
        [ngClass]="{ active: controlValue === value }"
        [disabled]="radioFormControl.disabled"
        (click)="radioClicked()"
      >
        <span *ngIf="controlValue === value" class="inner-mark is-touch_area"></span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeOfRadioBtn === RADIO_BTN_TYPES.TEXT_INSIDE_BTN">
  <button
    type="button"
    class="custom-radio--is-touch_area d-flex align-items-center justify-content-center w-100"
    [ngClass]="{ active: controlValue === value }"
    [disabled]="radioFormControl.disabled"
    (click)="radioClicked()"
  >
    <span *ngIf="controlValue === value" class="inner-mark is-touch_area w-100 h-100"></span>
    <div class="label-inside">
      <ng-template *ngTemplateOutlet="label"></ng-template>
    </div>
  </button>
</ng-container>

<ng-container *ngIf="typeOfRadioBtn === RADIO_BTN_TYPES.REGULAR_BTN">
  <div class="d-flex align-items-center">
    <button
      type="button"
      class="custom-radio mr-2 d-flex align-items-center justify-content-center"
      [ngClass]="{ active: controlValue === value }"
      [disabled]="radioFormControl.disabled"
      (click)="radioClicked()"
    >
      <span *ngIf="controlValue === value" class="inner-mark"></span>
    </button>
    <span (click)="radioClicked()" class="user-select-none" *ngTemplateOutlet="label"> </span>
  </div>
</ng-container>

<ng-container *ngIf="typeOfRadioBtn === RADIO_BTN_TYPES.REGULAR_BTN_WITH_CHECKMARK">
  <div class="d-flex align-items-center">
    <button
      type="button"
      class="custom-radio-checkmark mr-2 d-flex align-items-center justify-content-center"
      [ngClass]="{ active: controlValue === value }"
      [disabled]="radioFormControl.disabled"
      (click)="radioClicked()"
    >
      <span *ngIf="controlValue === value" class="inner-mark"
        ><i class="check" inlineSVG="/assets/icons/radio-check.svg"></i
      ></span>
    </button>
    <span (click)="radioClicked()" class="user-select-none" *ngTemplateOutlet="label"> </span>
  </div>
</ng-container>

<ng-template #label>
  <ng-content></ng-content>
</ng-template>
