import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { PlayerService } from 'src/app/core/services/player.service';
import { Player } from 'src/app/shared/models/player.model';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { BalanceInfo } from 'src/app/shared/models/balance-info.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BonusConfirmationDialog } from '@modules/profile/modules/promotions-activity/bonus-confirmation-dialog/bonus-confirmation.dialog';
import { Bonus } from '@models/bonus.model';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation.dialog';
import { CONFIRMATION_TYPE } from '@enums/confirmation-type.enum';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToasterService } from '@services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CorvusDepositStatusDialog } from 'src/app/shared/dialogs/corvus-status-dialog/corvus-deposit-status.dialog';
import { AuthService } from '@services/auth.service';
import { TokenExpirationDialog } from 'src/app/shared/dialogs/token-expiration-dialog/token-expiration.dialog';
import { MessagesService } from '@services/messages.service';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NotificationService } from '@services/notification.service';
import { MetaContentService } from '@services/meta-content.service';
import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info.dialog';
import { AllsecureDepositStatusDialog } from 'src/app/shared/dialogs/allsecure-deposit-status-dialog/allsecure-deposit-status.dialog';
import { MessagesPopupDialog } from 'src/app/shared/dialogs/messages-popup/messages-popup.dialog';
import { MessageDataModel } from 'src/app/shared/models/message.model';
import { PopupAllowanceStateService } from '@services/popup-allowance-state.service';
import { Admiral360BalanceInfo } from '@models/admiral-360-balance-info.model';
import { AppStateFacadeService } from '@state/app-state.facade';
import { ScrollService } from '@services/scroll.service';
import { PaystackDepoistStatusDialog } from 'src/app/shared/dialogs/paystack-deposit-status.dialog.html/paystack-deposit-status.dialog';
import { MobileGamePlayDialog } from 'src/app/shared/dialogs/mobile-game-play/mobile-game-play.dialog';
import { filter, map, skip } from 'rxjs/operators';
import { LobbyState } from '@modules/lobby/providers/lobby.state';
import { SOCKET_ACTION_TYPES } from '@enums/socket_action_type.enum';
import { DateService } from '@services/date.service';
import { HelpersService } from '@services/helpers.service';
import { NavigationService } from '@services/navigation.service';
import { ACTIVATION_TYPE } from '@enums/activation-types.enum';
import { SalesForceChatService } from '@services/sales-force-chat.service';
import { PoliticalExposureFormComponent } from 'src/app/shared/components/political-exposure-form/political-exposure-form.component';
import { CustomDialog } from 'src/app/shared/dialogs/custom-dialog/custom.dialog';
import { environment } from '../../../environments/environment';
import { PinnbetMessagesPopupDialog } from '../../shared/dialogs/messages-popup-pinnbet/pinnbet-messages-popup.dialog';
import { IpsDepositService } from '@services/ips-deposit.service';
import { sha256 } from 'js-sha256';
import { IpsDepositStatusDialog } from '@modules/profile/modules/deposit/ips-deposit/ips-deposit-status-dialog/ips-deposit-status.dialog';
import { QuickBarService } from '../quick-bar/quick-bar.service';

const PREVENT_SCROLL_TOP_ON_URLS = [
  '/withdrawal/bank',
  '/withdrawal/withdrawal-requests',
  '/identity-verification/doc',
  '/identity-verification/phone',
  '/payment-transactions/withdrawal',
  '/game-transactions/casino-transaction',
  '/game-transactions/live-casino-transaction',
  '/game-transactions/slot-transaction',
  '/game-transactions/live-dealer-transaction',
  '/game-transactions/virtual-games-transaction',
  window.innerWidth <= 1200 ? '/help' : ' ',
  window.innerWidth <= 1200 ? '/information' : '',
  window.innerWidth <= 1200 ? '/leaderboard' : '',
];

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public player: Player;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public clientName = this.appStateFacadeService.getClientName();
  public CLIENT_NAMES = CLIENT_NAMES;
  public courentRoute: string;
  public activePage: string;
  public mobileBottomRoute: string;
  public showDownloadPopup: boolean;
  public mobileMenuVisible = new BehaviorSubject(false);

  private timeoutRef;
  public isLoggedIn = this.localStorageService.isLoggedIn();
  private tokenExpirationDialogOpened = false;
  private popupMessagesOnHold: any[];
  private removeCasinoFiltersSkipNumber = 1;
  public isPopupAllowed: boolean;

  private webSocketObservable: Observable<any>;
  private playerObservable: Observable<any>;
  private nxcsPlatformLoginObservable: Observable<any>;

  private nxcsPlatformLogin$: Subscription;
  private webSocket$: Subscription;
  private player$: Subscription;
  private popupApperance$: Subscription;
  private router$: Subscription;
  private queryParam$: Subscription;
  private activeDialog$: Subscription;
  public downloadAppClients: string[] = [
    CLIENT_NAMES.ADMIRAL_SERBIA,
    CLIENT_NAMES.ADMIRAL_MONTENEGRO,
    CLIENT_NAMES.ADMIRAL_MD_SHOP,
    CLIENT_NAMES.ADMIRAL_BOSNIA,
    CLIENT_NAMES.ADMIRAL_PINNBET,
  ];

  private defaultAppUrl = this.appStateFacadeService.getDefaultAppUrl();
  protected readonly environment = environment;

  constructor(
    private localStorageService: LocalStorageService,
    private playerService: PlayerService,
    private router: Router,
    private toaster: ToasterService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private authService: AuthService,
    private messagesService: MessagesService,
    private notificationService: NotificationService,
    private metaContentService: MetaContentService,
    private popupAllowanceStateService: PopupAllowanceStateService,
    private appStateFacadeService: AppStateFacadeService,
    private scrollService: ScrollService,
    private lobbyState: LobbyState,
    private dateService: DateService,
    private helpersService: HelpersService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private salesForceChatService: SalesForceChatService,
    private ipsDepositService: IpsDepositService,
    private quickBarService: QuickBarService
  ) {
    this.setRouterSubscription();
  }

  ngOnInit(): void {
    // setup sales force chat for serbia and pinnbet
    if (
      (this.clientName === CLIENT_NAMES.ADMIRAL_SERBIA || this.clientName === CLIENT_NAMES.ADMIRAL_PINNBET) &&
      !this.isLoggedIn
    ) {
      this.salesForceChatService.setSalesForceChat();
    }
    this.isMobileBottomMenuVisible(this.router.url);
    if (this.isLoggedIn) {
      this.player = this.appStateFacadeService.getPlayerData();
      this.removeCasinoFiltersSkipNumber = 2;
    }

    this.setupStore();
    this.setSubscriptions();
    this.listenForSocketState();
    /**
     * [AB-3839] Clean up commented code when definitely quick bar pass testing
     */
    // if (this.isMobile && this.downloadAppClients.includes(this.clientName)) {
    //   this.checkShowAppDownloadPopupVisibility();
    // }
    this.setupQuickBarElement();
  }

  isMobileBottomMenuVisible(url) {
    if (this.isMobile) {
      this.mobileMenuVisible.next(false);
      this.activePage = url.split('?')[0].split('/')[1];
      const gamePlayUrl = new RegExp('/gameplay/');
      this.mobileBottomRoute = url;
      const mobileBottomMenuPages = [
        'casino',
        'casino-live',
        'slot',
        'live-dealer',
        'virtual',
        'mini-games',
        'promotions',
        'tournament',
        'profile',
        'help',
        'terms-and-conditions',
        'worldcup',
        'eurocup',
      ];
      if (environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET) {
        mobileBottomMenuPages.push('login');
        mobileBottomMenuPages.push('registration');
      }
      if (mobileBottomMenuPages.indexOf(this.activePage) > -1 && !gamePlayUrl.test(url)) {
        this.mobileMenuVisible.next(true);
      }
    }
  }

  ngOnDestroy(): void {
    this.webSocket$.unsubscribe();
    this.player$.unsubscribe();
    this.popupApperance$.unsubscribe();
    this.router$.unsubscribe();
    this.queryParam$.unsubscribe();
    this.activeDialog$.unsubscribe();
    // IPS payment - remove subscriptions, timer and page reload listener.
    this.ipsDepositService.clearSubscriptions();
    if (this.nxcsPlatformLogin$) {
      this.nxcsPlatformLogin$.unsubscribe();
    }
  }

  // setup store
  private setupStore() {
    this.webSocketObservable = this.appStateFacadeService.getSocketActionObservable();
    this.playerObservable = this.appStateFacadeService.getPlayerDataObservable();
    if (this.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      this.nxcsPlatformLoginObservable = this.appStateFacadeService.getNxcsPlatformLogin();
    }
  }

  private setSubscriptions() {
    this.setPlayerSubscription();
    this.setQueryParamsSubscription();
    this.subscribeToPopupAllowanceState();
    this.subscribeToActiveDialogs();
    if (this.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      this.setNxcsPlatformLoginSubscription();
    }
  }

  // set player subscription
  private setPlayerSubscription(): void {
    // Skip one because on initial setup we dont want to delete sections
    // If player is logged in then we need to skip 2 because first time there is no player
    // And second time when player is set

    this.playerObservable.pipe(skip(this.removeCasinoFiltersSkipNumber)).subscribe(() => {
      this.lobbyState.clearSectionsInLobby();
    });

    this.player$ = this.playerObservable
      .pipe(
        filter(playerData => !!playerData),
        map((data, index) => ({ ...data, isFirstEmission: index === 0 }))
      )
      .subscribe(player => {
        // Only a player-login or page-reload event should trigger a full setup process offered in this block.
        // All other subsequent events (which include player data changes) should set player data and perform only necessary actions.
        this.player = new Player(player);
        this.checkTermsConditions();
        this.setupChat();
        if (!player.isFirstEmission) {
          // It is not a player-login nor page-reload event.
          return;
        }

        if (player.token) {
          this.notificationService.connect();
        }
        this.initTokenExpiration();
        this.getUnreadMessages();
        if (this.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
          this.checkRegistrationStep();
        }

        // For logged in players, load any stored IPS payment data and add a page reload listener.
        if (
          this.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
          this.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
        ) {
          this.ipsDepositService.onLogin();
        }
      });
  }

  // setup chat by client
  private setupChat(): void {
    switch (this.clientName) {
      case CLIENT_NAMES.ADMIRAL_MONTENEGRO:
      case CLIENT_NAMES.ADMIRAL_MD_SHOP:
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
      case CLIENT_NAMES.ADMIRAL_BOSNIA:
        this.setupHelpCrunchChat(this.player);
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
      case CLIENT_NAMES.ADMIRAL_SERBIA:
        this.salesForceChatService.setSalesForceChat(this.player);
        break;
      case CLIENT_NAMES.ADMIRAL_UGANDA:
        this.setupTawkChat(this.player);
        break;
    }
  }

  // setup HelpCrunch chat
  private setupHelpCrunchChat(player): void {
    if (player && (window as any).HelpCrunch) {
      const user = {
        email: player.email,
        name: `${player.firstName} ${player.lastName}`,
        user_id: player.id.toString(),
      };
      (window as any).HelpCrunch('userAuth', user);
    }
  }

  // setup Tawk chat
  private setupTawkChat(player): void {
    const that = this;
    if (player && (window as any).Tawk_API) {
      // if tawk chat is already loaded login player
      if ((window as any).Tawk_API.onLoaded) {
        if ((window as any).Tawk_API.isChatOngoing()) {
          (window as any).Tawk_API.endChat();
        }
        this.loginToTawkChat(player);
      }
      // on first initialization of tawk chat wait for it to be loaded
      (window as any).Tawk_API.onLoad = function () {
        if ((window as any).Tawk_API.isChatOngoing()) {
          (window as any).Tawk_API.endChat();
        }
        that.loginToTawkChat(player);
      };
    }
  }

  private loginToTawkChat(player): void {
    const secretKey = environment.production
      ? '7d5e63d20fe621cdedc542858519654f853b79d3'
      : 'aba0d4d4f0e881d7b6f863f40ef51e62fab39f07';
    (window as any).Tawk_API.login(
      {
        hash: sha256.hmac(secretKey, player.id.toString()),
        userId: player.id.toString(),
        name: `${player.firstName} ${player.lastName}`,
        email: player.email,
      },
      function (error) {
        if (error) {
          console.log('Tawk login player fn error:', error);
        }
      }
    );
  }

  // get player data and dispatch to store
  private getPlayerData(): void {
    this.playerService.getPlayer().subscribe(player => {
      this.player = new Player(player);
      this.appStateFacadeService.setPlayerData(new Player(player));
      this.loadBalance();
    });
  }

  private checkRegistrationStep(): void {
    if (
      this.player &&
      !this.player.registrationCompleted &&
      !this.router.url.includes('/info') &&
      !this.router.url.includes('/registration') &&
      !this.router.url.includes('activation')
    ) {
      /**
       * if translate already loaded open procead registration
       * in case it is not loaded wait to be loaded
       */
      if (Object.keys(this.translateService.translations).length > 0) {
        this.navigationService.checkIfOnlyF2FLeftAndInProcess(this.player);
      } else {
        this.translateService.onDefaultLangChange.subscribe(language =>
          this.navigationService.checkIfOnlyF2FLeftAndInProcess(this.player)
        );
      }
    }
  }

  // Set router subscription
  private setRouterSubscription() {
    this.router$ = this.router.events.subscribe(data => {
      // hangle meta content depending on visited page
      if (data instanceof NavigationEnd) {
        this.metaContentService.setMetaContent(data.urlAfterRedirects);
        // on route change deside is mobile menu visible
        this.isMobileBottomMenuVisible(data.url);
      }

      // scroll to top on specified pages
      if (data instanceof NavigationEnd && !PREVENT_SCROLL_TOP_ON_URLS.find(url => data.url.includes(url))) {
        // This line is used to cover url changes because onbeforeUnload happens only on page refresh
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
        /* Browsers tend to jump back to their last scroll position on a reload.
        It seems that this automatic jump gets triggered right after the onload event(not confirmed),
        so it makes sense to have the browser scroll to the top before the page reloads. */
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        };
      }
    });
  }

  // listen for websocket state change
  private listenForSocketState(): void {
    this.webSocket$ = this.webSocketObservable.pipe(filter(data => !!data)).subscribe(data => {
      switch (data.type) {
        case SOCKET_ACTION_TYPES.SESSION_DURATION:
          this.localStorageService.setToken(data.payload);
          this.initTokenExpiration();
          break;
        case SOCKET_ACTION_TYPES.SESSION_EXPIRATION:
          this.toaster.showError(this.translateService.instant('LOGOUT_ANOTHER_DEVICE.SESSION_HAS_EXPIRED'));
          this.authService.logout('SESSION_EXPIRATION');
          break;
        case SOCKET_ACTION_TYPES.BONUS_CONFIRMATION:
          this.openBonusConfirmationDialog(data.payload);
          break;
        case SOCKET_ACTION_TYPES.BONUS_REDEMPTION:
          this.openConfirmationDialog();
          break;
      }
    });
  }

  // check token expiration
  private initTokenExpiration(): void {
    clearTimeout(this.timeoutRef);
    if (this.localStorageService.isLoggedIn()) {
      const duration = Number(localStorage.getItem('tokenDurationRaw'));
      const durationLeft = (duration - 1) * 60 * 1000; // miliseconds
      this.timeoutRef = setTimeout(() => {
        this.openTokenExpirationDialog();
      }, durationLeft);
    }
  }

  // open token expiration dialog
  private openTokenExpirationDialog(): void {
    // prevent multiple dialogs
    if (!this.tokenExpirationDialogOpened) {
      this.tokenExpirationDialogOpened = true;
      const modalRef = this.modalService.open(TokenExpirationDialog, {
        centered: true,
        scrollable: true,
        size: 'md',
        backdrop: 'static',
      });
      modalRef.result.then(
        result => {
          this.tokenExpirationDialogOpened = false;
        },
        reason => {}
      );
    }
  }

  // open bonus confirmation dialog
  private openBonusConfirmationDialog(bonus) {
    const modalRef = this.modalService.open(BonusConfirmationDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
    });
    // set bonus as a property in BonusConfirmationDialog
    bonus.currency = this.player.currency;
    modalRef.componentInstance.bonus = new Bonus(bonus);
  }

  // open confirmation dialog - bonus reedem case
  private openConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmationDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
    });
    const data = {
      type: CONFIRMATION_TYPE.BONUS_REDEMPTION,
      description: 'BONUS_ACTIVITY_BONUS_WON_CONTENT',
      headerName: 'BONUS_ACTIVITY_BONUS_WON',
    };
    modalRef.componentInstance.data = data;
  }

  // get player balance and dispatch to store
  private loadBalance(): void {
    this.playerService.getPlayerBalance().subscribe(balanceInfo => {
      this.appStateFacadeService.setPlayerBalance(new BalanceInfo(balanceInfo));
    });
    if (this.player.externalId) {
      this.playerService.getAdmiral360PlayerBalance().subscribe(admiral360BalanceInfo => {
        this.appStateFacadeService.setAdmiral360BalanceInfo(new Admiral360BalanceInfo(admiral360BalanceInfo));
      });
    }
  }

  // set query params subscription
  private setQueryParamsSubscription(): void {
    this.queryParam$ = this.appStateFacadeService.observeActiveQueryParamsStatus().subscribe(queryParams => {
      // catch defense token from IOS app and login player
      if (this.clientName === CLIENT_NAMES.ADMIRAL_SERBIA && queryParams.dt) {
        this.loginPlayerFromIOSApp(queryParams.dt);
      }
      this.checkForToasterMessage(queryParams);
      this.checkForInfoMessage(queryParams);

      // check if queryParams is not empty object and player is logged in
      if (this.isLoggedIn && queryParams && Object.keys(queryParams).length > 0) {
        this.checkPaymentQueryParams(queryParams);
      }

      if (this.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
        this.checkForPlayerActivation(queryParams);
      }

      if (this.clientName === CLIENT_NAMES.ADMIRAL_PINNBET) {
        this.activatePlayerByPassword(queryParams);
      }
    });
  }

  private loginPlayerFromIOSApp(defenceToken): void {
    this.authService
      .loginIOSPLayer({ dt: defenceToken, details: this.helpersService.getSessionData() })
      .subscribe(data => {
        this.localStorageService.setReferalCode('');
        this.localStorageService.setToken(data);
        this.getPlayerData();
        // remove dt queryParam from route
        const urlWithoutQueryParam = window.location.pathname;
        this.router.navigate([urlWithoutQueryParam]);
      });
  }

  private setNxcsPlatformLoginSubscription() {
    this.nxcsPlatformLogin$ = this.nxcsPlatformLoginObservable.subscribe(data => {
      if (data) {
        // token: token, duration
        const tokenData = {
          token: {
            token: data.token,
            duration: data.duration,
          },
        };
        this.localStorageService.setToken(tokenData);
        this.getPlayerData();

        // REDIRECT TO HOME PAGE
        this.router.navigate([`${this.defaultAppUrl}/my_casino`]);
      }
    });
  }

  // VERIFY PLAYER WITH EMAIL LINK CRO CLIENT
  private checkForPlayerActivation(queryParams) {
    switch (queryParams.activation) {
      case ACTIVATION_TYPE.MBASE:
        this.verifyEmailByCode(queryParams.mail, queryParams.code, false);
        break;
      case ACTIVATION_TYPE.MBASE_REACTIVATION:
        this.verifyEmailByCode(queryParams.mail, queryParams.code, true);
        break;
      case ACTIVATION_TYPE.NXCS:
        this.helpersService.setActivationParam(queryParams.token);
        this.router.navigate(['/create-password']);
        break;
    }
  }

  private activatePlayerByPassword(queryParams) {
    switch (queryParams.activation) {
      case ACTIVATION_TYPE.PASSWORD:
        this.helpersService.setActivationParam(queryParams.token);
        this.router.navigate(['/create-password']);
        break;
    }
  }

  // activate account with query params CRO
  private verifyEmailByCode(email, code, reactivation?): void {
    this.authService.queryParamAccountActivationCRO(email, code, reactivation).subscribe(data => {
      if (data.token) {
        const { token, duration } = data;
        this.localStorageService.setReferalCode('');
        this.localStorageService.setTokenDataAfterRegistartionStep2(token, duration);
        this.getPlayerData();
        this.router.navigate(['/registration']);
      } else {
        const newEmail = this.route.snapshot.queryParamMap.get('mail');
        this.toaster.showSuccess(data.message);
        // send email to login component and patch it to the email field
        this.helpersService.setActivationParam(newEmail);
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 500);
      }
    });
  }

  // check for success and error toaster messages
  private checkForToasterMessage(params): void {
    if (params.success_message) {
      this.toaster.showSuccess(this.translateService.instant(params.success_message));
    }
    if (params.error_message) {
      this.toaster.showError(this.translateService.instant(params.error_message));
    }
  }

  // check info dialog message
  private checkForInfoMessage(params): void {
    if (params.infoMessage) {
      const modalRef = this.modalService.open(InfoDialog, {
        centered: true,
        scrollable: true,
        size: 'md',
      });
      const data = {
        description: params.infoMessage,
      };
      modalRef.componentInstance.data = data;
    }
  }

  // check for deposit redirection from url
  private checkPaymentQueryParams(queryParams): void {
    if (queryParams.type === 'RESPONSE' || queryParams.type === 'MESSAGE') {
      // corvus, flutterwave deposit, nova banka deposit
      this.openDepositStatusDialog(queryParams);
    }
    if (queryParams.allsecureStatus) {
      this.openAllsecureDepositStatusDialog(queryParams);
    }
    if (queryParams.paystackStatus) {
      this.openPaystackDepositStatusDialog();
    }

    // IPS deepLink deposit method.
    if (queryParams.ipsStatus) {
      this.openIpsDepositStatusDialog(queryParams);
    }
  }

  // open deposit status dialog
  private openDepositStatusDialog(queryParams): void {
    const modalRef = this.modalService.open(CorvusDepositStatusDialog, {
      // Dialog will be centered -- default is false
      centered: true,
      // Dialog will be scrollable -- default is false
      scrollable: true,
      // This property is used to set size on the modal, values are : 'sm', 'md', 'lg'
      size: 'md',
    });

    // Set data on dialog instance to be used in called Modal
    modalRef.componentInstance.data = queryParams;
  }

  // open allsecure deposit status dialog
  private openAllsecureDepositStatusDialog(queryParams): void {
    const modalRef = this.modalService.open(AllsecureDepositStatusDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
    });

    // Set data on dialog instance to be used in called Modal
    modalRef.componentInstance.data = queryParams;
  }

  // Open IPS deposit status dialog.
  private openIpsDepositStatusDialog(queryParams: any): void {
    const modalRef = this.modalService.open(IpsDepositStatusDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: 'static',
    });
    modalRef.componentInstance.data = queryParams;
  }

  // check terms and conditions after login
  private checkTermsConditions(): void {
    if (this.player && !this.player.acceptedTandC) {
      setTimeout(() => {
        this.router.navigate(['/terms-and-conditions']);
      }, 1000);
    }
  }

  // Find all popup messages
  private findAllPopupMessages(unreadMessages) {
    return unreadMessages.records.filter(message => message.dialog).reverse();
  }

  private showPoliticalExposureModal(): void {
    const modalPoliticalExposure = this.modalService.open(CustomDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'top-level-position', // NOTE: Position this dialog on top of other modals.
    });

    const data = {
      title: this.translateService.instant('COMMON_NOTIFICATIONS'),
      closeButtonDeactivated: true,
      component: PoliticalExposureFormComponent,
      message: this.translateService.instant('MESSAGE_POLITICAL_EXPOSURE'),
      buttons: [
        {
          type: 'primary',
          label: this.translateService.instant('PERSONAL_DATA_SAVE'),
          on_click: false,
        },
      ],
    };

    modalPoliticalExposure.componentInstance.data = data;
    modalPoliticalExposure.result.then(
      // After close.
      result => {
        modalPoliticalExposure.componentInstance.data.component.submitData();
        this.checkForAdditionalMessages();
      }
    );
  }

  private checkForAdditionalMessages(): void {
    const currentMessage = this.popupMessagesOnHold.shift();
    if (currentMessage && this.isLoggedIn) {
      setTimeout(() => {
        // If there are active modals or popup is not allowed, do not open messages-popup dialog.
        if (this.modalService.hasOpenModals() || !this.isPopupAllowed) {
          return;
        }
        this.openMessagesPopupDialog(currentMessage);
      }, 1000);
    }
  }

  // Open Popup Messages Dialog
  private openMessagesPopupDialog(message): void {
    const messagesComponent =
      environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
        ? PinnbetMessagesPopupDialog
        : MessagesPopupDialog;
    const modalRef = this.modalService.open(messagesComponent, {
      centered: true,
      scrollable: true,
      keyboard: false,
      size: 'md',
      backdrop: 'static',
    });
    modalRef.componentInstance.message = new MessageDataModel(message);
    modalRef.result.then(
      // after close
      result => {
        this.checkForAdditionalMessages();
      }
    );
  }

  // Get unread messages for header notification
  private getUnreadMessages(): void {
    if (this.player) {
      this.messagesService.getAllUnreadMessages().subscribe(data => {
        this.appStateFacadeService.setMessages(data.records);

        // Find all popupMessages that are not shown because player was offline
        this.popupMessagesOnHold = this.findAllPopupMessages(data);
        // Check if there are any messages in that aray
        if (this.popupMessagesOnHold.length) {
          // If client is CRO and there is msg of type 'Politician', show that msg before other msgs.
          if (
            this.clientName === CLIENT_NAMES.ADMIRAL_CROATIA &&
            this.popupMessagesOnHold.find(element => element.type === 'Politician')
          ) {
            this.popupMessagesOnHold = this.popupMessagesOnHold.filter(
              element => element.type !== 'Politician'
            );
            this.showPoliticalExposureModal();
            return;
          }
          // If soo open popup dialog with that message as data
          // Get The first message from that array of messages
          const message = this.popupMessagesOnHold.shift();
          setTimeout(() => {
            if (this.modalService.hasOpenModals()) {
              return;
            }
            this.openMessagesPopupDialog(message);
          }, 2000);
        }
      });
    }
  }

  // This subscription is determining should next popup be opened
  private subscribeToPopupAllowanceState() {
    this.popupApperance$ = this.popupAllowanceStateService.popupAllowanceState$.subscribe(data => {
      this.isPopupAllowed = data;
    });
  }

  // listen for active(open) dialogs and disable body scroll in that case, enable when dialog is closed
  private subscribeToActiveDialogs() {
    this.activeDialog$ = this.modalService.activeInstances.subscribe(activeModals => {
      if (activeModals.length && !(activeModals[0].componentInstance instanceof MobileGamePlayDialog)) {
        // timeout set for prevent flashing when opening game info dialog
        setTimeout(() => {
          this.scrollService.disableOrEnableBodyScroll(true);
        }, 0);
      } else {
        this.scrollService.disableOrEnableBodyScroll(false);
      }
    });
  }

  // open paystack deposit status dialog
  private openPaystackDepositStatusDialog() {
    const modalRef = this.modalService.open(PaystackDepoistStatusDialog, {
      centered: true,
      scrollable: true,
      size: 'md',
    });
  }

  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // private checkShowAppDownloadPopupVisibility(): void {
  //   const popup = this.localStorageService.getShowAppDownloadPopup();
  //   if (!popup) {
  //     this.showAppDownloadPopup(true);
  //     return;
  //   }
  //   const durationDate = popup['date'];
  //   if (this.dateService.isAfterToday(durationDate)) {
  //     this.showAppDownloadPopup(true);
  //     this.localStorageService.removeFromLocalStorage('showAppDownloadPopup');
  //   }
  // }
  //
  // public showAppDownloadPopup(flag): void {
  //   this.showDownloadPopup = flag;
  //   this.appStateFacadeService.setAppDownloadPopupState(flag);
  // }

  private setupQuickBarElement() {
    this.quickBarService.setActiveItemsInBar(
      // Insert downloadApp item in quick bar if client is mobile and a client is in downloadAppClients array
      this.isMobile && this.downloadAppClients.includes(this.clientName)
        ? [{ itemType: 'downloadApp', active: true, duration: 1000 }]
        : []
    );
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (this.localStorageService.getToken()) {
      this.authService.updateToken().subscribe({});
    }
  }
}
