import { Component, OnInit } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { ExternalContentService } from '@services/external-content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-personal-data',
  templateUrl: './terms-personal-data.component.html',
  styleUrls: ['./terms-personal-data.component.scss'],
})
export class TermsPersonalDataComponent implements OnInit {
  public isJumpToTopAvailable: boolean;
  public pageContent = '';
  constructor(private externalContentService: ExternalContentService) {}

  ngOnInit(): void {
    this.getPageContent('PERSONAL_DATA_TERMS_CONDITIONS');
  }

  private getPageContent(code) {
    this.externalContentService.getStaticPageByCode(code).subscribe(data => {
      this.pageContent = data.message;
    });
  }

  public scroll(event: any) {
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      this.isJumpToTopAvailable = event.target.scrollTop >= 2000;
    }
  }

  public jumpToTop() {
    document.getElementsByClassName('termsPersonalDataContainer')[0].scrollTop = 0;
  }
}
