import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { PERSONAL_QUESTIONS } from '@modules/registrations/md-shop-registration/md-shop-registration/md-shop-registration-personal-questions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { ToasterService } from '@services/toaster.service';
import { WithdrawalService } from '@services/withdrawal.service';
import { passwordValidator } from '@validators/password.validator';
import { environment } from 'src/environments/environment';
import { HelpersService } from '@services/helpers.service';
import { BehaviorSubject, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-security-question-dialog',
  templateUrl: './security-question.dialog.html',
  styleUrls: ['./security-question.dialog.scss'],
})
export class SecurityQuestionDialog {
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;
  public securityQuestionForm: FormGroup;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public personalQuestions = PERSONAL_QUESTIONS;
  public isLoading: boolean;
  public patchPersonalQuestionSubject = new BehaviorSubject('');

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private withdrawalService: WithdrawalService,
    private toaster: ToasterService,
    private authService: AuthService,
    private helpersService: HelpersService,
    private translateService: TranslateService
  ) {
    this.buildSecurityQuestionForm();
  }
  public personalQuestionsCodesObservable = zip(
    of([{ code: 'PERSONAL_QUESTION_CHOOSE' }]),
    of(PERSONAL_QUESTIONS)
  ).pipe(
    map(item => item[0].concat(item[1])),
    map(questions => questions.map(singleQuestion => this.translateService.instant(singleQuestion.code)))
  );

  onSelectQuestion(question: string) {
    if (question === this.translateService.instant('PERSONAL_QUESTION_CHOOSE')) {
      // DEFAULT
    } else if (question === '' || !question) {
      // INVALID
    } else {
      // VALID
      const questions = PERSONAL_QUESTIONS;
      const originalQuestion = questions.find(
        singleQuestion => this.translateService.instant(singleQuestion.code) === question
      );

      this.securityQuestionForm.patchValue({ personalQuestion: originalQuestion.code });
    }

    this.onPersonalQuestionChange();
  }
  // build form
  private buildSecurityQuestionForm(): void {
    this.securityQuestionForm = this.fb.group({
      password: ['', [Validators.required, passwordValidator]],
      personalQuestion: ['', Validators.required],
      personalAnswer: [{ value: '', disabled: true }, Validators.required],
      personalQandA: [''],
    });

    this.translateService.get('PERSONAL_QUESTION_CHOOSE').subscribe(data => {
      this.patchPersonalQuestionSubject.next(data);
    });
  }

  // close modal
  public closeModal() {
    this.activeModal.close();
  }

  // check security question on BE
  public checkSecurityQuestion(): void {
    this.isLoading = true;
    const body = {
      password: this.password.value,
      personalQandA: this.personalQuestion.value + '|' + this.personalAnswer.value,
    };
    this.withdrawalService.checkMDShopSecurityQuestion(body).subscribe(data => {
      this.isLoading = false;
      data
        ? this.activeModal.close(true)
        : this.toaster.showError(this.translateService.instant('MD_SHOP_WITHDRAWAL_SECURITY_ERROR'));
    });
  }

  // on personal question change
  public onPersonalQuestionChange(): void {
    this.personalAnswer.setValue('');
    this.personalAnswer.markAsPristine();
    this.personalAnswer.markAsUntouched();
    if (this.personalQuestion.value) {
      this.personalAnswer.enable();
    } else {
      this.personalAnswer.disable();
    }
  }

  // send request for reset security questionn and answer
  public requestSecurityQandAReset(): void {
    this.isLoading = true;
    this.authService.requestResetMDShopSecurityQuestion().subscribe(data => {
      this.isLoading = false;
      this.toaster.showSuccess(data.message);
    });
  }

  maskUnmaskPassword(element: HTMLElement, hideShowIcon?: HTMLElement) {
    this.helpersService.maskUnmaskPassword(element, hideShowIcon);
  }

  // gender getter
  public get password(): AbstractControl {
    return this.securityQuestionForm.get('password');
  }

  public get personalQuestion(): AbstractControl {
    return this.securityQuestionForm.get('personalQuestion');
  }

  public get personalAnswer(): AbstractControl {
    return this.securityQuestionForm.get('personalAnswer');
  }
}
