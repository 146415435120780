import { Component, HostListener, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppStateFacadeService } from '@state/app-state.facade';
import { HelpersService } from '@services/helpers.service';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'app-mobile-bottom-menu',
  templateUrl: './mobile-bottom-menu.component.html',
  styleUrls: ['./mobile-bottom-menu.component.scss'],
})
export class MobileBottomMenuComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  documentClick(event: PointerEvent) {
    if (!this.viewRef.element.nativeElement.contains(event.target)) {
      this.secondaryMenuExtended = false;
    }
  }

  public activePage: string;
  public subRoute: string;
  public environment = environment;
  public menuActive = false;

  private closeMobileBottomMenu$: Subscription;
  @Input() set setActivePage(page) {
    const routeSplit = page.split('?')[0].split('/');
    this.activePage = `/${routeSplit[1]}`;
    this.subRoute = routeSplit[2];
    if (this.activePage === '/profile') {
      this.activePage = `${this.activePage}/${this.subRoute}`;
    }
    this.secondaryMenuExtended = false;
  }
  public secondaryMenuExtended = false;
  public menuItems = [];
  private menuItemsMoc = [
    {
      imagePath: 'menu_sport-icon.svg',
      pageActive: '/sport-prematch',
      iconLabel: 'COMMON_SPORTS',
    },
    {
      imagePath: 'menu_sport-live.svg',
      pageActive: '/sport-live',
      iconLabel: 'MENU_SPORT_LIVE',
    },
    {
      imagePath: 'menu_icon.svg',
      pageActive: 'menu',
      iconLabel: 'COMMON_MENU',
    },
    {
      imagePath: 'menu_casino.svg',
      pageActive:
        environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
        environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
          ? '/slot'
          : '/casino',
      iconLabel: 'CASINO',
    },
    {
      imagePath: 'menu_casino-live.svg',
      pageActive:
        environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
        environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET
          ? '/live-dealer'
          : '/casino-live',
      iconLabel: 'MENU_CASINO_LIVE',
    },
  ];

  public subMenuItems = [];
  private subMenuItemsMoc = [
    {
      imagePath: 'sec-menu-tourn.svg',
      classSuFix: '--is-first',
      pageActive: '/tournament',
      iconLabel: 'SEC_MENU_TOURN',
    },
    {
      imagePath: 'sec-menu-promo.svg',
      classSuFix: '--is-second',
      pageActive: '/promotions',
      iconLabel: 'PROMOTION.ADS1',
    },
    {
      imagePath: 'sec-menu-deposit.svg',
      classSuFix: '--is-third',
      pageActive: '/profile/deposit',
      iconLabel: 'DEPOSIT',
    },
    {
      imagePath: 'menu_mini-games.svg',
      classSuFix: '--is-fourth',
      pageActive: '/mini-games',
      iconLabel: 'SEC_MENU_MINI_GAMES',
    },
    {
      imagePath: 'menu_virtual.svg',
      classSuFix: '--is-fifth',
      pageActive: '/virtual',
      iconLabel: 'COMMON_VIRTUAL',
    },
  ];

  constructor(
    private router: Router,
    private appStateFacadeService: AppStateFacadeService,
    private viewRef: ViewContainerRef,
    private helperService: HelpersService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.closeMobileBottomMenu$ = this.appStateFacadeService
      .getSecondaryMenuStateObservable()
      .subscribe(newSecondaryMenuState => {
        this.secondaryMenuExtended = newSecondaryMenuState;
      });
    this.appStateFacadeService.getBottomMobileMenu().subscribe(menuItems => {
      if (menuItems.length) {
        if (this.helperService.iOS()) {
          setTimeout(() => this.setConfigDataSubscription(menuItems), 500);
        } else {
          this.setConfigDataSubscription(menuItems);
        }
        this.menuActive = true;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.closeMobileBottomMenu$) {
      this.closeMobileBottomMenu$.unsubscribe();
    }
  }

  classConstructor(base, sufix, boolean) {
    return boolean ? `${base}${sufix}` : '';
  }

  clickOnMenuIcon() {
    this.secondaryMenuExtended = !this.secondaryMenuExtended;
  }

  // set config subscription
  setConfigDataSubscription(menuItems): void {
    const mobileMenuItems = menuItems;
    this.menuItems = this.menuItemsMoc.map((menuItemsBtn, index: number) => {
      const itemPack = {
        imagePath: mobileMenuItems[index].activeIcon,
        pageActive: mobileMenuItems[index].url,
        iconLabel: mobileMenuItems[index].name,
        requireAuthorized: mobileMenuItems[index].requireAuthorized,
      };
      const mainMenu = {
        imagePath: mobileMenuItems[index].activeIcon,
        pageActive: 'menu',
        iconLabel: 'COMMON_MENU',
      };
      if (index === 2) {
        this.populateSubMenuWithBtnData(menuItems[index].subMenu);
      }
      return index === 2 ? mainMenu : itemPack;
    });
  }

  populateSubMenuWithBtnData(subMenuData) {
    this.subMenuItems = this.subMenuItemsMoc.map((subMenuItem, index: number) => {
      const itemPack = {
        imagePath: subMenuData[index].activeIcon,
        classSuFix: subMenuData[index].bsClass,
        pageActive: subMenuData[index].url,
        iconLabel: subMenuData[index].name,
        requireAuthorized: subMenuData[index].requireAuthorized,
      };
      return itemPack;
    });
  }

  navigateToPage(pageClicked, requireAuthorized) {
    if (pageClicked !== this.activePage) {
      if (requireAuthorized) {
        this.navigationService.checkRegistrationStatusAndNavigate({
          url: `${pageClicked}`,
          type: 'INTERNAL_LINK_SUBMENU',
        });
      } else {
        this.secondaryMenuExtended = false;
        this.activePage = pageClicked;
        this.router.navigate([`${pageClicked}`]);
      }
    } else {
      console.warn('already on page');
    }
  }
}
