import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateService } from '@services/date.service';
import { HelpersService } from '@services/helpers.service';
import { LocalStorageService } from '@services/local-storage.service';
import { environment } from '../../../../environments/environment';
import { APP_LINKS } from '../../../../assets/js/download-apps';

@Component({
  selector: 'app-download-popup',
  templateUrl: './app-download-popup.component.html',
  styleUrls: ['./app-download-popup.component.scss'],
})
export class AppDownloadPopupComponent {
  public environment = environment;
  public appLinks = APP_LINKS;
  @Input() closeQuickBar: () => void;

  public imagesBaseUrl = environment.imagesBaseUrl;
  public isAndroid = this.helpersService.isAndroid();

  constructor(private helpersService: HelpersService) {}
}
