import { Pipe, PipeTransform } from '@angular/core';

/**
 * Safe number pipe is used to sanitize string values to numbers.
 * Prevent a scenario where user types two "." characters in the input field.
 */
@Pipe({
  name: 'safeNumber',
})
export class SafeNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (typeof value === 'number') {
      return value;
    }
    const sanitizedValue = parseFloat(value);
    return isNaN(sanitizedValue) ? null : sanitizedValue;
  }
}
