import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IpsDepositResult } from '../shared/ips-deposit-result.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { IpsDepositService } from '@services/ips-deposit.service';

@Component({
  selector: 'app-ips-deposit-status-dialog',
  templateUrl: './ips-deposit-status.dialog.html',
  styleUrls: ['./ips-deposit-status.dialog.scss'],
})
export class IpsDepositStatusDialog implements OnInit {
  @Input() data: any;

  public depositResult: IpsDepositResult;
  public clientName = environment.clientName;
  public clientNames = CLIENT_NAMES;
  public imagesBaseUrl = environment.imagesBaseUrl;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private ipsDepositService: IpsDepositService
  ) {}

  ngOnInit(): void {
    this.depositResult = new IpsDepositResult(this.data);
    this.ipsDepositService.clearTimer(this.depositResult.rpReference);
  }

  // Close modal.
  public closeModal(): void {
    // Redirect to same base route, but without query params needed for deposit dialog.
    const route = this.router.url.split('?')[0];
    this.router.navigate([route]);
    this.activeModal.close();
  }
}
