<div class="c-sticky-placeholder">
  <!--  these wrapper take care on desktop and mobile view bacground and alingment-->
  <div class="c_quick-bar-wrapper">
    <div #scrollableDiv class="c_quick-bar-container c_quick-bar-container--is-{{ quickBarStates }}">
      <!--  item container will receive sum of width of all elements in it an he have roll for scrolling on position-->
      <div class="c_items-container">
        <ng-container *ngFor="let item of activeItemsInBar">
          <ng-container *ngTemplateOutlet="itemsInBar; context: { item: item }"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="isMobile && showExpandBtn"
    class="expand-btn-container"
    [class.classname--is-show]="quickBarStates === 'show'"
    [class.classname--is-expand]="quickBarStates === 'expand'"
  >
    <div class="c_expand-btn-handler" (click)="expandCollapseQuickBar()">
      <svg width="38" height="18" viewBox="0 0 38 18" fill="none">
        <path *ngIf="quickBarStates === 'expand'" d="M19 6L24 12H14L19 6Z" />
        <path *ngIf="quickBarStates !== 'expand'" d="M19 12L14 6L24 6L19 12Z" />
      </svg>
    </div>
  </div>
</div>

<ng-template #itemsInBar let-item="item">
  <ng-container [ngSwitch]="item.itemType">
    <!--   Here implement new elements witch we wont to contain in quick bar -->
    <ng-container *ngSwitchCase="'downloadApp'">
      <div class="c_bar-item-container">
        <app-download-popup [closeQuickBar]="closeQuickBar.bind(this)"></app-download-popup>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'cardDeposit'">
      <div class="c_bar-item-container">
        <app-deposit-switch-by-client
          [expandCollapseQuickBar]="expandCollapseQuickBar.bind(this)"
          [closeQuickBar]="closeQuickBar.bind(this)"
          [stopIntervalChangeItemsInBar]="stopIntervalChangeItemsInBar.bind(this)"
          [clientName]="clientName"
          [isMobile]="isMobile"
        ></app-deposit-switch-by-client>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'liederBoard'">
      <div class="c_bar-item-container">
        <app-tournament-list-quick-bar
          [closeQuickBar]="closeQuickBar.bind(this)"
          [tournamentItemConfig]="item"
          [setStateOfQuickBar]="quickBarStates"
        >
        </app-tournament-list-quick-bar>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
