import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, map, takeWhile, timer } from 'rxjs';
import { Deposit } from '@models/deposit.model';
import { IPS_DEPOSIT_TIMER, IpsDeposit } from '@models/ips-deposit.model';
import { LocalStorageService } from '@services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class IpsDepositService {
  private depositConfig = new BehaviorSubject<Deposit>(null);
  private depositConfig$ = this.depositConfig.asObservable();

  private countdownSubscription: Subscription = null;
  private timeLeftSubscription: Subscription = null;
  private timeLeftSubject = new BehaviorSubject<number | null>(null);
  private timeLeft$ = this.timeLeftSubject.asObservable();
  private activePaymentData: IpsDeposit = null;

  private isDepositFinishedSubject = new BehaviorSubject<string>(''); // Holds RP reference - transaction ID.
  private isDepositFinished$ = this.isDepositFinishedSubject.asObservable();

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.setTimeLeftSubscription();
  }

  public setDepositConfig(configData: Deposit) {
    this.depositConfig.next(configData);
  }

  public getDepositConfigObservable(): Observable<Deposit> {
    return this.depositConfig$;
  }

  /**
   * Generate payment data for IPS deposit.
   * @param url - IPS deposit base URL
   * @param isDeepLink - 'deep link' or QR code
   * @param data - payload. Property 'bankId' is sent only when isDeepLink is set to 'true'.
   * @returns generated payment link
   */
  public generateIpsDepositData(
    url: string,
    isDeepLink: boolean,
    data: { amount: number; bankId?: number }
  ): Observable<any> {
    return this.http.post(`${url}?deepLink=${isDeepLink}`, data);
  }

  // Save active payment data and start countdown.
  public initCountdown(data: IpsDeposit): void {
    this.activePaymentData = data;
    this.startCountdown(IPS_DEPOSIT_TIMER);
  }

  public getTimeLeftObservable(): Observable<number> {
    return this.timeLeft$;
  }

  public getActivePaymentData(): IpsDeposit {
    return this.activePaymentData;
  }

  public getIsDepositFinishedObservable(): Observable<string> {
    return this.isDepositFinished$;
  }

  // Extract RP reference from deposit string.
  public extractRpReference(data: string, startStr: string, endStr: string): string {
    let rpReference = '';
    const startStrLength = startStr.length;
    const startIndex = data.indexOf(startStr);

    if (startIndex !== -1) {
      const endIndex = data.indexOf(endStr, startIndex);
      if (endIndex !== -1) {
        rpReference = data.substring(startIndex + startStrLength, endIndex);
      } else {
        rpReference = data.substring(startIndex + startStrLength);
      }
    }

    return rpReference;
  }

  // On player login (also after page reload), load any stored IPS payment data and add a page reload listener.
  public onLogin(): void {
    // Load any active payment data from local storage.
    const ipsDepositData = this.localStorageService.getIpsDepositData();
    if (ipsDepositData) {
      this.activePaymentData = ipsDepositData;
      this.startCountdown(this.activePaymentData.timeLeft);
      this.localStorageService.removeIpsDepositData();
    }
    // Add page reload listener.
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  // On player logout, or before page reload, clear countdown timer and remove page reload listener.
  public clearSubscriptions(): void {
    this.clearCountdown();
    this.timeLeftSubscription?.unsubscribe();
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }

  // After deposit status has been received, clear countdown timer and remove active payment data.
  public clearTimer(rpReference: string): void {
    if (this.activePaymentData && this.activePaymentData.rpReference === rpReference) {
      this.clearCountdown();
      this.localStorageService.removeIpsDepositData();
      this.isDepositFinishedSubject.next(rpReference);
    }
  }

  // Reset deposit finished value (RP reference), so that it cannot be used again for resetting timer.
  public resetDepositFinishedValue(): void {
    this.isDepositFinishedSubject.next('');
  }

  // Page reload handler.
  private beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    // Save active payment data to local storage, if payment timer is active.
    if (this.activePaymentData) {
      this.activePaymentData.timeLeft = this.timeLeftSubject.value;
      this.localStorageService.setIpsDepositData(this.activePaymentData);
    }
  };

  // Subscribe to countdown timer.
  private setTimeLeftSubscription(): void {
    this.timeLeftSubscription = this.timeLeft$.subscribe((timeLeft: number) => {
      if (timeLeft === 0) {
        setTimeout(() => {
          this.clearCountdown();
        }, 0);
      }
    });
  }

  /**
   * Countdown timer.
   * @param countdownValue - countdown value in seconds.
   */
  private startCountdown(countdownValue: number): void {
    // Set countdown timer.
    const countdown$ = timer(0, 1000).pipe(
      map(n => countdownValue - n),
      takeWhile(n => n >= 0)
    );
    // Start countdown.
    this.countdownSubscription = countdown$.subscribe(secondsLeft => {
      this.timeLeftSubject.next(secondsLeft);
    });
  }

  private clearCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
      this.countdownSubscription = null;
    }
    this.activePaymentData = null;
    this.timeLeftSubject.next(null);
  }
}
