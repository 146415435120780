import { Injectable } from '@angular/core';
import { TransactionFilterModel } from '@models/transaction-filter-model';
import { BehaviorSubject } from 'rxjs';
import { DateService } from './date.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class TransactionFilterService {
  private filterModelSubject = new BehaviorSubject<any>({});
  public filterModelObservable = this.filterModelSubject.asObservable();

  private activeFilterSectionSubject = new BehaviorSubject<boolean>(false);
  public activeFilterSectionObservable = this.activeFilterSectionSubject.asObservable();

  today = this.dateService.today();
  yesterday = this.dateService.yesterday();
  currentWeekStart = this.dateService.currentWeekStart();
  currentMonthStart = this.dateService.currentMonthStart();
  lastWeekStart = this.dateService.lastWeekStart();
  lastWeekEnd = this.dateService.lastWeekEnd();
  lastMonthStart = this.dateService.lastMonthStart();
  lastMonthEnd = this.dateService.lastMonthEnd();

  // periods for filter search by period field
  public searchPeriods = [
    'SEARCH_FILTER_TODAY',
    'SEARCH_FILTER_YESTERDAY',
    'SEARCH_FILTER_CURRENT_WEEK',
    'SEARCH_FILTER_CURRENT_MONTH',
    'SEARCH_FILTER_LAST_WEEK',
    'SEARCH_FILTER_LAST_MONTH',
  ];

  // periods for filter search on tickets page
  public searchPeriodsWithActiveSection = [...this.searchPeriods, 'SEARCH_FILTER_ACTIVE_SPORT_TICKETS'];

  /**
   * This is initial/default TODAY filter model.
   * Will be used to set default value to filter component,
   * and for reseting filter. Do not change value of this variable.
   *  */
  private initialFilter = new TransactionFilterModel({
    period: 'SEARCH_FILTER_TODAY',
    startDate: this.today,
    endDate: this.today,
  });

  /**
   * This varible will be used to track changes in filter.
   */
  private filterState = new TransactionFilterModel({
    period: 'SEARCH_FILTER_TODAY',
    startDate: this.today,
    endDate: this.today,
  });

  constructor(private dateService: DateService) {}

  /**
   * get filter search periods
   *
   * haveActiveFilterSection param is only for tickets page to handle search periods array
   *
   */
  getFilterPeriods(haveActiveFilterSection?) {
    return haveActiveFilterSection ? this.searchPeriodsWithActiveSection : this.searchPeriods;
  }

  /**
   * get initial filter model - this f-tn will set default TODAY value in filter component on initialization
   */
  getInitialFilterValue() {
    return this.initialFilter;
  }

  /**
   * reset filter model - this f-tn will set default TODAY value
   */
  resetFilterValue() {
    // reset filter in filter component
    this.filterModelSubject.next(this.initialFilter);
    // reset filter state
    Object.assign(this.filterState, this.initialFilter);
  }

  /**
   * set new filter model - change period when user choose period in filter
   */
  setFilter(period): any {
    let startDate;
    let endDate;

    switch (period) {
      case 'SEARCH_FILTER_TODAY':
        startDate = this.today;
        endDate = this.today;
        break;
      case 'SEARCH_FILTER_YESTERDAY':
        startDate = this.yesterday;
        endDate = this.yesterday;
        break;
      case 'SEARCH_FILTER_CURRENT_WEEK':
        startDate = this.currentWeekStart;
        endDate = this.today;
        break;
      case 'SEARCH_FILTER_CURRENT_MONTH':
        startDate = this.currentMonthStart;
        endDate = this.today;
        break;
      case 'SEARCH_FILTER_LAST_WEEK':
        startDate = this.lastWeekStart;
        endDate = this.lastWeekEnd;
        break;
      case 'SEARCH_FILTER_LAST_MONTH':
        startDate = this.lastMonthStart;
        endDate = this.lastMonthEnd;
        break;
      case 'SEARCH_FILTER_ACTIVE_SPORT_TICKETS':
        startDate = null;
        endDate = null;
        break;
    }

    this.filterState = {
      period,
      startDate: this.dateService.formatDate(startDate),
      endDate: this.dateService.formatDate(endDate),
    };

    return this.filterState;
  }

  /**
   * get query params for all transactions
   */
  getFilterQueryParams(isActiveTickets?) {
    let queryParams;
    // if tickets have activeFilterSection in mock  and there is no startDate, we need just active param to get all active tickets
    if (isActiveTickets && !this.filterState.startDate) {
      queryParams = { active: true };
    } else {
      queryParams = {
        startDate: this.filterState.startDate,
        endDate: this.filterState.endDate,
      };
    }
    return queryParams;
  }

  /**
   * add active section in filter
   */
  addActiveFilterSection(haveActiveFilterSection: boolean): void {
    this.activeFilterSectionSubject.next(haveActiveFilterSection);
  }

  /**
   * Set new date filter model.
   */
  setDateFilter(date: NgbDate): TransactionFilterModel {
    let selectedDate = '';
    if (date) {
      const monthStr = date.month < 10 ? '0' + date.month : date.month;
      const dayStr = date.day < 10 ? '0' + date.day : date.day;
      selectedDate = `${date.year}-${monthStr}-${dayStr}`;
    }

    this.filterState = {
      period: '',
      startDate: selectedDate,
      endDate: selectedDate,
    };

    return this.filterState;
  }
}
