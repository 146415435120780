<div
  class="gameContainer"
  #gameContainer
  [ngClass]="{ 'md-shop-lobby': environment.clientName === CLIENT_NAMES.ADMIRAL_MD_SHOP }"
>
  <h4 class="sectionTitle section-title-reset" *ngIf="(showSectionTitleObservable | async) && !usedForSearch">
    {{ currentlyActiveSectionName }}
  </h4>

  <div #gamesWrapper class="p-3">
    <div>
      <div
        *ngIf="(gameLengthObservable | async) !== 0"
        [ngClass]="environment.clientName === clientNames.ADMIRAL_PINNBET ? 'row' : 'gamesRow'"
      >
        <ng-container *ngIf="environment.clientName !== clientNames.ADMIRAL_PINNBET">
          <app-lobby-game
            *ngFor="let game of gamesObservable | async"
            [game]="game"
            [lobbyPath]="lobbyPath"
          ></app-lobby-game>
        </ng-container>

        <ng-container *ngIf="environment.clientName === clientNames.ADMIRAL_PINNBET">
          <div class="col-6 col-md-4 col-lg-2 mt-2 mb-2" *ngFor="let game of gamesObservable | async">
            <app-pinnbet-lobby-game [game]="game" [lobbyPath]="lobbyPath"></app-pinnbet-lobby-game>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      (gameLengthObservable | async) === 0 && !gamesAreLoading && getGamesFromParams;
      then noGamesMessage
    "
  ></div>
  <div class="placeholder-div" #placeholders>
    <div
      *ngIf="!lastLoad"
      [ngClass]="environment.clientName === clientNames.ADMIRAL_PINNBET ? 'row' : 'gamesRow'"
    >
      <div
        class="placeholder-image"
        [ngClass]="{
          'col-6 col-md-4 col-lg-2 mt-2 mb-2': environment.clientName === clientNames.ADMIRAL_PINNBET
        }"
        *ngFor="let p of placehoderImageCountArray"
      >
        <span
          class="placeholder-icon"
          [setSVGAttributes]="{ height: '48px', width: '48px' }"
          inlineSVG="assets/images/image-solid.svg"
        ></span>
        <!-- <div class="animation"></div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #noGamesMessage>
  <div class="no-games-message-wrapper">
    <h4>
      {{ 'NO_GAMES_IN_SECTION' | translate }}
    </h4>
  </div>
</ng-template>
