import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

/**
 * ImageWrapperComponent take care of progressive image load
 * to get these component work correct im img folder you have compress
 * low resolution image with name *_lowRes.
 * if we have image name *_mobile wraper will slice mobile and concate _lowRes img name.
 * Example of implementation:
 * <app-image-wrapper
 *  [width]="120"
 *  [height]="120"
 *  [alt]="game.title"
 *  [progressiveLoad]="true"
 *  [src]="game[imgSource]"
 *  [costumeClass]="'img-fluid rounded-lg'"
 * ></app-image-wrapper>
 */

@Component({
  selector: 'app-image-wrapper',
  templateUrl: './image-wrapper.component.html',
  styleUrls: ['./image-wrapper.component.scss'],
})
export class ImageWrapperComponent implements OnInit {
  @ViewChild('imgElement', { static: true }) imageContainer: ElementRef;
  @Input() progressiveLoad = false;
  @Input() width = 100;
  @Input() height = 100;
  @Input() src = '';
  @Input() alt = '';
  @Input() costumeClass = '';
  @Input() costumeLowResSrc = '';
  private mobile: boolean;

  private image: HTMLImageElement;
  private imageHiRes: HTMLImageElement;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    // if progresive load is set to false, skip loading low resolution images
    this.progressiveLoad ? this.createImage() : this.asyncLoadImg();
  }

  // Low resolution image load.
  private createImage() {
    this.image = new Image(this.width, this.height);
    this.image.onload = () => {
      this.asyncLoadImg();
    };
    this.image.onerror = () => this.lowResLoadingError();
    this.image.src = this.costumeLowResSrc || this.src;
    this.image.className = `${this.costumeClass} loadingImage`;
    this.renderer.appendChild(this.imageContainer.nativeElement, this.image);
  }

  // High resolution image load.
  private asyncLoadImg() {
    this.imageHiRes = new Image(this.width, this.height);
    this.imageHiRes.onload = () => {
      // image element wont be created if progresive load is set to false
      if (this.image) {
        this.renderer.removeChild(this.imageContainer.nativeElement, this.image);
      }
      this.renderer.appendChild(this.imageContainer.nativeElement, this.imageHiRes);
    };
    this.imageHiRes.onerror = () => this.mainImgLoadingError();
    this.imageHiRes.className = this.costumeClass;
    this.imageHiRes.src = this.src;
  }

  private lowResLoadingError() {
    // console.warn('[low resolution image missing]', this.lowResImgName);
    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
        this.image.src = '../../../../assets/images/nigeria-missing-image-admiral_lowRes.png';
        break;
      default:
        this.image.src = '../../../../assets/images/missing-image-admiral_lowRes.jpg';
        break;
    }
  }

  private mainImgLoadingError() {
    // console.warn('[hi resolution image missing]', this.src);
    this.mobile = this.src.includes('mobile');
    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_CROATIA:
        this.imageHiRes.src = this.mobile
          ? '../../../../assets/images/croatia-missing-image-admiral.png'
          : '../../../../assets/images/croatia-missing-image-admiral.png';
        break;
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
        this.imageHiRes.src = this.mobile
          ? '../../../../assets/images/nigeria-missing-image-admiral_mobile.png'
          : '../../../../assets/images/nigeria-missing-image-admiral.png';
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
        this.imageHiRes.src = this.mobile
          ? '../../../../assets/images/pinnbet-missing-image.png'
          : '../../../../assets/images/pinnbet-missing-image.png';
        break;
      default:
        this.imageHiRes.src = this.mobile
          ? '../../../../assets/images/missing-image-admiral_mobile.jpg'
          : '../../../../assets/images/missing-image-admiral.jpg';
        break;
    }
  }
}
