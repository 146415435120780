import { Component, Input } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-card-detail-header',
  templateUrl: './payment-card-detail-header.component.html',
  styleUrls: ['./payment-card-detail-header.component.scss'],
})
export class PaymentCardDetailHeaderComponent {
  @Input() paymentTitle;
  @Input() backUrl;
  public environment = environment;
  public clientNames = CLIENT_NAMES;

  constructor() {}
}
