<div class="box-row p-3">
  <div class="c_document-upload-card">
    <div class="c_upload-file-field text-center">
      <button class="btn btn-icon" (click)="onClick($event)">
        <input id="hiddenImgInput" type="file" #fileInput (change)="onFileChange($event)" hidden />
        <div class="icon image-container" [inlineSVG]="'assets/icons/upload_document.svg'"></div>
      </button>
    </div>

    <div class="d-flex align-items-center img-wrap">
      <img [src]="imagePreview" alt="" *ngIf="imagePreviewSet" />
      <i class="sporticon sporticon-circle-remove" *ngIf="imagePreviewSet" (click)="clearFile()"></i>

      <div *ngIf="!imagePreviewSet" class="c_document-upload-notification">
        {{ 'FILE_UPLOAD_NO_DOCUMENTS' | translate }}
      </div>
    </div>
  </div>
</div>
<div @heightAnimation *ngIf="imagePreviewSet" class="overflow-y-hidden">
  <button
    class="mt-4"
    appAdmiralButton
    [type]="'save-changes'"
    [marginCostume]="'auto'"
    href="#"
    (click)="$event.preventDefault()"
    (click)="onSubmit()"
    [disabled]="!imagePreviewSet"
  ></button>
</div>
