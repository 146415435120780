import { Injectable } from '@angular/core';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SportradarAdsService {
  private supportedClients: string[] = [
    CLIENT_NAMES.ADMIRAL_SERBIA,
    CLIENT_NAMES.ADMIRAL_BOSNIA,
    CLIENT_NAMES.ADMIRAL_MONTENEGRO,
  ];

  setSportradarEvent(event: string, payload: any): void {
    if (environment.production && this.supportedClients.includes(environment.clientName)) {
      (window as any).srtmCommands.push({
        event,
        payload,
      });
    }
  }
}
