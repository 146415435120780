import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { GLOBAL_VARIABLES } from '@enums/global-variables.enum';
import { LobbyFavoriteGame } from '@models/lobby-game-favorite.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LobbyService } from '@services/lobby.service';
import { HelpersService } from '@services/helpers.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ToasterService } from '@services/toaster.service';
import { environment } from 'src/environments/environment';
import { JackpotService } from '@services/jackpot.service';
import { Observable, Subscription, filter } from 'rxjs';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { EventListenerService } from '@services/event-listener.service';
import { fadeAnimationTop } from '../../animations/animations';
import { BalanceInfo } from '@models/balance-info.model';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { Title } from '@angular/platform-browser';
import { BonusService } from '@services/bonus.service';
import { SOCKET_ACTION_TYPES } from '@enums/socket_action_type.enum';

@Component({
  selector: 'app-mobile-game-play',
  templateUrl: './mobile-game-play.dialog.html',
  styleUrls: ['./mobile-game-play.dialog.scss'],
  animations: [fadeAnimationTop('500ms')],
})
export class MobileGamePlayDialog implements OnInit, OnDestroy, AfterViewInit {
  @Input() iframeUrl;
  @Input() game;
  @Input() backToLobbyUrl;

  @ViewChild('iframeGamePlayContainer', { static: true }) iframeGamePlayContainer: ElementRef;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  private orientattionHasChanged: boolean;
  private body = document.getElementsByTagName('body')[0];
  private windowViewportHeight = window.innerHeight;
  public imagesBaseUrl = environment.imagesBaseUrl;
  public clientName = environment.clientName;
  public isIos = this.helpersService.iOS();
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isSafari = this.helpersService.isSafari();
  public isLoggedIn = this.localStorageService.isLoggedIn();
  public showOverlaySwipeContainer: boolean;
  public jackpots;
  public orientation;
  public showDeviceRotationOverlay: boolean;
  public isIpad = this.helpersService.isIPad();
  public jackpotStyleType;
  public hasBonus = false;
  private isGameStarted: boolean;
  public isFullScreen = false;
  public CLIENT_NAMES = CLIENT_NAMES;
  public isCroClient = CLIENT_NAMES.ADMIRAL_CROATIA === environment.clientName;
  // public totalBonus = 0;

  public player;
  public currency = '';
  private playerObservable: Observable<any>;
  private player$: Subscription;

  public balanceInfo: BalanceInfo;
  private balanceInfoObservable: Observable<any>;
  private orientation$: Subscription;
  private balanceInfo$: Subscription;
  private bonusServiceInterval$: Subscription;
  private webSocket$: Subscription;

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
    private helpersService: HelpersService,
    private lobbyService: LobbyService,
    private toasterService: ToasterService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private jackpotService: JackpotService,
    private title: Title,
    private appStateFacadeService: AppStateFacadeService,
    private bonusService: BonusService,
    private eventListenerService: EventListenerService
  ) {}

  ngOnInit() {
    this.setupStore();
    this.getInitOrientation();
    this.setPlayerSubscription();
    this.setBalanceInfoSubscription();
    this.getInitialJackpotsByGame(this.game.id);
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA) {
      this.title.setTitle(`${this.game.name} - Admiral casino`);
      if (this.isLoggedIn) {
        this.setBonusSubscription();
      }
    }
    if (this.isIos && !this.isSafari) {
      window.scrollTo(0, -this.body.clientHeight);
    }
    // check for the mobile bar appearance
    this.showOverlaySwipeContainer =
      this.windowViewportHeight < this.iframeGamePlayContainer.nativeElement.clientHeight;

    if (!this.isIos && !this.showOverlaySwipeContainer && this.isMobile) {
      this.requestFullScreen();
    }

    // For Religa games, rewrite redirect callback.
    // Reason: homeUrl is undefined and we want to navigate user to page from which game started.
    if (this.game.providerName === 'Religa') {
      window['alg'].redirect = (homeUrl: any) => {
        this.router.navigate([this.backToLobbyUrl]);
      };
    }
  }

  ngAfterViewInit(): void {
    this.iframe.nativeElement.referrerPolicy = 'origin';
    if (this.game.vendorName === 'EGT Digital') {
      this.iframe.nativeElement.allow = 'autoplay *; screen-wake-lock *; fullscreen *';
    }
    // if this is Ios device remove class from dialog if it is not in full screen to be able swipe up for full screen
    if (this.isIos) {
      if (this.showOverlaySwipeContainer) {
        this.body.classList.remove('modal-open');
      } else {
        this.isGameStarted = true;
        this.iframe.nativeElement.src = this.iframeUrl;
      }
    }

    // case for cro client because this component is used for playing games on desktop for this client
    if (environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA && !this.isMobile) {
      this.isGameStarted = true;
      this.iframe.nativeElement.src = this.iframeUrl;
    }
  }

  // return scroll position
  ngOnDestroy() {
    window.scrollTo(0, 0);
    this.orientation$.unsubscribe();
    this.player$.unsubscribe();
    this.balanceInfo$.unsubscribe();
    if (this.bonusServiceInterval$) {
      this.bonusServiceInterval$.unsubscribe();
    }
    this.webSocket$?.unsubscribe();
  }

  private getInitOrientation(): void {
    if (window.matchMedia('(orientation: portrait)').matches) {
      this.orientation = 0; // portrait
      this.jackpotStyleType = 'DYNAMIC_VERTICAL';
      if (this.isIos && this.game.forceLandscape) {
        this.showDeviceRotationOverlay = true;
      }
    } else {
      this.orientation = 1; // landscape
      this.jackpotStyleType = 'DYNAMIC_HORIZONTAL';
    }

    this.orientation$ = this.eventListenerService.orientationChange$.subscribe({
      next: response => {
        this.orientation = response;
        if (this.orientation === 0) {
          this.jackpotStyleType = 'DYNAMIC_VERTICAL';
        } else {
          this.jackpotStyleType = 'DYNAMIC_HORIZONTAL';
        }
        // In case game is set to play in landscape mode (on iOS devices),
        // and device is currently in portrait mode, then show rotate-device-to-landscape-mode image overlay.
        if (this.isIos && this.game.forceLandscape) {
          this.showDeviceRotationOverlay = this.orientation === 0;
        }
      },
    });
  }

  // setup store
  private setupStore(): void {
    this.playerObservable = this.appStateFacadeService.getPlayerDataObservable();
    this.balanceInfoObservable = this.appStateFacadeService.getBalanceInfo();
  }

  private setPlayerSubscription(): void {
    this.player$ = this.playerObservable.subscribe(playerData => {
      this.currency = playerData?.currency;
    });
  }

  // set balance info subscription
  private setBalanceInfoSubscription(): void {
    this.balanceInfo$ = this.balanceInfoObservable.subscribe(balanceState => {
      this.balanceInfo = balanceState.playerBalanceInfo || new BalanceInfo({});
    });
  }

  // window listener for orientationchange
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.orientattionHasChanged = true;
  }

  // window listener for resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.isIos && document.fullscreenElement !== null) {
      return;
    }
    // check if the windowLastHeight is bigger than new event resize height
    // Since this component is also used on desktop devices for the CRO client,
    // "isMobile" check is required because the Overlay should not be displayed for desktop devices.
    if (this.windowViewportHeight > event.target.innerHeight && this.isMobile) {
      // in this case status bar appeared and we need to show the overlay container
      this.showOverlaySwipeContainer = true;
      // as soon as this happens we scroll the page to top to reset scroll position for user to be able to scroll
      if (this.isIos) {
        this.body.classList.remove('modal-open');
      }
    } else {
      this.showOverlaySwipeContainer = false;

      if (!this.isGameStarted) {
        this.isGameStarted = true;
        this.iframe.nativeElement.src = this.iframeUrl;
        // this.iframe.nativeElement.contentWindow.location.replace(this.iframeUrl);
      }
    }

    // here we update the value of windowViewportHeight
    this.windowViewportHeight = event.target.innerHeight;

    // this is an extra case because orientation change event comes after the resize event
    if (this.isIos) {
      setTimeout(() => {
        if (this.orientattionHasChanged) {
          // so if the iframe container height is lower than window height that means that bar has appeared
          this.showOverlaySwipeContainer =
            this.windowViewportHeight < this.iframeGamePlayContainer.nativeElement.clientHeight;
          if (this.isIos) {
            if (this.showOverlaySwipeContainer) {
              this.body.classList.remove('modal-open');
            }
          }
          // we reset the orientation change flag
          this.orientattionHasChanged = false;
        }
        // added timeout on ios device when the game is opening in chrome on orientation change event
      }, 400);
    }
  }

  @HostListener('window:message', ['$event'])
  onProviderMessage(event) {
    // Oryx application in iframe case when opening next game
    if (event.data.wpgaction === 'openGame' && this.game.providerName === GLOBAL_VARIABLES.ORYX) {
      const newGameUrl = this.router.url.split('/');
      newGameUrl.shift();
      newGameUrl.pop();
      this.lobbyService
        .getLobbyGameByExternalId(event.data.data.gameChannels.mobile.gameCode)
        .subscribe(game => {
          newGameUrl.push(game.id);
          this.router.navigate([newGameUrl.join('/')]).then(() => {
            window.location.reload();
          });
        });
    }

    // Pragmatic aplication in iframe case when closing aplication from game provider
    if (typeof event.data === 'string' && this.game.providerName === GLOBAL_VARIABLES.PRAGMATIC_PLAY) {
      this.closeDialog();
    }

    // Aplication in iframe case when closing aplication from game provider
    if (event.data === GLOBAL_VARIABLES.HOST_APLICATION_IN_IFRAME) {
      this.closeDialog();
    }

    if (this.game.providerName === 'PlayNGo' && event.data.type === 'backToLobby') {
      this.router.navigate([this.backToLobbyUrl]);
    }
  }

  // close dialog
  public closeDialog(): void {
    this.activeModal.close();
    this.closeFullScreen();
  }

  // set game as favoite or remove it from favorite
  public setFavoriteGame(): void {
    // if user is not logged in send him to login page and close dialog
    if (!this.isLoggedIn) {
      this.toasterService.showError(this.translate.instant('LOGIN_FIRST_GAME_AS_FAVORITE'));
      this.closeDialog();
      this.router.navigate(['/login']);
      return;
    }

    const game: LobbyFavoriteGame = {
      gameId: this.game.id,
      isFavorite: !this.game.favorite,
    };
    this.lobbyService.setFavoriteGame(game).subscribe({
      next: data => {
        this.game.favorite = !this.game.favorite;
      },
      error: error => {
        this.closeDialog();
        this.toasterService.showError(this.translate.instant('UNABLE_TO_SET_FAVORITE_GAME'));
      },
    });
  }

  // request full screen
  public requestFullScreen() {
    this.helpersService.requestFullScreen();
    setTimeout(() => {
      this.showOverlaySwipeContainer = false;
      // set iframe url for load the game when user enter fullscreen
      if (!this.isGameStarted) {
        this.isGameStarted = true;
        this.iframe.nativeElement.src = this.iframeUrl;
        // this.iframe.nativeElement.contentWindow.location.replace(this.iframeUrl);
      }
    }, 0);
  }

  // close full screen
  public closeFullScreen() {
    this.helpersService.closeFullScreen();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.isIos && this.isSafari && !this.showOverlaySwipeContainer) {
      // Ios on Safari
      if (
        // on safari ios scroll to top if scroll hit bottom,
        // bcs when we hit bottom and overlay is preent, scroll top doesn't work
        window.innerHeight + event.srcElement.scrollingElement.scrollTop ===
        event.target.body.scrollHeight
      ) {
        window.scrollTo(0, 0);
      }
    }
  }

  // get initial set of jackpots if game have jackpots
  private getInitialJackpotsByGame(gameId) {
    this.jackpotService.getActiveJackpotsByGame(gameId).subscribe(data => {
      if (data.length) {
        this.jackpots = data;
      }
    });
  }

  private setBonusSubscription(): void {
    this.bonusService.getGameBonusAmount(this.game.id).subscribe(data => {
      this.hasBonus = data.bonus > 0;
    });

    const webSocketObservable = this.appStateFacadeService.getSocketActionObservable();
    this.webSocket$ = webSocketObservable.pipe(filter(data => !!data)).subscribe(data => {
      // If bonus widget is not opened and bonus notification has arrived, open bonus widget.
      if (!this.hasBonus && data.type === SOCKET_ACTION_TYPES.BONUS_NOTIFICATION) {
        this.hasBonus = true;
      }
      // If bonus widget is already opened and, meanwhile, bonus is redeemed or player has no more funds, close bonus widget.
      if (
        this.hasBonus &&
        (data.type === SOCKET_ACTION_TYPES.BONUS_REDEMPTION || data.type === SOCKET_ACTION_TYPES.NO_FUNDS)
      ) {
        this.hasBonus = false;
      }
    });
  }

  public toggleFullscreen(): void {
    if (this.isFullScreen) {
      this.closeFullScreen();
    }

    if (!this.isFullScreen) {
      this.requestFullScreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event']) fullScreen(event) {
    this.isFullScreen = !this.isFullScreen;
  }

  public onIframeLoad(): void {
    if (this.game.providerName === 'PlayNGo' && this.isGameStarted) {
      this.iframe.nativeElement.contentWindow.postMessage(
        { messageType: 'addEventListener', eventType: 'backToLobby' },
        '*'
      );
    }
  }
}
