import { environment } from '../../../environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { AssecoChatService } from './asseco-chat.service';

export class HelpersChatInstanceService extends AssecoChatService {
  constructor() {
    super();
  }

  // is chat enabled by client
  public chatEnabled(): boolean {
    return (
      environment.clientName === CLIENT_NAMES.ADMIRAL_SERBIA ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_PINNBET ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_MONTENEGRO ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_MD_SHOP ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_NIGERIA ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_BOSNIA ||
      environment.clientName === CLIENT_NAMES.ADMIRAL_UGANDA
    );
  }

  // show chat window by client
  public openChat(renderer, appStateFacadeService): void {
    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_MONTENEGRO:
      case CLIENT_NAMES.ADMIRAL_MD_SHOP:
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
      case CLIENT_NAMES.ADMIRAL_BOSNIA:
        if ((window as any).HelpCrunch) {
          (window as any).HelpCrunch('openChat');
        }
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
      case CLIENT_NAMES.ADMIRAL_SERBIA:
        if ((window as any).embedded_svc) {
          // CLICK ON CHAT BTN TO ACTIVATE CHAT WINDOW
          const chatElement = document.getElementsByClassName(
            'helpButtonEnabled uiButton'
          ) as HTMLCollectionOf<HTMLElement>;

          if (chatElement.length) {
            chatElement[0].click();
          }

          // SHOW CHAT WINDOW
          const chatWindow = document.getElementsByClassName(
            'dockableContainer'
          ) as HTMLCollectionOf<HTMLElement>;

          if (chatWindow.length) {
            renderer.setStyle(chatWindow[0], 'display', 'flex');
            appStateFacadeService.setChatWindowOpen(true);
          }
        }
        break;
      case CLIENT_NAMES.ADMIRAL_UGANDA:
        if ((window as any).Tawk_API) {
          (window as any).Tawk_API.maximize();
          appStateFacadeService.setChatWindowOpen(true);
        }
        break;
    }
  }

  // hide chat window by client
  public closeChat(renderer, appStateFacadeService): void {
    switch (environment.clientName) {
      case CLIENT_NAMES.ADMIRAL_MONTENEGRO:
      case CLIENT_NAMES.ADMIRAL_MD_SHOP:
      case CLIENT_NAMES.ADMIRAL_NIGERIA:
      case CLIENT_NAMES.ADMIRAL_BOSNIA:
        if ((window as any).HelpCrunch) {
          (window as any).HelpCrunch('closeChat');
        }
        break;
      case CLIENT_NAMES.ADMIRAL_PINNBET:
      case CLIENT_NAMES.ADMIRAL_SERBIA:
        if ((window as any).embedded_svc) {
          // HIDE CHAT WINDOW
          const chatWindow = document.getElementsByClassName(
            'dockableContainer'
          ) as HTMLCollectionOf<HTMLElement>;

          if (chatWindow.length) {
            renderer.setStyle(chatWindow[0], 'display', 'none');
            appStateFacadeService.setChatWindowOpen(false);
          }
        }
        break;
      case CLIENT_NAMES.ADMIRAL_UGANDA:
        if ((window as any).Tawk_API) {
          (window as any).Tawk_API.minimize();
          appStateFacadeService.setChatWindowOpen(false);
        }
        break;
    }
  }
}
