<ng-container *ngIf="isFooterVisible">
  <div class="footer-container">
    <div class="container">
      <div class="row" *ngFor="let section of footerCmsData">
        <div class="container">
          <ng-container *ngIf="section.sectionName">
            <div  class="row text-bold">
                {{section.sectionName | uppercase}}
            </div>
            <hr class="hr">
          </ng-container>
          <div class="row d-flex {{section.horizontalAlignment}} {{section.verticalAlignment}} c_section-style"
               [ngStyle]="{'row-gap': section.rowGap + 'px', 'column-gap': section.columnGap + 'px' }"
          >
            <ng-container *ngFor="let sectionItem of section.sectionItems">
              <ng-container *ngTemplateOutlet="sectionItemTemplate; context: { sectionItem: sectionItem }"></ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #sectionItemTemplate let-sectionItem="sectionItem">
  <div class="d-flex c_section-item {{sectionItem.flexDirection}} {{sectionItem.horizontalAlignment}} {{sectionItem.verticalAlignment}} "
       [ngStyle]="{'width': getDynamicStyles(sectionItem) + '%', 'row-gap': sectionItem.rowGap + 'px', 'column-gap': sectionItem.columnGap + 'px' }"
  >
    <ng-container *ngTemplateOutlet="assetItemsTemplate; context: {
      items: sectionItem.items,
      flexDirection: sectionItem.flexDirection,
      horizontalAlignment: sectionItem.horizontalAlignment,
      verticalAlignment: sectionItem.verticalAlignment
     }"></ng-container>
  </div>
</ng-template>

<ng-template  #assetItemsTemplate let-items="items" let-flexDirection="flexDirection" let-horizontalAlignment="horizontalAlignment" let-verticalAlignment="verticalAlignment">
  <div class="w-100" *ngFor="let item of items">
    <div class="c_asset-item">
      <ng-container>
        <a class="d-flex {{horizontalAlignment}} align-items-center" (click)="navigate(item)">
          <div *ngIf="item.icon" class="material-symbols-outlined">{{ item.icon }}</div>
          <img *ngIf="item.image" class="c_asset-image" [src]="baseServerUrl + item.image.path"
               alt="{{item.image.fileName}}" title="{{item.image.fileName}}"/>
          <div *ngIf="item.text && !item.localizationKey" class="w-100 ql-editor" [innerHTML]="item.text"></div>
          <div *ngIf="item.localizationKey" class="w-100 ql-editor">{{item.localizationKey | translate}}</div>
        </a>
      </ng-container>
    </div>
  </div>
</ng-template>


