import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { AppInjector } from './app-injector';
import { environment } from 'src/environments/environment';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  public showAlert = new Subject<any>();

  constructor() {}

  // show error message to user
  public showError(message?: string) {
    const translate = AppInjector.get(TranslateService);
    const alert = {
      class: environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA ? 'alert-danger-cro' : 'alert-danger',
      message: message || translate.instant('TOASTER_ERROR'),
    };
    this.showAlert.next(alert);
  }

  /* show error message to usertranslating key. This implementation
  is needed because of circular dependency in error interceptor */
  public showErrorTranslatingKey(key?: string) {
    const translate = AppInjector.get(TranslateService);
    const alert = {
      class: environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA ? 'alert-danger-cro' : 'alert-danger',
      message: translate.instant(key),
    };
    this.showAlert.next(alert);
  }

  // show success message to user
  public showSuccess(message: string) {
    const alert = {
      class: environment.clientName === CLIENT_NAMES.ADMIRAL_CROATIA ? 'alert-success-cro' : 'alert-success',
      message,
    };
    this.showAlert.next(alert);
  }

  // get alert message
  public getAlertMessage(): Observable<any> {
    return this.showAlert.asObservable();
  }
}
