<footer id="footer" *ngIf="isFooterVisible" class="print-hide">
  <div class="container" [ngClass]="[isMobile ? 'py-6' : 'py-4']">
    <!--    Accordion-->
    <div *ngIf="showAccordionInfo">
      <ng-container *ngFor="let accordionItem of accordionItems">
        <hr class="mb-3 mt-0" />
        <div
          class="pointer c_footer-accordion-header container-padding"
          (click)="openAccordionInformation(); $event.stopPropagation()"
        >
          <div class="c_footer-accordion-title">{{ accordionItem.title | translate }}</div>
          <div class="c_display-flex-vertical-center">
            <div
              class="dropdownArrow"
              [ngClass]="{ 'dropdownArrow--is-active': accordionDropDownOpen }"
            ></div>
          </div>
        </div>
        <div class="container-padding">
          <div
            @heightAnimation
            class="c_footer-accordion-container"
            *ngIf="accordionDropDownOpen"
            [innerHTML]="accordionItem.description | translate"
          ></div>
        </div>
        <hr class="mb-3 mt-3" />
      </ng-container>
    </div>

    <!-- COLUMNS -->
    <div
      class="row justify-content-left text-left footer-column"
      *ngIf="footer?.columns && footer.columns.length"
    >
      <div class="col-lg-2 text-white" *ngFor="let column of footer.columns">
        <div *ngIf="column?.items.length">
          <span class="text-uppercase">
            <h5 class="mb-1">{{ column?.title | translate }}</h5>
          </span>
          <hr class="mb-3 mt-0" />
          <div *ngFor="let item of column.items">
            <a class="row col-lg-12 text-white u_line-height-1 u_font-size-14px" [ngClass]="{ 'cky-banner-element': item.code === 'CHANGE_COOKIE_PREFERENCES' }" (click)="navigate(item)">
              <p>{{ item?.label | translate }}</p>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 text-white">
        <!-- CERTS -->
        <div class="row" *ngIf="footer?.certs && footer.certs.length">
          <div class="col-12" *ngFor="let cert of footer.certs">
            <!-- Quick fix certs -->
            <div class="center pb-3" *ngIf="!isMobile">
              <div class="text-left d-flex">
                <div class="d-flex align-items-center">
                  <span
                    appAppendImage
                    [imgPath]="cert.logo"
                    [className]="'certImgDimension'"
                    [loadingStrategy]="'async'"
                  >
                  </span>
                </div>
                <div class="text-left pl-2">
                  <a class="col-lg-12 text-white u_line-height-1 u_font-size-14px" (click)="navigate(cert)">
                    <div>{{ cert?.label | translate }}</div>
                  </a>
                </div>
              </div>
            </div>

            <div class="d-flex" *ngIf="isMobile">
              <div class="center pb-3">
                <ng-container
                  appAppendImage
                  [parentNode]="true"
                  [imgPath]="cert.logo"
                  [className]="'iconImage certImgDimension float-left'"
                  [loadingStrategy]="'async'"
                >
                </ng-container>
              </div>
              <div class="center text-left pl-2">
                <a class="text-white" (click)="navigate(cert)">
                  <div>{{ cert?.label | translate }}</div>
                </a>
              </div>
            </div>
            <!-- Quick fix certs end-->
          </div>
        </div>
      </div>
    </div>

    <!-- ICONS -->
    <div
      class="row justify-content-left text-left icons-wrapper"
      *ngIf="footer?.icons && footer.icons.length"
    >
      <small class="col-lg-12 text-white center" *ngFor="let iconHolder of footer.icons">
        <div class="text-left">
          <span (click)="navigate(iconHolder)" class="text-uppercase pointer">
            <h5 class="mb-1">{{ iconHolder?.title | translate }}</h5>
          </span>
        </div>
        <hr class="hr mb-3 mt-0" />
        <small *ngFor="let icon of iconHolder?.items">
          <a class="col-lg-12 text-light" (click)="navigate(icon)">
            <ng-container
              appAppendImage
              [parentNode]="true"
              [imgPath]="icon.logo"
              [className]="'iconImage footerImg'"
              [loadingStrategy]="'async'"
            >
            </ng-container>
            <span *ngIf="!icon.logo">{{ icon.label | translate }}</span>
          </a>
        </small>
      </small>
    </div>

    <!-- LOGO -->
    <div class="row justify-content-center text-left pt-4" *ngIf="footer?.backgroundImg">
      <ng-container
        appAppendImage
        [parentNode]="true"
        [className]="'logo'"
        [imgPath]="footer.backgroundImg"
        [loadingStrategy]="'async'"
      >
      </ng-container>
    </div>

    <!-- DESCRIPTIONS -->
    <div
      class="row justify-content-center text-center"
      [ngClass]="{ 'pt-4': footer?.columns || footer?.icons || footer.certs }"
      *ngIf="footer?.footerDescription && footer.footerDescription.length"
    >
      <small class="col-lg-8" *ngFor="let description of footer?.footerDescription">
        <p class="footer-description-text-style">{{ description.label | translate }}</p>
      </small>
    </div>

    <!-- VERSION -->
    <div class="row justify-content-center text-center">
      <small class="col-lg-8">
        <p class="footer-description-text-style">{{ 'VERSION' | translate : { version: version } }}</p>
      </small>
    </div>
  </div>
</footer>
