export enum MENU_TYPES {
  INTERNAL_LINK = 'INTERNAL_LINK',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  EXTERNAL_LINK_SUBMENU = 'EXTERNAL_LINK_SUBMENU',
  MENU_SUBMENU = 'WRAPPER',
  MENU_DIALOG = 'MENU_DIALOG',
  DIALOG = 'DIALOG',
  DIALOG_SUBMENU = 'DIALOG_SUBMENU',
  IFRAME = 'IFRAME',
  TEXT_LINK_INTERNAL = 'TEXT_LINK_INTERNAL',
  INTERNAL_LINK_SUBMENU = 'INTERNAL_LINK_SUBMENU',
}

export enum FOOTER_NAVIGATION_TYPES {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}
