<div class="gameContainer pinnbet-game-container">
  <div class="gameImgWrapper rounded-lg" [ngClass]="{ large: !displayGameInfo }">
    <!-- GAME ICON IMAGE -->
    <div #gameImage class="game-image rounded" (click)="navigateToGamePlay()"></div>
    <div *ngIf="!displayGameInfo && gamesCount > 1" class="game-number">{{ gameIndex + 1 }}</div>
    <div *ngIf="displayGameInfo" class="row no-gutters align-items-center">
      <div class="col">
        <p class="m-0 text-truncate">{{ game.name }}</p>
        <p class="m-0">{{ game.providerName }}</p>
      </div>
      <i
        class="pointer favorite-icon col-auto"
        inlineSVG="assets/icons/info-circle.svg"
        (click)="openGameInfoDialog()"
      ></i>
    </div>
    <!-- NEW LABEL ICON -->
    <div class="game-label-wrapper" [ngSwitch]="language">
      <div *ngSwitchCase="'sr'">
        <div class="game-label novo-label" *ngIf="game.newGame"></div>
      </div>
      <div *ngSwitchCase="'en'">
        <div class="game-label new-label" *ngIf="game.newGame"></div>
      </div>
      <div *ngSwitchDefault>
        <div class="game-label novo-label" *ngIf="game.newGame"></div>
      </div>
    </div>

    <!-- BADGES -->
    <div class="game-badges-wrapper">
      <div class="game-badge hot-icon" *ngIf="game.popular"></div>
      <div class="game-badge tournament-icon" *ngIf="game.tournament"></div>
      <div class="game-badge jackpot-icon" *ngIf="game.jackpot"></div>
    </div>
  </div>
</div>
