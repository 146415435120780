<div class="box">
  <header class="pb-2">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="h4 mb-0 mb-md-2 text-uppercase">{{'DEPOSIT_STATUS' | translate}}</div>
      </div>
      <div class="col-3 text-right">
        <div class="close">
          <img *ngIf="clientName !== clientNames.ADMIRAL_PINNBET"
            [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'" (click)="closeModal()" />
          <div *ngIf="clientName === clientNames.ADMIRAL_PINNBET" class="u_pointer" (click)="closeModal()"
            [inlineSVG]="'assets/icons/close_cross.svg'"></div>
        </div>
      </div>
    </div>
    <div class="pl-3 pr-3 h3 text-center">
      <div class="text-uppercase status-success" *ngIf="depositStatus === 'SUCCESS'">
        {{'DEPOSIT_STATUS_SUCCESSFUL' | translate}}
      </div>
      <div class="text-uppercase status-error" *ngIf="depositStatus === 'ERROR' || depositStatus === 'CANCEL'">
        {{'DEPOSIT_STATUS_UNSUCCESSFUL' | translate}}
      </div>
    </div>
  </header>
  <!-- SUCCESS STATUS -->
  <div class="px-5 pt-3" *ngIf="depositStatus === 'SUCCESS'">
    <div class="row mb-2">
      <div class="col-4">
        <span class="text-muted">{{allsecureDeposit?.amount | number: '1.2-2' | currency: allsecureDeposit?.currencyIso
          }}</span>
      </div>
      <div class="col-8 text-right">
        <span class="text-light">{{'SUCCESS_DEPOSITET_TO_ACCOUNT' | translate}}</span>
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-6">
        <span class="text-muted">{{ 'ALLSECURE_DEPOSIT_STATUS_REFERENCE_NUMBER' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ allsecureDeposit?.txnId }}</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <span class="text-muted">{{ 'ALLSECURE_DEPOSIT_STATUS_AMOUNT' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ allsecureDeposit?.amount | number: '1.2-2' | currency: allsecureDeposit?.currencyIso
          }}</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <span class="text-muted">{{ 'ALLSECURE_DEPOSIT_STATUS_CARD_TYPE' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ allsecureDeposit?.params.cardType | uppercase }} ****{{
          allsecureDeposit?.params.lastFourDigits
          }}</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <span class="text-muted">{{ 'ALLSECURE_DEPOSIT_STATUS_AUTH_BANK_CODE' | translate }}</span>
      </div>
      <div class="col-8 text-right">
        <span class="text-light">{{ allsecureDeposit?.params.authCode }}</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <span class="text-muted">{{ 'DEPOSIT_STATUS_TRANSACTION_DATE' | translate }}</span>
      </div>
      <div class="col-6 text-right">
        <span class="text-light">{{ allsecureDeposit?.createdOn | date: 'd MMM, y | HH:mm' }}</span>
      </div>
    </div>
  </div>
  <!-- ERROR/CANCEL STATUS -->
  <div class="px-5 pt-3" *ngIf="depositStatus === 'ERROR' || depositStatus === 'CANCEL'">
    <div class="row mb-2">
      <div class="col-12 text-center">{{allsecureDeposit?.params.message}}</div>
    </div>
  </div>
  <hr />
  <div class="py-1 text-center">
    <div (click)="closeModal()" class="btn btn-outline-light px-6">OK</div>
  </div>
</div>