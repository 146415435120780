<div
  [ngClass]="{
    'no-slides': imagesArray.length < 2,
    'pinnbet-slider': environment.clientName === clientNames.ADMIRAL_PINNBET
  }"
  class="carousel-wrapper"
  *ngIf="displayCarousel && enableCarousell"
>
  <div
    id="carousell-container"
    #carouselContainer
    (mouseleave)="stopSliderAutoplay()"
    (mouseenter)="stopSliderAutoplay()"
    (click)="navigateOnImgClick(imagesArray[currentSlideIndex])"
  >
    <div class="slideshow">
      <app-slideshow
        #slideshow
        [autoPlay]="enableAutoplay"
        [disableSwiping]="!isMobile || imagesArray?.length === 1"
        [backgroundSize]="'100% 100%'"
        [imageUrls]="imagesArray"
        [isButtonAllowed]="false"
        [areDotsIncluded]="environment.clientName === clientNames.ADMIRAL_PINNBET"
        (IndexChanged)="slideChange($event)"
      ></app-slideshow>
    </div>
    <!-- OTHER CLIENTS -->
    <div
      *ngIf="environment.clientName !== clientNames.ADMIRAL_PINNBET && imagesArray.length !== 1 && !isMobile"
      class="display-hide-arrows"
    >
      <!-- LEFT ARROW -->
      <div class="arrow-container prev" [ngClass]="{ pointer: imagesArray[currentSlideIndex].buttonLink }">
        <div
          class="icon icon-chevron-left arrow prev"
          (click)="onSwipeLeft(); $event.stopPropagation()"
        ></div>
      </div>
      <!-- RIGHT ARROW -->
      <div class="arrow-container next" [ngClass]="{ pointer: imagesArray[currentSlideIndex].buttonLink }">
        <div
          class="icon icon-chevron-right arrow next"
          (click)="onSwipeRight(); $event.stopPropagation()"
        ></div>
      </div>
    </div>
    <!-- PINNBET -->
    <div
      *ngIf="environment.clientName === clientNames.ADMIRAL_PINNBET && imagesArray.length !== 1 && !isMobile"
      class="display-hide-arrows"
    >
      <!-- LEFT ARROW -->
      <div class="arrow-container prev" [ngClass]="{ pointer: imagesArray[currentSlideIndex].buttonLink }">
        <div
          class="icon icon-chevron-left arrow-pinnbet prev"
          (click)="onSwipeLeft(); $event.stopPropagation()"
        ></div>
      </div>
      <!-- RIGHT ARROW -->
      <div class="arrow-container next" [ngClass]="{ pointer: imagesArray[currentSlideIndex].buttonLink }">
        <div
          class="icon icon-chevron-right arrow-pinnbet next"
          (click)="onSwipeRight(); $event.stopPropagation()"
        ></div>
      </div>
    </div>
  </div>
</div>
