import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PopupAllowanceStateService {
  // Popup state initial value
  private popupAllowanceStateValue = true;

  // Observable source
  public popupAllowanceStateSource = new BehaviorSubject<boolean>(this.popupAllowanceStateValue);

  // Observable state
  public popupAllowanceState$ = this.popupAllowanceStateSource.asObservable();

  public updatePopupAllowanceValue(allowance: boolean) {
    this.popupAllowanceStateSource.next(allowance);
  }
}
