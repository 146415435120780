import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TournamentService } from '@services/tournament.service';
import { AppStateFacadeService } from '@state/app-state.facade';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnChanges {
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer: ElementRef;
  @Input() tournament;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public player = this.appStateFacadeService.getPlayerData();
  public foundPlayerIndexInitialRankList: number;
  public playerCurrentPositionRankList;
  public hideDots = false;
  public isLoading = true;
  public rankList;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private tournamentService: TournamentService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tournament'].currentValue !== undefined) {
      this.getRankList(this.tournament.tournamentId);
    }
  }

  private getRankList(tournamentId) {
    this.tournamentService.getRankList(tournamentId).subscribe(data => {
      if (data.records && data.records.length) {
        this.checkForLoggedPlayer(data.records);
      } else {
        this.isLoading = false;
      }
    });
  }

  private checkForLoggedPlayer(rankList) {
    if (this.player) {
      const foundPlayerInInitialRankList = this.findPlayerInRankList(rankList);
      if (foundPlayerInInitialRankList) {
        // if we got player we mark him on initial rank list
        this.rankList = rankList;
        this.foundPlayerIndexInitialRankList = rankList.indexOf(foundPlayerInInitialRankList);
        setTimeout(() => {
          // and here we scroll to our found and highligted player
          const tableRowHeight = document.getElementsByTagName('td')[0].clientHeight;
          this.scrollableContainer.nativeElement.scrollTo({
            top: tableRowHeight * this.foundPlayerIndexInitialRankList,
            behavior: 'smooth',
          });
        }, 500);
        this.hideDots = true;
        this.isLoading = false;
      } else {
        // this is the case where we dont find player in our initial rank list
        this.getRankListForPLayer(this.tournament.tournamentId, this.player.id, rankList);
      }
    } else {
      // case where we do not have logged player we just show initial rank list
      this.rankList = rankList;
      this.isLoading = false;
    }
  }

  // find player in rank list
  private findPlayerInRankList(rankList) {
    return rankList.find(player => player.playerId === this.player.id);
  }

  // get rank list for player
  private getRankListForPLayer(tournamentId, playerId, initialRankList) {
    this.tournamentService.getRankListForPLayer(tournamentId, playerId, 1, 1).subscribe(data => {
      if (data.length) {
        // if there is a player eligible for tournament show first 3 positions and player current position
        this.playerCurrentPositionRankList = data;
        this.rankList = initialRankList.slice(0, 3);
      } else {
        // if user is not
        this.hideDots = true;
        this.rankList = initialRankList;
      }
      this.isLoading = false;
    });
  }
}
