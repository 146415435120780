import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CarouselImage } from '../../state/interfaces/CarouselImage';
import { AppStateFacadeService } from '../../state/app-state.facade';
import { ConfigData } from '@models/config-data/config-data.model';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { AppHtmlElementsService } from '@services/app-html-elements.service';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('carouselContainer') carouselContainer: ElementRef;
  @ViewChild('slideshow') slideshow: any;
  @Input() carouselData = [];
  public displayCarousel: boolean;
  public imagesArray: CarouselImage[] = [];
  public enableCarousell = false;
  public enableAutoplay = false;
  public defaultAppUrl: string;
  public environment = environment;
  public clientNames = CLIENT_NAMES;
  private configDataObservable: Observable<any>;
  private configData$: Subscription;
  public currentSlideIndex = 0;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private appHtmlElementsService: AppHtmlElementsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.configDataObservable = this.appStateFacadeService.getConfigData();
    this.setConfigDataSubscription();
  }

  ngAfterViewInit() {
    this.hideOnNoSlides();
    this.appHtmlElementsService.headerCarouselElement = this.carouselContainer?.nativeElement;
  }

  ngOnDestroy(): void {
    this.configData$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['carouselData']) {
      if (!this.carouselData.length) {
        this.enableCarousell = false;
        this.enableAutoplay = false;
        return;
      }
      this.enableAutoplay = !(this.carouselData.length === 1);
      this.enableCarousell = true;
      this.setImageArrayInitially();
    }
  }

  private setConfigDataSubscription(): void {
    this.configData$ = this.configDataObservable.subscribe((configData: ConfigData) => {
      if (configData) {
        this.displayCarousel = configData.displayCarousel;
      }
    });
  }

  public setImageArrayInitially(): void {
    // Reset image array because we fill it up again on login subscription change
    this.imagesArray = [];
    // Map data as an CarouselImage type of object
    for (const slide of this.carouselData) {
      switch (slide.buttonColor) {
        case 'blue':
          slide.buttonColor = 'btn-info';
          break;
        case 'orange':
          slide.buttonColor = 'btn-orange';
          break;
        case 'green':
          slide.buttonColor = 'btn-success';
          break;
        default:
          slide.buttonColor = null;
      }

      this.imagesArray.push({
        url:
          this.isMobile || window.innerWidth < 768
            ? environment.imagesBaseUrl + slide.imgSrcMobile
            : environment.imagesBaseUrl + slide.imgSrcDesktop,

        caption: slide.textTitle,
        captionDescription: slide.textBody,
        buttonLink: slide.buttonLink.includes(environment.imagesBaseUrl)
          ? slide.buttonLink.replace(environment.imagesBaseUrl, '')
          : slide.buttonLink,
        cursor: slide.buttonLink ? 'pointer' : 'default',
        buttonText: slide.buttonText,
        buttonColor: slide.buttonColor,
      });
    }
  }

  public onSwipeLeft(): void {
    if (this.imagesArray.length === 1) {
      return;
    }
    this.slideshow.onSlide(-1);
  }

  public onSwipeRight(): void {
    if (this.imagesArray.length === 1) {
      return;
    }
    this.slideshow.onSlide(1);
  }

  public hideOnNoSlides() {
    this.enableCarousell = !!this.carouselData.length && this.enableCarousell;
  }

  public stopSliderAutoplay(): void {
    if (this.imagesArray.length === 1) {
      return;
    }
    this.enableAutoplay = !this.enableAutoplay;
  }

  public closeCarousel(): void {
    this.enableCarousell = false;
    this.appStateFacadeService.setMainCarouselVisibility(false);
  }

  public slideChange(index) {
    this.currentSlideIndex = index;
  }

  public navigateOnImgClick(item) {
    const link = item.buttonLink;

    if (!link) {
      return;
    }

    if (link.startsWith('www') || link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      this.router.navigateByUrl(link);
    }
  }
}
