<div
  [style.display]="hidden ? 'none' : 'block'"
  #container
  class="slideshow-container"
  [ngClass]="{
    'no-slider': slides.length < 2 && areDotsIncluded,
    'slider-with-dots': areDotsIncluded,
    mobile: isMobile,
    'pinbet-slideshow': environment.clientName === clientNames.ADMIRAL_PINNBET
  }"
>
  <div class="position-relative">
    <a
      *ngFor="let slide of slides; index as i; trackBy: trackByFn"
      class="slides"
      [ngClass]="{ rounded: environment.clientName === clientNames.ADMIRAL_PINNBET }"
      tabindex="-1"
      title="{{ slide?.image?.title }}"
      [ngStyle]="getSlideStyle(i)"
      (click)="$event.preventDefault()"
      [class.selected]="slide?.selected"
      [class.hide-slide]="!slide?.selected && !slide?.leftSide && !slide?.rightSide"
      [class.next]="slide?.next"
      [class.left-side]="slide?.leftSide"
      [class.right-side]="slide?.rightSide"
      [class.slide-in-left]="slide?.action === 'slideInLeft'"
      [class.slide-in-right]="slide?.action === 'slideInRight'"
      [class.slide-out-left]="slide?.action === 'slideOutLeft'"
      [class.slide-out-right]="slide?.action === 'slideOutRight'"
    >
      <div class="loader" *ngIf="!slide?.loaded"></div>
      <!-- CAROUSEL GAME SLIDER -->
      <div
        class="carouselGamesContainer"
        *ngIf="slide.image.games && (environment.clientName !== clientNames.ADMIRAL_PINNBET || isMobile)"
        [ngClass]="{ mobile: isMobile }"
      >
        <app-game-slider [location]="'CASINO_CAROUSEL'" [games]="slide.image.games"></app-game-slider>
      </div>
      <!-- CAROUSEL GAME SLIDER PINNBET-->
      <div
        class="carouselGamesContainerPinnbet"
        *ngIf="slide.image.games && environment.clientName === clientNames.ADMIRAL_PINNBET && !isMobile"
        [ngClass]="{ mobile: isMobile }"
      >
        <ng-container *ngFor="let game of slide.image.games; let p = index">
          <div
            class="pointer"
            [ngStyle]="{ backgroundImage: 'url(' + environment.imagesBaseUrl + game.gameIconLocation + ')' }"
            (click)="openPinbetGameInfoDialog(game); $event.stopPropagation()"
            *ngIf="p < (isMobile ? 4 : 8)"
          >
            <div class="gameName">{{ game.name }}</div>
          </div>
        </ng-container>
        <div
          *ngIf="slide.image.type === PROMOTION_TYPE.GAMES"
          [ngClass]="{ mobile: isMobile }"
          (click)="openPromotionDetails(imageUrls[i].promotionId); $event.stopPropagation()"
          class="allGames"
        >
          {{ 'COMMON_ALL_GAMES' | translate }}
          <div [ngClass]="{ mobile: isMobile }"></div>
        </div>
      </div>
      <!-- BUTTON CONTAINER -->
      <div
        [ngClass]="{ right: buttonPosition === 'right', left: buttonPosition === 'left' }"
        *ngIf="slide.image.buttonLink && isButtonAllowed && slide.image.buttonText"
        class="button-container"
      >
        <button
          (click)="navigateByUrl($event, slide.image.buttonLink)"
          [class]="'btn ' + (slide.image.buttonColor || 'btn-success')"
        >
          {{ slide.image.buttonText }}
        </button>
      </div>

      <!-- OVERLAY IMAGE -->
      <div
        [@slideIn]="i === slideIndex ? 'slideInView' : 'outOfView'"
        class="overlayImgContainer"
        *ngIf="slide?.image?.overlayImg"
      >
        <img [src]="slide.image.overlayImg" alt="" />
      </div>

      <div *ngIf="isBottomGradientIncluded" class="gradient"></div>

      <!-- PINNBET TOURNAMENT CONTENT -->
      <div
        class="tournamentDescriptionWrapper"
        *ngIf="slide.image.tournamentTitle && environment.clientName === clientNames.ADMIRAL_PINNBET"
        [ngClass]="{ mobile: isMobile }"
      >
        <app-pinnbet-tournament-slider
          [tournament]="slide.image"
          (leaderboardOpened)="openLeaderboard()"
        ></app-pinnbet-tournament-slider>
      </div>

      <!-- TOURNAMENT CONTENT -->
      <div [ngClass]="{ mobile: isMobile }" class="tournamentDescriptionWrapper">
        <div
          *ngIf="slide.image.tournamentTitle && environment.clientName !== clientNames.ADMIRAL_PINNBET"
          class="tournamentDescriptionContainer"
        >
          <div [ngClass]="{ mobile: isMobile }" class="tournamentTitle">
            <div>
              {{ slide.image.tournamentTitle }}
            </div>
          </div>
          <div
            *ngIf="slide.image.tournamentPrizePool || slide.image.manualPrize"
            [ngClass]="{ mobile: isMobile }"
            class="tournamentPrizeFundContainer"
          >
            <img src="assets/icons/money-bag.svg" alt="Money bag icon" />
            <ng-container *ngIf="!slide.image.isManuallyAwarded">
              <span>{{
                slide.image.tournamentPrizePool | number : '1.2-2' | currency : slide.image.currency
              }}</span>
            </ng-container>
            <ng-container *ngIf="slide.image.isManuallyAwarded">
              <span>{{ slide.image.manualPrize }}</span>
            </ng-container>
          </div>
          <div *ngIf="isMobile" class="description">
            {{ slide.image.tournamentDescription }}
          </div>
          <div [ngClass]="{ mobile: isMobile }" class="labelsContainer">
            <div *ngIf="slide.image.fistPrize" [ngClass]="{ mobile: isMobile }" class="firstPrizeContainer">
              <i
                [setSVGAttributes]="
                  isMobile ? { width: '35px', height: '16px' } : { width: '35px', height: '22px' }
                "
                inlineSVG="assets/icons/cup.svg"
              ></i>
              {{ 'TOURNAMENT_FIRST_PRIZE' | translate }}
              <span>{{ slide.image.fistPrize | number : '1.2-2' | currency : slide.image.currency }}</span>
            </div>
            <div [ngClass]="{ mobile: isMobile }" class="numberOfAwardedPrizesContainer">
              <i
                [setSVGAttributes]="
                  isMobile ? { width: '35px', height: '16px' } : { width: '35px', height: '22px' }
                "
                inlineSVG="assets/icons/medal.svg"
              ></i>
              {{ 'TOURNAMENT_WIN_PLACES' | translate }} <span>{{ slide.image.numberOfAwardPrizes }}</span>
            </div>
            <div [ngClass]="{ mobile: isMobile }" class="tournamentEndTimeContainer">
              <i
                [setSVGAttributes]="
                  isMobile ? { width: '35px', height: '16px' } : { width: '35px', height: '22px' }
                "
                inlineSVG="assets/icons/clock.svg"
              ></i>
              {{ 'TOURNAMENT_ENDING' | translate }} :
              <span class="success">{{ slide.image.tournamentEndsOn | date : 'd. MMM, y HH:mm' }}</span>
            </div>
            <div [ngClass]="{ mobile: isMobile }" class="placeAndScoreContainer">
              <i
                [setSVGAttributes]="
                  isMobile ? { width: '35px', height: '22px' } : { width: '35px', height: '26px' }
                "
                inlineSVG="assets/icons/placements.svg"
              ></i>
              <div [ngClass]="{ mobile: isMobile }">
                <div>
                  {{ 'TOURNAMENT_YOUR_POSITION' | translate }}:
                  <span>{{ slide.image.playerPosition ? slide.image.playerPosition : '-' }}</span>
                </div>
                <div>
                  {{ 'TOURNAMENT_YOUR_SCORE' | translate }}
                  <span>{{ slide.image.playerPoints ? slide.image.playerPoints : '-' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="slide.image.tournamentImage && isMobile" class="tournamentImageContainer">
            <img [src]="slide.image.tournamentImage" alt="tournamentImage" />
          </div>
          <div *ngIf="slide.image.tournamentImage && isMobile" class="tournamentImageContainerTransparent">
            <img [src]="slide.image.tournamentImage" alt="tournamentImage" />
          </div>
        </div>
      </div>

      <div
        [ngClass]="{
          mobile: isMobile,
          'pinnbet-tournament-games-container':
            slide.image.tournamentGames?.length && environment.clientName === clientNames.ADMIRAL_PINNBET
        }"
        class="tournamentGamesContainer"
      >
        <img
          *ngFor="let game of slide.image.tournamentGames"
          (click)="openGameInfo($event, game, slide.image.tournamentId)"
          [src]="
            environment.imagesBaseUrl +
            (isMobile ? game.mobileGameIcon.location || game.gameIcon.location : game.gameIcon.location)
          "
          alt="Open game icon"
        />
        <p
          (click)="navigateByUrl($event, 'tournament/' + slide.image.tournamentId + '/informations')"
          *ngIf="
            slide.image.tournamentGames?.length &&
            environment.clientName === clientNames.ADMIRAL_PINNBET &&
            isMobile
          "
          class="show-all-games text-right p-0 mt-1 mb-1 col-12"
        >
          {{ 'PINNBET_SHOW_ALL_GAMES' | translate }}
        </p>
      </div>

      <div
        *ngIf="slide.image.tournamentId && environment.clientName !== clientNames.ADMIRAL_PINNBET"
        [ngClass]="{ mobile: isMobile }"
        (click)="openLeaderboard($event)"
        class="leaderboardIconContainer"
      >
        <img src="assets/icons/leaderboard.svg" alt="leaderboard" />
      </div>

      <!-- END OF TOURNAMENT CONTENT -->
    </a>
  </div>
  <!-- SLIDES -->
  <div
    [ngClass]="{ mobile: isMobile }"
    (click)="$event.stopPropagation()"
    *ngIf="slides.length && slides.length > 1 && areDotsIncluded"
    class="dotsContainer"
  >
    <div
      (click)="goToSlide(i)"
      [ngClass]="{ active: slideIndex === i, mobile: isMobile }"
      *ngFor="let slide of slides; let i = index"
      class="dot"
    ></div>
  </div>

  <div
    (click)="onSlide(1); $event.stopPropagation()"
    *ngIf="isMobile && isSwipeArrowIncluded && slides.length > 1"
    class="swipeArrow"
  >
    <div></div>
  </div>
</div>
