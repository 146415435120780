<div class="box">
  <header class="py-2 py-lg-3">
    <div class="row pl-3 pr-3">
      <div class="col-9">
        <div class="text-left h4 mb-0 mb-md-2 text-uppercase">
          {{'BONUS_ACTIVITY_BONUS_CONFIRMATION' | translate}}
        </div>
      </div>
      <ng-container *ngIf="clientName === CLIENT_NAMES.ADMIRAL_PINNBET; else rasterCloseBtn">
        <div class="c_svg-close-icon col-3">
          <i
            (click)="closeModal()"
            class="pointer close-icon"
            inlineSVG="assets/images/close-cross.svg"
            [setSVGAttributes]="{ height: '16px' }"
            fallbackSVG="assets/images/default-left-menu-icon.svg"
          ></i>
        </div>
      </ng-container>
      <ng-template #rasterCloseBtn>
        <div class="col-3 text-right">
          <div class="close">
            <img
              [src]="imagesBaseUrl + '/assets/icons/popup-close-btn.png'"
              (click)="closeModal()"
              alt="Popup close button icon"
            />
          </div>
        </div>
      </ng-template>
    </div>
  </header>
  <div class="descriptionContainer">
    <div class="box-row mb-2 p-2 flex-layout">
      <div class="row align-items-center">
        <div class="col-6">
          <div>{{'BONUS_ACTIVITY_BONUS_NAME' | translate }}</div>
        </div>
        <div class="col-6 text-right">
          <div>{{ bonus?.name }}</div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <div>{{'BONUS_ACTIVITY_INITIAL_FUNDS' | translate}}</div>
        </div>
        <div class="col-6 text-right">
          <div>{{ bonus?.initAmount }}</div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <div>{{'BONUS_ACTIVITY_BONUS_AMOUNT' | translate}}</div>
        </div>
        <div class="col-6 text-right">
          <div>{{ bonus?.amount }}</div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <div>{{'BONUS_ACTIVITY_CREATED_ON' | translate}}</div>
        </div>
        <div class="col-6 text-right">
          <div>{{ bonus?.createdOn | date: 'd MMM, y | HH:mm' }}</div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-6">
          <div>{{'BONUS_ACTIVITY_REMAINING_TIME_TO_BUY' | translate}}</div>
        </div>
        <div class="col-6 text-right">
          <div>{{ bonus?.redeemRemainingTime }}</div>
        </div>
      </div>
    </div>
    <div class="descriptionContainer">
      <div class="box-row mb-2 p-2">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="text-center text-primary">
              {{'BONUS_ACTIVITY_REDEMPTIONS' | translate | uppercase }}
            </div>
          </div>
        </div>
        <div *ngFor="let redeem of bonus?.redeems" class="row align-items-start">
          <div class="col-6">
            <div class="text-left text-primary">
              {{'ACTIVE_BONUS_REQUIRED_' + redeem?.type + '_AMOUNT' | translate}}
            </div>
          </div>
          <div class="col-6">
            <div class="text-right text-primary">
              {{redeem?.requiredAmount | number: '1.2-2' | currency: bonus.currency}}
            </div>
          </div>
          <div class="col-6">
            <div class="text-left text-primary">
              {{'ACTIVE_BONUS_' + redeem?.type + '_AMOUNT' | translate}}
            </div>
          </div>
          <div class="col-6">
            <div class="text-right text-primary">
              {{redeem?.amount | number: '1.2-2' | currency: bonus.currency}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      (click)="acceptBonus()"
      class="btn"
      [ngClass]="clientName === CLIENT_NAMES.ADMIRAL_PINNBET ? 'btn-primary d-block' : 'btn-outline-light'"
    >
      {{'BONUS_ACTIVITY_ACCEPT_BONUS' | translate }}
    </div>
  </div>
</div>
