export class AppColors {
  public primaryColor: string;
  public secondaryColor: string;
  public tertiaryColor: string;
  public quaternaryColor: string;
  public quinaryColor: string;
  public senaryColor: string;
  public septenaryColor: string;
  public octonaryColor: string;

  constructor(data) {
    this.primaryColor = data.primaryColor;
    this.secondaryColor = data.secondaryColor;
    this.tertiaryColor = data.tertiaryColor;
    this.quaternaryColor = data.quaternaryColor;
    this.quinaryColor = data.quinaryColor;
    this.senaryColor = data.senaryColor;
    this.septenaryColor = data.septenaryColor;
    this.octonaryColor = data.octonaryColor;
  }
}
