import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessagesInterface } from '@state/interfaces/AppStateInterface.interface';

@Injectable({ providedIn: 'root' })
export class MessagesService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @returns All messages
   */
  public getAllMessages(): Observable<MessagesInterface> {
    return this.http.get<MessagesInterface>(environment.apiUrl + `message`);
  }

  /**
   * Get all Messages
   * @param limit - how many items to return
   * @param offset - from which item data will be returned
   */
  public getMessages(limit, offset): Observable<any> {
    return this.http.get(environment.apiUrl + `message?limit=${limit}&offset=${offset}`).pipe(
      map(response => {
        response['records'].forEach(message => {
          // make property description to get content of a message without html tags
          message['description'] = message.text.replace(/<[^>]*>/g, '').substring(0, 50);
        });
        return response;
      })
    );
  }

  /**
   * Get all unread messages
   */
  public getAllUnreadMessages(): Observable<MessagesInterface> {
    return this.http.get<MessagesInterface>(environment.apiUrl + `message?unreaded=true`);
  }

  /**
   *
   * @param limit
   * @param offset
   * @returns Unreaded messages between offset - offset + limit
   */
  public getUnreadMessages(limit: number, offset: number): Observable<MessagesInterface> {
    return this.http.get<MessagesInterface>(environment.apiUrl + 'message', {
      params: new HttpParams()
        .set('limit', limit || 10)
        .set('offset', offset)
        .set('unreaded', 'true'),
    });
  }
  /**
   *
   * @returns All readed messages
   */
  public getAllReadMessages(): Observable<MessagesInterface> {
    return this.http.get<MessagesInterface>(environment.apiUrl + `message?unreaded=false`);
  }

  /**
   *
   * @param limit
   * @param offset
   * @returns Readed messages between offset - offset + limit
   */
  public getReadMessages(limit: number, offset: number): Observable<MessagesInterface> {
    return this.http.get<MessagesInterface>(environment.apiUrl + 'message', {
      params: new HttpParams()
        .set('limit', limit || 10)
        .set('offset', offset)
        .set('unreaded', 'false'),
    });
  }

  /**  Mark messages as seen
   * @param messageId - id to reference message and mark it as seen
   */
  public markAsSeen(messageId): Observable<any> {
    return this.http.put(environment.apiUrl + `message/${messageId}/mark-as-seen`, {});
  }

  /**  Delete messages
   * @param messageId - id to reference message and mark it as seen
   */
  public deleteMessage(messageId): Observable<any> {
    return this.http.put(environment.apiUrl + `message/${messageId}/delete`, {});
  }
}
