<div class="quick-menu-container" [@slideDown]="isQuickMenuOpen">
  <!-- CLOSE BUTTON -->
  <div class="text-center pointer" (click)="closeQuickMenu()">
    <i [inlineSVG]="'assets/images/mobile-menu-icon/menu_slider_btn_icon.svg'"></i>
  </div>

  <!-- NAV BAR MENU -->
  <div class="row no-gutters justify-content-between nav-bar-menu-title-wrapper">
    <div class="nav-bar-menu-title col-auto">{{ 'PINNBET_QUICK_MENU_SECTION' | translate }}</div>
    <div class="col-auto">
      <app-language-chooser-v2></app-language-chooser-v2>
    </div>
  </div>
  <div class="nav-bar-menu-container">
    <ng-container *ngFor="let navBarItem of navBarMenu">
      <div class="nav-bar-menu-btn" (click)="navigateToPage(navBarItem)">
        <div
          [setSVGAttributes]="{ height: '16px' }"
          [fallbackSVG]="'assets/images/mobile-menu-icon/menu_casino_l-menu.svg'"
          [inlineSVG]="imagesBaseUrl + navBarItem.iconPath"
        ></div>
        <div class="nav-bar-menu-btn-label text-center">{{ navBarItem.iconLabel | translate }}</div>
      </div>
    </ng-container>
  </div>

  <!-- LINKS SECTIONS -->
  <div *ngFor="let section of menuSections" class="links-container">
    <span class="links-section-title">{{ section.title | translate }}</span>
    <ng-container *ngFor="let linkItem of section.items; let even = even">
      <div
        *ngIf="!(linkItem.requireAuthorized && !player)"
        (click)="navigateToPage(linkItem)"
        routerLinkActive="active"
        [routerLink]="linkItem.pagePath"
        class="row no-gutters align-items-center pointer"
        [ngClass]="{ 'even-link': even }"
      >
        <div class="col-6 pt-2 pb-2 pl-5">
          <span class="link-item-label">{{ linkItem.iconLabel | translate }}</span>
          <i
            [setSVGAttributes]="{ height: '16px' }"
            [inlineSVG]="linkItem.iconPath ? imagesBaseUrl + linkItem.iconPath : null"
            class="pl-3"
          ></i>
        </div>
        <i
          [inlineSVG]="'assets/images/mobile-menu-icon/menu_right_chevron_icon.svg'"
          class="col-6 text-right pr-3"
        ></i>
      </div>
    </ng-container>
  </div>

  <button class="btn btn-primary quick-menu-main-btn text-uppercase" (click)="mainBtnAction()">
    {{ (player ? 'DEPOSIT' : 'LOGIN') | translate }}
  </button>
</div>
