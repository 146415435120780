<!-- DESKTOP VIEW -->
<div *ngIf="!isMobile" class="box desktop-view">
  <!-- HEADER -->
  <div class="section section-header">
    <!-- GAME FAVORITE -->
    <div *ngIf="isLoggedIn" (click)="setFavoriteGame()">
      <i *ngIf="!game.favorite" class="pointer favorite-icon" inlineSVG="assets/images/star-empty.svg"></i>
      <i *ngIf="game.favorite" class="pointer favorite-icon" inlineSVG="assets/images/star-solid.svg"></i>
    </div>
    <!-- empty if not logged in for close element to be on the right side -->
    <div *ngIf="!isLoggedIn"></div>
    <div>
      <i
        (click)="closeDialog()"
        class="pointer close-icon"
        inlineSVG="assets/images/close-cross.svg"
        [setSVGAttributes]="{ height: '16px' }"
        fallbackSVG="assets/images/default-left-menu-icon.svg"
      ></i>
    </div>
  </div>

  <!-- GAME TITLE -->
  <div class="section section-game-title">
    <div class="game-title">
      {{game.name}}
      <span class="game-vendor">{{game.providerName}}</span>
    </div>
  </div>

  <!-- GAME IMAGE -->
  <div #gameImage class="game-image"></div>

  <!-- BUTTONS -->
  <div class="section section-primary-buttons">
    <a
      *ngIf="game.demoIncluded"
      (click)="navigateToGamePlay(gameType.FOR_FUN)"
      class="btn demo-btn br-8 w-25"
    >
      {{ 'DEMO' | translate | uppercase }}
    </a>
    <a
      *ngIf="!isLoggedIn"
      [routerLink]="['/login']"
      (click)="goToLogin()"
      class="btn play-btn br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'LOGIN' | translate | uppercase }}
    </a>
    <a
      (click)="navigateToGamePlay(gameType.FOR_REAL)"
      *ngIf="isLoggedIn"
      class="btn play-btn br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'PLAY_FOR_CASH' | translate | uppercase }}
    </a>
  </div>
</div>

<!-- MOBILE VIEW -->
<div *ngIf="isMobile" class="box mobile-view">
  <!-- GAME IMAGE -->
  <div #gameImage class="game-image"></div>

  <!-- GAME TITLE -->
  <div class="section section-header">
    <div class="section section-game-title-mobile">
      <div class="game-title-mobile">{{game.name}}</div>
      <div class="game-vendor-mobile">{{game.providerName}}</div>
    </div>
    <!-- GAME FAVORITE -->
    <div *ngIf="isLoggedIn" (click)="setFavoriteGame()">
      <i *ngIf="!game.favorite" class="pointer favorite-icon" inlineSVG="assets/images/star-empty.svg"></i>
      <i *ngIf="game.favorite" class="pointer favorite-icon" inlineSVG="assets/images/star-solid.svg"></i>
    </div>
  </div>

  <!-- BUTTONS -->
  <div class="section section-primary-buttons-mobile">
    <a
      *ngIf="game.demoIncluded"
      (click)="navigateToGamePlay(gameType.FOR_FUN)"
      class="btn demo-btn br-8 w-25"
    >
      {{ 'DEMO' | translate | uppercase }}
    </a>
    <a
      *ngIf="!isLoggedIn"
      [routerLink]="['/login']"
      (click)="goToLogin()"
      class="btn play-btn br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'LOGIN' | translate | uppercase }}
    </a>
    <a
      (click)="navigateToGamePlay(gameType.FOR_REAL)"
      *ngIf="isLoggedIn"
      class="btn play-btn br-8 w-75"
      [ngClass]="game.demoIncluded ? 'w-75' : 'w-100'"
    >
      {{ 'PLAY_FOR_CASH' | translate | uppercase }}
    </a>
  </div>

  <!-- PROVIDER GAME OPEN -->
  <div *ngIf="isLoggedIn && gamePlayProblemLinkShown" class="section section-provider-game-open">
    <a (click)="navigateToGamePlay(gameType.FOR_REAL_EXTERNAL)" class="link">
      {{ 'PROBLEMS_OPENING_GAME' | translate }}
    </a>
  </div>
</div>
