import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionFilterModel } from '@models/transaction-filter-model';
import { TransactionFilterService } from '@services/transaction-filter.service';
import { Subscription } from 'rxjs';
import { DateService } from '@services/date.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

enum FILTER_TYPE {
  DATE_PICKER = 'FILTER_BY_DATE',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
}

@Component({
  selector: 'app-transaction-date-filter',
  templateUrl: './transaction-date-filter.component.html',
  styleUrls: ['./transaction-date-filter.component.scss'],
})
export class TransactionDateFilterComponent implements OnInit, OnDestroy {
  private filter$: Subscription;

  @Input() transactionQueryParams: any;
  @Input() haveActiveFilterSection: boolean;

  public todayObject = this.calendar.getToday();
  public lastMonthObject = { year: this.todayObject.year, month: this.todayObject.month - 1, day: 1 };
  public selectedDate = new FormControl(this.dateService.today());

  public filter = new TransactionFilterModel();
  public filterType = FILTER_TYPE;
  public showDatepicker = true;

  constructor(
    private router: Router,
    private transactionFilterService: TransactionFilterService,
    private dateService: DateService,
    private calendar: NgbCalendar
  ) {}

  ngOnInit(): void {
    this.setFilterModelSubscription();
    // Assign init value to filter model. It will be TODAY.
    Object.assign(this.filter, this.transactionFilterService.getInitialFilterValue());
  }

  ngOnDestroy(): void {
    // Reset filter model, set it back to initial TODAY value.
    this.transactionFilterService.resetFilterValue();
    this.transactionQueryParams = null;
    this.filter$?.unsubscribe();
    // Unset active section on destroy.
    this.transactionFilterService.addActiveFilterSection(false);
  }

  // Set filter model subscription to listen for changes on filter model, from other components or service.
  private setFilterModelSubscription() {
    this.filter$ = this.transactionFilterService.filterModelObservable.subscribe(newFilterState => {
      // check if new filter state is not equal to state that is already on scope
      if (JSON.stringify(newFilterState) !== JSON.stringify(this.filter)) {
        Object.assign(this.filter, newFilterState);
      }
    });
  }

  onFilterTypeChange(selectedFilterType: FILTER_TYPE): void {
    if (selectedFilterType === FILTER_TYPE.ACTIVE_ONLY) {
      this.showDatepicker = false;
      this.onDateSelect(null);
    } else {
      // Show datepicker and set it to TODAY.
      this.showDatepicker = true;
      this.onDateSelect(this.todayObject);
      this.selectedDate.setValue(this.filter.startDate);
    }
  }

  onDateSelect(date: NgbDate): void {
    Object.assign(this.filter, this.transactionFilterService.setDateFilter(date));
    // New query parameters to be applied to the current route.
    const queryParams = {
      ...this.transactionQueryParams,
      filterChange: true,
      ...this.transactionFilterService.getFilterQueryParams(this.haveActiveFilterSection),
    };
    this.router.navigate([], { queryParams });
  }
}
