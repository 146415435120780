import { Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { unAuthGuardCanActivate, unAuthGuardCanMatch } from '@guards/unauth.guard';
import { authGuardCanMatch } from '@guards/auth.guard';

export const commonRoutingModules: Routes = [
  { path: '', redirectTo: environment.appDefaultRoute, pathMatch: 'full' },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canMatch: [unAuthGuardCanMatch],
    canActivate: [unAuthGuardCanActivate],
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    canMatch: [authGuardCanMatch],
  },
  {
    path: 'sport-prematch',
    loadChildren: () => import('./modules/sport/sport.module').then(m => m.SportModule),
  },
  {
    path: 'sport-live',
    loadChildren: () => import('./modules/sport-live/sport-live.module').then(m => m.SportLiveModule),
  },
  {
    path: 'promotions',
    loadChildren: () => import('./modules/promotions/promotions.module').then(m => m.PromotionsModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./modules/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
  },
  {
    path: 'tournament',
    loadChildren: () => import('./modules/tournament/tournament.module').then(m => m.TournamentModule),
  },
];
