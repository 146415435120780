/* eslint-disable max-len */
export const baseServerUrl = `https://stg.admiral.hr`;
const wsBaseUrl = `wss://stg.admiral.hr`;

export const environment = {
  production: false,
  defaultLang: 'hr',
  apiUrl: `${baseServerUrl}/mbase/api/`,
  imagesBaseUrl: `${baseServerUrl}`,
  sportApi: 'https://stgwebapi.admiral.hr/api/Platform/',
  trumpeter: `${wsBaseUrl}/trumpeter/notification-socket?token=`,
  gaKey: '',
  fbqKey: '',
  uploadFilePath: `${baseServerUrl}/mbase/api/verification-doc`,
  uploadAvatarPath: `${baseServerUrl}/mbase/api/player/{playerId}/picture`,
  phoneCode: '+385',
  appDefaultRoute: 'casino',
  clientName: 'ADMIRAL_CROATIA',
  spribeBirman: `${baseServerUrl}/mbase/api/launch-game/spribe/games`,
  sportStartIframeId: '191',
  sportStartIframeMobile: '', // will be added
  sportLiveStartIframeId: '192',
  pwa: false,
  cookiePolicyUrl: '/help/category/124/card/139',
  cookiePolicyPrivacyUrl: '/help/category/124/card/140',
  allsecurePublicIntegrationKey: '',
  soliticsBrand: '',
  soliticsPopupToken: '',
  soliticsBranch: '',
  microBlinkLicenceKey:
    'sRwCAA5zdGcuYWRtaXJhbC5ocgZsZXlKRGNtVmhkR1ZrVDI0aU9qRTNNVGd4TURreE1qazVOVEFzSWtOeVpXRjBaV1JHYjNJaU9pSmxZekl6TldFNVlpMWlaVE5sTFRRek16QXRabVl5WWkwM01XTmlOakpqTnpSaE9XRWlmUT09gOk2fnh2qrI4Tqj6J+ITwKAsLvYW2tONU1Y4UUomfjz99WrTb+DRqpJDS2n3ExGEsEPopzOSW2YvMqasB1Q+XY/uvKFZ9qlJrmg4dO7XpSZ2OrLadaNpRybWZdb2gT/ysi0DqLyymOQ/k8F7f3xvtLMv',
  CMSElements: false,
};
