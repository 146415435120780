import { Injectable } from '@angular/core';
import { DateService } from './date.service';
import { environment } from 'src/environments/environment';
import { IpsDeposit } from '@models/ips-deposit.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private dateService: DateService) {}

  public setToken(loginResponse): void {
    const token = loginResponse['token']['token'];
    const duration = Number(loginResponse['token']['duration']);
    const durationConverted = this.dateService.addMinutesToCurrent(duration);

    localStorage.setItem('token', token);

    // save token duration (converted to timestamp + duration value)
    localStorage.setItem('tokenDuration', String(durationConverted));

    // save token duration raw
    localStorage.setItem('tokenDurationRaw', String(duration));

    // reload other application tabs after login state change
    localStorage.setItem('handlingTabs', 'true');
  }

  // CRO CLIENT ONLY
  public setTokenDataAfterRegistartionStep2(token: string, tokenDuration: string): void {
    const duration = Number(tokenDuration);
    const durationConverted = this.dateService.addMinutesToCurrent(duration);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenDuration', String(durationConverted));
    localStorage.setItem('tokenDurationRaw', String(duration));
    localStorage.setItem('handlingTabs', 'true');
  }

  public setCookieExpDate(expDate): void {
    localStorage.setItem('cookieExpDate', expDate);
  }

  public getCookieExpDate(): string {
    return localStorage.getItem('cookieExpDate');
  }

  public setLastUsedDeposit(id) {
    localStorage.setItem('lastUsedDeposit', JSON.stringify(id));
  }

  public getLastUsedDeposit() {
    return JSON.parse(localStorage.getItem('lastUsedDeposit'));
  }

  public setLastUsedWithdrawal(id) {
    localStorage.setItem('lastUsedWithdrawal', JSON.stringify(id));
  }

  public getLastUsedWithdrawal() {
    return JSON.parse(localStorage.getItem('lastUsedWithdrawal'));
  }
  // Set promo code.
  public setReferalCode(referalCode) {
    localStorage.setItem('referralCode', JSON.stringify(referalCode));
  }
  // Get promo code.
  public getReferalCode() {
    return JSON.parse(localStorage.getItem('referralCode'));
  }

  public setKskId(kskid) {
    localStorage.setItem('kskid', kskid);
  }

  public getKskId() {
    return localStorage.getItem('kskid');
  }

  public setPreviousUrl(previousUrl) {
    localStorage.setItem('previousUrl', JSON.stringify(previousUrl));
  }

  public getPreviousUrl(): string | null {
    return JSON.parse(localStorage.getItem('previousUrl'));
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  public setLanguage(language: string): void {
    localStorage.setItem('language', language);
  }

  public getLanguage(): string {
    return localStorage.getItem('language') || environment.defaultLang;
  }

  public setVerificationEmail(email: string) {
    localStorage.setItem('verificationEmail', email);
  }

  public getVerificationEmail(): string | null {
    return localStorage.getItem('verificationEmail');
  }

  public setVerificationPhoneNumber(phoneNumber: string) {
    localStorage.setItem('verificationPhoneNumber', phoneNumber);
  }

  public getVerificationPhoneNumber(): string | null {
    return localStorage.getItem('verificationPhoneNumber');
  }

  public setMobileFlag(isMobile: boolean): void {
    localStorage.setItem('isMobile', JSON.stringify(isMobile));
  }

  // set withdrawal back link
  public setWithdrawalBackLink(withdrawalBackLink: string) {
    localStorage.setItem('withdrawalBackLink', withdrawalBackLink);
  }

  // get withdrawal back link
  public getWithdrawalBackLink(): string {
    return localStorage.getItem('withdrawalBackLink');
  }

  public setShowAppDownloadPopup(duration): void {
    localStorage.setItem('showAppDownloadPopup', JSON.stringify(duration));
  }

  public setShowQuickBar(duration): void {
    localStorage.setItem('showQuickBar', JSON.stringify(duration));
  }

  public getShowQuickBarStatus(): string {
    return JSON.parse(localStorage.getItem('showQuickBar'));
  }

  public getShowAppDownloadPopup(): string {
    return JSON.parse(localStorage.getItem('showAppDownloadPopup'));
  }

  public removeFromLocalStorage(item): void {
    localStorage.removeItem(item);
  }

  public setPokerLocationMessageSeenFlag(flag): void {
    localStorage.setItem('pokerLocationMessage', flag);
  }

  public getPokerLocationMessageSeenFlag(): string {
    return localStorage.getItem('pokerLocationMessage');
  }

  public setIpsDepositData(data: IpsDeposit): void {
    localStorage.setItem('ipsDepositData', JSON.stringify(data));
  }

  public getIpsDepositData(): IpsDeposit {
    return JSON.parse(localStorage.getItem('ipsDepositData') || 'null');
  }

  public removeIpsDepositData(): void {
    localStorage.removeItem('ipsDepositData');
  }

  public setMicroBlinkData(data: any): void {
    localStorage.setItem('microBlinkData', JSON.stringify(data));
  }

  public getMicroBlinkData(): any {
    return JSON.parse(localStorage.getItem('microBlinkData'));
  }

  // clear storage
  clearStorage() {
    localStorage.removeItem('previousUrl');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenDuration');
    localStorage.removeItem('tokenDurationRaw');
    localStorage.removeItem('player');
    localStorage.removeItem('verificationEmail');
    localStorage.removeItem('verificationPhoneNumber');
    localStorage.removeItem('withdrawalBackLink');
    localStorage.removeItem('microBlinkData');
    // reload other application tabs after login state change
    localStorage.setItem('handlingTabs', 'false');
    this.removeIpsDepositData();
  }
}
