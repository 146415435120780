import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

enum RADIO_BTN_TYPES {
  REGULAR_BTN = 'REGULAR_BTN',
  TEXT_INSIDE_BTN = 'TEXT_INSIDE_BTN',
  TOUCH_REGION_BTN = 'TOUCH_REGION_BTN',
  REGULAR_BTN_WITH_CHECKMARK = 'REGULAR_BTN_WITH_CHECKMARK',
}

@Component({
  selector: 'app-custom-radio',
  templateUrl: './custom-radio.component.html',
  styleUrls: ['./custom-radio.component.scss'],
})
export class CustomRadioComponent {
  @Input() radioFormControl: FormControl;
  @Input() value: any = null;
  @Input() typeOfRadioBtn: string = RADIO_BTN_TYPES.REGULAR_BTN;
  @Output() radioClick = new EventEmitter<void>();

  public RADIO_BTN_TYPES = RADIO_BTN_TYPES;

  radioClicked() {
    this.radioFormControl.markAsTouched();
    this.radioFormControl.markAsDirty();
    this.radioFormControl.patchValue(this.value);
    this.radioClick.emit();
  }

  get controlValue(): any {
    return this.radioFormControl.value;
  }
}
