import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CmsGeneralHeaderSettings } from '../cmsHeader.model';
import { NavigationService } from '@services/navigation.service';
import { environment } from 'src/environments/environment';
export interface IAsset {
  id?: number;
  title: string;
  assetType: string;
  items: IAssetItem[];
}
export interface IAssetItem {
  title: string;
  image: null | IImage;
  icon: null | string;
  text: string;
  link: string;
  index?: number;
}

export interface IImage {
  data: string;
  path: string;
  fileName: string;
  type: string;
  target: string;
}
@Component({
  selector: 'app-header-cms-menu-component',
  templateUrl: './header-cms-menu.component.html',
  styleUrls: ['./header-cms-menu.component.scss'],
})
export class HeaderCmsMenuComponent implements OnInit {
  @Input() navigationMenu: any;
  @Input() player: any;
  @Input() cmsGeneralData: CmsGeneralHeaderSettings;

  public environment = environment;
  public imagesBaseUrl = environment.imagesBaseUrl;
  constructor(public navigationService: NavigationService, private elementRef: ElementRef) {}
  ngOnInit(): void {
    this.setColorsForMenuFromGeneralSettings();
  }

  // TODO set colors from General CMS settings
  private setColorsForMenuFromGeneralSettings(): void {
    this.elementRef.nativeElement.style.setProperty('--menuTextColor', this.cmsGeneralData.menuTextColor.hex);
    this.elementRef.nativeElement.style.setProperty('--menuIconColor', this.cmsGeneralData.menuIconColor.hex);
    this.elementRef.nativeElement.style.setProperty(
      '--activeLinkBtnBackgroundColor',
      this.cmsGeneralData.menuBackgroundActiveColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--hoverLinkBtnBackgroundColor',
      this.cmsGeneralData.menuBackgroundHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuIconTextColor',
      this.cmsGeneralData.menuIconTextColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuTextHoverColor',
      this.cmsGeneralData.menuTextHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuBorderActiveColor',
      this.cmsGeneralData.menuBorderActiveColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuTextActiveColor',
      this.cmsGeneralData.menuTextActiveColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--menuBorderHoverColor',
      this.cmsGeneralData.menuBorderHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty('--defaultHeaderHeight', '68px');
  }
}
