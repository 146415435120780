<div class="sidebar-language sidebar-hidden flex-row">
  <div *ngIf="chatEnabled" class="pointer clickableArea chatIconColor" (click)="toggleChat()">
    <span inlineSVG="assets/icons/chat-icon.svg"></span>
    <sub
      *ngIf="unreadChat > 0"
      class="align-top top text-white badge badge-danger p-1 d-inline-block ml-n1"
      >{{ unreadChat }}</sub
    >
  </div>
  <div *ngIf="isCroatianStagingEnv" class="pointer clickableArea chatIconColor" (click)="toggleInfobipChat()">
    <span inlineSVG="assets/icons/chat-icon.svg"></span>
  </div>
  <div
    *ngFor="let language of languagesList"
    class="btn btn-outline-dark btn-rounded p-2 flagWrapper"
    [ngClass]="{ activeLanguage: activeLanguage === language.isocode2 }"
    (click)="changeLanguage(language.isocode2)"
  >
    <div
      [ngStyle]="{
        'background-image':
          'url(../../../../assets/flags/' +
          (clientName === clients.ADMIRAL_BOSNIA ? 'ba' : language?.isocode2) +
          '.jpg)'
      }"
    ></div>
  </div>
</div>
