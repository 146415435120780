<div
  [ngClass]="{ mobile: isMobile, profileLocation: isProfileLocation }"
  class="live-document-scanner-container"
  *ngIf="active"
>
  <p class="document-side-msg">
    {{ documentSideMsg | translate }}
  </p>
  <p class="text-center">{{ 'CRO_V2_DOCUMENT_EASY_VISIBLE' | translate }}</p>

  <div *ngIf="isCameraActivated" class="camera-not-active-counter my-5">
    {{ 'ACCESSING_CAMERA' | translate }} {{ counter }}
  </div>

  <div class="screen-scanning" [ngClass]="{ hidden: shouldHideUntilConfirmed }">
    <video playsinline #cameraFeed></video>
    <!-- Recognition events will be drawn here. -->
    <canvas class="camera-feedback" #cameraFeedback></canvas>
    <p class="camera-guides" #cameraGuides></p>
  </div>
  <div class="text-center camera-not-working-label">{{ 'CRO_V2_CAMERA_NOT_WORKING' | translate }}</div>
  <div [ngClass]="{ profileLocation: isProfileLocation }" class="button-container">
    <button (click)="switchToUpload()" class="cro-button registration">
      {{ 'CRO_V2_LOAD_FROM_GALLERY' | translate }}
    </button>
  </div>
  <div *ngIf="!isProfileLocation" (click)="stepSkipped()" class="no-scan">
    {{ 'CRO_V2_UPLOAD_LATER' | translate }}
  </div>
</div>
