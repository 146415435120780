<div class="values-wrapper" #valuesWrapper>
  <div *ngFor="let displayItem of displayArray; let i = index">
    <ng-container *ngIf="displayItem.isOdometer">
      <app-odometer-v2-col
        [startingValue]="displayItem.odometerStartingValue"
        [odometer]="displayItem"
        [digitHeight]="digitHeight"
      ></app-odometer-v2-col>
    </ng-container>
    <ng-container *ngIf="!displayItem.isOdometer">
      <app-odometer-v2-text-col
        [text]="displayItem.textValue"
        [shouldBeDeleted]="displayItem.shouldBeDeleted"
      ></app-odometer-v2-text-col>
    </ng-container>
  </div>
</div>
