import { Component, Input, ViewContainerRef } from '@angular/core';

/**
 * @MbetAccordionComponent create accordion element using NG-BOOTSTRAP plugin
 * @Input dataForAccordion can be any Array witch correspond data with template inputs
 * @Input paginateConfig {itemsPerPage: number, currentPage: number, totalItems: number}
 * example of implementation:
 *   <app-mbet-accordion
 *     [dataForAccordion]='tickets'
 *     [paginateConfig]='config'
 *     [panelHeader]="headerPanel"
 *     [panelContent]="contentPanel"
 *   ></app-mbet-accordion>
 *
 *   <ng-template #headerPanel let-ticket="data" let-index="itemIndex" let-expandPanel="expandPanel">
 *     <app-panel-header
 *     [ticket]="ticket"
 *     [index]="index"
 *     (expandPanel)="expandPanel($event)"
 *     ></app-panel-header>
 *   </ng-template>
 *
 *   <ng-template #contentPanel let-ticket="data">
 *     <app-panel-content [ticket]="ticket"></app-panel-content>
 *   </ng-template>
 */
@Component({
  selector: 'app-mbet-accordion',
  templateUrl: './mbet-accordion.component.html',
})
export class MbetAccordionComponent {
  public activePanelID: number;
  @Input() dataForAccordion: any[];
  @Input() paginateConfig?: PaginateConfigInterface = {
    itemsPerPage: 7,
    currentPage: 1,
    totalItems: 7,
  };
  @Input() panelHeader: ViewContainerRef;
  @Input() panelContent: ViewContainerRef;

  expandPanel(activePanelID) {
    this.activePanelID = this.activePanelID === activePanelID ? 0 : activePanelID;
  }

  closePanel() {
    this.activePanelID = 0;
  }
}

interface PaginateConfigInterface {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
}
