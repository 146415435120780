import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { IMAGE_ORIENTATION } from '@enums/image-orientation.enum';

@Injectable({
  providedIn: 'root',
})
export class ImageCompressService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Get image orientation
   * @param file - file for upload
   */
  public getImageOrientation(file: File): Promise<IMAGE_ORIENTATION> {
    return new Promise<IMAGE_ORIENTATION>(resolve => {
      this.getOrientation(file, result => {
        resolve(result);
      });
    });
  }

  private getOrientation(file: File, callback: (result: IMAGE_ORIENTATION) => void) {
    const reader = new FileReader();
    try {
      reader.onload = function () {
        const view = new DataView(reader.result as ArrayBuffer);
        if (view.getUint16(0, false) !== 0xffd8) {
          return callback(IMAGE_ORIENTATION.NotDefined);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xffe1) {
            if (view.getUint32((offset += 2), false) !== 0x45786966) {
              return callback(IMAGE_ORIENTATION.NotJpeg);
            }
            const little = view.getUint16((offset += 6), false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                return callback(view.getUint16(offset + i * 12 + 8, little));
              }
            }
          } else if ((marker && 0xff00) !== 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return callback(IMAGE_ORIENTATION.NotJpeg);
      };
      reader.readAsArrayBuffer(file);
    } catch (e) {
      return callback(IMAGE_ORIENTATION.Default);
    }
  }

  /**
   * Compress image function
   * @param image - image in string format
   * @param orientation - image orientation
   * @param ratio - default 50%
   * @param quality - default 50%
   * @param maxwidth - max width of image
   * @param maxheight - max height of image
   */
  // TODO check why orientation doesnt work when upload picture taken with mobile
  public compressImage(
    image: string,
    orientation: IMAGE_ORIENTATION,
    ratio: number = 50,
    quality: number = 50,
    maxwidth: number = 0,
    maxheight: number = 0
  ): Promise<string> {
    const promise: Promise<string> = new Promise((resolve, reject) => {
      quality = quality / 100;
      ratio = ratio / 100;
      const sourceImage = new Image();

      // important for safari: we need to wait for onload event
      sourceImage.onload = () => {
        const canvas: HTMLCanvasElement = this.renderer.createElement('canvas');
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        let w = sourceImage.naturalWidth;
        let h = sourceImage.naturalHeight;

        if (orientation === IMAGE_ORIENTATION.Right || orientation === IMAGE_ORIENTATION.Left) {
          const t = w;
          w = h;
          h = t;
        }

        const xratio = maxwidth ? maxwidth / w : 1;
        const yratio = maxheight ? maxheight / h : 1;
        ratio = Math.min(ratio, xratio, yratio);
        canvas.width = w * ratio;
        canvas.height = h * ratio;

        const TO_RADIANS = Math.PI / 180;

        if (orientation === IMAGE_ORIENTATION.Up) {
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        } else if (orientation === IMAGE_ORIENTATION.Right) {
          ctx.save();
          ctx.rotate(90 * TO_RADIANS);
          ctx.translate(0, -canvas.width);
          ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
          ctx.restore();
        } else if (orientation === IMAGE_ORIENTATION.Left) {
          ctx.save();
          ctx.rotate(-90 * TO_RADIANS);
          ctx.translate(-canvas.width, 0);
          ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
          ctx.restore();
        } else if (orientation === IMAGE_ORIENTATION.Down) {
          ctx.save();
          ctx.rotate(180 * TO_RADIANS);
          ctx.translate(-canvas.width, -canvas.height);
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
          ctx.restore();
        } else {
          console.warn('ngx-image-compress - no orientation value found');
          // same as default UP
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        }
        // Take string between 'data:' and ';'.
        const mime = image.split(';')[0].substring(5);
        const result = canvas.toDataURL(mime, quality);

        resolve(result);
      };

      sourceImage.onerror = function (e) {
        reject(e);
      };

      sourceImage.src = image;
    });

    return promise;
  }
}
