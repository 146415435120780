import { environment } from 'src/environments/environment';

const ANDROID_URL_PATH = 'https://appster.blob.core.windows.net/apps';
const IOS_URL_PATH = 'https://apps.apple.com/us/app';
const CLIENT_ICONS_PATH = `/assets/icons/${environment.clientName}`;

export const APP_LINKS = {
  ADMIRAL_SERBIA: {
    iosLink: `${IOS_URL_PATH}/admiralbet/id6444629230`,
    androidLink: `${ANDROID_URL_PATH}/admiralbet.apk`,
    platformLogoSrc: `${CLIENT_ICONS_PATH}/admiralbet-icon.png`,
    iosDownloadIcon: `${CLIENT_ICONS_PATH}/ios-icon.svg`,
    androidDownloadIcon: `${CLIENT_ICONS_PATH}/android-icon.svg`,
    title: 'admiralbet.rs',
  },
  ADMIRAL_MD_SHOP: {
    iosLink: `${IOS_URL_PATH}/md-shop/id6448045982`,
    androidLink: `${ANDROID_URL_PATH}/md-shop.apk`,
    platformLogoSrc: `${CLIENT_ICONS_PATH}/mdshop_icon.png`,
    iosDownloadIcon: `${CLIENT_ICONS_PATH}/ios-icon.svg`,
    androidDownloadIcon: `${CLIENT_ICONS_PATH}/android-icon.svg`,
    title: 'www.mdshop.ba',
  },
  ADMIRAL_MONTENEGRO: {
    iosLink: `${IOS_URL_PATH}/admiralbet-montenegro/id6447435661`,
    androidLink: `${ANDROID_URL_PATH}/admiralbet-mne.apk`,
    platformLogoSrc: `${CLIENT_ICONS_PATH}/admiralbet-icon.png`,
    iosDownloadIcon: `${CLIENT_ICONS_PATH}/ios-icon.svg`,
    androidDownloadIcon: `${CLIENT_ICONS_PATH}/android-icon.svg`,
    title: 'www.admiralbet.me',
  },
  ADMIRAL_BOSNIA: {
    iosLink: `${IOS_URL_PATH}/admiralbet-ba/id6449092006`,
    androidLink: `${ANDROID_URL_PATH}/admiralbet-bih.apk`,
    platformLogoSrc: `${CLIENT_ICONS_PATH}/admiralbet-ba-icon.png`,
    iosDownloadIcon: `${CLIENT_ICONS_PATH}/ios-icon.svg`,
    androidDownloadIcon: `${CLIENT_ICONS_PATH}/android-icon.svg`,
    title: 'admiralbet.ba',
  },
  ADMIRAL_PINNBET: {
    iosLink: `https://apps.apple.com/rs/app/pinnbet-sport-slot/id6499205718`,
    androidLink: `https://pinnbet.appstersolutions.com/apps/pinnbet.apk`,
    platformLogoSrc: `${CLIENT_ICONS_PATH}/pinnbet-icon.svg`,
    iosDownloadIcon: `${CLIENT_ICONS_PATH}/ios-icon.svg`,
    androidDownloadIcon: `${CLIENT_ICONS_PATH}/android-icon.svg`,
    title: 'pinnbet.rs',
  },
};
