import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JackpotModel } from '@models/jackpot.model';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JackpotService {
  constructor(private http: HttpClient) {}

  /**
   * Accept Jackpot
   * @param jackpotAssignedId - id of instance of jackpot which will be accepted by player
   */
  acceptJackpot(jackpotAssignedId: number): Observable<any> {
    return this.http.put(environment.apiUrl + `jackpot/${jackpotAssignedId}/claim`, {});
  }

  /**
   * Get jackpot current value
   * @param jackpotId
   */
  getJackpotValue(jackpotId: number): Observable<number> {
    return this.http.get<number>(environment.apiUrl + `jackpot/${jackpotId}/value`);
  }

  /**
   * Get Active jackpots by game
   * @param gameId - id of a game
   */
  getActiveJackpotsByGame(gameId: number): Observable<JackpotModel[]> {
    return this.http
      .get<JackpotModel[]>(environment.apiUrl + `jackpot/active-by-game/${gameId}`)
      .pipe(map((response: any[]) => response.map(data => new JackpotModel(data))));
  }

  /**
   * Get jackpot winners
   * @param jackpotId
   */
  getJackpotWinners(jackpotId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `jackpot/${jackpotId}/winners`);
  }
}
