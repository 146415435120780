import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Player } from '@models/player.model';
import { interval, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateFacadeService } from '@state/app-state.facade';
import { CookieService } from 'ngx-cookie';
import { MessagesInterface } from '@state/interfaces/AppStateInterface.interface';
import { CmsHeader } from '../cmsHeader.model';

@Component({
  selector: 'app-user-operation-elements',
  templateUrl: './header-cms-user-operation.component.html',
  styleUrls: ['./header-cms-user-operation.component.scss'],
})
export class HeaderCmsUserOperationComponent implements OnInit, OnDestroy {
  @Input() player: Player;
  @Input() headerCmsData: CmsHeader;
  @Input() isMobile: boolean;
  public showMenuUserIcon = true;
  public showRegistrationBtn = true;
  private unreadMessagesObservable: Observable<any>;
  private unreadMessages$: Subscription;
  public unreadMessages: number;

  public clock = interval(1000).pipe(map(() => new Date())); // clock implementation

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private cookieService: CookieService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.setupStore();
    this.checkCookies();
    this.setUnreadMessagesSubscription();
    this.setColorsForMenuFromGeneralSettings();
  }
  public handleRightSideBar() {
    const chatWindowOpen = this.appStateFacadeService.isChatWindowOpen();
    this.appStateFacadeService.setChatWindowWasOpenBeforeRightBarExpand(chatWindowOpen);
    this.appStateFacadeService.expandCollapseRightSideBar();
  }

  private setUnreadMessagesSubscription(): void {
    this.unreadMessages$ = this.unreadMessagesObservable.subscribe((data: MessagesInterface) => {
      this.unreadMessages = data.total;
    });
  }

  private checkCookies(): void {
    const cookie = this.cookieService.get('cookie');
    const idsCookieArray = this.cookieService.get('_pc');
    if (cookie && idsCookieArray) {
      this.showRegistrationBtn = false;
    }
  }

  setupStore(): void {
    this.unreadMessagesObservable = this.appStateFacadeService.getMessagesObservable();
  }

  ngOnDestroy(): void {
    this.unreadMessages$.unsubscribe();
  }

  // TODO set colors from General CMS settings
  private setColorsForMenuFromGeneralSettings(): void {
    this.elementRef.nativeElement.style.setProperty('--colorOfIconsAndLabels', 'white');
    this.elementRef.nativeElement.style.setProperty(
      '--registrationButtonColor',
      this.headerCmsData.registrationButtonLinkColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--registrationButtonHoverColor',
      this.headerCmsData.registrationButtonLinkHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--loginButtonColor',
      this.headerCmsData.loginButtonLinkColor.hex
    );
    this.elementRef.nativeElement.style.setProperty(
      '--loginButtonHoverColor',
      this.headerCmsData.loginButtonLinkHoverColor.hex
    );
    this.elementRef.nativeElement.style.setProperty('--clockColor', this.headerCmsData.timeColor.hex);
  }
}
