import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TOURNAMENT_STATUS } from '@enums/tournament-status.enum';
import { AppStateFacadeService } from '../../../../shared/state/app-state.facade';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { TournamentService } from '@services/tournament.service';
import { Observable } from 'rxjs';

interface RankedPlayer {
  username: string;
  points: number;
  prize: number;
}

@Component({
  selector: 'app-pinnbet-tournament-slider',
  templateUrl: './tournament-slider.component.html',
  styleUrls: ['./tournament-slider.component.scss'],
})
export class PinnbetTournamentSliderComponent implements OnInit {
  public TOURNAMENT_STATUS = TOURNAMENT_STATUS;
  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public endTime: string;
  public days: number;
  public rankListObservable: Observable<RankedPlayer[] | null>;
  public environment = environment;
  public showCounter = false;
  // it is in timestamp format because of that it is number type
  public labelForCounter: string;
  public dateForCounter: number;
  public player = this.appStateFacadeService.getPlayerData();

  @Output() leaderboardOpened = new EventEmitter<any>();
  @Input() tournament: any;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private tournamentService: TournamentService
  ) {}

  ngOnInit(): void {
    //TODO when BE give us showCounter flag connect this prop with flag
    this.showCounter = moment().isBefore(moment(this.tournament.tournamentStartOn));
    if (this.showCounter) {
      const beforeStart = moment().isBefore(moment(this.tournament.tournamentStartOn));
      this.dateForCounter = beforeStart
        ? this.tournament.tournamentStartOn
        : this.tournament.tournamentEndsOn;
      this.labelForCounter = beforeStart ? 'STARTS_IN' : 'END_IN';
    }
    if (moment().isBetween(this.tournament.tournamentStartOn, this.tournament.tournamentEndsOn)) {
      const diffTodayToTime = moment(this.tournament.tournamentEndsOn).diff(moment());
      this.days = Math.floor(moment.duration(diffTodayToTime).asDays());
      const hours = moment.duration(diffTodayToTime).hours();
      const minutes = moment.duration(diffTodayToTime).minutes();
      const seconds = moment.duration(diffTodayToTime).seconds();
      this.endTime = `${hours}:${minutes}:${seconds}`;
    }
    this.getRankList(this.tournament.tournamentId);
  }

  private getRankList(tournamentId: number): void {
    this.rankListObservable = this.tournamentService
      .getRankList(tournamentId, 5)
      .pipe(map(data => (data.total ? data.records : null)));
  }

  public openGameInfo(event, game, tournamentId) {
    event.stopPropagation();
  }

  public openLeaderboard(event) {
    event.stopPropagation();
    this.leaderboardOpened.emit();
  }
}
