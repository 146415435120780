export class GameDataModel {
  id: number;
  name: string;
  lowResGameIconLocation: string; // lowResGameIcon.location
  mobileGameIconLocation: string; //mobileGameIcon.location
  gameIconLocation: string; //gameIcon.location
  newGame: boolean;
  popular: boolean;
  tournament: boolean;
  externalProductId: string;
  demoIncluded: boolean;
  favorite: boolean;
  visible: boolean;
  gameIconVisible: boolean; //gameIcon.visible
  funId: string;
  providerName: string;
  code: string;
  vendorName: string;
  lobbies: string[];
  forceExternalLink: boolean;
  forceLandscape: boolean;
  birmanUrl: string;
  description: string;
  descriptionLocale: string;
  jackpot: boolean;
  allowedInGeolocation: boolean;

  deserialize(gameData): this {
    Object.assign(this, {
      id: gameData.id,
      name: gameData.name,
      lowResGameIconLocation: gameData.lowResGameIcon.location,
      mobileGameIconLocation: gameData.mobileGameIcon.location,
      gameIconLocation: gameData.gameIcon.location,
      newGame: gameData.newGame,
      popular: gameData.popular,
      tournament: gameData.tournament,
      externalProductId: gameData.externalProductId,
      demoIncluded: gameData.demoIncluded,
      favorite: gameData.favorite,
      visible: gameData.visible,
      gameIconVisible: gameData.gameIcon.visible,
      funId: gameData.funId,
      providerName: gameData.providerName
        ? gameData.providerName
            .replace(/[_]/g, ' ')
            .split(' ')
            .map(item => item.charAt(0).toUpperCase() + item.substring(1))
            .join(' ')
        : '',
      code: gameData.code,
      vendorName: gameData.vendorName,
      lobbies: gameData.lobbies,
      forceExternalLink: gameData.forceExternalLink,
      forceLandscape: gameData.forceLandscape,
      birmanUrl: gameData.birmanUrl,
      description: gameData.description,
      descriptionLocale: gameData.descriptionLocale,
      jackpot: gameData.jackpot,
      allowedInGeolocation: gameData.allowedInGeolocation,
    });
    return this;
  }
}
