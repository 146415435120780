export const MD_SHOP_THEME_COLORS = [
  { backgroundColor: '#2F3135' },
  { sideMenuColor: '#fff' },
  { admiralButtonColor: '#fff' },
  { sideMenuBackgroundColor: '#000000' },
  { primaryColor: '#EA2124' },
  { secondaryColor: '#131316' },
  { secondaryMenuColor: '#fff' },
  { successColor: '#EA2124' },
  { tertiaryColor: '#EA2124' },
  { cardColor: '#2F3135' },
  { cardColorActive: '#EA2124' },
  { filterColor: '#ffffff' },
  { borderColor: '#313131' },
  { headerActiveElementColor: '#282828' },
  { boxRowColor: '#171717' },
  { boxRowDesktopColor: '#171717' },
  { boxRowBorder: '1px solid #2f3235' },
  { inputColor: '#171717' },
  { boxInputBackgroundColor: '#171717' },
  { inputFontColor: '#fff' },
  { inputBorderColor: '#362121' },
  { depositCardColor: '#171717' },
  { desktopCardColor: '#2F3135' },
  { lobbyGameSearchColor: '#362121' },
  { btnDepositClass: 'btn-outline-primary' },
  { gameInfoBoxColor: 'rgba(54, 33, 33, 0.96)' },
  { activeWithdrawalBckgColor: 'rgb(150, 54, 54)' },
  { helpPageBackgroundColor: '#171717' },
  { btnDisabledColor: '#EA2124' },
  { btnPrimaryHoverColor: '#EA2124' },
  { btnPrimaryBorderHoverColor: '#fff' },
  { btnPrimaryBorderRadius: '2rem' },
  { btnAdmiralBorderRadius: '4px' },
  { btnPrimaryShadowColor: 'rgba(222, 178, 10, 0.5)' },
  { lightGrayColor: '#7d6c6c' },
  { backgroundImage: 'mb-bgrd-2k.webp' },
  { tournamentGradient: 'linear-gradient(116.84deg, #660000 0.88%, #b30000 100%)' },
  { leaderboardDialogGradient: 'linear-gradient(to bottom, #660000, #b30000)' },
  { fontFamily: 'Montserrat, sans-serif' },
  { fontColor: '#fff' },
  { primaryButtonTextColor: '#fff' },
  { buttonOutlineColor: '#fff' },
  { subgroupButtonColor: '#171717' },
  { labelTextTransform: 'uppercase' },
  { labelFontSize: '10px' },
  { btnDarkColor: '#fff' },
  { linkNoHrefColor: 'inherit' },
  // toaster colors
  { toasterSuccessColor: '#c9ffaf' },
  { toasterSuccessBackgroundColor: '#1e3314' },
  { toasterSuccessBorderColor: '#2b471c' },
  { toasterSuccessAlertLinkColor: '#a7ff7c' },
  { toasterDangerColor: '#ff92b7' },
  { toasterDangerBackgroundColor: '#330917' },
  { toasterDangerBorderColor: '#470d21' },
  { toasterDangerAlertLinkColor: '#ff5f95' },
  { titleFontSize: '1.125rem' },
  { titleFontSizeMobile: '1.125rem' },
  { customSelectHoverColor: '#38393b' },
  { darkBtnHoverBackground: '#070708' },
  // Date picker colors
  { datePickerBorderColor: '#34373b' },
  { datePickerActiveElementBackground: '#393e41' },
  { datePickerSelectContainerBackground: '#171717' },
  { datePickerSelectContainerColor: '#fff' },
  { datePickerDayButtonBackground: '#171717' },
  { datePickerDayButtonColor: '#fff' },
];
