import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @ViewChild('progressBarContainer', { static: true }) progressBarContainer: ElementRef;
  @Output() navigation = new EventEmitter<any>();
  @Input() navigationUrl: string;
  @Input() totalAmount: number;
  @Input() shareAmount: number;
  @Input() noAmountMessage: string;
  @Input() lockedAmount: number;
  @Input() label: string;
  @Input() labelShouldUseTranslatePipe = true;
  @Input() lockedItemsLength = 0;
  @Input() currency: string;
  public percentage: number;
  public environment = environment;
  public clientNames = CLIENT_NAMES;

  constructor(private renderer: Renderer2, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if there are any changes in inputs update the progress bar
    if (changes.totalAmount || changes.shareAmount) {
      !this.totalAmount
        ? (this.percentage = 0)
        : (this.percentage = (100 * this.shareAmount) / this.totalAmount).toFixed(2);
      this.renderer.setStyle(
        this.progressBarContainer.nativeElement,
        'width',
        `calc(${this.percentage}% - 8px)`
      );
    }
  }

  public navigate() {
    if (!this.navigationUrl) {
      return;
    }
    this.router.navigate([this.navigationUrl]);
    this.navigation.emit();
  }
}
