import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AppStateFacadeService } from '@state/app-state.facade';
import { filter } from 'rxjs/operators';
import { ConfigData } from '@models/config-data/config-data.model';
import { SideBar, SideMenusConfigurations } from '../../left-menu-cms/cmsSideMenu.model';
import { Subscription } from 'rxjs';
import { baseServerUrl } from '../../../../environments/environment';
import { BUTTON_TYPES } from '../../../shared/directive/admiral-button.directive';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-right-menu-links-cms',
  templateUrl: './right-menu-links-cms.component.html',
  styleUrls: ['./right-menu-links-cms.component.scss'],
})
export class RightMenuLinksCmsComponent implements OnInit {
  @Input() navigateToLinkInMenu: (menuItem) => void;
  public baseServerUrl = baseServerUrl;
  public BUTTON_TYPES = BUTTON_TYPES;
  private windowWidth: number;

  public isMobile = this.appStateFacadeService.getIsMobileStatus();
  public isTablet = this.appStateFacadeService.getIsTabletStatus();
  private configDataObservable$: Subscription;
  public rightMenuData: SideBar;
  public sideMenuData: SideMenusConfigurations;

  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private authService: AuthService,
    private router: Router
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
    this.setRightMenuType();
  }

  ngOnInit() {
    this.configDataObservable$ = this.appStateFacadeService
      .getConfigData()
      .pipe(filter((data: ConfigData) => !!data))
      .subscribe((data: ConfigData) => {
        this.sideMenuData = new SideMenusConfigurations(JSON.parse(data.sideMenuV2));
        this.setRightMenuType();
      });
  }

  setRightMenuType(): void {
    if (!this.isMobile && !this.isTablet) {
      switch (true) {
        case this.windowWidth < 576: // Extra small devices (portrait phones, less than 576px)
          this.rightMenuData = this.sideMenuData.mobileWidth.rightSideBar;
          break;
        case this.windowWidth >= 576 && this.windowWidth < 768: // Small devices (landscape phones, 576px and up)
          this.rightMenuData = this.sideMenuData.tabletWidth.rightSideBar;
          break;
        case this.windowWidth >= 768: // Medium devices (tablets, 768px and up) and Large devices (desktops, 992px and up)
          this.rightMenuData = this.sideMenuData.desktopWidth.rightSideBar;
          break;
        default:
          this.rightMenuData = this.sideMenuData.desktopWidth.rightSideBar;
      }
    } else {
      this.rightMenuData = this.isTablet
        ? this.sideMenuData.tabletWidth.rightSideBar
        : this.sideMenuData.mobileWidth.rightSideBar;
    }
  }

  public clickOnBtn(menuItem) {
    if (menuItem.title === 'Logout') {
      this.authService.logout('Right menu');
    }
    if (menuItem.title === 'Login') {
      this.router.navigate(['/login']);
    }
  }
}
