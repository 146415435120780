export class FooterDescription {
  bsClass: string;
  code: string;
  label: string;
  logo: string;
  logoHover: string;
  parentCode: string;
  type: string;
  url: string;
  urlType: string;

  constructor(data) {
    this.bsClass = data.bsClass;
    this.code = data.code;
    this.label = data.label;
    this.logo = data.logo;
    this.logoHover = data.logoHover;
    this.parentCode = data.parentCode;
    this.type = data.type;
    this.url = data.url;
    this.urlType = data.urlType;
  }
}
