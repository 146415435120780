import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { LeftMenuComponent } from './layout/left-menu/left-menu.component';
import { LeftMenuCmsComponent } from './layout/left-menu-cms/left-menu-cms.component';
import { HeaderComponent } from './layout/header/header.component';
import { HeaderCroComponent } from './layout/header-cro/header-cro.component';
import { MobileBottomMenuComponent } from '@modules/lobby/mobile-bottom-menu/mobile-bottom-menu.component';
import { SharedModule } from './shared/shared.module';
import { RightMenuComponent } from './layout/right-menu/right-menu.component';
import { RightMenuCmsComponent } from './layout/right-menu-cms/right-menu-cms.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FooterCroComponent } from './layout/footer-cro/footer-cro.component';
import { FooterCMSComponent } from './layout/footer/footer-cms/footer-cms.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/core/services/language.service';
import { WarnMissingTranslationHandler } from '@services/mbet-missing-translator.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter } from './core/services/custom-date-adapter.service';
import { CustomDateParserFormatter } from './core/services/custom-date-parser-formatter.service';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { setAppInjector } from 'src/app/core/services/app-injector';
import { HammerConfig } from 'src/config/hammer.config';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieModule } from 'ngx-cookie';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';

// REDUCERS
import { OverlayShadeComponent } from './layout/overlay-shade/overlay-shade/overlay-shade.component';
/**
 * [AB-3839] Clean up commented code when definitely quick bar pass testing
 */
// import { AppDownloadPopupComponent } from './layout/app-download-popup/app-download-popup.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RightMenuCroComponent } from './layout/right-menu-cro/right-menu-cro.component';
import { CLIENT_NAMES } from '@enums/client-names.enum';
import SERBIA_LOCALE from '@angular/common/locales/sr-Latn';
import UGANDA_LOCALE from '@angular/common/locales/en-UG';
import NIGERIA_LOCALE from '@angular/common/locales/en-NG';
import CROATIA_LOCALE from '@angular/common/locales/hr';
import MONTENEGRO_LOCALE from '@angular/common/locales/sr-Latn-ME';
import BOSNIA_LOCALE from '@angular/common/locales/bs-Latn';

import { HeaderPinnbetComponent } from './layout/header-pinnbet/header-pinnbet.component';
import { MobileBottomMenuPinnbetComponent } from './layout/mobile-menu-pinnbet/mobile-bottom-menu-pinnbet/mobile-bottom-menu-pinnbet.component';
import { MobileQuickMenuPinnbetComponent } from './layout/mobile-menu-pinnbet/mobile-quick-menu-pinnbet/mobile-quick-menu-pinnbet.component';

import { CmsHeaderModule } from './layout/header-cms/cms-header.module';
import { RightMenuLinksCmsComponent } from './layout/right-menu-cms/right-menu-links-cms/right-menu-links-cms.component';
import { RightMenuCroCmsComponent } from './layout/right-menu-cro-cms/right-menu-cro-cms.component';

let localeClientCode = '';

switch (environment.clientName) {
  case CLIENT_NAMES.ADMIRAL_UGANDA:
    localeClientCode = 'en-UG';
    registerLocaleData(UGANDA_LOCALE, localeClientCode);
    break;
  case CLIENT_NAMES.ADMIRAL_NIGERIA:
    localeClientCode = 'en-NG';
    registerLocaleData(NIGERIA_LOCALE, localeClientCode);
    break;
  case CLIENT_NAMES.ADMIRAL_MONTENEGRO:
    localeClientCode = 'sr-Latn-ME';
    registerLocaleData(MONTENEGRO_LOCALE, localeClientCode);
    break;
  case CLIENT_NAMES.ADMIRAL_CROATIA:
    localeClientCode = 'hr';
    registerLocaleData(CROATIA_LOCALE, localeClientCode);
    break;
  case CLIENT_NAMES.ADMIRAL_SERBIA:
  case CLIENT_NAMES.ADMIRAL_PINNBET:
    localeClientCode = 'sr-Latn';
    registerLocaleData(SERBIA_LOCALE, localeClientCode);
    break;
  default:
    // default case for two bosnia clients
    localeClientCode = 'bs-Latn';
    registerLocaleData(BOSNIA_LOCALE, localeClientCode);
    break;
}

const COMPONENTS = [
  AppComponent,
  LayoutComponent,
  LeftMenuComponent,
  LeftMenuCmsComponent,
  HeaderComponent,
  HeaderCroComponent,
  HeaderPinnbetComponent,
  RightMenuComponent,
  RightMenuCmsComponent,
  RightMenuLinksCmsComponent,
  RightMenuCroComponent,
  RightMenuCroCmsComponent,
  FooterComponent,
  FooterCroComponent,
  FooterCMSComponent,
  LoaderComponent,
  OverlayShadeComponent,
  MobileBottomMenuComponent,
  /**
   * [AB-3839] Clean up commented code when definitely quick bar pass testing
   */
  // AppDownloadPopupComponent,
  MobileBottomMenuPinnbetComponent,
  MobileQuickMenuPinnbetComponent,
];

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  SharedModule,
  HttpClientModule,
  TranslateModule.forRoot({
    defaultLanguage: localStorage.getItem('language') || environment.defaultLang,
    missingTranslationHandler: {
      provide: MissingTranslationHandler,
      useClass: WarnMissingTranslationHandler,
    },
    loader: {
      provide: TranslateLoader,
      useClass: LanguageService,
    },
  }),
  HammerModule,
  InlineSVGModule.forRoot(),
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwa }),
  CookieModule.withOptions(),
  CmsHeaderModule,
];

export let AppInjector: Injector;

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...MODULES],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: LOCALE_ID, useValue: localeClientCode },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
