export class LobbySectionModel {
  id: number;
  code: string;
  gameCount: number;
  games: any;
  icon: string;
  name: string;
  productId: any;
  requireLoggedInPlayer: boolean;
  showOnUi: boolean;
  defaultSection: boolean;
  constructor(obj?: any) {
    this.id = (obj && obj.id) || -1;
    this.code = (obj && obj.code) || '';
    this.gameCount = (obj && obj.gameCount) || 0;
    this.games = (obj && obj.games) || null;
    this.icon = (obj && obj.icon) || '';
    this.name = (obj && obj.name) || '';
    this.productId = (obj && obj.productId) || null;
    this.requireLoggedInPlayer = (obj && obj.requireLoggedInPlayer) || false;
    this.showOnUi = (obj && obj.showOnUi) || false;
    this.defaultSection = (obj && obj.defaultSection) || false;
  }
}
